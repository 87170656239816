import React, { useContext, useEffect, useState } from 'react'
import classes from './RangTabela.module.css'
import MisterTipsterRangTable from '../components/MisterTipsterRangTable'
import axios from 'axios'
import AuthContext from '../context/AuthProvider'
import Spinner from '../components/Spinner'
import { message } from 'antd'
import { useNavigate } from 'react-router-dom'
const SERVER_URL = process.env.REACT_APP_SERVER_URL

const RangTabela = () => {
  const [rankTableData, setRankTableDate] = useState([[], []])
  const [searchTerm, setSearchTerm] = useState(null)
  const [intervals, setIntervals] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const { auth } = useContext(AuthContext)
  const searchParams = new URLSearchParams(window.location.search)
  const period = searchParams.get('period')
  const page = searchParams.get('page')
  const navigate = useNavigate()

  const startDate = period?.substring(0, 10)
  const endDate = period?.substring(11, 21)

  useEffect(() => {
    const fetchTableData = async () => {
      setIsLoading(true)
      try {
        const response = await axios.get(
          `${SERVER_URL}/user/get-ten-day-table/${
            auth?._id || 'noId'
          }/${page}/${startDate}/${endDate}/${searchTerm || null}`
        )
        setRankTableDate(response?.data)
      } catch (error) {
        // setRankTableDate([[], []])
        message.error(
          error?.response?.data?.message ||
            'Greška pri preuzimanju podataka, molimo pokušajte kasnije!'
        )
      }
      setIsLoading(false)
    }

    if (page && startDate) {
      fetchTableData()
    }
  }, [startDate, period, page, searchTerm])

  useEffect(() => {
    const fetchDatesForRank = async () => {
      try {
        const response = await axios.get(`${SERVER_URL}/user/get-date-for-rank`)
        const intervalsData = response?.data?.intervals

        setIntervals(intervalsData)
        if (intervalsData?.length > 0) {
          const newPeriod = `${intervalsData[0].startDate}-${intervalsData[0].endDate}`
          searchParams.set('period', newPeriod)
          navigate({
            search: searchParams.toString(),
          })
        }
      } catch (err) {
        message.error(
          err?.response?.data?.message ||
            'Greška pri preuzimanju podataka, molimo pokušajte kasnije!'
        )
      }
    }

    fetchDatesForRank()
  }, [])

  return (
    <div className={classes.rangTableContainer}>
      <MisterTipsterRangTable
        data={rankTableData}
        period='rang'
        intervals={intervals}
        searchTerm={searchTerm}
        isLoading={isLoading}
        setSearchTerm={setSearchTerm}
      />
    </div>
  )
}

export default RangTabela
