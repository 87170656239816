import React, { useContext, useEffect, useState } from 'react'
import axios from 'axios'
import classes from './MisterTipster.module.css'
import { useNavigate } from 'react-router-dom'
import MisterTipsterSingleMatch from '../components/MisterTipsterSingleMatch'
import AuthContext from '../context/AuthProvider'
import { IoStarSharp } from 'react-icons/io5'
import { IoMdArrowDropleft } from 'react-icons/io'
import { message, notification } from 'antd'
import LoginModal from '../components/LoginModal'
import useAxiosPrivate from '../hooks/useAxiosPrivate'
import useRefreshToken from '../hooks/useRefreshToken'

const SERVER_URL = process.env.REACT_APP_SERVER_URL

const MisterTipster = () => {
  const [pairsForDate, setPairsForDate] = useState(null)
  const [userBets, setUserBets] = useState(null)
  const [isOpenLoginModal, setIsOpenLoginModal] = useState(false)
  const [selectedBets, setSelectedBets] = useState([])
  const navigate = useNavigate()
  const [refetchTrigger, setRefetchTrigger] = useState(false)
  const axiosPrivate = useAxiosPrivate()
  const { auth } = useContext(AuthContext)
  const refresh = useRefreshToken()
  const hasPlacedBets = userBets && userBets[0]?.bets?.length > 0
  const isLogged = auth && Object.keys(auth).length > 0

  const handlePlaceBets = async () => {
    if (!isLogged) {
      setIsOpenLoginModal(true)
      return
    }

    if (isLogged && !auth.isMisterTipster) {
      navigate('/moj_nalog')
    }

    try {
      await axios.post(`${SERVER_URL}/create-bet`, {
        bets: selectedBets,
        userId: auth?._id,
      })
      message.success('Uspešno ste odigrali današnji tiket!')
      setRefetchTrigger((curr) => !curr)
    } catch (err) {
      console.log(err)
      message.error(err?.response?.data?.message || 'sdsdsd')
    }
  }

  useEffect(() => {
    const getPairsForDate = async () => {
      try {
        const response = await axios.get(`${SERVER_URL}/get-pair-for-today`)
        setPairsForDate(response?.data?.pairsForDate)
      } catch (err) {
        console.error('Error fetching pairs:', err)
      }
    }

    const getBetsByUser = async () => {
      try {
        const response = await axios.get(
          `${SERVER_URL}/getBetsByUser/${auth?._id}`
        )
        setUserBets(response?.data)
      } catch (err) {
        console.error('Error fetching pairs:', err)
      }
    }
    auth._id && getBetsByUser()
    getPairsForDate()
  }, [refetchTrigger])

  const handleEmailSubscription = async () => {
    if (!isLogged) {
      setIsOpenLoginModal(true)
      return
    }
    try {
      const response = await axiosPrivate.put(
        `${SERVER_URL}/update-email-notification/${auth?._id}`,
        {
          misterTipsterNotification: !auth?.misterTipsterNotification,
        }
      )
      notification.success({
        message: response?.data?.message ||  'Uspešno ste se pretplatili!',
        placement: 'topRight',
      })
      console.log(response)
      refresh()
    } catch (err) {
      notification.error({
        message: err?.response?.data?.message || 'Greška, pokušajte kasnije !',
        placement: 'topRight',
      })
    }
  }

  return (
    <div className={classes.tipsterPage}>
      <div className={classes.sticky}>
        <div className={classes.topBtns}>
          <button
            onClick={() =>
              isLogged ? navigate('/moj_nalog') : setIsOpenLoginModal(true)
            }
          >
            {isLogged ? 'MOJ NALOG' : 'REGISTRUJ SE - ULOGUJ SE'}
          </button>
          <h3>IGRAJ I POBEDI !</h3>
          <div className={classes.mailNotificationCheckbox}>
            <label htmlFor='mailNotificationCheckbox'>E-MAIL INFO</label>
            <div>
              <input
                // disabled={!isLogged}
                checked={auth?.misterTipsterNotification || false}
                // onChange={(e) => setIsEmailChecked((curr) => !curr)}
                onClick={() => handleEmailSubscription()}
                type='checkbox'
                name='mailNotificationCheckbox'
                id='mailNotificationCheckbox'
              />
            </div>
          </div>
        </div>
        <div className={classes.backAndTitle}>
          <button onClick={() => navigate(-1)}>
            <IoMdArrowDropleft />
            <span>NAZAD</span>
          </button>
          <div className={classes.dailyTipsterTitle}>
            <IoStarSharp className={classes.redStartIcon} />
            MOJ DANAŠNJI TIPSTER MATCH
            <IoStarSharp className={classes.redStartIcon} />
          </div>
        </div>
      </div>

      <div className={classes.buttonWrapper}>
        <button
          className={classes.greenBtn}
          onClick={() =>
            navigate('/mister_tipster/tabele/tabela-ranga?page=1', {
              state: { from: '/mister_tipster' },
            })
          }
        >
          TABELA RANGA
        </button>
        <button
          className={classes.blueBtn}
          onClick={() => {
            isLogged
              ? navigate(`/mister_tipster/profil?user=${auth?._id}`)
              : setIsOpenLoginModal(true)
          }}
        >
          MOJ TIPSTER PROFIL
        </button>
        <button
          className={classes.greenBtn}
          onClick={() =>
            navigate('/mister_tipster/tabele/mesecna-tabela?page=1', {
              state: { from: '/mister_tipster' },
            })
          }
        >
          MESEČNA TABELA
        </button>
        <button
          className={classes.blueBtn}
          onClick={() => navigate('/mister_tipster/pravila-igre')}
        >
          PRAVILA IGRE
        </button>
        <button
          className={classes.greenBtn}
          onClick={() =>
            navigate('/mister_tipster/tabele/godisnja-tabela?page=1', {
              state: { from: '/mister_tipster' },
            })
          }
        >
          GODIŠNJA TABELA
        </button>
        <button
          className={classes.blueBtn}
          onClick={() => navigate('/mister_tipster/nagrade')}
        >
          FOND ZA OSVOJENE NAGRADE
        </button>
      </div>
      <div className={classes.tipsterMatchTableHeader}>
        <div className={classes.x12Div}>
          <span className={classes.x12}>1 <span>X</span> 2</span>
          <span className={classes.clickOnTip}>KLIKNI NA SVOJ TIP</span>
        </div>
        <div className={classes.dailyPairsTitle}>DANAŠNJI PAROVI</div>
      </div>
      <div className={classes.tipsterMatchContainer}>
        {pairsForDate?.length > 0 ? (
          pairsForDate?.map((pair, index) => (
            <MisterTipsterSingleMatch
              key={pair._id}
              pair={pair}
              selectedBets={selectedBets}
              setSelectedBets={setSelectedBets}
              isMatchPage
              userBets={userBets}
              index={index}
            />
          ))
        ) : (
          <div className={classes.empty}>
            <h1>Nemate meč za danas!</h1>
          </div>
        )}
      </div>
      {!hasPlacedBets && pairsForDate?.length > 0 && (
        <button
          className={classes.placeYourBetsBtn}
          onClick={() => handlePlaceBets()}
        >
          KLIKNI I POTVRDI TIPOVE
        </button>
      )}

      <LoginModal
        setIsOpenLoginModal={setIsOpenLoginModal}
        isOpenLoginModal={isOpenLoginModal}
        title='Da bi ste nastavili morate biti ulogovani!'
      />
    </div>
  )
}

export default MisterTipster
