import { Outlet } from "react-router-dom";
import AdminHeader from "../adminPanel/AdminHeader";
import AdminSidePanel from "../adminPanel/AdminSidePanel";
import AdminFooter from "../adminPanel/AdminFooter";
import classes from "./adminLayout.module.css";
const AdminLayout = () => {
  return (
    <div className={classes.container}>
      <AdminHeader />
      <div className={classes.center}>
        <AdminSidePanel />
        <Outlet />
      </div>
      <AdminFooter />
    </div>
  );
};

export default AdminLayout;
