import Grupa1 from "../components/Grupa1";
import Grupa28 from "../components/Grupa28";
import Grupa3004 from "../components/Grupa3004";
import classes from "./kosarka.module.css";
import { useState } from "react";

const Kosarka = () => {
  ////zaglavlje
  const [snd, setSND] = useState("-");
  const [sod, setSOD] = useState("-");
  const [sog, setSOG] = useState("-");
  const [sng, setSNG] = useState("-");
  const [sodg, setSODG] = useState("-");
  const [sndg, setSNDG] = useState("-");
  const [sndSog, setSNDSOG] = useState("-");
  const [sodSng, setSODSNG] = useState("-");
  /////input polja////
  const [pobedaDomacina, setPobedaDomacina] = useState(0);
  const [nereseno, setNeresno] = useState(0);
  const [pobedaGosta, setPobedaGosta] = useState(0);
  const [domacinDao, setDomacinDao] = useState(0);
  const [domacinPrimio, setDomacinPrimio] = useState(0);
  const [brojUtakmicaDomacin, setBrojUtakmicaDomacin] = useState(0);
  const [gostDao, setGostDao] = useState(0);
  const [gostPrimio, setGostPrimio] = useState(0);
  const [brojUtakmicaGost, setBrojUtakmicaGost] = useState(0);
  ////rezultati
  const [konacanIshod1, setKonacanIshod1] = useState(0);
  const [konacanIshod2, setKonacanIshod2] = useState(0);
  const [poeniMin, setPoeniMin] = useState(0);
  const [poeniMax, setPoeniMax] = useState(0);
  const [poeniBalans, setPoeniBalans] = useState(0);
  const [pobedaSaRazlikomMin, setPobedaSaRazlikomMin] = useState(0);
  const [pobedaSaRazlikomMax, setPobedaSaRazlikomMax] = useState(0);
  const [pobedaSaRazlikomBalans, setPobedaSaRazlikomBalans] = useState(0);
  const [tacanRezultatDom1, setTacanRezultatDom1] = useState(0);
  const [tacanRezultatDom2, setTacanRezultatDom2] = useState(0);
  const [tacanRezultatDom3, setTacanRezultatDom3] = useState(0);
  const [tacanRezultatGost1, setTacanRezultatGost1] = useState(0);
  const [tacanRezultatGost2, setTacanRezultatGost2] = useState(0);
  const [tacanRezultatGost3, setTacanRezultatGost3] = useState(0);
  const [betkopredlog, setBetkoPredlog] = useState(0);

  return (
    <div className={classes.container}>
      <Grupa1
        imgText="KOŠARKA"
        imgBackgroundColorDark="#000000"
        imgBackgroundColorLight="#000000"
        imgFontColor="#F9511D"
        label1="VREME POČETKA"
        label2="ZEMLJA"
        label3="DOMAĆIN"
        label4="TAKMIČENJE - LIGA"
        label5="GOST"
      />
      <Grupa28
        imgBackgroundColorDark="#000000"
        imgBackgroundColorLight="#000000"
        imgFontColor="#F9511D"
        label1={snd}
        label2={sod}
        label3={sog}
        label4={sng}
        label5={sodg}
        label6={sndg}
        label7={sndSog}
        label8={sodSng}
        calculatorType={`Kosarka`}
      />
      <Grupa3004
        snd={snd}
        setSND={setSND}
        sod={sod}
        setSOD={setSOD}
        sog={sog}
        setSOG={setSOG}
        sng={sng}
        setSNG={setSNG}
        sodg={sodg}
        setSODG={setSODG}
        sndg={sndg}
        setSNDG={setSNDG}
        sndSog={sndSog}
        setSNDSOG={setSNDSOG}
        sodSng={sodSng}
        setSODSNG={setSODSNG}
        pobedaDomacina={pobedaDomacina}
        setPobedaDomacina={setPobedaDomacina}
        nereseno={nereseno}
        setNeresno={setNeresno}
        pobedaGosta={pobedaGosta}
        setPobedaGosta={setPobedaGosta}
        domacinDao={domacinDao}
        setDomacinDao={setDomacinDao}
        domacinPrimio={domacinPrimio}
        setDomacinPrimio={setDomacinPrimio}
        brojUtakmicaDomacin={brojUtakmicaDomacin}
        setBrojUtakmicaDomacin={setBrojUtakmicaDomacin}
        brojUtakmicaGost={brojUtakmicaGost}
        setBrojUtakmicaGost={setBrojUtakmicaGost}
        gostDao={gostDao}
        setGostDao={setGostDao}
        gostPrimio={gostPrimio}
        setGostPrimio={setGostPrimio}
        konacanIshod1={konacanIshod1}
        setKonacanIshod1={setKonacanIshod1}
        konacanIshod2={konacanIshod2}
        setKonacanIshod2={setKonacanIshod2}
        poeniMin={poeniMin}
        setPoeniMin={setPoeniMin}
        poeniMax={poeniMax}
        setPoeniMax={setPoeniMax}
        poeniBalans={poeniBalans}
        setPoeniBalans={setPoeniBalans}
        pobedaSaRazlikomMin={pobedaSaRazlikomMin}
        setPobedaSaRazlikomMin={setPobedaSaRazlikomMin}
        pobedaSaRazlikomMax={pobedaSaRazlikomMax}
        setPobedaSaRazlikomMax={setPobedaSaRazlikomMax}
        pobedaSaRazlikomBalans={pobedaSaRazlikomBalans}
        setPobedaSaRazlikomBalans={setPobedaSaRazlikomBalans}
        tacanRezultatDom1={tacanRezultatDom1}
        setTacanRezultatDom1={setTacanRezultatDom1}
        tacanRezultatDom2={tacanRezultatDom2}
        setTacanRezultatDom2={setTacanRezultatDom2}
        tacanRezultatDom3={tacanRezultatDom3}
        setTacanRezultatDom3={setTacanRezultatDom3}
        tacanRezultatGost1={tacanRezultatGost1}
        setTacanRezultatGost1={setTacanRezultatGost1}
        tacanRezultatGost2={tacanRezultatGost2}
        setTacanRezultatGost2={setTacanRezultatGost2}
        tacanRezultatGost3={tacanRezultatGost3}
        setTacanRezultatGost3={setTacanRezultatGost3}
        betkopredlog={betkopredlog}
        setBetkoPredlog={setBetkoPredlog}
      />
      <div className={classes.calculator}>
        <p className={classes.konacanIshod1}>
          {isNaN(konacanIshod1)
            ? null
            : konacanIshod1 !== 0
            ? konacanIshod1 + "%"
            : ""}{" "}
        </p>
        <p className={classes.konacanIshod2}>
          {isNaN(konacanIshod2)
            ? null
            : konacanIshod2 !== 0
            ? konacanIshod2 + "%"
            : ""}{" "}
        </p>
        <p className={classes.poeniMin}>
          {isNaN(poeniMin) ? null : poeniMin !== 0 ? poeniMin : ""}{" "}
        </p>
        <p className={classes.poeniMax}>
          {isNaN(poeniMax) ? null : poeniMax !== 0 ? poeniMax : ""}{" "}
        </p>
        <p className={classes.poeniBalans}>
          {isNaN(poeniBalans) ? null : poeniBalans !== 0 ? poeniBalans : ""}{" "}
        </p>
        <p className={classes.pobedaSaRazlikomMin}>
          {isNaN(pobedaSaRazlikomMin)
            ? null
            : pobedaSaRazlikomMin !== 0
            ? pobedaSaRazlikomMin
            : ""}{" "}
        </p>
        <p className={classes.pobedaSaRazlikomMax}>
          {isNaN(pobedaSaRazlikomMax)
            ? null
            : pobedaSaRazlikomMax !== 0
            ? pobedaSaRazlikomMax
            : ""}{" "}
        </p>
        <p className={classes.pobedaSaRazlikomBalans}>
          {isNaN(pobedaSaRazlikomBalans)
            ? null
            : pobedaSaRazlikomBalans !== 0
            ? pobedaSaRazlikomBalans
            : ""}
        </p>
        <p className={classes.tacanRezultatDom1}>
          {isNaN(tacanRezultatDom1)
            ? null
            : tacanRezultatDom1 !== 0
            ? tacanRezultatDom1
            : ""}{" "}
        </p>
        <p className={classes.tacanRezultatDom2}>
          {isNaN(tacanRezultatDom2)
            ? null
            : tacanRezultatDom2 !== 0
            ? tacanRezultatDom2
            : ""}{" "}
        </p>
        <p className={classes.tacanRezultatDom3}>
          {isNaN(tacanRezultatDom3)
            ? null
            : tacanRezultatDom3 !== 0
            ? tacanRezultatDom3
            : ""}{" "}
        </p>
        <p className={classes.tacanRezultatGost1}>
          {isNaN(tacanRezultatGost1)
            ? null
            : tacanRezultatGost1 !== 0
            ? tacanRezultatGost1
            : ""}{" "}
        </p>
        <p className={classes.tacanRezultatGost2}>
          {isNaN(tacanRezultatGost2)
            ? null
            : tacanRezultatGost2 !== 0
            ? tacanRezultatGost2
            : ""}{" "}
        </p>
        <p className={classes.tacanRezultatGost3}>
          {isNaN(tacanRezultatGost3)
            ? null
            : tacanRezultatGost3 !== 0
            ? tacanRezultatGost3
            : ""}{" "}
        </p>
        <p className={classes.betkopredlog}>
          {isNaN(betkopredlog) ? null : betkopredlog !== 0 ? betkopredlog : ""}{" "}
        </p>
      </div>
    </div>
  );
};

export default Kosarka;
