import Grupa3 from "../components/Grupa3";
import Grupa20 from "../components/Grupa20";
import classes from "./comboBombo.module.css";

const ComboBombo = () => {
  const firstColumnData = Array.from({ length: 47 });
  const secondColumnData = Array.from({ length: 47 });

  for (let i = 0; i < firstColumnData.length; i++) {
    if (
      i === 2 ||
      i === 4 ||
      i === 6 ||
      i === 8 ||
      i === 10 ||
      i === 12 ||
      i === 14
    ) {
      firstColumnData[i] =
        "UG 1-3 (NA UTAKMICI ĆE BITI UKUPNO GOLOVA OD 1 DO 3)";
    } else if (
      i === 3 ||
      i === 5 ||
      i === 7 ||
      i === 9 ||
      i === 11 ||
      i === 13 ||
      i === 15
    ) {
      firstColumnData[i] =
        "UG 2-5 (NA UTAKMICI ĆE BITI UKUPNO GOLOVA OD 2 DO 5)";
    } else if (
      i === 17 ||
      i === 19 ||
      i === 21 ||
      i === 23 ||
      i === 25 ||
      i === 27 ||
      i === 29
    ) {
      firstColumnData[i] =
        "UG 0-3 (NA UTAKMICI ĆE BITI UKUPNO GOLOVA OD 0 DO 3)";
    } else if (
      i === 30 ||
      i === 18 ||
      i === 20 ||
      i === 22 ||
      i === 24 ||
      i === 26 ||
      i === 28
    ) {
      firstColumnData[i] =
        "UG 1-4 (NA UTAKMICI ĆE BITI UKUPNO GOLOVA OD 1 DO 4)";
    } else if (
      i === 33 ||
      i === 35 ||
      i === 37 ||
      i === 39 ||
      i === 41 ||
      i === 43 ||
      i === 45
    ) {
      firstColumnData[i] = "KI X2 (NEREŠEN REZULTAT ILI POBEDA GOSTA)";
    } else if (
      i === 32 ||
      i === 34 ||
      i === 36 ||
      i === 38 ||
      i === 40 ||
      i === 42 ||
      i === 44
    ) {
      firstColumnData[i] = "KI 1X (POBEDA, DOMAČINA ILI NEREŠEN REZULTAT)";
    }
  }

  for (let i = 0; i < secondColumnData.length; i++) {
    if (
      i === 2 ||
      i === 4 ||
      i === 6 ||
      i === 8 ||
      i === 10 ||
      i === 12 ||
      i === 14
    ) {
      secondColumnData[i] =
        "UG 2-5 (NA UTAKMICI ĆE BITI UKUPNO GOLOVA OD 2 DO 5)";
    } else if (
      i === 3 ||
      i === 5 ||
      i === 7 ||
      i === 9 ||
      i === 11 ||
      i === 13 ||
      i === 15
    ) {
      secondColumnData[i] =
        "UG 1-3 (NA UTAKMICI ĆE BITI UKUPNO GOLOVA OD 1 DO 3)";
    } else if (
      i === 17 ||
      i === 19 ||
      i === 21 ||
      i === 23 ||
      i === 25 ||
      i === 27 ||
      i === 29
    ) {
      secondColumnData[i] =
        "UG 1-4 (NA UTAKMICI ĆE BITI UKUPNO GOLOVA OD 1 DO 4)";
    } else if (
      i === 30 ||
      i === 18 ||
      i === 20 ||
      i === 22 ||
      i === 24 ||
      i === 26 ||
      i === 28
    ) {
      secondColumnData[i] =
        "UG 0-3 (NA UTAKMICI ĆE BITI UKUPNO GOLOVA OD 0 DO 3)";
    } else if (
      i === 33 ||
      i === 35 ||
      i === 37 ||
      i === 39 ||
      i === 41 ||
      i === 43 ||
      i === 45
    ) {
      secondColumnData[i] = "KI 1X (POBEDA, DOMAČINA ILI NEREŠEN REZULTAT)";
    } else if (
      i === 32 ||
      i === 34 ||
      i === 36 ||
      i === 38 ||
      i === 40 ||
      i === 42 ||
      i === 44
    ) {
      secondColumnData[i] = "KI X2 (NEREŠEN REZULTAT ILI POBEDA GOSTA)";
    }
  }

  const rows = 47;
  const columns = 9;
  const limitedColumnsRows = [1];
  const limitForSelectedRows = 8;
  const arrayOfArrays = [];

  for (let i = 0; i < rows; i++) {
    const innerArray = [];

    const currentColumnsLimit = limitedColumnsRows.includes(i)
      ? limitForSelectedRows
      : columns;

    for (let j = 0; j < currentColumnsLimit; j++) {
      innerArray.push(`${i}-${j}`);
    }

    arrayOfArrays.push(innerArray);
  }

  // console.log(firstColumnData);

  return (
    <div className={classes.container}>
      <Grupa3
        imgText="COMBO BOMBO"
        imgBackgroundColorDark="#052C83"
        imgBackgroundColorLight="#0D2869"
        imgFontColor="#00B0F0"
      />
      <Grupa20
        imgBackgroundColorDark="#052C83"
        imgBackgroundColorLight="#0D2869"
        imgFontColor="#00B0F0"
        calculatorType={`Combo bombo`}
      />
      <div className={classes.bottom}>
        <div className={classes.calculator}>
          <table border="1">
            <tbody>
              {arrayOfArrays.map((row, rowIndex) => (
                <tr style={{ display: "flex", width: "100%" }} key={rowIndex}>
                  {rowIndex === 0 ||
                  rowIndex === 1 ||
                  rowIndex === 16 ||
                  rowIndex === 31 ||
                  rowIndex === 46 ? (
                    <div style={{ width: "100%" }}>
                      {rowIndex === 0 ? (
                        <div style={{ display: "flex" }}>
                          <div
                            style={{
                              width: "5%",
                              textAlign: "center",
                              backgroundColor: "rgb(5, 44, 131",
                              color: "white",
                              border: "1px solid black",
                            }}
                          >
                            R.br.
                          </div>
                          <div
                            style={{
                              width: "5%",
                              textAlign: "center",
                              backgroundColor: "rgb(5, 44, 131",
                              color: "white",
                              border: "1px solid black",
                            }}
                          >
                            h
                          </div>
                          <div
                            style={{
                              width: "11%",
                              textAlign: "center",
                              backgroundColor: "rgb(5, 44, 131",
                              color: "white",
                              border: "1px solid black",
                            }}
                          >
                            DOMAĆIN
                          </div>
                          <div
                            style={{
                              width: "11%",
                              textAlign: "center",
                              backgroundColor: "rgb(5, 44, 131",
                              color: "white",
                              border: "1px solid black",
                            }}
                          >
                            GOST
                          </div>
                          <div
                            style={{
                              width: "5%",
                              textAlign: "center",
                              backgroundColor: "rgb(5, 44, 131",
                              color: "white",
                              border: "1px solid black",
                            }}
                          >
                            ZEMLJA
                          </div>
                          <div
                            style={{
                              width: "5%",
                              textAlign: "center",
                              backgroundColor: "rgb(5, 44, 131",
                              color: "white",
                              border: "1px solid black",
                            }}
                          >
                            IGRA
                          </div>
                          <div
                            style={{
                              width: "5%",
                              textAlign: "center",
                              backgroundColor: "rgb(5, 44, 131",
                              color: "white",
                              border: "1px solid black",
                            }}
                          >
                            KVOTA
                          </div>
                          <td
                            style={{
                              width: "370px",
                              backgroundColor: "rgb(5, 44, 131)",
                              color: "white",
                              borderColor: "black",
                            }}
                          >
                            TIKET 1
                          </td>
                          <td
                            style={{
                              width: "370px",
                              backgroundColor: "rgb(5, 44, 131)",
                              color: "white",
                              borderColor: "black",
                            }}
                          >
                            TIKET 2
                          </td>
                        </div>
                      ) : (
                        ""
                      )}
                      {rowIndex === 1 ? (
                        <div
                          style={{
                            width: "100%",
                            textAlign: "center",
                            backgroundColor: "rgb(5, 44, 131",
                            color: "white",
                            border: "1px solid black",
                            fontSize: "22px",
                          }}
                        >
                          KOMBINACIJA 1
                        </div>
                      ) : (
                        ""
                      )}

                      {rowIndex === 16 ? (
                        <div
                          style={{
                            width: "100%",
                            textAlign: "center",
                            backgroundColor: "rgb(5, 44, 131",
                            color: "white",
                            border: "1px solid black",
                            fontSize: "22px",
                          }}
                        >
                          KOMBINACIJA 2
                        </div>
                      ) : (
                        ""
                      )}

                      {rowIndex === 31 ? (
                        <div
                          style={{
                            width: "100%",
                            textAlign: "center",
                            backgroundColor: "rgb(5, 44, 131",
                            color: "white",
                            border: "1px solid black",
                            fontSize: "22px",
                          }}
                        >
                          KOMBINACIJA 3
                        </div>
                      ) : (
                        ""
                      )}

                      {rowIndex === 46 ? (
                        <div
                          style={{
                            width: "100%",
                            textAlign: "center",
                            backgroundColor: "#00c157",
                            color: "black",
                            border: "1px solid black",
                            fontSize: "22px",
                          }}
                        >
                          ZBIRKU SISTEMA ĆEMO KONTINUIRANO NADOGRAĐIVATI UNUTAR
                          MENIJA 'PREMIUM'
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  ) : (
                    row.map((col, colIndex) => {
                      if (colIndex == 2 || colIndex == 3) {
                        return (
                          <div
                            style={{ width: "11%", border: "1px solid gray" }}
                          >
                            <input
                              style={{ width: "95%", border: "none" }}
                            ></input>
                          </div>
                        );
                      }
                      if (
                        colIndex == 0 ||
                        colIndex == 1 ||
                        colIndex == 4 ||
                        colIndex == 5 ||
                        colIndex == 6
                      ) {
                        return (
                          <div
                            style={{ width: "5%", border: "1px solid gray" }}
                          >
                            <input
                              style={{ width: "95%", border: "none" }}
                            ></input>
                          </div>
                        );
                      } else {
                        if (colIndex === 7 && rowIndex > 1 && rowIndex < 16) {
                          return (
                            <td
                              style={{
                                width: "370px",
                                backgroundColor: "#00a7f4",
                                color: "black",
                                textAlign: "center",
                                fontSize: "12px",
                                fontWeight: "bolder",
                              }}
                              key={colIndex}
                            >
                              {firstColumnData[rowIndex]}
                            </td>
                          );
                        } else if (
                          colIndex === 7 &&
                          rowIndex > 16 &&
                          rowIndex < 31
                        ) {
                          return (
                            <td
                              style={{
                                width: "370px",
                                backgroundColor: "#ffc700",
                                color: "black",
                                textAlign: "center",
                                fontSize: "12px",
                                fontWeight: "bolder",
                              }}
                              key={colIndex}
                            >
                              {firstColumnData[rowIndex]}
                            </td>
                          );
                        } else if (
                          colIndex === 8 &&
                          rowIndex > 1 &&
                          rowIndex < 16
                        ) {
                          return (
                            <td
                              style={{
                                width: "370px",
                                backgroundColor: "#ffc700",
                                color: "black",
                                textAlign: "center",
                                fontSize: "12px",
                                fontWeight: "bolder",
                              }}
                              key={colIndex}
                            >
                              {secondColumnData[rowIndex]}
                            </td>
                          );
                        } else if (
                          colIndex === 8 &&
                          rowIndex > 16 &&
                          rowIndex < 31
                        ) {
                          return (
                            <td
                              style={{
                                width: "370px",
                                backgroundColor: "#00a7f4",
                                color: "black",
                                textAlign: "center",
                                fontSize: "12px",
                                fontWeight: "bolder",
                              }}
                              key={colIndex}
                            >
                              {secondColumnData[rowIndex]}
                            </td>
                          );
                        } else if (
                          colIndex === 7 &&
                          rowIndex > 30 &&
                          rowIndex < 46
                        ) {
                          return (
                            <td
                              style={{
                                width: "370px",
                                backgroundColor: "#00a7f4",
                                color: "black",
                                textAlign: "center",
                                fontSize: "12px",
                                fontWeight: "bolder",
                              }}
                              key={colIndex}
                            >
                              {firstColumnData[rowIndex]}
                            </td>
                          );
                        } else if (
                          colIndex === 8 &&
                          rowIndex > 30 &&
                          rowIndex < 46
                        ) {
                          return (
                            <td
                              style={{
                                width: "370px",
                                backgroundColor: "#ffc700",
                                color: "black",
                                textAlign: "center",
                                fontSize: "12px",
                                fontWeight: "bolder",
                              }}
                              key={colIndex}
                            >
                              {secondColumnData[rowIndex]}
                            </td>
                          );
                        } else {
                          return (
                            <td style={{ width: "350px" }} key={colIndex}></td>
                          );
                        }
                      }
                    })
                  )}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default ComboBombo;
