import classes from './PolitikaPrivatnosti.module.css'

const PolitikaPrivatnosti = () => {
  return (
    <div className={classes.mainCenter}>
      <h2>ŠTA JE POLITIKA PRIVATNOSTI ?</h2>
      <p>
        Želimo da razumete koje podatke možemo da prikupljamo, kako da ih
        koristimo, i kako možemo da ih delimo.Zato vas pozivamo da pročitate
        našu politiku privatnosti. Ovo vam pomaže da koristite naš sajt na način
        na koji to vama odgovara.
      </p>
      <p>
        U politici privatnosti objašnjavamo kako možemo da prikupljamo, da
        koristimo i da delimo, te zadržavamo i prenosimo podatke. Takođe vas
        obaveštavamo o vašim pravima. Svaki odeljak politike privatnosti sadrži
        jednostavan tekst, kako biste lakše razumeli sadržinu istog.
      </p>
      <p>
        Shodno tome da je politika privatnosti dokument koji se ne menja,
        ukazujemo vam na sve funkcije koje betkotip.com može da koristi, što
        nema značenje i da koristi sve od istih, kako biste bili upoznati sa
        svim potrebnim detaljima koji štite vaše interese, i na koje potvrđujete
        svoju saglasnost korišćenjem našeg veb sajta i naše mobilne aplikacije.
      </p>
      <p>
        Za nas je važno da znate, kako da kontrolišete svoju privatnost, kako
        biste kvalitetno oblikovali svoje iskustvo korišćenja betkotip.com
      </p>
      <h2>DATOTEKE SA BELEŠKAMA</h2>
      <p>
        Sajt BETKOTIP.COM koristi standardnu proceduru korišćenja datoteka
        fajlova. Ove datoteke evidentiraju posetioce kada posete sajt. Sve
        hosting kompanije rade ovo kao deo analitike hosting usluga. Podaci
        prikupljeni u datotekama fajlova uključuju adrese internetskog protokola
        (IP), tip pregledača, dobavljača Internet usluga (ISP), zemlje iz koje
        korisnik posećuje sajt, datum i vreme, stranice sa upućivanjem /
        izlaskom i broj klikova.
      </p>
      <p>
        Ovi podaci nisu povezani sa bilo kojim informacijama koje za cilj imaju
        da vas lično identifikuju, izuzev u situacijama u kojima se to u
        slulačaju ugrožavanja nečije bezbednosti, pretnji ili sličnih stvari, od
        strane nadležnih organa traži od nas u vidu podrške u cilju rešavanja
        određenih slučajeva.
      </p>
      <p>
        Svrha informacija je analiziranje trendova, administriranje sajta,
        praćenje kretanja korisnika na sajtu i prikupljanje demografskih
        podataka.
      </p>
      <h2>NEZAVISNI OGLASIVAČI</h2>
      <p>
        Nezavisni oglašivači ili oglasne mreže koriste tehnologije poput
        kolačića, koji se koriste u njihovim odgovarajućim oglasima i vezama i
        koji mogu da se pojavljuju na sajtu BETKOTIP.COM, a koji se šalju
        direktno u pregledač korisnika. Oni automatski prime vašu IP adresu kada
        se to dogodi. Ove tehnologije se koriste za merenje efikasnosti njihovih
        reklamnih kampanja i / ili za personalizaciju reklamnog sadržaja koji
        vidite na veb lokacijama koje posećujete. Imajte na umu da BETKOTIP.COM
        nema pristup ili kontrolu nad ovim kolačićima koje koriste nezavisni
        oglašivači. POLITIKE
      </p>
      <h2>PRIVATNOSTI TREĆIH STRANA</h2>
      <p>
        Politika privatnosti sajta BETKOTIP.COM se ne odnosi na druge oglašavače
        ili veb lokacije. Stoga vam savetujemo da detaljnije informacije
        potražite u odgovarajućim Politikama privatnosti ovih nezavisnih
        oglašivača. Može sadržati njihovu praksu i uputstva o tome kako da se
        onemoguće određene opcije. Možete da onemogućite kolačiće pomoću
        pojedinačnih opcija pregledača. Da biste znali detaljnije informacije o
        upravljanju kolačićima pomoću određenih veb pregledača, možete ih
        pronaći na odgovarajućim veb lokacijama pregledača.
      </p>
      <h2>POLITIKA PRIVATNOSTI ZA DECU</h2>
      <p>
        Drugi deo našeg prioriteta je dodavanje zaštite za decu dok koriste
        Internet. Podstičemo roditelje i staratelje da posmatraju, učestvuju i /
        ili nadgledaju svoje mrežne aktivnosti. BETKOTIP.COM ne prikuplja svesno
        nikakve lične podatke koji se mogu identifikovati od dece mlađe od 18
        godina. Ako mislite da je vaše dete pružilo ovakve informacije na našoj
        veb stranici, iskreno Vam preporučujemo da nas odmah kontaktirate i mi
        ćemo se potruditi da odmah uklonimo takve podatke iz naše evidencije.
      </p>
      <h2>POLITIKA PRIVATNOSTI ONLINE</h2>
      <p>
        Ova Politika privatnosti odnosi se samo na naše mrežne aktivnosti i važi
        za posetioce naše veb stranice u vezi sa informacijama koje su delili i
        / ili prikupljali na BETKOTIP.COM Ova politika se ne odnosi na bilo koje
        informacije prikupljene van mreže ili putem kanala koji nisu sa ove veb
        lokacije.
      </p>
      <h2>KOJI DEO SAJTA OBUHVATA NAŠA POLITIKA PRIVATNOSTI ?</h2>
      <p>
        Politika privatnosti betkotip.com obuhvata sve delove veb sajta
        betkotip.com i mobilne aplikacije pod nazivom betko.
      </p>
      <h2>KOJE INFORMACIJE MOŽEMO DA PRIKUPLJAMO ?</h2>
      <p>
        Podaci koje možemo da prikupljamo o vama, i koje možemo da obrađujemo,
        zavise od vašeg načina korišćenja naše platforme ili mobilne aplikacije.
        Primera radi, možemo da prikupljamo različite informacije, ukoliko
        koristitite deo betkotip.com koji se odnosi na shop menije, ili pak
        drugačije ukoliko koristitite recimo komentare na našoj platformi.
        Takođe, u određenim situacijama možemo da prikupljamo određene podatke
        čak i ako nemate nalog na betkotip.com
      </p>
      <p>To mogu biti podaci poput :</p>
      <p>
        - Evidencije vaših pregledača i aplikacija o vašim posetama javnom
        sadržaju.
      </p>
      <p>
        - Osnovne informacije o uređajima koji su preuzeli našu aplikaciju,
        poput modela uređaja i operativnog sistema.
      </p>
      <p>
        Možemo da prikupljamo podatke i o sadržaju koji kreirate, poput vaših
        objava, poruka koje postavljate, uključujući njihov sadržaj, u cilju
        kontrole merodavnog korišćenja.Takođe, možemo da prikupljamo i podatke o
        načinu korišćenja alata na našem sajtu, primera radi bet kalkulatora,
        kako bismo kroz analitiku istog poboljšali vaše korisničko iskustvo.
      </p>
      <h2>ZAŠTO MOŽEMO DA PRIKUPLJAMO INFORMACIJE ?</h2>
      <p>
        Primera radi, jedan od razloga zbog kog možemo da prikupljamo
        informacije jeste taj da bismo pratili pokušaje napada na naše sisteme,
        kao i preopterećenje naših servera saobraćajem. Takođe, ako vidimo da
        neko bez naloga pokušava da učita previše stranica, možda pokušava da
        izvlači podatke sa naše veb lokacije. Tada možemo preduzeti mere da to
        sprečimo.
      </p>
      <p>
        Takođe, primera radi, podatke možemo da prikupljamo u skladu sa zaštitom
        bezbednosti i integriteta sadržaja koji korisnici postavljaju. U cilju
        zaštite nečijih autorskih prava, ukoliko dobijemo obaveštenje o kršenju
        istih (korisnici su odgovorni za sadržaj koji postavljaju). Ujedno,
        možemo ukloniti sadržaj koji krši naše uslove korišćenja i smernice.
        Jednako, ukoliko je sadržaj opasan po okolinu, sadrži pretnje, ili bilo
        koji vid opasnosti po okolinu, prikupljanje podataka može pomoći u
        razrešenju slučaja, shodno tome da možemo obavestiti nadležne organe o
        takvom sadržaju i dostaviti im potrebne informacije sa kojima
        raspolažemo.
      </p>

      <p>
        Možemo da prikupljamo i informacije kada ljudi koriste našu platformu,
        čak i kada nemaju registrovan nalog, kako bismo prikupili informacije
        koliko se brzo učitavaju stranice u različitim zemljama, i da li rade
        pravilno. To može da nam pomogne da identifikujemo i da rešimo probleme
        na lokalnim mrežama.
      </p>

      <h2>ŠTA AKO NAM NE DOZVOLITE DA PRIKUPLJAMO INFORMACIJE ?</h2>
      <p>
        Neki podaci su potrebni da bi naš sajt funkcionisao. Ostali podaci nisu
        neophodni, ali utiču na kvalitet vašeg korisničkog iskustva.
      </p>
      <p>
        Primera radi, ukoliko nam ne dostavite vašu e-mail adresu, nećemo biti u
        mogućnosti da vam otvorimo nalog na našem sajtu, a samim tim vaše
        korisničko iskustvo neće biti jednako onom koje imaju registrovani
        članovi. Podatak o e-mail adresi je neophodan za sve korisnike koji žele
        da koriste funkcije sajta prilagođene samo registrovanim korisnicima.
      </p>

      <h2>KAKO MOŽEMO DA KORISTIMO VAŠE PODATKE ?</h2>

      <p>
        Podatke koje možemo da prikupljamo, možemo da koristimo kako bismo vam
        pružili personalizovano iskustvo, uključujući oglase i sve druge svrhe u
        tom cilju.
      </p>
      <p>
        Naši sistemi mogu da koriste automatsku obradu informacija koje
        koristimo u ove svrhe. Međutim, u nekim slučajevima možemo da koristimo
        i ručnu proveru. Ručnom proverom kontrolišemo i promovišemo bezbednost i
        sigurnost, tako što ručno možemo da uklonimo svaki sadržaj za koji
        smatramo da nije u skladu sa osnovnim načelima naše poslovne politike.
        Identično, ručnom proverom možemo da analiziramo načine korišćenja našeg
        sajta od strane korisnika, kako bismo obučili naše algoritme da budu što
        približniji ličnoj upotrebi od strane ljudi. To je način na koji želimo
        da poboljšamo vaše korisničko iskustvo.
      </p>
      <p>
        U cilju korišćenja manjeg broja informacija, koje su povezane sa
        korisnikom na individualnom nivou, u nekim slučajevima možemo da
        deidentifikujemo, identifikujemo, ili objedinjujemo informacije u cilju
        obezbeđivanja personalizovanja naše ponude. Možemo da koristimo
        informacije koje imamo zarad pružanja i poboljšanja naših usluga.
      </p>

      <h2>KAKO MOŽEMO ŠTITIMO I PROMOVIŠEMO BEZBEDNOST I SIGURNOST ?</h2>

      <p>
        Vodimo računa o tome da potvrdimo naloge i aktivnosti, kao i da
        pronađemo i otklonimo slučajeve za koje utvrdimo da krše našu poslovnu
        politiku i uslove korišćenja naše platforme. Istražujemo sumnjive
        aktivnosti i borimo se protiv štetnog i nezakonitog ponašanja.
        Identifikujemo i reagujemo ukoliko primetimo ponašanje koje krši
        elementarna ljudska prava, poput sadržaja koji diskriminiše nejednakosti
        ili nije prilagođen adekvatnom uzrastu, poput recimo promovisanja
        nasilničkog ponašanja, seksualnih aktivnosti i slično. Ukoliko primetimo
        takav sadržaj, reagujemo adekvatno i u skladu sa našim mogućnostima.
      </p>
      <h2>KAKO KOMUNICIRAMO SA KORISNICIMA ?</h2>

      <p>
        Šaljemo vam e-mail poruke o našim uslugama i akcijama, kako bismo vas
        informisali o istima, ukoliko ste registrovani korisnik na betkotip.com
        ili na betko aplikaciji. Takođe, istim putem vam dostavljamo potrebnu
        informaciju o tome kako možete da isključite prijem e-mail obaveštenja
        bilo kog tipa, jer naš cilj nije uznemiravanje korisnika, već
        informisanje istih. U slučaju da smatrate da vas naša obaveštenja
        uznemiravaju, postupite prateći uputstva i isključite prijem istih.
      </p>
      <p>
        Kada nam se obratite sa pitanjima, odgovaramo na ista putem elektronske
        pošte, a opciono i putem chata ''PITAJ BETKA''. U svakom trenutku možete
        da nam se obratite po bilo kom pitanju, i mi ćemo gledati da vam
        odgovorimo na ista u najkraćem mogućem roku.
      </p>

      <p>
        Takođe, možemo da koristimo i informacije o tome kakva je vaša reakcija
        na naše poruke. Primera radi, ukoliko otvorite elektronsku poštu koju
        dobijete od nas, vaša reakcija će nam pomoći da na najbolji mogući način
        razumemo vaše, i potrebe većeg broja korisnika, izvedeno na osnovu
        analitike po tom pitanju.
      </p>

      <h2>ISTRAŽIVANJA U CILJU DRUŠTVENOG DOBROG ?</h2>
      <p>
        U cilju društvenih dobara možemo da sprovodimo podršku istraživanjima u
        cilju istog. Cilj i smer naše platforme jeste doprinos društvenom dobru
        u područjima od javnog interesa istog. Možemo da istražujemo između
        ostalog, načine za poboljšanje bezbednosti, kao i mentalnog zdravlja i
        blagostanja. Možemo da sarađujemo sa nezavisnim organizacijama, kako
        bismo bolje razumeli potrebe društva, kao i sa akademskim građanima, i
        sa stručnjacima u raznim oblastima, kako bismo bolje razumeli potrebe
        društva i kako bismo kroz naše delovanje pozitivno odgovorili na
        iste.Podržavamo istraživanja u oblastima kao što su veštaćka
        inteligencija i mašinsko učenje.
      </p>

      <h2>VAŠA MEĐUSOBNA KOMUNIKACIJA SA DRUGIM KORISNICIMA ?</h2>

      <p>
        U situacijama kada delite sadržaj kroz komentarisanje na našoj
        platformi, posetioci sajta mogu videti vaše objave, dok registrovani
        korisnici sajta mogu i odgovoriti na iste, ili i sami
        komentarisati.Takođe, kao registrovani korisnik imate mogućnost
        ocenjivanja komentara drugih korisnika, kao i svog, te obeležavanje toga
        da li vam se neki komentar sviđa ili ne. Administracija betkotip.com u
        cilju razvoja i pravilnog usmerenja, te edukacije zajednice posetilaca
        ili aktivnih korisnika naše platforme, zadržava pravo na neograničeno
        lajkovanje komentara i odgovora na iste.
      </p>
      <h2>KAKO MOŽEMO DA DELIMO PODATKE SA TREĆIM LICIMA ?</h2>
      <p>
        Vaše informacije ne prodajemo i ne dajemo trećim licima, i to nikada
        nećemo raditi.
      </p>
      <h2>VAŠA SAGLASNOST</h2>

      <p>
        Korišćenjem naše BETKOTIP.COM pristajete na našu Politiku privatnosti i
        slažete se sa njenim Uslovima i odredbama, kao i sa opštim uslovima
        korišćenja sajta BETKOTIP.COM
      </p>
      <h2>KONTAKT</h2>

      <p>
        Za sve nejasnoće, dodatne informacije ili upite po bilo kom osnovu,
        možete u svakom trenutku kontaktirati našu korisničku podršku, putem
        elektonske pošte, elektronskom porukom koju možete dostaviti na našu
        e-mail adresu :
      </p>

      <p>betko.podrska@gmail.com</p>
    </div>
  )
}

export default PolitikaPrivatnosti
