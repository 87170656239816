import { useContext } from 'react'
import axios from '../config/axios'
import AuthContext from '../context/AuthProvider'

const useRefreshToken = () => {
  const { setAuth } = useContext(AuthContext)

  const refresh = async () => {
    const response = await axios.get('/refresh', {
      withCredentials: true,
    })
    // console.log("response: ", response);
    setAuth((prev) => {
      return {
        ...prev,
        roles: response.data.roles,
        accessToken: response.data.accessToken,
        paidToDate: new Date(response.data.paidToDate).getTime(),
        email: response.data.email,
        _id: response.data._id,
        commentRestricted: response.data.commentRestricted,
        status: response.data.status,
        username: response.data.username,
        emailNotification: response.data.emailNotification,
        commentNotification: response.data.commentNotification,
        moderator: response.data.moderator,
        emailNotificationPosts: response.data.emailNotificationPosts,
        isMisterTipster: response?.data?.isMisterTipster,
        accessAdminShop: response?.data?.accessAdminShop,
        name: response?.data?.name,
        address: response?.data?.address,
        place: response?.data?.place,
        zipCode: response?.data?.zipCode,
        phoneNumber: response?.data?.phoneNumber,
        misterTipsterNotification: response?.data?.misterTipsterNotification,
      }
    })
    return response.data.accessToken
    // return response.data;
  }

  return refresh
}

export default useRefreshToken
