import Grupa1 from "../components/Grupa1";
import Grupa216 from "../components/Grupa216";
import classes from "./procenaTacnogBrojaGolova.module.css";

const ProcenaTacnogBrojaGolova = () => {
  return (
    <div className={classes.container}>
      <Grupa1
        imgText="PROCENA TAČNOG BROJA GOLOVA"
        imgBackgroundColorDark="#DD3D3D"
        imgBackgroundColorLight="#F64242"
        imgFontColor="#FFFFFF"
        label1="VREME POČETKA"
        label2="ZEMLJA"
        label3="DOMAĆIN"
        label4="TAKMIČENJE - LIGA"
        label5="GOST"
      />
      <Grupa216
        imgBackgroundColorDark="#DD3D3D"
        imgBackgroundColorLight="#F64242"
        imgFontColor="#FFFFFF"
        label1="-"
        label2="-"
        label3="-"
        label4="-"
        label5="-"
        label6="-"
        label7="-"
        label8="-"
        label9="-"
        label10="-"
        label11="-"
        label12="-"
        label13="-"
        label14="-"
        label15="-"
        label16="-"
        linkOpisKalkulatora="/bet_kalkulatori_tutorijal/645b16a1cd9c8da743a1982d"
      />
    </div>
  );
};

export default ProcenaTacnogBrojaGolova;
