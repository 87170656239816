import classes from "./grupa20.module.css";
import Axios from "axios";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
const SERVER_URL = process.env.REACT_APP_SERVER_URL;

const Grupa20 = ({
  imgBackgroundColorDark,
  imgBackgroundColorLight,
  imgFontColor,
  calculatorType,
  label1,
}) => {
  const navigate = useNavigate();
  let [tutorialLink, setTutorialLink] = useState("");

  const handlePitaj = () => {
    window.open("https://m.me/betkobet1x2", "_blank");
  };

  useEffect(() => {
    fetchLink();
  }, []);

  const fetchLink = async () => {
    try {
      const tutorial = await Axios.get(
        `${SERVER_URL}/tutorial-calcname?name=${calculatorType}`
      );

      if (tutorial && tutorial.data && tutorial.data._id) {
        setTutorialLink(tutorial.data._id);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleOpiskalkulatora = () => {
    window.open(`../../bet_kalkulatori_tutorijal/${tutorialLink}`, "_blank");
  };
  return (
    <div className={classes.container}>
      <div
        style={{
          background: `linear-gradient(${imgBackgroundColorDark}, ${imgBackgroundColorLight})`,
          color: imgFontColor,
          display: "flex",
          fontWeight: "bolder",
        }}
      >
        <div className={classes.left}>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <div
              style={{
                width: "313px",
                flexDirection: "row",
                display: "flex",
                textAlign: "center",
                color: "darkblue",
                overflow: "hidden",
                whiteSpace: "nowrap",
                height: "100%",
              }}
            >
              <div
                style={{
                  width: "120px",
                  display: "flex",
                  flexDirection: "column",
                  textAlign: "center",
                  color: "darkblue",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                  height: "90px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    textAlign: "center",
                    color: "darkblue",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    height: "30px",
                  }}
                >
                  <p style={{ marginTop: "5px" }}> FIX</p>
                </div>
                <div
                  style={{
                    width: "120px",
                    display: "flex",
                    flexDirection: "column",
                    textAlign: "center",
                    color: "darkblue",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    height: "60px",
                    background: "#63BE7B",
                  }}
                >
                  <p style={{ marginTop: "19px" }}>asd</p>
                </div>
              </div>

              <div
                className={classes.rotateP}
                style={{
                  width: "72px",
                  display: "flex",
                  textAlign: "center",
                  color: "darkblue",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                  height: "90px",
                }}
              >
                <p
                  style={{
                    transform: "revert(-90deg)",
                    transformOrigin: "right bottom",
                    marginTop: "35px",
                    marginLeft: "10px",
                  }}
                >
                  MARŽE
                </p>
              </div>

              <div
                style={{
                  width: "120px",
                  display: "flex",
                  flexDirection: "column",
                  textAlign: "center",
                  color: "darkblue",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                  height: "90px",
                }}
              >
                <div
                  style={{
                    width: "120px",
                    display: "flex",
                    flexDirection: "column",
                    textAlign: "center",
                    color: "darkblue",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    height: "30px",
                  }}
                >
                  <p style={{ marginTop: "5px" }}>UG 0-2 / 3+</p>
                </div>
                <div
                  style={{
                    width: "120px",
                    display: "flex",
                    flexDirection: "column",
                    textAlign: "center",
                    color: "darkblue",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    height: "60px",
                    background: "#F8696B",
                  }}
                >
                  <p style={{ marginTop: "19px" }}>asd</p>
                </div>
              </div>
            </div>

            <div
              style={{
                width: "457px",
                flexDirection: "row",
                display: "flex",
                textAlign: "center",
                color: "darkblue",
                overflow: "hidden",
                whiteSpace: "nowrap",
                height: "100%",
              }}
            >
              <div
                style={{
                  width: "228.5px",
                  flexDirection: "row",
                  display: "flex",
                  textAlign: "center",
                  color: "darkblue",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                  height: "100%",
                }}
              >
                <p style={{ fontSize: "30px", marginLeft: "40px" }}>
                  % NA FIKS
                </p>
              </div>

              <div
                style={{
                  width: "228.5px",
                  flexDirection: "column",
                  display: "flex",
                  textAlign: "center",
                  color: "darkblue",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                  height: "100%",
                }}
              >
                <div
                  style={{
                    width: "228.5px",
                    flexDirection: "row",
                    display: "flex",
                    textAlign: "center",
                    color: "darkblue",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    height: "30px",
                  }}
                >
                  <div
                    style={{
                      width: "76px",
                      display: "flex",
                      textAlign: "center",
                      color: "darkblue",
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                      height: "100%",
                      background: "#91FB86",
                      border: "1px solid black",
                    }}
                  >
                    <p
                      style={{
                        marginTop: "5px",
                        marginLeft: "25px",
                        color: "black",
                      }}
                    >
                      asd
                    </p>
                  </div>

                  <div
                    style={{
                      width: "76px",
                      display: "flex",
                      textAlign: "center",
                      color: "darkblue",
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                      height: "100%",
                      background: "#91FB86",
                      border: "1px solid black",
                      borderLeft: "none",
                    }}
                  >
                    <p
                      style={{
                        marginTop: "5px",
                        marginLeft: "25px",
                        color: "black",
                      }}
                    >
                      asd
                    </p>
                  </div>

                  <div
                    style={{
                      width: "76px",
                      display: "flex",
                      textAlign: "center",
                      color: "darkblue",
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                      height: "100%",
                      background: "#91FB86",
                      border: "1px solid black",
                      borderLeft: "none",
                    }}
                  >
                    <p
                      style={{
                        marginTop: "5px",
                        marginLeft: "25px",
                        color: "black",
                      }}
                    >
                      asd
                    </p>
                  </div>
                </div>
                <div
                  style={{
                    width: "228.5px",
                    flexDirection: "row",
                    display: "flex",
                    textAlign: "center",
                    color: "darkblue",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    height: "60px",
                  }}
                >
                  <div
                    style={{
                      width: "76px",
                      display: "flex",
                      textAlign: "center",
                      color: "darkblue",
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                      height: "100%",
                      border: "1px solid black",
                      background: "#63BE7B",
                    }}
                  >
                    <p style={{ marginLeft: "22px", color: "black" }}>asd</p>
                  </div>

                  <div
                    style={{
                      width: "76px",
                      display: "flex",
                      textAlign: "center",
                      color: "darkblue",
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                      height: "100%",
                      border: "1px solid black",
                      borderLeft: "none",
                      background: "#FFEB84",
                    }}
                  >
                    <p style={{ marginLeft: "22px", color: "black" }}>asd</p>
                  </div>

                  <div
                    style={{
                      width: "76px",
                      display: "flex",
                      textAlign: "center",
                      color: "darkblue",
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                      height: "100%",
                      border: "1px solid black",
                      borderLeft: "none",
                      background: "#F8696B",
                    }}
                  >
                    <p style={{ marginLeft: "22px", color: "black" }}>asd</p>
                  </div>
                </div>
              </div>
            </div>

            <div
              style={{
                width: "330px",
                flexDirection: "row",
                display: "flex",
                textAlign: "center",
                color: "darkblue",
                overflow: "hidden",
                whiteSpace: "nowrap",
                height: "100%",
              }}
            >
              <div
                style={{
                  width: "178px",
                  flexDirection: "row",
                  display: "flex",
                  textAlign: "center",
                  color: "darkblue",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                  height: "100%",
                }}
              >
                <p style={{ fontSize: "30px", marginLeft: "15px" }}>
                  % NA FIKS
                </p>
              </div>

              <div
                style={{
                  width: "152px",
                  flexDirection: "column",
                  display: "flex",
                  textAlign: "center",
                  color: "darkblue",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                  height: "100%",
                }}
              >
                <div
                  style={{
                    width: "152px",
                    flexDirection: "row",
                    display: "flex",
                    textAlign: "center",
                    color: "darkblue",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    height: "30px",
                  }}
                >
                  <div
                    style={{
                      width: "76px",
                      display: "flex",
                      textAlign: "center",
                      color: "darkblue",
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                      height: "100%",
                      background: "#91FB86",
                      border: "1px solid black",
                    }}
                  >
                    <p
                      style={{
                        marginTop: "5px",
                        marginLeft: "25px",
                        color: "black",
                      }}
                    >
                      asd
                    </p>
                  </div>

                  <div
                    style={{
                      width: "76px",
                      display: "flex",
                      textAlign: "center",
                      color: "darkblue",
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                      height: "100%",
                      background: "#91FB86",
                      border: "1px solid black",
                      borderLeft: "none",
                    }}
                  >
                    <p
                      style={{
                        marginTop: "5px",
                        marginLeft: "25px",
                        color: "black",
                      }}
                    >
                      asd
                    </p>
                  </div>
                </div>
                <div
                  style={{
                    width: "152px",
                    flexDirection: "row",
                    display: "flex",
                    textAlign: "center",
                    color: "darkblue",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    height: "60px",
                  }}
                >
                  <div
                    style={{
                      width: "76px",
                      display: "flex",
                      textAlign: "center",
                      color: "darkblue",
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                      height: "100%",
                      background: "#63BE7B",
                      border: "1px solid black",
                    }}
                  >
                    <p
                      style={{
                        marginTop: "15px",
                        marginLeft: "25px",
                        color: "black",
                      }}
                    >
                      asd
                    </p>
                  </div>

                  <div
                    style={{
                      width: "76px",
                      display: "flex",
                      textAlign: "center",
                      color: "darkblue",
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                      height: "100%",
                      background: "#F8696B",
                      border: "1px solid black",
                      borderLeft: "none",
                    }}
                  >
                    <p
                      style={{
                        marginTop: "15px",
                        marginLeft: "25px",
                        color: "black",
                      }}
                    >
                      asd
                    </p>
                  </div>
                </div>
              </div>
            </div>

            {/*<div style={{width:"457px", display:"flex", textAlign:"center", color:"darkblue", overflow:"hidden", whiteSpace:"nowrap"}}>*/}
            {/*</div>*/}
            {/*<div style={{width:"300px", display:"flex",color:"darkblue", overflow:"hidden", whiteSpace:"nowrap"}}>*/}
            {/*</div>*/}
          </div>
        </div>
        <div className={classes.right} style={{ fontWeight: "normal" }}>
          <button
            onClick={() => handleOpiskalkulatora()}
            className={classes.btn1}
          >
            OPIS KALKULATORA
          </button>
          <button onClick={() => handlePitaj()} className={classes.btn2}>
            PITAJ
          </button>
        </div>
      </div>
    </div>
  );
};

export default Grupa20;
