import React from 'react'
import classes from './Pagination.module.css'

const TargetButtons = ({ data, onTarget, currentPage }) => {
  return (
    <div className={classes.targetButtonsContainer}>
      {data && data.length > 0 && data.map((btn, idx) => {
        return (
          <button className={`${currentPage === btn && classes.active}`} key={idx} onClick={() => onTarget(btn)}>
            {btn}
          </button>
        )
      })}
    </div>
  )
}

const Pagination = ({ setCurrentPage, currentPage, totalPages, buttonLimit }) => {

  const goNext = () => {
    setCurrentPage(prev => prev + 1)
  }
  const goBack = () => {
    setCurrentPage(prev => prev - 1)
  }
  const goTarget = (target) => {
    setCurrentPage(target)
  }

  const numbers = []
  for (let i = 1; i <= totalPages; i++) {
    numbers.push(i)
  }

  const getPaginationButton = () => {
    let numShown = buttonLimit;
    let start = Math.min(numShown, numbers.length);
    let first = currentPage - Math.floor(start / 2);
    first = Math.max(first, 1);
    first = Math.min(first, numbers.length - start + 1);

    return [...Array(start)].map((k, i) => i + first);
  };

  const paginationButton = getPaginationButton();

  const targetButtonProps = {
    onTarget: goTarget,
    data: paginationButton,
    currentPage: currentPage,
  }

  return (
    <div className={classes.pagination_container}>
      <div className={classes.back_button_container}>
        <button type='button' disabled={currentPage <= 1} onClick={goBack}>
          <img alt='left arrow' src='/left-arrow-red.svg' width={35} height={35} />
        </button>
      </div>

      <TargetButtons {...targetButtonProps} />

      <div className={classes.next_button_container}>
        <button type='button' disabled={currentPage >= totalPages} onClick={goNext}>
          <img alt='right arrow' src='/right-arrow.svg' width={35} height={35} />
        </button>
      </div>
    </div>
  )
}

export default Pagination
