import Grupa3 from "../components/Grupa3";
import Grupa20 from "../components/Grupa20";
import classes from "./procenaVrednostiTiketa.module.css";
import { useState } from "react";

const ProcenaVrednostiTiketa = () => {
  const [kvota, setKvota] = useState([
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
  ]);
  const [marza, setMarza] = useState(0);
  const [srednjaKvota, setSrednjaKvota] = useState("-");
  const [procenatSrednjeKvote, setProcenatSrednjeKvote] = useState("-");
  const [prosecanBrProlaznihParova, setProsecanBrProlaznihParova] =
    useState("-");
  const [rerender, setReRender] = useState(false);
  const [upisaKvote, setUpisaKvote] = useState(0);

  const handleChangeKvota = (e, index) => {
    let kvotaarr = kvota;

    kvotaarr[index] = e;

    setKvota(kvotaarr);
    setReRender(!rerender);
    handleCalculate();
  };

  const handleChangeMarza = (e) => {
    setMarza(e);
    setReRender(!rerender);
    handleCalculate();
  };

  const handleCalculate = () => {
    if (marza) {
      let v62 = parseFloat(marza);

      let z117 = 0;

      for (let i = 0; i < kvota.length; i++) {
        if (kvota[i]) {
          z117++;
        }
      }

      setUpisaKvote(z117);

      let p117 = z117;

      let n117 = 0;

      for (let i = 0; i < kvota.length; i++) {
        if (kvota[i]) {
          n117 = n117 + parseFloat(kvota[i]);
        }
      }

      let q117 = v62 ? v62 / 100 : 0;

      let q116 = parseFloat(p117 / n117);

      let q119 = q116 - q117;

      let procenatSrednjeKvoteLet = q119;

      let u74 = procenatSrednjeKvoteLet;

      let srednjakvotalet = 1 / u74;

      setProcenatSrednjeKvote((procenatSrednjeKvoteLet * 100).toFixed(2));

      setSrednjaKvota(srednjakvotalet.toFixed(2));

      let q122 = q119 * p117;

      setProsecanBrProlaznihParova(q122.toFixed(2));
    }
  };

  return (
    <div className={classes.container}>
      <Grupa3
        imgText="PROCENA VREDNOSTI TIKETA"
        imgBackgroundColorDark="#FF0000"
        imgBackgroundColorLight="#FF0000"
        imgFontColor="#000000"
      />
      <Grupa20
        imgBackgroundColorDark="#FF0000"
        imgBackgroundColorLight="#FF0000"
        imgFontColor="#000000"
        calculatorType={`Procena vrednosti tiketa`}
      />

      <div className={classes.ceokalkulator}>
        <div className={classes.forma}>
          <div className={classes.prvired}>
            <div
              className={classes.row}
              style={{ marginBottom: "10px", marginTop: "10px" }}
            >
              <div
                style={{
                  width: "73px",
                  height: "20px",
                  border: "3px solid red",
                  borderRight: "5px solid red",
                  borderLeft: "none",
                  background: "black",
                  fontWeight: "bolder",
                  fontSize: "18px",
                }}
              >
                Par br.
              </div>
              <div
                style={{
                  width: "140px",
                  height: "20px",
                  border: "3px solid red",
                  background: "black",
                  fontWeight: "bolder",
                  fontSize: "18px",
                  color: "white",
                  textAlign: "center",
                }}
              >
                Domaćin
              </div>
              <div
                style={{
                  width: "140px",
                  height: "20px",
                  border: "3px solid red",
                  background: "black",
                  fontWeight: "bolder",
                  fontSize: "18px",
                  color: "white",
                  textAlign: "center",
                }}
              >
                Gost
              </div>
              <div
                style={{
                  width: "140px",
                  height: "20px",
                  border: "3px solid red",
                  background: "black",
                  fontWeight: "bolder",
                  fontSize: "18px",
                  color: "white",
                  textAlign: "center",
                }}
              >
                Zemlja
              </div>
              <div
                style={{
                  width: "140px",
                  height: "20px",
                  border: "3px solid red",
                  background: "black",
                  fontWeight: "bolder",
                  fontSize: "18px",
                  color: "white",
                  textAlign: "center",
                }}
              >
                Liga
              </div>
              <div
                style={{
                  width: "140px",
                  height: "20px",
                  border: "3px solid red",
                  background: "black",
                  fontWeight: "bolder",
                  fontSize: "18px",
                  color: "white",
                  textAlign: "center",
                }}
              >
                Igra
              </div>
              <div
                style={{
                  width: "140px",
                  height: "20px",
                  border: "3px solid red",
                  background: "black",
                  fontWeight: "bolder",
                  fontSize: "18px",
                  color: "white",
                  textAlign: "center",
                }}
              >
                Kvota
              </div>
            </div>
          </div>

          {kvota &&
            kvota.map((item, index) => {
              return (
                <div className={classes.row}>
                  <div style={{ width: "70px", border: "3px solid black" }}>
                    {index + 1}
                  </div>
                  <div
                    style={{
                      width: "140px",
                      border: "3px solid black",
                      background: "whitesmoke",
                    }}
                  >
                    <input
                      style={{
                        width: "133px",
                        border: "none",
                        textAlign: "center",
                        fontWeight: "bolder",
                      }}
                      // className={classes.inputfieldprocenavrednosti}
                    ></input>
                  </div>
                  <div
                    style={{
                      width: "140px",
                      border: "3px solid black",
                      background: "whitesmoke",
                    }}
                  >
                    <input
                      style={{
                        width: "133px",
                        border: "none",
                        textAlign: "center",
                        fontWeight: "bolder",
                      }}
                      // className={classes.inputfieldprocenavrednosti}
                    ></input>
                  </div>
                  <div
                    style={{
                      width: "140px",
                      border: "3px solid black",
                      background: "whitesmoke",
                    }}
                  >
                    <input
                      style={{
                        width: "133px",
                        border: "none",
                        textAlign: "center",
                        fontWeight: "bolder",
                      }}
                      // className={classes.inputfieldprocenavrednosti}
                    ></input>
                  </div>
                  <div
                    style={{
                      width: "140px",
                      border: "3px solid black",
                      background: "whitesmoke",
                    }}
                  >
                    <input
                      style={{
                        width: "133px",
                        border: "none",
                        textAlign: "center",
                        fontWeight: "bolder",
                      }}
                      // className={classes.inputfieldprocenavrednosti}
                    ></input>
                  </div>
                  <div
                    style={{
                      width: "140px",
                      border: "3px solid black",
                      background: "whitesmoke",
                    }}
                  >
                    <input
                      style={{
                        width: "133px",
                        border: "none",
                        textAlign: "center",
                        fontWeight: "bolder",
                      }}
                      // className={classes.inputfieldprocenavrednosti}
                    ></input>
                  </div>
                  <div
                    style={{
                      width: "140px",
                      border: "3px solid black",
                      background: "whitesmoke",
                    }}
                  >
                    <input
                      style={{
                        width: "133px",
                        border: "none",
                        textAlign: "center",
                        fontWeight: "bolder",
                      }}
                      // className={classes.inputfieldprocenavrednosti}
                      type="number"
                      min="0"
                      onChange={(e) => {
                        handleChangeKvota(e.target.value, index);
                      }}
                      value={kvota[index]}
                    ></input>
                  </div>
                </div>
              );
            })}
        </div>

        <div className={classes.desnatabela}>
          <div className={classes.prvireddesno}>
            <p style={{ fontWeight: "bold", fontSize: "20px" }}>
              PRVI KORAK UNOSA - UNOS MARŽE (2.5% + )
            </p>
            <input
              className={classes.prvireddesnoitem}
              type="number"
              onChange={(e) => {
                handleChangeMarza(e.target.value);
              }}
              min="0"
              value={marza}
            ></input>
          </div>
          <div className={classes.divispod}>
            <div className={classes.header}>
              Obračun prosečne prolaznosti broja parova na tiketu
            </div>
            <div className={classes.label}>Srednja kvota</div>
            <div className={classes.label}>{srednjaKvota}</div>
            <div className={classes.label}>Procenat srednje kvote</div>
            <div className={classes.label}>
              {parseFloat(procenatSrednjeKvote).toFixed(2)}
              {procenatSrednjeKvote !== "-" ? "%" : ""}
            </div>
            <div className={classes.label}>Prosecan broj prolaznih parova</div>
            <div className={classes.label}>
              {prosecanBrProlaznihParova} / {upisaKvote}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProcenaVrednostiTiketa;
