const getUserName = (username) => {
  const parts = (username || '')?.split('@')

  if (!parts[1]) {
    return username
  }

  const userName = parts[0].slice(0, 3) + '...@' + parts[1]
  return userName
}

export default getUserName
