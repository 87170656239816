import { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import classes from "./adminUserSearchModule.module.css";

const AdminSearchModule = () => {
  const [userEmail, setUserEmail] = useState("");

  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const getSingleUser = async (userEmail) => {
    const { data } = await axiosPrivate.get(`/user/${userEmail}`);
    const userId = data.userId;
    navigate("/betko-admin/korisnik-detalji", { state: { userId } });
    return data.userId;
  };

  const { refetch } = useQuery(
    ["user", userEmail],
    () => getSingleUser(userEmail),
    {
      enabled: false,
    }
  );

  const handleSearch = () => {
    setUserEmail("");
    refetch();
  };

  return (
    <div className={classes.container}>
      <input
        className={classes.input}
        type="text"
        placeholder="Upiši email korisnika"
        onChange={(e) => setUserEmail(e.target.value)}
        value={userEmail}
      />
      <button onClick={handleSearch} className={classes.btn}>
        Pretraži
      </button>
    </div>
  );
};

export default AdminSearchModule;
