import { useState, useEffect } from "react";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import { useQuery } from "@tanstack/react-query";
import SinglePost from "../components/SinglePost";
import classes from "./betKursNovo.module.css";
import Spinner from "../components/Spinner";
import { useNavigate } from "react-router-dom";
import { IoStarSharp } from "react-icons/io5";

const BetKursNovo = () => {
  const [posts, setPosts] = useState([]);
  const [isMsg, setIsMsg] = useState(false);
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate()

  const [showPopUp, setShowPopUp] = useState(false)


  useEffect(() => {
    if (typeof window !== 'undefined') {
      const premiumPopUp = localStorage.getItem('PremiumPopUp')
      console.log(premiumPopUp)

      if (!premiumPopUp || premiumPopUp === 'false') {
        setShowPopUp(true)

        setTimeout(() => {
          setShowPopUp(false)
        }, 20000)
      }
    }
  }, [])

  const handleLocalStorage = (e) => {
    const { checked } = e.target

    localStorage.setItem('PremiumPopUp', checked)
  }

  const getAllBetKursNovoPosts = async () => {
    const { data } = await axiosPrivate.get("/getAllBetKursNovoPosts");
    data.msg ? setIsMsg(true) : setPosts(data.result);
    !data.msg ? setIsMsg(false) : setPosts([]);
    return data.result;
  };
  const allBetKursPostsQuery = useQuery({
    queryKey: ["betKursNovoPosts"],
    queryFn: getAllBetKursNovoPosts,
    staleTime: 0,
    refetchInterval: 1000 * 60 * 60 * 12,
  });

  return (
    <div className={classes.buttonContainer}>
      {allBetKursPostsQuery.isLoading || posts.length === 0 ? (
        <div className={classes.spinner}>
          <Spinner />
        </div>
      ) : (
        showPopUp ? (
          <div className={classes.popUpContainer}>
            <div>
              <div>
                <p>
                  UKOLIKO NEMATE PRETPLATNIČKI PAKET IZLAZOM IZ OVOG PROZORA POGLEDAJTE MENI "KORISNIČKI PAKETI" I ISPRATITE KORAKE DO OTKLJUČAVANJA SADRŽAJA PREDLOGA, KOJE MOŽETE VIDETI NA "BETKO SEMAFORIMA", KAKO BI VAM BILI DOSTUPNI PRE POČETKA UTAKMICA !
                </p>
              </div>

              <div>
                <button onClick={() => setShowPopUp(false)}>
                  <img src='/close-mini-icon.svg' width={35} height={35} />
                  <span>IZLAZ IZ PROZORA</span>
                </button>
              </div>

              <div>
                <div>
                  <p>UKOLIKO STE U PRETPLATI ZA "BETKO PAKETE" UPOZNAJTE SE SA SADRŽAJEM U OPISU "KARTICA" I UŽIVAJTE U NAŠIM</p>
                </div>

                <div className={classes.smallerClickAndSee}>
                  <button onClick={() => navigate('/bet_kurs_novo/6549642d3ca6f705bb3fc418')}>
                    <span><IoStarSharp style={{ fontSize: '20px' }} /></span>
                    <span>KLIKNI I POGLEDAJ - PREMIUM PREDLOZI</span>
                  </button>
                </div>
              </div>
            </div>

            <div className={classes.checkboxPopUpContainer}>
              <span>AKO STE SHVATILI FUNKCIJU "PREMIUM" MENIJA, OBELEŽITE DA VAM VIŠE NE ISKAČE OVAJ PROZOR</span>
              <div>
                <input type='checkbox' onChange={(e) => handleLocalStorage(e)} />
              </div>
            </div>
          </div>
        ) : (
          <div className={classes.newMenuContainer}>
            <div>
              <button onClick={() => navigate('/bet_kurs_novo/6549642d3ca6f705bb3fc418')}>
                <span><IoStarSharp style={{ fontSize: '40px' }} /></span>
                <span>KLIKNI I POGLEDAJ - PREMIUM PREDLOZI</span>
              </button>
            </div>
          </div>
        )
      )}

      <div className={classes.container}>
        {allBetKursPostsQuery.isLoading || posts.length === 0 ? (
          <div className={classes.spinner}>
            <Spinner />
          </div>
        ) : (
          <>
            {!isMsg ? (
              posts.map((post) => {
                return (
                  <SinglePost
                    commentNumbers={post?.commentsNumber}
                    key={post._id}
                    post={post}
                    postId={post._id}
                    type="betKursNovo"
                  />
                );
              })
            ) : (
              <div className={classes.msg}>
                <p>{allBetKursPostsQuery.data.msg}</p>
              </div>
            )}
          </>
        )}
      </div>
    </div >
  );
};

export default BetKursNovo;
