import React, { useEffect, useState } from 'react'
import { Form, Input, Button, message, Flex, notification } from 'antd'
import classes from './AdminTipsterAwards.module.css'
import axios from '../config/axios'
import useAxiosPrivate from '../hooks/useAxiosPrivate'
const SERVER_URL = process.env.REACT_APP_SERVER_URL

const AdminTipsterAwards = () => {
  const [refetchTrigger, setRefetchTrigger] = useState(false)
  const axiosPrivate = useAxiosPrivate()
  const [form] = Form.useForm()

  const handleCreateAwards = async (values, type) => {
    const data = {
      year: [values?.year],
      month: [values?.month],
      rank: [values?.rank],
    }
    try {
      await axios.post(`${SERVER_URL}/create-award`, data)
      type !== 'archive' && message.success('Uspešno ste kreirali nagrade!')
      setRefetchTrigger((curr) => !curr)
    } catch (err) {
      message.error('Greška pri kreiranju nagrada!')
    }
  }

  const handleArchiveAward = async (table, place) => {
    const formValues = form.getFieldsValue()[table]
    const reqBody = {
      user: formValues[place]?.user,
      description: formValues[place]?.description,
      award: formValues[`${place + 'Place'}`],
      period: formValues?.period,
      table: table,
      place: `${place}Place`,
    }
    try {
       await axiosPrivate.post(
        `${SERVER_URL}/create-user-awards`,
        reqBody
      )
      form.resetFields([[`${table}`, `${place + 'Place'}`]]);
      await handleCreateAwards(form.getFieldsValue(), 'archive')
      notification.success({
        message: 'Uspešno ste dodelili nagradu!',
        placement: 'topRight',
      })
    } catch (err) {
      notification.error({
        message:
          err?.response?.data?.message || 'Greška, molimo pokušajte ponovo!',
        placement: 'topRight',
      })
    }
  }

  useEffect(() => {
    const getAwards = async () => {
      form.resetFields()
      try {
        const response = await axios.get(`${SERVER_URL}/get-all-awards`)

        form.setFieldsValue({
          year: response?.data[0]?.year[0],
          month: response?.data[0]?.month[0],
          rank: response?.data[0]?.rank[0],
        })
      } catch (err) {
        message.error(
          err?.response?.data?.message || 'Greška pri preuzimanju nagrada!'
        )
      }
    }

    getAwards()
  }, [refetchTrigger])

  return (
    <div className={classes.tipsterPricesAdmin}>
      <Form onFinish={handleCreateAwards} layout='vertical' form={form}>
        <div className={classes.tableTop}>
          <h2>Mister Tipster Nagrade</h2>
          <Form.Item>
            <Button
              type='primary'
              htmlType='submit'
              size='large'
              className={classes.submitBtn}
            >
              Sačuvaj SVE AKTUELNE nagrade
            </Button>
          </Form.Item>
        </div>
        <span className={classes.userManual}>
          * Opis nagrade i dobitnika možete uneti samo neposredno pred
          dodeljivanje, prethodne promene neće biti sačuvane <br />
          * Pre dodele nagrade, ista mora biti prethodno sačuvana (naziv nagrade
          sa linkom i period) <br />
          * U polje dobitnik treba uneti email (obavezno registrovanog
          korisnika) <br />* Nakon što se nagrada dodeli, briše se iz aktuelnih
          nagrada
        </span>
        <div className={classes.tipsterPricesForm}>
          <div className={classes.row}>
            <h3>Godišnja tabela</h3>
            <div className={classes.awardFormRow}>
              <Form.Item name={['year', 'firstPlace']} label='Prvo mesto'>
                <Input />
              </Form.Item>
              <Form.Item name={['year', 'first', 'user']} label='Dobitnik'>
                <Input />
              </Form.Item>
              <Form.Item name={['year', 'first', 'description']} label='Opis'>
                <Input />
              </Form.Item>
            </div>
            <div className={classes.awardFormRow}>
              <Form.Item name={['year', 'secondPlace']} label='Drugo mesto'>
                <Input />
              </Form.Item>
              <Form.Item name={['year', 'second', 'user']} label='Dobitnik'>
                <Input />
              </Form.Item>
              <Form.Item name={['year', 'second', 'description']} label='Opis'>
                <Input />
              </Form.Item>
            </div>
            <div className={classes.awardFormRow}>
              <Form.Item name={['year', 'thirdPlace']} label='Treće mesto'>
                <Input />
              </Form.Item>
              <Form.Item name={['year', 'third', 'user']} label='Dobitnik'>
                <Input />
              </Form.Item>
              <Form.Item name={['year', 'third', 'description']} label='Opis'>
                <Input />
              </Form.Item>
            </div>
            <div className={classes.awardFormRow}>
              <Form.Item name={['year', 'period']} label='Period'>
                <Input />
              </Form.Item>
            </div>
            <div className={classes.archiveButtons}>
              <button
                type='button'
                onClick={() => handleArchiveAward('year', 'first')}
              >
                Dodeli PRVU GODIŠNJU nagradu
              </button>
              <button
                type='button'
                onClick={() => handleArchiveAward('year', 'second')}
              >
                Dodeli DRUGU GODIŠNJU nagradu
              </button>
              <button
                type='button'
                onClick={() => handleArchiveAward('year', 'third')}
              >
                Dodeli TREĆU GODIŠNJU nagradu
              </button>
            </div>
          </div>
          <div className={classes.row}>
            <h3>Mesečna tabela</h3>

            <div className={classes.awardFormRow}>
              <Form.Item name={['month', 'firstPlace']} label='Prvo mesto'>
                <Input />
              </Form.Item>
              <Form.Item name={['month', 'first', 'user']} label='Dobitnik'>
                <Input />
              </Form.Item>
              <Form.Item name={['month', 'first', 'description']} label='Opis'>
                <Input />
              </Form.Item>
            </div>

            <div className={classes.awardFormRow}>
              <Form.Item name={['month', 'secondPlace']} label='Drugo mesto'>
                <Input />
              </Form.Item>
              <Form.Item name={['month', 'second', 'user']} label='Dobitnik'>
                <Input />
              </Form.Item>
              <Form.Item name={['month', 'second', 'description']} label='Opis'>
                <Input />
              </Form.Item>
            </div>
            <div className={classes.awardFormRow}>
              <Form.Item name={['month', 'thirdPlace']} label='Treće mesto'>
                <Input />
              </Form.Item>
              <Form.Item name={['month', 'third', 'user']} label='Dobitnik'>
                <Input />
              </Form.Item>
              <Form.Item name={['month', 'third', 'description']} label='Opis'>
                <Input />
              </Form.Item>
            </div>
            <div className={classes.awardFormRow}>
              <Form.Item name={['month', 'period']} label='Period'>
                <Input />
              </Form.Item>
            </div>
            <div className={classes.archiveButtons}>
              <button
                type='button'
                onClick={() => handleArchiveAward('month', 'first')}
              >
                Dodeli PRVU MESEČNU nagradu
              </button>
              <button
                type='button'
                onClick={() => handleArchiveAward('month', 'second')}
              >
                Dodeli DRUGU MESEČNU nagradu
              </button>
              <button
                type='button'
                onClick={() => handleArchiveAward('month', 'third')}
              >
                Dodeli TREĆU MESEČNU nagradu
              </button>
            </div>
          </div>
          <div className={classes.row}>
            <h3>Tabela ranga</h3>

            <div className={classes.awardFormRow}>
              <Form.Item name={['rank', 'firstPlace']} label='Prvo mesto'>
                <Input />
              </Form.Item>
              <Form.Item name={['rank', 'first', 'user']} label='Dobitnik'>
                <Input />
              </Form.Item>
              <Form.Item name={['rank', 'first', 'description']} label='Opis'>
                <Input />
              </Form.Item>
            </div>
            <div className={classes.awardFormRow}>
              <Form.Item name={['rank', 'period']} label='Period'>
                <Input />
              </Form.Item>
            </div>
            <div className={classes.archiveButtons}>
              <button
                type='button'
                onClick={() => handleArchiveAward('rank', 'first')}
              >
                Dodeli PRVU RANG nagradu
              </button>
            </div>
          </div>
        </div>
      </Form>
    </div>
  )
}

export default AdminTipsterAwards
