import Grupa1 from "../components/Grupa1";
import Grupa20 from "../components/Grupa20";
import Grupa302 from "../components/Grupa302";
import classes from "./mma.module.css";
import { useState } from "react";

const Mma = () => {
  const [pobedaigraca1, setPobedaIgraca1] = useState(0);
  const [pobedaigraca2, setPobedaIgraca2] = useState(0);
  const [sansaZaPobeduBorca1, setSansaZaPobeduBorca1] = useState(0);
  const [sansaZaPobeduBorca2, setSansaZaPobeduBorca2] = useState(0);

  return (
    <div className={classes.container}>
      <Grupa1
        imgText="MMA"
        imgBackgroundColorDark="#14B32D"
        imgBackgroundColorLight="#18D418"
        imgFontColor="#000000"
        label1="VREME POČETKA"
        label2="ZEMLJA"
        label3="BORAC 1"
        label4="TAKMIČENJE - LIGA"
        label5="BORAC 2"
      />
      <Grupa20
        imgBackgroundColorDark="#14B32D"
        imgBackgroundColorLight="#18D418"
        imgFontColor="#000000"
        calculatorType={`Mma`}
      />
      <Grupa302
        pobedaigraca1={pobedaigraca1}
        pobedaigraca2={pobedaigraca2}
        setPobedaIgraca1={setPobedaIgraca1}
        setPobedaIgraca2={setPobedaIgraca2}
        sansaZaPobeduBorca1={sansaZaPobeduBorca1}
        sansaZaPobeduBorca2={sansaZaPobeduBorca2}
        setSansaZaPobeduBorca1={setSansaZaPobeduBorca1}
        setSansaZaPobeduBorca2={setSansaZaPobeduBorca2}
      />
      <div className={classes.calculator}>
        <p className={classes.prvi}>
          {isNaN(sansaZaPobeduBorca1) || sansaZaPobeduBorca1 == 0
            ? null
            : sansaZaPobeduBorca1}{" "}
          {isNaN(sansaZaPobeduBorca1) || sansaZaPobeduBorca1 == 0 ? null : "%"}
        </p>
        <p className={classes.drugi}>
          {isNaN(sansaZaPobeduBorca2) || sansaZaPobeduBorca2 == 0
            ? null
            : sansaZaPobeduBorca2}{" "}
          {isNaN(sansaZaPobeduBorca2) || sansaZaPobeduBorca2 == 0 ? null : "%"}
        </p>
      </div>
    </div>
  );
};

export default Mma;
