import { useContext } from "react";
import axios from "../config/axios";
import AuthContext from "../context/AuthProvider";

const useLogout = () => {
  const { setAuth } = useContext(AuthContext);

  const logout = async () => {
    setAuth({});
    try {
      await axios.get("/logout", {
        withCredentials: true,
      });
    } catch (error) {
      console.log(error);
    }
  };

  return logout;
};

export default useLogout;
