import { useNavigate, useLocation } from "react-router-dom";
import classes from "./adminBetkoInfoZid.module.css";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import { useState, useRef, useEffect } from "react";
import {
  Modal,
  notification,
  Input,
  InputNumber,
  Select,
  Button,
  Checkbox,
} from "antd";
import Axios from "axios";
import { DeleteOutlined } from "@ant-design/icons";
import svggore from "./assets/arrowup.svg";
import svgdole from "./assets/arrowdown.svg";

const { TextArea } = Input;
const { Option } = Select;

const AdminAddNewBetkoInfoZid = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [rerender, setReRender] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [data, setData] = useState([
    { title: "" },
    { title: "" },
    { title: "" },
  ]);
  const [images, setImages] = useState([]);
  const [videos, setVideos] = useState([]);
  const [filteredImages, setFilteredImages] = useState([]);
  const [featureImage, setFeatureImage] = useState();
  const [featureVideo, setFeatureVideo] = useState();
  const [featureTypes, setFeatureTypes] = useState([]);
  const [selectedIndex, setSelectedIndex] = useState("");
  const [isVisibleModal, setIsVisibleModal] = useState(false);
  const [isVisibleVideoModal, setIsVisibleVideoModal] = useState(false);
  const [isSaveButtonDisabled, setIsSaveButtonDisabled] = useState(false);

  const [title, setTitle] = useState('');

  const [itemsFirstArr, setItemsFirstArr] = useState();

  const [selectedFirstItemType, setSelectedFirstItemType] = useState();
  const [selectedSecondItemType, setSelectedSecondItemType] = useState();
  const [selectedThirdItemType, setSelectedThirdItemType] = useState();

  const [selectedFirstItem, setSelectedFirstItem] = useState();
  const [selectedSecondItem, setSelectedSecondItem] = useState();
  const [selectedThirdItem, setSelectedThirdItem] = useState();

  const [postType, setPostType] = useState('');

  const [link, setLink] = useState();

  const axiosPrivate = useAxiosPrivate();
  const SERVER_URL = process.env.REACT_APP_SERVER_URL;

  const handleSubmit = async () => {
    try {
      const formData = {
        title,
        selectedFirstItem,
        postType,
        featureImage,
        featureVideo
      };

      console.log('form data', formData);

      setIsSaveButtonDisabled(true);
      if (editMode) {
        await axiosPrivate.put(`${SERVER_URL}/betko-info-wall`, formData);
        notification.success({ message: "Uspešno izmenjena stranica." });
      } else {
        await axiosPrivate.post(`${SERVER_URL}/betko-info-wall`, formData);
        notification.success({ message: "Uspešno objavljena stranica." });
      }

      setTimeout(() => {
        window.location.reload();
      }, 300);
    } catch (error) {
      notification.error({ message: error });
    }
  };


  const fetchImages = async () => {
    try {
      const imagesArr = await Axios.get(`${SERVER_URL}/images/get-all`, {
        withCredentials: false,
      });

      setImages(imagesArr.data);
      setFilteredImages(imagesArr.data);
    } catch (error) {
      console.log("err", error);
    }
  };

  useEffect(() => {
    fetchImages();
    fetchVideos();
  }, []);

  const fetchVideos = async () => {
    try {
      const videosArr = await Axios.get(`${SERVER_URL}/videos/get-all`, {
        withCredentials: false,
      });

      setVideos(videosArr.data);
    } catch (error) {
      console.log("err", error);
    }
  };

  const handleShowModal = (e, index) => {
    if (e === "featureVideo") {
      setIsVisibleVideoModal(true);
      setSelectedIndex(index);
    } else {
      setIsVisibleModal(true);
      setSelectedIndex(index);
    }
  };

  const handleChangeTitle = (e) => {
    console.log('eee', e.target.value);
    setTitle(e.target.value)
  }

  // const handleChangeLink = (e) => {
  //   console.log('eee', e.target.value);
  //   setLink(e.target.value)
  // }

  const handleCancel = () => {
    setIsVisibleModal(false);
    setIsVisibleVideoModal(false);
  };

  const handleSelectImage = (image) => {
    let typesArr = featureTypes;

    setFeatureImage(image);

    console.log('Image', image);

    setFeatureTypes(typesArr);

    setIsVisibleModal(false);
  };

  const handleSelectVideo = (video) => {
    let typesArr = featureTypes;
    setFeatureVideo(video);

    // setFeatureTypes(typesArr);

    // setFeature(featureArr);

    setIsVisibleVideoModal(false);
  };

  const filterImages = (e) => {
    if (e) {
      let filtered = images.filter((item) => item.imageType === e);

      setFilteredImages(filtered);
    } else {
      setFilteredImages(images);
    }
  };

  const handleChangePost = async (e) => {
    try {
        let postsArr = [];
        if (e === "info_blok") {
            postsArr = await Axios.get(`${SERVER_URL}/getAllInfoBlokPosts`);
        } else if (e === "statistika") {
            postsArr = await Axios.get(`${SERVER_URL}/getAllStatistikaPosts`);
        } else if (e === "bet_portal") {
            postsArr = await Axios.get(`${SERVER_URL}/getAllBetPortalPosts`);
        } else if (e === "nasi_predlozi") {
            postsArr = await Axios.get(`${SERVER_URL}/getAllNasiPredloziPosts`);
        } else if (e === "bet_kurs") {
            postsArr = await Axios.get(`${SERVER_URL}/betcourse`);
        } else if (e === "bet_kurs_novo") {
            postsArr = await Axios.get(`${SERVER_URL}/getAllBetKursNovoPosts`);
        }

        setPostType(e)

        setItemsFirstArr(postsArr.data.result);
          
    } catch (error) {
        console.log('error', error);
    }
  }

  return (
    <div className={classes.container}>
      <h1 style={{ marginLeft: "10px" }}>Betko Info Zid</h1>

      <div className={classes.main}>
              <div className={classes.card}>
                <div className={classes.field}>
                  <p className={classes.titlePadding}>Slika/Video:</p>
                  {featureImage && featureImage.url && (
                    <img
                      style={{ width: "50px" }}
                      src={`${SERVER_URL}/${featureImage.url}`}
                    />
                  )}
                  <button
                    className={classes.addImageBtn}
                    onClick={() => {
                      handleShowModal("featureImage");
                    }}
                  >
                    {featureImage && featureImage.url
                      ? featureImage.url
                      : "Izaberite sliku"}
                  </button>
                  <button
                    className={classes.addImageBtn}
                    onClick={() => {
                      handleShowModal("featureVideo");
                    }}
                  >
                    {featureVideo && featureVideo.url
                      ? featureVideo.url
                      : "Izaberite video"}
                  </button>
                </div>
                <div className={classes.field}>
                  <p>Naslov:</p>
                  <input
                    onChange={(e) => {
                        handleChangeTitle(e)
                    }}
                    value={title}
                  ></input>
                </div>
                <div className={classes.field}>
                  <p>Izaberi post</p>
                  <Select
                    onChange={(e) => {
                        handleChangePost(e)
                    }}
                    value={
                        selectedFirstItemType
                    }
                  >
                    <Option value="info_blok">Info blok</Option>
                    <Option value="bet_portal">Bet portal</Option>
                    <Option value="statistika">Statistika</Option>
                    <Option value="bet_kurs">Bet kurs</Option>
                    <Option value="bet_kurs_novo">Premium</Option>
                    <Option value="nasi_predlozi">Vaši predlozi</Option>
                  </Select>

                  <Select
                    showSearch
                    filterOption={(input, option) =>
                      option.props.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    onChange={(e) => {
                      console.log('eeeeeeeeee', e);
                        setSelectedFirstItem(e);    
                    }}
                    value={
                        selectedFirstItem
                    }
                  >
                    {
                      itemsFirstArr &&
                      itemsFirstArr.length > 0 &&
                      itemsFirstArr.map((item, index) => {
                        return <Option value={item._id}>{item.title}</Option>;
                      })}
                  </Select>
                </div>
              </div>
      </div>

      <Modal
        width={"80%"}
        footer={[]}
        title="Kliknite sliku da biste je odabrali"
        visible={isVisibleModal}
        onCancel={handleCancel}
      >
        <div>
          <Select
            style={{ minWidth: "200px" }}
            onChange={(e) => {
              filterImages(e);
            }}
            defaultValue={""}
          >
            <Option value="">Sve</Option>
            <Option value="home">Početna strana</Option>
            <Option value="gallery">Gallery</Option>
          </Select>
        </div>
        <div className={classes.imagesList}>
          {filteredImages &&
            filteredImages.length > 0 &&
            filteredImages.map((item, index) => {
              return (
                <div className={classes.imageItem}>
                  <img
                    onClick={() => {
                      handleSelectImage(item);
                    }}
                    className="img"
                    src={`${SERVER_URL}/${item.url}`}
                    alt={item.url}
                  />
                </div>
              );
            })}
        </div>
      </Modal>
      <Modal
        width={"80%"}
        footer={[]}
        title="VIDEO GALERIJA"
        visible={isVisibleVideoModal}
        onCancel={handleCancel}
      >
        <div className={classes.videosList}>
          {videos &&
            videos.length > 0 &&
            videos.map((item, index) => {
              return (
                <div className={classes.videoItem}>
                  <video width="320" height="240" controls>
                    <source
                      src={`${SERVER_URL}/${item.url}`}
                      type="video/mp4"
                    />
                  </video>
                  <Button
                    onClick={() => {
                      handleSelectVideo(item);
                    }}
                    className={classes.selectVideoButton}
                  >
                    Izaberite video
                  </Button>
                </div>
              );
            })}
        </div>
      </Modal>

      <div className={classes.statistikaFooter}>
        <button
          className={classes.submitButton}
          onClick={() => {
            handleSubmit();
          }}
          disabled={isSaveButtonDisabled}
        >
          Sačuvaj
        </button>
      </div>
    </div>
  );
};

export default AdminAddNewBetkoInfoZid;
