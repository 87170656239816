import classes from "./adminAddNewCourseChapter.module.css";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import { useState } from "react";
import { notification } from "antd";

const AdminAddNewCourseChapter = () => {
  const [title, setTitle] = useState();
  const [isSaveButtonDisabled, setIsSaveButtonDisabled] = useState(false);
  const axiosPrivate = useAxiosPrivate();
  const SERVER_URL = process.env.REACT_APP_SERVER_URL;

  const handleSubmit = async () => {
    try {
      if (!title) {
        notification.error({ message: "Unesite naslov poglavlja!" });
        return;
      }

      setIsSaveButtonDisabled(true);

      await axiosPrivate.post(`${SERVER_URL}/betcoursechapter`, {
        title: title,
      });

      notification.success({ message: "Uspešno objavljeno poglavlje." });

      setTimeout(() => {
        window.location.reload();
      }, 300);
    } catch (error) {
      console.log(error);
      notification.error({ message: error });
    }
  };

  return (
    <div className={classes.container}>
      <h1 style={{ marginLeft: "10px" }}>Novo poglavlje bet kursa</h1>
      <div className={classes.main}>
        <p>Naslov:</p>
        <input
          value={title}
          onChange={(e) => {
            setTitle(e.target.value);
          }}
        ></input>
      </div>
      <div className={classes.statistikaFooter}>
        <button
          className={classes.submitButton}
          disabled={isSaveButtonDisabled}
          onClick={() => {
            handleSubmit();
          }}
        >
          Sačuvaj
        </button>
      </div>
    </div>
  );
};

export default AdminAddNewCourseChapter;
