import classes from "./grupa302.module.css";
import { useEffect } from "react";

const Grupa302 = ({
  pobedaigraca1,
  pobedaigraca2,
  setPobedaIgraca1,
  setPobedaIgraca2,
  setSansaZaPobeduBorca1,
  setSansaZaPobeduBorca2,
}) => {
  useEffect(() => {
    handleCalculate();
  }, [pobedaigraca1, pobedaigraca2]);

  const handleChangePobedaIgraca1 = (e) => {
    setPobedaIgraca1(e.target.value);
  };

  const handleChangePobedaIgraca2 = (e) => {
    setPobedaIgraca2(e.target.value);
  };

  const handleCalculate = () => {
    if (pobedaigraca1 && pobedaigraca2) {
      const procenat1 = (1 / pobedaigraca1) * 100;
      const procenat2 = (1 / pobedaigraca2) * 100;

      const procenatUkupno = procenat1 + procenat2;

      const procenatIznad100Procenata = procenatUkupno - 100;

      const polaOdProcentaIznad = procenatIznad100Procenata / 2;

      const sansaPobede1Borca = procenat1 - polaOdProcentaIznad;

      const sansaPobede2Borca = procenat2 - polaOdProcentaIznad;

      setSansaZaPobeduBorca1(sansaPobede1Borca.toFixed(2));
      setSansaZaPobeduBorca2(sansaPobede2Borca.toFixed(2));
    }
  };

  return (
    <div className={classes.container}>
      <input
        className={classes.blackCenteredinput}
        placeholder="pobeda borca 1"
        type="number"
        min="0"
        onChange={(e) => {
          handleChangePobedaIgraca1(e);
        }}
      />
      <input
        className={classes.blackCenteredinput}
        placeholder="pobeda borca 2"
        type="number"
        min="0"
        onChange={(e) => {
          handleChangePobedaIgraca2(e);
        }}
      />
    </div>
  );
};

export default Grupa302;
