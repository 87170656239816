import React, { createContext, useState, useEffect, useMemo } from 'react'
import axios from 'axios'

const CartContext = createContext()
const SERVER_URL = process.env.REACT_APP_SERVER_URL;

export const CartProvider = ({ children }) => {
  const [cart, setCart] = useState([])
  const [cartWithData, setCartWithData] = useState([])

  // Load cart from localStorage on initial load
  useEffect(() => {
    const storedCart = JSON.parse(localStorage.getItem('cart')) || []
    if (storedCart.length > 0) {
      setCart(storedCart)
    }
  }, [])

  // Store cart in localStorage whenever it changes, only if cart is not empty
  useEffect(() => {
    if (cart.length > 0) {
      localStorage.setItem('cart', JSON.stringify(cart))
    } else {
      localStorage.removeItem('cart') // Optional: clear localStorage when cart is empty
    }
  }, [cart])

  useEffect(() => {
    const updateCartWithData = async () => {
      try {
        const promises = cart.map(async (item) => {
          try {
            const product = await axios.get(
              `${SERVER_URL}/get-product-by-id/${item?.id}`
            )
            return { ...product.data, quantity: item?.quantity, atrDesc: item?.atrDesc }
          } catch (error) {
            console.error(`Error fetching product with ID ${item?.id}:`, error)
            // Don't return anything in case of an error
            return null
          }
        })
        
        const results = await Promise.all(promises)
        const updatedCartWithData = results.filter((item) => item !== null) // Remove null values
        setCartWithData(updatedCartWithData)
      } catch (error) {
        console.error('Error in updating cart:', error)
      }
    }
  
    if (cart.length > 0) {
      updateCartWithData()
    } else {
      setCartWithData([]) // Clear cartWithData if the cart is empty
    }
  }, [cart])
  
  
  const addToCart = (productId, quantity, atrDesc) => {
    setCart((prevCart) => {
      const existingProduct = prevCart.find((item) => item.id === productId);
      if (existingProduct) {
        return prevCart.map((item) =>
          item.id === productId
            ? { ...item, quantity, atrDesc }
            : item
        );
      } else {
        return [...prevCart, { id: productId, quantity, atrDesc }];
      }
    });
  };
  
  const removeFromCart = (productId) => {
    setCart((prevCart) => {
      const existingProduct = prevCart.find((item) => item.id === productId)
      if (existingProduct) {
        if (existingProduct.quantity > 1) {
          return prevCart.map((item) =>
            item.id === productId
              ? { ...item, quantity: item.quantity - 1 }
              : item
          )
        } else {
          return prevCart.filter((item) => item.id !== productId)
        }
      }
      return prevCart
    })
  }

  const resetCart = () => {
    setCart([]);
    setCartWithData([]);
    localStorage.setItem('cart',[]);
  };

  const totalAmount = useMemo(() => {
    const amount = cartWithData.reduce((total, item) => {
      return total + item.price * item.quantity;
    }, 0);
  
    // Format the number as currency
    return new Intl.NumberFormat('de-DE', {
      style: 'currency',
      currency: 'RSD', 
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(amount);
  }, [cartWithData]);

  return (
    <CartContext.Provider
      value={{ cart, cartWithData, addToCart, removeFromCart, totalAmount, resetCart }}
    >
      {children}
    </CartContext.Provider>
  )
}

export default CartContext
