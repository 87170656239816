import Grupa3 from "../components/Grupa3";
import Grupa20 from "../components/Grupa20";
import classes from "./sistemiUSistemu.module.css";

const SistemiUSistemu = () => {
  const rows = 29;
  const columns = 38;
  const limitedColumnsRows = [1];
  const limitForSelectedRows = 8;
  const arrayOfArrays = [];

  for (let i = 0; i < rows; i++) {
    const innerArray = [];

    const currentColumnsLimit = limitedColumnsRows.includes(i)
      ? limitForSelectedRows
      : columns;

    for (let j = 0; j < currentColumnsLimit; j++) {
      innerArray.push(`${i}-${j}`);
    }

    arrayOfArrays.push(innerArray);
  }

  return (
    <div className={classes.container}>
      <Grupa3
        imgText="SISTEMI U SISTEMU"
        imgBackgroundColorDark="#052C83"
        imgBackgroundColorLight="#0D2869"
        imgFontColor="#00B0F0"
      />
      <Grupa20
        imgBackgroundColorDark="#052C83"
        imgBackgroundColorLight="#0D2869"
        imgFontColor="#00B0F0"
        calculatorType={`Sistemi u sistemu`}
      />
      <div className={classes.bottom}>
        <div className={classes.calculator}>
          <table border="1">
            <tbody>
              {arrayOfArrays.map((row, rowIndex) => (
                <tr style={{ display: "flex", width: "100%" }} key={rowIndex}>
                  {rowIndex === 0 ||
                  rowIndex === 1 ||
                  rowIndex === 8 ||
                  rowIndex === 17 ||
                  rowIndex === 28 ? (
                    <div style={{ width: "100%" }}>
                      {rowIndex === 0 ? (
                        <div style={{ display: "flex" }}>
                          <div
                            style={{
                              width: "5%",
                              textAlign: "center",
                              backgroundColor: "rgb(5, 44, 131",
                              color: "white",
                              border: "1px solid black",
                            }}
                          >
                            R.br.
                          </div>
                          <div
                            style={{
                              width: "5%",
                              textAlign: "center",
                              backgroundColor: "rgb(5, 44, 131",
                              color: "white",
                              border: "1px solid black",
                            }}
                          >
                            h
                          </div>
                          <div
                            style={{
                              width: "11%",
                              textAlign: "center",
                              backgroundColor: "rgb(5, 44, 131",
                              color: "white",
                              border: "1px solid black",
                            }}
                          >
                            DOMAĆIN
                          </div>
                          <div
                            style={{
                              width: "11%",
                              textAlign: "center",
                              backgroundColor: "rgb(5, 44, 131",
                              color: "white",
                              border: "1px solid black",
                            }}
                          >
                            GOST
                          </div>
                          <div
                            style={{
                              width: "5%",
                              textAlign: "center",
                              backgroundColor: "rgb(5, 44, 131",
                              color: "white",
                              border: "1px solid black",
                            }}
                          >
                            ZEMLJA
                          </div>
                          <div
                            style={{
                              width: "5%",
                              textAlign: "center",
                              backgroundColor: "rgb(5, 44, 131",
                              color: "white",
                              border: "1px solid black",
                            }}
                          >
                            IGRA
                          </div>
                          <div
                            style={{
                              width: "5%",
                              textAlign: "center",
                              backgroundColor: "rgb(5, 44, 131",
                              color: "white",
                              border: "1px solid black",
                            }}
                          >
                            KVOTA
                          </div>
                          <td
                            style={{
                              width: "1.5%",
                              backgroundColor: "rgb(5, 44, 131)",
                              color: "white",
                              borderColor: "black",
                            }}
                          >
                            1
                          </td>
                          <td
                            style={{
                              width: "1.5%",
                              backgroundColor: "rgb(5, 44, 131)",
                              color: "white",
                              borderColor: "black",
                            }}
                          >
                            2
                          </td>
                          <td
                            style={{
                              width: "1.5%",
                              backgroundColor: "rgb(5, 44, 131)",
                              color: "white",
                              borderColor: "black",
                            }}
                          >
                            3
                          </td>
                          <td
                            style={{
                              width: "1.5%",
                              backgroundColor: "rgb(5, 44, 131)",
                              color: "white",
                              borderColor: "black",
                            }}
                          >
                            4
                          </td>
                          <td
                            style={{
                              width: "1.5%",
                              backgroundColor: "rgb(5, 44, 131)",
                              color: "white",
                              borderColor: "black",
                            }}
                          >
                            5
                          </td>
                          <td
                            style={{
                              width: "1.5%",
                              backgroundColor: "rgb(5, 44, 131)",
                              color: "white",
                              borderColor: "black",
                            }}
                          >
                            6
                          </td>
                          <td
                            style={{
                              width: "1.5%",
                              backgroundColor: "rgb(5, 44, 131)",
                              color: "white",
                              borderColor: "black",
                            }}
                          >
                            7
                          </td>
                          <td
                            style={{
                              width: "1.5%",
                              backgroundColor: "rgb(5, 44, 131)",
                              color: "white",
                              borderColor: "black",
                            }}
                          >
                            8
                          </td>
                          <td
                            style={{
                              width: "1.5%",
                              backgroundColor: "rgb(5, 44, 131)",
                              color: "white",
                              borderColor: "black",
                            }}
                          >
                            9
                          </td>
                          <td
                            style={{
                              width: "1.5%",
                              backgroundColor: "rgb(5, 44, 131)",
                              color: "white",
                              borderColor: "black",
                            }}
                          >
                            10
                          </td>
                          <td
                            style={{
                              width: "1.5%",
                              backgroundColor: "rgb(5, 44, 131)",
                              color: "white",
                              borderColor: "black",
                            }}
                          >
                            11
                          </td>
                          <td
                            style={{
                              width: "1.5%",
                              backgroundColor: "rgb(5, 44, 131)",
                              color: "white",
                              borderColor: "black",
                            }}
                          >
                            12
                          </td>
                          <td
                            style={{
                              width: "1.5%",
                              backgroundColor: "rgb(5, 44, 131)",
                              color: "white",
                              borderColor: "black",
                            }}
                          >
                            13
                          </td>
                          <td
                            style={{
                              width: "1.5%",
                              backgroundColor: "rgb(5, 44, 131)",
                              color: "white",
                              borderColor: "black",
                            }}
                          >
                            14
                          </td>
                          <td
                            style={{
                              width: "1.5%",
                              backgroundColor: "rgb(5, 44, 131)",
                              color: "white",
                              borderColor: "black",
                            }}
                          >
                            15
                          </td>
                          <td
                            style={{
                              width: "1.5%",
                              backgroundColor: "rgb(5, 44, 131)",
                              color: "white",
                              borderColor: "black",
                            }}
                          >
                            16
                          </td>
                          <td
                            style={{
                              width: "1.5%",
                              backgroundColor: "rgb(5, 44, 131)",
                              color: "white",
                              borderColor: "black",
                            }}
                          >
                            17
                          </td>
                          <td
                            style={{
                              width: "1.5%",
                              backgroundColor: "rgb(5, 44, 131)",
                              color: "white",
                              borderColor: "black",
                            }}
                          >
                            18
                          </td>
                          <td
                            style={{
                              width: "1.5%",
                              backgroundColor: "rgb(5, 44, 131)",
                              color: "white",
                              borderColor: "black",
                            }}
                          >
                            19
                          </td>
                          <td
                            style={{
                              width: "1.5%",
                              backgroundColor: "rgb(5, 44, 131)",
                              color: "white",
                              borderColor: "black",
                            }}
                          >
                            20
                          </td>
                          <td
                            style={{
                              width: "1.5%",
                              backgroundColor: "rgb(5, 44, 131)",
                              color: "white",
                              borderColor: "black",
                            }}
                          >
                            21
                          </td>
                          <td
                            style={{
                              width: "1.5%",
                              backgroundColor: "rgb(5, 44, 131)",
                              color: "white",
                              borderColor: "black",
                            }}
                          >
                            22
                          </td>
                          <td
                            style={{
                              width: "1.5%",
                              backgroundColor: "rgb(5, 44, 131)",
                              color: "white",
                              borderColor: "black",
                            }}
                          >
                            23
                          </td>
                          <td
                            style={{
                              width: "1.5%",
                              backgroundColor: "rgb(5, 44, 131)",
                              color: "white",
                              borderColor: "black",
                            }}
                          >
                            24
                          </td>
                          <td
                            style={{
                              width: "1.5%",
                              backgroundColor: "rgb(5, 44, 131)",
                              color: "white",
                              borderColor: "black",
                            }}
                          >
                            25
                          </td>
                          <td
                            style={{
                              width: "1.5%",
                              backgroundColor: "rgb(5, 44, 131)",
                              color: "white",
                              borderColor: "black",
                            }}
                          >
                            26
                          </td>
                          <td
                            style={{
                              width: "1.5%",
                              backgroundColor: "rgb(5, 44, 131)",
                              color: "white",
                              borderColor: "black",
                            }}
                          >
                            27
                          </td>
                          <td
                            style={{
                              width: "1.5%",
                              backgroundColor: "rgb(5, 44, 131)",
                              color: "white",
                              borderColor: "black",
                            }}
                          >
                            28
                          </td>
                          <td
                            style={{
                              width: "1.5%",
                              backgroundColor: "rgb(5, 44, 131)",
                              color: "white",
                              borderColor: "black",
                            }}
                          >
                            29
                          </td>
                          <td
                            style={{
                              width: "1.5%",
                              backgroundColor: "rgb(5, 44, 131)",
                              color: "white",
                              borderColor: "black",
                            }}
                          >
                            30
                          </td>
                          <td
                            style={{
                              width: "1.5%",
                              backgroundColor: "rgb(5, 44, 131)",
                              color: "white",
                              borderColor: "black",
                            }}
                          >
                            31
                          </td>
                        </div>
                      ) : (
                        ""
                      )}
                      {rowIndex === 1 ? (
                        <div
                          style={{
                            width: "100%",
                            textAlign: "center",
                            backgroundColor: "rgb(5, 44, 131",
                            color: "white",
                            border: "1px solid black",
                            fontSize: "22px",
                          }}
                        >
                          SISTEM U SISTEMU - 2/3 + 2/3 UK.BR.PAROVA - 6
                        </div>
                      ) : (
                        ""
                      )}

                      {rowIndex === 8 ? (
                        <div
                          style={{
                            width: "100%",
                            textAlign: "center",
                            backgroundColor: "rgb(5, 44, 131",
                            color: "white",
                            border: "1px solid black",
                            fontSize: "22px",
                          }}
                        >
                          SISTEM U SISTEMU - 3/4 + 3/4 UK.BR.PAROVA - 8
                        </div>
                      ) : (
                        ""
                      )}

                      {rowIndex === 17 ? (
                        <div
                          style={{
                            width: "100%",
                            textAlign: "center",
                            backgroundColor: "rgb(5, 44, 131",
                            color: "white",
                            border: "1px solid black",
                            fontSize: "22px",
                          }}
                        >
                          SISTEM U SISTEMU - 4/5 + 4/5 UK.BR.PAROVA - 10
                        </div>
                      ) : (
                        ""
                      )}

                      {rowIndex === 28 ? (
                        <div
                          style={{
                            width: "100%",
                            textAlign: "center",
                            backgroundColor: "#00c157",
                            color: "black",
                            border: "1px solid black",
                            fontSize: "22px",
                          }}
                        >
                          ZBIRKU SISTEMA ĆEMO KONTINUIRANO NADOGRAĐIVATI UNUTAR
                          MENIJA 'PREMIUM'
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  ) : (
                    row.map((col, colIndex) => {
                      if (colIndex == 2 || colIndex == 3) {
                        return (
                          <div
                            style={{ width: "11%", border: "1px solid gray" }}
                          >
                            <input
                              style={{ width: "95%", border: "none" }}
                            ></input>
                          </div>
                        );
                      }
                      if (
                        colIndex == 0 ||
                        colIndex == 1 ||
                        colIndex == 4 ||
                        colIndex == 5 ||
                        colIndex == 6
                      ) {
                        return (
                          <div
                            style={{ width: "5%", border: "1px solid gray" }}
                          >
                            <input
                              style={{ width: "95%", border: "none" }}
                            ></input>
                          </div>
                        );
                      } else if (
                        rowIndex === 2 &&
                        (colIndex === 7 ||
                          colIndex === 8 ||
                          colIndex === 9 ||
                          colIndex === 13 ||
                          colIndex === 14 ||
                          colIndex === 15)
                      ) {
                        return (
                          <td
                            style={{
                              width: "1.5%",
                              backgroundColor: "#00ec2b",
                              color: "black",
                              textAlign: "center",
                              fontSize: "12px",
                              fontWeight: "bolder",
                            }}
                            key={colIndex}
                          >
                            &lt;
                          </td>
                        );
                      } else if (
                        rowIndex === 3 &&
                        (colIndex === 7 ||
                          colIndex === 8 ||
                          colIndex === 9 ||
                          colIndex === 10 ||
                          colIndex === 11 ||
                          colIndex === 12)
                      ) {
                        return (
                          <td
                            style={{
                              width: "1.5%",
                              backgroundColor: "#00ec2b",
                              color: "black",
                              textAlign: "center",
                              fontSize: "12px",
                              fontWeight: "bolder",
                            }}
                            key={colIndex}
                          >
                            &lt;
                          </td>
                        );
                      } else if (
                        rowIndex === 4 &&
                        (colIndex === 13 ||
                          colIndex === 14 ||
                          colIndex === 15 ||
                          colIndex === 12 ||
                          colIndex === 11 ||
                          colIndex === 10)
                      ) {
                        return (
                          <td
                            style={{
                              width: "1.5%",
                              backgroundColor: "#00ec2b",
                              color: "black",
                              textAlign: "center",
                              fontSize: "12px",
                              fontWeight: "bolder",
                            }}
                            key={colIndex}
                          >
                            &lt;
                          </td>
                        );
                      } else if (
                        rowIndex === 5 &&
                        (colIndex === 7 ||
                          colIndex === 8 ||
                          colIndex === 10 ||
                          colIndex === 11 ||
                          colIndex === 13 ||
                          colIndex === 14)
                      ) {
                        return (
                          <td
                            style={{
                              width: "1.5%",
                              backgroundColor: "#00ec2b",
                              color: "black",
                              textAlign: "center",
                              fontSize: "12px",
                              fontWeight: "bolder",
                            }}
                            key={colIndex}
                          >
                            &lt;
                          </td>
                        );
                      } else if (
                        rowIndex === 6 &&
                        (colIndex === 7 ||
                          colIndex === 9 ||
                          colIndex === 10 ||
                          colIndex === 12 ||
                          colIndex === 13 ||
                          colIndex === 15)
                      ) {
                        return (
                          <td
                            style={{
                              width: "1.5%",
                              backgroundColor: "#00ec2b",
                              color: "black",
                              textAlign: "center",
                              fontSize: "12px",
                              fontWeight: "bolder",
                            }}
                            key={colIndex}
                          >
                            &lt;
                          </td>
                        );
                      } else if (
                        rowIndex === 7 &&
                        (colIndex === 8 ||
                          colIndex === 9 ||
                          colIndex === 11 ||
                          colIndex === 12 ||
                          colIndex === 14 ||
                          colIndex === 15)
                      ) {
                        return (
                          <td
                            style={{
                              width: "1.5%",
                              backgroundColor: "#00ec2b",
                              color: "black",
                              textAlign: "center",
                              fontSize: "12px",
                              fontWeight: "bolder",
                            }}
                            key={colIndex}
                          >
                            &lt;
                          </td>
                        );
                      } else if (
                        rowIndex === 9 &&
                        (colIndex === 7 ||
                          colIndex === 8 ||
                          colIndex === 9 ||
                          colIndex === 10 ||
                          colIndex === 11 ||
                          colIndex === 12 ||
                          colIndex === 13 ||
                          colIndex === 14 ||
                          colIndex === 19 ||
                          colIndex === 20 ||
                          colIndex === 21 ||
                          colIndex === 22)
                      ) {
                        return (
                          <td
                            style={{
                              width: "1.5%",
                              backgroundColor: "#00ec2b",
                              color: "black",
                              textAlign: "center",
                              fontSize: "12px",
                              fontWeight: "bolder",
                            }}
                            key={colIndex}
                          >
                            &lt;
                          </td>
                        );
                      } else if (
                        rowIndex === 10 &&
                        (colIndex === 7 ||
                          colIndex === 8 ||
                          colIndex === 9 ||
                          colIndex === 10 ||
                          colIndex === 11 ||
                          colIndex === 12 ||
                          colIndex === 13 ||
                          colIndex === 14 ||
                          colIndex === 15 ||
                          colIndex === 16 ||
                          colIndex === 17 ||
                          colIndex === 18)
                      ) {
                        return (
                          <td
                            style={{
                              width: "1.5%",
                              backgroundColor: "#00ec2b",
                              color: "black",
                              textAlign: "center",
                              fontSize: "12px",
                              fontWeight: "bolder",
                            }}
                            key={colIndex}
                          >
                            &lt;
                          </td>
                        );
                      } else if (
                        rowIndex === 11 &&
                        (colIndex === 7 ||
                          colIndex === 8 ||
                          colIndex === 9 ||
                          colIndex === 10 ||
                          colIndex === 19 ||
                          colIndex === 20 ||
                          colIndex === 21 ||
                          colIndex === 22 ||
                          colIndex === 15 ||
                          colIndex === 16 ||
                          colIndex === 17 ||
                          colIndex === 18)
                      ) {
                        return (
                          <td
                            style={{
                              width: "1.5%",
                              backgroundColor: "#00ec2b",
                              color: "black",
                              textAlign: "center",
                              fontSize: "12px",
                              fontWeight: "bolder",
                            }}
                            key={colIndex}
                          >
                            &lt;
                          </td>
                        );
                      } else if (
                        rowIndex === 12 &&
                        (colIndex === 19 ||
                          colIndex === 20 ||
                          colIndex === 21 ||
                          colIndex === 22 ||
                          colIndex === 11 ||
                          colIndex === 12 ||
                          colIndex === 13 ||
                          colIndex === 14 ||
                          colIndex === 15 ||
                          colIndex === 16 ||
                          colIndex === 17 ||
                          colIndex === 18)
                      ) {
                        return (
                          <td
                            style={{
                              width: "1.5%",
                              backgroundColor: "#00ec2b",
                              color: "black",
                              textAlign: "center",
                              fontSize: "12px",
                              fontWeight: "bolder",
                            }}
                            key={colIndex}
                          >
                            &lt;
                          </td>
                        );
                      } else if (
                        rowIndex === 13 &&
                        (colIndex === 7 ||
                          colIndex === 8 ||
                          colIndex === 10 ||
                          colIndex === 11 ||
                          colIndex === 12 ||
                          colIndex === 14 ||
                          colIndex === 15 ||
                          colIndex === 16 ||
                          colIndex === 18 ||
                          colIndex === 19 ||
                          colIndex === 20 ||
                          colIndex === 22)
                      ) {
                        return (
                          <td
                            style={{
                              width: "1.5%",
                              backgroundColor: "#00ec2b",
                              color: "black",
                              textAlign: "center",
                              fontSize: "12px",
                              fontWeight: "bolder",
                            }}
                            key={colIndex}
                          >
                            &lt;
                          </td>
                        );
                      } else if (
                        rowIndex === 14 &&
                        (colIndex === 7 ||
                          colIndex === 8 ||
                          colIndex === 9 ||
                          colIndex === 11 ||
                          colIndex === 12 ||
                          colIndex === 13 ||
                          colIndex === 15 ||
                          colIndex === 16 ||
                          colIndex === 17 ||
                          colIndex === 19 ||
                          colIndex === 20 ||
                          colIndex === 21)
                      ) {
                        return (
                          <td
                            style={{
                              width: "1.5%",
                              backgroundColor: "#00ec2b",
                              color: "black",
                              textAlign: "center",
                              fontSize: "12px",
                              fontWeight: "bolder",
                            }}
                            key={colIndex}
                          >
                            &lt;
                          </td>
                        );
                      } else if (
                        rowIndex === 15 &&
                        (colIndex === 7 ||
                          colIndex === 9 ||
                          colIndex === 10 ||
                          colIndex === 11 ||
                          colIndex === 13 ||
                          colIndex === 14 ||
                          colIndex === 15 ||
                          colIndex === 17 ||
                          colIndex === 18 ||
                          colIndex === 19 ||
                          colIndex === 21 ||
                          colIndex === 22)
                      ) {
                        return (
                          <td
                            style={{
                              width: "1.5%",
                              backgroundColor: "#00ec2b",
                              color: "black",
                              textAlign: "center",
                              fontSize: "12px",
                              fontWeight: "bolder",
                            }}
                            key={colIndex}
                          >
                            &lt;
                          </td>
                        );
                      } else if (
                        rowIndex === 16 &&
                        (colIndex === 8 ||
                          colIndex === 9 ||
                          colIndex === 10 ||
                          colIndex === 12 ||
                          colIndex === 13 ||
                          colIndex === 14 ||
                          colIndex === 16 ||
                          colIndex === 17 ||
                          colIndex === 18 ||
                          colIndex === 20 ||
                          colIndex === 21 ||
                          colIndex === 22)
                      ) {
                        return (
                          <td
                            style={{
                              width: "1.5%",
                              backgroundColor: "#00ec2b",
                              color: "black",
                              textAlign: "center",
                              fontSize: "12px",
                              fontWeight: "bolder",
                            }}
                            key={colIndex}
                          >
                            &lt;
                          </td>
                        );
                      } else if (
                        rowIndex === 18 &&
                        (colIndex === 12 ||
                          colIndex === 13 ||
                          colIndex === 14 ||
                          colIndex === 15 ||
                          colIndex === 16 ||
                          colIndex === 17 ||
                          colIndex === 18 ||
                          colIndex === 19 ||
                          colIndex === 20 ||
                          colIndex === 21 ||
                          colIndex === 22 ||
                          colIndex === 23 ||
                          colIndex === 24 ||
                          colIndex === 25 ||
                          colIndex === 26 ||
                          colIndex === 27 ||
                          colIndex === 28 ||
                          colIndex === 29 ||
                          colIndex === 30 ||
                          colIndex === 31)
                      ) {
                        return (
                          <td
                            style={{
                              width: "1.5%",
                              backgroundColor: "#00ec2b",
                              color: "black",
                              textAlign: "center",
                              fontSize: "12px",
                              fontWeight: "bolder",
                            }}
                            key={colIndex}
                          >
                            &lt;
                          </td>
                        );
                      } else if (
                        rowIndex === 19 &&
                        (colIndex === 7 ||
                          colIndex === 8 ||
                          colIndex === 9 ||
                          colIndex === 10 ||
                          colIndex === 11 ||
                          colIndex === 17 ||
                          colIndex === 18 ||
                          colIndex === 19 ||
                          colIndex === 20 ||
                          colIndex === 21 ||
                          colIndex === 22 ||
                          colIndex === 23 ||
                          colIndex === 24 ||
                          colIndex === 25 ||
                          colIndex === 26 ||
                          colIndex === 27 ||
                          colIndex === 28 ||
                          colIndex === 29 ||
                          colIndex === 30 ||
                          colIndex === 31)
                      ) {
                        return (
                          <td
                            style={{
                              width: "1.5%",
                              backgroundColor: "#00ec2b",
                              color: "black",
                              textAlign: "center",
                              fontSize: "12px",
                              fontWeight: "bolder",
                            }}
                            key={colIndex}
                          >
                            &lt;
                          </td>
                        );
                      } else if (
                        rowIndex === 20 &&
                        (colIndex === 7 ||
                          colIndex === 8 ||
                          colIndex === 9 ||
                          colIndex === 10 ||
                          colIndex === 11 ||
                          colIndex === 12 ||
                          colIndex === 13 ||
                          colIndex === 14 ||
                          colIndex === 15 ||
                          colIndex === 16 ||
                          colIndex === 22 ||
                          colIndex === 23 ||
                          colIndex === 24 ||
                          colIndex === 25 ||
                          colIndex === 26 ||
                          colIndex === 27 ||
                          colIndex === 28 ||
                          colIndex === 29 ||
                          colIndex === 30 ||
                          colIndex === 31)
                      ) {
                        return (
                          <td
                            style={{
                              width: "1.5%",
                              backgroundColor: "#00ec2b",
                              color: "black",
                              textAlign: "center",
                              fontSize: "12px",
                              fontWeight: "bolder",
                            }}
                            key={colIndex}
                          >
                            &lt;
                          </td>
                        );
                      } else if (
                        rowIndex === 21 &&
                        (colIndex === 7 ||
                          colIndex === 8 ||
                          colIndex === 9 ||
                          colIndex === 10 ||
                          colIndex === 11 ||
                          colIndex === 12 ||
                          colIndex === 13 ||
                          colIndex === 14 ||
                          colIndex === 15 ||
                          colIndex === 16 ||
                          colIndex === 17 ||
                          colIndex === 18 ||
                          colIndex === 19 ||
                          colIndex === 20 ||
                          colIndex === 21 ||
                          colIndex === 27 ||
                          colIndex === 28 ||
                          colIndex === 29 ||
                          colIndex === 30 ||
                          colIndex === 31)
                      ) {
                        return (
                          <td
                            style={{
                              width: "1.5%",
                              backgroundColor: "#00ec2b",
                              color: "black",
                              textAlign: "center",
                              fontSize: "12px",
                              fontWeight: "bolder",
                            }}
                            key={colIndex}
                          >
                            &lt;
                          </td>
                        );
                      } else if (
                        rowIndex === 22 &&
                        (colIndex === 7 ||
                          colIndex === 8 ||
                          colIndex === 9 ||
                          colIndex === 10 ||
                          colIndex === 11 ||
                          colIndex === 12 ||
                          colIndex === 13 ||
                          colIndex === 14 ||
                          colIndex === 15 ||
                          colIndex === 16 ||
                          colIndex === 17 ||
                          colIndex === 18 ||
                          colIndex === 19 ||
                          colIndex === 20 ||
                          colIndex === 21 ||
                          colIndex === 22 ||
                          colIndex === 23 ||
                          colIndex === 24 ||
                          colIndex === 25 ||
                          colIndex === 26)
                      ) {
                        return (
                          <td
                            style={{
                              width: "1.5%",
                              backgroundColor: "#00ec2b",
                              color: "black",
                              textAlign: "center",
                              fontSize: "12px",
                              fontWeight: "bolder",
                            }}
                            key={colIndex}
                          >
                            &lt;
                          </td>
                        );
                      } else if (
                        rowIndex === 23 &&
                        (colIndex === 8 ||
                          colIndex === 9 ||
                          colIndex === 10 ||
                          colIndex === 11 ||
                          colIndex === 13 ||
                          colIndex === 14 ||
                          colIndex === 15 ||
                          colIndex === 16 ||
                          colIndex === 18 ||
                          colIndex === 19 ||
                          colIndex === 20 ||
                          colIndex === 21 ||
                          colIndex === 23 ||
                          colIndex === 24 ||
                          colIndex === 25 ||
                          colIndex === 26 ||
                          colIndex === 28 ||
                          colIndex === 29 ||
                          colIndex === 30 ||
                          colIndex === 31)
                      ) {
                        return (
                          <td
                            style={{
                              width: "1.5%",
                              backgroundColor: "#00ec2b",
                              color: "black",
                              textAlign: "center",
                              fontSize: "12px",
                              fontWeight: "bolder",
                            }}
                            key={colIndex}
                          >
                            &lt;
                          </td>
                        );
                      } else if (
                        rowIndex === 24 &&
                        (colIndex === 7 ||
                          colIndex === 9 ||
                          colIndex === 10 ||
                          colIndex === 11 ||
                          colIndex === 12 ||
                          colIndex === 14 ||
                          colIndex === 15 ||
                          colIndex === 16 ||
                          colIndex === 17 ||
                          colIndex === 19 ||
                          colIndex === 20 ||
                          colIndex === 21 ||
                          colIndex === 22 ||
                          colIndex === 24 ||
                          colIndex === 25 ||
                          colIndex === 26 ||
                          colIndex === 27 ||
                          colIndex === 29 ||
                          colIndex === 30 ||
                          colIndex === 31)
                      ) {
                        return (
                          <td
                            style={{
                              width: "1.5%",
                              backgroundColor: "#00ec2b",
                              color: "black",
                              textAlign: "center",
                              fontSize: "12px",
                              fontWeight: "bolder",
                            }}
                            key={colIndex}
                          >
                            &lt;
                          </td>
                        );
                      } else if (
                        rowIndex === 25 &&
                        (colIndex === 7 ||
                          colIndex === 8 ||
                          colIndex === 10 ||
                          colIndex === 11 ||
                          colIndex === 12 ||
                          colIndex === 13 ||
                          colIndex === 15 ||
                          colIndex === 16 ||
                          colIndex === 17 ||
                          colIndex === 18 ||
                          colIndex === 20 ||
                          colIndex === 21 ||
                          colIndex === 22 ||
                          colIndex === 23 ||
                          colIndex === 25 ||
                          colIndex === 26 ||
                          colIndex === 27 ||
                          colIndex === 28 ||
                          colIndex === 30 ||
                          colIndex === 31)
                      ) {
                        return (
                          <td
                            style={{
                              width: "1.5%",
                              backgroundColor: "#00ec2b",
                              color: "black",
                              textAlign: "center",
                              fontSize: "12px",
                              fontWeight: "bolder",
                            }}
                            key={colIndex}
                          >
                            &lt;
                          </td>
                        );
                      } else if (
                        rowIndex === 26 &&
                        (colIndex === 7 ||
                          colIndex === 8 ||
                          colIndex === 9 ||
                          colIndex === 11 ||
                          colIndex === 12 ||
                          colIndex === 13 ||
                          colIndex === 14 ||
                          colIndex === 16 ||
                          colIndex === 17 ||
                          colIndex === 18 ||
                          colIndex === 19 ||
                          colIndex === 21 ||
                          colIndex === 22 ||
                          colIndex === 23 ||
                          colIndex === 24 ||
                          colIndex === 26 ||
                          colIndex === 27 ||
                          colIndex === 28 ||
                          colIndex === 29 ||
                          colIndex === 31)
                      ) {
                        return (
                          <td
                            style={{
                              width: "1.5%",
                              backgroundColor: "#00ec2b",
                              color: "black",
                              textAlign: "center",
                              fontSize: "12px",
                              fontWeight: "bolder",
                            }}
                            key={colIndex}
                          >
                            &lt;
                          </td>
                        );
                      } else if (
                        rowIndex === 27 &&
                        (colIndex === 7 ||
                          colIndex === 8 ||
                          colIndex === 9 ||
                          colIndex === 10 ||
                          colIndex === 12 ||
                          colIndex === 13 ||
                          colIndex === 14 ||
                          colIndex === 15 ||
                          colIndex === 17 ||
                          colIndex === 18 ||
                          colIndex === 19 ||
                          colIndex === 20 ||
                          colIndex === 22 ||
                          colIndex === 23 ||
                          colIndex === 24 ||
                          colIndex === 25 ||
                          colIndex === 27 ||
                          colIndex === 28 ||
                          colIndex === 29 ||
                          colIndex === 30)
                      ) {
                        return (
                          <td
                            style={{
                              width: "1.5%",
                              backgroundColor: "#00ec2b",
                              color: "black",
                              textAlign: "center",
                              fontSize: "12px",
                              fontWeight: "bolder",
                            }}
                            key={colIndex}
                          >
                            &lt;
                          </td>
                        );
                      } else {
                        return (
                          <td style={{ width: "1.5%" }} key={colIndex}></td>
                        );
                      }
                    })
                  )}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default SistemiUSistemu;
