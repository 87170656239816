import React from 'react'
import classes from './ShopAdminDownload.module.css'
import useAxiosPrivate from '../hooks/useAxiosPrivate'
import { notification } from 'antd'

const ShopAdminDownload = () => {
  const axiosPrivate = useAxiosPrivate()
  const SERVER_URL = process.env.REACT_APP_SERVER_URL

  const handleDownload = async (type) => {
    try {
      const response = await axiosPrivate.get(
        `${SERVER_URL}${
          type === 'user' ? '/user' : ''
        }/export-${type}-to-excel`,
        {
          responseType: 'blob',
        }
      )
      const url = window.URL.createObjectURL(new Blob([response?.data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', `${type}_data.xlsx`)
      document.body.appendChild(link)
      link.click()
      link.parentNode.removeChild(link)
      notification.success({
        message: 'Uspešno ste preuzeli podatke!',
        placement: 'top',
      })
    } catch (err) {
      notification.error({
        message:
          err?.response?.data?.message ||
          'Greška pri preuzimanju, pokušajte kasnije!',
        placement: 'top',
      })
    }
  }

  return (
    <div className={classes.container}>
      <div className={classes.buttonsContainer}>
        <button onClick={() => handleDownload('user')}>
          PREUZMI PODATKE O KORISNICIMA
        </button>
        <button onClick={() => handleDownload('order')}>
          PREUZMI PODATKE O PORUDŽBINAMA
        </button>
      </div>
    </div>
  )
}

export default ShopAdminDownload
