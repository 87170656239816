import React, { useContext, useEffect, useState } from 'react'
import classes from './Scorebat.module.css'
import Comments from '../components/Comments'
import AuthContext from '../context/AuthProvider'
import AppContext from '../context/AppContext'
import axios from '../config/axios'

export default function Scorebat() {
  const { auth } = useContext(AuthContext)
  const { openLiveComments, setOpenLiveComments } = useContext(AppContext)
  const [data, setData] = useState({})
  const [scorebat, setScorebat] = useState(1)
  const [commentCounter, setCommentCounter] = useState({
    totalComments: 0,
    commentsLength: 0,
    answersLength: 0,
  })

  const fetchData = async () => {
    const { data } = await axios.get(
      `/info-block-post/653f0fb252a098b10de9797a`
    )
    setData(data.post)
  }

  useEffect(() => {
    const script = document.createElement('script')
    script.src = 'https://www.scorebat.com/embed/embed.js?v=arrv'
    script.async = true
    document.body.appendChild(script)

    return () => {
      document.body.removeChild(script)
    }
  }, [])

  useEffect(() => {
    fetchData()
  }, [])

  useEffect(() => {
    ;(function (d, s, id) {
      var js,
        fjs = d.getElementsByTagName(s)[0]
      if (d.getElementById(id)) return
      js = d.createElement(s)
      js.id = id
      js.src = 'https://www.scorebat.com/embed/embed.js?v=arrv'
      fjs.parentNode.insertBefore(js, fjs)
    })(document, 'script', 'scorebat-jssdk')
  }, [])

  const adminCommentOnly = data.adminCommentOnly ? data.adminCommentOnly : false

  return (
    <div
      className={`${classes.container} ${openLiveComments && classes.active}`}
    >
      <div className={classes.scorebatTopMenu}>
        <div className={classes.topMenuBtnContainers}>
          <button onClick={() => setScorebat(2)} type='button'>
            <span style={{ color: 'rgb(0, 216, 0)' }}>YT STREAM</span>
            <span style={{ color: 'white' }}>+</span>
            <span style={{ color: 'red' }}>HIGHLIGHTS</span>
          </button>
        </div>

        <div className={classes.topMenuBtnContainers}>
          <button onClick={() => setScorebat(1)} type='button'>
            <span style={{ color: 'red' }}>REZULTATI</span>
            <span style={{ color: 'white' }}>-</span>
            <span style={{ color: 'rgb(0, 216, 0)' }}>UŽIVO</span>
            <span style={{ color: 'white' }}>-</span>
            <span style={{ color: '#fcc102' }}>RASPORED</span>
          </button>
        </div>
      </div>

      <div className={classes.scoreBatMainContainer}>
        <div style={{ width: '100%', background: '#fbf9f9' }}>
          {scorebat === 1 && (
            <iframe
              src={`https://www.scorebat.com/embed/livescore/?token=${process.env.REACT_APP_SCOREBAT_EMBED_TOKEN}`}
              frameBorder='0'
              width='600'
              height='760'
              allowFullScreen
              allow='autoplay; fullscreen'
              style={{
                width: '100%',
                height: '760px',
                overflow: 'hidden',
                display: 'block',
              }}
              className={`_scorebatEmbeddedPlayer_ ${classes.iFrame}`}
            />
          )}
          {scorebat === 2 && (
            <iframe
              src={`https://www.scorebat.com/embed/?token=${process.env.REACT_APP_SCOREBAT_EMBED_TOKEN}`}
              frameBorder='0'
              width='600'
              height='760'
              allowFullScreen
              allow='autoplay; fullscreen'
              className='_scorebatEmbeddedPlayer_'
            ></iframe>
          )}
        </div>
      </div>

      <div
        className={`${classes.floatingComments} ${
          openLiveComments ? classes.active : null
        }`}
      >
        <div>
          <button onClick={() => setOpenLiveComments(false)}>
            <img src='/close-mini-icon.svg' width={40} height={40} />
          </button>
          <span>IZLAZ</span>
        </div>
        <Comments
          commentType={'info_blok'}
          adminComment={adminCommentOnly}
          user={auth}
          id={'653f0fb252a098b10de9797a'}
          setCommentCounter={setCommentCounter}
        />
      </div>
    </div>
  )
}
