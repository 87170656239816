import { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import classes from "./admingallery.module.css";
import Axios from "axios";
import { Flex, notification, Modal, Button } from "antd";

const AdminGallery = () => {
  const imageRef = useRef(null);
  const SERVER_URL = process.env.REACT_APP_SERVER_URL;
  const [toggle, setToggle] = useState(false);
  const [images, setImages] = useState([]);
  const [rerender, setReRender] = useState(false);
  const [selectedItem, setSelectedItem] = useState();
  const [isDelModalVisible, setIsDelModalVisible] = useState(false);
  const navigate = useNavigate();
  const axiosPrivate = useAxiosPrivate();

  const handleImageUpload = async () => {
    try {
      const formData = new FormData();
      formData.append("file", imageRef.current.files[0]);
      formData.imageType = "gallery";

      const response = await Axios.post(
        `${SERVER_URL}/images/upload-image`,
        formData,
        {
          withCredentials: false,
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      setReRender(!rerender);

      notification.success({ message: "Slika uspešno objavljena" });
    } catch (error) {
      console.error("Upload Error: ", error);
      // Optionally: Provide user feedback on failure.
    }
  };

  const fetchImages = async () => {
    try {
      const imagesArr = await Axios.get(`${SERVER_URL}/images/get-gallery`, {
        withCredentials: false,
      });

      setImages(imagesArr.data);
    } catch (error) {
      console.log("err", error);
    }
  };

  useEffect(() => {
    fetchImages();
  }, [rerender]);

  const handleDeleteItem = async (itemId) => {
    await Axios.delete(`${SERVER_URL}/images/delete/${itemId}`, {
      withCredentials: false,
    });

    notification.success({ message: "Slika uspešno obrisana" });

    setTimeout(() => {
      window.location.reload();
    }, 300);
  };

  return (
    <div className={classes.container}>
      <div style={{ display: "flex", alignItems: "center" }}>
        <h1>Galerija: </h1>

        <div className={classes.imageDiv}>
          <input
            id="fileInput"
            className={classes.imageInput}
            type="file"
            ref={imageRef}
            onChange={handleImageUpload}
            accept="image/*"
          />
          <label htmlFor="fileInput">Objavi sliku</label>
        </div>

        <h1 style={{ marginLeft: "15px" }}>{images.length} slike/a</h1>
      </div>

      <div className={classes.imagesList}>
        {images &&
          images.length > 0 &&
          images.map((item, index) => {
            return (
              <div className={classes.imageItem}>
                <img
                  className="img"
                  src={`${SERVER_URL}/${item.url}`}
                  alt={item.url}
                />
                <button
                  onClick={(e) => {
                    setSelectedItem(item._id);
                    setIsDelModalVisible(true);
                  }}
                  className={classes.delButton}
                >
                  <span>Obriši</span>
                </button>
              </div>
            );
          })}
      </div>

      <Modal
        footer={[]}
        open={isDelModalVisible}
        title="Da li ste sigurni da želite da obrišete sliku?"
        onCancel={() => {
          setIsDelModalVisible(false);
        }}
      >
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-evenly",
            marginTop: "20px",
          }}
        >
          <Button
            onClick={(e) => {
              handleDeleteItem(selectedItem);
              setIsDelModalVisible(false);
            }}
          >
            Obriši
          </Button>
          <Button
            onClick={(e) => {
              setIsDelModalVisible(false);
            }}
          >
            Odustani
          </Button>
        </div>
      </Modal>
    </div>
  );
};

export default AdminGallery;
