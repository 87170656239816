import classes from "./adminHome.module.css";

const AdminHome = () => {
  return (
    <div className={classes.container}>
      <h2>HOME</h2>
    </div>
  );
};

export default AdminHome;
