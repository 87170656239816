import podesavanja from "../images/podesavanja.png";
import ctrlPlus from "../images/ctrl+.png";
import ctrlMinus from "../images/ctrl-.png";

import classes from "./podesiIzgledSajta.module.css";

const PodesiIzgledSajta = () => {
  return (
    <div className={classes.mainCenter}>
      <img className={classes.headerImage} src={podesavanja} alt="image" />
      <h2 className={classes.blue}>
        Sajt je prilagođen desktop i laptop računarima, za jasan pregled sajta,
        koristite iste.
      </h2>
      <h2 className={classes.red}>
        Verzija sajta za tablete i za mobilne telefone je u izradi.
      </h2>
      <div className={classes.images}>
        <img src={ctrlMinus} alt="image" />
        <img src={ctrlPlus} alt="image" />
      </div>
    </div>
  );
};

export default PodesiIzgledSajta;
