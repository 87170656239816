import classes from "./grupa3007.module.css";
import { useEffect } from "react";

const Grupa3007 = ({
  kvotaNaKonacanIshod1,
  kvotaNaKonacanIshod2,
  kvotaNaKonacanIshodX,
  domacinDao,
  domacinPrimio,
  brojUtakmicaDomacin,
  brojUtakmicaGost,
  gostDao,
  gostPrimio,
  setKvotaNaKonacanIshod1,
  setKvotaNaKonacanIshod2,
  setKvotaNaKonacanIshodX,
  setDomacinDao,
  setDomacinPrimio,
  setBrojUtakmicaDomacin,
  setBrojUtakmicaGost,
  setGostDao,
  setGostPrimio,
  setSOG,
  setSNG,
  setSODG,
  setSODSNG,
  setSND,
  setSOD,
  setSNDG,
  setSNDSOG,
  pobedaDomacina,
  setPobedaDomacina,
  pobedaGosta,
  setPobedaGosta,
  nereseno,
  setNereseno,
  golMin,
  setGolMin,
  golMax,
  setGolMax,
  golBalans,
  setGolBalans,
  tacanRezultatDomacin,
  setTacanRezultatDomacin,
  tacanRezultatGost,
  setTacanRezultatGost,
  pobedaSaRazlikom,
  setPobedaSaRazlikomZagrada,
  setPobedaSaRazlikom,
  betkoPredlog1,
  setBetkoPredlog1,
  betkoPredlog2,
  setBetkoPredlog2,
  setOcekivaniMaksimalanTacanRezultat,
  setOcekivaniMinimalanTacanRezultat,
  setOcekivaniSrednjiTacanRezultat,
  setOcekivaniMaksimalanTacanRezultat2,
  setOcekivaniMinimalanTacanRezultat2,
  setOcekivaniSrednjiTacanRezultat2,
}) => {
  const handleChangePobedaDomacina = (e) => {
    setKvotaNaKonacanIshod1(e);
  };

  const handleChangeNereseno = (e) => {
    setKvotaNaKonacanIshodX(e);
  };

  const handleChangePobedaGosta = (e) => {
    setKvotaNaKonacanIshod2(e);
  };

  const handleChangeDomacinDao = (e) => {
    setDomacinDao(e);
  };

  const handleChangeDomacinPrimio = (e) => {
    setDomacinPrimio(e);
  };

  const handleChangeDomacinBrojUtakmica = (e) => {
    setBrojUtakmicaDomacin(e);
  };

  const handleChangeGostDao = (e) => {
    setGostDao(e);
  };

  const handleChangeGostPrimio = (e) => {
    setGostPrimio(e);
  };

  const handleChangeGostBrojUtakmica = (e) => {
    setBrojUtakmicaGost(e);
  };

  const handleCalculate = () => {
    if (
      (kvotaNaKonacanIshod1,
      kvotaNaKonacanIshod2,
      kvotaNaKonacanIshodX,
      domacinDao,
      domacinPrimio,
      brojUtakmicaDomacin,
      gostDao,
      gostPrimio,
      brojUtakmicaGost)
    ) {
      /////////zaglavlje

      let snd = parseFloat(domacinDao) / parseFloat(brojUtakmicaDomacin);
      setSND(snd.toFixed(2));

      let sod = parseFloat(domacinPrimio) / parseFloat(brojUtakmicaDomacin);
      setSOD(sod.toFixed(2));

      let sog = parseFloat(gostDao) / parseFloat(brojUtakmicaGost);
      setSOG(sog.toFixed(2));

      let sng = parseFloat(gostPrimio) / parseFloat(brojUtakmicaGost);
      setSNG(sng.toFixed(2));

      let sodg = (sod + sog) / 2;
      setSODG(sodg.toFixed(2));

      let sndg = (snd + sng) / 2;
      setSNDG(sndg.toFixed(2));

      let sndsog = (snd + sog) / 2;
      setSNDSOG(sndsog.toFixed(2));

      let sodsng = (sod + sng) / 2;
      setSODSNG(sodsng.toFixed(2));
    }
  };

  useEffect(() => {
    handleCalculate();
  }, [
    kvotaNaKonacanIshod1,
    kvotaNaKonacanIshod2,
    kvotaNaKonacanIshodX,
    domacinDao,
    domacinPrimio,
    brojUtakmicaDomacin,
    gostDao,
    gostPrimio,
    brojUtakmicaGost,
  ]);

  return (
    <div className={classes.container}>
      <input
        onChange={(e) => {
          handleChangePobedaDomacina(e.target.value);
        }}
        className={classes.blackCenteredInput}
        placeholder="pobeda domaćina"
        type="number"
        min="0"
      />
      <input
        onChange={(e) => {
          handleChangeNereseno(e.target.value);
        }}
        className={classes.blackCenteredInput}
        placeholder="nerešeno"
        type="number"
        min="0"
      />
      <input
        onChange={(e) => {
          handleChangePobedaGosta(e.target.value);
        }}
        className={classes.blackCenteredInput}
        placeholder="pobeda gosta"
        type="number"
        min="0"
      />
      <input
        onChange={(e) => {
          handleChangeDomacinDao(e.target.value);
        }}
        className={classes.blackCenteredInput}
        placeholder="domaćin + (dao)"
        type="number"
        min="0"
      />
      <input
        onChange={(e) => {
          handleChangeDomacinPrimio(e.target.value);
        }}
        className={classes.blackCenteredInput}
        placeholder="domaćin - (primio)"
        type="number"
        min="0"
      />
      <input
        onChange={(e) => {
          handleChangeDomacinBrojUtakmica(e.target.value);
        }}
        className={classes.blackCenteredInput}
        placeholder="domaćin broj utak."
        type="number"
        min="0"
      />
      <input
        onChange={(e) => {
          handleChangeGostDao(e.target.value);
        }}
        className={classes.blackCenteredInput}
        placeholder="gost + (dao)"
        type="number"
        min="0"
      />
      <input
        onChange={(e) => {
          handleChangeGostPrimio(e.target.value);
        }}
        className={classes.blackCenteredInput}
        placeholder="gost - (primio)"
        type="number"
        min="0"
      />
      <input
        onChange={(e) => {
          handleChangeGostBrojUtakmica(e.target.value);
        }}
        className={classes.blackCenteredInput}
        placeholder="gost broj utak."
        type="number"
        min="0"
      />
    </div>
  );
};

export default Grupa3007;
