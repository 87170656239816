import { Routes, Route, useLocation, Link } from 'react-router-dom'
import { useState, useEffect } from 'react'
import HomeLayout from './layout/HomeLayout'
import 'swiper/css'
import 'swiper/css/navigation'
import Home from './pages/Home'
import AboutUs from './pages/AboutUs'
import Contact from './pages/Contact'
import Saradnja from './pages/Saradnja'
import PolitikaPrivatnosti from './pages/PolitikaPrivatnosti'
import TermOfUse from './pages/TermOfUse'
import Auth from './pages/Auth'
import AdminLayout from './layout/AdminLayout'
import AdminHome from './adminPanel/AdminHome'
import AdminStatistika from './adminPanel/AdminStatistika'
import AdminInfoBlok from './adminPanel/AdminInfoBlok'
import AdminBetKursNovo from './adminPanel/AdminBetKursNovo'
import AdminNasiPredlozi from './adminPanel/AdminNasiPredlozi'
import BetKurs from './pages/BetKurs'
import BetKalkulatori from './pages/BetKalkulatori'
import BetKursTutorial from './pages/BetKursTutorial'
import BetKalkulatoriTutorial from './pages/BetKalkulatoriTutorial'
import MojNalog from './pages/MojNalog'
import PersistLogin from './components/PersistLogin'
import RequireAuth from './components/RequireAuth'
import AdminSviKorisnici from './adminPanel/AdminSviKorisnici'
import AdminUserDetails from './adminPanel/AdminUserDetails'
import AdminEditPost from './adminPanel/AdminEditPost'
import Statistika from './pages/Statistika'
import BetKursNovo from './pages/BetKursNovo'
import NasiPredlozi from './pages/NasiPredlozi'
import BetKalkulatoriRute from './components/BetKalkulatoriRute'
import BetKalkulatorLayout from './layout/BetKalkulatorLayout'
import BetPortal from './pages/BetPortal'
import Neovlascen from './pages/Neovlascen'
import AdminOpstiUslovi from './adminPanel/AdminOpstiUslovi'
import AdminBetPortal from './adminPanel/AdminBetPortal'
import AdminBetKalkulatoriTutorijal from './adminPanel/AdminBetKalkulatoriTutorijal'
import PodesiIzgledSajta from './pages/PodesiIzgledSajta'
import ZaboravioSamLozinku from './pages/ZaboravioSamLozinku'
import PromeniLozinku from './pages/PromeniLozinku'
import PromeniPassword from './pages/PromeniPassword'
import InfoBlockSinglePage from './pages/InfoBlockSinglePage'
import BetKursSinglePage from './pages/BetKursSinglePage'
import BetPortalInfoSinglePage from './pages/BetPortalInfoSinglePage'
import StatistikaSinglePage from './pages/StatistikaSinglePage'
import BetKalkulatoriTutorijalSinglePage from './pages/BetKalkulatoriTutorijalSinglePage'
import BetKursNovoSinglePage from './pages/BetKursNovoSinglePage'
import NasiPredloziSinglePage from './pages/NasiPredloziSinglePage'
import InfoBlok from './pages/InfoBlok'
import Scorebat from './pages/Scorebat'

//Nove stranice
import AdminEditPostTutorijal from './adminPanel/AdminEditPostTutorijal'
import AdminEditBetKurs from './adminPanel/AdminEditBetKurs'
import AdminAddNewForm from './adminPanel/AdminAddNewForm'
import AdminAddNewBetCourseForm from './adminPanel/AdminAddNewBetCourseForm'
import AdminAddNewBetCourseChapter from './adminPanel/AdminAddNewCourseChapter'
import AdminGallery from './adminPanel/AdminGallery'
import AdminVideoGallery from './adminPanel/AdminVideoGallery'
import AdminChatImages from './adminPanel/AdminChatImages'
import AdminAddNewTutorijalKalkulatora from './adminPanel/AdminAddNewTutorijalKalkulatora'
import AdminBetKurs from './adminPanel/AdminBetKurs'
import PokreniKalkulatore from './pages/PokreniKalkulatore'
import AdminBetKursPoglavlja from './adminPanel/AdminBetKursPoglavlja'
import AdminPokreniKalkulatore from './adminPanel/AdminPokreniKalkulatore'
import AdminFooterImages from './adminPanel/AdminFooterImages'
import AdminKorisnickiPaketi from './adminPanel/AdminKorisnickiPaketi'
import AdminAddNewUsersPackageForm from './adminPanel/AdminAddNewUsersPackageForm'
import AdminEditKorisnickiPaketiForm from './adminPanel/AdminEditKorisnickiPaketiForm'
import KorisnickiPaketiLista from './pages/KorisniskiPaketiLista'
import KorisnickiPaketiSinglePage from './pages/KorisnickiPaketiSinglePage'
import OpstiUsloviPost from './pages/OpstiUsloviPost'
import AdminBetkoInfoZid from './adminPanel/AdminBetkoInfoZid'
import AdminGalleryHome from './adminPanel/AdminGalleryHome'
import AdminHomeVideoGallery from './adminPanel/AdminHomeVideoGallery'
import AdminAvatarGallery from './adminPanel/AdminAvatarGallery'
import AdminTeams from './adminPanel/AdminTeams'
import AdminComments from './adminPanel/AdminComments'
import AdminCommentsReports from './adminPanel/AdminCommentsReports'
import AdminCommentsRates from './adminPanel/AdminCommentsRates'

import AdminAddNewHomePageVideo from './adminPanel/AdminAddNewHomePageVideo'

import AdminAddNewBetkoInfoZid from './adminPanel/AdminAddNewBetkoInfoZid'

import AdminEditBetkoInfoZid from './adminPanel/AdminEditBetkoInfoZid'
import AdminBlockedUsers from './adminPanel/AdminBlockedUsers'

import coverImage from './icons/mobile-new.webp'
import SportskePricePage from './pages/SportskePrice'
import GlavneKartice from './pages/GlavneKartice'
import AdminNews from './adminPanel/AdminNews'
import MisterTipster from './pages/MisterTipster'
import AdminMisterTipster from './adminPanel/AdminMisterTipster'
import AdminTipsterAwards from './adminPanel/AdminTipsterAwards'
import MisterTipsterAwards from './pages/MisterTipsterAwards'
import MisterTipsterProfil from './pages/MisterTipsterProfil'
import GodisnjaTabela from './pages/GodisnjaTabela'
import MesecnaTabela from './pages/MesecnaTabela'
import GlobalnaTabela from './pages/GlobalnaTabela'
import Tabele from './pages/Tabele'
import PravilaIgre from './pages/PravilaIgre/PravilaIgre'
import RangTabela from './pages/RangTabela'
import MisterTipsterOpisIgre from './pages/MisterTipsterOpisIgre'
import BetkoShopUslovi from './pages/BetkoShop/BetkoShopUslovi'
import BetkoShopHome from './pages/BetkoShop/BetkoShopHome'
import BetkoShopPage from './pages/BetkoShop/BetkoShopPage'
import ShopAdminLayout from './layout/ShopAdminLayout'
import ShopAdminHome from './shopAdminPanel/ShopAdminHome'
import ShopAdminSellers from './shopAdminPanel/ShopAdminSellers'
import ShopAdminCategories from './shopAdminPanel/ShopAdminCategories'
import ShopAdminProducts from './shopAdminPanel/ShopAdminProducts'
import BetkoCart from './pages/BetkoShop/BetkoCart'
import BetkoShopSaradnja from './pages/BetkoShop/BetkoShopSaradnja'
import ShopAdminOrders from './shopAdminPanel/ShopAdminOrders'
import ShopAdminDownload from './shopAdminPanel/ShopAdminDownload'
import AdminTipsterAwardsArchive from './adminPanel/AdminTipsterAwardsArchive'
import classes from './App.module.css'
import BetkoFamily from './pages/BetkoFamily'
import BetkoFamilyDesc from './pages/BetkoFamilyDesc'
import BetkoPlus from './pages/BetkoPlus'
import AdminMobileApk from './adminPanel/AdminMobileApk'
import SuccessfulPayment from './pages/SuccessfulPayment'
import DeniedPayment from './pages/DeniedPayment'
import Payment from './pages/Payment'
import SafePurchase from './pages/SafePurchase'
import Marketing from './pages/Marketing'
import AdminKorisnickiPaketiProdaja from './adminPanel/AdminKorisnickiPaketiProdaja'
import downloadApk from './utils/DownloadApk'
import ApkUpdate from './pages/ApkUpdate'

const App = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768)
  const isAndroid = /Android/i.test(navigator.userAgent)

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768)
    }

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])
  const { pathname } = useLocation()

  const decodedURL = decodeURIComponent(pathname)

  let betKursURL
  let betKalkulatoriURL

  if (decodedURL.split('/')[1] === 'bet_kurs') {
    betKursURL = decodedURL
  }

  if (decodedURL.split('/')[1] === 'bet_kalkulatori') {
    betKalkulatoriURL = decodedURL
  }

  useEffect(() => {
    if (
      isMobile &&
      isAndroid &&
      !decodedURL.startsWith('/betko_shop') &&
      decodedURL !== '/azuriraj-aplikaciju'
    ) {
      if (isAndroid) {
        window.location.href = `betkotip-app://betkotip-staging-frontend.concordsofttest.com${pathname}`
      }
    }
  }, [isMobile, isAndroid])

  return (
    <div className={classes.appContainer}>
      {isMobile &&
      !decodedURL.startsWith('/betko_shop') &&
      decodedURL !== '/azuriraj-aplikaciju' ? (
        isAndroid ? (
          <>
            <img className={classes.imgCover} src='/androidPlaceholder.png' />
            <div className={classes.btnsWrapper}>
              <button
                onClick={downloadApk}
                className={classes.leftButton}
                target='_blank'
              />
              <a href='/betko_shop' className={classes.rightButton} />
            </div>
          </>
        ) : (
          <a href='/betko_shop'>
            <img className={classes.imgCover} src='/iOSplaceholder1.png' />
          </a>
        )
      ) : (
        <Routes>
          <Route element={<PersistLogin />}>
            <Route
              element={<RequireAuth allowedRoles={[5151]} adminPanel={true} />}
            >
              <Route element={<AdminLayout />}>
                <Route path='/betko-admin' element={<AdminHome />} />
                <Route
                  path='/betko-admin/statistika'
                  element={<AdminStatistika />}
                />
                <Route
                  path='/betko-admin/blokirani-korisnici'
                  element={<AdminBlockedUsers />}
                />
                <Route
                  path='/betko-admin/aplikacija-za-mobilni'
                  element={<AdminMobileApk />}
                />
                <Route
                  path='/betko-admin/info-blok'
                  element={<AdminInfoBlok />}
                />
                <Route
                  path='/betko-admin/chat-slike'
                  element={<AdminChatImages />}
                />
                <Route path='/betko-admin/timovi' element={<AdminTeams />} />
                <Route path='/betko-admin/vesti' element={<AdminNews />} />
                <Route
                  path='/betko-admin/footer-slike'
                  element={<AdminFooterImages />}
                />
                <Route
                  path='/betko-admin/galerija'
                  element={<AdminGallery />}
                />
                <Route
                  path='/betko-admin/galerija-homepage'
                  element={<AdminGalleryHome />}
                />
                <Route
                  path='/betko-admin/video-galerija'
                  element={<AdminVideoGallery />}
                />
                <Route
                  path='/betko-admin/video-home-galerija'
                  element={<AdminHomeVideoGallery />}
                />
                <Route
                  path='/betko-admin/avatar-galerija'
                  element={<AdminAvatarGallery />}
                />
                <Route
                  path='/betko-admin/komentari'
                  element={<AdminComments />}
                />
                <Route
                  path='/betko-admin/prijavljeni-komentari'
                  element={<AdminCommentsReports />}
                />
                <Route
                  path='/betko-admin/ocene-komentara'
                  element={<AdminCommentsRates />}
                />
                <Route
                  path='/betko-admin/bet-portal'
                  element={<AdminBetPortal />}
                />
                <Route
                  path='/betko-admin/bet-kurs-novo'
                  element={<AdminBetKursNovo />}
                />
                <Route
                  path='/betko-admin/bet-kurs'
                  element={<AdminBetKurs />}
                />
                <Route
                  path='/betko-admin/nasi-predlozi'
                  element={<AdminNasiPredlozi />}
                />
                <Route
                  path='/betko-admin/opsti_uslovi'
                  element={<AdminOpstiUslovi />}
                />
                <Route
                  path='/betko-admin/svi-korisnici'
                  element={<AdminSviKorisnici />}
                />
                <Route
                  path='/betko-admin/korisnicki-paketi'
                  element={<AdminKorisnickiPaketi />}
                />
                <Route
                  path='/betko-admin/korisnicki-paketi-prodaja'
                  element={<AdminKorisnickiPaketiProdaja />}
                />
                <Route
                  path='/betko-admin/korisnik-detalji'
                  element={<AdminUserDetails />}
                />
                <Route
                  path='/betko-admin/kreiraj-novi-post'
                  element={<AdminAddNewForm />}
                />
                <Route
                  path='/betko-admin/kreiraj-zid-karticu'
                  element={<AdminAddNewBetkoInfoZid />}
                />
                <Route
                  path='/betko-admin/kreiraj-novi-post-betkurs'
                  element={<AdminAddNewBetCourseForm />}
                />
                <Route
                  path='/betko-admin/kreiraj-novo-poglavlje-betkurs'
                  element={<AdminAddNewBetCourseChapter />}
                />
                <Route
                  path='/betko-admin/kreiraj-novi-post-tutorijalikalkulatora'
                  element={<AdminAddNewTutorijalKalkulatora />}
                />
                <Route
                  path='/betko-admin/kreiraj-novi-post-korisnickipaketi'
                  element={<AdminAddNewUsersPackageForm />}
                />
                <Route
                  path='/betko-admin/bet-kurs-poglavlja'
                  element={<AdminBetKursPoglavlja />}
                />
                <Route
                  path='/betko-admin/edituj-post'
                  element={<AdminEditPost />}
                />
                <Route
                  path='/betko-admin/edituj-karticu'
                  element={<AdminEditBetkoInfoZid />}
                />
                <Route
                  path='/betko-admin/edituj-post-tutorijal'
                  element={<AdminEditPostTutorijal />}
                />
                <Route
                  path='/betko-admin/edituj-post-betkurs'
                  element={<AdminEditBetKurs />}
                />
                <Route
                  path='/betko-admin/edituj-post-korisnickipaketi'
                  element={<AdminEditKorisnickiPaketiForm />}
                />
                <Route
                  path='/betko-admin/bet-kalkulatori-tutorijal'
                  element={<AdminBetKalkulatoriTutorijal />}
                />
                <Route
                  path='/betko-admin/pokreni-kalkulatore'
                  element={<AdminPokreniKalkulatore />}
                />
                <Route
                  path='/betko-admin/betko-info-zid'
                  element={<AdminBetkoInfoZid />}
                />
                <Route
                  path='/betko-admin/betko-home-zid'
                  element={<AdminAddNewHomePageVideo />}
                />
                <Route
                  path='/betko-admin/mister-tipster'
                  element={<AdminMisterTipster />}
                />
                <Route
                  path='/betko-admin/mister-tipster-nagrade'
                  element={<AdminTipsterAwards />}
                />
                <Route
                  path='/betko-admin/mister-tipster-arhiva-nagrada'
                  element={<AdminTipsterAwardsArchive />}
                />
              </Route>
            </Route>
            <Route
              element={
                <RequireAuth allowedRoles={[5151]} shopAdminPanel={true} />
              }
            >
              <Route element={<ShopAdminLayout />}>
                <Route
                  path='/betko-shop-admin/home'
                  element={<ShopAdminHome />}
                />
                <Route
                  path='/betko-shop-admin/prodavci'
                  element={<ShopAdminSellers />}
                />
                <Route
                  path='/betko-shop-admin/kategorije'
                  element={<ShopAdminCategories />}
                />
                <Route
                  path='/betko-shop-admin/proizvodi'
                  element={<ShopAdminProducts />}
                />
                <Route
                  path='/betko-shop-admin/porudzbine'
                  element={<ShopAdminOrders />}
                />
                <Route
                  path='/betko-shop-admin/preuzmi-korisnike-i-porudzbine'
                  element={<ShopAdminDownload />}
                />
              </Route>
            </Route>
            <Route path='/betko_shop' element={<BetkoShopHome />} />
            <Route path='/betko_shop/shop' element={<BetkoShopPage />} />

            <Route path='/betko_shop/uslovi' element={<BetkoShopUslovi />} />
            <Route
              path='/betko_shop/saradnja'
              element={<BetkoShopSaradnja />}
            />
            <Route path='/betko_shop/moja-korpa' element={<BetkoCart />} />

            <Route element={<HomeLayout />}>
              <Route exact path='/' element={<Home />} />
              <Route
                exact
                path='/sportske-price'
                element={<SportskePricePage />}
              />
              <Route exact path='/glavne-kartice' element={<GlavneKartice />} />
              <Route exact path='/info_blok' element={<InfoBlok />} />
              <Route path='/info_blok/:id' element={<InfoBlockSinglePage />} />

              <Route path='/o_nama' element={<AboutUs />} />
              <Route
                path='/podešavanja_izgleda_sajta'
                element={<PodesiIzgledSajta />}
              />
              <Route
                path='/pokreni_kalkulatore'
                element={<PokreniKalkulatore />}
              />
              <Route path='/kontakt' element={<Contact />} />
              <Route path='/saradnja' element={<Saradnja />} />
              <Route
                path='/politika-privatnosti'
                element={<PolitikaPrivatnosti />}
              />
              <Route path='/registracija_i_logovanje' element={<Auth />} />
              <Route path='/moj_nalog' element={<MojNalog />} />
              <Route path='/azuriraj-aplikaciju' element={<ApkUpdate />} />
              <Route
                path='/promeni_lozinku/:tempPassword'
                element={<PromeniLozinku />}
              >
                <Route path=':tempPassword' />
              </Route>
              <Route
                path='/zaboravio_sam_lozinku'
                element={<ZaboravioSamLozinku />}
              />
              <Route path='/18+' element={<TermOfUse />} />
              <Route path='/bet_kurs' element={<BetKurs />} />
              {/* <Route path="/bet_kurs/:id" element={<BetKursSinglePage />} /> */}
              <Route path='/bet_kalkulatori' element={<BetKalkulatori />} />
              <Route path='/bet_kurs_tutorijal' element={<BetKursTutorial />} />
              <Route path='/bet_portal' element={<BetPortal />} />
              <Route
                path='/bet_portal/:id'
                element={<BetPortalInfoSinglePage />}
              />
              <Route path='/statistika' element={<Statistika />} />
              <Route
                path='/statistika/:id'
                element={<StatistikaSinglePage />}
              />
              <Route path='/vasi_predlozi' element={<NasiPredlozi />} />
              <Route
                path='/nasi_predlozi/:id'
                element={<NasiPredloziSinglePage />}
              />
              <Route path='/rezultati_uzivo' element={<Scorebat />} />
              <Route path='/mister_tipster' element={<MisterTipster />} />
              <Route
                path='/mister_tipster/nagrade'
                element={<MisterTipsterAwards />}
              />
              <Route
                path='/mister_tipster/opis-igre'
                element={<MisterTipsterOpisIgre />}
              />
              <Route
                path='/mister_tipster/profil'
                element={<MisterTipsterProfil />}
              />
              <Route
                path='/mister_tipster/tabele/godisnja-tabela'
                element={<GodisnjaTabela />}
              />
              <Route
                path='/mister_tipster/tabele/mesecna-tabela'
                element={<MesecnaTabela />}
              />
              <Route path='/mister_tipster/tabele' element={<Tabele />} />
              <Route
                path='/mister_tipster/tabele/globalna-tabela'
                element={<GlobalnaTabela />}
              />
              <Route
                path='/mister_tipster/tabele/tabela-ranga'
                element={<RangTabela />}
              />
              <Route
                path='/mister_tipster/pravila-igre'
                element={<PravilaIgre />}
              />

              <Route
                element={
                  <RequireAuth
                    allowedRoles={[5151, 1984, 2993, 7623, 2673]}
                    adminPanel={false}
                  />
                }
              >
                <Route path='/bet_kurs_novo/' element={<BetKursNovo />} />
                <Route
                  path='/bet_kurs_novo/:id'
                  element={<BetKursNovoSinglePage />}
                />
              </Route>
              <Route
                path='/korisnicki_paketi'
                element={<KorisnickiPaketiLista />}
              />
              <Route path='/sigurna_kupovina' element={<SafePurchase />} />
              <Route path='/marketing_prostor' element={<Marketing />} />
              <Route
                path='/korisnicki_paketi/kupi-paket'
                element={<Payment />}
              />
              <Route path='/opsti_uslovi' element={<OpstiUsloviPost />} />
              <Route path='/betko_family' element={<BetkoFamily />} />
              <Route path='/betko_family/opis' element={<BetkoFamilyDesc />} />
              <Route path='/betko_plus' element={<BetkoPlus />} />
              <Route
                path='/bet_kalkulatori_tutorijal'
                element={<BetKalkulatoriTutorial />}
              />
              <Route
                path='/bet_kalkulatori_tutorijal/:id'
                element={<BetKalkulatoriTutorijalSinglePage />}
              />
              <Route
                path='/korisnicki_paketi/:id'
                element={<KorisnickiPaketiSinglePage />}
              />
              <Route path='/uspesno-placanje' element={<SuccessfulPayment />} />
              <Route path='/neuspesno-placanje' element={<DeniedPayment />} />
              <Route path='/neovlašćen' element={<Neovlascen />} />
              <Route
                element={
                  <RequireAuth
                    allowedRoles={[5151, 1984, 7743, 2993, 7623, 2673]}
                  />
                }
              >
                <Route path='/bet_kurs/:id' element={<BetKursSinglePage />} />
              </Route>
              <Route
                element={
                  <RequireAuth
                    allowedRoles={[
                      5151, 1984, 2343, 7743, 2993, 7623, 2673, 2001,
                    ]}
                  />
                }
              >
                <Route path='/promeni_lozinku' element={<PromeniPassword />} />
              </Route>
            </Route>
            <Route
              element={
                <RequireAuth
                  allowedRoles={[5151, 1984, 2343, 7743, 2993, 7623, 2673]}
                />
              }
            >
              <Route element={<BetKalkulatorLayout />}>
                <Route
                  path={betKalkulatoriURL}
                  element={<BetKalkulatoriRute />}
                />
              </Route>
            </Route>
          </Route>
        </Routes>
      )}
    </div>
  )
}

export default App
