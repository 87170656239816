import Grupa1 from "../components/Grupa1";
import Grupa28 from "../components/Grupa28";
import Grupa300 from "../components/Grupa300";
import classes from "./futsal.module.css";
import { useState } from "react";

const Futsal = () => {
  const [snd, setSND] = useState("-");
  const [sod, setSOD] = useState("-");
  const [sog, setSOG] = useState("-");
  const [sng, setSNG] = useState("-");
  const [sodg, setSODG] = useState("-");
  const [sndg, setSNDG] = useState("-");
  const [sndSog, setSNDSOG] = useState("-");
  const [sodSng, setSODSNG] = useState("-");
  /////input polja////
  const [kvotaNaKonacanIshod1, setKvotaNaKonacanIshod1] = useState(0);
  const [kvotaNaKonacanIshod2, setKvotaNaKonacanIshod2] = useState(0);
  const [kvotaNaKonacanIshodX, setKvotaNaKonacanIshodX] = useState(0);
  const [domacinDao, setDomacinDao] = useState(0);
  const [domacinPrimio, setDomacinPrimio] = useState(0);
  const [brojUtakmicaDomacin, setBrojUtakmicaDomacin] = useState(0);
  const [brojUtakmicaGost, setBrojUtakmicaGost] = useState(0);
  const [gostDao, setGostDao] = useState(0);
  const [gostPrimio, setGostPrimio] = useState(0);
  //////////////////
  const [pobedaDomacina, setPobedaDomacina] = useState(0);
  const [pobedaGosta, setPobedaGosta] = useState(0);
  const [nereseno, setNereseno] = useState(0);
  const [golMin, setGolMin] = useState(0);
  const [golMax, setGolMax] = useState(0);
  const [golBalans, setGolBalans] = useState(0);
  const [tacanRezultatDomacin, setTacanRezultatDomacin] = useState(0);
  const [tacanRezultatGost, setTacanRezultatGost] = useState(0);
  const [pobedaSaRazlikom, setPobedaSaRazlikom] = useState(0);
  const [betkoPredlog1, setBetkoPredlog1] = useState(0);
  const [betkoPredlog2, setBetkoPredlog2] = useState(0);
  const [pobedaSaRazlikomZagrada, setPobedaSaRazlikomZagrada] = useState(0);

  return (
    <div className={classes.container}>
      <Grupa1
        imgText="FUTSAL"
        imgBackgroundColorDark="#57237E"
        imgBackgroundColorLight="#7730AD"
        imgFontColor="#DEEBF7"
        label1="VREME POČETKA"
        label2="ZEMLJA"
        label3="DOMAĆIN"
        label4="TAKMIČENJE - LIGA"
        label5="GOST"
      />
      <Grupa28
        imgBackgroundColorDark="#57237E"
        imgBackgroundColorLight="#7730AD"
        imgFontColor="#DEEBF7"
        label1={snd}
        label2={sod}
        label3={sog}
        label4={sng}
        label5={sodg}
        label6={sndg}
        label7={sndSog}
        label8={sodSng}
        calculatorType={`Futsal`}
      />
      <Grupa300
        setSND={setSND}
        setSOD={setSOD}
        setSNDG={setSNDG}
        setSOG={setSOG}
        setSNG={setSNG}
        setSODG={setSODG}
        setSNDSOG={setSNDSOG}
        setSODSNG={setSODSNG}
        kvotaNaKonacanIshod1={kvotaNaKonacanIshod1}
        setKvotaNaKonacanIshod1={setKvotaNaKonacanIshod1}
        kvotaNaKonacanIshod2={kvotaNaKonacanIshod2}
        setKvotaNaKonacanIshod2={setKvotaNaKonacanIshod2}
        kvotaNaKonacanIshodX={kvotaNaKonacanIshodX}
        setKvotaNaKonacanIshodX={setKvotaNaKonacanIshodX}
        domacinDao={domacinDao}
        setDomacinDao={setDomacinDao}
        domacinPrimio={domacinPrimio}
        setDomacinPrimio={setDomacinPrimio}
        brojUtakmicaDomacin={brojUtakmicaDomacin}
        setBrojUtakmicaDomacin={setBrojUtakmicaDomacin}
        brojUtakmicaGost={brojUtakmicaGost}
        setBrojUtakmicaGost={setBrojUtakmicaGost}
        gostDao={gostDao}
        setGostDao={setGostDao}
        gostPrimio={gostPrimio}
        setGostPrimio={setGostPrimio}
        pobedaDomacina={pobedaDomacina}
        setPobedaDomacina={setPobedaDomacina}
        pobedaGosta={pobedaGosta}
        setPobedaGosta={setPobedaGosta}
        nereseno={nereseno}
        setNereseno={setNereseno}
        golMin={golMin}
        setGolMin={setGolMin}
        golMax={golMax}
        setGolMax={setGolMax}
        golBalans={golBalans}
        setGolBalans={setGolBalans}
        tacanRezultatDomacin={tacanRezultatDomacin}
        setTacanRezultatDomacin={setTacanRezultatDomacin}
        tacanRezultatGost={tacanRezultatGost}
        setTacanRezultatGost={setTacanRezultatGost}
        pobedaSaRazlikom={pobedaSaRazlikom}
        setPobedaSaRazlikom={setPobedaSaRazlikom}
        betkoPredlog1={betkoPredlog1}
        setBetkoPredlog1={setBetkoPredlog1}
        betkoPredlog2={betkoPredlog2}
        setBetkoPredlog2={setBetkoPredlog2}
        pobedaSaRazlikomZagrada={pobedaSaRazlikomZagrada}
        setPobedaSaRazlikomZagrada={setPobedaSaRazlikomZagrada}
      />
      <div className={classes.calculator}>
        <p className={classes.pobedaDomacina}>
          {isNaN(pobedaDomacina) || pobedaDomacina == 0 ? null : pobedaDomacina}{" "}
          {isNaN(pobedaDomacina) || pobedaDomacina == 0 ? null : "%"}
        </p>
        <p className={classes.pobedaGosta}>
          {isNaN(pobedaGosta) || pobedaGosta == 0 ? null : pobedaGosta}{" "}
          {isNaN(pobedaGosta) || pobedaGosta == 0 ? null : "%"}
        </p>
        <p className={classes.nereseno}>
          {isNaN(nereseno) || nereseno == 0 ? null : nereseno}{" "}
          {isNaN(nereseno) || nereseno == 0 ? null : "%"}
        </p>
        <p className={classes.golMin}>
          {isNaN(golMin) || golMin == 0 ? null : golMin}{" "}
        </p>
        <p className={classes.golMax}>
          {isNaN(golMax) || golMax == 0 ? null : golMax}{" "}
        </p>
        <p className={classes.golBalans}>
          {isNaN(golBalans) || golBalans == 0 ? null : golBalans}{" "}
        </p>
        <p className={classes.tacanRezultatDomacin}>
          {isNaN(tacanRezultatDomacin) ? null : tacanRezultatDomacin}{" "}
        </p>
        <p className={classes.tacanRezultatGost}>
          {isNaN(tacanRezultatGost) ? null : tacanRezultatGost}{" "}
        </p>
        <p className={classes.pobedaSaRazlikom}>
          {isNaN(pobedaSaRazlikom) || pobedaSaRazlikom == 0
            ? null
            : pobedaSaRazlikom}{" "}
        </p>
        <p className={classes.pobedaSaRazlikomZagrada}>
          (
          {isNaN(pobedaSaRazlikomZagrada) || pobedaSaRazlikomZagrada == 0
            ? null
            : pobedaSaRazlikomZagrada}
          )
        </p>
        <p className={classes.betkoPredlog1}>
          {isNaN(betkoPredlog1) || betkoPredlog1 == 0 ? null : betkoPredlog1}{" "}
        </p>
        <p className={classes.betkoPredlog2}>
          {isNaN(betkoPredlog2) || betkoPredlog2 == 0 ? null : betkoPredlog2}{" "}
        </p>
      </div>
    </div>
  );
};

export default Futsal;
