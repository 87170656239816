import { message, Modal, Table, Button } from 'antd'
import axios from 'axios'
import React, { useContext, useEffect, useState } from 'react'
import classes from './AdminKorisnickiPaketiProdaja.module.css'
import AuthContext from '../context/AuthProvider'

const AdminKorisnickiPaketiProdaja = () => {
  const SERVER_URL = process.env.REACT_APP_SERVER_URL
  const { auth } = useContext(AuthContext)
  const [allPackages, setAllPackages] = useState([])
  const [addPackagesModalOpen, setAddPackagesModalOpen] = useState(false)
  const [editPackagesModalOpen, setEditPackagesModalOpen] = useState(false)
  const [deleteModalOpen, setDeleteModalOpen] = useState(false)
  const [selectedPackages, setSelectedPackages] = useState(null)
  const [refetchTrigger, setRefetchTrigger] = useState(false)

  const [packageValues, setPackageValues] = useState({
    descriptionMobile: '',
    descriptionWeb: '',
    price: '',
    packageDuration: '',
  })

  useEffect(() => {
    const getAllPackages = async () => {
      try {
        const response = await axios.get(`${SERVER_URL}/get-all-package`)
        setAllPackages(response?.data)
      } catch (err) {
        console.error('Error fetching packages:', err)
      }
    }

    getAllPackages()
  }, [refetchTrigger])

  const handleAddPackages = async () => {
    try {
      await axios.post(
        `${SERVER_URL}/create-package`,
        { ...packageValues },
        {
          withCredentials: false,
          headers: { Authorization: `Bearer ${auth.accessToken}` },
        }
      )
      setRefetchTrigger((curr) => !curr)
      setAddPackagesModalOpen(false)
      message.success('Paket uspešno dodata!')
    } catch (err) {
      console.error(err?.message || err)
      message.error(err?.message || err)
    }
  }

  const handleEditPackages = async () => {
    try {
      await axios.put(
        `${SERVER_URL}/update-package/${selectedPackages._id}`,
        { ...packageValues },
        {
          withCredentials: false,
          headers: { Authorization: `Bearer ${auth.accessToken}` },
        }
      )
      setRefetchTrigger((curr) => !curr)
      setEditPackagesModalOpen(false)
      message.success('Paket uspešno izmenjen!')
    } catch (err) {
      console.error(err?.message || err)
      message.error(err?.message || err)
    }
  }

  const handleDeletePackages = async () => {
    try {
      await axios.delete(
        `${SERVER_URL}/delete-package/${selectedPackages._id}`,
        {
          withCredentials: false,
          headers: { Authorization: `Bearer ${auth.accessToken}` },
        }
      )
      setRefetchTrigger((curr) => !curr)
      setDeleteModalOpen(false)
      message.success('Paket uspešno obrisan!')
    } catch (err) {
      console.error(err?.message || err)
      message.error(err?.message || err)
    }
  }

  const columns = [
    {
      title: 'Opis Mobilni',
      dataIndex: 'descriptionMobile',
      key: 'descriptionMobile',
      ellipsis: true,
      className: 'truncate-text',
    },
    {
      title: 'Opis Web',
      dataIndex: 'descriptionWeb',
      key: 'descriptionWeb',
      ellipsis: true,
      className: 'truncate-text',
    },
    {
      title: 'Cena u Evrima',
      dataIndex: 'price',
      key: 'price',
    },
    {
      title: 'Period u danima',
      dataIndex: 'packageDuration',
      key: 'packageDuration',
    },
    {
      title: 'Akcije',
      key: 'actions',
      render: (_, record) => (
        <span>
          <Button
            type='link'
            onClick={() => {
              setPackageValues(record)
              handleOpenEditModal(record)
            }}
          >
            Izmeni
          </Button>
          {/* <Button type='link' onClick={() => handleOpenDeleteModal(record)}>
            Obriši
          </Button> */}
        </span>
      ),
    },
  ]

  const handleOpenEditModal = (record) => {
    setSelectedPackages(record)
    setEditPackagesModalOpen(true)
  }

  const handleOpenDeleteModal = (record) => {
    setSelectedPackages(record)
    setDeleteModalOpen(true)
  }

  return (
    <div className={classes.packagesPageWrapper}>
      <div className={classes.topWrapper}>
        <h2>Svi Paketi</h2>
        <Button
          type='primary'
          onClick={() => {
            setPackageValues({
              descriptionMobile: '',
              descriptionWeb: '',
              price: '',
              packageDuration: '',
            })
            setAddPackagesModalOpen(true)
          }}
        >
          Dodaj paket
        </Button>
      </div>

      <Table
        dataSource={allPackages?.allPackages || []}
        columns={columns}
        rowKey='_id'
        className={classes.packagesTable}
        pagination={false}
      />

      {/* Add Packages Modal */}
      <Modal
        title='Dodaj Paket'
        open={addPackagesModalOpen}
        onOk={handleAddPackages}
        onCancel={() => setAddPackagesModalOpen(false)}
        className={classes.packagesModal}
        okText='Dodaj'
        cancelText='Otkaži'
      >
        <span>Opis za mobilni</span>
        <textarea
          value={packageValues?.descriptionMobile}
          onChange={(e) =>
            setPackageValues((values) => ({
              ...values,
              descriptionMobile: e.target.value,
            }))
          }
          placeholder='Opis za mobilni'
        />
        <span>Opis za WEB</span>
        <textarea
          value={packageValues?.descriptionWeb}
          onChange={(e) =>
            setPackageValues((values) => ({
              ...values,
              descriptionWeb: e.target.value,
            }))
          }
          placeholder='Opis za WEB'
        />
        <span>CENA U EVRIMA:</span>
        <input
          type='number'
          value={packageValues?.price}
          onChange={(e) =>
            setPackageValues((values) => ({
              ...values,
              price: e.target.value,
            }))
          }
          placeholder='CENA'
        />
        <span>PERIOD U DANIMA:</span>

        <input
          value={packageValues?.packageDuration}
          type='number'
          onChange={(e) =>
            setPackageValues((values) => ({
              ...values,
              packageDuration: e.target.value,
            }))
          }
          placeholder='PERIOD'
        />
      </Modal>

      {/* Edit Packages Modal */}
      <Modal
        title='Izmeni Paket'
        open={editPackagesModalOpen}
        onOk={handleEditPackages}
        onCancel={() => setEditPackagesModalOpen(false)}
        className={classes.packagesModal}
        okText='Izmeni'
        cancelText='Otkaži'
      >
        <span>Opis za mobilni</span>

        <textarea
          value={packageValues?.descriptionMobile}
          onChange={(e) =>
            setPackageValues((values) => ({
              ...values,
              descriptionMobile: e.target.value,
            }))
          }
          placeholder='Opis za mobilni'
        />
        <span>Opis za WEB</span>

        <textarea
          value={packageValues?.descriptionWeb}
          onChange={(e) =>
            setPackageValues((values) => ({
              ...values,
              descriptionWeb: e.target.value,
            }))
          }
          placeholder='Opis za WEB'
        />
        <span>CENA U EVRIMA:</span>

        <input
          type='number'
          value={packageValues?.price}
          onChange={(e) =>
            setPackageValues((values) => ({
              ...values,
              price: e.target.value,
            }))
          }
          placeholder='CENA'
        />
        <span>PERIOD U DANIMA:</span>

        <input
          value={packageValues?.packageDuration}
          type='number'
          onChange={(e) =>
            setPackageValues((values) => ({
              ...values,
              packageDuration: e.target.value,
            }))
          }
          placeholder='PERIOD'
        />
      </Modal>

      {/* Delete Packages Modal */}
      <Modal
        title='Obriši Paket'
        open={deleteModalOpen}
        onOk={handleDeletePackages}
        onCancel={() => setDeleteModalOpen(false)}
        okText='Obriši'
        cancelText='Otkaži'
      >
        Da li ste sigurni da želite da obrišete ovaj paket?
      </Modal>
    </div>
  )
}

export default AdminKorisnickiPaketiProdaja
