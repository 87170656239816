import classes from "./betKurs.module.css";
import betKurs from "../images/betKurs.png";

const BetKurs = () => {
  return (
    <div className={classes.mainCenter}>
      <p>
        Ukoliko ste doneli odluku da sami gradite kuću, veoma je bitno da u tom
        procesu znate dobro šta je potrebno da radite, te da budete upoznati sa
        svim detaljima koji će Vas u svom poduhvatu zateći, i koji će se
        pojavljivati dok proces gradnje neprivedete uspešno realizovanom kraju.
        Nije drugačije ni sa bilo čim drugim, pa ni najmanje ni sam sportskim
        klađenjem.
      </p>
      <p>
        Sportsko klađenje i klađenje uopšteno, jeste vrlo kompleksna oblast,
        celokupno satkana u jedan vrlo složen sistem funkcionisanja, postavljen
        od temelja do krova, na veoma čvrstim osnovama, svakako sažetim znanjem
        ljudi koji su učestvovali i razvijali ga, svako u svom segmentu u
        industriji istog.
      </p>
      <p>
        Uostalom, i kada gradite kuću, potrebni su Vam i inžinjeri, i arhitekte,
        ali i zidari, i stolari, i tesari, i električari, gipsari, moleri i još
        mnogi, zar ne ? Ipak, ukoliko se ta kuća gradi za Vaše potrebe, Vi ćete
        svakako želeti da znate više o svakom od segmenata potrebnih da biste
        nakon što se uselite u nju uživali u njoj, a ne suprotno tome imali
        stalne probleme, nastale kao plod nekih ili mnogih grešaka u procesu
        gradnje. Složićete se da to možemo da uporedimo sa mnogim drugim
        stvarima, a jedna od njih je svakako i sportsko klađenje i klađenje
        uopšteno.
      </p>
      <p>
        Nekome ko je ljubitelj sporta i sportske prognoze ispred svega, pružamo
        Vam mogućnost da kroz sticanje znanja kroz naš BET KURS, sa naših tačaka
        gledišta i onoga što znamo o sportskom klađenju, postanete edukovaniji i
        bolji igrač, tako što ćete kroz BET KURS, ispisan na preko 300 stranica,
        uz stalno dopunjavanje sadržaja kroz proces rada sajta, unaprediti svoje
        znanje i implementirati naučeno u svoju igru.
      </p>
      <p>
        Potrebno je da budemo racionalni, objektivni i svesni, da industrija
        sportskog klađenja nepostoji zbog toga da bi igrači igrali profitabilno,
        već pri istoj svi segmenti u organizaciji igre, od krovnih do samih
        kladionica, gledaju sasvim normalno, razumno i realno, svoje interese.
        Da je drugačije sportske kladionice i celokupna industrija sportskog i
        klađenja uopšteno, zapravo ne bi ni postojala. Ipak, to naravno ne treba
        da Vas obeshrabruje na putu da naučite nešto o onome u šta ulažete svoj
        novac, ukoliko to radite.
      </p>
      <p>
        Sa višim nivoom sopstvenog znanja o sportskom klađenju, svakako
        postižete potreban bolji efekat svoje igre. BET KURS će Vam pomoći u
        tome. Po prvi put, i sve na jednom mestu, na našem sajtu, imate mnoštvo
        kvalitetnog materijala sa sveobuhvatnom temom vezanom za sportsko
        klađenje. Dalje je sve na Vama, na Vašoj volji i želji, da li želite da
        gradite kuću bez temelja, ili ipak želite da dostignete nivo neimara sa
        zavidnim poznavanjem svih procesa gradnje.
      </p>
      <p>
        Od toga na koji način prilazite i pristupate ovom procesu, koliko često
        i na koji način čitate BET KURS edukativni materijal, svakako zavisi i
        da li ćete na kraju uživati u plodovima svoje igre, ili ćete za svaki
        neuspeh pri istoj kriviti sve, od kladionica pa na dalje, a samo ne
        sebe, i ako biste zapravo jedino objektivno Vi bili ti koji su krivi,
        jer nebiste uložili svoje vreme u sticanje potrebnog znanja o onome što
        radite. Zaobiđite kockarske manire, sportsko klađenje je kocka i sreća,
        onoliko koliko nije matematika i znanje, i suprotno tome.
      </p>
      <p>
        Ukoliko želite isključivo da gubite, ovo nije mesto za Vas, u suprotnom,
        posvetite pažnju svemu onome što Vam na istom ukazuje na to, da kroz
        edukativni proces kom ćete posvetiti vreme i volju, kroz pravilnu
        upotrebu alata koji su Vam dostupni, te kroz pravilnu upotrebu
        statistike, sutra postanete bolji u tome što kao neko ko voli da polaže
        opklade, radite to bolje u odnosu na juče, danas i na pređašnje vreme u
        kom ste očekivali rezultate igre, a da niste posvetili potrebnu pažnju
        edukaciji vezanoj za to kroz koji se proces do njih dolazi.
      </p>
      <p>
        BET KURS materijal nisu sabrana dela Fjodora Dostojevskog, niti su bilo
        kakav književni materijal, BET KURS je tema koja Vas zanima, samim tim
        što čitate ove redove, a ako čitate nešto što je u sferi Vašeg
        interesovanja, onda to svakako treba da radite sa zadovoljstvom.
      </p>
      <h2>NAUČITE SVE O SPORTSKOM KLAĐENJU I O MATEMATICI U ISTOM.</h2>
      <h2>ŽELIMO VAM UŽIVANJE U ‘’BET KURS’’ EDUKACIJI. </h2>
      <img src={betKurs} alt="betKurs" />
    </div>
  );
};

export default BetKurs;
