import React from 'react'
import { useNavigate } from 'react-router-dom'
import classes from './BetkoPlus.module.css'

const BetkoPlus = () => {
  const navigate = useNavigate()
  return (
    <div className={classes.plusPageContainer}>
      <button onClick={()=> navigate('/korisnicki_paketi/65bac125a86ff9a51a6b110f')}>KLIKNI # CENE PAKETA</button>
      <button className={classes.accentBtn}>USKORO - UPLATI PAKET ONLINE</button>
      <button onClick={()=> navigate('/korisnicki_paketi/65b7598c2ae4015b3076844c')}>KLIKNI # UPLATI PAKET</button>
      <button onClick={()=> navigate('/korisnicki_paketi/65e93b3a066b55f096da9d2b')} className={classes.accentBtn}>KLIKNI # PAMETNA KUPOVINA</button>
      <button onClick={()=> navigate('/korisnicki_paketi/65e93d1a066b55f096da9d9f')}>KLIKNI # KORISNIČKA PODRŠKA</button>
      <button onClick={()=> navigate('/korisnicki_paketi/65bae5faa86ff9a51a6b1b2b')} className={classes.accentBtn}>KLIKNI # BET KALKULATORI - OPIS</button>
      <button onClick={()=> navigate('/korisnicki_paketi/65bae44ba86ff9a51a6b1a96')} >KLIKNI # PREMIUM SADRŽAJ - OPIS</button>
      <button onClick={()=> navigate('/korisnicki_paketi/65bae51ba86ff9a51a6b1ab4')} className={classes.accentBtn}>KLIKNI # BET KURS I SISTEMI - OPIS</button>
      <button onClick={()=> navigate('/korisnicki_paketi/65bae6aea86ff9a51a6b1b45')} >KLIKNI # BESPLATAN SADRŽAJ - OPIS</button>
      <button onClick={()=> navigate('/korisnicki_paketi/65bac394a86ff9a51a6b11a7')} className={classes.accentBtn}>KLIKNI # JEDAN UREĐAJ - JEDNO LOGOVANJE</button>
      <button onClick={()=> navigate('/korisnicki_paketi/65bae745a86ff9a51a6b1b5f')} >KLIKNI # KONSULTANTSKE USLUGE</button>
    </div>
  )
}

export default BetkoPlus
