import { Outlet } from "react-router-dom";
import BetKalkulatori from "../components/BetKalkulatori";
import Header from "../components/Header";
import TitleNav from "../components/TitleNav";
import Footer from "../components/Footer";
import classes from "./betKalkulatoriLayout.module.css";

const BetKalkulatorLayout = () => {
  return (
    <>
      <Header />
      <TitleNav />
      <div className={classes.container}>
        <Outlet />
        <BetKalkulatori />
      </div>
      <Footer />
    </>
  );
};

export default BetKalkulatorLayout;
