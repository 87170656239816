import React from 'react'
import classes from './PravilaIgre.module.css'
import { useNavigate } from 'react-router-dom'
import PravilaIgreData from './PravilaIgreData'
import { IoMdArrowDropleft } from 'react-icons/io'

const PravilaIgre = () => {
  const navigate = useNavigate()
  const singlePage = new URLSearchParams(window.location.search).get('strana')

  if (singlePage) {
    return (
      <div className={classes.pageContainer}>
        <button
          className={classes.backButton}
          onClick={() => navigate('/mister_tipster/pravila-igre')}
        >
          <IoMdArrowDropleft />
          <span>NAZAD</span>
        </button>
        <div className={classes.titleWrapper}>
          <button>"{PravilaIgreData[singlePage].title}"</button>
        </div>
        {PravilaIgreData[singlePage].texts.map((text, index) => (
          <div className={classes.textWrapper} key={index}>
            {text.split('\n').map((line, idx) => (
              <p key={idx}>{line}</p>
            ))}{' '}
          </div>
        ))}
      </div>
    )
  }

  return (
    <div className={classes.pageContainer}>
      <button
        className={classes.backButton}
        onClick={() => navigate('/mister_tipster')}
      >
        <IoMdArrowDropleft />
        <span>NAZAD</span>
      </button>
      <div className={classes.titleWrapper}>
        <button
          onClick={() =>
            navigate('/mister_tipster/pravila-igre?strana=otvaranje-profila')
          }
        >
          OTVARANJE PROFILA
        </button>
      </div>
      <div className={classes.titleWrapper}>
        <button
          onClick={() =>
            navigate('/mister_tipster/pravila-igre?strana=email-notifikacije')
          }
        >
          E-MAIL NOTIFIKACIJE
        </button>
      </div>
      <div className={classes.titleWrapper}>
        <button
          onClick={() =>
            navigate('/mister_tipster/pravila-igre?strana=tipster-match')
          }
        >
          “TIPSTER MATCH”
        </button>
      </div>
      <div className={classes.titleWrapper}>
        <button
          onClick={() =>
            navigate(
              '/mister_tipster/pravila-igre?strana=tipster-rang-takmicenje'
            )
          }
        >
          “TIPSTER RANG TAKMIČENJE”
        </button>
      </div>
      <div className={classes.titleWrapper}>
        <button
          onClick={() =>
            navigate(
              '/mister_tipster/pravila-igre?strana=tipster-mesecno-takmicenje'
            )
          }
        >
          “TIPSTER MESEČNO TAKMIČENJE”
        </button>
      </div>
      <div className={classes.titleWrapper}>
        <button
          onClick={() =>
            navigate(
              '/mister_tipster/pravila-igre?strana=tipster-godisnje-takmicenje'
            )
          }
        >
          “TIPSTER GODIŠNJE TAKMIČENJE”
        </button>
      </div>
      <div className={classes.titleWrapper}>
        <button
          onClick={() =>
            navigate(
              '/mister_tipster/pravila-igre?strana=tipster-globalno-takmicenje'
            )
          }
        >
          “TIPSTER GLOBALNO TAKMIČENJE”
        </button>
      </div>
      <div className={classes.titleWrapper}>
        <button
          onClick={() =>
            navigate(
              '/mister_tipster/pravila-igre?strana=periodi-takmicenja-i-takmicarske-nagrade'
            )
          }
        >
          USLOVI I PERIODI TAKMIČENJA I TAKMIČARSKE NAGRADE
        </button>
      </div>
      <div className={classes.titleWrapper}>
        <button
          onClick={() =>
            navigate('/mister_tipster/pravila-igre?strana=tipster-profil')
          }
        >
          "TIPSTER PROFIL"
        </button>
      </div>
    </div>
  )
}

export default PravilaIgre
