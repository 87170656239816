import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import classes from './MisterTipsterSingleMatch.module.css'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import moment from 'moment'
dayjs.extend(utc)

const MisterTipsterSingleMatch = ({
  pair,
  selectedBets,
  setSelectedBets,
  isMatchPage,
  isTipsterProfile,
  userBets,
  isTipsterProfileTable,
  index,
}) => {
  const navigate = useNavigate()
  const [isBlockedMatch, setIsBlockedMatch] = useState(false)

  const handleTipSelect = (selectedBet, odds) => {
    if (!isMatchPage) {
      navigate('/mister_tipster/match')
      return
    }

    setSelectedBets((bets) => {
      const existingBet = bets.find((bet) => bet?.pair === pair._id)

      if (existingBet) {
        return bets.map((bet) =>
          bet?.pair === pair._id
            ? { ...bet, betType: selectedBet, odds: odds }
            : bet
        )
      }

      return [...bets, { pair: pair._id, betType: selectedBet, odds: odds }]
    })
  }

  useEffect(() => {
    const checkMatchStatus = () => {
      const serbianTime = new Date().toLocaleString('sr-RS')
      const [day, month, year, time] = serbianTime.split('.')
      const formatedSerbianTime = `${month}.${day}.${year}.${time}`
      const pairDate = moment.utc(pair.date)
      const nowUtc = moment.utc(formatedSerbianTime)
      const isAfter = pairDate.isAfter(nowUtc)

      if (!isAfter) {
        setSelectedBets((bets) => bets.filter((bet) => bet?.pair !== pair._id))
      }
      setIsBlockedMatch(!isAfter)
    }

    // Check the match status immediately
    checkMatchStatus()

    // Set up an interval to check the match status every second
    const intervalId = setInterval(checkMatchStatus, 1000)

    // Clear the interval when the component unmounts
    return () => clearInterval(intervalId)
  }, [pair.date])

  const hasPlacedBets = userBets && userBets[0]?.bets?.length > 0

  const isSelected = (betType) => {
    if (!hasPlacedBets) {
      const singleBetObject = selectedBets?.find(
        (bet) => bet?.pair === pair._id
      )
      return singleBetObject?.betType === betType ? classes.isSelected : ''
    }

    const singleBetObject = userBets[0].bets?.find(
      (bet) => bet?.pair?._id === pair._id
    )
    return singleBetObject?.betType === betType
      ? `${classes.isSelected} ${classes.isPlacedBet}`
      : ''
  }

  const isEntered = (betType) => {
    if (!userBets) {
      return
    }
    const singleBetObject = userBets[0].bets?.find(
      (bet) => bet?.pair?._id === pair._id
    )

    const enteredChecks = {
      X: pair?.isEnteredX,
      1: pair?.isEntered1,
      2: pair?.isEntered2,
    }

    return singleBetObject?.betType === betType && enteredChecks[betType]
      ? classes.win
      : ''
  }

  const isFailed = (betType) => {
    if (!userBets) {
      return
    }
    const singleBetObject = userBets[0].bets?.find(
      (bet) => bet?.pair?._id === pair._id
    )

    const enteredChecks = {
      X: pair?.isEntered1 || pair?.isEntered2,
      1: pair?.isEnteredX || pair?.isEntered2,
      2: pair?.isEnteredX || pair?.isEntered1,
    }

    return singleBetObject?.betType === betType && enteredChecks[betType]
      ? classes.fail
      : ''
  }

  const getBetClassname = (betType) => {
    return ` ${
      isBlockedMatch && !isTipsterProfile ? classes.blockedMatch : ''
    } ${isEntered(betType)} ${isFailed(betType)} ${isSelected(betType)}`
  }

  return (
    <div
      className={`${classes.tipsterMatch} ${
        index !==undefined  && index % 2 !== 0 ? classes.blc : ''
      }`}
    >
      <div
        className={`${classes.tipsteMatchWrapper} ${
          isTipsterProfileTable ? classes.profileTable : ''
        }`}
      >
        <div className={classes.tipBtnsContainer}>
          <button
            className={getBetClassname('1')}
            onClick={() => handleTipSelect('1', pair?.odds1)}
            disabled={isBlockedMatch || hasPlacedBets}
          >
            {pair?.odds1?.toFixed(2)}
          </button>
          <button
            className={getBetClassname('X')}
            onClick={() => handleTipSelect('X', pair?.oddsX)}
            disabled={isBlockedMatch || hasPlacedBets}
          >
            {pair?.oddsX?.toFixed(2)}
          </button>
          <button
            className={getBetClassname('2')}
            onClick={() => handleTipSelect('2', pair?.odds2)}
            disabled={isBlockedMatch || hasPlacedBets}
          >
            {pair?.odds2?.toFixed(2)}
          </button>
        </div>
        <div className={classes.matchInfo}>
          <div className={classes.matchLeague}>{pair?.league}</div>
          <div className={classes.timeAndOpponents}>
            <div
              className={`${classes.matchTime} ${
                isBlockedMatch && !isTipsterProfile
                  ? classes.blockedMatchTime
                  : ''
              }`}
            >
              {isBlockedMatch && !isTipsterProfile ? (
                <span>
                  UTAKMICA JE <br /> POČELA
                </span>
              ) : (
                pair?.date.substring(11, 16)
              )}
            </div>
            <div className={classes.matchOpponents}>
              <div>{pair?.club1}</div>
              <div>{pair?.club2}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default MisterTipsterSingleMatch
