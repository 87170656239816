import React from 'react'
import classes from './DeniedPayment.module.css'
import { GiCancel } from 'react-icons/gi'
import { useNavigate } from 'react-router-dom'

const DeniedPayment = () => {
  const navigate = useNavigate()

  return (
    <div className={classes.deniedPageContianer}>
      <div className={classes.iconWrapper}>
        <GiCancel />
      </div>
      <h2>
        NALOG ZA PLAĆANJE <br /> NIJE USPEŠNO POSLAT!
      </h2>
      <p>
        DA BI STE ISPRAVILI POGREŠNE UNOSE, VRATITE SE KORAK U NAZAD KLIKOM NA
        <br />
        DUGME "PROVERI UNOSE ZA UPLATU".
      </p>
      <p>
        IZVRŠITE UPLATU PRAVILNO I VAŠ PRETPLATNIČKI PAKET ĆE BITI AKTIVIRAN.
      </p>
      <button onClick={() => navigate('/korisnicki_paketi/kupi-paket')}>
        PROVERI UNOSE ZA UPLATU
      </button>
    </div>
  )
}

export default DeniedPayment
