import React, { useContext, useEffect, useState } from 'react'
import {
  Table,
  Button,
  Modal,
  Form,
  Input,
  message,
  Radio,
  Space,
  InputNumber,
  Upload,
  notification,
} from 'antd'
import { DeleteOutlined, EditOutlined, UploadOutlined } from '@ant-design/icons'
import classes from './shopAdminProducts.module.css'
import axios from 'axios'
import AuthContext from '../context/AuthProvider'
import { FaCheck } from 'react-icons/fa'
import { MdDelete } from 'react-icons/md'
import useAxiosPrivate from '../hooks/useAxiosPrivate'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'

const SERVER_URL = process.env.REACT_APP_SERVER_URL
const { Group: RadioGroup, Button: RadioButton } = Radio

const ShopAdminProducts = () => {
  const [isModalVisible, setIsAddOrDeleteModalVisible] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)
  const [shopType, setShopType] = useState(null)
  const [allCategories, setAllCategories] = useState([])
  const [attributes, setAttributes] = useState([])
  const [selectedCategory, setSelectedCategory] = useState('')
  const [imageUrl, setImageUrl] = useState(null)
  const [imageFile, setImageFile] = useState(null)
  const { auth } = useContext(AuthContext)
  const [allProducts, setAllProducts] = useState(null)
  const [fetcApprovedProducts, setFetcApprovedProducts] = useState(true)
  const [searchTerm, setSearchTerm] = useState(null)
  const [refetchTrigger, setRefetchTrigger] = useState(false)
  const [selectedProduct, setSelectedProduct] = useState(null)
  const [isApproveModalVisible, setIsApproveModalVisible] = useState(false)
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false)
  const [form] = Form.useForm()
  const axiosPrivate = useAxiosPrivate()
  const [selectedDate, setSelectedDate] = useState(null)
  const [tourStartDate, setTourStartDate] = useState(null)
  const [tourEndDate, setTourEndDate] = useState(null)

  const isAdmin = auth.roles.includes(5151)

  useEffect(() => {
    const getAllProducts = async () => {
      try {
        let response = null
        if (isAdmin) {
          response = await axiosPrivate.get(
            `${SERVER_URL}/${
              fetcApprovedProducts ? 'get-all-product' : 'get-pending-products'
            }/${currentPage}/10/${searchTerm || null}${
              fetcApprovedProducts ? '/null/null/null' : ''
            }`
          )
        } else {
          response = await axiosPrivate.get(
            `${SERVER_URL}/get-by-user/${auth._id}/${currentPage}/10/${
              searchTerm || null
            }`
          )
        }
        setAllProducts(response?.data)
      } catch (error) {
        setAllProducts([])
        message.error(
          error?.response?.data?.message || 'Greška pri preuzimanju proizvoda!'
        )
      }
    }

    getAllProducts()
  }, [fetcApprovedProducts, searchTerm, refetchTrigger, currentPage])

  useEffect(() => {
    const getAllCategories = async () => {
      try {
        const response = await axios.get(
          `${SERVER_URL}/categories-get-all/${null}/${null}/`
        )
        setAllCategories(response?.data.paginatedData)
      } catch (error) {
        setAllCategories([])
        message.error(
          error?.response?.data?.message || 'Greška pri preuzimanju kategorija!'
        )
      }
    }

    getAllCategories()
  }, [shopType])

  const handleOpenAddProductFrom = () => {
    form.resetFields()
    setImageUrl('')
    setAttributes([])
    setIsAddOrDeleteModalVisible(true)
  }

  const handleEditProduct = (record) => {
    setSelectedProduct(record)
    setSelectedCategory(record?.category?._id)
    setShopType(record?.shopType)
    setSelectedDate(new Date(record?.expireDate || ''))
    setTourStartDate(
      record?.startTourDate ? new Date(record?.startTourDate) : new Date()
    )
    setTourEndDate(
      record?.endTourDate ? new Date(record?.endTourDate) : new Date()
    )
    setImageUrl(`${SERVER_URL}/${record?.images?.url}` || null)
    form.setFieldsValue(record)
    setIsAddOrDeleteModalVisible(true)
  }
  const handleCreateProduct = async () => {
    try {
      const values = await form.validateFields()

      if (!selectedCategory) {
        notification.error({
          message: 'Molimo izaberite kategoriju!',
          placement: 'topRight',
        })
        return
      }

      const requestBody = {
        ...values,
        createdBy: auth?._id,
        user: auth?._id,
        category: selectedCategory,
        expireDate: selectedDate,
        startTourDate: tourStartDate,
        endTourDate: tourEndDate,
      }

      if (imageFile) {
        const formData = new FormData()
        formData.append('file', imageFile)
        formData.append('imageType', 'avatar')
        // Upload the image

        const response = await axios.post(
          `${SERVER_URL}/images/upload-image`,
          formData,
          {
            withCredentials: false,
            headers: { 'Content-Type': 'multipart/form-data' },
          }
        )
        if (response.status === 201) {
          requestBody.images = response?.data?.data?._id
        } else {
          throw new Error('Image upload failed')
        }
      }

      if (!selectedProduct) {
        await axiosPrivate.post(`${SERVER_URL}/create-product`, requestBody)
      } else {
        await axiosPrivate.put(
          `${SERVER_URL}/update-product/${selectedProduct._id}`,
          requestBody
        )
      }

      setRefetchTrigger((curr) => !curr)
      setIsAddOrDeleteModalVisible(false)
      setImageUrl('')
      setAttributes([])
      form.resetFields()
      setSelectedProduct(null)
      message.success(
        `Uspešno ste ${selectedProduct ? 'izmenili' : 'dodali'} proizvod!`
      )
    } catch (error) {
      notification.error({
        message: error?.response?.data?.message,
        placement: 'topRight',
      })
    }
  }

  const handleApproveProduct = async () => {
    try {
      await axios.put(
        `${SERVER_URL}/approve-product/${selectedProduct?._id}`,
        null,
        {
          withCredentials: false,
          headers: { Authorization: `Bearer ${auth.accessToken}` },
        }
      )
      message.success('Uspešno ste odobrili proizvod!')
      setIsApproveModalVisible(false)
      setRefetchTrigger((curr) => !curr)
    } catch (error) {
      message.error(
        error?.response?.data?.message || 'Greška pri odobravanju proizvoda!'
      )
    }
  }

  const handleDeleteProduct = async () => {
    try {
      await axiosPrivate.delete(
        `${SERVER_URL}/delete-product/${selectedProduct?._id}`,
        null
      )
      message.success('Uspešno ste uklonili proizvod!')
      setIsDeleteModalVisible(false)
      setSelectedProduct(null)
      setRefetchTrigger((curr) => !curr)
    } catch (error) {
      message.error(
        error?.response?.data?.message || 'Greška pri uklanjanju proizvoda!'
      )
    }
  }

  const handleAddAttribute = () => {
    setAttributes([...attributes, { name: '', value: '' }])
  }

  const handleRemoveAttribute = (index) => {
    setAttributes(attributes.filter((_, i) => i !== index))
  }

  const handleAttributeChange = (index, field, value) => {
    const updatedAttributes = [...attributes]
    updatedAttributes[index][field] = value
    setAttributes(updatedAttributes)
  }

  const handleFileValidation = (file) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png'
    const isLt500KB = file.size / 1024 < 500

    if (!isJpgOrPng) {
      notification.error({
        message: 'Dozvoljeni formati slike su JPEG i PNG',
      })
    }

    if (!isLt500KB) {
      notification.error({
        message: 'Maksimalna velicina slike je 500kb',
      })
    }

    return isJpgOrPng && isLt500KB
  }

  const columns = [
    {
      title: 'Naziv',
      dataIndex: 'title',
      key: 'title',
    },
    {
      title: 'Fotografija',
      dataIndex: 'images',
      key: 'images',
      render: (images) => (
        <div className={classes.tableImageWrapper}>
          <img
            src={`${SERVER_URL}/${images?.url}` || '/tiger.webp'}
            alt='Product'
          />
        </div>
      ),
    },
    {
      title: 'Cena',
      dataIndex: 'price',
      key: 'price',
    },
    {
      title: 'Početna cena',
      dataIndex: 'startingPrice',
      key: 'startingPrice',
    },
    {
      title: 'Tip prodavnice',
      dataIndex: 'shopType',
      key: 'shopType',
    },
    {
      title: 'Kategorija',
      dataIndex: ['category', 'name'],
      key: 'category',
    },
    {
      title: 'Stanje',
      dataIndex: 'onStock',
      key: 'onStock',
      render: (onStock) => (
        <span>{onStock ? 'Na stanju' : 'Nema na stanju'}</span>
      ),
    },
    {
      title: 'Status',
      dataIndex: 'listingStatus',
      key: 'listingStatus',
    },
    {
      title: 'Akcije',
      key: 'actions',
      render: (_, record) => (
        <span>
          <Button
            icon={<EditOutlined />}
            onClick={() => handleEditProduct(record)}
          />
          <Button
            icon={<DeleteOutlined />}
            title='Ukloni Prozivod'
            style={{ marginBlock: 8 }}
            onClick={() => {
              setIsDeleteModalVisible(true)
              setSelectedProduct(record)
            }}
          />
          {auth?.status === 'Admin' && !fetcApprovedProducts && (
            <Button
              icon={<FaCheck />}
              onClick={() => {
                setIsApproveModalVisible(true)
                setSelectedProduct(record)
              }}
              title='Odobri Prozivod'
            />
          )}
        </span>
      ),
    },
  ]

  return (
    <div className={classes.shopAdminProducts}>
      <div className={classes.top}>
        <div>
          <h2>PROIZVODI</h2>
          <Input
            type='text'
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            placeholder='Pretraži'
            className={classes.searchTermInput}
          />
        </div>
        <div>
          {auth?.status === 'Admin' && (
            <Button
              type='primary'
              onClick={() => setFetcApprovedProducts((curr) => !curr)}
              className={classes.addButton}
            >
              {fetcApprovedProducts
                ? 'Pogledaj neodobrene proizvode'
                : 'Pogledaj odobrene proizvode'}
            </Button>
          )}
          <Button
            type='primary'
            onClick={handleOpenAddProductFrom}
            className={classes.addButton}
          >
            Dodaj Proizvod
          </Button>
        </div>
      </div>
      <div className='productTableContainer'>
        <Table
          columns={columns}
          dataSource={allProducts?.paginatedData}
          className={classes.productTable}
          pagination={{
            current: currentPage,
            pageSize: 10,
            total: allProducts?.totalItems,
            onChange: (page) => setCurrentPage(page),
            showSizeChanger: false,
          }}
        />
      </div>

      <Modal
        open={isDeleteModalVisible}
        title='UKLONI PROIZVOD'
        onOk={handleDeleteProduct}
        onCancel={() => {
          setIsDeleteModalVisible(false)
          setSelectedProduct(null)
        }}
      >
        <MdDelete className={classes.deleteIcon} />
        Da li ste sigurni da želite da uklonite proizvod pod nazivom{' '}
        {selectedProduct?.title}?
      </Modal>
      <Modal
        open={isApproveModalVisible}
        title='ODOBRI PROIZVOD'
        onOk={handleApproveProduct}
        onCancel={() => {
          setIsApproveModalVisible(false)
          setSelectedProduct(null)
        }}
      >
        Da li ste sigurni da želite da odobrite proizvod pod nazivom{' '}
        {selectedProduct?.title}?
      </Modal>
      <Modal
        title={selectedProduct ? 'IZMENI PROIZVOD' : 'DODAJ PROIZVOD'}
        open={isModalVisible}
        onOk={handleCreateProduct}
        onCancel={() => {
          setIsAddOrDeleteModalVisible(false)
          setSelectedProduct(null)
        }}
      >
        <Form
          form={form}
          layout='vertical'
          initialValues={{ onStock: true, isPopular: false }}
        >
          <Form.Item
            name='title'
            label='Naziv'
            rules={[{ required: true, message: 'Molimo unesite naziv!' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name='image'
            label='Fotografija'
            rules={[
              {
                required: !imageUrl,
                message: 'Fotografija je obavezna!',
              },
            ]}
          >
            <Upload
              listType='picture-card'
              showUploadList={false}
              beforeUpload={(file) => {
                const isValid = handleFileValidation(file)
                if (isValid) {
                  const imageUrl = URL.createObjectURL(file)
                  setImageUrl(imageUrl)
                  setImageFile(file)
                }
                return false
              }}
            >
              {imageUrl ? (
                <div className={classes.uploadWrapper}>
                  <img src={imageUrl} alt='Product' style={{ width: '100%' }} />
                </div>
              ) : (
                <div className={classes.uploadWrapper}>
                  <UploadOutlined /> Dodaj
                </div>
              )}
            </Upload>
          </Form.Item>

          <Form.Item
            label='Tip prodavnice'
            name='shopType'
            rules={[
              {
                required: true,
                message: 'Tip prodavnice je obavezan!',
              },
            ]}
          >
            <RadioGroup
              className={classes.radioGroup}
              value={shopType}
              onChange={(e) => {
                setSelectedCategory('')
                setShopType(e.target.value)
              }}
            >
              <RadioButton value='shop'>SHOP</RadioButton>
              <RadioButton value='auction'>LICITACIJA</RadioButton>
              <RadioButton value='sport-tourist'>SPORT TURIST</RadioButton>
            </RadioGroup>
          </Form.Item>
          {isAdmin && (
            <Form.Item
              label='Popularan proizvod'
              name='isPopular'
              rules={[
                {
                  required: true,
                  message: 'Polje je obavezno!',
                },
              ]}
            >
              <RadioGroup className={classes.radioGroup} name='isPopular'>
                <RadioButton value={true}>Da</RadioButton>
                <RadioButton value={false}>Ne</RadioButton>
              </RadioGroup>
            </Form.Item>
          )}
          <Form.Item
            label='Stanje'
            name='onStock'
            rules={[
              {
                required: true,
                message: 'Stanje na zalihama je obavezan!',
              },
            ]}
          >
            <RadioGroup className={classes.radioGroup} name='onStock'>
              <RadioButton value={true}>
                Ima{' '}
                {shopType === 'sport-tourist'
                  ? 'slobodnih mesta'
                  : 'na zalihama'}
              </RadioButton>
              <RadioButton value={false}>
                Nema{' '}
                {shopType === 'sport-tourist'
                  ? 'slobodnih mesta'
                  : 'na zalihama'}
              </RadioButton>
            </RadioGroup>
          </Form.Item>

          <div className={classes.categorySelectWrapper}>
            * Kategorija
            <select
              value={selectedCategory}
              onChange={(e) => setSelectedCategory(e.target.value)}
            >
              <option value='' disabled>
                Izaberite Kategoriju
              </option>
              {allCategories
                ?.filter((category) => {
                  const shopType = form.getFieldValue('shopType')
                  return category.typeShop === shopType
                })
                .sort((a, b) =>
                  a.name === 'OSTAVI ADMINU DA ODREDI KATEGORIJU' ? -1 : 1
                )
                .map((category) => (
                  <option key={category._id} value={category._id}>
                    {category.name}
                  </option>
                ))}
            </select>
          </div>

          {shopType === 'sport-tourist' && (
            <div className={classes.tourDatePickers}>
              <div>
                <span className={classes.exipreSpan}>* Datum početka ture</span>
                <DatePicker
                  selected={tourStartDate || new Date()}
                  onChange={(date) => setTourStartDate(date)}
                  dateFormat='yyyy-MM-dd'
                  label='Datum početka ture'
                />
              </div>
              <div>
                <span className={classes.exipreSpan}>
                  * Datum završetka ture
                </span>
                <DatePicker
                  selected={tourEndDate || new Date()}
                  onChange={(date) => setTourEndDate(date)}
                  dateFormat='yyyy-MM-dd'
                  label='Datum završetka ture'
                />
              </div>
            </div>
          )}

          {shopType === 'auction' ? (
            <>
              {allCategories.find((cat) => cat.name === 'SMS POMOĆ')?._id ===
              selectedCategory ? (
                <Form.Item
                  name='sms'
                  label='SMS na'
                  rules={[
                    {
                      required: false,
                      message: 'Molimo broj za SMS !',
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              ) : allCategories.find((cat) => cat.name === 'POMOĆ NA RAČUN')?._id ===
                selectedCategory ? (
                <Form.Item
                  name='cardNumber'
                  label='Tekući račun'
                  rules={[
                    {
                      required: false,
                      message: 'Molimo unesite tekući račun !',
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              ) : (
                <Form.Item
                  name='startingPrice'
                  label='Početna cena'
                  rules={[
                    {
                      required: false,
                      message: 'Molimo unesite početnu cenu!',
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              )}
              <span className={classes.exipreSpan}>* Datum Isteka</span>
              <DatePicker
                selected={selectedDate || new Date()}
                onChange={(date) => setSelectedDate(date)}
                showTimeSelect
                timeFormat='HH:mm'
                dateFormat='yyyy-MM-dd HH:mm:ss'
                label='Datum Isteka'
              />
            </>
          ) : (
            <Form.Item
              name='price'
              label='Cena'
              rules={[
                { required: true, message: 'Molimo unesite isparavnu cenu!' },
              ]}
            >
              <InputNumber min={1} suffix='RSD' style={{ width: 250 }} />
            </Form.Item>
          )}
          {shopType !== 'auction' && (
            <Form.Item label='Atributi'>
              <span className={classes.attributesNote}>
                * Vrednost može sadržati više varijacija koje je potrebno
                odvojiti zarezom
                <br /> npr. Naziv = Veličina, Vrednost = S, M, L, XL
              </span>
              {attributes?.map((attr, index) => (
                <Space
                  key={index}
                  style={{ display: 'flex', marginBottom: 8 }}
                  align='baseline'
                >
                  <Form.Item
                    name={['attributes', index, 'name']}
                    style={{ flex: 1 }}
                    rules={[
                      {
                        required: true,
                        message: 'Molimo unesite naziv atributa!',
                      },
                    ]}
                  >
                    <Input
                      placeholder='Naziv'
                      value={attr.name}
                      onChange={(e) =>
                        handleAttributeChange(index, 'name', e.target.value)
                      }
                    />
                  </Form.Item>
                  <Form.Item
                    name={['attributes', index, 'value']}
                    style={{ flex: 1 }}
                    rules={[
                      {
                        required: true,
                        message: 'Molimo unesite vrednost atributa!',
                      },
                    ]}
                  >
                    <Input
                      placeholder='Vrednost'
                      value={attr.value}
                      onChange={(e) =>
                        handleAttributeChange(index, 'value', e.target.value)
                      }
                    />
                  </Form.Item>
                  <Button
                    type='danger'
                    onClick={() => handleRemoveAttribute(index)}
                    icon={<DeleteOutlined />}
                  />
                </Space>
              ))}
              <Button
                block
                size='small'
                type='primary'
                onClick={handleAddAttribute}
              >
                Dodaj još 1 atribut
              </Button>
            </Form.Item>
          )}
          <Form.Item name='description' label='Opis'>
            <Input.TextArea rows={3} />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  )
}

export default ShopAdminProducts
