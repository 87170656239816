import classes from "./adminSingleUser.module.css";
import { useNavigate } from "react-router-dom";
import { format } from "date-fns";

const AdminSingleUser = ({ user }) => {
  const navigate = useNavigate();
  const dateNow = new Date().getTime();
  const userId = user._id;

  const handleChange = (userId) => {
    navigate("/betko-admin/korisnik-detalji", { state: { userId } });
  };

  let date;
  if (dateNow >= user.paidToDate) {
    date = "Istekao";
  } else {
    date = format(new Date(user.paidToDate), "dd-MM-yyyy");
  }

  return (
    <div className={classes.singleUser} onClick={() => handleChange(userId)}>
      <div className={classes.left}>
        <p>
          <span className={classes.title}>Plaćeno do:</span>
          {user.status === "Admin" || user.status === "Vip"
            ? "Neograničeno"
            : date}
        </p>
      </div>
      <div className={classes.center}>
        <p>
          <span className={classes.title}>Status:</span>
          {user.status}
        </p>
      </div>
      <div className={classes.right}>
        <p>
          <span className={classes.title}>Korisnik:</span> {user.email}
        </p>
      </div>
    </div>
  );
};

export default AdminSingleUser;
