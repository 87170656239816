import React, { useEffect, useRef, useState } from 'react'
import classes from './AdminComments.module.css'
import axios from 'axios'
import {
  MdOutlineKeyboardDoubleArrowLeft,
  MdKeyboardDoubleArrowRight,
} from 'react-icons/md'
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io'

const limit = 1

const PaginationBullet = ({ data, current, onTarget }) => {
  return (
    <>
      {data.map((item, idx) => {
        return (
          <button
            key={idx}
            onClick={() => onTarget(item)}
            className={`${classes.targetPage} ${current === item ? classes.isActive : ''
              }`}
          >
            <span>{item}</span>
          </button>
        )
      })}
    </>
  )
}

const Pagination = ({
  current,
  setCurrentPage,
  limitPage,
  buttonLimit,
  pagesCount,
  totalCount,
}) => {
  const onPrev = () => {
    setCurrentPage((prev) => prev - 1)
  }
  const onNext = () => {
    setCurrentPage((prev) => prev + 1)
  }
  const onFirst = () => {
    setCurrentPage(1)
  }
  const onLast = () => {
    setCurrentPage(pagesCount)
  }
  const onTarget = (target) => {
    setCurrentPage(target)
  }

  const numbers = []
  for (let i = 1; i <= Math.ceil(totalCount / limitPage); i++) {
    numbers.push(i)
  }

  const getPaginationButton = () => {
    let numShown = buttonLimit
    let start = Math.min(numShown, numbers.length)
    let first = current - Math.floor(start / 2)
    first = Math.max(first, 1)
    first = Math.min(first, numbers.length - start + 1)

    return [...Array(start)].map((k, i) => i + first)
  }

  const paginationButton = getPaginationButton()
  const maxOfNumbers = Math.max(...numbers)
  const minOfNumbers = Math.min(...numbers)

  return (
    <div className={classes.pagination}>
      <button
        className={`${classes.firstPage} ${paginationButton.length > 1 && current !== minOfNumbers
          ? classes.show
          : ''
          }`}
        onClick={() => onFirst()}
      >
        <MdOutlineKeyboardDoubleArrowLeft />
      </button>

      <button
        className={`${classes.pageBack} ${current <= 1 ? classes.disabled : ''
          }`}
        onClick={() => onPrev()}
        disabled={`${current <= 1 ? classes.disabled : ''}`}
      >
        <IoIosArrowBack />
      </button>

      <div className={classes.targetPageContainer}>
        <PaginationBullet
          data={paginationButton}
          current={current}
          onTarget={onTarget}
        />
      </div>

      <button
        className={`${classes.pageForward} ${current === numbers.length ? classes.disabled : ''
          }`}
        onClick={() => onNext()}
        disabled={`${current === numbers.length || totalCount === 0 ? classes.disabled : ''
          }`}
      >
        <IoIosArrowForward />
      </button>

      <button
        className={`${classes.lastPage} ${paginationButton.length > 1 && current !== maxOfNumbers
          ? classes.show
          : ''
          }`}
        onClick={() => onLast()}
      >
        <MdKeyboardDoubleArrowRight />
      </button>
    </div>
  )
}

function AdminBlockedUsers() {
  // server
  const SERVER_URL = process.env.REACT_APP_SERVER_URL
  // ref
  const searchRef = useRef(null)

  // current date
  const currentDate = new Date()
  currentDate.setHours(0, 0, 0, 0)

  // states
  const [pageBlockedUsers, setPageBlockedUsers] = useState([])
  //search
  const [search, setSearch] = useState(null)
  const [searchForm, setSearchForm] = useState({
    query: '',
  })
  // pagination
  const [pageNumber, setPageNumber] = useState(1)
  const [totalCount, setTotalCount] = useState(1)
  const [pageCount, setPageCount] = useState(1)
  // refetch
  const [refetch, setRefetch] = useState(false)
  // fetch functions
  const fetchBlockedUsers = async () => {
    const payload = {
      searchTerm: search
    }
    try {
      await axios
        .post(
          `${SERVER_URL}/user/getBlockedUsers/${pageNumber}/${limit}/null`,
          payload,
          { withCredentials: false }
        )
        .then((res) => {
          if (res.status === 200) {
            setPageBlockedUsers(res.data.data)
            setTotalCount(res?.data?.totalCount)
            setPageCount(res?.data?.pageCount)
          }
        })
    } catch (error) {
      console.error(error)
    }
  }

  // useEffects
  useEffect(() => {
    fetchBlockedUsers()

    return () => { }
  }, [refetch, pageNumber])

  useEffect(() => {
    if (pageBlockedUsers && pageBlockedUsers.length < 1) {
      if (pageNumber === 1) {
        return
      } else {
        setPageNumber((prev) => prev - 1)
      }
    }
  }, [pageBlockedUsers])

  // functions
  const handleChangeSearch = (e) => {
    const { value } = e.target

    setSearchForm({ query: value })
  }

  const handleOnSubmitSearch = async (e) => {
    e.preventDefault()
    setSearch(searchForm.query)
    setRefetch((prev) => !prev)
  }

  const pagesCountComments = pageCount
  const totalCountComments = totalCount

  // props
  const paginationProps = {
    current: pageNumber,
    setCurrentPage: setPageNumber,
    limitPage: limit,
    buttonLimit: 3,
    pagesCount: pagesCountComments,
    totalCount: totalCountComments,
  }

  return (
    <div className={classes.container}>
      <div className={classes.commentsHeader}>
        <h1>Blokirani korisnici</h1>
        <button className={classes.searchBlockedUser} onClick={() => searchRef.current.focus()}>Pretrazi blokiranog korisnika</button>
        <form onSubmit={(e) => handleOnSubmitSearch(e)}>
          <input
            ref={searchRef}
            value={searchForm.query || ''}
            type='text'
            onChange={(e) => handleChangeSearch(e)}
            placeholder='search...'
          />
          <div>
            <button type='submit'>Search</button>
          </div>
        </form>
      </div>

      <div className={classes.tableContainer}>
        <table className={classes.commentTable}>
          <thead>
            <tr>
              <th>Blokiran korisnik</th>
              <th>Blokirao</th>
              <th>Razlog</th>
            </tr>
          </thead>
          <tbody>
            {pageBlockedUsers && pageBlockedUsers.length > 0 ? (
              pageBlockedUsers.map((item, index) => {
                return item.blockedBy.map((i, idx) => {
                  return (
                    <tr key={idx}>
                      <td>{item?.email}</td>
                      <td>{i?.user?.email}</td>
                      <td>{i?.reason}</td>
                    </tr>
                  )
                })
              })
            ) : (
              <tr>
                <td
                  style={{
                    fontSize: '20px',
                    fontWeight: 'bold',
                    textTransform: 'uppercase',
                    letterSpacing: '2px',
                    textAlign: 'center',
                  }}
                  colSpan={3}
                >
                  Nema blokiranih korisnika
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      {/* <Pagination {...paginationProps} /> */}
    </div>
  )
}

export default AdminBlockedUsers
