import React, { useContext, useEffect, useRef, useState } from 'react'
import classes from './BetkoShopPage.module.css'
import BetkoShopLayout from './BetkoShopLayout'
import { BiCaretLeft, BiCaretRight } from 'react-icons/bi'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { IoCaretDownSharp, IoCaretUpSharp } from 'react-icons/io5'
import ProductCard from '../../components/BetkoShop/ProductCard'
import { BsFillCartCheckFill } from 'react-icons/bs'
import CategoriesSidebar from '../../components/BetkoShop/CategoriesSidebar'
import axios from 'axios'
import { Badge, message, Modal } from 'antd'
import Pagination from '../../components/Pagination'
import ProductPreview from '../../components/BetkoShop/ProductPreview'
import CartContext from '../../context/CartContext'
import Spinner from '../../components/Spinner'
import CardPlaceHolder from '../../components/BetkoShop/CardPlaceHolder'
import AuthContext from '../../context/AuthProvider'
import LoginModal from '../../components/LoginModal'
import { RxTriangleLeft } from 'react-icons/rx'

const SERVER_URL = process.env.REACT_APP_SERVER_URL

const BetkoShopPage = () => {
  const navigate = useNavigate()
  const [selectedCategory, setSelectedCategory] = useState(null)
  const [allCategories, setAllCategories] = useState([])
  const [isOpenLoginModal, setIsOpenLoginModal] = useState(false)
  const [sortByPrice, setSortByPrice] = useState(null)
  const [currentPage, setCurrentPage] = useState(1)
  const [allProducts, setAllProducts] = useState(null)
  const [refetchTrigger, setRefetchTrigger] = useState(false)
  const [selectedProduct, setSelectedProduct] = useState(null)
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768)
  const [selecteImagePreview, setSelecteImagePreview] = useState(null)
  const { cartWithData } = useContext(CartContext)
  const [isLoading, setIsLoading] = useState(false)
  const placeholdersNeeded = Math.max(
    0,
    3 - (allProducts?.paginatedData?.length || 0)
  )
  const { auth } = useContext(AuthContext)

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768)
    }

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  const productsContainerRef = useRef(null)
  const [searchParams, setSearchParams] = useSearchParams()

  const selectedProductId = searchParams.get('productId')
  const shopType = searchParams.get('shopType')
  const searchTerm = searchParams.get('searchTerm')
  const sort = searchParams.get('sort')

  useEffect(() => {
    setSelectedCategory(null)
    setSelectedProduct(null)
    const newParams = new URLSearchParams(searchParams)
    setSearchParams(newParams)
    setCurrentPage(1)
  }, [shopType])

  useEffect(() => {
    const getAllCategories = async () => {
      try {
        const response = await axios.get(
          `${SERVER_URL}/categories-get-all/${null}/${null}/`
        )
        setAllCategories(response?.data)
      } catch (error) {
        setAllCategories([])
        message.error(
          error?.response?.data?.message || 'Greška pri preuzimanju kategorija!'
        )
      }
    }

    getAllCategories()
  }, [])

  useEffect(() => {
    const getSelectedProduct = async () => {
      try {
        const product = await axios.get(
          `${SERVER_URL}/get-product-by-id/${selectedProductId}`
        )
        setSelectedProduct(product?.data)
      } catch {
        setSelectedProduct(null)
        selectedProductId && message.error('Greška pri preuzimanju proizvoda! ')
      }
    }

    getSelectedProduct()
  }, [selectedProductId])

  const scrollProducts = (direction) => {
    if (productsContainerRef.current) {
      const scrollValue = isMobile ? (window.innerWidth - 55) / 3 : 255

      productsContainerRef.current.scrollBy({
        left: direction === 'left' ? -scrollValue : scrollValue,
        behavior: 'smooth',
      })
    }
  }

  const onSortChange = () => {
    const sortOption = sortByPrice !== 'asc' ? 'asc' : 'desc'
    setSortByPrice(sortOption || null)
    const params = new URLSearchParams(searchParams)
    params.set('sort', sortOption || null)
    setSearchParams(params)
  }

  useEffect(() => {
    const getAllProducts = async () => {
      setIsLoading(true)
      try {
        const response = await axios.get(
          `${SERVER_URL}/get-all-product/${currentPage}/10/${
            searchTerm || null
          }/${selectedCategory || null}/${shopType || null}/${sort || null}`
        )
        setAllProducts(response?.data)
      } catch (error) {
        setAllProducts([])
      }
      setIsLoading(false)
    }
    getAllProducts()
  }, [
    searchTerm,
    refetchTrigger,
    selectedCategory,
    currentPage,
    shopType,
    sort,
  ])

  const paginationProps = {
    setCurrentPage: setCurrentPage,
    currentPage: currentPage,
    limit: 10,
    totalCount: allProducts?.totalItems,
    totalPages: Math.ceil(allProducts?.totalItems / 10),
    buttonLimit: isMobile ? 1 : 3,
  }

  if (isMobile && selectedProduct) {
    return (
      <BetkoShopLayout>
        <ProductPreview
          product={selectedProduct}
          setSelectedProduct={setSelectedProduct}
        />
      </BetkoShopLayout>
    )
  }

  return (
    <BetkoShopLayout>
      <div className={classes.pageContainer}>
        <div
          className={`${classes.productsContainer} ${classes.productContainerMobile}`}
        >
          <div className={classes.navigatePrev}>
            <BiCaretLeft onClick={() => scrollProducts('left')} />
          </div>
          <div
            className={classes.productsContainerInner}
            ref={productsContainerRef}
          >
            {isLoading ? (
              <div className={classes.spinner}>
                <Spinner />
              </div>
            ) : (
              <>
                {allProducts?.paginatedData?.map((product) => (
                  <ProductCard
                    product={product}
                    key={product._id}
                    isHomePage={false}
                  />
                ))}
                {placeholdersNeeded > 0 &&
                  new Array(placeholdersNeeded)
                    .fill(null)
                    .map((_, index) => (
                      <CardPlaceHolder isShopPage={true} key={index} />
                    ))}
              </>
            )}
          </div>
          <div className={classes.navigateNext}>
            <BiCaretRight onClick={() => scrollProducts('right')} />
          </div>
        </div>
        <div className={classes.products}>
          <div className={classes.productsHeader}>
            {selectedProduct && (
              <button
                className={classes.backFromProd}
                onClick={() => {
                  setSelectedProduct(null)
                  setSearchParams((prevParams) => {
                    const newParams = new URLSearchParams(prevParams)
                    newParams.delete('productId')
                    return newParams
                  })
                }}
              >
                <RxTriangleLeft /> NAZAD
              </button>
            )}
            <button className={classes.sortBtn} onClick={() => onSortChange()}>
              <span>SORTIRAJ PO VISINI CENE </span>
              <div className={classes.carets}>
                <IoCaretDownSharp className={classes.caretDown} />
                <IoCaretUpSharp className={classes.caretUp} />
              </div>
            </button>
            <Pagination {...paginationProps} />
            <button
              className={classes.myCart}
              onClick={() => {
                if (cartWithData.length < 1) {
                  message.error('Korpa je prazna!')
                  return
                }
                if (!auth?._id) {
                  setIsOpenLoginModal(true)
                  return
                }
                navigate('/betko_shop/moja-korpa')
              }}
            >
              <Badge
                count={auth?._id ? cartWithData?.length : 0}
                showZero={false}
              >
                <BsFillCartCheckFill />
              </Badge>
              <span>MOJA KORPA</span>
            </button>
          </div>

          <div className={classes.categoriesAndProducts}>
            <CategoriesSidebar
              allCategories={
                shopType
                  ? allCategories?.paginatedData
                      ?.filter((category) => category?.typeShop === shopType)
                      ?.filter(
                        (cat) =>
                          cat?.name !== 'OSTAVI ADMINU DA ODREDI KATEGORIJU'
                      )
                  : allCategories?.paginatedData?.filter(
                      (cat) =>
                        cat?.name !== 'OSTAVI ADMINU DA ODREDI KATEGORIJU'
                    )
              }
              setSelectedCategory={setSelectedCategory}
              selectedCategory={selectedCategory}
              setSelectedProduct={setSelectedProduct}
              setSearchParams={setSearchParams}
            />
            {!isMobile &&
              (selectedProduct ? (
                <ProductPreview
                  product={selectedProduct}
                  setSelecteImagePreview={setSelecteImagePreview}
                />
              ) : (
                <div className={classes.productsContainer}>
                  <div className={classes.navigatePrev}>
                    <BiCaretLeft onClick={() => scrollProducts('left')} />
                  </div>
                  <div
                    className={classes.productsContainerInner}
                    ref={productsContainerRef}
                  >
                    {isLoading ? (
                      <div className={classes.spinner}>
                        <Spinner />
                      </div>
                    ) : (
                      <>
                        {allProducts?.paginatedData?.map((product) => (
                          <ProductCard
                            product={product}
                            key={product?._id}
                            isHomePage={false}
                          />
                        ))}
                        {placeholdersNeeded > 0 &&
                          new Array(placeholdersNeeded)
                            .fill(null)
                            .map((_, index) => (
                              <CardPlaceHolder isShopPage={true} key={index} />
                            ))}
                      </>
                    )}
                  </div>
                  <div className={classes.navigateNext}>
                    <BiCaretRight onClick={() => scrollProducts('right')} />
                  </div>
                </div>
              ))}
          </div>
        </div>
        <Modal
          open={selecteImagePreview}
          footer={null}
          style={{ width: '100%', resize: 'none' }}
          width='auto'
          className={classes.modalWrapper}
          onClose={() => setSelecteImagePreview(null)}
          onCancel={() => setSelecteImagePreview(null)}
          centered
        >
          <div className={classes.imagePreviewWrapper}>
            <img src={selecteImagePreview} />
          </div>
        </Modal>
      </div>

      <LoginModal
        setIsOpenLoginModal={setIsOpenLoginModal}
        isOpenLoginModal={isOpenLoginModal}
        title='Da bi ste nastavili morate biti ulogovani !'
      />
    </BetkoShopLayout>
  )
}

export default BetkoShopPage
