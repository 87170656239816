import React from 'react'
import classes from './PricesModal.module.css'

const PricesModal = ({
  setIsModalVisible,
  isModalVisible,
  selectedPackage,
  setSelectedPackage,
  allPackages,
}) => {
  return (
    <div
      className={`${classes.pricesModal} ${
        !isModalVisible ? classes.paymentPg : ''
      }`}
    >
      <div className={classes.top}>
        {isModalVisible ? (
          <>
            {' '}
            <button
              className={classes.closeBtn}
              onClick={() => setIsModalVisible(false)}
            >
              <img src='/close-mini-icon.svg' width={40} height={40} />
              IZLAZ
            </button>
            <h2>KLIKNI I ODABERI PAKET</h2>
          </>
        ) : (
          <h3>PROMENI PAKET</h3>
        )}
      </div>
      <div className={classes.packagesWrapper}>
        {allPackages
          ?.sort((a, b) => a.packageDuration - b.packageDuration)
          .map((pkg) => (
            <button
              key={pkg?._id}
              className={`${
                selectedPackage?._id === pkg?._id ? classes.selectedPackage : ''
              }`}
              onClick={() => {
                setIsModalVisible(false)
                setSelectedPackage(pkg)
              }}
            >
              <div className={classes.side}>
                <span>PERIOD PAKETA</span>
                <span>
                  {pkg?.packageDuration}{' '}
                  {pkg?.packageDuration === 1 ? 'DAN' : 'DANA'}
                </span>
              </div>
              <div className={classes.desc}>
                <p>{pkg?.descriptionWeb}</p>
              </div>

              <div className={classes.side}>
                <span>CENA PAKETA</span>
                <span> {pkg?.price} €</span>
              </div>
            </button>
          ))}
      </div>
      <p className={classes.tnx}>HVALA TI ŠTO SI ODABRAO/ODABRALA PAKET !</p>
    </div>
  )
}

export default PricesModal
