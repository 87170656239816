import React from 'react'
import classes from './ProductCard.module.css'
import { useLocation, useNavigate } from 'react-router-dom'
import useCountdown from '../../hooks/useCountdown'
const SERVER_URL = process.env.REACT_APP_SERVER_URL

const ProductCard = ({ product, isHomePage }) => {
  const navigate = useNavigate()
  const location = useLocation()
  const isAuction = product?.shopType === 'auction'
  const isSportTourist = product?.shopType === 'sport-tourist'
  const timeRemaining = useCountdown(product?.expireDate)
  const isExpired = timeRemaining === '00:00:00'

  return (
    <div
      className={`${classes.singleProductCard} ${
        !isHomePage ? classes.shopPage : classes.homePage
      }`}
      onClick={() => {
        const params = new URLSearchParams(location.search)
        params.set('productId', product?._id)
        params.set('shopType', product?.shopType)

        const newPath = isHomePage
          ? `/betko_shop/shop?${params.toString()}`
          : `?${params.toString()}`

        navigate(newPath)
      }}
    >
      <div className={classes.imageWrapper}>
        <img
          src={`${SERVER_URL}/${product?.images?.url}` || '/tiger.webp'}
          alt='product image'
        />
      </div>
      <div className={classes.productTitle}>
        <p>{product?.title}</p>
      </div>
      {isAuction && isHomePage && (
        <span className={isExpired ? classes.expiredAuction : classes.auction}>
          {timeRemaining}
        </span>
      )}
      <div className={classes.productPriceSeparator}>
        <span>
          {product?.sms ? 'POŠALJI' : product?.cardNumber ? 'UPLATI' : 'CENA'}
        </span>
      </div>
      <div
        className={`${
          product.cardNumber ? classes.cardNumber : classes.productPrice
        }`}
      >
        <span>
          {product?.cardNumber
            ? product.cardNumber
            : product.sms
            ? product?.sms
            : `${product?.price} RSD`}{' '}
        </span>
      </div>
      {!isHomePage && (
        <div
          className={`${classes.productStock} ${
            (!product?.onStock && !isAuction) || (isAuction && isExpired)
              ? classes.outOfStock
              : ''
          }`}
        >
          <span>
            {isAuction
              ? timeRemaining
              : product?.onStock
              ? `${isSportTourist ? 'REZERVIŠI MESTO' : 'IMA NA STANJU'}`
              : `${isSportTourist ? 'POPUNJENO' : 'NEMA NA STANJU'}`}
          </span>
        </div>
      )}
      <button className={classes.seeMore}>KLIKNI I SAZNAJ VIŠE</button>
    </div>
  )
}

export default ProductCard
