export const betkoShopUsloviData = {
  shop: {
    'uslovi-za-korisnike': `Registracijom na betkotip.com prihvatate opšte uslove korišćenja sajta betkotip.com, kao i uslove za korisnike shopa. Što se shopa tiče, nepostoje posebni uslovi korišćenja istog. Potrebno je znati nekoliko stvari...  ${'\n'} Naš sajt betkotip.com  nema odgovornost za bilo šta što se tiče proizvoda. Dakle, bukvalno, betkotip.com  jeste posrednik u interakciji između korisnika kao poručioca, i ponuđača kao prodavaca proizvoda. Za sve oko isporuke, načina plaćanja,reklamacije na proizvode i svega ostalog, dogovaraju se sa ponuđač proizvoda i zainteresovani za kupovinu istog. ${'\n'} Na betkotip.com  se vrši samo porudžbina proizvoda, koju mi dostavljamo ponuđaču proizvoda, koji je svoj proizvod izložio na betkotip.com , nakon čega isti kontaktira i u daljem se procesu dogovara sa korisnikom oko svih potrebnih detalja porudžbine ili ponude. Ukoliko bi korisnik imao bilo kakvu reklamaciju na proizvode ili slično, obraća se ponuđaču proizvoda. ${'\n'} Naš sajt betkotip.com  sarađuje samo sa legalnim preduzećima, koja su dužna da dostave kupcu fiskalni račun uz proizvod, i koja štite interese potrošača.`,
    'saznaj-vise': `Hvala vam što želite da saznate više o shop-u na betkotip.com. Shop na našem sajtu jeste mesto na kom može da se pronađe i na kom će moći, da se pronađe veliki broj artikala koji su povezani sa sportom na bilo koji način. To podrazumeva da ćemo sa razvojem projekta shop-a kontinuirano nadograđivati saradnju sa ponuđačima, kako bi ponuda ka korisnicima bila što šira i što raznovrsnija. ${'\n'} Očekujte da ćete na ovom mestu moći pronaći brojne sportske rekvizite, sportsku opremu, navijačke rekvizite poput šalova, majci, kačketa, šešira i još mnogo toga. Takođe, cilj nam je da sarađujemo i sa sportskim klubovima, kako bi u našem shopu korisnici imali mogućnost da poruče i opremu istih, i još mnogo toga.  ${'\n'} Pratite nas, i videćete mnogo toga što bi moglo da vas zanima i što biste želeli da posedujete. Želimo da napomenemo i to, da će i brojne takmičarske igre koje će se odvijati u okviru našeg sajta, pratiti artikli iz shopa, kao artikli koji će biti u fondu nagrada osvojenih kroz takmičenja u oblastima znanja i veštine. Na taj način ćete lako moći pogledati koju nagradu možete da osvojite kroz takmičenja, i da se informišete o njoj i na taj način.`,
    'uslovi-za-ponudjace': `Brzo, jednostavno, besplatno i lako, izložite svoj proizvod na betkotip.com  shop-u, bez obzira da li imate svoju online prodavnicu, ili poslujete offline. Ukoliko u svojoj ponudi imate proizvode koji su na bilo koji način povezivi sa sportom, a čak i ako imate drugačije od toga (kategorija - ''Ostalo'') pruža vam se prilika da ih na krajnje jednostavan način stavite u izlog na betkotip.com sajtu. 
${'\n'}
Klikom na KLIKNI I POSTANI PRODAVAC otvorite svoj administratorski profil, u okviru kog sami izlažete proizvode iz svoje ponude, sa svim potrebnim detaljima, opisom proizvoda, fotografijama, cenama, stanjem na lageru, pri čemu sami održavate vaš profil i vršite izmene na istom u skladu sa vašom ponudom i eventualnim promenama pri istoj. 
${'\n'}
Naravno, skladno osnovnim normama i načelima, svaka promena na našem sajtu prolazi i naše odobrenje pre postavke iste. Važno je da znate, da za sve što se tiče vaših proizvoda ili proizvoda iz vaše ponude, koji su izloženi na betkotip.com , kao i za sve što se tiče procesa kupo-prodaje između vas i kupca, odgovornost snosite vi. Dakle, betkotip.com  nema nikakvu odgovornost što se tiče proizvoda koje izlažete na našem sajtu.  
${'\n'}
Izlaganje vaše ponude je potpuno besplatno !
${'\n'}
Ukoliko bi došlo do bilo kakvih promena po bilo kom pitanju vezanom za poslovnu politiku i uslove izlaganja na betkotip.com , betkotip.com se obavezuje da o tome pravovremeno informiše sve izlagače koji imaju postavku svojih proizvoda na našem sajtu.
${'\n'}
Ponuda važi samo za pravna lica.
${'\n'}
VAŠ BETKO !`,
  },
  licitacije: {
    'uslovi-za-korisnike': `Registracijom na betkotip.com  prihvataš opšte uslove korišćenja sajta betkotip.com, kao i uslove za korisnike licitacija. 
${'\n'}
Vaše je da licitirate, kao i da uz licitaciju ostavite upisane podatke, a na humanitarnoj fondaciji ili orgaizaciji koja je postavila svoju licitaciju je da vas kontaktira. 
${'\n'}
Takođe, ukoliko i ne želite da licitirate, ukoliko je humanitarna organizacija koja je postavila licitaciju, postavila i način da slanjem sms poruke ili uplatom na žiro-račun organizacije koja prosleđuje sredstva u humanitarne svrhe ili direktno na račun primaoca humanitarne pomoći, pomognete onima kojima je pomoć potrebna, možete učiniti to. 
${'\n'}
Naš sajt betkotip.com  ničim nije odgovoran za bilo koji segment postavke licitacija, naša je dobra volja da ustupimo prostor izlagačima licitacija, koji snose punu odgovornost za svaku licitaciju, kao i za prikupljanje sredstava za pomoć ljudima kojima je ona potrebna, i za to gde će ta sredstva završiti. 
${'\n'}
Naš sajt, betkotip.com  ima zdravu ideju, pri kojoj ustupanjem prostora isključivo legalnim i registrovanim humanitarnim fondacijama i  organizacijama, uz udruživanje istih sa posetiocima našeg sajta, zaista i iskreno, želimo da pomognemo onima kojima je to potrebno i neophodno. 
${'\n'}
Nadamo se da i vi razmišljate  na isti način. Neka nas krase dela, a ne reči ! `,

    'saznaj-vise': `Pomozimo onima kojima je to zaista potrebno i neophodno. To sutra možemo biti mi, zašto bismo razmišljali drugačije kada su drugi u pitanju ? Kako da očekujemo da nama neko pomogne, ako mi nismo spremni da pomognemo drugima? 
${'\n'}
Osnovna ideja LICITACIJA jeste upravo pomoć onima kojima je to zaista potrebno i neophodno. Naš sajt betkotip.com  nema nikakve materijalne benefite ili bilo kakvu zaradu od LICITACIJA ili bilo kog segmenta kada je u pitanju humanitarna delatnost i podrška istoj. 
${'\n'}
Naša ideja je zdrava, i u saradnji sa humanitarnim fondacijama i organizacijama, te sa poznatim sportistima, želimo da ponudimo prostor za izlaganje predmeta koji imaju značajnu vrednost, koja ne mora nužno biti i materijalno visoka. 
${'\n'}
Vama kao korisnicima želimo da ponudimo mogućnost da kroz licitaciju predmeta poznatih sportista i javnih ličnosti, što može biti njihova oprema, njihovi sportski rekviziti koje su koristili na određenim takmičenjima ili slično, kupovinom istih pomognete nekome kome je to zaista potrebno i neophodno, ali i da istovremeno uz zadovoljstvo što jeste, u svoju kolekciju uvrstite neki predmet koji ćete imati samo vi, i na koji ćete dodatno biti ponosni, koliko i na delo koje ste učinili.
`,

    'uslovi-za-ponudjace': `Ukoliko rukovodite nekom od humanitarnih organizacija ili fondacija, pozivamo vas da zajedničkim snagama i uz našu podršku, te podršku posetilaca i korisnika betkotip.com  sajta, zajedno pomognemo onima kojima je pomoć zaista potrebna i neophodna. 
${'\n'}
Naš sajt vam ustupa prostor za izlaganje proizvoda koje možete postaviti na licitaciju, pri čemu betkotip.com  nema bilo kakvu materijalnu korist od toga. Prostor vam ustupamo potpuno besplatno, pri čemu vam pri sklapanju saradnje dodeljujemo vaš profil, sa kog možete unositi sve potrebno vezano za samu licitaciju, od fotografije predmeta (poželjno opreme ili slično, koja pripada nekoj javnoj ličnosti iz sveta sporta) preko opisa predmeta, vremena trajanja licitacije, trenutne vrednosti licitirane prodajne cene itd. Profil i sve promene na istom vodite vi, i snosite potpunu odgovornost za sve vezano za licitaciju koju izlažete i sprovodite. No shodno tome da ste legalna humanitarna organizacija ili fondacija, taj detalj napominjemo tek forme radi, jer smo apsolutno sigurni u to da je vaša društvena odgovornost na najvišem mogućem nivou. 
${'\n'}
Takođe, mimo samih licitiranih proizvoda, uz iste možete izložiti i podatke i način na koji korisnici našeg sajta koji možda ne mogu da izdvoje sredstva i da se nadmeću u okviru licitacije, mogu da pomognu onima kojima je to zaista neophodno i na druge načine, slanjem sms poruka, uplatama na  žiro-račun ili slično. 
${'\n'}
Pomozite nam da zajedno pomognemo onima kojima je to najpotrebnije i najneophodnije. Učinimo dela na koja ćemo biti ponosni !`,
  },
  'sport-turist': {
    'uslovi-za-korisnike': `Registracijom na betkotip.com prihvatate opšte uslove korišćenja sajta betkotip.com, kao i uslove za korisnike sport turist menija. 
${'\n'}
Želimo da vam napomenemo i to, da će vas dalje za sve što se tiče rezervacija, i svega potebnog kontaktirati organizator puta ili turističke ture. ${'\n'}
Naš sajt betkotip.com  nema nikakvu odgovornost oko bilo čega dalje, mi samo ustupamo prostor za ponuđače organizacije puta ili turističkih tura koji vas vode na manje ili na veće pojedinačne sportske događaje ili turnire i takmičenja, i vašu rezervaciju prosleđujemo ponuđačima.`,
    'saznaj-vise': `Registracijom na prihvatate opšte uslove korišćenja sajta betkotip.com, kao i uslove za korisnike sport turist menija. 
${'\n'}
Sve što treba da uradite jeste da uđete na betkotip.com, kliknete na meni SHOP, kliknete na meni SPORT TURIST i pogledate aktuelnu ponudu turističkih ili prevozničkih tura sa konačnom destinacijom ka manjim ili velikim sportskim događajima ili takmičenjima. 
${'\n'}
Želimo da napomenemo i to, da će vas dalje za sve što se tiče rezervacija, i svega potrebnog kontaktirati organizator puta ili turističke ture. Naš sajt betkotip.com  nema nikakvu odgovornost oko bilo čega dalje, mi samo ustupamo prostor za ponuđače organizacije puta ili turističkih tura koje vode na manje ili veće pojedinačne sportske događaje ili turnire i takmičenja, i vašu rezervaciju prosleđujemo ponuđačima.`,
    'uslovi-za-ponudjace': `Vlasnik ste turističke agencije, ili organizujete puteve u inostranstvo ? A želeli biste da unapredite vaše poslovanje ? Odlično, evo prilike za to !
${'\n'}
Na našem sajtu je postavljen koncept koji za cilj i za ideju ima da ljubiteljima sporta pruži mogućnost da dosanjaju svoje snove. Mnogi od njih bi voleli da uživo vide aktere brojnih sportskih događaja širom planete, koje svakodnedno gledaju na svojim televijskim ekranima. 
${'\n'}
Mi vam izdajemo prostor na kom brojni posetioci našeg sajta mogu da pogledaju vašu ponudu, a na vama je samo da je kreirate. Put u Milano, Barcelonu, Madrid... Zvuči egzotično i dobro, zar ne ? A ako ga spojimo još i sa nekom fudbalskom utakmicom Intera, Barse ili Reala, zvuči još bolje, zar ne ? Zašto ne priuštiti brojnim ljubiteljima fudbala tu mogućnost ? 
${'\n'}
Velika takmičenja, poput svetskih i evropskih prvenstava, olimpijskih igara... A ko ne bi voleo i želeo da poseti neko od njih ? Sve smo rekli, zar ne ? Vi ste na potezu. Mi vam nudimo prostor za izlaganje vaše ponude, i on je potpuno besplatan. 
${'\n'}
Nudimo vam vaš korisnički profil kojim sami rukujete i u kom sami unosite detalje oko putovanja, perioda turističkih tura, smeštaja i ostalih opisnih detalja. Vaše je sve dalje, i sva odgovornost vezano za put i organizaciju puta i sve ostalo, te kontaktiranje korisnika koji su rezervisali svoje mesto za isti (rezervacije vam prosleđujemo mi). 
${'\n'}
Nemate nikakve troškove, pa hajde da vidimo, možemo li zajedno da napravimo stvari koje ljubitelji sporta nikada neće izbrisati iz svojih sećanja, o kojima će pričati godinama ispred, kao i o vama, koji ste im priuštili mogućnost da ih posete. Ima li boljeg marketinga za vašu turističku agenciju ?
${'\n'}
Ukoliko bi došlo do bilo kakvih promena po bilo kom pitanju vezanom za poslovnu politiku i uslove izlaganja na betkotip.com , betkotip.com se obavezuje da o tome pravovremeno informiše sve izlagače koji imaju postavku svojih ponuda na našem sajtu.
${'\n'}
Mi smo tu, vi ste na potezu. Sa betkom se može, sve što se poželi !`,
  },
}
