import React from 'react'
import { useNavigate } from 'react-router-dom'

import classes from './BetkoFamily.module.css'

const BetkoFamily = () => {
  const navigate = useNavigate()
  return (
    <div className={classes.familyPageContainer}>
      <button onClick={()=> navigate('/betko_plus')} className={classes.betkoPlusBtn}>KLIKNI # BETKO PLUS</button>
      <div
        onClick={() => navigate('/betko_family/opis')}
        className={classes.descBtn}
      >
        <h2>BETTING (R)EVOLUCIJA !</h2>
        <h3>INOVANTNO ! VIRALNO ! DRUGAČIJE !</h3>
        <p className={classes.join}>
          PRIDRUŽI SE NAŠIM PROJEKTIMA ! KREIRAJ "BUDUĆNOST BETTINGA" ! POSTANI
          DEO NJE !
        </p>
        <p className={classes.green}>KLIKNI I POGLEDAJ !</p>
      </div>
      <div
        onClick={() => navigate('/betko_family/opis')}
        className={`${classes.descBtn} ${classes.mid}`}
      >
        <h2>BETTING (R)EVOLUCIJA !</h2>
        <h3>INOVANTNO ! VIRALNO ! DRUGAČIJE !</h3>
        <p className={classes.join}>
          PRIDRUŽI SE NAŠIM PROJEKTIMA ! KREIRAJ "BUDUĆNOST BETTINGA" ! POSTANI
          DEO NJE !
        </p>
        <p className={classes.green}>KLIKNI I POGLEDAJ !</p>
      </div>
      <div
        onClick={() => navigate('/betko_family/opis')}
        className={classes.descBtn}
      >
        <h2>BETTING (R)EVOLUCIJA !</h2>
        <h3>INOVANTNO ! VIRALNO ! DRUGAČIJE !</h3>
        <p className={classes.join}>
          PRIDRUŽI SE NAŠIM PROJEKTIMA ! KREIRAJ "BUDUĆNOST BETTINGA" ! POSTANI
          DEO NJE !
        </p>
        <p className={classes.green}>KLIKNI I POGLEDAJ !</p>
      </div>
    </div>
  )
}

export default BetkoFamily
