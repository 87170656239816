import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import AuthContext from "../context/AuthProvider";
import classes from "./promeniPassword.module.css";

const PromeniPassword = () => {
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmedNewPassword, setConfirmedNewPassword] = useState("");
  const [isError, setIsError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");

  const navigate = useNavigate();
  const { auth } = useContext(AuthContext);
  const axiosPrivate = useAxiosPrivate();

  const userEmail = auth.email;

  const rp =
    /^(?=.*?[A-Z])(?=(.*[a-z]){1,})(?=(.*[\d]){1,})(?=(.*[\W]){1,})(?!.*\s).{8,}$/;

  const validOldPassword = rp.test(oldPassword);
  const validNewPassword = rp.test(newPassword);

  const promeniPasswordMutation = useMutation({
    mutationFn: async (userData) => {
      await axiosPrivate.post(
        `/forgottenPassword/newPassword`,
        { oldPassword, newPassword, userEmail },
        {
          headers: { "Content-Type": "application/json" },
          withCredentials: true,
        }
      );
    },
    onSuccess: () => {
      navigate("/");
    },
    onError: (error) => {
      if (error.response.status === 400) {
        setIsError(true);
        setErrorMsg("Pogrešana stara lozinka.");
      }
    },
  });

  const handleChangePassword = () => {
    if (
      validOldPassword &&
      validNewPassword &&
      newPassword === confirmedNewPassword
    ) {
      promeniPasswordMutation.mutate();
    }

    if (!validOldPassword) {
      // console.log("stari password nije validan");
      setIsError(true);
      setErrorMsg("Stara lozinka nije validna.");
      return;
    }
    if (!validNewPassword) {
      // console.log("novi password nije validan");
      setIsError(true);
      setErrorMsg("Nova lozinka nije validna.");
      return;
    }
    if (newPassword !== confirmedNewPassword) {
      // console.log("Lozinke se ne podudaraju");
      setIsError(true);
      setErrorMsg("Lozinke se ne podudaraju.");
      return;
    }
  };

  return (
    <div className={classes.container}>
      <h2>Promeni Lozinku</h2>
      {isError && (
        <div className={classes.error}>
          <p>{errorMsg}</p>
        </div>
      )}
      <p>Unesite vašu staru lozinku:</p>
      <input
        onChange={(e) => setOldPassword(e.target.value)}
        className={classes.input}
        type="password"
      />
      <p>Unesite vašu novu lozinku:</p>
      <input
        onChange={(e) => setNewPassword(e.target.value)}
        className={classes.input}
        type="password"
      />
      <p>Potvrdite vašu novu lozinku:</p>
      <input
        onChange={(e) => setConfirmedNewPassword(e.target.value)}
        className={classes.input}
        type="password"
      />
      <p className={classes.red}>
        Napomena: Nova lozinka mora imati minimum 8 karaktera i mora sadržati
        bar jedno veliko slovo, bar jedno malo slovo, bar jedan broj i bar jedan
        specijalan karakter.
      </p>
      <div className={classes.btnContainers}>
        <button className={classes.btn} onClick={() => navigate(-1)}>
          Nazad
        </button>
        <button onClick={() => handleChangePassword()} className={classes.btn}>
          Pošalji
        </button>
      </div>
    </div>
  );
};

export default PromeniPassword;
