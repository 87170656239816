import classes from "./adminFooter.module.css";

const AdminFooter = () => {
  return (
    <div className={classes.container}>
      <p>&copy; 2023 BETKOBETKO.com</p>
    </div>
  );
};

export default AdminFooter;
