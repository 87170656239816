import { Outlet } from 'react-router-dom'
import classes from './shopAdminLayout.module.css'
import ShopAdminHeader from '../shopAdminPanel/ShopAdminHeader'
import ShopAdminSidePanel from '../shopAdminPanel/ShopAdminSidePanel'

const ShopAdminLayout = () => {
  return (
    <div className={classes.shopAdminLayout}>
      <ShopAdminHeader />
      <div className={classes.center}>
        <ShopAdminSidePanel />
        <Outlet />
      </div>
    </div>
  )
}

export default ShopAdminLayout
