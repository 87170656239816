import React, { useEffect, useState } from 'react'
import classes from './AdminComments.module.css'
import axios, { all } from 'axios'
import { Button, Modal, notification } from 'antd'
import { MdOutlineKeyboardDoubleArrowLeft, MdKeyboardDoubleArrowRight } from "react-icons/md";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { FaSort } from "react-icons/fa";
import { IoIosCloseCircle } from "react-icons/io";

const PaginationBullet = ({ data, current, onTarget }) => {
  return (
    <>
      {data.map((item, idx) => {
        return (
          <button key={idx} onClick={() => onTarget(item)} className={`${classes.targetPage} ${current === item ? classes.isActive : ''}`}>
            <span>{item}</span>
          </button>
        );
      })}
    </>
  );
};

const Pagination = ({ current, setCurrentPage, limitPage, buttonLimit, pagesCount, totalCount }) => {
  const onPrev = () => {
    setCurrentPage(prev => prev - 1)
  }
  const onNext = () => {
    setCurrentPage(prev => prev + 1)
  }
  const onFirst = () => {
    setCurrentPage(1)
  }
  const onLast = () => {
    setCurrentPage(pagesCount)
  }
  const onTarget = (target) => {
    setCurrentPage(target)
  }

  const numbers = []
  for (let i = 1; i <= Math.ceil(totalCount / limitPage); i++) {
    numbers.push(i)
  }

  const getPaginationButton = () => {
    let numShown = buttonLimit
    let start = Math.min(numShown, numbers.length)
    let first = current - Math.floor(start / 2)
    first = Math.max(first, 1)
    first = Math.min(first, numbers.length - start + 1)

    return [...Array(start)].map((k, i) => i + first)
  }

  const paginationButton = getPaginationButton()
  const maxOfNumbers = Math.max(...numbers);
  const minOfNumbers = Math.min(...numbers);

  return (
    <div className={classes.pagination}>
      <button className={`${classes.firstPage} ${paginationButton.length > 1 && current !== minOfNumbers ? classes.show : ''}`} onClick={() => onFirst()}>
        <MdOutlineKeyboardDoubleArrowLeft />
      </button>

      <button className={`${classes.pageBack} ${current <= 1 ? classes.disabled : ''}`} onClick={() => onPrev()} disabled={`${current <= 1 ? classes.disabled : ''}`}>
        <IoIosArrowBack />
      </button>

      <div className={classes.targetPageContainer}>
        <PaginationBullet data={paginationButton} current={current} onTarget={onTarget} />
      </div>

      <button className={`${classes.pageForward} ${current === numbers.length ? classes.disabled : ''}`} onClick={() => onNext()} disabled={`${current === numbers.length || totalCount === 0 ? classes.disabled : ''}`}>
        <IoIosArrowForward />
      </button>

      <button className={`${classes.lastPage} ${paginationButton.length > 1 && current !== maxOfNumbers ? classes.show : ''}`} onClick={() => onLast()}>
        <MdKeyboardDoubleArrowRight />
      </button>
    </div>
  );
}

const limit = 7

function AdminCommentsReports() {
  const SERVER_URL = process.env.REACT_APP_SERVER_URL

  // states
  const [pageComments, setPageComments] = useState([])
  const [comment, setComment] = useState({})
  //search
  const [search, setSearch] = useState(null)
  const [searchForm, setSearchForm] = useState({
    query: ''
  })
  // filters
  const [filterType, setFilterType] = useState(null)
  const [filterDate, setFilterDate] = useState(null)
  const [activeFilter, setActiveFilter] = useState(false)
  const [activeDate, setActiveDate] = useState(false)
  // pagination
  const [pageNumber, setPageNumber] = useState(1)
  const [totalCount, setTotalCount] = useState(1)
  const [pageCount, setPageCount] = useState(1)
  //modals
  const [openDeleteModal, setOpenDleteModal] = useState(false)
  // refetch
  const [refetch, setRefetch] = useState(false)
  const [commentCounter, setCommentCounter] = useState({
    totalComments: 0,
    commentsLength: 0,
    answersLength: 0,
    likesLength: 0,
    dislikesLength: 0,
    totalReports: 0,
  })
  // edit comment modal
  const [editCommentModal, setEditCommentModal] = useState(false)
  const [editCommentValue, setEditCommentValue] = useState('')
  // users that reported comment
  const [modalOfReports, setModalOfReports] = useState(false)
  const [reportUsers, setReportUsers] = useState([])

  const currentDate = new Date()
  currentDate.setHours(0, 0, 0, 0)

  const fetchComments = async () => {
    const payload = {
      searchTerm: search,
      filterType: filterType,
      filterDate: filterDate,
    }
    try {
      await axios.post(`${SERVER_URL}/getComments/${pageNumber}/${limit}/null`, { ...payload }, { withCredentials: false }).then(res => {
        if (res.status === 200) {
          const onlyReportedComments = res?.data?.data?.filter(comm => comm.reports && comm.reports.length > 0)
          setCommentCounter(prev => {
            return {
              ...prev,
              totalComments: res?.data?.totalComments,
              commentsLength: res?.data?.commentsLength,
              answersLength: res?.data?.answersLength,
              likesLength: res?.data?.likesLength,
              dislikesLength: res?.data?.dislikesLength,
              totalReports: res?.data?.totalReports
            }
          })
          setPageComments(onlyReportedComments)
          setTotalCount(res?.data?.totalCount)
          setPageCount(res?.data?.pageCount)
        }
      })
    } catch (error) {
      console.error(error)
    }
  }

  const fetchUsers = async (id) => {
    try {
      await axios.get(`${SERVER_URL}/reportUsers/${id}`, { withCredentials: false }).then(res => {
        if (res.status === 200) {

          setModalOfReports(true)
          setReportUsers(res.data)
        }
      }).catch(error => {
        console.error(error)
      })
    } catch (error) {
      console.error(error)
      setModalOfReports(true)
    }
  }

  // useEffects
  useEffect(() => {
    fetchComments()

    return () => { }
  }, [refetch, pageNumber, filterType, filterDate])

  useEffect(() => {
    if (comment && Object.keys(comment).length > 0) {
      setEditCommentValue(comment.adminComment)
    }
  }, [comment])

  const handleDeleteComment = async ({ comment, event }) => {
    try {
      await axios.delete(`${SERVER_URL}/deleteComment/${comment._id}?postId=${comment.post._id}&type=${comment.type}`).then(res => {
        if (res.status === 200) {

          setRefetch(prev => !prev)
          setOpenDleteModal(false)
          setComment({})
          // notification
          notification.success({
            message: 'Komentar uspešno obrisan.',
            placement: 'topRight'
          })
        }
      }).catch(error => {
        console.error(error)
      })
    } catch (error) {
      console.error(error)
    }
  }

  const handleChangeSearch = (e) => {
    const { value } = e.target

    setSearchForm({ query: value })
  }

  const handleOnSubmitSearch = async (e) => {
    e.preventDefault()
    setSearch(searchForm.query)
    setRefetch(prev => !prev)
  }

  const handleChooseDate = (e) => {
    const { value } = e.target

    setActiveDate(false)
    setFilterDate(value)
  }
  const handleChooseFilter = (e) => {
    const { value, name } = e.target

    setActiveFilter(false)
    setFilterType(value)
  }

  const handleDisplayTime = (date) => {
    let hours = date.getHours()
    let minutes = date.getMinutes()

    if (minutes < 10) {
      minutes = '0' + minutes
    }
    if (hours < 10) {
      hours = '0' + hours
    }

    let formatedTime = `${hours}:${minutes}`
    return formatedTime
  }

  const maxChars = (text, limit) => {
    if (text.length <= limit) {
      return text
    }
    const trimmedText = text.substring(0, limit)

    return `${trimmedText}...`
  }

  const handleEditComment = async () => {
    const payload = {
      adminComment: editCommentValue
    }
    try {
      await axios.put(`${SERVER_URL}/adminComment/${comment._id}`, payload, { withCredentials: false }).then(res => {

        setRefetch(prev => !prev)
        setComment({})
        setEditCommentValue('')
        setEditCommentModal(false)

        notification.success({
          message: res.data.message,
          placement: 'topRight'
        })
      }).catch(error => {
        console.error(error)
        notification.error({
          message: 'Greška prilikom izmene komentara',
          placement: 'topRight'
        })
      })
    } catch (error) {
      console.error(error)
      notification.error({
        message: 'Greška prilikom izmene komentara',
        placement: 'topRight'
      })
    }
  }

  const pagesCountComments = pageCount
  const totalCountComments = totalCount

  // props
  const paginationProps = {
    current: pageNumber,
    setCurrentPage: setPageNumber,
    limitPage: limit,
    buttonLimit: 3,
    pagesCount: pagesCountComments,
    totalCount: totalCountComments,
  }

  const excelExport = async () => {
    try {
      const response = await axios.post(
        `${SERVER_URL}/export-reports-excel`,
        {},
        {
          responseType: 'blob',
          withCredentials: false,
        }
      )
      // Create a URL for the blob
      const fileURL = window.URL.createObjectURL(new Blob([response.data]))
      // Create a temp <a> tag to download the file
      const fileLink = document.createElement('a')
      fileLink.href = fileURL
      fileLink.setAttribute('download', 'reports.xlsx') // Set the file name for the download
      document.body.appendChild(fileLink)

      fileLink.click() // Programmatically click the link to trigger the download

      // Clean up by removing the temporary link
      fileLink.parentNode.removeChild(fileLink)
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <div className={classes.container}>
      <div className={classes.commentsHeader}>
        <h1>Svi Komentari</h1>
        <form onSubmit={(e) => handleOnSubmitSearch(e)}>
          <input value={searchForm.query || ''} type='text' onChange={(e) => handleChangeSearch(e)} placeholder='search...' />
          <div>
            <button type='submit'>Search</button>
          </div>
        </form>
        <div className={classes.excelContainer}>
          <button onClick={() => excelExport()} className={classes.excelExport}>Excel export</button>
        </div>
      </div>

      <div className={classes.tableContainer}>
        <table className={classes.commentTable}>
          <thead>
            <tr>
              <th colSpan={3}>Brojac prijava komentara</th>
              <th style={{ background: 'red', color: 'black', fontWeight: 'bold' }} colSpan={8}>Administrator komentara = admini na sajtu</th>
              <th colSpan={4}>Brojac (dis)lajkova</th>
            </tr>
            <tr>
              <th style={{ background: 'yellow', color: 'black', fontWeight: 'bold' }}>
                {commentCounter.totalReports}
              </th>
              <th></th>
              <th style={{ background: 'lightblue', color: 'black', fontWeight: 'bold' }} colSpan={8}>Moderatori komentara = Pristup samo u admin panel komentara i nigde vise na sajtu</th>
              <th>Ban na komentarisanje</th>
              <th style={{ background: 'yellow', color: 'black', fontWeight: 'bold' }}>{commentCounter.likesLength}</th>
              <th style={{ background: 'yellow', color: 'black', fontWeight: 'bold' }}>{commentCounter.dislikesLength}</th>
              <th colSpan={2}></th>
            </tr>
            <tr>
              <th>
                Br. prijava
              </th>
              <th>Prijavili</th>
              <th>
                <div style={{ display: 'flex', alignItems: 'center', gap: '10px', justifyContent: 'space-between', position: 'relative' }}>
                  <span>Datum Kom.</span>
                  <div style={{ display: 'flex', alignItems: 'center', gap: '2px' }}>
                    <FaSort onClick={() => setActiveDate(prev => !prev)} style={{ cursor: 'pointer', fontSize: '18px' }} />
                    {filterDate && <IoIosCloseCircle onClick={() => setFilterDate(null)} style={{ cursor: 'pointer', fontSize: '18px' }} />}
                  </div>
                  <div className={`${classes.filterType} ${activeDate && classes.show}`}>
                    <button type='button' name='newest' value='newest' onClick={(e) => handleChooseDate(e)}>Najnoviji</button>
                    <button type='button' name='oldest' value='oldest' onClick={(e) => handleChooseDate(e)}>Najstariji</button>
                  </div>
                </div>
              </th>
              <th>Vreme</th>
              <th>Korisničko ime korisnika</th>
              <th>Sadržaj komentara</th>
              <th>
                <div style={{ display: 'flex', alignItems: 'center', gap: '10px', justifyContent: 'space-between', position: 'relative' }}>
                  <span>Meni komentara</span>
                  <div style={{ display: 'flex', alignItems: 'center', gap: '2px' }}>
                    <FaSort onClick={() => setActiveFilter(prev => !prev)} style={{ cursor: 'pointer', fontSize: '18px' }} />
                    {filterType && <IoIosCloseCircle onClick={() => setFilterType(null)} style={{ cursor: 'pointer', fontSize: '18px' }} />}
                  </div>
                  <div className={`${classes.filterType} ${activeFilter && classes.show}`}>
                    <button type='button' name='info_blok' value='info_blok' onClick={(e) => handleChooseFilter(e)}>Info Blok</button>
                    <button type='button' name='vasi_predlozi' value='vasi_predlozi' onClick={(e) => handleChooseFilter(e)}>Vaši Predlozi</button>
                    <button type='button' name='bet_kurs_novo' value='bet_kurs_novo' onClick={(e) => handleChooseFilter(e)}>Premium</button>
                    <button type='button' name='statistika' value='statistika' onClick={(e) => handleChooseFilter(e)}>Statistika</button>
                    <button type='button' name='bet_portal' value='bet_portal' onClick={(e) => handleChooseFilter(e)}>Bet Portal</button>
                  </div>
                </div>
              </th>
              <th>Kartica komentara</th>
              <th>Moderacija</th>
              <th>Izbriši komentar</th>
              <th>E-mail korisnika</th>
              <th>( ne na upotrebu placenih paketa )</th>
              <th>Like br.</th>
              <th>Dislike br.</th>
              <th>Link gde je kom.</th>
            </tr>
          </thead>
          <tbody>
            {pageComments && pageComments.length > 0 ? pageComments.map((item, index) => {
              const createdAt = new Date(item.createdAt)

              const restrictionDate = new Date(item?.owner?.commentRestricted)
              restrictionDate.setHours(0, 0, 0, 0)

              const millisecondsRestrictedDate = restrictionDate - currentDate
              const millisecondsInDay = 1000 * 60 * 60 * 24
              const differenceInDays = Math.floor(millisecondsRestrictedDate / millisecondsInDay)
              const days = differenceInDays === 36525 ? 'Trajno' : `${differenceInDays} dan/a`
              const banOnCommenting = restrictionDate > currentDate ? days : '0 dana'

              const linkToTheComment = item.type === 'vasi_predlozi' ? `${process.env.REACT_APP_PROD_URL}/nasi_predlozi/${item.post._id}` : `${process.env.REACT_APP_PROD_URL}/${item.type}/${item.post._id}`
              return (
                <tr key={index}>
                  <td>{item?.reports?.length}</td>
                  <td>
                    <div className={classes.actionContainer}>
                      <button
                        onClick={() => fetchUsers(item._id)}
                        className={classes.actionButtonReports}
                      >
                        Korisnici
                      </button>
                    </div>
                  </td>
                  <td>{createdAt.toLocaleDateString()}</td>
                  <td>{handleDisplayTime(createdAt)}</td>
                  <td>{item?.owner?.userName}</td>
                  <td>{maxChars(item.comment, 100)}</td>
                  <td>{item.type}</td>
                  <td>{item?.post?.title}</td>
                  <td>
                    <div className={classes.actionContainer}>
                      <button
                        onClick={() => {
                          setEditCommentModal(true)
                          setComment(item)
                        }}
                        className={classes.actionButtonEdit}
                      >
                        Edit
                      </button>
                    </div>
                  </td>
                  <td>
                    <div className={classes.actionContainer}>
                      <button onClick={() => {
                        setOpenDleteModal(true)
                        setComment(item)
                      }} className={classes.actionButtonDelete}>Delete</button>
                    </div>
                  </td>
                  <td>{item?.owner?.email}</td>
                  <td>{banOnCommenting}</td>
                  <td>{+item?.likes?.length + +item?.adminLikes}</td>
                  <td>{+item?.dislikes?.length + +item?.adminDislikes}</td>
                  <td>
                    <a style={{ color: 'black', textDecoration: 'none' }} href={linkToTheComment} target='_blank'>
                      {linkToTheComment}
                    </a>
                  </td>
                </tr>
              )
            }) : (
              <tr>
                <td style={{ fontSize: '20px', fontWeight: 'bold', textTransform: 'uppercase', letterSpacing: '2px', textAlign: 'center' }} colSpan={15}>Nema Komentara</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <Pagination {...paginationProps} />

      <div className={`${classes.modalOfReports} ${modalOfReports && classes.activeReportsModal}`}>
        <div className={classes.row}>
          <p>Korisnici koji su prijavili komentar</p>
        </div>

        <div style={{
          display: 'flex',
          justifyContent: 'center',
          maxHeight: '500px',
          overflowY: 'auto',
          padding: '10px 20px'
        }}>
          {reportUsers && reportUsers.length > 0 ? (
            <table style={{ color: 'black', border: '1px solid black', borderCollapse: 'collapse' }}>
              <thead>
                <tr>
                  <th style={{ border: '1px solid black', padding: '10px' }}>Email</th>
                  <th style={{ border: '1px solid black', padding: '10px' }}>Username</th>
                </tr>
              </thead>

              <tbody>
                {reportUsers.map((user, index) => {
                  return (
                    <tr key={index}>
                      <td style={{ border: '1px solid black', padding: '10px', pointerEvents: 'all', userSelect: 'text' }}>{user.email}</td>
                      <td style={{ border: '1px solid black', padding: '10px', pointerEvents: 'all', userSelect: 'text' }}>{user.userName}</td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          ) : (
            <p>Nema korisnika</p>
          )}
        </div>

        <div className={classes.row}>
          <div className={classes.editCommentActionContainer}>
            <button onClick={() => {
              setModalOfReports(false)
            }} className={classes.cancelCommentButton}>Zatvori</button>
          </div>
        </div>
      </div>

      <div className={`${classes.editCommentModal} ${editCommentModal && classes.activeEditModal}`}>
        <div className={classes.row}>
          <p>Dodaj admin komentar</p>
        </div>
        <div className={classes.row}>
          <textarea value={editCommentValue} onChange={(e) => setEditCommentValue(e.target.value)} cols={10} rows={10} />
        </div>
        <div className={classes.row}>
          <div className={classes.editCommentActionContainer}>
            <button onClick={() => handleEditComment()} className={classes.saveCommentButton}>Sačuvaj</button>
            <button
              onClick={() => {
                setEditCommentModal(false)
                setComment({})
                setEditCommentValue('')
              }}
            >
              Otkaži
            </button>
          </div>
        </div>
      </div>

      <div onClick={() => {
        setEditCommentModal(false)
        setComment({})
        setEditCommentValue('')
      }} className={`${classes.editCommentOverlay} ${editCommentModal && classes.editOverlayActive}`} />

      <div onClick={() => {
        setModalOfReports(false)
      }} className={`${classes.modalOfReportsOverlay} ${modalOfReports && classes.modalOfReportsOverlayActive}`} />

      <Modal
        footer={[]}
        open={openDeleteModal}
        title="Da li ste sigurni da želite da obrišete komentar?"
        onCancel={() => {
          setOpenDleteModal(false);
          setComment({})
        }}
      >
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-evenly",
            marginTop: "20px",
          }}
        >
          <Button
            onClick={(e) => handleDeleteComment({ comment: comment, event: e })}
          >
            Obriši
          </Button>
          <Button
            onClick={(e) => {
              setOpenDleteModal(false);
              setComment({})
            }}
          >
            Odustani
          </Button>
        </div>
      </Modal>
    </div>
  )
}

export default AdminCommentsReports
