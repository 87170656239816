import React, { useState, useEffect } from "react";
import Grupa3 from "../components/Grupa3";
import Grupa20 from "../components/Grupa20";
import classes from "./dalembert.module.css";

const Dalembert = () => {
  const [ulogArr, setUlogArr] = useState(new Array(21).fill(0));
  const [dobitakArr, setDobitakArr] = useState(new Array(21).fill(0));
  const [trenutnoStanjeArr, setTrenutnoStanjeArr] = useState(
    new Array(21).fill(0)
  );
  const [kvotaArr, setKvotaArr] = useState(new Array(21).fill(0));
  const [prolazUpisArr, setProlazUpisArr] = useState(new Array(21).fill(""));
  const [trenutnoStanjeTotalArr, setTretnutnoStanjeTotalArr] = useState(0);
  const [isActive, setIsActive] = useState(new Array(21).fill(false));

  isActive[0] = true;

  const handleUlogArr = (e, index) => {
    let arr = [...ulogArr];
    arr[index] = e.target.value;
    setUlogArr(arr);
  };

  const handleChangeProlazValue = (e, index) => {
    let arr = [...prolazUpisArr];
    arr[index] = e.target.value;
    setProlazUpisArr(arr);
  };

  const handleKvotaArr = (e, index) => {
    let arr = [...kvotaArr];
    arr[index] = e.target.value;
    setKvotaArr(arr);
  };

  const calcDobitak = () => {
    const newValue = ulogArr.map((ulog, index) => ulog * kvotaArr[index]);
    setDobitakArr(newValue);
  };

  const calcTrenutnoStanje = () => {
    const trenutnoStanjeArr = dobitakArr.map((dobitak, index) => {
      if (prolazUpisArr[index] === "DA" || prolazUpisArr[index] === "da") {
        return dobitak - ulogArr[index];
      } else if (
        prolazUpisArr[index] === "NE" ||
        prolazUpisArr[index] === "ne"
      ) {
        return -ulogArr[index];
      }
    });
    setTrenutnoStanjeArr(trenutnoStanjeArr);
  };

  const calcNextUlog = () => {
    let nextUlog = trenutnoStanjeArr.map((stanje, index) => {
      if (prolazUpisArr[index] === "DA" || prolazUpisArr[index] === "da") {
        let value = Math.abs(stanje) - Number(ulogArr[0]);
        if (value <= 0) {
          return Number(ulogArr[0]);
        }
        return Number(value);
      } else if (
        prolazUpisArr[index] === "NE" ||
        prolazUpisArr[index] === "ne"
      ) {
        let value =
          Number(dobitakArr[index] - ulogArr[index]) + Number(ulogArr[0]);
        if (value <= 0) {
          return Number(ulogArr[0]);
        }
        return Number(value);
      }
    });

    for (let i = 0; i < nextUlog.length - 1; i++) {
      ulogArr[i + 1] = nextUlog[i];
    }

    setUlogArr([...ulogArr]);
  };

  const calcTrenutnoStanjeTotal = () => {
    let validneVrednosti = trenutnoStanjeArr.filter(
      (trenutnoStanje) => !isNaN(trenutnoStanje)
    );

    let value = validneVrednosti.reduce(
      (acc, trenutnoStanje) => Number(acc) + Number(trenutnoStanje),
      0
    );

    setTretnutnoStanjeTotalArr(value);
  };

  useEffect(() => {
    const updatedIsActive = prolazUpisArr.map(
      (value) => value === "DA" || value === "da"
    );
    calcDobitak();
    calcTrenutnoStanje();
    calcTrenutnoStanjeTotal();
    calcNextUlog();
  }, [prolazUpisArr, ulogArr, kvotaArr, dobitakArr]);

  return (
    <div className={classes.container}>
      <Grupa3
        imgText="D'ALEMBERT"
        imgBackgroundColorDark="#000000"
        imgBackgroundColorLight="#000000"
        imgFontColor="#F9511D"
      />
      <Grupa20
        imgBackgroundColorDark="#000000"
        imgBackgroundColorLight="#000000"
        imgFontColor="#F9511D"
        calculatorType={`D alembert`}
      />
      <div className={classes.calculator} style={{ fontWeight: "bolder" }}>
        <div className={classes.firstRow}>
          <div className={classes.ulogRow} style={{ background: "#230F32" }}>
            <p style={{ fontSize: "28px", marginTop: "17px" }}>ULOG</p>
          </div>
          <div className={classes.kvotaRow} style={{ background: "#230F32" }}>
            <p style={{ fontSize: "28px", marginTop: "17px" }}>KVOTA</p>
          </div>
          <div
            className={classes.prolazUpisRow}
            style={{ background: "#230F32" }}
          >
            <p style={{ fontSize: "28px", marginTop: "5px" }}>
              PROLAZ UPIS (DA/NE)
            </p>
          </div>
          <div className={classes.dobitakRow} style={{ background: "#230F32" }}>
            <p style={{ fontSize: "28px", marginTop: "17px" }}>DOBITAK</p>
          </div>
          <div
            className={classes.trenutnoStanjeRow}
            style={{
              background: "#230F32",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div style={{ height: "20px" }}>
              <p style={{ marginTop: "1px" }}>TRENUTNO STANJE</p>
            </div>
            <div
              style={{
                background: "green",
                fontSize: "28px",
                height: "41px",
                borderTop: "5px solid black",
              }}
            >
              <p style={{ marginTop: "6px" }}>
                {isNaN(trenutnoStanjeTotalArr)
                  ? "-"
                  : Number(trenutnoStanjeTotalArr).toFixed(2)}
              </p>
            </div>
          </div>
        </div>

        {isActive.map((active, index) => (
          <div className={classes.row} key={index}>
            {active ? (
              <div
                className={classes.ulogRow}
                style={{ background: "whitesmoke" }}
              >
                <input
                  className={classes.odvojenoInputPolje}
                  onChange={(e) => {
                    handleUlogArr(e, index);
                  }}
                  type="number"
                  min="0"
                />
              </div>
            ) : (
              <div className={classes.ulogRow2}>
                {isNaN(ulogArr[index])
                  ? "-"
                  : Number(ulogArr[index]).toFixed(2)}
              </div>
            )}
            <div className={classes.kvotaRow}>
              <input
                onChange={(e) => {
                  handleKvotaArr(e, index);
                }}
                type="number"
                min="0"
              />
            </div>
            <div className={classes.prolazUpisRow}>
              <input
                onChange={(e) => handleChangeProlazValue(e, index)}
                type="text"
                maxLength="2"
              />
            </div>
            <div className={classes.dobitakRow}>
              {isNaN(dobitakArr[index])
                ? "-"
                : Number(dobitakArr[index]).toFixed(2)}
            </div>
            <div className={classes.trenutnoStanjeRow}>
              {isNaN(trenutnoStanjeArr[index])
                ? "-"
                : Number(trenutnoStanjeArr[index]).toFixed(2)}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Dalembert;
