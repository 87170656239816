import { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import AdminPostSearchModule from "./AdminPostSearchModule";
import AdminSinglePost from "./AdminSinglePost";
import Spinner from "../components/Spinner";
import classes from "./adminBetKursNovo.module.css";

const AdminBetKursNovo = () => {
  const [isArray, setIsArray] = useState(false);
  const navigate = useNavigate();
  const axiosPrivate = useAxiosPrivate();

  const getAllBetKursNovoPosts = async (page = 0) => {
    const { data } = await axiosPrivate.get("/getAllBetKursNovoPosts");
    const isArray = await Array.isArray(data.result);
    if (isArray) setIsArray(true);
    return data;
  };
  const allBetKursNovoPostsQuery = useQuery({
    queryKey: ["betKursNovoPosts"],
    queryFn: getAllBetKursNovoPosts,
    staleTime: 0,
    refetchInterval: 1000 * 60 * 60 * 12,
    keepPreviousData: true,
  });

  if (allBetKursNovoPostsQuery.isError) return <h2>Error...</h2>;

  const handleNewPost = () => {
    navigate("/betko-admin/kreiraj-novi-post", {
      state: "betKursNovo",
    });
  };

  return (
    <div className={classes.container}>
      {allBetKursNovoPostsQuery.isLoading ? (
        <Spinner />
      ) : (
        <>
          <h1>PREMIUM</h1>
          <div className={classes.top}>
            {isArray && allBetKursNovoPostsQuery.data.result !== undefined && (
              <AdminPostSearchModule type="betKursNovo" />
            )}
            <button onClick={() => handleNewPost()} className={classes.btn}>
              Dodaj Novi
            </button>
          </div>
          <div className={classes.postContainer}>
            <div>
              {isArray && allBetKursNovoPostsQuery.data.result !== undefined
                ? allBetKursNovoPostsQuery.data.result.map((post) => {
                    return (
                      <AdminSinglePost
                        post={post}
                        key={post._id}
                        type="betKursNovo"
                      />
                    );
                  })
                : allBetKursNovoPostsQuery.data.msg}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default AdminBetKursNovo;
