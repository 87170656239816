import { useNavigate } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import { format } from "date-fns";
import { useState } from "react";
import classes from "./adminSinglePost.module.css";
import { Modal, Button } from "antd";

const AdminBetkoInfoZidSinglePost = ({ post }) => {
  const navigate = useNavigate();
  const [isDelModalVisible, setIsDelModalVisible] = useState(false);
  const axiosPrivate = useAxiosPrivate();
  const formatedDate = format(new Date(post.createdAt), "dd-MM-yyyy HH:mm:ss");

  const SERVER_URL = process.env.REACT_APP_SERVER_URL;

  let path;
  let func;

//   if (deletePostMutation.isLoading) return <h1>Loading...</h1>;
//   if (deletePostMutation.isError) return <h1>Error...</h1>;

  const handleIzmeni = () => {
      navigate("/betko-admin/edituj-karticu", {
        state: { postId: post._id },
      });
  };
  const handleObrisi = async () => {
    await axiosPrivate.delete(`${SERVER_URL}/betko-info-wall/${post._id}`);

    window.location.reload();
  };

  return (
    <div className={classes.singlePost}>
      <div className={classes.left}>
        <p>
          <span className={classes.title}>Modifikovan:</span>
          {formatedDate}
        </p>
        |
        <p>
          <span className={classes.title}>Naslov:</span>
          {post.title.length >= 38
            ? post.title.substring(0, 38) + "..."
            : post.title}
        </p>
      </div>
      <div className={classes.right}>
          <div>
            {" "}
            <button className={classes.btn} onClick={handleIzmeni}>
              Izmeni
            </button>
            |
          </div>
        <button
          className={classes.btn}
          onClick={() => {
            setIsDelModalVisible(true);
          }}
        >
          Obriši
        </button>
      </div>

      <Modal
        footer={[]}
        open={isDelModalVisible}
        title="Da li ste sigurni da želite da obrišete post?"
        onCancel={() => {
          setIsDelModalVisible(false);
        }}
      >
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-evenly",
            marginTop: "20px",
          }}
        >
          <Button
            onClick={(e) => {
              handleObrisi();
              setIsDelModalVisible(false);
            }}
          >
            Obriši
          </Button>
          <Button
            onClick={(e) => {
              setIsDelModalVisible(false);
            }}
          >
            Odustani
          </Button>
        </div>
      </Modal>
    </div>
  );
};

export default AdminBetkoInfoZidSinglePost;
