import classes from "./adminUserDetails.module.css";
import { useLocation, useNavigate } from "react-router-dom";
import React, { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import { format } from "date-fns";

const AdminUserDetails = () => {
  const [startDate, setStartDate] = useState(new Date());
  const [userStatus, setUserStatus] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [restricted, setRestricted] = useState(0);
  const [userRegistredDate, setUserRegistredDate] = useState("");
  const [userPaidToDate, setUserPaidToDate] = useState("");
  const [userRoles, setUserRoles] = useState([]);
  const [moderator, setModerator] = useState(false)

  const { state } = useLocation();
  const { userId } = state;
  const navigate = useNavigate();
  const axiosPrivate = useAxiosPrivate();

  const queryClient = useQueryClient();

  const getSingleUser = async (userId) => {
    const data = await axiosPrivate.get(`/user/getSingleUser/${userId}`);
    data.data.createdAt = format(new Date(data.data.createdAt), "dd-MM-yyyy");
    data.data.paidToDate = format(new Date(data.data.paidToDate), "dd-MM-yyyy");

    const newDate = new Date(data.data.commentRestricted)
    const currentDate = new Date()

    newDate.setHours(0, 0, 0, 0)
    currentDate.setHours(0, 0, 0, 0)

    const difference = newDate - currentDate
    const millisecondsInDay = 1000 * 60 * 60 * 24
    const differenceInDays = Math.floor(difference / millisecondsInDay)

    if (data.data.commentRestricted === undefined) {
      setRestricted(0)
    } else {
      setRestricted(differenceInDays)
    }

    setModerator(data.data.moderator)
    setUserEmail(data.data.email);
    setUserRegistredDate(data.data.createdAt);
    setUserPaidToDate(data.data.paidToDate);
    setUserStatus(data.data.status);
    setUserRoles(data.data.roles);
    return data.data;
  };
  const singleUserQuery = useQuery({
    queryKey: ["user", userId],
    queryFn: () => getSingleUser(userId),
    // staleTime: 0,
    // refetchInterval: 1000 * 60 * 60 * 12,
    // enabled: allUsersQuery?.data.user._id,
  });

  const changesPostMutation = useMutation({
    mutationFn: ({ data }) => handleSacuvajIzmene,
    onSuccess: () => {
      //   queryClient.setQueryData(["posts", data.id], data)   --> manuelno updetovanje cachea da se brrze pojavi
      queryClient.invalidateQueries(["user"], { exact: true });
    },
  });

  if (singleUserQuery.isLoading) return <h2>Loading...</h2>;
  if (singleUserQuery.isError) return <h2>Error...</h2>;

  if (changesPostMutation.isLoading) return <h1>Loading...</h1>;
  if (changesPostMutation.isError) return <h1>Error...</h1>;

  const handleSacuvajIzmene = async () => {
    const data = {
      newStatus: userStatus,
      newPaidToDate: startDate,
      userId,
      restricted,
      moderator,
    };
    await axiosPrivate.post("/user/saveUserChanges", {
      newStatus: userStatus,
      newPaidToDate: startDate,
      userId,
      restricted,
      moderator
    });
    changesPostMutation.mutate({ data });
    navigate(-1);
  };

  const handleVratiSeNazad = () => {
    navigate(-1);
  };

  return (
    <div style={{ overflowY: 'auto' }} className={classes.container}>
      <h1>Detalji o korisniku</h1>
      <div className={classes.info}>
        <div className={classes.singleContainer}>
          <p>Korisnik:</p>
          <span className={classes.span}>{userEmail}</span>
        </div>
        <div className={classes.singleContainer}>
          <p>Registrovan od:</p>
          <span className={classes.span}>
            {userRegistredDate.substring(0, 10)}
          </span>
        </div>
        <div className={classes.singleContainer}>
          <p>Registrovan do:</p>
          <span className={classes.span}>
            {singleUserQuery.data.status === "Admin" ||
              singleUserQuery.data.status === "Vip"
              ? "Neograničen"
              : userPaidToDate.substring(0, 10)}
          </span>
        </div>
        <div className={classes.singleContainer}>
          <p>Status: </p>
          <span className={classes.span}>{singleUserQuery.data.status}</span>
        </div>
        <div className={classes.singleContainer}>
          <p>Promeni datum važenja:</p>
          <div>
            {singleUserQuery.data.status === "Admin" ||
              singleUserQuery.data.status === "Vip" ? (
              <span className={classes.span}>Neograničen</span>
            ) : (
              <DatePicker
                className={classes.datePicker}
                selected={startDate}
                onChange={(date) => setStartDate(date)}
                // placeholderText="Weeks start on Monday"
                dateFormat="dd-MM-yyyy"
              />
            )}
          </div>
        </div>
        <div className={classes.singleContainer}>
          <p>Promeni Status: </p>
          <span className={classes.span}>
            <select
              className={classes.select}
              name="tipPredplate"
              value={userStatus}
              onChange={(e) => setUserStatus(e.target.value)}
            >
              <option value="Neplaćen">Neplaćen</option>
              <option value="1 - mesec">1 - mesec</option>
              <option value="3 - meseca">3 - meseca</option>
              <option value="6 - meseci">6 - meseci</option>
              <option value="9 - meseci">9 - meseci</option>
              <option value="12 - meseci">12 - meseci</option>
              <option value="Vip">Vip</option>
              <option value="Admin">Admin</option>
            </select>
          </span>
        </div>
        <div className={classes.singleContainer}>
          <p>Zabrani komentarisanje</p>
          <span className={classes.span}>
            <select className={classes.select} value={restricted} name='zabranaKomentarisanja' onChange={(e) => setRestricted(e.target.value)}>
              <option value={0}>Nema zabrane</option>
              <option value={1}>1 - dan</option>
              <option value={3}>3 - dana</option>
              <option value={7}>7 - dana</option>
              <option value={14}>14 - dana</option>
              <option value={30}>30 - dana</option>
              <option value={36525}>Trajno</option>
            </select>
          </span>
        </div>

        <div className={classes.singleContainer}>
          <p>Moderator</p>

          <div className={classes.moderationContainer}>
            <input className={classes.moderationInput} type='checkbox' checked={moderator} name='moderator' onChange={(e) => setModerator(e.target.checked)} />
          </div>
        </div>

        <div className={classes.napomena}>
          <h2>Napomena:</h2>
          <ul>
            <li>
              Potrebno je uvek promeniti obe vrednosti i datum važenja i status
              jer za datum važenja se uvek uzima današnji datum zbog lakšeg
              određivanja datuma važenja
            </li>
            <li>
              Nakon promene datuma važenja ili statusa korisnika potrebno je da
              prođe 15 minuta da bi promene bile prihvaćene
            </li>
          </ul>
        </div>
        <div className={classes.buttonsContainer}>
          <button onClick={handleSacuvajIzmene} className={classes.btn}>
            Sačuvaj izmene
          </button>
          <button onClick={handleVratiSeNazad} className={classes.btn}>
            Vrati se nazad
          </button>
        </div>
      </div>
    </div>
  );
};

export default AdminUserDetails;
