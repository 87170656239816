import Grupa3 from "../components/Grupa3";
import Grupa20 from "../components/Grupa20";
import classes from "./viseuslovniSistemi.module.css";

const ViseuslovniSistemi = () => {
  return (
    <div className={classes.container}>
      <Grupa3
        imgText="VIŠEUSLOVNI SISTEMI"
        imgBackgroundColorDark="#062C82"
        imgBackgroundColorLight="#0D2868"
        imgFontColor="#BF9000"
      />
      <Grupa20
        imgBackgroundColorDark="#042C83"
        imgBackgroundColorLight="#0D2868"
        imgFontColor="#BF9000"
        linkOpisKalkulatora="/bet_kalkulatori_tutorijal/645b0838cd9c8da743a193e0"
      />
    </div>
  );
};

export default ViseuslovniSistemi;
