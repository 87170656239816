import Grupa3 from "../components/Grupa3";
import Grupa20 from "../components/Grupa20";
import classes from "./pozitivnaProgresija.module.css";
import { useState, useEffect } from "react";

const PozitivnaProgresija = () => {
  const [rerender, setReRender] = useState(false);
  const [ulog, setUlog] = useState([
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  ]);
  const [upisKvota, setUpisKvota] = useState([
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  ]);
  const [eventualniDobitak, setEventualniDobitak] = useState([
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  ]);
  const [cistaDobit, setCistaDobit] = useState([
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  ]);
  const [upisProcenata, setUpisProcenata] = useState([
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  ]);
  const [prenosUUnitima, setPrenosUUnitima] = useState([
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  ]);
  const [trenutnoStanje, setTrenutnoStanje] = useState(0);
  const [upisUlogaPromasaja, setUpisUlogaPromasaja] = useState([
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  ]);

  const [ukupanDobitakGubitak, setUkupanGubitakDobitak] = useState([0, 0, 0]);

  const handleChangeUlog = (e) => {
    let ulogpriv = ulog;
    ulogpriv[0] = parseFloat(e);

    setUlog(ulogpriv);
    setReRender(!rerender);
    handleCalculate();
  };

  const handleChangeKvota = (e, index) => {
    let kvotapriv = upisKvota;

    kvotapriv[index] = parseFloat(e);

    setUpisKvota(kvotapriv);
    setReRender(!rerender);
    handleCalculate();
  };

  const handleChangeUpisProcenta = (e, index) => {
    let upispriv = upisProcenata;

    upispriv[index] = parseFloat(e);

    setUpisProcenata(upispriv);
    setReRender(!rerender);
    handleCalculate();
  };

  const handleChangeUpisUlogaPromasaja = (e, index) => {
    let upispriv = upisUlogaPromasaja;

    upispriv[index] = parseFloat(e);

    setUpisUlogaPromasaja(upispriv);
    setReRender(!rerender);
    handleCalculate();
  };

  useEffect(() => {
    handleCalculate();
  }, [ulog, upisKvota, rerender, upisProcenata, upisUlogaPromasaja]);

  const handleCalculate = () => {
    let eventualniDobitakArr = eventualniDobitak;

    for (let i = 0; i < eventualniDobitak.length; i++) {
      let item = upisKvota[i] * ulog[i];
      if (isNaN(item)) {
        eventualniDobitakArr[i] = 0;
      } else {
        eventualniDobitakArr[i] = item.toFixed(2);
      }
    }

    setEventualniDobitak(eventualniDobitakArr);

    let cistadobitArr = cistaDobit;

    for (let i = 0; i < cistaDobit.length; i++) {
      let item = eventualniDobitak[i] - ulog[i];
      if (isNaN(item)) {
        cistadobitArr[i] = 0;
      } else if (item > 0) {
        cistadobitArr[i] = item.toFixed(2);
      } else {
        cistadobitArr[i] = 0;
      }
    }

    setCistaDobit(cistadobitArr);

    let prenosuunitimaArr = prenosUUnitima;

    for (let i = 0; i < prenosUUnitima.length; i++) {
      let item = parseFloat(cistaDobit[i]) * (upisProcenata[i] / 100);
      if (isNaN(item)) {
        prenosuunitimaArr[i] = 0;
      } else {
        prenosuunitimaArr[i] = item.toFixed(2);
      }
    }

    // console.log(prenosuunitimaArr);

    setPrenosUUnitima(prenosuunitimaArr);

    let sumCistaDobit = 0;

    for (let i = 0; i < cistaDobit.length; i++) {
      sumCistaDobit = sumCistaDobit + parseFloat(cistaDobit[i]);
    }

    let ulogarr = ulog;

    for (let i = 1; i < ulog.length; i++) {
      let item = prenosuunitimaArr[i - 1];

      ulogarr[i] = item;
    }

    setUlog(ulogarr);

    let trenutnostanjepriv = sumCistaDobit - parseFloat(ulog[0]);

    setTrenutnoStanje(trenutnostanjepriv.toFixed(2));

    let ukupanUpisUlogaPromasaja = 0;

    for (let i = 0; i < upisUlogaPromasaja.length; i++) {
      ukupanUpisUlogaPromasaja =
        ukupanUpisUlogaPromasaja + parseFloat(upisUlogaPromasaja[i]);
    }

    let gubitakDobitakPriv = ukupanDobitakGubitak;

    gubitakDobitakPriv[0] = sumCistaDobit;

    gubitakDobitakPriv[1] = trenutnostanjepriv.toFixed(2);

    gubitakDobitakPriv[2] = ukupanUpisUlogaPromasaja;

    setUkupanGubitakDobitak(gubitakDobitakPriv);
  };

  return (
    <div className={classes.container}>
      <Grupa3
        imgText="POZITIVNA PROGRESIJA"
        imgBackgroundColorDark="#642891"
        imgBackgroundColorLight="#7831AE"
        imgFontColor="#FF0000"
      />
      <Grupa20
        imgBackgroundColorDark="#642891"
        imgBackgroundColorLight="#7831AE"
        imgFontColor="#FF0000"
        calculatorType={`Pozitivna progresija`}
      />
      <div className={classes.calculator} style={{ fontWeight: "bolder" }}>
        <div className={classes.row}>
          <div
            style={{
              height: "50px",
              display: "flex",
              width: "64px",
              background: "orangered",
              justifyContent: "center",
              color: "white",
              borderRight: "1px solid black",
              borderTop: "1px solid black",
            }}
          >
            <p style={{ textAlign: "center", marginTop: "9px" }}>KRUG BROJ</p>
          </div>
          <div
            style={{
              height: "50px",
              width: "80px",
              background: "orangered",
              border: "1px solid black",
              borderLeft: "none",
              borderBottom: "none",
              fontWeight: "bolder",
            }}
          >
            <p style={{ textAlign: "center", marginTop: "9px" }}>UPIS ULOG</p>
          </div>

          <div
            style={{
              height: "50px",
              background: "orangered",
              width: "68px",
              border: "1px solid black",
              borderLeft: "none",
              borderBottom: "none",
              fontWeight: "bolder",
            }}
          >
            <p style={{ textAlign: "center", marginTop: "9px" }}>UPIS KVOTA</p>
          </div>

          <div
            style={{
              height: "50px",
              background: "orangered",
              width: "127px",
              border: "1px solid black",
              borderLeft: "none",
              borderBottom: "none",
              fontWeight: "bolder",
            }}
          >
            <p style={{ textAlign: "center", marginTop: "9px" }}>
              EVENTUALNI DOBITAK
            </p>
          </div>

          <div
            style={{
              height: "50px",
              background: "orangered",
              width: "148px",
              border: "1px solid black",
              borderLeft: "none",
              borderBottom: "none",
              fontWeight: "bolder",
            }}
          >
            <p style={{ textAlign: "center", marginTop: "9px" }}>
              UPIS PROLAZ(DA/NE)
            </p>
          </div>

          <div
            style={{
              height: "50px",
              background: "orangered",
              width: "121px",
              border: "1px solid black",
              borderLeft: "none",
              borderBottom: "none",
              fontWeight: "bolder",
            }}
          >
            <p style={{ textAlign: "center" }}>CISTA DOBIT</p>
          </div>

          <div
            style={{
              height: "50px",
              background: "orangered",
              width: "190px",
              border: "1px solid black",
              borderLeft: "none",
              borderBottom: "none",
              fontWeight: "bolder",
            }}
          >
            <p
              style={{
                textAlign: "center",
                marginTop: "8px",
                fontSize: "15px",
              }}
            >
              UPIS PROCENATA (%) PRENOS U SLEDECI KRUG
            </p>
          </div>

          <div
            style={{
              height: "50px",
              background: "orangered",
              width: "180px",
              border: "1px solid black",
              borderLeft: "none",
              borderBottom: "none",
              fontWeight: "bolder",
            }}
          >
            <p style={{ textAlign: "center" }}>PRENOS U UNITIMA</p>
          </div>

          <div
            style={{
              height: "50px",
              background: "orangered",
              width: "179px",
              border: "1px solid black",
              borderLeft: "none",
              borderBottom: "none",
              fontWeight: "bolder",
            }}
          >
            <p style={{ textAlign: "center" }}>TRENUTNO STANJE</p>
          </div>

          <div
            className={classes.upisProcenataRow}
            style={{ display: "flex", flexDirection: "row", width: "260px" }}
          >
            <div
              style={{
                display: "flex",
                width: "260px",
                background: "orangered",
                fontWeight: "bolder",
                color: "white",
                justifyContent: "center",
                borderTop: "1px solid black",
              }}
            >
              <p style={{ textAlign: "center" }}>UPIS ULOGA PROMASAJA - KRUG</p>
            </div>
          </div>
        </div>

        <div className={classes.row}>
          <div
            style={{
              display: "flex",
              width: "64px",
              background: "#712ea1",
              justifyContent: "center",
              color: "white",
              borderRight: "1px solid black",
            }}
          >
            1
          </div>
          <div className={classes.upisUlogFirstRow2}>
            <input
              style={{
                width: "72px",
                height: "15px",
                textAlign: "center",
                borderRight: "1px solid black",
                fontWeight: "bolder",
              }}
              type="number"
              min="0"
              onChange={(e) => {
                handleChangeUlog(e.target.value);
              }}
            />
          </div>
          <div className={classes.upisKvotaRow}>
            <input
              type="number"
              min="0"
              onChange={(e) => {
                handleChangeKvota(e.target.value, 0);
              }}
            />
          </div>
          <div className={classes.eventualniDobitakRow}>
            {eventualniDobitak[0] === "0.00" ? "-" : eventualniDobitak[0]}
          </div>
          <div className={classes.upisProlazDaNeRow}>DA</div>

          <div className={classes.cistaDobitRow}>
            {cistaDobit[0] === 0 ? "-" : cistaDobit[0]}
          </div>

          <div className={classes.upisProcenataRow}>
            <input
              type="number"
              min="0"
              onChange={(e) => {
                handleChangeUpisProcenta(e.target.value, 0);
              }}
            />
          </div>
          <div className={classes.prenosUunitmaRow}>
            {prenosUUnitima[0] === "0.00" ? "-" : prenosUUnitima[0]}
          </div>
          <div className={classes.trenutnoStanjeRow}>
            {trenutnoStanje === "0.00" ? "-" : trenutnoStanje}
          </div>
          <div
            className={classes.upisProcenataRow}
            style={{ display: "flex", flexDirection: "row", width: "260px" }}
          >
            <div
              style={{
                display: "flex",
                width: "100px",
                background: "orangered",
                fontWeight: "bolder",
                color: "white",
                justifyContent: "center",
              }}
            >
              KRUG 1-NE
            </div>
            <input
              style={{ width: "160px" }}
              type="number"
              min="0"
              onChange={(e) => {
                handleChangeUpisUlogaPromasaja(e.target.value, 0);
              }}
            />
          </div>
        </div>
        <div className={classes.row}>
          <div
            style={{
              display: "flex",
              width: "64px",
              background: "#712ea1",
              justifyContent: "center",
              color: "white",
              borderRight: "1px solid black",
            }}
          >
            2
          </div>
          <div className={classes.upisUlogFirstRow2}>{ulog[1]}</div>
          <div className={classes.upisKvotaRow}>
            <input
              type="number"
              min="0"
              onChange={(e) => {
                handleChangeKvota(e.target.value, 1);
              }}
            />
          </div>
          <div className={classes.eventualniDobitakRow}>
            {eventualniDobitak[1] === "0.00" ? "-" : eventualniDobitak[1]}
          </div>
          <div className={classes.upisProlazDaNeRow}>DA</div>

          <div className={classes.cistaDobitRow}>
            {cistaDobit[1] === 0 ? "-" : cistaDobit[1]}
          </div>

          <div className={classes.upisProcenataRow}>
            <input
              type="number"
              min="0"
              onChange={(e) => {
                handleChangeUpisProcenta(e.target.value, 1);
              }}
            />
          </div>
          <div className={classes.prenosUunitmaRow}>
            {prenosUUnitima[1] === "0.00" ? "-" : prenosUUnitima[1]}
          </div>
          <div className={classes.trenutnoStanjeRow}>
            {trenutnoStanje === "0.00" ? "-" : trenutnoStanje}
          </div>
          <div
            className={classes.upisProcenataRow}
            style={{ display: "flex", flexDirection: "row", width: "260px" }}
          >
            <div
              style={{
                display: "flex",
                width: "100px",
                background: "orangered",
                fontWeight: "bolder",
                color: "white",
                justifyContent: "center",
              }}
            >
              KRUG 1-NE
            </div>
            <input
              style={{ width: "160px" }}
              type="number"
              min="0"
              onChange={(e) => {
                handleChangeUpisUlogaPromasaja(e.target.value, 1);
              }}
            />
          </div>
        </div>
        <div className={classes.row}>
          <div
            style={{
              display: "flex",
              width: "64px",
              background: "#712ea1",
              justifyContent: "center",
              color: "white",
              borderRight: "1px solid black",
            }}
          >
            3
          </div>
          <div className={classes.upisUlogFirstRow2}>{ulog[2]}</div>
          <div className={classes.upisKvotaRow}>
            <input
              type="number"
              min="0"
              onChange={(e) => {
                handleChangeKvota(e.target.value, 2);
              }}
            />
          </div>
          <div className={classes.eventualniDobitakRow}>
            {eventualniDobitak[2] === "0.00" ? "-" : eventualniDobitak[2]}
          </div>
          <div className={classes.upisProlazDaNeRow}>DA</div>

          <div className={classes.cistaDobitRow}>
            {cistaDobit[2] === 0 ? "-" : cistaDobit[2]}
          </div>

          <div className={classes.upisProcenataRow}>
            <input
              type="number"
              min="0"
              onChange={(e) => {
                handleChangeUpisProcenta(e.target.value, 2);
              }}
            />
          </div>
          <div className={classes.prenosUunitmaRow}>
            {prenosUUnitima[2] === "0.00" ? "-" : prenosUUnitima[2]}
          </div>
          <div className={classes.trenutnoStanjeRow}>
            {trenutnoStanje === "0.00" ? "-" : trenutnoStanje}
          </div>
          <div
            className={classes.upisProcenataRow}
            style={{ display: "flex", flexDirection: "row", width: "260px" }}
          >
            <div
              style={{
                display: "flex",
                width: "100px",
                background: "orangered",
                fontWeight: "bolder",
                color: "white",
                justifyContent: "center",
              }}
            >
              KRUG 1-NE
            </div>
            <input
              style={{ width: "160px" }}
              type="number"
              min="0"
              onChange={(e) => {
                handleChangeUpisUlogaPromasaja(e.target.value, 2);
              }}
            />
          </div>
        </div>
        <div className={classes.row}>
          <div
            style={{
              display: "flex",
              width: "64px",
              background: "#712ea1",
              justifyContent: "center",
              color: "white",
              borderRight: "1px solid black",
            }}
          >
            4
          </div>
          <div className={classes.upisUlogFirstRow2}>{ulog[3]}</div>
          <div className={classes.upisKvotaRow}>
            <input
              type="number"
              min="0"
              onChange={(e) => {
                handleChangeKvota(e.target.value, 3);
              }}
            />
          </div>
          <div className={classes.eventualniDobitakRow}>
            {eventualniDobitak[3] === "0.00" ? "-" : eventualniDobitak[3]}
          </div>
          <div className={classes.upisProlazDaNeRow}>DA</div>

          <div className={classes.cistaDobitRow}>
            {cistaDobit[3] === 0 ? "-" : cistaDobit[3]}
          </div>

          <div className={classes.upisProcenataRow}>
            <input
              type="number"
              min="0"
              onChange={(e) => {
                handleChangeUpisProcenta(e.target.value, 3);
              }}
            />
          </div>
          <div className={classes.prenosUunitmaRow}>
            {prenosUUnitima[3] === "0.00" ? "-" : prenosUUnitima[3]}
          </div>
          <div className={classes.trenutnoStanjeRow}>
            {trenutnoStanje === "0.00" ? "-" : trenutnoStanje}
          </div>
          <div
            className={classes.upisProcenataRow}
            style={{ display: "flex", flexDirection: "row", width: "260px" }}
          >
            <div
              style={{
                display: "flex",
                width: "100px",
                background: "orangered",
                fontWeight: "bolder",
                color: "white",
                justifyContent: "center",
              }}
            >
              KRUG 1-NE
            </div>
            <input
              style={{ width: "160px" }}
              type="number"
              min="0"
              onChange={(e) => {
                handleChangeUpisUlogaPromasaja(e.target.value, 3);
              }}
            />
          </div>
        </div>
        <div className={classes.row}>
          <div
            style={{
              display: "flex",
              width: "64px",
              background: "#712ea1",
              justifyContent: "center",
              color: "white",
              borderRight: "1px solid black",
            }}
          >
            5
          </div>
          <div className={classes.upisUlogFirstRow2}>{ulog[4]}</div>
          <div className={classes.upisKvotaRow}>
            <input
              type="number"
              min="0"
              onChange={(e) => {
                handleChangeKvota(e.target.value, 4);
              }}
            />
          </div>
          <div className={classes.eventualniDobitakRow}>
            {eventualniDobitak[4] === "0.00" ? "-" : eventualniDobitak[4]}
          </div>
          <div className={classes.upisProlazDaNeRow}>DA</div>

          <div className={classes.cistaDobitRow}>
            {cistaDobit[4] === 0 ? "-" : cistaDobit[4]}
          </div>

          <div className={classes.upisProcenataRow}>
            <input
              type="number"
              min="0"
              onChange={(e) => {
                handleChangeUpisProcenta(e.target.value, 4);
              }}
            />
          </div>
          <div className={classes.prenosUunitmaRow}>
            {prenosUUnitima[4] === "0.00" ? "-" : prenosUUnitima[4]}
          </div>
          <div className={classes.trenutnoStanjeRow}>
            {trenutnoStanje === "0.00" ? "-" : trenutnoStanje}
          </div>
          <div
            className={classes.upisProcenataRow}
            style={{ display: "flex", flexDirection: "row", width: "260px" }}
          >
            <div
              style={{
                display: "flex",
                width: "100px",
                background: "orangered",
                fontWeight: "bolder",
                color: "white",
                justifyContent: "center",
              }}
            >
              KRUG 1-NE
            </div>
            <input
              style={{ width: "160px" }}
              type="number"
              min="0"
              onChange={(e) => {
                handleChangeUpisUlogaPromasaja(e.target.value, 4);
              }}
            />
          </div>
        </div>
        <div className={classes.row}>
          <div
            style={{
              display: "flex",
              width: "64px",
              background: "#712ea1",
              justifyContent: "center",
              color: "white",
              borderRight: "1px solid black",
            }}
          >
            6
          </div>
          <div className={classes.upisUlogFirstRow2}>{ulog[5]}</div>
          <div className={classes.upisKvotaRow}>
            <input
              type="number"
              min="0"
              onChange={(e) => {
                handleChangeKvota(e.target.value, 5);
              }}
            />
          </div>
          <div className={classes.eventualniDobitakRow}>
            {eventualniDobitak[5] === "0.00" ? "-" : eventualniDobitak[5]}
          </div>
          <div className={classes.upisProlazDaNeRow}>DA</div>

          <div className={classes.cistaDobitRow}>
            {cistaDobit[5] === 0 ? "-" : cistaDobit[5]}
          </div>

          <div className={classes.upisProcenataRow}>
            <input
              type="number"
              min="0"
              onChange={(e) => {
                handleChangeUpisProcenta(e.target.value, 5);
              }}
            />
          </div>
          <div className={classes.prenosUunitmaRow}>
            {prenosUUnitima[5] === "0.00" ? "-" : prenosUUnitima[5]}
          </div>
          <div className={classes.trenutnoStanjeRow}>
            {trenutnoStanje === "0.00" ? "-" : trenutnoStanje}
          </div>
          <div
            className={classes.upisProcenataRow}
            style={{ display: "flex", flexDirection: "row", width: "260px" }}
          >
            <div
              style={{
                display: "flex",
                width: "100px",
                background: "orangered",
                fontWeight: "bolder",
                color: "white",
                justifyContent: "center",
              }}
            >
              KRUG 1-NE
            </div>
            <input
              style={{ width: "160px" }}
              type="number"
              min="0"
              onChange={(e) => {
                handleChangeUpisUlogaPromasaja(e.target.value, 5);
              }}
            />
          </div>
        </div>
        <div className={classes.row}>
          <div
            style={{
              display: "flex",
              width: "64px",
              background: "#712ea1",
              justifyContent: "center",
              color: "white",
              borderRight: "1px solid black",
            }}
          >
            7
          </div>
          <div className={classes.upisUlogFirstRow2}>{ulog[6]}</div>
          <div className={classes.upisKvotaRow}>
            <input
              type="number"
              min="0"
              onChange={(e) => {
                handleChangeKvota(e.target.value, 6);
              }}
            />
          </div>
          <div className={classes.eventualniDobitakRow}>
            {eventualniDobitak[6] === "0.00" ? "-" : eventualniDobitak[6]}
          </div>
          <div className={classes.upisProlazDaNeRow}>DA</div>

          <div className={classes.cistaDobitRow}>
            {cistaDobit[6] === 0 ? "-" : cistaDobit[6]}
          </div>

          <div className={classes.upisProcenataRow}>
            <input
              type="number"
              min="0"
              onChange={(e) => {
                handleChangeUpisProcenta(e.target.value, 6);
              }}
            />
          </div>
          <div className={classes.prenosUunitmaRow}>
            {prenosUUnitima[6] === "0.00" ? "-" : prenosUUnitima[6]}
          </div>
          <div className={classes.trenutnoStanjeRow}>
            {trenutnoStanje === "0.00" ? "-" : trenutnoStanje}
          </div>
          <div
            className={classes.upisProcenataRow}
            style={{ display: "flex", flexDirection: "row", width: "260px" }}
          >
            <div
              style={{
                display: "flex",
                width: "100px",
                background: "orangered",
                fontWeight: "bolder",
                color: "white",
                justifyContent: "center",
              }}
            >
              KRUG 1-NE
            </div>
            <input
              style={{ width: "160px" }}
              type="number"
              min="0"
              onChange={(e) => {
                handleChangeUpisUlogaPromasaja(e.target.value, 6);
              }}
            />
          </div>
        </div>
        <div className={classes.row}>
          <div
            style={{
              display: "flex",
              width: "64px",
              background: "#712ea1",
              justifyContent: "center",
              color: "white",
              borderRight: "1px solid black",
            }}
          >
            8
          </div>
          <div className={classes.upisUlogFirstRow2}>{ulog[7]}</div>
          <div className={classes.upisKvotaRow}>
            <input
              type="number"
              min="0"
              onChange={(e) => {
                handleChangeKvota(e.target.value, 7);
              }}
            />
          </div>
          <div className={classes.eventualniDobitakRow}>
            {eventualniDobitak[7] === "0.00" ? "-" : eventualniDobitak[7]}
          </div>
          <div className={classes.upisProlazDaNeRow}>DA</div>

          <div className={classes.cistaDobitRow}>
            {cistaDobit[7] === 0 ? "-" : cistaDobit[7]}
          </div>

          <div className={classes.upisProcenataRow}>
            <input
              type="number"
              min="0"
              onChange={(e) => {
                handleChangeUpisProcenta(e.target.value, 7);
              }}
            />
          </div>
          <div className={classes.prenosUunitmaRow}>
            {prenosUUnitima[7] === "0.00" ? "-" : prenosUUnitima[7]}
          </div>
          <div className={classes.trenutnoStanjeRow}>
            {trenutnoStanje === "0.00" ? "-" : trenutnoStanje}
          </div>
          <div
            className={classes.upisProcenataRow}
            style={{ display: "flex", flexDirection: "row", width: "260px" }}
          >
            <div
              style={{
                display: "flex",
                width: "100px",
                background: "orangered",
                fontWeight: "bolder",
                color: "white",
                justifyContent: "center",
              }}
            >
              KRUG 1-NE
            </div>
            <input
              style={{ width: "160px" }}
              type="number"
              min="0"
              onChange={(e) => {
                handleChangeUpisUlogaPromasaja(e.target.value, 7);
              }}
            />
          </div>
        </div>
        <div className={classes.row}>
          <div
            style={{
              display: "flex",
              width: "64px",
              background: "#712ea1",
              justifyContent: "center",
              color: "white",
              borderRight: "1px solid black",
            }}
          >
            9
          </div>
          <div className={classes.upisUlogFirstRow2}>{ulog[8]}</div>
          <div className={classes.upisKvotaRow}>
            <input
              type="number"
              min="0"
              onChange={(e) => {
                handleChangeKvota(e.target.value, 8);
              }}
            />
          </div>
          <div className={classes.eventualniDobitakRow}>
            {eventualniDobitak[8] === "0.00" ? "-" : eventualniDobitak[8]}
          </div>
          <div className={classes.upisProlazDaNeRow}>DA</div>

          <div className={classes.cistaDobitRow}>
            {cistaDobit[8] === 0 ? "-" : cistaDobit[8]}
          </div>

          <div className={classes.upisProcenataRow}>
            <input
              type="number"
              min="0"
              onChange={(e) => {
                handleChangeUpisProcenta(e.target.value, 8);
              }}
            />
          </div>
          <div className={classes.prenosUunitmaRow}>
            {prenosUUnitima[8] === "0.00" ? "-" : prenosUUnitima[8]}
          </div>
          <div className={classes.trenutnoStanjeRow}>
            {trenutnoStanje === "0.00" ? "-" : trenutnoStanje}
          </div>
          <div
            className={classes.upisProcenataRow}
            style={{ display: "flex", flexDirection: "row", width: "260px" }}
          >
            <div
              style={{
                display: "flex",
                width: "100px",
                background: "orangered",
                fontWeight: "bolder",
                color: "white",
                justifyContent: "center",
              }}
            >
              KRUG 1-NE
            </div>
            <input
              style={{ width: "160px" }}
              type="number"
              min="0"
              onChange={(e) => {
                handleChangeUpisUlogaPromasaja(e.target.value, 8);
              }}
            />
          </div>
        </div>
        <div className={classes.row}>
          <div
            style={{
              display: "flex",
              width: "64px",
              background: "#712ea1",
              justifyContent: "center",
              color: "white",
              borderRight: "1px solid black",
            }}
          >
            10
          </div>
          <div className={classes.upisUlogFirstRow2}>{ulog[9]}</div>
          <div className={classes.upisKvotaRow}>
            <input
              type="number"
              min="0"
              onChange={(e) => {
                handleChangeKvota(e.target.value, 9);
              }}
            />
          </div>
          <div className={classes.eventualniDobitakRow}>
            {eventualniDobitak[9] === "0.00" ? "-" : eventualniDobitak[9]}
          </div>
          <div className={classes.upisProlazDaNeRow}>DA</div>

          <div className={classes.cistaDobitRow}>
            {cistaDobit[9] === 0 ? "-" : cistaDobit[9]}
          </div>

          <div className={classes.upisProcenataRow}>
            <input
              type="number"
              min="0"
              onChange={(e) => {
                handleChangeUpisProcenta(e.target.value, 9);
              }}
            />
          </div>
          <div className={classes.prenosUunitmaRow}>
            {prenosUUnitima[9] === "0.00" ? "-" : prenosUUnitima[9]}
          </div>
          <div className={classes.trenutnoStanjeRow}>
            {trenutnoStanje === "0.00" ? "-" : trenutnoStanje}
          </div>
          <div
            className={classes.upisProcenataRow}
            style={{ display: "flex", flexDirection: "row", width: "260px" }}
          >
            <div
              style={{
                display: "flex",
                width: "100px",
                background: "orangered",
                fontWeight: "bolder",
                color: "white",
                justifyContent: "center",
              }}
            >
              KRUG 1-NE
            </div>
            <input
              style={{ width: "160px" }}
              type="number"
              min="0"
              onChange={(e) => {
                handleChangeUpisUlogaPromasaja(e.target.value, 9);
              }}
            />
          </div>
        </div>
        <div className={classes.row}>
          <div
            style={{
              display: "flex",
              width: "64px",
              background: "#712ea1",
              justifyContent: "center",
              color: "white",
              borderRight: "1px solid black",
            }}
          >
            11
          </div>
          <div className={classes.upisUlogFirstRow2}>{ulog[10]}</div>
          <div className={classes.upisKvotaRow}>
            <input
              type="number"
              min="0"
              onChange={(e) => {
                handleChangeKvota(e.target.value, 10);
              }}
            />
          </div>
          <div className={classes.eventualniDobitakRow}>
            {eventualniDobitak[10] === "0.00" ? "-" : eventualniDobitak[10]}
          </div>
          <div className={classes.upisProlazDaNeRow}>DA</div>

          <div className={classes.cistaDobitRow}>
            {cistaDobit[10] === 0 ? "-" : cistaDobit[10]}
          </div>

          <div className={classes.upisProcenataRow}>
            <input
              type="number"
              min="0"
              onChange={(e) => {
                handleChangeUpisProcenta(e.target.value, 10);
              }}
            />
          </div>
          <div className={classes.prenosUunitmaRow}>
            {prenosUUnitima[10] === "0.00" ? "-" : prenosUUnitima[10]}
          </div>
          <div className={classes.trenutnoStanjeRow}>
            {trenutnoStanje === "0.00" ? "-" : trenutnoStanje}
          </div>
          <div
            className={classes.upisProcenataRow}
            style={{ display: "flex", flexDirection: "row", width: "260px" }}
          >
            <div
              style={{
                display: "flex",
                width: "100px",
                background: "orangered",
                fontWeight: "bolder",
                color: "white",
                justifyContent: "center",
              }}
            >
              KRUG 1-NE
            </div>
            <input
              style={{ width: "160px" }}
              type="number"
              min="0"
              onChange={(e) => {
                handleChangeUpisUlogaPromasaja(e.target.value, 10);
              }}
            />
          </div>
        </div>
        <div className={classes.row}>
          <div
            style={{
              display: "flex",
              width: "64px",
              background: "#712ea1",
              justifyContent: "center",
              color: "white",
              borderRight: "1px solid black",
            }}
          >
            12
          </div>
          <div className={classes.upisUlogFirstRow2}>{ulog[11]}</div>
          <div className={classes.upisKvotaRow}>
            <input
              type="number"
              min="0"
              onChange={(e) => {
                handleChangeKvota(e.target.value, 11);
              }}
            />
          </div>
          <div className={classes.eventualniDobitakRow}>
            {eventualniDobitak[11] === "0.00" ? "-" : eventualniDobitak[11]}
          </div>
          <div className={classes.upisProlazDaNeRow}>DA</div>

          <div className={classes.cistaDobitRow}>
            {cistaDobit[11] === 0 ? "-" : cistaDobit[11]}
          </div>

          <div className={classes.upisProcenataRow}>
            <input
              type="number"
              min="0"
              onChange={(e) => {
                handleChangeUpisProcenta(e.target.value, 11);
              }}
            />
          </div>
          <div className={classes.prenosUunitmaRow}>
            {prenosUUnitima[11] === "0.00" ? "-" : prenosUUnitima[11]}
          </div>
          <div className={classes.trenutnoStanjeRow}>
            {trenutnoStanje === "0.00" ? "-" : trenutnoStanje}
          </div>
          <div
            className={classes.upisProcenataRow}
            style={{ display: "flex", flexDirection: "row", width: "260px" }}
          >
            <div
              style={{
                display: "flex",
                width: "100px",
                background: "orangered",
                fontWeight: "bolder",
                color: "white",
                justifyContent: "center",
              }}
            >
              KRUG 1-NE
            </div>
            <input
              style={{ width: "160px" }}
              type="number"
              min="0"
              onChange={(e) => {
                handleChangeUpisUlogaPromasaja(e.target.value, 11);
              }}
            />
          </div>
        </div>
        <div className={classes.row}>
          <div
            style={{
              display: "flex",
              width: "64px",
              background: "#712ea1",
              justifyContent: "center",
              color: "white",
              borderRight: "1px solid black",
            }}
          >
            13
          </div>
          <div className={classes.upisUlogFirstRow2}>{ulog[12]}</div>
          <div className={classes.upisKvotaRow}>
            <input
              type="number"
              min="0"
              onChange={(e) => {
                handleChangeKvota(e.target.value, 12);
              }}
            />
          </div>
          <div className={classes.eventualniDobitakRow}>
            {eventualniDobitak[12] === "0.00" ? "-" : eventualniDobitak[12]}
          </div>
          <div className={classes.upisProlazDaNeRow}>DA</div>

          <div className={classes.cistaDobitRow}>
            {cistaDobit[12] === 0 ? "-" : cistaDobit[12]}
          </div>

          <div className={classes.upisProcenataRow}>
            <input
              type="number"
              min="0"
              onChange={(e) => {
                handleChangeUpisProcenta(e.target.value, 12);
              }}
            />
          </div>
          <div className={classes.prenosUunitmaRow}>
            {prenosUUnitima[12] === "0.00" ? "-" : prenosUUnitima[12]}
          </div>
          <div className={classes.trenutnoStanjeRow}>
            {trenutnoStanje === "0.00" ? "-" : trenutnoStanje}
          </div>
          <div
            className={classes.upisProcenataRow}
            style={{ display: "flex", flexDirection: "row", width: "260px" }}
          >
            <div
              style={{
                display: "flex",
                width: "100px",
                background: "orangered",
                fontWeight: "bolder",
                color: "white",
                justifyContent: "center",
              }}
            >
              KRUG 1-NE
            </div>
            <input
              style={{ width: "160px" }}
              type="number"
              min="0"
              onChange={(e) => {
                handleChangeUpisUlogaPromasaja(e.target.value, 12);
              }}
            />
          </div>
        </div>
        <div className={classes.row}>
          <div
            style={{
              display: "flex",
              width: "64px",
              background: "#712ea1",
              justifyContent: "center",
              color: "white",
              borderRight: "1px solid black",
            }}
          >
            14
          </div>
          <div className={classes.upisUlogFirstRow2}>{ulog[13]}</div>
          <div className={classes.upisKvotaRow}>
            <input
              type="number"
              min="0"
              onChange={(e) => {
                handleChangeKvota(e.target.value, 13);
              }}
            />
          </div>
          <div className={classes.eventualniDobitakRow}>
            {eventualniDobitak[13] === "0.00" ? "-" : eventualniDobitak[13]}
          </div>
          <div className={classes.upisProlazDaNeRow}>DA</div>

          <div className={classes.cistaDobitRow}>
            {cistaDobit[13] === 0 ? "-" : cistaDobit[13]}
          </div>

          <div className={classes.upisProcenataRow}>
            <input
              type="number"
              min="0"
              onChange={(e) => {
                handleChangeUpisProcenta(e.target.value, 13);
              }}
            />
          </div>
          <div className={classes.prenosUunitmaRow}>
            {prenosUUnitima[13] === "0.00" ? "-" : prenosUUnitima[13]}
          </div>
          <div className={classes.trenutnoStanjeRow}>
            {trenutnoStanje === "0.00" ? "-" : trenutnoStanje}
          </div>
          <div
            className={classes.upisProcenataRow}
            style={{ display: "flex", flexDirection: "row", width: "260px" }}
          >
            <div
              style={{
                display: "flex",
                width: "100px",
                background: "orangered",
                fontWeight: "bolder",
                color: "white",
                justifyContent: "center",
              }}
            >
              KRUG 1-NE
            </div>
            <input
              style={{ width: "160px" }}
              type="number"
              min="0"
              onChange={(e) => {
                handleChangeUpisUlogaPromasaja(e.target.value, 13);
              }}
            />
          </div>
        </div>
        <div className={classes.row}>
          <div
            style={{
              display: "flex",
              width: "64px",
              background: "#712ea1",
              justifyContent: "center",
              color: "white",
              borderRight: "1px solid black",
            }}
          >
            15
          </div>
          <div className={classes.upisUlogFirstRow2}>{ulog[14]}</div>
          <div className={classes.upisKvotaRow}>
            <input
              type="number"
              min="0"
              onChange={(e) => {
                handleChangeKvota(e.target.value, 14);
              }}
            />
          </div>
          <div className={classes.eventualniDobitakRow}>
            {eventualniDobitak[14] === "0.00" ? "-" : eventualniDobitak[14]}
          </div>
          <div className={classes.upisProlazDaNeRow}>DA</div>

          <div className={classes.cistaDobitRow}>
            {cistaDobit[14] === 0 ? "-" : cistaDobit[14]}
          </div>

          <div className={classes.upisProcenataRow}>
            <input
              type="number"
              min="0"
              onChange={(e) => {
                handleChangeUpisProcenta(e.target.value, 14);
              }}
            />
          </div>
          <div className={classes.prenosUunitmaRow}>
            {prenosUUnitima[14] === "0.00" ? "-" : prenosUUnitima[14]}
          </div>
          <div className={classes.trenutnoStanjeRow}>
            {trenutnoStanje === "0.00" ? "-" : trenutnoStanje}
          </div>
          <div
            className={classes.upisProcenataRow}
            style={{ display: "flex", flexDirection: "row", width: "260px" }}
          >
            <div
              style={{
                display: "flex",
                width: "100px",
                background: "orangered",
                fontWeight: "bolder",
                color: "white",
                justifyContent: "center",
              }}
            >
              KRUG 1-NE
            </div>
            <input
              style={{ width: "160px" }}
              type="number"
              min="0"
              onChange={(e) => {
                handleChangeUpisUlogaPromasaja(e.target.value, 14);
              }}
            />
          </div>
        </div>
        <div className={classes.row}>
          <div
            style={{
              display: "flex",
              width: "64px",
              background: "#712ea1",
              justifyContent: "center",
              color: "white",
              borderRight: "1px solid black",
            }}
          >
            16
          </div>
          <div className={classes.upisUlogFirstRow2}>{ulog[15]}</div>
          <div className={classes.upisKvotaRow}>
            <input
              type="number"
              min="0"
              onChange={(e) => {
                handleChangeKvota(e.target.value, 15);
              }}
            />
          </div>
          <div className={classes.eventualniDobitakRow}>
            {eventualniDobitak[15] === "0.00" ? "-" : eventualniDobitak[15]}
          </div>
          <div className={classes.upisProlazDaNeRow}>DA</div>

          <div className={classes.cistaDobitRow}>
            {cistaDobit[15] === 0 ? "-" : cistaDobit[15]}
          </div>

          <div className={classes.upisProcenataRow}>
            <input
              type="number"
              min="0"
              onChange={(e) => {
                handleChangeUpisProcenta(e.target.value, 15);
              }}
            />
          </div>
          <div className={classes.prenosUunitmaRow}>
            {prenosUUnitima[15] === "0.00" ? "-" : prenosUUnitima[15]}
          </div>
          <div className={classes.trenutnoStanjeRow}>
            {trenutnoStanje === "0.00" ? "-" : trenutnoStanje}
          </div>
          <div
            className={classes.upisProcenataRow}
            style={{ display: "flex", flexDirection: "row", width: "260px" }}
          >
            <div
              style={{
                display: "flex",
                width: "100px",
                background: "orangered",
                fontWeight: "bolder",
                color: "white",
                justifyContent: "center",
              }}
            >
              KRUG 1-NE
            </div>
            <input
              style={{ width: "160px" }}
              type="number"
              min="0"
              onChange={(e) => {
                handleChangeUpisUlogaPromasaja(e.target.value, 15);
              }}
            />
          </div>
        </div>
        <div className={classes.row}>
          <div
            style={{
              display: "flex",
              width: "64px",
              background: "#712ea1",
              justifyContent: "center",
              color: "white",
              borderRight: "1px solid black",
            }}
          >
            17
          </div>
          <div className={classes.upisUlogFirstRow2}>{ulog[16]}</div>
          <div className={classes.upisKvotaRow}>
            <input
              type="number"
              min="0"
              onChange={(e) => {
                handleChangeKvota(e.target.value, 16);
              }}
            />
          </div>
          <div className={classes.eventualniDobitakRow}>
            {eventualniDobitak[16] === "0.00" ? "-" : eventualniDobitak[16]}
          </div>
          <div className={classes.upisProlazDaNeRow}>DA</div>

          <div className={classes.cistaDobitRow}>
            {cistaDobit[16] === 0 ? "-" : cistaDobit[16]}
          </div>

          <div className={classes.upisProcenataRow}>
            <input
              type="number"
              min="0"
              onChange={(e) => {
                handleChangeUpisProcenta(e.target.value, 16);
              }}
            />
          </div>
          <div className={classes.prenosUunitmaRow}>
            {prenosUUnitima[16] === "0.00" ? "-" : prenosUUnitima[16]}
          </div>
          <div className={classes.trenutnoStanjeRow}>
            {trenutnoStanje === "0.00" ? "-" : trenutnoStanje}
          </div>
          <div
            className={classes.upisProcenataRow}
            style={{ display: "flex", flexDirection: "row", width: "260px" }}
          >
            <div
              style={{
                display: "flex",
                width: "100px",
                background: "orangered",
                fontWeight: "bolder",
                color: "white",
                justifyContent: "center",
              }}
            >
              KRUG 1-NE
            </div>
            <input
              style={{ width: "160px" }}
              type="number"
              min="0"
              onChange={(e) => {
                handleChangeUpisUlogaPromasaja(e.target.value, 16);
              }}
            />
          </div>
        </div>
        <div className={classes.row}>
          <div
            style={{
              display: "flex",
              width: "64px",
              background: "#712ea1",
              justifyContent: "center",
              color: "white",
              borderRight: "1px solid black",
            }}
          >
            18
          </div>
          <div className={classes.upisUlogFirstRow2}>{ulog[17]}</div>
          <div className={classes.upisKvotaRow}>
            <input
              type="number"
              min="0"
              onChange={(e) => {
                handleChangeKvota(e.target.value, 17);
              }}
            />
          </div>
          <div className={classes.eventualniDobitakRow}>
            {eventualniDobitak[17] === "0.00" ? "-" : eventualniDobitak[17]}
          </div>
          <div className={classes.upisProlazDaNeRow}>DA</div>

          <div className={classes.cistaDobitRow}>
            {cistaDobit[17] === 0 ? "-" : cistaDobit[17]}
          </div>

          <div className={classes.upisProcenataRow}>
            <input
              type="number"
              min="0"
              onChange={(e) => {
                handleChangeUpisProcenta(e.target.value, 17);
              }}
            />
          </div>
          <div className={classes.prenosUunitmaRow}>
            {prenosUUnitima[17] === "0.00" ? "-" : prenosUUnitima[17]}
          </div>
          <div className={classes.trenutnoStanjeRow}>
            {trenutnoStanje === "0.00" ? "-" : trenutnoStanje}
          </div>
          <div
            className={classes.upisProcenataRow}
            style={{ display: "flex", flexDirection: "row", width: "260px" }}
          >
            <div
              style={{
                display: "flex",
                width: "100px",
                background: "orangered",
                fontWeight: "bolder",
                color: "white",
                justifyContent: "center",
              }}
            >
              KRUG 1-NE
            </div>
            <input
              style={{ width: "160px" }}
              type="number"
              min="0"
              onChange={(e) => {
                handleChangeUpisUlogaPromasaja(e.target.value, 17);
              }}
            />
          </div>
        </div>
        <div className={classes.row}>
          <div
            style={{
              display: "flex",
              width: "64px",
              background: "#712ea1",
              justifyContent: "center",
              color: "white",
              borderRight: "1px solid black",
            }}
          >
            19
          </div>
          <div className={classes.upisUlogFirstRow2}>{ulog[18]}</div>
          <div className={classes.upisKvotaRow}>
            <input
              type="number"
              min="0"
              onChange={(e) => {
                handleChangeKvota(e.target.value, 18);
              }}
            />
          </div>
          <div className={classes.eventualniDobitakRow}>
            {eventualniDobitak[18] === "0.00" ? "-" : eventualniDobitak[18]}
          </div>
          <div className={classes.upisProlazDaNeRow}>DA</div>

          <div className={classes.cistaDobitRow}>
            {cistaDobit[18] === 0 ? "-" : cistaDobit[18]}
          </div>

          <div className={classes.upisProcenataRow}>
            <input
              type="number"
              min="0"
              onChange={(e) => {
                handleChangeUpisProcenta(e.target.value, 18);
              }}
            />
          </div>
          <div className={classes.prenosUunitmaRow}>
            {prenosUUnitima[18] === "0.00" ? "-" : prenosUUnitima[18]}
          </div>
          <div className={classes.trenutnoStanjeRow}>
            {trenutnoStanje === "0.00" ? "-" : trenutnoStanje}
          </div>
          <div
            className={classes.upisProcenataRow}
            style={{ display: "flex", flexDirection: "row", width: "260px" }}
          >
            <div
              style={{
                display: "flex",
                width: "100px",
                background: "orangered",
                fontWeight: "bolder",
                color: "white",
                justifyContent: "center",
              }}
            >
              KRUG 1-NE
            </div>
            <input
              style={{ width: "160px" }}
              type="number"
              min="0"
              onChange={(e) => {
                handleChangeUpisUlogaPromasaja(e.target.value, 18);
              }}
            />
          </div>
        </div>
        <div className={classes.row}>
          <div
            style={{
              display: "flex",
              width: "64px",
              background: "#712ea1",
              justifyContent: "center",
              color: "white",
              borderRight: "1px solid black",
            }}
          >
            20
          </div>
          <div className={classes.upisUlogFirstRow2}>{ulog[19]}</div>
          <div className={classes.upisKvotaRow}>
            <input
              type="number"
              min="0"
              onChange={(e) => {
                handleChangeKvota(e.target.value, 19);
              }}
            />
          </div>
          <div className={classes.eventualniDobitakRow}>
            {eventualniDobitak[19] === "0.00" ? "-" : eventualniDobitak[19]}
          </div>
          <div className={classes.upisProlazDaNeRow}>DA</div>

          <div className={classes.cistaDobitRow}>
            {cistaDobit[19] === 0 ? "-" : cistaDobit[19]}
          </div>

          <div className={classes.upisProcenataRow}>
            <input
              type="number"
              min="0"
              onChange={(e) => {
                handleChangeUpisProcenta(e.target.value, 19);
              }}
            />
          </div>
          <div className={classes.prenosUunitmaRow}>
            {prenosUUnitima[19] === "0.00" ? "-" : prenosUUnitima[19]}
          </div>
          <div className={classes.trenutnoStanjeRow}>
            {trenutnoStanje === "0.00" ? "-" : trenutnoStanje}
          </div>
          <div
            className={classes.upisProcenataRow}
            style={{ display: "flex", flexDirection: "row", width: "260px" }}
          >
            <div
              style={{
                display: "flex",
                width: "100px",
                background: "orangered",
                fontWeight: "bolder",
                color: "white",
                justifyContent: "center",
              }}
            >
              KRUG 1-NE
            </div>
            <input
              style={{ width: "160px" }}
              type="number"
              min="0"
              onChange={(e) => {
                handleChangeUpisUlogaPromasaja(e.target.value, 19);
              }}
            />
          </div>
        </div>
        <div className={classes.row}>
          <div
            style={{
              display: "flex",
              width: "64px",
              background: "#712ea1",
              justifyContent: "center",
              color: "white",
              borderRight: "1px solid black",
            }}
          >
            21
          </div>
          <div className={classes.upisUlogFirstRow2}>{ulog[20]}</div>
          <div className={classes.upisKvotaRow}>
            <input
              type="number"
              min="0"
              onChange={(e) => {
                handleChangeKvota(e.target.value, 20);
              }}
            />
          </div>
          <div className={classes.eventualniDobitakRow}>
            {eventualniDobitak[20] === "0.00" ? "-" : eventualniDobitak[20]}
          </div>
          <div className={classes.upisProlazDaNeRow}>DA</div>

          <div className={classes.cistaDobitRow}>
            {cistaDobit[20] === 0 ? "-" : cistaDobit[20]}
          </div>

          <div className={classes.upisProcenataRow}>
            <input
              type="number"
              min="0"
              onChange={(e) => {
                handleChangeUpisProcenta(e.target.value, 20);
              }}
            />
          </div>
          <div className={classes.prenosUunitmaRow}>
            {prenosUUnitima[20] === "0.00" ? "-" : prenosUUnitima[20]}
          </div>
          <div className={classes.trenutnoStanjeRow}>
            {trenutnoStanje === "0.00" ? "-" : trenutnoStanje}
          </div>
          <div
            className={classes.upisProcenataRow}
            style={{ display: "flex", flexDirection: "row", width: "260px" }}
          >
            <div
              style={{
                display: "flex",
                width: "100px",
                background: "orangered",
                fontWeight: "bolder",
                color: "white",
                justifyContent: "center",
              }}
            >
              KRUG 1-NE
            </div>
            <input
              style={{ width: "160px" }}
              type="number"
              min="0"
              onChange={(e) => {
                handleChangeUpisUlogaPromasaja(e.target.value, 20);
              }}
            />
          </div>
        </div>
        <div className={classes.row}>
          <div
            style={{
              display: "flex",
              width: "64px",
              background: "#712ea1",
              justifyContent: "center",
              color: "white",
              borderRight: "1px solid black",
            }}
          >
            22
          </div>
          <div className={classes.upisUlogFirstRow2}>{ulog[21]}</div>
          <div className={classes.upisKvotaRow}>
            <input
              type="number"
              min="0"
              onChange={(e) => {
                handleChangeKvota(e.target.value, 21);
              }}
            />
          </div>
          <div className={classes.eventualniDobitakRow}>
            {eventualniDobitak[21] === "0.00" ? "-" : eventualniDobitak[21]}
          </div>
          <div className={classes.upisProlazDaNeRow}>DA</div>

          <div className={classes.cistaDobitRow}>
            {cistaDobit[21] === 0 ? "-" : cistaDobit[21]}
          </div>

          <div className={classes.upisProcenataRow}>
            <input
              type="number"
              min="0"
              onChange={(e) => {
                handleChangeUpisProcenta(e.target.value, 21);
              }}
            />
          </div>
          <div className={classes.prenosUunitmaRow}>
            {prenosUUnitima[21] === "0.00" ? "-" : prenosUUnitima[21]}
          </div>
          <div className={classes.trenutnoStanjeRow}>
            {trenutnoStanje === "0.00" ? "-" : trenutnoStanje}
          </div>
          <div
            className={classes.upisProcenataRow}
            style={{ display: "flex", flexDirection: "row", width: "260px" }}
          >
            <div
              style={{
                display: "flex",
                width: "100px",
                background: "orangered",
                fontWeight: "bolder",
                color: "white",
                justifyContent: "center",
              }}
            >
              KRUG 1-NE
            </div>
            <input
              style={{ width: "160px" }}
              type="number"
              min="0"
              onChange={(e) => {
                handleChangeUpisUlogaPromasaja(e.target.value, 21);
              }}
            />
          </div>
        </div>
        <div className={classes.row}>
          <div
            style={{
              display: "flex",
              width: "64px",
              background: "#712ea1",
              justifyContent: "center",
              color: "white",
              borderRight: "1px solid black",
            }}
          >
            23
          </div>
          <div className={classes.upisUlogFirstRow2}>{ulog[22]}</div>
          <div className={classes.upisKvotaRow}>
            <input
              type="number"
              min="0"
              onChange={(e) => {
                handleChangeKvota(e.target.value, 22);
              }}
            />
          </div>
          <div className={classes.eventualniDobitakRow}>
            {eventualniDobitak[22] === "0.00" ? "-" : eventualniDobitak[22]}
          </div>
          <div className={classes.upisProlazDaNeRow}>DA</div>

          <div className={classes.cistaDobitRow}>
            {cistaDobit[22] === 0 ? "-" : cistaDobit[22]}
          </div>

          <div className={classes.upisProcenataRow}>
            <input
              type="number"
              min="0"
              onChange={(e) => {
                handleChangeUpisProcenta(e.target.value, 22);
              }}
            />
          </div>
          <div className={classes.prenosUunitmaRow}>
            {prenosUUnitima[22] === "0.00" ? "-" : prenosUUnitima[22]}
          </div>
          <div className={classes.trenutnoStanjeRow}>
            {trenutnoStanje === "0.00" ? "-" : trenutnoStanje}
          </div>
          <div
            className={classes.upisProcenataRow}
            style={{ display: "flex", flexDirection: "row", width: "260px" }}
          >
            <div
              style={{
                display: "flex",
                width: "100px",
                background: "orangered",
                fontWeight: "bolder",
                color: "white",
                justifyContent: "center",
              }}
            >
              KRUG 1-NE
            </div>
            <input
              style={{ width: "160px" }}
              type="number"
              min="0"
              onChange={(e) => {
                handleChangeUpisUlogaPromasaja(e.target.value, 22);
              }}
            />
          </div>
        </div>
        <div className={classes.row}>
          <div
            style={{
              display: "flex",
              width: "64px",
              background: "#712ea1",
              justifyContent: "center",
              color: "white",
              borderRight: "1px solid black",
            }}
          >
            24
          </div>
          <div className={classes.upisUlogFirstRow2}>{ulog[23]}</div>
          <div className={classes.upisKvotaRow}>
            <input
              type="number"
              min="0"
              onChange={(e) => {
                handleChangeKvota(e.target.value, 23);
              }}
            />
          </div>
          <div className={classes.eventualniDobitakRow}>
            {eventualniDobitak[23] === "0.00" ? "-" : eventualniDobitak[23]}
          </div>
          <div className={classes.upisProlazDaNeRow}>DA</div>

          <div className={classes.cistaDobitRow}>
            {cistaDobit[23] === 0 ? "-" : cistaDobit[23]}
          </div>

          <div className={classes.upisProcenataRow}>
            <input
              type="number"
              min="0"
              onChange={(e) => {
                handleChangeUpisProcenta(e.target.value, 23);
              }}
            />
          </div>
          <div className={classes.prenosUunitmaRow}>
            {prenosUUnitima[23] === "0.00" ? "-" : prenosUUnitima[23]}
          </div>
          <div className={classes.trenutnoStanjeRow}>
            {trenutnoStanje === "0.00" ? "-" : trenutnoStanje}
          </div>
          <div
            className={classes.upisProcenataRow}
            style={{ display: "flex", flexDirection: "row", width: "260px" }}
          >
            <div
              style={{
                display: "flex",
                width: "100px",
                background: "orangered",
                fontWeight: "bolder",
                color: "white",
                justifyContent: "center",
              }}
            >
              KRUG 1-NE
            </div>
            <input
              style={{ width: "160px" }}
              type="number"
              min="0"
              onChange={(e) => {
                handleChangeUpisUlogaPromasaja(e.target.value, 23);
              }}
            />
          </div>
        </div>
        <div className={classes.row}>
          <div
            style={{
              display: "flex",
              width: "64px",
              background: "#712ea1",
              justifyContent: "center",
              color: "white",
              borderRight: "1px solid black",
            }}
          >
            25
          </div>
          <div className={classes.upisUlogFirstRow2}>{ulog[24]}</div>
          <div className={classes.upisKvotaRow}>
            <input
              type="number"
              min="0"
              onChange={(e) => {
                handleChangeKvota(e.target.value, 24);
              }}
            />
          </div>
          <div className={classes.eventualniDobitakRow}>
            {eventualniDobitak[24] === "0.00" ? "-" : eventualniDobitak[24]}
          </div>
          <div className={classes.upisProlazDaNeRow}>DA</div>

          <div className={classes.cistaDobitRow}>
            {cistaDobit[24] === 0 ? "-" : cistaDobit[24]}
          </div>

          <div className={classes.upisProcenataRow}>
            <input
              type="number"
              min="0"
              onChange={(e) => {
                handleChangeUpisProcenta(e.target.value, 24);
              }}
            />
          </div>
          <div className={classes.prenosUunitmaRow}>
            {prenosUUnitima[24] === "0.00" ? "-" : prenosUUnitima[24]}
          </div>
          <div className={classes.trenutnoStanjeRow}>
            {trenutnoStanje === "0.00" ? "-" : trenutnoStanje}
          </div>
          <div
            className={classes.upisProcenataRow}
            style={{ display: "flex", flexDirection: "row", width: "260px" }}
          >
            <div
              style={{
                display: "flex",
                width: "100px",
                background: "orangered",
                fontWeight: "bolder",
                color: "white",
                justifyContent: "center",
              }}
            >
              KRUG 1-NE
            </div>
            <input
              style={{ width: "160px" }}
              type="number"
              min="0"
              onChange={(e) => {
                handleChangeUpisUlogaPromasaja(e.target.value, 24);
              }}
            />
          </div>
        </div>

        <div className={classes.bottom}>
          <div className={classes.prvi} style={{ width: "616px" }}>
            UKUPAN DOBITAK / GUBITAK
          </div>
          <div className={classes.drugi} style={{ width: "368px" }}>
            {isNaN(ukupanDobitakGubitak[0] - ukupanDobitakGubitak[2])
              ? ""
              : ukupanDobitakGubitak[0] - ukupanDobitakGubitak[2]}
          </div>
          <div className={classes.treci} style={{ width: "178px" }}>
            {isNaN(ukupanDobitakGubitak[1]) ? "" : ukupanDobitakGubitak[1]}
          </div>
          <div className={classes.cetvrti} style={{ width: "260px" }}>
            {isNaN(ukupanDobitakGubitak[2]) ? "" : ukupanDobitakGubitak[2]}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PozitivnaProgresija;
