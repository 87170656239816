import Grupa3 from "../components/Grupa3";
import Grupa20 from "../components/Grupa20";
import classes from "./odbranaTiketa.module.css";
import { useState } from "react";
const OdbranaTiketa = () => {
  const [upisKvota, setUpisKvota] = useState(["", ""]);
  const [upisUloga, setUpisUloga] = useState(["", ""]);
  const [dobitakBezBonusa, setDobitakBezBonusa] = useState(["", ""]);
  const [ukDobitak, setUkDobitak] = useState(["", ""]);

  dobitakBezBonusa[0] = upisKvota[0] * upisUloga[0];
  ukDobitak[0] =
    parseFloat(dobitakBezBonusa[0]) -
    (parseFloat(upisUloga[0]) + parseFloat(upisUloga[1]));
  dobitakBezBonusa[1] = upisKvota[1] * upisUloga[1];
  ukDobitak[1] =
    dobitakBezBonusa[1] - (parseFloat(upisUloga[0]) + parseFloat(upisUloga[1]));

  const handleChangeUpisKvota = (e, index) => {
    let arr = [...upisKvota];
    arr[index] = e.target.value;
    setUpisKvota(arr);
  };

  const handleChangeUpisUloga = (e, index) => {
    let arr = [...upisUloga];
    arr[index] = e.target.value;
    setUpisUloga(arr);
  };
  return (
    <div className={classes.container}>
      <Grupa3
        imgText="ODBRANA TIKETA"
        imgBackgroundColorDark="#0898DE"
        imgBackgroundColorLight="#00B0F0"
        imgFontColor="#FFFFFF"
      />
      <Grupa20
        imgBackgroundColorDark="#0898DE"
        imgBackgroundColorLight="#00B0F0"
        imgFontColor="#FFFFFF"
        calculatorType={`Odbrana tiketa`}
      />
      <div>
        <input
          style={{
            height: "30px",
            width: "300px",
            fontWeight: "bolder",
            textAlign: "center",
            margin: "5px 10px 5px 0",
            borderRadius: "5px",
            border: "3px solid black",
          }}
          onChange={(e) => handleChangeUpisKvota(e, 0)}
          value={upisKvota[0]}
          placeholder="upis kvota - osnovni"
        />
        <input
          style={{
            height: "30px",
            width: "300px",
            fontWeight: "bolder",
            textAlign: "center",
            margin: "5px 10px 5px 0",
            borderRadius: "5px",
            border: "3px solid black",
          }}
          onChange={(e) => handleChangeUpisUloga(e, 0)}
          value={upisUloga[0]}
          placeholder="upis uloga - osnovni"
        />
        <input
          style={{
            height: "30px",
            width: "300px",
            fontWeight: "bolder",
            textAlign: "center",
            margin: "5px 10px 5px 0",
            borderRadius: "5px",
            border: "3px solid black",
          }}
          onChange={(e) => handleChangeUpisKvota(e, 1)}
          value={upisKvota[1]}
          placeholder="upis kvota - odbrana"
        />
        <input
          style={{
            height: "30px",
            width: "300px",
            fontWeight: "bolder",
            textAlign: "center",
            marginRight: "10px",
            borderRadius: "5px",
            border: "3px solid black",
          }}
          onChange={(e) => handleChangeUpisUloga(e, 1)}
          value={upisUloga[1]}
          placeholder="upis uloga - odbrana"
        />
      </div>
      <div className={classes.calculator}>
        <p className={classes.treci}>
          {isNaN(upisKvota[0]) ? "" : upisKvota[0]}
        </p>
        <p className={classes.cetvrti}>
          {isNaN(upisKvota[1]) ? "" : upisKvota[1]}
        </p>
        <p className={classes.peti}>
          {isNaN(upisUloga[0]) ? "" : upisUloga[0]}
        </p>
        <p className={classes.sesti}>
          {isNaN(upisUloga[1]) ? "" : upisUloga[1]}
        </p>
        <p className={classes.sedmi}>
          {isNaN(dobitakBezBonusa[0]) ? "" : dobitakBezBonusa[0]}
        </p>
        <p className={classes.osmi}>{isNaN(ukDobitak[0]) ? 0 : ukDobitak[0]}</p>
        <p className={classes.deveti}>
          {isNaN(dobitakBezBonusa[1]) ? "" : dobitakBezBonusa[1]}
        </p>
        <p className={classes.deseti}>
          {isNaN(ukDobitak[1]) ? 0 : ukDobitak[1]}
        </p>
      </div>
    </div>
  );
};

export default OdbranaTiketa;
