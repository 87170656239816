import Grupa2 from "../components/Grupa2";
import Grupa20 from "../components/Grupa20";
import poredjenjeGolmanaDoljnjaSlika from "../images/poredjenjeGolmanaDoljnjaSlika.png";
import classes from "./poredenjeGolmanaFudbal.module.css";
import { useState, useEffect } from "react";

const PoredenjeGolmanaFudbal = () => {
  ////total fields/////
  const [brojUtakmicaDomacin, setBrojUtakmicaDomacin] = useState(0);
  const [brojUtakmicaGost, setBrojUtakmicaGost] = useState(0);
  const [data, setData] = useState({
    poceo: 0,
    minutaPoUtakmici: 0,
    timKola: 0,
    odbranjeniPenali: 0,
    odbranaPoUtakmici: 0,
    uspesnaIstrcavanjaPoUtakmici: 0,
    primljeniGolovi: 0,
    primljeniGoloviUnutar16: 0,
    primljeniGoloviIzvan16: 0,
    odbraneUdarcaUnutar16: 0,
    odbraneUdarcaIzvan16: 0,
    odbraneUhvaceneLopte: 0,
    odbraneOdbijeneLopte: 0,
    dodavanja: 0,
    dodiriSaLoptom: 0,
    utakmiceBezPrimljenogGola: 0,
    preciznaDodavanjaNaSvojojPolovini: 0,
    precizneDugeLopte: 0,
    preciznihPasova: 0,
    precizniCentrasutevi: 0,
    utakmiceBezPrimljenogGola: 0,
    preciznaDodavanjaNaSvojojPolovini: 0,
    precizneDugeLopte: 0,
    precizniCentrasutevi: 0,
    skrivljeniSlobodniUdarci: 0,
    skrivljeniGolovi: 0,
    skrivljeniPenali: 0,
    osvojeniZracniDueli: 0,
    zutiKartoni: 0,
    drugiZutiKartoni: 0,
    crveniKartoni: 0,
    ///////////////
    poceo2: 0,
    minutaPoUtakmici2: 0,
    timKola2: 0,
    odbranjeniPenali2: 0,
    odbranaPoUtakmici2: 0,
    uspešnaIstrcavanjaPoUtakmici: 0,
    primljeniGolovi2: 0,
    primljeniGoloviUnutar162: 0,
    primljeniGoloviIzvan162: 0,
    odbraneUdarcaUnutar162: 0,
    odbraneUdaracaIzvan162: 0,
    odbraneUhvaceneLopte2: 0,
    odbraneOdbijeneLopte2: 0,
    dodavanja2: 0,
    dodiriSaLoptom2: 0,
    utakmiceBezPrimljenogGola2: 0,
    preciznaDodavanjaNaSvojojPolovini2: 0,
    precizneDugeLopte2: 0,
    preciznihPasova2: 0,
    precizniCentrasutevi2: 0,
    utakmiceBezPrimljenogGola2: 0,
    preciznaDodavanjaNaSvojojPolovini2: 0,
    precizneDugeLopte2: 0,
    precizniCentrasutevi2: 0,
    skrivljeniSlobodniUdarci2: 0,
    skrivljeniGolovi2: 0,
    skrivljeniPenali2: 0,
    osvojeniZracniDueli2: 0,
    zutiKartoni2: 0,
    drugiZutiKartoni2: 0,
    crveniKartoni2: 0,
  });

  const [calculatedData, setCalculatedData] = useState({
    poceo: 0,
    minutaPoUtakmici: 0,
    timKola: 0,
    odbranjeniPenali: 0,
    odbranaPoUtakmici: 0,
    uspesnaIstrcavanjaPoUtakmici: 0,
    primljeniGolovi: 0,
    primljeniGoloviUnutar16: 0,
    primljeniGoloviIzvan16: 0,
    odbraneUdarcaUnutar16: 0,
    odbraneUdarcaIzvan16: 0,
    odbraneUhvaceneLopte: 0,
    odbraneOdbijeneLopte: 0,
    dodavanja: 0,
    dodiriSaLoptom: 0,
    utakmiceBezPrimljenogGola: 0,
    preciznaDodavanjaNaSvojojPolovini: 0,
    precizneDugeLopte: 0,
    preciznihPasova: 0,
    precizniCentrasutevi: 0,
    utakmiceBezPrimljenogGola: 0,
    preciznaDodavanjaNaSvojojPolovini: 0,
    precizneDugeLopte: 0,
    precizniCentrasutevi: 0,
    skrivljeniSlobodniUdarci: 0,
    skrivljeniGolovi: 0,
    skrivljeniPenali: 0,
    osvojeniZracniDueli: 0,
    zutiKartoni: 0,
    drugiZutiKartoni: 0,
    crveniKartoni: 0,
    ///////////////
    poceo2: 0,
    minutaPoUtakmici2: 0,
    timKola2: 0,
    odbranjeniPenali2: 0,
    odbranaPoUtakmici2: 0,
    uspešnaIstrcavanjaPoUtakmici: 0,
    primljeniGolovi2: 0,
    primljeniGoloviUnutar162: 0,
    primljeniGoloviIzvan162: 0,
    odbraneUdarcaUnutar162: 0,
    odbraneUdaracaIzvan162: 0,
    odbraneUhvaceneLopte2: 0,
    odbraneOdbijeneLopte2: 0,
    dodavanja2: 0,
    dodiriSaLoptom2: 0,
    utakmiceBezPrimljenogGola2: 0,
    preciznaDodavanjaNaSvojojPolovini2: 0,
    precizneDugeLopte2: 0,
    preciznihPasova2: 0,
    precizniCentrasutevi2: 0,
    utakmiceBezPrimljenogGola2: 0,
    preciznaDodavanjaNaSvojojPolovini2: 0,
    precizneDugeLopte2: 0,
    precizniCentrasutevi2: 0,
    skrivljeniSlobodniUdarci2: 0,
    skrivljeniGolovi2: 0,
    skrivljeniPenali2: 0,
    osvojeniZracniDueli2: 0,
    zutiKartoni2: 0,
    drugiZutiKartoni2: 0,
    crveniKartoni2: 0,
  });

  useEffect(() => {
    handleCalculate();
  }, [data, brojUtakmicaDomacin, brojUtakmicaGost]);

  const handleCalculate = () => {
    let obj = {
      poceo: 0,
      minutaPoUtakmici: 0,
      timKola: 0,
      odbranjeniPenali: 0,
      odbranaPoUtakmici: 0,
      uspesnaIstrcavanjaPoUtakmici: 0,
      primljeniGolovi: 0,
      primljeniGoloviUnutar16: 0,
      primljeniGoloviIzvan16: 0,
      odbraneUdarcaUnutar16: 0,
      odbraneUdarcaIzvan16: 0,
      odbraneUhvaceneLopte: 0,
      odbraneOdbijeneLopte: 0,
      dodavanja: 0,
      dodiriSaLoptom: 0,
      utakmiceBezPrimljenogGola: 0,
      preciznaDodavanjaNaSvojojPolovini: 0,
      precizneDugeLopte: 0,
      preciznihPasova: 0,
      precizniCentrasutevi: 0,
      utakmiceBezPrimljenogGola: 0,
      preciznaDodavanjaNaSvojojPolovini: 0,
      precizneDugeLopte: 0,
      precizniCentrasutevi: 0,
      skrivljeniSlobodniUdarci: 0,
      skrivljeniGolovi: 0,
      skrivljeniPenali: 0,
      osvojeniZracniDueli: 0,
      zutiKartoni: 0,
      drugiZutiKartoni: 0,
      crveniKartoni: 0,
      ///////////////
      poceo2: 0,
      minutaPoUtakmici2: 0,
      timKola2: 0,
      odbranjeniPenali2: 0,
      odbranaPoUtakmici2: 0,
      uspešnaIstrcavanjaPoUtakmici: 0,
      primljeniGolovi2: 0,
      primljeniGoloviUnutar162: 0,
      primljeniGoloviIzvan162: 0,
      odbraneUdarcaUnutar162: 0,
      odbraneUdaracaIzvan162: 0,
      odbraneUhvaceneLopte2: 0,
      odbraneOdbijeneLopte2: 0,
      dodavanja2: 0,
      dodiriSaLoptom2: 0,
      utakmiceBezPrimljenogGola2: 0,
      preciznaDodavanjaNaSvojojPolovini2: 0,
      precizneDugeLopte2: 0,
      preciznihPasova2: 0,
      precizniCentrasutevi2: 0,
      utakmiceBezPrimljenogGola2: 0,
      preciznaDodavanjaNaSvojojPolovini2: 0,
      precizneDugeLopte2: 0,
      precizniCentrasutevi2: 0,
      skrivljeniSlobodniUdarci2: 0,
      skrivljeniGolovi2: 0,
      skrivljeniPenali2: 0,
      osvojeniZracniDueli2: 0,
      zutiKartoni2: 0,
      drugiZutiKartoni2: 0,
      crveniKartoni2: 0,
    };
    if (brojUtakmicaDomacin) {
      const keys = Object.keys(data);

      for (let key of keys) {
        if (data && data[key] && !key.includes(2)) {
          if (key === "minutaPoUtakmici") {
            obj = {
              ...obj,
              [key]: parseFloat(data[key] / 90).toFixed(2),
            };
          } else {
            obj = {
              ...obj,
              [key]: parseFloat(
                (data[key] / brojUtakmicaDomacin) * 100
              ).toFixed(2),
            };
          }
        }
      }
      setCalculatedData(obj);
    }

    if (brojUtakmicaGost) {
      const keys = Object.keys(data);

      for (let key of keys) {
        if (data && data[key] && key.includes(2)) {
          if (key === "minutaPoUtakmici2") {
            obj = {
              ...obj,
              [key]: parseFloat(data[key] / 90).toFixed(2),
            };
          } else {
            obj = {
              ...obj,
              [key]: parseFloat((data[key] / brojUtakmicaGost) * 100).toFixed(
                2
              ),
            };
          }
        }
      }
      setCalculatedData(obj);
    }
  };

  return (
    <div className={classes.container}>
      <Grupa2
        imgText="POREĐENJE GOLMANA - FUDBAL"
        imgBackgroundColorDark="#C8D020"
        imgBackgroundColorLight="#D5E420"
        imgFontColor="#1339F9"
        label1="ZEMLJA"
        label2="KLUB"
        label3="GOLMAN 1"
        label4="KLUB"
        label5="ZEMLJA"
        label6="GOLMAN 2"
      />
      <Grupa20
        imgBackgroundColorDark="#C8D020"
        imgBackgroundColorLight="#D5E420"
        imgFontColor="#1339F9"
        calculatorType={`Poredjenje golmana - fudbal`}
      />
      <img src={poredjenjeGolmanaDoljnjaSlika} />
      <div className={classes.calculator}>
        <div className={classes.row}>
          <div className={classes.col1}></div>
          <div className={classes.col2}>
            <input
              style={{ fontWeight: "bold", fontSize: "20px" }}
              type="number"
              min="0"
              onChange={(e) => {
                setBrojUtakmicaDomacin(e.target.value);
              }}
            />
          </div>
          <div className={classes.col3}>UTAKMICE</div>
          <div className={classes.col4}>
            <input
              style={{ fontWeight: "bold", fontSize: "20px" }}
              onChange={(e) => {
                setBrojUtakmicaGost(e.target.value);
              }}
              type="number"
              min="0"
            />
          </div>
          <div className={classes.col5}></div>
        </div>
        <div className={classes.row}>
          <div className={classes.col1}>{calculatedData.poceo} %</div>
          <div className={classes.col2}>
            <input
              style={{ fontWeight: "bold", fontSize: "20px" }}
              onChange={(e) => {
                setData({ ...data, poceo: e.target.value });
              }}
              type="number"
              min="0"
            />
          </div>
          <div className={classes.col3}>Počeo</div>
          <div className={classes.col4}>
            <input
              style={{ fontWeight: "bold", fontSize: "20px" }}
              onChange={(e) => {
                setData({ ...data, poceo2: e.target.value });
              }}
              type="number"
              min="0"
            />
          </div>
          <div className={classes.col5}>{calculatedData.poceo2} %</div>
        </div>
        <div className={classes.row}>
          <div className={classes.col1}>
            {(calculatedData.minutaPoUtakmici / 90).toFixed(2)} %
          </div>
          <div className={classes.col2}>
            <input
              style={{ fontWeight: "bold", fontSize: "20px" }}
              onChange={(e) => {
                setData({ ...data, minutaPoUtakmici: e.target.value });
              }}
              type="number"
              min="0"
            />
          </div>
          <div className={classes.col3}>Minuta po utakmici</div>
          <div className={classes.col4}>
            <input
              style={{ fontWeight: "bold", fontSize: "20px" }}
              onChange={(e) => {
                setData({ ...data, minutaPoUtakmici2: e.target.value });
              }}
              type="number"
              min="0"
            />
          </div>
          <div className={classes.col5}>
            {(calculatedData.minutaPoUtakmici2 / 90).toFixed(2)} %
          </div>
        </div>
        <div className={classes.row}>
          <div className={classes.col1}>{calculatedData.timKola} %</div>
          <div className={classes.col2}>
            <input
              style={{ fontWeight: "bold", fontSize: "20px" }}
              onChange={(e) => {
                setData({ ...data, timKola: e.target.value });
              }}
              type="number"
              min="0"
            />
          </div>
          <div className={classes.col3}>Tim Kola</div>
          <div className={classes.col4}>
            <input
              style={{ fontWeight: "bold", fontSize: "20px" }}
              onChange={(e) => {
                setData({ ...data, timKola2: e.target.value });
              }}
              type="number"
              min="0"
            />
          </div>
          <div className={classes.col5}>{calculatedData.timKola2} %</div>
        </div>
        <div className={classes.row}>
          <div className={classes.col1}>
            {calculatedData.odbranjeniPenali} %
          </div>
          <div className={classes.col2}>
            <input
              style={{ fontWeight: "bold", fontSize: "20px" }}
              onChange={(e) => {
                setData({ ...data, odbranjeniPenali: e.target.value });
              }}
              type="number"
              min="0"
            />
          </div>
          <div className={classes.col3}>Odbranjeni penali</div>
          <div className={classes.col4}>
            <input
              style={{ fontWeight: "bold", fontSize: "20px" }}
              onChange={(e) => {
                setData({ ...data, odbranjeniPenali2: e.target.value });
              }}
              type="number"
              min="0"
            />
          </div>
          <div className={classes.col5}>
            {calculatedData.odbranjeniPenali2} %
          </div>
        </div>
        <div className={classes.row}>
          <div className={classes.col1}>
            {calculatedData.odbranaPoUtakmici} %
          </div>
          <div className={classes.col2}>
            <input
              style={{ fontWeight: "bold", fontSize: "20px" }}
              onChange={(e) => {
                setData({ ...data, odbranaPoUtakmici: e.target.value });
              }}
              type="number"
              min="0"
            />
          </div>
          <div className={classes.col3}>Odbrana po utakmici</div>
          <div className={classes.col4}>
            <input
              style={{ fontWeight: "bold", fontSize: "20px" }}
              onChange={(e) => {
                setData({ ...data, odbranaPoUtakmici2: e.target.value });
              }}
              type="number"
              min="0"
            />
          </div>
          <div className={classes.col5}>
            {calculatedData.odbranaPoUtakmici2} %
          </div>
        </div>
        <div className={classes.row}>
          <div className={classes.col1}>
            {calculatedData.uspesnaIstrcavanjaPoUtakmici} %
          </div>
          <div className={classes.col2}>
            <input
              style={{ fontWeight: "bold", fontSize: "20px" }}
              onChange={(e) => {
                setData({
                  ...data,
                  uspesnaIstrcavanjaPoUtakmici: e.target.value,
                });
              }}
              type="number"
              min="0"
            />
          </div>
          <div className={classes.col3}>Uspešna istrčavanja po utakmici</div>
          <div className={classes.col4}>
            <input
              style={{ fontWeight: "bold", fontSize: "20px" }}
              onChange={(e) => {
                setData({
                  ...data,
                  uspešnaIstrcavanjaPoUtakmici: e.target.value,
                });
              }}
              type="number"
              min="0"
            />
          </div>
          <div className={classes.col5}>
            {calculatedData.uspešnaIstrcavanjaPoUtakmici} %
          </div>
        </div>
        <div className={classes.row}>
          <div className={classes.col1}>{calculatedData.primljeniGolovi} %</div>
          <div className={classes.col2}>
            <input
              style={{ fontWeight: "bold", fontSize: "20px" }}
              onChange={(e) => {
                setData({ ...data, primljeniGolovi: e.target.value });
              }}
              type="number"
              min="0"
            />
          </div>
          <div className={classes.col3}>Primljeni golovi</div>
          <div className={classes.col4}>
            <input
              style={{ fontWeight: "bold", fontSize: "20px" }}
              onChange={(e) => {
                setData({ ...data, primljeniGolovi2: e.target.value });
              }}
              type="number"
              min="0"
            />
          </div>
          <div className={classes.col5}>
            {calculatedData.primljeniGolovi2} %
          </div>
        </div>
        <div className={classes.row}>
          <div className={classes.col1}>
            {calculatedData.primljeniGoloviUnutar16} %
          </div>
          <div className={classes.col2}>
            <input
              style={{ fontWeight: "bold", fontSize: "20px" }}
              onChange={(e) => {
                setData({
                  ...data,
                  primljeniGoloviUnutar16: e.target.value,
                });
              }}
              type="number"
              min="0"
            />
          </div>
          <div className={classes.col3}>Primljeni golovi unutar 16m</div>
          <div className={classes.col4}>
            <input
              style={{ fontWeight: "bold", fontSize: "20px" }}
              onChange={(e) => {
                setData({
                  ...data,
                  primljeniGoloviUnutar162: e.target.value,
                });
              }}
              type="number"
              min="0"
            />
          </div>
          <div className={classes.col5}>
            {calculatedData.primljeniGoloviUnutar162} %
          </div>
        </div>
        <div className={classes.row}>
          <div className={classes.col1}>
            {calculatedData.primljeniGoloviIzvan16} %
          </div>
          <div className={classes.col2}>
            <input
              style={{ fontWeight: "bold", fontSize: "20px" }}
              onChange={(e) => {
                setData({ ...data, primljeniGoloviIzvan16: e.target.value });
              }}
              type="number"
              min="0"
            />
          </div>
          <div className={classes.col3}>Primljeni golovi izvan 16</div>
          <div className={classes.col4}>
            <input
              style={{ fontWeight: "bold", fontSize: "20px" }}
              onChange={(e) => {
                setData({ ...data, primljeniGoloviIzvan162: e.target.value });
              }}
              type="number"
              min="0"
            />
          </div>
          <div className={classes.col5}>
            {calculatedData.primljeniGoloviIzvan162} %
          </div>
        </div>
        <div className={classes.row}>
          <div className={classes.col1}>
            {calculatedData.odbraneUdarcaUnutar16} %
          </div>
          <div className={classes.col2}>
            <input
              style={{ fontWeight: "bold", fontSize: "20px" }}
              onChange={(e) => {
                setData({ ...data, odbraneUdarcaUnutar16: e.target.value });
              }}
              type="number"
              min="0"
            />
          </div>
          <div className={classes.col3}>Odbrane udaraca unutar 16 m</div>
          <div className={classes.col4}>
            <input
              style={{ fontWeight: "bold", fontSize: "20px" }}
              onChange={(e) => {
                setData({ ...data, odbraneUdarcaUnutar162: e.target.value });
              }}
              type="number"
              min="0"
            />
          </div>
          <div className={classes.col5}>
            {calculatedData.odbraneUdarcaUnutar162} %
          </div>
        </div>
        <div className={classes.row}>
          <div className={classes.col1}>
            {calculatedData.odbraneUdarcaIzvan16} %
          </div>
          <div className={classes.col2}>
            <input
              style={{ fontWeight: "bold", fontSize: "20px" }}
              onChange={(e) => {
                setData({ ...data, odbraneUdarcaIzvan16: e.target.value });
              }}
              type="number"
              min="0"
            />
          </div>
          <div className={classes.col3}>Odbrane udaraca izvan 16 m</div>
          <div className={classes.col4}>
            <input
              style={{ fontWeight: "bold", fontSize: "20px" }}
              onChange={(e) => {
                setData({ ...data, odbraneUdaracaIzvan162: e.target.value });
              }}
              type="number"
              min="0"
            />
          </div>
          <div className={classes.col5}>
            {calculatedData.odbraneUdaracaIzvan162} %
          </div>
        </div>
        <div className={classes.row}>
          <div className={classes.col1}>
            {calculatedData.odbraneUhvaceneLopte} %
          </div>
          <div className={classes.col2}>
            <input
              style={{ fontWeight: "bold", fontSize: "20px" }}
              onChange={(e) => {
                setData({ ...data, odbraneUhvaceneLopte: e.target.value });
              }}
              type="number"
              min="0"
            />
          </div>
          <div className={classes.col3}>Odbrane (uhvacene lopte)</div>
          <div className={classes.col4}>
            <input
              style={{ fontWeight: "bold", fontSize: "20px" }}
              onChange={(e) => {
                setData({ ...data, odbraneUhvaceneLopte2: e.target.value });
              }}
              type="number"
              min="0"
            />
          </div>
          <div className={classes.col5}>
            {calculatedData.odbraneUhvaceneLopte2} %
          </div>
        </div>
        <div className={classes.row}>
          <div className={classes.col1}>
            {calculatedData.odbraneOdbijeneLopte} %
          </div>
          <div className={classes.col2}>
            <input
              style={{ fontWeight: "bold", fontSize: "20px" }}
              onChange={(e) => {
                setData({ ...data, odbraneOdbijeneLopte: e.target.value });
              }}
              type="number"
              min="0"
            />
          </div>
          <div className={classes.col3}>Odbrane (odbijene lopte)</div>
          <div className={classes.col4}>
            <input
              style={{ fontWeight: "bold", fontSize: "20px" }}
              onChange={(e) => {
                setData({ ...data, odbraneOdbijeneLopte2: e.target.value });
              }}
              type="number"
              min="0"
            />
          </div>
          <div className={classes.col5}>
            {calculatedData.odbraneOdbijeneLopte2} %
          </div>
        </div>
        <div className={classes.row}>
          <div className={classes.col1}>{calculatedData.dodavanja} %</div>
          <div className={classes.col2}>
            <input
              style={{ fontWeight: "bold", fontSize: "20px" }}
              onChange={(e) => {
                setData({ ...data, dodavanja: e.target.value });
              }}
              type="number"
              min="0"
            />
          </div>
          <div className={classes.col3}>Dodavanja</div>
          <div className={classes.col4}>
            <input
              style={{ fontWeight: "bold", fontSize: "20px" }}
              onChange={(e) => {
                setData({ ...data, dodavanja2: e.target.value });
              }}
              type="number"
              min="0"
            />
          </div>
          <div className={classes.col5}>{calculatedData.dodavanja2} %</div>
        </div>
        <div className={classes.row}>
          <div className={classes.col1}>{calculatedData.dodiriSaLoptom} %</div>
          <div className={classes.col2}>
            <input
              style={{ fontWeight: "bold", fontSize: "20px" }}
              onChange={(e) => {
                setData({ ...data, dodiriSaLoptom: e.target.value });
              }}
              type="number"
              min="0"
            />
          </div>
          <div className={classes.col3}>Dodiri sa loptom</div>
          <div className={classes.col4}>
            <input
              style={{ fontWeight: "bold", fontSize: "20px" }}
              onChange={(e) => {
                setData({ ...data, dodiriSaLoptom2: e.target.value });
              }}
              type="number"
              min="0"
            />
          </div>
          <div className={classes.col5}>{calculatedData.dodiriSaLoptom2} %</div>
        </div>
        <div className={classes.row}>
          <div className={classes.col1}>
            {calculatedData.utakmiceBezPrimljenogGola} %
          </div>
          <div className={classes.col2}>
            <input
              style={{ fontWeight: "bold", fontSize: "20px" }}
              onChange={(e) => {
                setData({ ...data, utakmiceBezPrimljenogGola: e.target.value });
              }}
              type="number"
              min="0"
            />
          </div>
          <div className={classes.col3}>Utakmice bez primljenog gola</div>
          <div className={classes.col4}>
            <input
              style={{ fontWeight: "bold", fontSize: "20px" }}
              onChange={(e) => {
                setData({
                  ...data,
                  utakmiceBezPrimljenogGola2: e.target.value,
                });
              }}
              type="number"
              min="0"
            />
          </div>
          <div className={classes.col5}>
            {calculatedData.utakmiceBezPrimljenogGola2} %
          </div>
        </div>
        <div className={classes.row}>
          <div className={classes.col1}>
            {calculatedData.preciznaDodavanjaNaSvojojPolovini} %
          </div>
          <div className={classes.col2}>
            <input
              style={{ fontWeight: "bold", fontSize: "20px" }}
              onChange={(e) => {
                setData({
                  ...data,
                  preciznaDodavanjaNaSvojojPolovini: e.target.value,
                });
              }}
              type="number"
              min="0"
            />
          </div>
          <div className={classes.col3}>
            Precizna dodavanja na svojoj polovini
          </div>
          <div className={classes.col4}>
            <input
              style={{ fontWeight: "bold", fontSize: "20px" }}
              onChange={(e) => {
                setData({
                  ...data,
                  preciznaDodavanjaNaSvojojPolovini2: e.target.value,
                });
              }}
              type="number"
              min="0"
            />
          </div>
          <div className={classes.col5}>
            {calculatedData.preciznaDodavanjaNaSvojojPolovini2} %
          </div>
        </div>
        <div className={classes.row}>
          <div className={classes.col1}>
            {calculatedData.precizneDugeLopte} %
          </div>
          <div className={classes.col2}>
            <input
              style={{ fontWeight: "bold", fontSize: "20px" }}
              onChange={(e) => {
                setData({ ...data, precizneDugeLopte: e.target.value });
              }}
              type="number"
              min="0"
            />
          </div>
          <div className={classes.col3}>Precizne duge lopte</div>
          <div className={classes.col4}>
            <input
              style={{ fontWeight: "bold", fontSize: "20px" }}
              onChange={(e) => {
                setData({ ...data, precizneDugeLopte2: e.target.value });
              }}
              type="number"
              min="0"
            />
          </div>
          <div className={classes.col5}>
            {calculatedData.precizneDugeLopte2} %
          </div>
        </div>
        <div className={classes.row}>
          <div className={classes.col1}>{calculatedData.preciznihPasova} %</div>
          <div className={classes.col2}>
            <input
              style={{ fontWeight: "bold", fontSize: "20px" }}
              onChange={(e) => {
                setData({ ...data, preciznihPasova: e.target.value });
              }}
              type="number"
              min="0"
            />
          </div>
          <div className={classes.col3}>Preciznih pasova</div>
          <div className={classes.col4}>
            <input
              style={{ fontWeight: "bold", fontSize: "20px" }}
              onChange={(e) => {
                setData({ ...data, preciznihPasova2: e.target.value });
              }}
              type="number"
              min="0"
            />
          </div>
          <div className={classes.col5}>
            {calculatedData.preciznihPasova2} %
          </div>
        </div>
        <div className={classes.row}>
          <div className={classes.col1}>
            {calculatedData.precizniCentrasutevi} %
          </div>
          <div className={classes.col2}>
            <input
              style={{ fontWeight: "bold", fontSize: "20px" }}
              onChange={(e) => {
                setData({ ...data, precizniCentrasutevi: e.target.value });
              }}
              type="number"
              min="0"
            />
          </div>
          <div className={classes.col3}>Precizni centrašutevi</div>
          <div className={classes.col4}>
            <input
              style={{ fontWeight: "bold", fontSize: "20px" }}
              onChange={(e) => {
                setData({ ...data, precizniCentrasutevi2: e.target.value });
              }}
              type="number"
              min="0"
            />
          </div>
          <div className={classes.col5}>
            {calculatedData.precizniCentrasutevi2} %
          </div>
        </div>

        <div className={classes.row}>
          <div className={classes.col1}>
            {calculatedData.skrivljeniSlobodniUdarci} %
          </div>
          <div className={classes.col2}>
            <input
              style={{ fontWeight: "bold", fontSize: "20px" }}
              onChange={(e) => {
                setData({ ...data, skrivljeniSlobodniUdarci: e.target.value });
              }}
              type="number"
              min="0"
            />
          </div>
          <div className={classes.col3}>Skrivljeni slobodni udarci</div>
          <div className={classes.col4}>
            <input
              style={{ fontWeight: "bold", fontSize: "20px" }}
              onChange={(e) => {
                setData({
                  ...data,
                  skrivljeniSlobodniUdarci2: e.target.value,
                });
              }}
              type="number"
              min="0"
            />
          </div>
          <div className={classes.col5}>
            {calculatedData.skrivljeniSlobodniUdarci2} %
          </div>
        </div>

        <div className={classes.row}>
          <div className={classes.col1}>
            {calculatedData.skrivljeniGolovi} %
          </div>
          <div className={classes.col2}>
            <input
              style={{ fontWeight: "bold", fontSize: "20px" }}
              onChange={(e) => {
                setData({ ...data, skrivljeniGolovi: e.target.value });
              }}
              type="number"
              min="0"
            />
          </div>
          <div className={classes.col3}>Skrivljeni golovi</div>
          <div className={classes.col4}>
            <input
              style={{ fontWeight: "bold", fontSize: "20px" }}
              onChange={(e) => {
                setData({
                  ...data,
                  skrivljeniGolovi2: e.target.value,
                });
              }}
              type="number"
              min="0"
            />
          </div>
          <div className={classes.col5}>
            {calculatedData.skrivljeniGolovi2} %
          </div>
        </div>

        <div className={classes.row}>
          <div className={classes.col1}>
            {calculatedData.skrivljeniPenali} %
          </div>
          <div className={classes.col2}>
            <input
              style={{ fontWeight: "bold", fontSize: "20px" }}
              onChange={(e) => {
                setData({ ...data, skrivljeniPenali: e.target.value });
              }}
              type="number"
              min="0"
            />
          </div>
          <div className={classes.col3}>Skrivljeni penali</div>
          <div className={classes.col4}>
            <input
              style={{ fontWeight: "bold", fontSize: "20px" }}
              onChange={(e) => {
                setData({
                  ...data,
                  skrivljeniPenali2: e.target.value,
                });
              }}
              type="number"
              min="0"
            />
          </div>
          <div className={classes.col5}>
            {calculatedData.skrivljeniPenali2} %
          </div>
        </div>

        <div className={classes.row}>
          <div className={classes.col1}>
            {calculatedData.osvojeniZracniDueli} %
          </div>
          <div className={classes.col2}>
            <input
              style={{ fontWeight: "bold", fontSize: "20px" }}
              onChange={(e) => {
                setData({ ...data, osvojeniZracniDueli: e.target.value });
              }}
              type="number"
              min="0"
            />
          </div>
          <div className={classes.col3}>Osvojeni zracni dueli</div>
          <div className={classes.col4}>
            <input
              style={{ fontWeight: "bold", fontSize: "20px" }}
              onChange={(e) => {
                setData({
                  ...data,
                  osvojeniZracniDueli2: e.target.value,
                });
              }}
              type="number"
              min="0"
            />
          </div>
          <div className={classes.col5}>
            {calculatedData.osvojeniZracniDueli2} %
          </div>
        </div>

        <div className={classes.row}>
          <div className={classes.col1}>{calculatedData.zutiKartoni} %</div>
          <div className={classes.col2}>
            <input
              style={{ fontWeight: "bold", fontSize: "20px" }}
              onChange={(e) => {
                setData({ ...data, zutiKartoni: e.target.value });
              }}
              type="number"
              min="0"
            />
          </div>
          <div className={classes.col3}>Žuti kartoni</div>
          <div className={classes.col4}>
            <input
              style={{ fontWeight: "bold", fontSize: "20px" }}
              onChange={(e) => {
                setData({
                  ...data,
                  zutiKartoni2: e.target.value,
                });
              }}
              type="number"
              min="0"
            />
          </div>
          <div className={classes.col5}>{calculatedData.zutiKartoni2} %</div>
        </div>

        <div className={classes.row}>
          <div className={classes.col1}>
            {calculatedData.drugiZutiKartoni} %
          </div>
          <div className={classes.col2}>
            <input
              style={{ fontWeight: "bold", fontSize: "20px" }}
              onChange={(e) => {
                setData({ ...data, drugiZutiKartoni: e.target.value });
              }}
              type="number"
              min="0"
            />
          </div>
          <div className={classes.col3}>Drugi žuti kartoni</div>
          <div className={classes.col4}>
            <input
              style={{ fontWeight: "bold", fontSize: "20px" }}
              onChange={(e) => {
                setData({
                  ...data,
                  drugiZutiKartoni2: e.target.value,
                });
              }}
              type="number"
              min="0"
            />
          </div>
          <div className={classes.col5}>
            {calculatedData.drugiZutiKartoni2} %
          </div>
        </div>

        <div className={classes.row}>
          <div className={classes.col1}>{calculatedData.crveniKartoni} %</div>
          <div className={classes.col2}>
            <input
              style={{ fontWeight: "bold", fontSize: "20px" }}
              onChange={(e) => {
                setData({ ...data, crveniKartoni: e.target.value });
              }}
              type="number"
              min="0"
            />
          </div>
          <div className={classes.col3}>Crveni kartoni</div>
          <div className={classes.col4}>
            <input
              style={{ fontWeight: "bold", fontSize: "20px" }}
              onChange={(e) => {
                setData({
                  ...data,
                  crveniKartoni2: e.target.value,
                });
              }}
              type="number"
              min="0"
            />
          </div>
          <div className={classes.col5}>{calculatedData.crveniKartoni2} %</div>
        </div>
      </div>
    </div>
  );
};

export default PoredenjeGolmanaFudbal;
