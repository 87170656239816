import React, { useContext, useEffect, useState } from 'react'
import { Table, Button, Input, message, Modal } from 'antd'
import classes from './shopAdminOrders.module.css'
import AuthContext from '../context/AuthProvider'
import formatPrice from '../utils/FormatPriceCurrency'
import useAxiosPrivate from '../hooks/useAxiosPrivate'
import { BsEye } from 'react-icons/bs'
import { useNavigate } from 'react-router-dom'
const SERVER_URL = process.env.REACT_APP_SERVER_URL

const ShopAdminOrders = () => {
  const [currentPage, setCurrentPage] = useState(1)
  const { auth } = useContext(AuthContext)
  const [allOrders, setAllOrders] = useState(null)
  const [searchTerm, setSearchTerm] = useState(null)
  const [refetchTrigger, setRefetchTrigger] = useState(false)
  const [selectedOrder, setSelectedOrder] = useState(null)
  const [previewOrderModal, setPreviewOrderModal] = useState()
  const isAdmin = auth.roles.includes(5151)
  const axiosPrivate = useAxiosPrivate()
  const navigate = useNavigate()

  useEffect(() => {
    const getAllOrders = async () => {
      try {
        const response = await axiosPrivate.get(
          `${SERVER_URL}/get-all-orders/${currentPage}/10/${
            searchTerm || null
          }/${null}`
        )

        setAllOrders(response?.data)
      } catch (error) {
        setAllOrders([])
        message.error(
          error?.response?.data?.message || 'Greška pri preuzimanju porudzbina!'
        )
      }
    }

    getAllOrders()
  }, [searchTerm, refetchTrigger, currentPage])

  useEffect(() => {
    if (!isAdmin) {
      navigate('/betko-shop-admin/home')
    }
  }, [])

  const columns = [
    {
      title: 'Broj porudzbine',
      dataIndex: 'orderNumber',
      key: 'orderNumber',
    },
    {
      title: 'Ime i prezime kupca',
      dataIndex: ['customer', 'name'],
      key: 'customer.name',
    },
    {
      title: 'Email kupca',
      dataIndex: ['customer_id', 'email'],
      key: 'customer.email',
    },
    {
      title: 'Telefon kupca',
      dataIndex: ['customer', 'phoneNumber'],
      key: 'customer.phoneNumber',
    },
    {
      title: 'Adresa kupca',
      key: 'customerAddress',
      render: (_, record) => (
        <span>
          {record?.customer?.address}, {record?.customer?.place} (
          {record?.customer?.zipCode})
        </span>
      ),
    },
    {
      title: 'Tip porudžbine',
      dataIndex: 'orderType',
      key: 'orderType',
    },
    {
      title: 'Ukupno',
      dataIndex: 'totalPrice',
      key: 'totalPrice',
      render: (totalPrice) => <span>{formatPrice(totalPrice)}</span>,
    },
    {
      title: 'Akcije',
      key: 'actions',
      render: (_, record) => (
        <span>
          <Button
            icon={<BsEye />}
            onClick={() => {
              setPreviewOrderModal(true)
              setSelectedOrder(record)
            }}
            title='Detalji porudžbine'
          />
        </span>
      ),
    },
  ]

  return (
    <div className={classes.shopAdminOrders}>
      <div className={classes.top}>
        <div>
          <h2>PORUDŽBINE</h2>
          <Input
            type='text'
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            placeholder='Pretraži'
            className={classes.searchTermInput}
          />
        </div>
      </div>
      <div className='orderTableContainer'>
        <Table
          columns={columns}
          dataSource={allOrders?.paginatedData}
          className={classes.orderTable}
          pagination={{
            current: currentPage,
            pageSize: 10,
            total: allOrders?.totalItems,
            onChange: (page) => setCurrentPage(page),
            showSizeChanger: false,
          }}
        />
      </div>

      <Modal
        open={previewOrderModal}
        title={`PRIKAZ PORUDŽBINE BR. ${selectedOrder?.orderNumber}`}
        onCancel={() => {
          setPreviewOrderModal(false)
          setSelectedOrder(null)
        }}
        closable
        footer={null}
        className={classes.orderPreviewModal}
      >
        <table className={classes.orderPreviewTable}>
          <thead>
            <th>NAZIV PROIZVODA</th>
            <th>NAZIV KOMPANIJE</th>
            <th>EMAIL PRODAVCA</th>
            <th>CENA</th>
            <th>KOLIČINA</th>
            <th>UKUPNO</th>
            <th>ATRIBUTI</th>
          </thead>
          <tbody className={classes.cartProducts}>
            {console.log(selectedOrder)}
            {selectedOrder?.orderedProducts?.map((product) => (
              <tr key={product._id}>
                <td className={classes.productName}>
                  {product?.productId?.title}
                </td>
                <td>{product?.salesman_id?.companyName}</td>
                <td>{product?.salesman_id?.email}</td>
                <td>{formatPrice(product?.productId?.price)}</td>
                <td>{product?.quantity}</td>
                <td>{formatPrice(product?.quantity * +product?.productId?.price)}</td>
                <td>{product?.atrDesc}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </Modal>
    </div>
  )
}

export default ShopAdminOrders
