import { useState } from 'react'
import useAxiosPrivate from '../hooks/useAxiosPrivate'
import { useQuery } from '@tanstack/react-query'
import SinglePost from '../components/SinglePost'
import Spinner from '../components/Spinner'
import classes from './statistika.module.css'

const Statistika = () => {
  const [posts, setPosts] = useState([])
  const [isMsg, setIsMsg] = useState(false)
  const axiosPrivate = useAxiosPrivate()

  const getAllStatistikaPosts = async () => {
    const { data } = await axiosPrivate.get('/getAllStatistikaPosts')
    data.msg ? setIsMsg(true) : setPosts(data.result)
    !data.msg ? setIsMsg(false) : setPosts([])

    return data
  }

  const allStatistikaPostsQuery = useQuery({
    queryKey: ['statistika'],
    queryFn: getAllStatistikaPosts,
    staleTime: 0,
    refetchInterval: 1000 * 60 * 60 * 12,
  })

  return (
    <div className={classes.container}>
      {allStatistikaPostsQuery.isLoading || posts.length === 0 ? (
        <div className={classes.spinner}>
          <Spinner />
        </div>
      ) : (
        <>
          {!isMsg ? (
            posts.map((post) => {
              return (
                <SinglePost
                  commentNumbers={post?.commentsNumber}
                  key={post._id}
                  post={post}
                  postId={post._id}
                  type='statistika'
                />
              )
            })
          ) : (
            <div className={classes.msg}>
              <p>{allStatistikaPostsQuery.data.msg}</p>
            </div>
          )}
        </>
      )}
    </div>
  )
}

export default Statistika
