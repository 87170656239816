import classes from "./grupa300.module.css";
import { useState, useEffect } from "react";

const Grupa3001 = ({
  brMecevaIgrac2,
  brMecevaIgrac1,
  igrac2IzgSetova,
  igrac1IzgSetova,
  igrac2OsvSetova,
  pobedaigraca1,
  pobedaigraca2,
  brSetovaNaMecu,
  igrac1OsvSetova,
  setProcenatPobedeIgraca1,
  setProcenatPobedeIgraca2,
  setBrMecevaIgrac1,
  setBrMecevaIgrac2,
  setBrSetovaNaMecu,
  setIgrac1IzgSetova,
  setIgrac1OsvSetova,
  setIgrac2IzgSetova,
  setIgrac2OsvSetova,
  setPobedaIgraca1,
  setPobedaIgraca2,
  setMaxSetova,
  setSND,
  setSOD,
  setSOG,
  setSNG,
  setBalans,
  setOcekivaniBrojSetovaIgrac1,
  setOcekivaniBrojSetovaIgrac2,
  setBetkoPredlog,
  setSODG,
  setSNDG,
  setIsCalculated,
  setSNDSOG,
  setSODSNG,
}) => {
  useEffect(() => {
    handleCalculate();
  }, [
    brMecevaIgrac2,
    brMecevaIgrac1,
    igrac2IzgSetova,
    igrac1IzgSetova,
    igrac2OsvSetova,
    pobedaigraca1,
    pobedaigraca2,
    brSetovaNaMecu,
    igrac1OsvSetova,
  ]);

  const handleChangePobedaIgraca1 = (e) => {
    setPobedaIgraca1(e);
  };

  const handleChangePobedaIgraca2 = (e) => {
    setPobedaIgraca2(e);
  };

  const handleChangeBrSetovaNaMecu = (e) => {
    setBrSetovaNaMecu(e);
  };

  const handleChangeIgrac1OsvSetova = (e) => {
    setIgrac1OsvSetova(e);
  };

  const handleChangeIgrac1IzgSetova = (e) => {
    setIgrac1IzgSetova(e);
  };

  const handleChangeBrMecevaIgraca1 = (e) => {
    setBrMecevaIgrac1(e);
  };

  const handleChangeIgrac2OsvSetova = (e) => {
    setIgrac2OsvSetova(e);
  };

  const handleChangeIgrac2IzgSetova = (e) => {
    setIgrac2IzgSetova(e);
  };

  const handleChangeBrMecevaIgraca2 = (e) => {
    setBrMecevaIgrac2(e);
  };

  const handleCalculate = () => {
    if (
      brMecevaIgrac2 &&
      brMecevaIgrac1 &&
      igrac2IzgSetova &&
      igrac1IzgSetova &&
      igrac2OsvSetova &&
      pobedaigraca1 &&
      pobedaigraca2 &&
      brSetovaNaMecu &&
      igrac1OsvSetova
    ) {
      const procenat1 = 1 / pobedaigraca1;
      const procenat2 = 1 / pobedaigraca2;
      let ukupanProcenat = procenat1 + procenat2;

      ukupanProcenat = ukupanProcenat * 100;

      const iznad100Procenata = ukupanProcenat - 100;

      const polaOdIznad = iznad100Procenata / 2;

      let b58 = pobedaigraca1;

      let m59 = b58;

      let o59 = (1 / m59) * 100;

      let c58 = pobedaigraca2;

      let n59 = c58;

      let p59 = (1 / n59) * 100;

      let q59 = o59 + p59;

      let r59 = q59 - 100;

      let s59 = r59 / 2;

      let procenatPobede1 = parseFloat(o59 - s59).toFixed(2);

      const procenatPobede2 = (procenat2 * 100 - polaOdIznad).toFixed(2);

      setProcenatPobedeIgraca1(procenatPobede1);
      setProcenatPobedeIgraca2(procenatPobede2);

      let maxSetova =
        parseFloat(brSetovaNaMecu) - 1 + parseFloat(brSetovaNaMecu);

      setMaxSetova(maxSetova);

      let snd = igrac1OsvSetova / brMecevaIgrac1;

      setSND(snd.toFixed(2));

      let sod = igrac1IzgSetova / brMecevaIgrac1;

      setSOD(sod.toFixed(2));

      let sog = igrac2OsvSetova / brMecevaIgrac2;

      setSOG(sog.toFixed(2));

      let sng = igrac2IzgSetova / brMecevaIgrac2;

      setSNG(sng.toFixed(2));

      let f57 = snd + sod;

      let g57 = sog + sng;

      let f60 = maxSetova / 2;

      let f59 = (f57 + g57) / 2;

      let f27 = parseFloat(brSetovaNaMecu);

      let e60 = f27 * 2 - 1;

      let g59;

      if (f59 > e60) {
        g59 = (f27 + e60) / 2;
      } else {
        g59 = f59;
      }

      let ak60 = Math.round(parseFloat(procenatPobede1 / 100) * g59);

      let aj60 = procenatPobede2;

      let al60 = (parseFloat(aj60) / 100) * g59;

      // console.log(al60);

      let ar60;

      if (procenatPobede1 >= procenatPobede2) {
        ar60 = Math.round(f60);
      } else {
        ar60 = ak60;
      }

      let as60;

      if (ar60 === Math.round(f60)) {
        as60 = al60;
      } else {
        as60 = f60;
      }

      let at60 = ar60 + as60;

      let au60;

      if (at60 >= maxSetova && procenat1 >= procenat2) {
        au60 = Math.round(f60);
      } else {
        au60 = ar60;
      }

      setOcekivaniBrojSetovaIgrac1(au60);

      let av60;

      if (at60 >= maxSetova && procenat1 < procenat2) {
        av60 = Math.round(f60);
      } else {
        av60 = Math.round(as60);
      }

      setOcekivaniBrojSetovaIgrac2(av60);

      let balans = av60 + au60;

      setBalans(balans);

      let b44 = brSetovaNaMecu;

      let e44 = balans;

      let betkopredlog = Math.round(parseFloat(b44) + e44) / 2 - 0.5;

      let betkopredlogstring = `${parseFloat(betkopredlog).toFixed(1)} +`;

      setBetkoPredlog(betkopredlogstring);

      let sodg = (sod + sog) / 2;

      setSODG(sodg.toFixed(2));

      let sndg = (snd + sng) / 2;

      setSNDG(sndg.toFixed(2));

      let sndsog = (snd + sog) / 2;

      setSNDSOG(sndsog.toFixed(2));

      let sodsng = (sod + sng) / 2;

      setSODSNG(sodsng.toFixed(2));

      setIsCalculated(true);
    }
  };

  return (
    <div className={classes.container}>
      <input
        type="number"
        min="0"
        onChange={(e) => {
          handleChangePobedaIgraca1(e.target.value);
        }}
        className={classes.blackCenteredInput}
        placeholder="kvota na tip 1"
      />
      <input
        onChange={(e) => {
          handleChangePobedaIgraca2(e.target.value);
        }}
        className={classes.blackCenteredInput}
        placeholder="kvota na tip 2"
        type="number"
        min="0"
      />
      <input
        onChange={(e) => {
          handleChangeBrSetovaNaMecu(e.target.value);
        }}
        className={classes.blackCenteredInput}
        placeholder="igra se na dob.set"
        type="number"
        min="0"
      />
      <input
        onChange={(e) => {
          handleChangeIgrac1OsvSetova(e.target.value);
        }}
        className={classes.blackCenteredInput}
        placeholder="igrač 1 osv. setova"
        type="number"
        min="0"
      />
      <input
        onChange={(e) => {
          handleChangeIgrac1IzgSetova(e.target.value);
        }}
        className={classes.blackCenteredInput}
        placeholder="igrač 1 izg. setova"
        type="number"
        min="0"
      />
      <input
        onChange={(e) => {
          handleChangeBrMecevaIgraca1(e.target.value);
        }}
        className={classes.blackCenteredInput}
        placeholder="br mečeva igrača 1"
        type="number"
        min="0"
      />
      <input
        onChange={(e) => {
          handleChangeIgrac2OsvSetova(e.target.value);
        }}
        className={classes.blackCenteredInput}
        placeholder="igrač 2 osv. setova"
        type="number"
        min="0"
      />
      <input
        onChange={(e) => {
          handleChangeIgrac2IzgSetova(e.target.value);
        }}
        className={classes.blackCenteredInput}
        placeholder="igrač 2 izg. setova"
        type="number"
        min="0"
      />
      <input
        onChange={(e) => {
          handleChangeBrMecevaIgraca2(e.target.value);
        }}
        className={classes.blackCenteredInput}
        placeholder="br mečeva igrača 2"
        type="number"
        min="0"
      />
    </div>
  );
};

export default Grupa3001;
