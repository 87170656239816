import Grupa3 from "../components/Grupa3";
import Grupa20 from "../components/Grupa20";
import classes from "./pozitivnaLinija.module.css";
import { useState, useEffect } from "react";

const PozitivnaLinija = () => {
  const [rerender, setReRender] = useState(false);
  const [upisUloga, setUpisUloga] = useState([
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  ]);
  const [upisKvota, setUpisKvota] = useState([
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  ]);
  const [eventualniDobitak, setEventualniDobitak] = useState([
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  ]);
  const [upisProlazDaNe, setUpisProlazDaNe] = useState([
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
  ]);
  const [cistaDobit, setCistaDobit] = useState([
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  ]);
  const [upisProcenata, setUpisProcenata] = useState([
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  ]);
  const [prenosUUnitima, setPrenosUUnitima] = useState([
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  ]);
  const [trenutnoStanje, setTrenutnoStanje] = useState([
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  ]);
  const [upisUlogaPromasaja, setUpisUlogaPromasaja] = useState([
    "-",
    "-",
    "-",
    "-",
    "-",
    "-",
    "-",
    "-",
    "-",
    "-",
    "-",
    "-",
    "-",
    "-",
    "-",
    "-",
    "-",
    "-",
    "-",
    "-",
    "-",
  ]);
  const [ukupanDobitakGubitak, setUkupanDobitakGubitak] = useState([0, 0, 0]);

  const handleChangeUlog = (e, index) => {
    let arr = upisUloga;

    arr[index] = e;

    setUpisUloga(arr);
    setReRender(!rerender);
    handleCalculate();
  };

  // console.log(ukupanDobitakGubitak[0]);

  const handleChangeUpisKvota = (e, index) => {
    let arr = upisKvota;

    arr[index] = e;

    setUpisKvota(arr);
    setReRender(!rerender);
    handleCalculate();
  };

  const handleChangeUpisProcenata = (e, index) => {
    let arr = upisProcenata;

    arr[index] = e;

    setUpisProcenata(arr);
    setReRender(!rerender);
    handleCalculate();
  };

  const handleChangeUpisProlazDaNe = (e, index) => {
    let arr = upisProlazDaNe;

    arr[index] = e;

    setUpisProlazDaNe(arr);
    setReRender(!rerender);
    handleCalculate();
  };

  useEffect(() => {
    handleCalculate();
  }, [upisKvota, upisUloga, upisProcenata, upisProlazDaNe]);

  const handleCalculate = () => {
    let eventualniDobitakArr = eventualniDobitak;

    for (let i = 0; i < eventualniDobitak.length; i++) {
      let item = upisKvota[i] * upisUloga[i];
      if (isNaN(item)) {
        eventualniDobitakArr[i] = 0;
      } else {
        eventualniDobitakArr[i] = item.toFixed(2);
      }
    }

    setEventualniDobitak(eventualniDobitakArr);

    let cistadobitArr = cistaDobit;

    for (let i = 0; i < cistaDobit.length; i++) {
      let item = eventualniDobitak[i] - upisUloga[i];

      if (isNaN(item)) {
        cistadobitArr[i] = 0;
      } else if (item > 0 && upisProlazDaNe[i] === "da") {
        cistadobitArr[i] = item.toFixed(2);
      } else if (item > 0 && upisProlazDaNe[i] === "ne") {
        cistadobitArr[i] = -upisUloga[i];
      } else {
        cistadobitArr[i] = 0;
      }
    }

    setCistaDobit(cistadobitArr);

    let prenosuunitimaArr = prenosUUnitima;

    // console.log(cistadobitArr);

    for (let i = 0; i < prenosUUnitima.length; i++) {
      let item = parseFloat(cistadobitArr[i]) * (upisProcenata[i] / 100);
      if (isNaN(item)) {
        prenosuunitimaArr[i] = 0;
      } else {
        prenosuunitimaArr[i] = item.toFixed(2);
      }
    }

    setPrenosUUnitima(prenosuunitimaArr);

    let ulogarr = upisUloga;

    for (let i = 1; i < upisUloga.length; i++) {
      let item;
      if (upisProlazDaNe === "da" && i === 1) {
        item = prenosuunitimaArr[i - 1];
      } else if (upisProlazDaNe === "ne" && i === 1) {
        item = upisUloga[0];
      } else {
        item = prenosuunitimaArr[i - 1];
      }

      ulogarr[i] = Math.abs(item);
    }

    setUpisUloga(ulogarr);

    let trenutnostanjeArr = trenutnoStanje;

    for (let i = 0; i < trenutnoStanje.length; i++) {
      let item = cistaDobit[i];
      trenutnostanjeArr[i] = item;
    }

    setTrenutnoStanje(trenutnostanjeArr);

    let gubitakdobitakpriv = ukupanDobitakGubitak;
    let totalTrenutnoStanje = 0;

    // console.log(cistaDobit);

    for (let i = 0; i < trenutnostanjeArr.length; i++) {
      totalTrenutnoStanje = totalTrenutnoStanje + Number(trenutnostanjeArr[i]);
    }

    gubitakdobitakpriv[0] = totalTrenutnoStanje;
    gubitakdobitakpriv[1] = totalTrenutnoStanje;

    let promasajiArr = upisUlogaPromasaja;

    for (let i = 0; i < upisUlogaPromasaja.length; i++) {
      let item;
      if (upisProcenata[i] > 0) {
        item = "U PLUSU STE";
      } else if (upisProcenata[i] === 0) {
        item = "NA NULI STE";
      } else {
        item = `${trenutnoStanje[i]}`;
      }
      promasajiArr[i] = item;
    }

    setUpisUlogaPromasaja(promasajiArr);
  };

  return (
    <div className={classes.container}>
      <Grupa3
        imgText="POZITIVNA LINIJA"
        imgBackgroundColorDark="#6D2C9E"
        imgBackgroundColorLight="#7730AD"
        imgFontColor="#FFFFFF"
      />
      <Grupa20
        imgBackgroundColorDark="#6D2C9E"
        imgBackgroundColorLight="#7730AD"
        imgFontColor="#FFFFFF"
        calculatorType={`Pozitivna linija`}
      />
      <div className={classes.calculator} style={{ fontWeight: "bolder" }}>
        <div className={classes.firstRow}>
          <div className={classes.upisUlogHeader}>
            <div className={classes.first}>
              <p style={{ textAlign: "center", marginTop: "10px" }}>
                UPIS <br /> ULOG
              </p>
            </div>
          </div>
          <div className={classes.upisKvotaHeader}>
            <div className={classes.first}>
              <p style={{ textAlign: "center", marginTop: "10px" }}>
                UPIS <br /> KVOTA
              </p>
            </div>
          </div>
          <div className={classes.eventualniDobitakHeader}>
            <div className={classes.first}>
              <p style={{ textAlign: "center", marginTop: "10px" }}>
                EVENTUALNI <br /> DOBITAK
              </p>
            </div>
          </div>
          <div className={classes.upisProlazDaNeHeader}>
            <div className={classes.first}>
              <p style={{ textAlign: "center", marginTop: "10px" }}>
                UPIS <br /> PROLAZ (DA/NE)
              </p>
            </div>
          </div>

          <div className={classes.cistaDobitHeader}>
            <p style={{ textAlign: "center", marginTop: "15px" }}>
              ČISTA DOBIT
            </p>
          </div>

          <div className={classes.upisProcenataHeader}>
            <div className={classes.first}>
              <p style={{ textAlign: "center", marginTop: "10px" }}>
                UPIS PROCENATA (%)
                <br /> PRENOS U SLEDECI KRUG
              </p>
            </div>
          </div>
          <div className={classes.prenosUunitmaHeader}>
            <p style={{ textAlign: "center", marginTop: "15px" }}>
              PRENOS U UNITIMA
            </p>
          </div>
          <div className={classes.trenutnoStanjeHeader}>
            <p style={{ textAlign: "center", marginTop: "15px" }}>
              TRENUTNO STANJE
            </p>
          </div>
          <div className={classes.upisUlogPromasajaHeader}>
            <p style={{ textAlign: "center", marginTop: "15px" }}>
              UPIS ULOGA PROMAŠAJA - KRUG 1
            </p>
          </div>
        </div>
        <div className={classes.row}>
          <div className={classes.upisUlogFirstRow2}>
            <input
              style={{
                width: "100%",
                boxSizing: "border-box",
                padding: "0",
                margin: "0",
                textAlign: "center",
                fontWeight: "bolder",
              }}
              type="number"
              min="0"
              onChange={(e) => {
                handleChangeUlog(e.target.value, 0);
              }}
            />
          </div>
          <div className={classes.upisKvotaRow}>
            <input
              type="number"
              min="0"
              onChange={(e) => {
                handleChangeUpisKvota(e.target.value, 0);
              }}
            />
          </div>
          <div className={classes.eventualniDobitakRow}>
            {eventualniDobitak[0] === 0 || eventualniDobitak[0] === "0.00"
              ? "-"
              : eventualniDobitak[0]}
          </div>
          <div className={classes.upisProlazDaNeRow}>
            <select
              style={{ textAlign: "center", fontWeight: "bolder" }}
              onChange={(e) => handleChangeUpisProlazDaNe(e.target.value, 0)}
              value={upisProlazDaNe[0]}
            >
              <option value=""></option>
              <option value="da">DA</option>
              <option value="ne">NE</option>
            </select>
          </div>

          <div className={classes.cistaDobitRow}>
            {cistaDobit[0] === 0
              ? "-"
              : upisProlazDaNe[0] === "ne"
              ? `${cistaDobit[0]}`
              : cistaDobit[0]}
          </div>

          <div className={classes.upisProcenataRow}>
            <input
              type="number"
              min="0"
              onChange={(e) => handleChangeUpisProcenata(e.target.value, 0)}
            />
          </div>
          <div className={classes.prenosUunitmaRow}>
            {prenosUUnitima[0] === 0 || prenosUUnitima[0] === "0.00"
              ? "-"
              : upisProlazDaNe[0] === "ne"
              ? `${prenosUUnitima[0]}`
              : prenosUUnitima[0]}
          </div>
          <div className={classes.trenutnoStanjeRow}>
            {trenutnoStanje[0] === 0
              ? "-"
              : upisProlazDaNe[0] === "ne"
              ? `${trenutnoStanje[0]}`
              : trenutnoStanje[0]}
          </div>
          <div className={classes.upisUlogaPromasajaRow}>
            <input style={{ width: "100%" }}></input>
          </div>
        </div>
        <div className={classes.row}>
          <div className={classes.upisUlogFirstRow2}>{upisUloga[1]}</div>
          <div className={classes.upisKvotaRow}>
            <input
              type="number"
              min="0"
              onChange={(e) => {
                handleChangeUpisKvota(e.target.value, 1);
              }}
            />
          </div>
          <div className={classes.eventualniDobitakRow}>
            {eventualniDobitak[1] === 0 || eventualniDobitak[1] === "0.00"
              ? "-"
              : eventualniDobitak[1]}
          </div>
          <div className={classes.upisProlazDaNeRow}>
            <select
              style={{ textAlign: "center", fontWeight: "bolder" }}
              onChange={(e) => handleChangeUpisProlazDaNe(e.target.value, 1)}
              value={upisProlazDaNe[1]}
            >
              <option value=""></option>
              <option value="da">DA</option>
              <option value="ne">NE</option>
            </select>
          </div>

          <div className={classes.cistaDobitRow}>
            {cistaDobit[1] === 0
              ? "-"
              : upisProlazDaNe[1] === "ne"
              ? `${cistaDobit[1]}`
              : cistaDobit[1]}
          </div>

          <div className={classes.upisProcenataRow}>
            <input
              type="number"
              min="0"
              onChange={(e) => handleChangeUpisProcenata(e.target.value, 1)}
            />
          </div>
          <div className={classes.prenosUunitmaRow}>
            {prenosUUnitima[1] === 0 || prenosUUnitima[1] === "0.00"
              ? "-"
              : upisProlazDaNe[1] === "ne"
              ? `${prenosUUnitima[1]}`
              : prenosUUnitima[1]}
          </div>
          <div className={classes.trenutnoStanjeRow}>
            {trenutnoStanje[1] === 0
              ? "-"
              : upisProlazDaNe[1] === "ne"
              ? `${trenutnoStanje[1]}`
              : trenutnoStanje[1]}
          </div>
          <div className={classes.upisUlogaPromasajaRow}>
            <input style={{ width: "100%" }}></input>
          </div>
        </div>
        <div className={classes.row}>
          <div className={classes.upisUlogFirstRow2}>{upisUloga[2]}</div>
          <div className={classes.upisKvotaRow}>
            <input
              type="number"
              min="0"
              onChange={(e) => {
                handleChangeUpisKvota(e.target.value, 2);
              }}
            />
          </div>
          <div className={classes.eventualniDobitakRow}>
            {eventualniDobitak[2] === 0 || eventualniDobitak[2] === "0.00"
              ? "-"
              : eventualniDobitak[2]}
          </div>
          <div className={classes.upisProlazDaNeRow}>
            <select
              style={{ textAlign: "center", fontWeight: "bolder" }}
              onChange={(e) => handleChangeUpisProlazDaNe(e.target.value, 2)}
              value={upisProlazDaNe[2]}
            >
              <option value=""></option>
              <option value="da">DA</option>
              <option value="ne">NE</option>
            </select>
          </div>

          <div className={classes.cistaDobitRow}>
            {cistaDobit[2] === 0
              ? "-"
              : upisProlazDaNe[2] === "ne"
              ? `${cistaDobit[2]}`
              : cistaDobit[2]}
          </div>

          <div className={classes.upisProcenataRow}>
            <input
              type="number"
              min="0"
              onChange={(e) => handleChangeUpisProcenata(e.target.value, 2)}
            />
          </div>
          <div className={classes.prenosUunitmaRow}>
            {prenosUUnitima[2] === 0 || prenosUUnitima[2] === "0.00"
              ? "-"
              : upisProlazDaNe[2] === "ne"
              ? `${prenosUUnitima[2]}`
              : prenosUUnitima[2]}
          </div>
          <div className={classes.trenutnoStanjeRow}>
            {trenutnoStanje[2] === 0
              ? "-"
              : upisProlazDaNe[2] === "ne"
              ? `${trenutnoStanje[2]}`
              : trenutnoStanje[2]}
          </div>
          <div className={classes.upisUlogaPromasajaRow}>
            <input style={{ width: "100%" }}></input>
          </div>
        </div>
        <div className={classes.row}>
          <div className={classes.upisUlogFirstRow2}>{upisUloga[3]}</div>
          <div className={classes.upisKvotaRow}>
            <input
              type="number"
              min="0"
              onChange={(e) => {
                handleChangeUpisKvota(e.target.value, 3);
              }}
            />
          </div>
          <div className={classes.eventualniDobitakRow}>
            {eventualniDobitak[3] === 0 || eventualniDobitak[3] === "0.00"
              ? "-"
              : eventualniDobitak[3]}
          </div>
          <div className={classes.upisProlazDaNeRow}>
            <select
              style={{ textAlign: "center", fontWeight: "bolder" }}
              onChange={(e) => handleChangeUpisProlazDaNe(e.target.value, 3)}
              value={upisProlazDaNe[3]}
            >
              <option value=""></option>
              <option value="da">DA</option>
              <option value="ne">NE</option>
            </select>
          </div>

          <div className={classes.cistaDobitRow}>
            {cistaDobit[3] === 0
              ? "-"
              : upisProlazDaNe[3] === "ne"
              ? `${cistaDobit[3]}`
              : cistaDobit[3]}
          </div>

          <div className={classes.upisProcenataRow}>
            <input
              type="number"
              min="0"
              onChange={(e) => handleChangeUpisProcenata(e.target.value, 3)}
            />
          </div>
          <div className={classes.prenosUunitmaRow}>
            {prenosUUnitima[3] === 0 || prenosUUnitima[3] === "0.00"
              ? "-"
              : upisProlazDaNe[3] === "ne"
              ? `${prenosUUnitima[3]}`
              : prenosUUnitima[3]}
          </div>
          <div className={classes.trenutnoStanjeRow}>
            {trenutnoStanje[3] === 0
              ? "-"
              : upisProlazDaNe[3] === "ne"
              ? `${trenutnoStanje[3]}`
              : trenutnoStanje[3]}
          </div>
          <div className={classes.upisUlogaPromasajaRow}>
            <input style={{ width: "100%" }}></input>
          </div>
        </div>
        <div className={classes.row}>
          <div className={classes.upisUlogFirstRow2}>{upisUloga[4]}</div>
          <div className={classes.upisKvotaRow}>
            <input
              type="number"
              min="0"
              onChange={(e) => {
                handleChangeUpisKvota(e.target.value, 4);
              }}
            />
          </div>
          <div className={classes.eventualniDobitakRow}>
            {eventualniDobitak[4] === 0 || eventualniDobitak[4] === "0.00"
              ? "-"
              : eventualniDobitak[4]}
          </div>
          <div className={classes.upisProlazDaNeRow}>
            <select
              style={{ textAlign: "center", fontWeight: "bolder" }}
              onChange={(e) => handleChangeUpisProlazDaNe(e.target.value, 4)}
              value={upisProlazDaNe[4]}
            >
              <option value=""></option>
              <option value="da">DA</option>
              <option value="ne">NE</option>
            </select>
          </div>

          <div className={classes.cistaDobitRow}>
            {cistaDobit[4] === 0
              ? "-"
              : upisProlazDaNe[4] === "ne"
              ? `${cistaDobit[4]}`
              : cistaDobit[4]}
          </div>

          <div className={classes.upisProcenataRow}>
            <input
              type="number"
              min="0"
              onChange={(e) => handleChangeUpisProcenata(e.target.value, 4)}
            />
          </div>
          <div className={classes.prenosUunitmaRow}>
            {prenosUUnitima[4] === 0 || prenosUUnitima[4] === "0.00"
              ? "-"
              : upisProlazDaNe[4] === "ne"
              ? `${prenosUUnitima[4]}`
              : prenosUUnitima[4]}
          </div>
          <div className={classes.trenutnoStanjeRow}>
            {trenutnoStanje[4] === 0
              ? "-"
              : upisProlazDaNe[4] === "ne"
              ? `${trenutnoStanje[4]}`
              : trenutnoStanje[4]}
          </div>
          <div className={classes.upisUlogaPromasajaRow}>
            <input style={{ width: "100%" }}></input>
          </div>
        </div>
        <div className={classes.row}>
          <div className={classes.upisUlogFirstRow2}>{upisUloga[5]}</div>
          <div className={classes.upisKvotaRow}>
            <input
              type="number"
              min="0"
              onChange={(e) => {
                handleChangeUpisKvota(e.target.value, 5);
              }}
            />
          </div>
          <div className={classes.eventualniDobitakRow}>
            {eventualniDobitak[5] === 0 || eventualniDobitak[5] === "0.00"
              ? "-"
              : eventualniDobitak[5]}
          </div>
          <div className={classes.upisProlazDaNeRow}>
            <select
              style={{ textAlign: "center", fontWeight: "bolder" }}
              onChange={(e) => handleChangeUpisProlazDaNe(e.target.value, 5)}
              value={upisProlazDaNe[5]}
            >
              <option value=""></option>
              <option value="da">DA</option>
              <option value="ne">NE</option>
            </select>
          </div>

          <div className={classes.cistaDobitRow}>
            {cistaDobit[5] === 0
              ? "-"
              : upisProlazDaNe[5] === "ne"
              ? `${cistaDobit[5]}`
              : cistaDobit[5]}
          </div>

          <div className={classes.upisProcenataRow}>
            <input
              type="number"
              min="0"
              onChange={(e) => handleChangeUpisProcenata(e.target.value, 5)}
            />
          </div>
          <div className={classes.prenosUunitmaRow}>
            {prenosUUnitima[5] === 0 || prenosUUnitima[5] === "0.00"
              ? "-"
              : upisProlazDaNe[5] === "ne"
              ? `${prenosUUnitima[5]}`
              : prenosUUnitima[5]}
          </div>
          <div className={classes.trenutnoStanjeRow}>
            {trenutnoStanje[5] === 0
              ? "-"
              : upisProlazDaNe[5] === "ne"
              ? `${trenutnoStanje[5]}`
              : trenutnoStanje[5]}
          </div>
          <div className={classes.upisUlogaPromasajaRow}>
            <input style={{ width: "100%" }}></input>
          </div>
        </div>
        <div className={classes.row}>
          <div className={classes.upisUlogFirstRow2}>{upisUloga[6]}</div>
          <div className={classes.upisKvotaRow}>
            <input
              type="number"
              min="0"
              onChange={(e) => {
                handleChangeUpisKvota(e.target.value, 6);
              }}
            />
          </div>
          <div className={classes.eventualniDobitakRow}>
            {eventualniDobitak[6] === 0 || eventualniDobitak[6] === "0.00"
              ? "-"
              : eventualniDobitak[6]}
          </div>
          <div className={classes.upisProlazDaNeRow}>
            <select
              style={{ textAlign: "center", fontWeight: "bolder" }}
              onChange={(e) => handleChangeUpisProlazDaNe(e.target.value, 6)}
              value={upisProlazDaNe[6]}
            >
              <option value=""></option>
              <option value="da">DA</option>
              <option value="ne">NE</option>
            </select>
          </div>

          <div className={classes.cistaDobitRow}>
            {cistaDobit[6] === 0
              ? "-"
              : upisProlazDaNe[6] === "ne"
              ? `${cistaDobit[6]}`
              : cistaDobit[6]}
          </div>

          <div className={classes.upisProcenataRow}>
            <input
              type="number"
              min="0"
              onChange={(e) => handleChangeUpisProcenata(e.target.value, 6)}
            />
          </div>
          <div className={classes.prenosUunitmaRow}>
            {prenosUUnitima[6] === 0 || prenosUUnitima[6] === "0.00"
              ? "-"
              : upisProlazDaNe[6] === "ne"
              ? `${prenosUUnitima[6]}`
              : prenosUUnitima[6]}
          </div>
          <div className={classes.trenutnoStanjeRow}>
            {trenutnoStanje[6] === 0
              ? "-"
              : upisProlazDaNe[6] === "ne"
              ? `${trenutnoStanje[6]}`
              : trenutnoStanje[6]}
          </div>
          <div className={classes.upisUlogaPromasajaRow}>
            <input style={{ width: "100%" }}></input>
          </div>
        </div>
        <div className={classes.row}>
          <div className={classes.upisUlogFirstRow2}>{upisUloga[7]}</div>
          <div className={classes.upisKvotaRow}>
            <input
              type="number"
              min="0"
              onChange={(e) => {
                handleChangeUpisKvota(e.target.value, 7);
              }}
            />
          </div>
          <div className={classes.eventualniDobitakRow}>
            {eventualniDobitak[7] === 0 || eventualniDobitak[7] === "0.00"
              ? "-"
              : eventualniDobitak[7]}
          </div>
          <div className={classes.upisProlazDaNeRow}>
            <select
              style={{ textAlign: "center", fontWeight: "bolder" }}
              onChange={(e) => handleChangeUpisProlazDaNe(e.target.value, 7)}
              value={upisProlazDaNe[7]}
            >
              <option value=""></option>
              <option value="da">DA</option>
              <option value="ne">NE</option>
            </select>
          </div>

          <div className={classes.cistaDobitRow}>
            {cistaDobit[7] === 0
              ? "-"
              : upisProlazDaNe[7] === "ne"
              ? `${cistaDobit[7]}`
              : cistaDobit[7]}
          </div>

          <div className={classes.upisProcenataRow}>
            <input
              type="number"
              min="0"
              onChange={(e) => handleChangeUpisProcenata(e.target.value, 7)}
            />
          </div>
          <div className={classes.prenosUunitmaRow}>
            {prenosUUnitima[7] === 0 || prenosUUnitima[7] === "0.00"
              ? "-"
              : upisProlazDaNe[7] === "ne"
              ? `${prenosUUnitima[7]}`
              : prenosUUnitima[7]}
          </div>
          <div className={classes.trenutnoStanjeRow}>
            {trenutnoStanje[7] === 0
              ? "-"
              : upisProlazDaNe[7] === "ne"
              ? `${trenutnoStanje[7]}`
              : trenutnoStanje[7]}
          </div>
          <div className={classes.upisUlogaPromasajaRow}>
            <input style={{ width: "100%" }}></input>
          </div>
        </div>
        <div className={classes.row}>
          <div className={classes.upisUlogFirstRow2}>{upisUloga[8]}</div>
          <div className={classes.upisKvotaRow}>
            <input
              type="number"
              min="0"
              onChange={(e) => {
                handleChangeUpisKvota(e.target.value, 8);
              }}
            />
          </div>
          <div className={classes.eventualniDobitakRow}>
            {eventualniDobitak[8] === 0 || eventualniDobitak[8] === "0.00"
              ? "-"
              : eventualniDobitak[8]}
          </div>
          <div className={classes.upisProlazDaNeRow}>
            <select
              style={{ textAlign: "center", fontWeight: "bolder" }}
              onChange={(e) => handleChangeUpisProlazDaNe(e.target.value, 8)}
              value={upisProlazDaNe[8]}
            >
              <option value=""></option>
              <option value="da">DA</option>
              <option value="ne">NE</option>
            </select>
          </div>

          <div className={classes.cistaDobitRow}>
            {cistaDobit[8] === 0
              ? "-"
              : upisProlazDaNe[8] === "ne"
              ? `${cistaDobit[8]}`
              : cistaDobit[8]}
          </div>

          <div className={classes.upisProcenataRow}>
            <input
              type="number"
              min="0"
              onChange={(e) => handleChangeUpisProcenata(e.target.value, 8)}
            />
          </div>
          <div className={classes.prenosUunitmaRow}>
            {prenosUUnitima[8] === 0 || prenosUUnitima[8] === "0.00"
              ? "-"
              : upisProlazDaNe[8] === "ne"
              ? `${prenosUUnitima[8]}`
              : prenosUUnitima[8]}
          </div>
          <div className={classes.trenutnoStanjeRow}>
            {trenutnoStanje[8] === 0
              ? "-"
              : upisProlazDaNe[8] === "ne"
              ? `${trenutnoStanje[8]}`
              : trenutnoStanje[8]}
          </div>
          <div className={classes.upisUlogaPromasajaRow}>
            <input style={{ width: "100%" }}></input>
          </div>
        </div>
        <div className={classes.row}>
          <div className={classes.upisUlogFirstRow2}>{upisUloga[9]}</div>
          <div className={classes.upisKvotaRow}>
            <input
              type="number"
              min="0"
              onChange={(e) => {
                handleChangeUpisKvota(e.target.value, 9);
              }}
            />
          </div>
          <div className={classes.eventualniDobitakRow}>
            {eventualniDobitak[9] === 0 || eventualniDobitak[9] === "0.00"
              ? "-"
              : eventualniDobitak[9]}
          </div>
          <div className={classes.upisProlazDaNeRow}>
            <select
              style={{ textAlign: "center", fontWeight: "bolder" }}
              onChange={(e) => handleChangeUpisProlazDaNe(e.target.value, 9)}
              value={upisProlazDaNe[9]}
            >
              <option value=""></option>
              <option value="da">DA</option>
              <option value="ne">NE</option>
            </select>
          </div>

          <div className={classes.cistaDobitRow}>
            {cistaDobit[9] === 0
              ? "-"
              : upisProlazDaNe[9] === "ne"
              ? `${cistaDobit[9]}`
              : cistaDobit[9]}
          </div>

          <div className={classes.upisProcenataRow}>
            <input
              type="number"
              min="0"
              onChange={(e) => handleChangeUpisProcenata(e.target.value, 9)}
            />
          </div>
          <div className={classes.prenosUunitmaRow}>
            {prenosUUnitima[9] === 0 || prenosUUnitima[9] === "0.00"
              ? "-"
              : upisProlazDaNe[9] === "ne"
              ? `${prenosUUnitima[9]}`
              : prenosUUnitima[9]}
          </div>
          <div className={classes.trenutnoStanjeRow}>
            {trenutnoStanje[9] === 0
              ? "-"
              : upisProlazDaNe[9] === "ne"
              ? `${trenutnoStanje[9]}`
              : trenutnoStanje[9]}
          </div>
          <div className={classes.upisUlogaPromasajaRow}>
            <input style={{ width: "100%" }}></input>
          </div>
        </div>
        <div className={classes.row}>
          <div className={classes.upisUlogFirstRow2}>{upisUloga[10]}</div>
          <div className={classes.upisKvotaRow}>
            <input
              type="number"
              min="0"
              onChange={(e) => {
                handleChangeUpisKvota(e.target.value, 10);
              }}
            />
          </div>
          <div className={classes.eventualniDobitakRow}>
            {eventualniDobitak[10] === 0 || "0.00"
              ? "-"
              : eventualniDobitak[10]}
          </div>
          <div className={classes.upisProlazDaNeRow}>
            <select
              style={{ textAlign: "center", fontWeight: "bolder" }}
              onChange={(e) => handleChangeUpisProlazDaNe(e.target.value, 10)}
              value={upisProlazDaNe[10]}
            >
              <option value=""></option>
              <option value="da">DA</option>
              <option value="ne">NE</option>
            </select>
          </div>

          <div className={classes.cistaDobitRow}>
            {cistaDobit[10] === 0
              ? "-"
              : upisProlazDaNe[10] === "ne"
              ? `${cistaDobit[10]}`
              : cistaDobit[10]}
          </div>

          <div className={classes.upisProcenataRow}>
            <input
              type="number"
              min="0"
              onChange={(e) => handleChangeUpisProcenata(e.target.value, 10)}
            />
          </div>
          <div className={classes.prenosUunitmaRow}>
            {prenosUUnitima[10] === 0 || prenosUUnitima[10] === "0.00"
              ? "-"
              : upisProlazDaNe[10] === "ne"
              ? `${prenosUUnitima[10]}`
              : prenosUUnitima[10]}
          </div>
          <div className={classes.trenutnoStanjeRow}>
            {trenutnoStanje[10] === 0
              ? "-"
              : upisProlazDaNe[10] === "ne"
              ? `${trenutnoStanje[10]}`
              : trenutnoStanje[10]}
          </div>
          <div className={classes.upisUlogaPromasajaRow}>
            <input style={{ width: "100%" }}></input>
          </div>
        </div>
        <div className={classes.row}>
          <div className={classes.upisUlogFirstRow2}>{upisUloga[11]}</div>
          <div className={classes.upisKvotaRow}>
            <input
              type="number"
              min="0"
              onChange={(e) => {
                handleChangeUpisKvota(e.target.value, 11);
              }}
            />
          </div>
          <div className={classes.eventualniDobitakRow}>
            {eventualniDobitak[11] === 0 || "0.00"
              ? "-"
              : eventualniDobitak[11]}
          </div>
          <div className={classes.upisProlazDaNeRow}>
            <select
              style={{ textAlign: "center", fontWeight: "bolder" }}
              onChange={(e) => handleChangeUpisProlazDaNe(e.target.value, 11)}
              value={upisProlazDaNe[11]}
            >
              <option value=""></option>
              <option value="da">DA</option>
              <option value="ne">NE</option>
            </select>
          </div>

          <div className={classes.cistaDobitRow}>
            {cistaDobit[11] === 0
              ? "-"
              : upisProlazDaNe[11] === "ne"
              ? `${cistaDobit[11]}`
              : cistaDobit[11]}
          </div>

          <div className={classes.upisProcenataRow}>
            <input
              type="number"
              min="0"
              onChange={(e) => handleChangeUpisProcenata(e.target.value, 11)}
            />
          </div>
          <div className={classes.prenosUunitmaRow}>
            {prenosUUnitima[11] === 0 || prenosUUnitima[11] === "0.00"
              ? "-"
              : upisProlazDaNe[11] === "ne"
              ? `${prenosUUnitima[11]}`
              : prenosUUnitima[11]}
          </div>
          <div className={classes.trenutnoStanjeRow}>
            {trenutnoStanje[11] === 0
              ? "-"
              : upisProlazDaNe[11] === "ne"
              ? `${trenutnoStanje[11]}`
              : trenutnoStanje[11]}
          </div>
          <div className={classes.upisUlogaPromasajaRow}>
            <input style={{ width: "100%" }}></input>
          </div>
        </div>
        <div className={classes.row}>
          <div className={classes.upisUlogFirstRow2}>{upisUloga[12]}</div>
          <div className={classes.upisKvotaRow}>
            <input
              type="number"
              min="0"
              onChange={(e) => {
                handleChangeUpisKvota(e.target.value, 12);
              }}
            />
          </div>
          <div className={classes.eventualniDobitakRow}>
            {eventualniDobitak[12] === 0 || "0.00"
              ? "-"
              : eventualniDobitak[12]}
          </div>
          <div className={classes.upisProlazDaNeRow}>
            <select
              style={{ textAlign: "center", fontWeight: "bolder" }}
              onChange={(e) => handleChangeUpisProlazDaNe(e.target.value, 12)}
              value={upisProlazDaNe[12]}
            >
              <option value=""></option>
              <option value="da">DA</option>
              <option value="ne">NE</option>
            </select>
          </div>

          <div className={classes.cistaDobitRow}>
            {cistaDobit[12] === 0
              ? "-"
              : upisProlazDaNe[12] === "ne"
              ? `${cistaDobit[12]}`
              : cistaDobit[12]}
          </div>

          <div className={classes.upisProcenataRow}>
            <input
              type="number"
              min="0"
              onChange={(e) => handleChangeUpisProcenata(e.target.value, 12)}
            />
          </div>
          <div className={classes.prenosUunitmaRow}>
            {prenosUUnitima[12] === 0 || prenosUUnitima[12] === "0.00"
              ? "-"
              : upisProlazDaNe[12] === "ne"
              ? `${prenosUUnitima[12]}`
              : prenosUUnitima[12]}
          </div>
          <div className={classes.trenutnoStanjeRow}>
            {trenutnoStanje[12] === 0
              ? "-"
              : upisProlazDaNe[12] === "ne"
              ? `${trenutnoStanje[12]}`
              : trenutnoStanje[12]}
          </div>
          <div className={classes.upisUlogaPromasajaRow}>
            <input style={{ width: "100%" }}></input>
          </div>
        </div>
        <div className={classes.row}>
          <div className={classes.upisUlogFirstRow2}>{upisUloga[13]}</div>
          <div className={classes.upisKvotaRow}>
            <input
              type="number"
              min="0"
              onChange={(e) => {
                handleChangeUpisKvota(e.target.value, 13);
              }}
            />
          </div>
          <div className={classes.eventualniDobitakRow}>
            {eventualniDobitak[13] === 0 || "0.00"
              ? "-"
              : eventualniDobitak[13]}
          </div>
          <div className={classes.upisProlazDaNeRow}>
            <select
              style={{ textAlign: "center", fontWeight: "bolder" }}
              onChange={(e) => handleChangeUpisProlazDaNe(e.target.value, 13)}
              value={upisProlazDaNe[13]}
            >
              <option value=""></option>
              <option value="da">DA</option>
              <option value="ne">NE</option>
            </select>
          </div>

          <div className={classes.cistaDobitRow}>
            {cistaDobit[13] === 0
              ? "-"
              : upisProlazDaNe[13] === "ne"
              ? `${cistaDobit[13]}`
              : cistaDobit[13]}
          </div>

          <div className={classes.upisProcenataRow}>
            <input
              type="number"
              min="0"
              onChange={(e) => handleChangeUpisProcenata(e.target.value, 13)}
            />
          </div>
          <div className={classes.prenosUunitmaRow}>
            {prenosUUnitima[13] === 0 || prenosUUnitima[13] === "0.00"
              ? "-"
              : upisProlazDaNe[13] === "ne"
              ? `${prenosUUnitima[13]}`
              : prenosUUnitima[13]}
          </div>
          <div className={classes.trenutnoStanjeRow}>
            {trenutnoStanje[13] === 0
              ? "-"
              : upisProlazDaNe[13] === "ne"
              ? `${trenutnoStanje[13]}`
              : trenutnoStanje[13]}
          </div>
          <div className={classes.upisUlogaPromasajaRow}>
            <input style={{ width: "100%" }}></input>
          </div>
        </div>
        <div className={classes.row}>
          <div className={classes.upisUlogFirstRow2}>{upisUloga[14]}</div>
          <div className={classes.upisKvotaRow}>
            <input
              type="number"
              min="0"
              onChange={(e) => {
                handleChangeUpisKvota(e.target.value, 14);
              }}
            />
          </div>
          <div className={classes.eventualniDobitakRow}>
            {eventualniDobitak[14] === 0 || "0.00"
              ? "-"
              : eventualniDobitak[14]}
          </div>
          <div className={classes.upisProlazDaNeRow}>
            <select
              style={{ textAlign: "center", fontWeight: "bolder" }}
              onChange={(e) => handleChangeUpisProlazDaNe(e.target.value, 14)}
              value={upisProlazDaNe[14]}
            >
              <option value=""></option>
              <option value="da">DA</option>
              <option value="ne">NE</option>
            </select>
          </div>

          <div className={classes.cistaDobitRow}>
            {cistaDobit[14] === 0
              ? "-"
              : upisProlazDaNe[14] === "ne"
              ? `${cistaDobit[14]}`
              : cistaDobit[14]}
          </div>

          <div className={classes.upisProcenataRow}>
            <input
              type="number"
              min="0"
              onChange={(e) => handleChangeUpisProcenata(e.target.value, 14)}
            />
          </div>
          <div className={classes.prenosUunitmaRow}>
            {prenosUUnitima[14] === 0 || prenosUUnitima[14] === "0.00"
              ? "-"
              : upisProlazDaNe[14] === "ne"
              ? `${prenosUUnitima[14]}`
              : prenosUUnitima[14]}
          </div>
          <div className={classes.trenutnoStanjeRow}>
            {trenutnoStanje[14] === 0
              ? "-"
              : upisProlazDaNe[14] === "ne"
              ? `${trenutnoStanje[14]}`
              : trenutnoStanje[14]}
          </div>
          <div className={classes.upisUlogaPromasajaRow}>
            <input style={{ width: "100%" }}></input>
          </div>
        </div>
        <div className={classes.row}>
          <div className={classes.upisUlogFirstRow2}>{upisUloga[15]}</div>
          <div className={classes.upisKvotaRow}>
            <input
              type="number"
              min="0"
              onChange={(e) => {
                handleChangeUpisKvota(e.target.value, 15);
              }}
            />
          </div>
          <div className={classes.eventualniDobitakRow}>
            {eventualniDobitak[15] === 0 || "0.00"
              ? "-"
              : eventualniDobitak[15]}
          </div>
          <div className={classes.upisProlazDaNeRow}>
            <select
              style={{ textAlign: "center", fontWeight: "bolder" }}
              onChange={(e) => handleChangeUpisProlazDaNe(e.target.value, 15)}
              value={upisProlazDaNe[15]}
            >
              <option value=""></option>
              <option value="da">DA</option>
              <option value="ne">NE</option>
            </select>
          </div>

          <div className={classes.cistaDobitRow}>
            {cistaDobit[15] === 0
              ? "-"
              : upisProlazDaNe[15] === "ne"
              ? `${cistaDobit[15]}`
              : cistaDobit[15]}
          </div>

          <div className={classes.upisProcenataRow}>
            <input
              type="number"
              min="0"
              onChange={(e) => handleChangeUpisProcenata(e.target.value, 15)}
            />
          </div>
          <div className={classes.prenosUunitmaRow}>
            {prenosUUnitima[15] === 0 || prenosUUnitima[15] === "0.00"
              ? "-"
              : upisProlazDaNe[15] === "ne"
              ? `${prenosUUnitima[15]}`
              : prenosUUnitima[15]}
          </div>
          <div className={classes.trenutnoStanjeRow}>
            {trenutnoStanje[15] === 0
              ? "-"
              : upisProlazDaNe[15] === "ne"
              ? `${trenutnoStanje[15]}`
              : trenutnoStanje[15]}
          </div>
          <div className={classes.upisUlogaPromasajaRow}>
            <input style={{ width: "100%" }}></input>
          </div>
        </div>
        <div className={classes.row}>
          <div className={classes.upisUlogFirstRow2}>{upisUloga[16]}</div>
          <div className={classes.upisKvotaRow}>
            <input
              type="number"
              min="0"
              onChange={(e) => {
                handleChangeUpisKvota(e.target.value, 16);
              }}
            />
          </div>
          <div className={classes.eventualniDobitakRow}>
            {eventualniDobitak[16] === 0 || "0.00"
              ? "-"
              : eventualniDobitak[16]}
          </div>
          <div className={classes.upisProlazDaNeRow}>
            <select
              style={{ textAlign: "center", fontWeight: "bolder" }}
              onChange={(e) => handleChangeUpisProlazDaNe(e.target.value, 16)}
              value={upisProlazDaNe[16]}
            >
              <option value=""></option>
              <option value="da">DA</option>
              <option value="ne">NE</option>
            </select>
          </div>

          <div className={classes.cistaDobitRow}>
            {cistaDobit[16] === 0
              ? "-"
              : upisProlazDaNe[16] === "ne"
              ? `${cistaDobit[16]}`
              : cistaDobit[16]}
          </div>

          <div className={classes.upisProcenataRow}>
            <input
              type="number"
              min="0"
              onChange={(e) => handleChangeUpisProcenata(e.target.value, 16)}
            />
          </div>
          <div className={classes.prenosUunitmaRow}>
            {prenosUUnitima[16] === 0 || prenosUUnitima[16] === "0.00"
              ? "-"
              : upisProlazDaNe[16] === "ne"
              ? `${prenosUUnitima[16]}`
              : prenosUUnitima[16]}
          </div>
          <div className={classes.trenutnoStanjeRow}>
            {trenutnoStanje[16] === 0
              ? "-"
              : upisProlazDaNe[16] === "ne"
              ? `${trenutnoStanje[16]}`
              : trenutnoStanje[16]}
          </div>
          <div className={classes.upisUlogaPromasajaRow}>
            <input style={{ width: "100%" }}></input>
          </div>
        </div>
        <div className={classes.row}>
          <div className={classes.upisUlogFirstRow2}>{upisUloga[17]}</div>
          <div className={classes.upisKvotaRow}>
            <input
              type="number"
              min="0"
              onChange={(e) => {
                handleChangeUpisKvota(e.target.value, 17);
              }}
            />
          </div>
          <div className={classes.eventualniDobitakRow}>
            {eventualniDobitak[17] === 0 || "0.00"
              ? "-"
              : eventualniDobitak[17]}
          </div>
          <div className={classes.upisProlazDaNeRow}>
            <select
              style={{ textAlign: "center", fontWeight: "bolder" }}
              onChange={(e) => handleChangeUpisProlazDaNe(e.target.value, 17)}
              value={upisProlazDaNe[17]}
            >
              <option value=""></option>
              <option value="da">DA</option>
              <option value="ne">NE</option>
            </select>
          </div>

          <div className={classes.cistaDobitRow}>
            {cistaDobit[17] === 0
              ? "-"
              : upisProlazDaNe[17] === "ne"
              ? `${cistaDobit[17]}`
              : cistaDobit[17]}
          </div>

          <div className={classes.upisProcenataRow}>
            <input
              type="number"
              min="0"
              onChange={(e) => handleChangeUpisProcenata(e.target.value, 17)}
            />
          </div>
          <div className={classes.prenosUunitmaRow}>
            {prenosUUnitima[17] === 0 || prenosUUnitima[17] === "0.00"
              ? "-"
              : upisProlazDaNe[17] === "ne"
              ? `${prenosUUnitima[17]}`
              : prenosUUnitima[17]}
          </div>
          <div className={classes.trenutnoStanjeRow}>
            {trenutnoStanje[17] === 0
              ? "-"
              : upisProlazDaNe[17] === "ne"
              ? `${trenutnoStanje[17]}`
              : trenutnoStanje[17]}
          </div>
          <div className={classes.upisUlogaPromasajaRow}>
            <input style={{ width: "100%" }}></input>
          </div>
        </div>
        <div className={classes.row}>
          <div className={classes.upisUlogFirstRow2}>{upisUloga[18]}</div>
          <div className={classes.upisKvotaRow}>
            <input
              type="number"
              min="0"
              onChange={(e) => {
                handleChangeUpisKvota(e.target.value, 18);
              }}
            />
          </div>
          <div className={classes.eventualniDobitakRow}>
            {eventualniDobitak[18] === 0 || "0.00"
              ? "-"
              : eventualniDobitak[18]}
          </div>
          <div className={classes.upisProlazDaNeRow}>
            <select
              style={{ textAlign: "center", fontWeight: "bolder" }}
              onChange={(e) => handleChangeUpisProlazDaNe(e.target.value, 18)}
              value={upisProlazDaNe[18]}
            >
              <option value=""></option>
              <option value="da">DA</option>
              <option value="ne">NE</option>
            </select>
          </div>

          <div className={classes.cistaDobitRow}>
            {cistaDobit[18] === 0
              ? "-"
              : upisProlazDaNe[18] === "ne"
              ? `${cistaDobit[18]}`
              : cistaDobit[18]}
          </div>

          <div className={classes.upisProcenataRow}>
            <input
              type="number"
              min="0"
              onChange={(e) => handleChangeUpisProcenata(e.target.value, 18)}
            />
          </div>
          <div className={classes.prenosUunitmaRow}>
            {prenosUUnitima[18] === 0 || prenosUUnitima[18] === "0.00"
              ? "-"
              : upisProlazDaNe[18] === "ne"
              ? `${prenosUUnitima[18]}`
              : prenosUUnitima[18]}
          </div>
          <div className={classes.trenutnoStanjeRow}>
            {trenutnoStanje[18] === 0
              ? "-"
              : upisProlazDaNe[18] === "ne"
              ? `${trenutnoStanje[18]}`
              : trenutnoStanje[18]}
          </div>
          <div className={classes.upisUlogaPromasajaRow}>
            <input style={{ width: "100%" }}></input>
          </div>
        </div>
        <div className={classes.row}>
          <div className={classes.upisUlogFirstRow2}>{upisUloga[19]}</div>
          <div className={classes.upisKvotaRow}>
            <input
              type="number"
              min="0"
              onChange={(e) => {
                handleChangeUpisKvota(e.target.value, 19);
              }}
            />
          </div>
          <div className={classes.eventualniDobitakRow}>
            {eventualniDobitak[19] === 0 || "0.00"
              ? "-"
              : eventualniDobitak[19]}
          </div>
          <div className={classes.upisProlazDaNeRow}>
            <select
              style={{ textAlign: "center", fontWeight: "bolder" }}
              onChange={(e) => handleChangeUpisProlazDaNe(e.target.value, 19)}
              value={upisProlazDaNe[19]}
            >
              <option value=""></option>
              <option value="da">DA</option>
              <option value="ne">NE</option>
            </select>
          </div>

          <div className={classes.cistaDobitRow}>
            {cistaDobit[19] === 0
              ? "-"
              : upisProlazDaNe[19] === "ne"
              ? `${cistaDobit[19]}`
              : cistaDobit[19]}
          </div>

          <div className={classes.upisProcenataRow}>
            <input
              type="number"
              min="0"
              onChange={(e) => handleChangeUpisProcenata(e.target.value, 19)}
            />
          </div>
          <div className={classes.prenosUunitmaRow}>
            {prenosUUnitima[19] === 0 || prenosUUnitima[19] === "0.00"
              ? "-"
              : upisProlazDaNe[19] === "ne"
              ? `${prenosUUnitima[19]}`
              : prenosUUnitima[19]}
          </div>
          <div className={classes.trenutnoStanjeRow}>
            {trenutnoStanje[19] === 0
              ? "-"
              : upisProlazDaNe[19] === "ne"
              ? `${trenutnoStanje[19]}`
              : trenutnoStanje[19]}
          </div>
          <div className={classes.upisUlogaPromasajaRow}>
            <input style={{ width: "100%" }}></input>
          </div>
        </div>
        <div className={classes.row}>
          <div className={classes.upisUlogFirstRow2}>{upisUloga[20]}</div>
          <div className={classes.upisKvotaRow}>
            <input
              type="number"
              min="0"
              onChange={(e) => {
                handleChangeUpisKvota(e.target.value, 20);
              }}
            />
          </div>
          <div className={classes.eventualniDobitakRow}>
            {eventualniDobitak[20] === 0 || "0.00"
              ? "-"
              : eventualniDobitak[20]}
          </div>
          <div className={classes.upisProlazDaNeRow}>
            <select
              style={{ textAlign: "center", fontWeight: "bolder" }}
              onChange={(e) => handleChangeUpisProlazDaNe(e.target.value, 20)}
              value={upisProlazDaNe[20]}
            >
              <option value=""></option>
              <option value="da">DA</option>
              <option value="ne">NE</option>
            </select>
          </div>

          <div className={classes.cistaDobitRow}>
            {cistaDobit[20] === 0
              ? "-"
              : upisProlazDaNe[20] === "ne"
              ? `${cistaDobit[20]}`
              : cistaDobit[20]}
          </div>

          <div className={classes.upisProcenataRow}>
            <input
              type="number"
              min="0"
              onChange={(e) => handleChangeUpisProcenata(e.target.value, 20)}
            />
          </div>
          <div className={classes.prenosUunitmaRow}>
            {prenosUUnitima[20] === 0 || prenosUUnitima[20] === "0.00"
              ? "-"
              : upisProlazDaNe[20] === "ne"
              ? `${prenosUUnitima[20]}`
              : prenosUUnitima[20]}
          </div>
          <div className={classes.trenutnoStanjeRow}>
            {trenutnoStanje[20] === 0
              ? "-"
              : upisProlazDaNe[20] === "ne"
              ? `${trenutnoStanje[20]}`
              : trenutnoStanje[20]}
          </div>
          <div className={classes.upisUlogaPromasajaRow}>
            <input style={{ width: "100%" }}></input>
          </div>
        </div>
        <div className={classes.bottom}>
          <div className={classes.prvi} style={{ width: "37.7%" }}>
            UKUPAN DOBITAK / GUBITAK
          </div>
          <div
            className={classes.prvi}
            style={{ width: "28%", background: "green", color: "white" }}
          >
            {ukupanDobitakGubitak[0]}
          </div>
          <div className={classes.prvi} style={{ width: "15.35%" }}></div>
          <div className={classes.prvi} style={{ width: "18.67%" }}></div>
        </div>
      </div>
    </div>
  );
};

export default PozitivnaLinija;
