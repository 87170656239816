import Grupa2 from "../components/Grupa2";
import Grupa20 from "../components/Grupa20";
import poredjenjeIgracaKosarkaDolnjaSlika from "../images/poredjenjeIgracaKosarkaDolnjaSlika.png";
import classes from "./poredenjeIgracaKosarka.module.css";
import { useState, useEffect } from "react";
const PoredenjeIgracaKosarka = () => {
  ////total fields/////
  const [brojUtakmicaDomacin, setBrojUtakmicaDomacin] = useState(0);
  const [brojUtakmicaGost, setBrojUtakmicaGost] = useState(0);
  const [data, setData] = useState({
    minutaPoUtakmici: 0,
    poeni: 0,
    poceo: 0,
    golovi: 0,
    sutzadva: 0,
    sutZaTri: 0,
    poeniIzPolja: 0,
    skokoviPoUtakmici: 0,
    odbranaSkokovi: 0,
    napadiSkokovi: 0,
    asistencija: 0,
    ukradeneLopte: 0,
    blokovi: 0,
    licneGreske: 0,
    dablDabl: 0,
    izgubljeneLopte: 0,
    triplDabl: 0,
    goloviDesnomNogom: 0,
    izboreniPenali: 0,
    dodavanje: 0,
    asistenicije: 0,
    dodiri: 0,
    kreiranjeVelikeSanse: 0,
    ///////////////
    minutaPoUtakmici2: 0,
    poeni2: 0,
    poceo2: 0,

    slobodnaBacanja2: 0,
    sutZaDva2: 0,
    sutZaTri2: 0,
    poeniIzPolja2: 0,
    skokoviPoUtakmici2: 0,
    odbranaSkokovi2: 0,
    napadiSkokovi2: 0,
    asistencija2: 0,
    ukradeneLopte2: 0,
    izgubljeneLopte2: 0,
    blokovi2: 0,
    licneGreske2: 0,
    dablDabl2: 0,
    triplDabl2: 0,
    goloviDesnomNogom2: 0,
    izboreniPenali2: 0,
    dodavanje2: 0,
    asistenicije2: 0,
    dodiri2: 0,
    kreiranjeVelikeSanse2: 0,
  });

  const [calculatedData, setCalculatedData] = useState({
    minutaPoUtakmici: 0,
    poceo: 0,
    ukupnoOdigrano: 0,
    poeni: 0,
    izgubljeneLopte: 0,
    golovi: 0,
    sutzadva: 0,
    sutZaTri: 0,
    poeniIzPolja: 0,
    skokoviPoUtakmici: 0,
    odbranaSkokovi: 0,
    napadiSkokovi: 0,
    asistencija: 0,
    ukradeneLopte: 0,
    blokovi: 0,
    licneGreske: 0,
    dablDabl: 0,
    triplDabl: 0,
    goloviDesnomNogom: 0,
    izboreniPenali: 0,
    dodavanje: 0,
    asistenicije: 0,
    dodiri: 0,
    kreiranjeVelikeSanse: 0,
    ///////////////
    minutaPoUtakmici2: 0,
    ukupnoOdigrano2: 0,
    poceo2: 0,
    poeni2: 0,
    slobodnaBacanja2: 0,
    sutZaDva2: 0,
    sutZaTri2: 0,
    poeniIzPolja2: 0,
    skokoviPoUtakmici2: 0,
    odbranaSkokovi2: 0,
    napadiSkokovi2: 0,
    asistencija2: 0,
    ukradeneLopte2: 0,
    blokovi2: 0,
    izgubljeneLopte2: 0,
    licneGreske2: 0,
    dablDabl2: 0,
    triplDabl2: 0,
    goloviDesnomNogom2: 0,
    izboreniPenali2: 0,
    dodavanje2: 0,
    asistenicije2: 0,
    dodiri2: 0,
    kreiranjeVelikeSanse2: 0,
  });

  useEffect(() => {
    handleCalculate();
  }, [data, brojUtakmicaDomacin, brojUtakmicaGost]);

  const handleCalculate = () => {
    let obj = {
      minutaPoUtakmici: 0,
      poceo: 0,
      ukupnoOdigrano: 0,
      ukupnoOdigrano: 0,
      izgubljeneLopte: 0,
      poeni: 0,
      golovi: 0,
      sutzadva: 0,
      sutZaTri: 0,
      poeniIzPolja: 0,
      skokoviPoUtakmici: 0,
      odbranaSkokovi: 0,
      napadiSkokovi: 0,
      asistencija: 0,
      ukradeneLopte: 0,
      blokovi: 0,
      licneGreske: 0,
      dablDabl: 0,
      triplDabl: 0,
      goloviDesnomNogom: 0,
      izboreniPenali: 0,
      dodavanje: 0,
      asistenicije: 0,
      dodiri: 0,
      kreiranjeVelikeSanse: 0,
      ///////////////
      minutaPoUtakmici2: 0,
      poeni2: 0,
      ukupnoOdigrano2: 0,
      ukupnoOdigrano2: 0,
      izgubljeneLopte2: 0,
      poceo2: 0,
      slobodnaBacanja2: 0,
      sutZaDva2: 0,
      sutZaTri2: 0,
      poeniIzPolja2: 0,
      skokoviPoUtakmici2: 0,
      odbranaSkokovi2: 0,
      napadiSkokovi2: 0,
      asistencija2: 0,
      ukradeneLopte2: 0,
      blokovi2: 0,
      licneGreske2: 0,
      dablDabl2: 0,
      triplDabl2: 0,
      goloviDesnomNogom2: 0,
      izboreniPenali2: 0,
      dodavanje2: 0,
      asistenicije2: 0,
      dodiri2: 0,
      kreiranjeVelikeSanse2: 0,
    };
    if (brojUtakmicaDomacin && data.minutaPoUtakmici !== 0) {
      const keys = Object.keys(data);

      for (let key of keys) {
        if (data && data[key] && !key.includes(2)) {
          if (key === "ukupnoOdigrano") {
            obj = {
              ...obj,
              [key]: parseFloat(
                (data[key] / brojUtakmicaDomacin) * 100
              ).toFixed(2),
            };
          } else if (key === "minutaPoUtakmici") {
            obj = {
              ...obj,
              [key]: parseFloat(data[key] / 48).toFixed(2),
            };
          } else {
            obj = {
              ...obj,
              [key]: parseFloat(
                (data[key] / brojUtakmicaDomacin) * 100
              ).toFixed(2),
            };
          }
        }
      }
      setCalculatedData(obj);
    }

    if (brojUtakmicaGost && data.minutaPoUtakmici2 !== 0) {
      const keys = Object.keys(data);

      for (let key of keys) {
        if (data && data[key] && key.includes(2)) {
          if (key === "ukupnoOdigrano2") {
            obj = {
              ...obj,
              [key]: parseFloat((data[key] / brojUtakmicaGost) * 100).toFixed(
                2
              ),
            };
          } else if (key === "minutaPoUtakmici2") {
            obj = {
              ...obj,
              [key]: parseFloat(data[key] / 48).toFixed(2),
            };
          } else {
            obj = {
              ...obj,
              [key]: parseFloat((data[key] / brojUtakmicaGost) * 100).toFixed(
                2
              ),
            };
          }
        }
      }
      setCalculatedData(obj);
    }
  };

  return (
    <div className={classes.container}>
      <Grupa2
        imgText="POREĐENJE IGRAČA - KOŠARKA"
        imgBackgroundColorDark="#FF0000"
        imgBackgroundColorLight="#FF0000"
        imgFontColor="#000000"
        label1="ZEMLJA"
        label2="KLUB"
        label3="IGRAČ1"
        label4="KLUB"
        label5="ZEMLJA"
        label6="IGRAČ2"
      />
      <Grupa20
        imgBackgroundColorDark="#FF0000"
        imgBackgroundColorLight="#FF0000"
        imgFontColor="#000000"
        calculatorType={`Poredjenje igraca - kosarka`}
      />
      <img src={poredjenjeIgracaKosarkaDolnjaSlika} />
      <div className={classes.calculator}>
        <div className={classes.row}>
          <div className={classes.col1}></div>
          <div className={classes.col2}>
            <input
              style={{
                border: "none",
                width: "60px",
                fontWeight: "bold",
                fontSize: "20px",
                marginTop: "2px",
                textAlign: "center",
              }}
              type="number"
              min="0"
              onChange={(e) => {
                setBrojUtakmicaDomacin(e.target.value);
              }}
            />
          </div>
          <div className={classes.col3}>UTAKMICE</div>
          <div className={classes.col4}>
            <input
              style={{
                border: "none",
                width: "60px",
                fontWeight: "bold",
                fontSize: "20px",
                marginTop: "2px",
                textAlign: "center",
              }}
              onChange={(e) => {
                setBrojUtakmicaGost(e.target.value);
              }}
              type="number"
              min="0"
            />
          </div>
          <div className={classes.col5}></div>
        </div>

        <div className={classes.row}>
          <div className={classes.col1}>{calculatedData.ukupnoOdigrano} %</div>
          <div className={classes.col2}>
            <input
              style={{
                border: "none",
                width: "60px",
                fontWeight: "bold",
                fontSize: "20px",
                marginTop: "2px",
                textAlign: "center",
              }}
              onChange={(e) => {
                setData({ ...data, ukupnoOdigrano: e.target.value });
              }}
              type="number"
              min="0"
            />
          </div>
          <div className={classes.col3}>Odigrano</div>
          <div className={classes.col4}>
            <input
              style={{
                border: "none",
                width: "60px",
                fontWeight: "bold",
                fontSize: "20px",
                marginTop: "2px",
                textAlign: "center",
              }}
              onChange={(e) => {
                setData({ ...data, ukupnoOdigrano2: e.target.value });
              }}
              type="number"
              min="0"
            />
          </div>
          <div className={classes.col5}>{calculatedData.ukupnoOdigrano2} %</div>
        </div>

        <div className={classes.row}>
          <div className={classes.col1}>
            {(calculatedData.minutaPoUtakmici / 48).toFixed(2)} %
          </div>
          <div className={classes.col2}>
            <input
              style={{
                border: "none",
                width: "60px",
                fontWeight: "bold",
                fontSize: "20px",
                marginTop: "2px",
                textAlign: "center",
              }}
              onChange={(e) => {
                setData({ ...data, minutaPoUtakmici: e.target.value });
              }}
              type="number"
              min="0"
            />
          </div>
          <div className={classes.col3}>Minuta po utakmici</div>
          <div className={classes.col4}>
            <input
              style={{
                border: "none",
                width: "60px",
                fontWeight: "bold",
                fontSize: "20px",
                marginTop: "2px",
                textAlign: "center",
              }}
              onChange={(e) => {
                setData({ ...data, minutaPoUtakmici2: e.target.value });
              }}
              type="number"
              min="0"
            />
          </div>
          <div className={classes.col5}>
            {(calculatedData.minutaPoUtakmici2 / 48).toFixed(2)} %
          </div>
        </div>

        <div className={classes.row}>
          <div className={classes.col1}>{calculatedData.poeni} %</div>
          <div className={classes.col2}>
            <input
              style={{
                border: "none",
                width: "60px",
                fontWeight: "bold",
                fontSize: "20px",
                marginTop: "2px",
                textAlign: "center",
              }}
              onChange={(e) => {
                setData({ ...data, poeni: e.target.value });
              }}
              type="number"
              min="0"
            />
          </div>
          <div className={classes.col3}>Poeni</div>
          <div className={classes.col4}>
            <input
              style={{
                border: "none",
                width: "60px",
                fontWeight: "bold",
                fontSize: "20px",
                marginTop: "2px",
                textAlign: "center",
              }}
              onChange={(e) => {
                setData({ ...data, poeni2: e.target.value });
              }}
              type="number"
              min="0"
            />
          </div>
          <div className={classes.col5}>{calculatedData.poeni2} %</div>
        </div>
        <div className={classes.row}>
          <div className={classes.col1}>{calculatedData.slobodnaBacanja} %</div>
          <div className={classes.col2}>
            <input
              style={{
                border: "none",
                width: "60px",
                fontWeight: "bold",
                fontSize: "20px",
                marginTop: "2px",
                textAlign: "center",
              }}
              onChange={(e) => {
                setData({ ...data, slobodnaBacanja: e.target.value });
              }}
              type="number"
              min="0"
            />
          </div>
          <div className={classes.col3}>Slobodna bacanja</div>
          <div className={classes.col4}>
            <input
              style={{
                border: "none",
                width: "60px",
                fontWeight: "bold",
                fontSize: "20px",
                marginTop: "2px",
                textAlign: "center",
              }}
              onChange={(e) => {
                setData({ ...data, slobodnaBacanja2: e.target.value });
              }}
              type="number"
              min="0"
            />
          </div>
          <div className={classes.col5}>
            {calculatedData.slobodnaBacanja2} %
          </div>
        </div>
        <div className={classes.row}>
          <div className={classes.col1}> {calculatedData.sutzadva} %</div>
          <div className={classes.col2}>
            <input
              style={{
                border: "none",
                width: "60px",
                fontWeight: "bold",
                fontSize: "20px",
                marginTop: "2px",
                textAlign: "center",
              }}
              onChange={(e) => {
                setData({ ...data, sutzadva: e.target.value });
              }}
              type="number"
              min="0"
            />
          </div>
          <div className={classes.col3}>Šut za 2</div>
          <div className={classes.col4}>
            <input
              style={{
                border: "none",
                width: "60px",
                fontWeight: "bold",
                fontSize: "20px",
                marginTop: "2px",
                textAlign: "center",
              }}
              onChange={(e) => {
                setData({ ...data, sutZaDva2: e.target.value });
              }}
              type="number"
              min="0"
            />
          </div>
          <div className={classes.col5}>{calculatedData.sutZaDva2} %</div>
        </div>
        <div className={classes.row}>
          <div className={classes.col1}>{calculatedData.sutZaTri} %</div>
          <div className={classes.col2}>
            <input
              style={{
                border: "none",
                width: "60px",
                fontWeight: "bold",
                fontSize: "20px",
                marginTop: "2px",
                textAlign: "center",
              }}
              onChange={(e) => {
                setData({ ...data, sutZaTri: e.target.value });
              }}
              type="number"
              min="0"
            />
          </div>
          <div className={classes.col3}>Sut za 3</div>
          <div className={classes.col4}>
            <input
              style={{
                border: "none",
                width: "60px",
                fontWeight: "bold",
                fontSize: "20px",
                marginTop: "2px",
                textAlign: "center",
              }}
              onChange={(e) => {
                setData({ ...data, sutZaTri2: e.target.value });
              }}
              type="number"
              min="0"
            />
          </div>
          <div className={classes.col5}>{calculatedData.sutZaTri2} %</div>
        </div>
        <div className={classes.row}>
          <div className={classes.col1}>{calculatedData.poeniIzPolja} %</div>
          <div className={classes.col2}>
            <input
              style={{
                border: "none",
                width: "60px",
                fontWeight: "bold",
                fontSize: "20px",
                marginTop: "2px",
                textAlign: "center",
              }}
              onChange={(e) => {
                setData({ ...data, poeniIzPolja: e.target.value });
              }}
              type="number"
              min="0"
            />
          </div>
          <div className={classes.col3}>Poeni iz polja</div>
          <div className={classes.col4}>
            <input
              style={{
                border: "none",
                width: "60px",
                fontWeight: "bold",
                fontSize: "20px",
                marginTop: "2px",
                textAlign: "center",
              }}
              onChange={(e) => {
                setData({ ...data, poeniIzPolja2: e.target.value });
              }}
              type="number"
              min="0"
            />
          </div>
          <div className={classes.col5}>{calculatedData.poeniIzPolja2} %</div>
        </div>
        <div className={classes.row}>
          <div className={classes.col1}>
            {" "}
            {calculatedData.skokoviPoUtakmici} %
          </div>
          <div className={classes.col2}>
            <input
              style={{
                border: "none",
                width: "60px",
                fontWeight: "bold",
                fontSize: "20px",
                marginTop: "2px",
                textAlign: "center",
              }}
              onChange={(e) => {
                setData({ ...data, skokoviPoUtakmici: e.target.value });
              }}
              type="number"
              min="0"
            />
          </div>
          <div className={classes.col3}>Skokovi po utakmici</div>
          <div className={classes.col4}>
            <input
              style={{
                border: "none",
                width: "60px",
                fontWeight: "bold",
                fontSize: "20px",
                marginTop: "2px",
                textAlign: "center",
              }}
              onChange={(e) => {
                setData({ ...data, skokoviPoUtakmici2: e.target.value });
              }}
              type="number"
              min="0"
            />
          </div>
          <div className={classes.col5}>
            {calculatedData.skokoviPoUtakmici2} %
          </div>
        </div>
        <div className={classes.row}>
          <div className={classes.col1}>{calculatedData.odbranaSkokovi} %</div>
          <div className={classes.col2}>
            <input
              style={{
                border: "none",
                width: "60px",
                fontWeight: "bold",
                fontSize: "20px",
                marginTop: "2px",
                textAlign: "center",
              }}
              onChange={(e) => {
                setData({
                  ...data,
                  odbranaSkokovi: e.target.value,
                });
              }}
              type="number"
              min="0"
            />
          </div>
          <div className={classes.col3}>Odbrana skokovi</div>
          <div className={classes.col4}>
            <input
              style={{
                border: "none",
                width: "60px",
                fontWeight: "bold",
                fontSize: "20px",
                marginTop: "2px",
                textAlign: "center",
              }}
              onChange={(e) => {
                setData({
                  ...data,
                  odbranaSkokovi2: e.target.value,
                });
              }}
              type="number"
              min="0"
            />
          </div>
          <div className={classes.col5}>{calculatedData.odbranaSkokovi2} %</div>
        </div>
        <div className={classes.row}>
          <div className={classes.col1}>{calculatedData.napadiSkokovi} %</div>
          <div className={classes.col2}>
            <input
              style={{
                border: "none",
                width: "60px",
                fontWeight: "bold",
                fontSize: "20px",
                marginTop: "2px",
                textAlign: "center",
              }}
              onChange={(e) => {
                setData({ ...data, napadiSkokovi: e.target.value });
              }}
              type="number"
              min="0"
            />
          </div>
          <div className={classes.col3}>Napadi skokovi</div>
          <div className={classes.col4}>
            <input
              style={{
                border: "none",
                width: "60px",
                fontWeight: "bold",
                fontSize: "20px",
                marginTop: "2px",
                textAlign: "center",
              }}
              onChange={(e) => {
                setData({ ...data, napadiSkokovi2: e.target.value });
              }}
              type="number"
              min="0"
            />
          </div>
          <div className={classes.col5}>{calculatedData.napadiSkokovi2} %</div>
        </div>
        <div className={classes.row}>
          <div className={classes.col1}>{calculatedData.asistencija} %</div>
          <div className={classes.col2}>
            <input
              style={{
                border: "none",
                width: "60px",
                fontWeight: "bold",
                fontSize: "20px",
                marginTop: "2px",
                textAlign: "center",
              }}
              onChange={(e) => {
                setData({ ...data, asistencija: e.target.value });
              }}
              type="number"
              min="0"
            />
          </div>
          <div className={classes.col3}>Asistencije</div>
          <div className={classes.col4}>
            <input
              style={{
                border: "none",
                width: "60px",
                fontWeight: "bold",
                fontSize: "20px",
                marginTop: "2px",
                textAlign: "center",
              }}
              onChange={(e) => {
                setData({ ...data, asistencija2: e.target.value });
              }}
              type="number"
              min="0"
            />
          </div>
          <div className={classes.col5}>{calculatedData.asistencija2} %</div>
        </div>

        <div className={classes.row}>
          <div className={classes.col1}>{calculatedData.izgubljeneLopte} %</div>
          <div className={classes.col2}>
            <input
              style={{
                border: "none",
                width: "60px",
                fontWeight: "bold",
                fontSize: "20px",
                marginTop: "2px",
                textAlign: "center",
              }}
              onChange={(e) => {
                setData({ ...data, izgubljeneLopte: e.target.value });
              }}
              type="number"
              min="0"
            />
          </div>
          <div className={classes.col3}>Izgubljene lopte</div>
          <div className={classes.col4}>
            <input
              style={{
                border: "none",
                width: "60px",
                fontWeight: "bold",
                fontSize: "20px",
                marginTop: "2px",
                textAlign: "center",
              }}
              onChange={(e) => {
                setData({ ...data, izgubljeneLopte2: e.target.value });
              }}
              type="number"
              min="0"
            />
          </div>
          <div className={classes.col5}>
            {calculatedData.izgubljeneLopte2} %
          </div>
        </div>

        <div className={classes.row}>
          <div className={classes.col1}>{calculatedData.ukradeneLopte} %</div>
          <div className={classes.col2}>
            <input
              style={{
                border: "none",
                width: "60px",
                fontWeight: "bold",
                fontSize: "20px",
                marginTop: "2px",
                textAlign: "center",
              }}
              onChange={(e) => {
                setData({ ...data, ukradeneLopte: e.target.value });
              }}
              type="number"
              min="0"
            />
          </div>
          <div className={classes.col3}>Ukradene lopte</div>
          <div className={classes.col4}>
            <input
              style={{
                border: "none",
                width: "60px",
                fontWeight: "bold",
                fontSize: "20px",
                marginTop: "2px",
                textAlign: "center",
              }}
              onChange={(e) => {
                setData({ ...data, ukradeneLopte2: e.target.value });
              }}
              type="number"
              min="0"
            />
          </div>
          <div className={classes.col5}>{calculatedData.ukradeneLopte2} %</div>
        </div>
        <div className={classes.row}>
          <div className={classes.col1}>{calculatedData.blokovi} %</div>
          <div className={classes.col2}>
            <input
              style={{
                border: "none",
                width: "60px",
                fontWeight: "bold",
                fontSize: "20px",
                marginTop: "2px",
                textAlign: "center",
              }}
              onChange={(e) => {
                setData({ ...data, blokovi: e.target.value });
              }}
              type="number"
              min="0"
            />
          </div>
          <div className={classes.col3}>Blokovi</div>
          <div className={classes.col4}>
            <input
              style={{
                border: "none",
                width: "60px",
                fontWeight: "bold",
                fontSize: "20px",
                marginTop: "2px",
                textAlign: "center",
              }}
              onChange={(e) => {
                setData({ ...data, blokovi2: e.target.value });
              }}
              type="number"
              min="0"
            />
          </div>
          <div className={classes.col5}>{calculatedData.blokovi2} %</div>
        </div>
        <div className={classes.row}>
          <div className={classes.col1}>{calculatedData.licneGreske} %</div>
          <div className={classes.col2}>
            <input
              style={{
                border: "none",
                width: "60px",
                fontWeight: "bold",
                fontSize: "20px",
                marginTop: "2px",
                textAlign: "center",
              }}
              onChange={(e) => {
                setData({ ...data, licneGreske: e.target.value });
              }}
              type="number"
              min="0"
            />
          </div>
          <div className={classes.col3}>Lične greške</div>
          <div className={classes.col4}>
            <input
              style={{
                border: "none",
                width: "60px",
                fontWeight: "bold",
                fontSize: "20px",
                marginTop: "2px",
                textAlign: "center",
              }}
              onChange={(e) => {
                setData({ ...data, licneGreske2: e.target.value });
              }}
              type="number"
              min="0"
            />
          </div>
          <div className={classes.col5}>{calculatedData.licneGreske2} %</div>
        </div>
        <div className={classes.row}>
          <div className={classes.col1}>{calculatedData.dablDabl} %</div>
          <div className={classes.col2}>
            <input
              style={{
                border: "none",
                width: "60px",
                fontWeight: "bold",
                fontSize: "20px",
                marginTop: "2px",
                textAlign: "center",
              }}
              onChange={(e) => {
                setData({ ...data, dablDabl: e.target.value });
              }}
              type="number"
              min="0"
            />
          </div>
          <div className={classes.col3}>Dabl Dabl</div>
          <div className={classes.col4}>
            <input
              style={{
                border: "none",
                width: "60px",
                fontWeight: "bold",
                fontSize: "20px",
                marginTop: "2px",
                textAlign: "center",
              }}
              onChange={(e) => {
                setData({ ...data, dablDabl2: e.target.value });
              }}
              type="number"
              min="0"
            />
          </div>
          <div className={classes.col5}>{calculatedData.dablDabl2} %</div>
        </div>

        <div className={classes.row}>
          <div className={classes.col1}>{calculatedData.triplDabl} %</div>
          <div className={classes.col2}>
            <input
              style={{
                border: "none",
                width: "60px",
                fontWeight: "bold",
                fontSize: "20px",
                marginTop: "2px",
                textAlign: "center",
              }}
              onChange={(e) => {
                setData({ ...data, triplDabl: e.target.value });
              }}
              type="number"
              min="0"
            />
          </div>
          <div className={classes.col3}>Tripl Dabl</div>
          <div className={classes.col4}>
            <input
              style={{
                border: "none",
                width: "60px",
                fontWeight: "bold",
                fontSize: "20px",
                marginTop: "2px",
                textAlign: "center",
              }}
              onChange={(e) => {
                setData({ ...data, triplDabl2: e.target.value });
              }}
              type="number"
              min="0"
            />
          </div>
          <div className={classes.col5}>{calculatedData.triplDabl2} %</div>
        </div>
      </div>
    </div>
  );
};

export default PoredenjeIgracaKosarka;
