import classes from "./grupa300.module.css";
import { useEffect } from "react";

const Grupa300 = ({
  kvotaNaKonacanIshod1,
  kvotaNaKonacanIshod2,
  kvotaNaKonacanIshodX,
  domacinDao,
  domacinPrimio,
  brojUtakmicaDomacin,
  brojUtakmicaGost,
  gostDao,
  gostPrimio,
  setKvotaNaKonacanIshod1,
  setKvotaNaKonacanIshod2,
  setKvotaNaKonacanIshodX,
  setDomacinDao,
  setDomacinPrimio,
  setBrojUtakmicaDomacin,
  setBrojUtakmicaGost,
  setGostDao,
  setGostPrimio,
  setSOG,
  setSNG,
  setSODG,
  setSODSNG,
  setSND,
  setSOD,
  setSNDG,
  setSNDSOG,
  pobedaDomacina,
  setPobedaDomacina,
  pobedaGosta,
  setPobedaGosta,
  nereseno,
  setNereseno,
  golMin,
  setGolMin,
  golMax,
  setGolMax,
  golBalans,
  setGolBalans,
  tacanRezultatDomacin,
  setTacanRezultatDomacin,
  tacanRezultatGost,
  setTacanRezultatGost,
  pobedaSaRazlikom,
  setPobedaSaRazlikomZagrada,
  setPobedaSaRazlikom,
  betkoPredlog1,
  setBetkoPredlog1,
  betkoPredlog2,
  setBetkoPredlog2,
}) => {
  const handleChangePobedaDomacina = (e) => {
    setKvotaNaKonacanIshod1(e);
  };

  const handleChangeNereseno = (e) => {
    setKvotaNaKonacanIshodX(e);
  };

  const handleChangePobedaGosta = (e) => {
    setKvotaNaKonacanIshod2(e);
  };

  const handleChangeDomacinDao = (e) => {
    setDomacinDao(e);
  };

  const handleChangeDomacinPrimio = (e) => {
    setDomacinPrimio(e);
  };

  const handleChangeDomacinBrojUtakmica = (e) => {
    setBrojUtakmicaDomacin(e);
  };

  const handleChangeGostDao = (e) => {
    setGostDao(e);
  };

  const handleChangeGostPrimio = (e) => {
    setGostPrimio(e);
  };

  const handleChangeGostBrojUtakmica = (e) => {
    setBrojUtakmicaGost(e);
  };

  const handleCalculate = () => {
    if (
      (kvotaNaKonacanIshod1,
      kvotaNaKonacanIshod2,
      kvotaNaKonacanIshodX,
      domacinDao,
      domacinPrimio,
      brojUtakmicaDomacin,
      gostDao,
      gostPrimio,
      brojUtakmicaGost)
    ) {
      /////////// ZAGLAVLJE //////////////////

      let snd = domacinDao / brojUtakmicaDomacin;

      setSND(snd.toFixed(2));

      let sod = domacinPrimio / brojUtakmicaDomacin;

      setSOD(sod.toFixed(2));

      let sog =  gostDao / brojUtakmicaGost;

      setSOG(sog.toFixed(2));

      let sng =gostPrimio / brojUtakmicaGost;

      setSNG(sng.toFixed(2));

      let sodg = (sod + sog) / 2;

      setSODG(sodg.toFixed(2));

      let sndg = (snd + sng) / 2;

      setSNDG(sndg.toFixed(2));

      let sndsog = (snd + sog) / 2;

      setSNDSOG(sndsog.toFixed(2));

      let sodsng = (sod + sng) / 2;

      setSODSNG(sodsng.toFixed(2));
      /////////////////////////////

      let h63 = parseFloat(kvotaNaKonacanIshod1);

      let k63 = (1 / h63) * 100;

      let i63 = parseFloat(kvotaNaKonacanIshodX);

      let j63 = parseFloat(kvotaNaKonacanIshod2);

      let m63 = (1 / j63) * 100;

      let l63 = (1 / i63) * 100;

      let n63 = k63 + l63 + m63 - 100;

      let o63 = k63 - n63 / 3;

      let r63 = (o63 * n63) / 100;

      let procenatPobede1 = parseFloat(k63 - r63);

      let q63 = m63 - n63 / 3;

      let t63 = (q63 * n63) / 100;

      let procenatPobede2 = parseFloat(m63 - t63);

      let p63 = l63 - n63 / 3;

      let s63 = (p63 * n63) / 100;

      let nereseno = parseFloat(l63 - s63);

      setPobedaDomacina(procenatPobede1.toFixed(2));

      setPobedaGosta(procenatPobede2.toFixed(2));

      setNereseno(nereseno.toFixed(2));

      let n67 = parseFloat(domacinDao);

      let o67 = parseFloat(domacinPrimio);

      let o69 = parseFloat(brojUtakmicaDomacin);

      let p67 = parseFloat(gostPrimio);

      let q67 = parseFloat(gostDao);

      let q69 = parseFloat(brojUtakmicaGost);

      let l60 = l63 - s63;

      let q71 = (p67 + q67) / q69;

      let o71 = (n67 + o67) / o69;

      let o73 = (o71 + q71) / 2;

      let p60 = o73 - o73 * 0.0312;

      let golMin = o73 - (l60 / 100) * p60;

      setGolMin(golMin.toFixed(2));

      let n60 = o73 - (l60 / 100) * p60;

      let q59 = p60 - n60 + p60;

      let golMax = q59 + q59 * 0.0123;

      setGolMax(golMax.toFixed(2));

      let golBalans = p60;

      setGolBalans(golBalans.toFixed(2));

      let ag60 = procenatPobede1;

      let ah60 = nereseno;

      let aq60 = p60;

      let ar60 = ag60 + ah60 / 2;

      let at60 = (aq60 * ar60) / 100;

      let bf60;

      let k60 = procenatPobede1;

      let m60 = procenatPobede2;

      let bd60 = p60 / 2;

      let ai60 = procenatPobede2;
      let as60 = ai60 + ah60 / 2;
      let au60 = (aq60 * as60) / 100;

      let av60 = Math.round(at60);

      let ax60 = Math.round(au60);

      let max;
      let min;

      if (av60 > ax60) {
        max = av60;
        min = ax60;
      } else {
        max = ax60;
        min = av60;
      }

      let be60 = (max - min) / 5;

      let bg60;

      if (bd60 > bf60) {
        bg60 = bd60 + be60 / 2;
      } else {
        bg60 = bd60 - be60 / 2;
      }

      let u59 = (au60 + bg60) / 2;

      if (k60 >= m60) {
        bf60 = bd60 + be60 / 2;
      } else {
        bf60 = bd60 - be60 / 2;
      }

      let t59 = (at60 + bf60) / 2;

      let tacanrez1 = Math.round(t59);

      let tacanrez2 = Math.round(u59);

      setTacanRezultatDomacin(tacanrez1);
      setTacanRezultatGost(tacanrez2);

      let r59 = (at60 + bf60) / 2 - (au60 + bg60) / 2;

      let pobedaSaRazlikom = r59;

      setPobedaSaRazlikom(pobedaSaRazlikom.toFixed(2));

      let ay60;

      if (av60 > ax60) {
        ay60 = "1";
      } else {
        if (av60 === ax60) {
          ay60 = "X";
        } else {
          ay60 = "2";
        }
      }

      setPobedaSaRazlikomZagrada(ay60);

      let betkoPredlog1 = n60;

      setBetkoPredlog1(betkoPredlog1.toFixed(2));

      let o60 = (n60 + p60) / 2;

      let betkoPredlog2 = o60;

      setBetkoPredlog2(betkoPredlog2.toFixed(2));
    }
  };

  useEffect(() => {
    handleCalculate();
  }, [
    kvotaNaKonacanIshod1,
    kvotaNaKonacanIshod2,
    kvotaNaKonacanIshodX,
    domacinDao,
    domacinPrimio,
    brojUtakmicaDomacin,
    gostDao,
    gostPrimio,
    brojUtakmicaGost,
  ]);

  return (
    <div className={classes.container}>
      <input
        onChange={(e) => {
          handleChangePobedaDomacina(e.target.value);
        }}
        className={classes.blackCenteredInput}
        placeholder="pobeda domaćina"
        type="number"
        min="0"
      />
      <input
        onChange={(e) => {
          handleChangeNereseno(e.target.value);
        }}
        className={classes.blackCenteredInput}
        placeholder="nerešeno"
        type="number"
        min="0"
      />
      <input
        onChange={(e) => {
          handleChangePobedaGosta(e.target.value);
        }}
        className={classes.blackCenteredInput}
        placeholder="pobeda gosta"
        type="number"
        min="0"
      />
      <input
        onChange={(e) => {
          handleChangeDomacinDao(e.target.value);
        }}
        className={classes.blackCenteredInput}
        placeholder="domaćin + (dao)"
        type="number"
        min="0"
      />
      <input
        onChange={(e) => {
          handleChangeDomacinPrimio(e.target.value);
        }}
        className={classes.blackCenteredInput}
        placeholder="domaćin - (primio)"
        type="number"
        min="0"
      />
      <input
        onChange={(e) => {
          handleChangeDomacinBrojUtakmica(e.target.value);
        }}
        className={classes.blackCenteredInput}
        placeholder="domaćin broj utak."
        type="number"
        min="0"
      />
      <input
        onChange={(e) => {
          handleChangeGostDao(e.target.value);
        }}
        className={classes.blackCenteredInput}
        placeholder="gost + (dao)"
        type="number"
        min="0"
      />
      <input
        onChange={(e) => {
          handleChangeGostPrimio(e.target.value);
        }}
        className={classes.blackCenteredInput}
        placeholder="gost - (primio)"
        type="number"
        min="0"
      />
      <input
        onChange={(e) => {
          handleChangeGostBrojUtakmica(e.target.value);
        }}
        className={classes.blackCenteredInput}
        placeholder="gost broj utak."
        type="number"
        min="0"
      />
    </div>
  );
};

export default Grupa300;
