import Grupa1 from "../components/Grupa1";
import Grupa28 from "../components/Grupa28";
import Grupa3008 from "../components/Grupa3008";
import classes from "./liveInteraktiv.module.css";
import { useState, useEffect } from "react";

const LiveInteraktiv = () => {
  //////zaglavlje////////
  const [snd, setSND] = useState("-");
  const [sod, setSOD] = useState("-");
  const [sog, setSOG] = useState("-");
  const [sng, setSNG] = useState("-");
  const [sodg, setSODG] = useState("-");
  const [sndg, setSNDG] = useState("-");
  const [sndSog, setSNDSOG] = useState("-");
  const [sodSng, setSODSNG] = useState("-");
  /////input polja////
  const [kvotaNaKonacanIshod1, setKvotaNaKonacanIshod1] = useState(0);
  const [kvotaNaKonacanIshodX, setKvotaNaKonacanIshodX] = useState(0);
  const [kvotaNaKonacanIshod2, setKvotaNaKonacanIshod2] = useState(0);
  const [domacinDao, setDomacinDao] = useState(0);
  const [domacinPrimio, setDomacinPrimio] = useState(0);
  const [brojUtakmicaDomacin, setBrojUtakmicaDomacin] = useState(0);
  const [brojUtakmicaGost, setBrojUtakmicaGost] = useState(0);
  const [gostDao, setGostDao] = useState(0);
  const [gostPrimio, setGostPrimio] = useState(0);
  //// input polja u formi
  const [trenutniMinutUtakmice, setTrenutniMinutUtakmice] = useState(0);
  const [rezPrvogPoluvremenaDomacin, setRezPrvogPoluvremenaDomacin] =
    useState(0);
  const [rezPrvogPoluvremenaGost, setRezPrvogPoluvremenaGost] = useState(0);
  const [kvotaUG02, setKvotaUG02] = useState(0);
  const [kvotaUG30, setKvotaUG30] = useState(0);
  const [kvotaGG, setKvotaGG] = useState(0);
  const [kvotaNEGG, setKvotaNEGG] = useState(0);

  //////////////////
  const [pobedaDomacina, setPobedaDomacina] = useState(0);
  const [pobedaGosta, setPobedaGosta] = useState(0);
  const [nereseno, setNereseno] = useState(0);

  ////useless////
  const [golMin, setGolMin] = useState(0);
  const [golMax, setGolMax] = useState(0);
  const [golBalans, setGolBalans] = useState(0);
  const [tacanRezultatDomacin, setTacanRezultatDomacin] = useState(0);
  const [tacanRezultatGost, setTacanRezultatGost] = useState(0);
  const [pobedaSaRazlikom, setPobedaSaRazlikom] = useState(0);
  const [betkoPredlog1, setBetkoPredlog1] = useState(0);
  const [betkoPredlog2, setBetkoPredlog2] = useState(0);
  const [pobedaSaRazlikomZagrada, setPobedaSaRazlikomZagrada] = useState(0);
  ///FORMA/////
  const [minOcekivaniBrGolovaDoKraja, setMinOcekivaniBrojGolovaDoKraja] =
    useState(0);
  const [srdOcekivaniBrGolovaDoKraja, setSrdOcekivaniBrojGolovaDoKraja] =
    useState(0);
  const [maxOcekivaniBrGolovaDoKraja, setMaxOcekivaniBrojGolovaDoKraja] =
    useState(0);
  const [minTacanRezultat, setMinTacanRezultat] = useState(["-", "-", "-"]);
  const [maxTacanRezultat, setMaxTacanRezultat] = useState(["-", "-", "-"]);
  const [srdTacanRezultat, setSrdTacanRezultat] = useState(["-", "-", "-"]);

  useEffect(() => {
    handleCalculate();
  }, [
    trenutniMinutUtakmice,
    rezPrvogPoluvremenaDomacin,
    rezPrvogPoluvremenaGost,
    kvotaUG02,
    kvotaUG30,
    kvotaGG,
    kvotaNEGG,
    pobedaDomacina,
    nereseno,
    pobedaGosta,
    domacinDao,
    domacinPrimio,
    brojUtakmicaDomacin,
    gostDao,
    gostPrimio,
    brojUtakmicaGost,
  ]);

  const handleCalculate = () => {
    let b43 = parseFloat(rezPrvogPoluvremenaDomacin);
    let d43 = parseFloat(rezPrvogPoluvremenaGost);
    let i66 = parseFloat(kvotaNaKonacanIshodX);
    let l66 = (1 / i66) * 100;
    let j66 = parseFloat(kvotaNaKonacanIshod2);
    let m66 = (1 / j66) * 100;
    let h66 = parseFloat(kvotaNaKonacanIshod1);
    let k66 = (1 / h66) * 100;

    let n66 = k66 + l66 + m66 - 100;

    let p66 = l66 - n66 / 3;
    let s66 = (p66 * n66) / 100;
    let v66 = l66 - s66;

    let c78 = v66;

    let o66 = k66 - n66 / 3;
    let r66 = (o66 * n66) / 100;

    let b78 = k66 - r66;
    let g78 = (b78 + 79.21) / 2;
    let h78 = (b78 + 14.18) / 2;
    let i78 = (b78 + 6.61) / 2;

    let ab78 = (g78 + h78 + i78 - 100) / 3;

    let p78;
    if (ab78 >= 0) {
      p78 = ab78;
    } else {
      p78 = 100 - (g78 + h78 + i78) / 3;
    }
    let s78;
    if (p78 > 0) {
      s78 = g78 - p78;
    } else {
      s78 = g78 + p78;
    }

    let ae78 = Math.round(s78 * 100) / 100;

    ae78 = ae78 * 1.001;

    let q78;

    let q66 = m66 - n66 / 3;

    let t66 = (q66 * n66) / 100;

    let w66 = m66 - t66;

    let d78 = w66;

    let j78 = (b78 + 36.42) / 2;
    let k78 = (c78 + 35.6) / 2;
    let l78 = (d78 + 27.98) / 2;

    let ac78 = (j78 + k78 + l78 - 100) / 3;

    if (ac78 >= 0) {
      q78 = ac78;
    } else {
      q78 = 100 - (j78 + k78 + l78) / 3;
    }

    let v78;

    if (q78 > 0) {
      v78 = j78 - q78;
    } else {
      v78 = j78 + q78;
    }

    let m78 = (b78 + 10.7) / 2;
    let n78 = (c78 + 18.91) / 2;
    let o78 = (d78 + 70.4) / 2;

    let ad78 = (m78 + n78 + o78 - 100) / 3;

    let r78;

    if (ad78 >= 0) {
      r78 = ad78;
    } else {
      r78 = 100 - (m78 + n78 + o78) / 3;
    }

    let y78;

    if (r78 > 0) {
      y78 = m78 - r78;
    } else {
      y78 = m78 + r78;
    }

    let ak78 = y78 + 0.1;

    let ah78 = v78 + 0.1;

    let ae66;

    if (b43 > d43) {
      ae66 = ae78;
    } else if ((b43 = d43)) {
      ae66 = ah78;
    } else {
      ae66 = ak78;
    }
    let pobedadomacina = ae66;
    if (isNaN(pobedadomacina)) {
      setPobedaDomacina(0);
    } else {
      setPobedaDomacina(pobedadomacina.toFixed(2));
    }

    let u78;

    if (p78 > 0) {
      u78 = i78 - p78;
    } else {
      u78 = i78 + p78;
    }

    let x78;

    if (q78 > 0) {
      x78 = l78 - q78;
    } else {
      x78 = l78 + q78;
    }

    let aa78;

    if (r78 > 0) {
      aa78 = o78 - r78;
    } else {
      aa78 = o78 + r78;
    }

    let ag78 = u78 + 0.1;

    let aj78 = x78 + 0.1;

    let am78 = aa78 + 0.1;

    let ae68;

    if (b43 > d43) {
      ae68 = ag78;
    } else if ((b43 = d43)) {
      ae68 = aj78;
    } else {
      ae68 = am78;
    }

    let pobedagosta = ae68;

    if (isNaN(pobedagosta)) {
      setPobedaGosta(0);
    } else {
      setPobedaGosta(pobedagosta.toFixed(2));
    }

    let ae67 = 100 - ae66 - ae68;

    let neresenishod = ae67;

    if (isNaN(neresenishod)) {
      setNereseno(0);
    } else {
      setNereseno(neresenishod.toFixed(2));
    }

    let b32 = parseFloat(trenutniMinutUtakmice);

    let t33 = parseFloat(kvotaUG30);
    let u92 = t33;

    let w92 = (1 / u92) * 100;

    let r33 = parseFloat(kvotaUG02);

    let t92 = r33;

    let v92 = (1 / t92) * 100;

    let x92 = v92 + w92;

    let y92 = x92 - 100;

    let z92 = y92 / 2;

    let n27 = parseFloat(domacinDao);

    let p27 = parseFloat(domacinPrimio);

    let o90 = p27;

    let n90 = n27;

    let o94 = (n90 + o90) / o90;

    let r27 = parseFloat(gostPrimio);

    let p90 = r27;

    let t27 = parseFloat(gostDao);

    let q90 = t27;

    let s29 = parseFloat(brojUtakmicaGost);

    let q92 = s29;

    let q94 = (p90 + q90) / q92;

    let o96 = (o94 + q94) / 2;

    let p84 = o96;

    let ae92 = p84;

    let ab92 = w92 - z92;

    let ad92;

    if (ab92 < 0) {
      ad92 = 0;
    } else {
      ad92 = ab92;
    }

    let af92 = (ad92 * 5) / 100;

    let ag92 = (ae92 + af92) / 2;

    let b104 = ag92 / 90;

    let e102 = p84;

    let b108 = e102 * 0.55;
    let c108 = b108 - b104;
    let d108 = c108 - b104;
    let e108 = d108 - b104;
    let f108 = e108 - b104;
    let g108 = f108 - b104;
    let h108 = g108 - b104;
    let i108 = h108 - b104;
    let j108 = i108 - b104;
    let k108 = j108 - b104;
    let l108 = k108 - b104;
    let m108 = l108 - b104;
    let n108 = m108 - b104;
    let o108 = n108 - b104;
    let p108 = o108 - b104;
    let q108 = p108 - b104;
    let r108 = q108 - b104;
    let s108 = r108 - b104;
    let t108 = s108 - b104;
    let u108 = t108 - b104;
    let v108 = u108 - b104;
    let w108 = v108 - b104;
    let x108 = w108 - b104;
    let y108 = x108 - b104;
    let z108 = y108 - b104;
    let aa108 = z108 - b104;
    let ab108 = aa108 - b104;
    let ac108 = ab108 - b104;
    let ad108 = ac108 - b104;
    let ae108 = ad108 - b104;
    let af108 = ae108 - b104;
    let ag108 = af108 - b104;
    let ah108 = ag108 - b104;
    let ai108 = ah108 - b104;
    let aj108 = ai108 - b104;
    let ak108 = aj108 - b104;
    let al108 = ak108 - b104;
    let am108 = al108 - b104;
    let an108 = am108 - b104;
    let ao108 = an108 - b104;
    let ap108 = ao108 - b104;
    let aq108 = ap108 - b104;
    let ar108 = aq108 - b104;
    let as108 = ar108 - b104;
    let at108 = as108 - b104;

    let b110 = b32 === 46 ? e102 * 0.55 : 0;
    let c110 = b32 === 47 ? b108 - b104 : 0;
    let d110 = b32 === 48 ? c108 - b104 : 0;
    let e110 = b32 === 49 ? d108 - b104 : 0;
    let f110 = b32 === 50 ? e108 - b104 : 0;
    let g110 = b32 === 51 ? f108 - b104 : 0;
    let h110 = b32 === 52 ? g108 - b104 : 0;
    let i110 = b32 === 53 ? h108 - b104 : 0;
    let j110 = b32 === 54 ? i108 - b104 : 0;
    let k110 = b32 === 55 ? j108 - b104 : 0;
    let l110 = b32 === 56 ? k108 - b104 : 0;
    let m110 = b32 === 57 ? l108 - b104 : 0;
    let n110 = b32 === 58 ? m108 - b104 : 0;
    let o110 = b32 === 59 ? n108 - b104 : 0;
    let p110 = b32 === 60 ? o108 - b104 : 0;
    let q110 = b32 === 61 ? p108 - b104 : 0;
    let r110 = b32 === 62 ? q108 - b104 : 0;
    let s110 = b32 === 63 ? r108 - b104 : 0;
    let t110 = b32 === 64 ? s108 - b104 : 0;
    let u110 = b32 === 65 ? t108 - b104 : 0;
    let v110 = b32 === 66 ? u108 - b104 : 0;
    let w110 = b32 === 67 ? v108 - b104 : 0;
    let x110 = b32 === 68 ? w108 - b104 : 0;
    let y110 = b32 === 69 ? x108 - b104 : 0;
    let z110 = b32 === 70 ? y108 - b104 : 0;
    let aa110 = b32 === 71 ? z108 - b104 : 0;
    let ab110 = b32 === 72 ? aa108 - b104 : 0;
    let ac110 = b32 === 73 ? ab108 - b104 : 0;
    let ad110 = b32 === 74 ? ac108 - b104 : 0;
    let ae110 = b32 === 75 ? ad108 - b104 : 0;
    let af110 = b32 === 76 ? ae108 - b104 : 0;
    let ag110 = b32 === 77 ? af108 - b104 : 0;
    let ah110 = b32 === 78 ? ag108 - b104 : 0;
    let ai110 = b32 === 79 ? ah108 - b104 : 0;
    let aj110 = b32 === 80 ? ai108 - b104 : 0;
    let ak110 = b32 === 81 ? aj108 - b104 : 0;
    let al110 = b32 === 82 ? ak108 - b104 : 0;
    let am110 = b32 === 83 ? al108 - b104 : 0;
    let an110 = b32 === 84 ? am108 - b104 : 0;
    let ao110 = b32 === 85 ? an108 - b104 : 0;
    let ap110 = b32 === 86 ? ao108 - b104 : 0;
    let aq110 = b32 === 87 ? ap108 - b104 : 0;
    let ar110 = b32 === 88 ? aq108 - b104 : 0;
    let as110 = b32 === 89 ? ar108 - b104 : 0;
    let at110 = b32 === 90 ? as108 - b104 : 0;

    let au110 =
      b110 +
      c110 +
      d110 +
      e110 +
      f110 +
      g110 +
      h110 +
      i110 +
      j110 +
      k110 +
      l110 +
      m110 +
      n110 +
      o110 +
      p110 +
      q110 +
      r110 +
      s110 +
      t110 +
      u110 +
      v110 +
      w110 +
      x110 +
      y110 +
      z110 +
      aa110 +
      ab110 +
      ac110 +
      ad110 +
      ae110 +
      af110 +
      ag110 +
      ah110 +
      ai110 +
      aj110 +
      ak110 +
      al110 +
      am110 +
      an110 +
      ao110 +
      ap110 +
      aq110 +
      ar110 +
      as110 +
      at110;

    let srednjiocekivanbrojgolova = au110;

    if (isNaN(srednjiocekivanbrojgolova)) {
      setSrdOcekivaniBrojGolovaDoKraja(0);
    } else {
      setSrdOcekivaniBrojGolovaDoKraja(srednjiocekivanbrojgolova);
    }

    let minocekivanbrgolova = srednjiocekivanbrojgolova / 2;

    if (isNaN(minocekivanbrgolova)) {
      setMinOcekivaniBrojGolovaDoKraja(0);
    } else {
      setMinOcekivaniBrojGolovaDoKraja(minocekivanbrgolova);
    }

    let j39 = srednjiocekivanbrojgolova;

    let maxocekivani = j39 * 1.5;

    if (isNaN(maxocekivani)) {
      setMaxOcekivaniBrojGolovaDoKraja(0);
    } else {
      setMaxOcekivaniBrojGolovaDoKraja(maxocekivani);
    }

    let f34 = pobedadomacina;

    let n34 = pobedagosta;

    let f39 = minocekivanbrgolova;

    let b96 = 0;

    let r43 = kvotaGG;

    let b92 = r43;

    let d92 = 1 / b92;

    let t43 = kvotaNEGG;

    let c92 = t43;

    let e92 = 1 / c92;

    let f92 = d92 + e92;

    let g92 = f92 - 100;

    let h92 = g92 / 2;

    let i92 = d92 - h92;

    let k92;

    if (i92 < 0) {
      k92 = 0;
    } else {
      k92 = i92;
    }

    let n92 = k92 / 2.2;

    let o92 = 100 - n92;

    if (f34 >= n34) {
      b96 = (o92 * f39) / 100;
    } else {
      b96 = 0;
    }

    let c96 = f39 - b96;

    let b103 = c96;

    let b109;

    if (f34 > n34) {
      b109 = b96;
    } else {
      b109 = b103;
    }

    let c109;

    if (b96.toFixed(2) === b109.toFixed(2)) {
      c109 = c96;
    } else {
      c109 = b103;
    }

    let j27 = kvotaNaKonacanIshod2;

    let j88 = j27;

    let m88 = (1 / j88) * 100;

    let b27 = kvotaNaKonacanIshod1;

    let h88 = b27;

    let k88 = (1 / h88) * 100;

    let f27 = kvotaNaKonacanIshodX;

    let i88 = f27;

    let l88 = (1 / i88) * 100;

    let n88 = k88 + l88 + m88 - 100;

    let q88 = m88 - n88 / 3;

    let t88 = (q88 * n88) / 100;

    let w88 = m88 - t88;

    let m84 = w88;

    let p88 = l88 - n88 / 3;

    let s88 = (p88 * n88) / 100;

    let v88 = l88 - s88;

    let l84 = v88;

    let f59 = m84;

    let o88 = k88 - n88 / 3;

    let r88 = (o88 * n88) / 100;

    let u88 = k88 - r88;

    let k84 = u88;

    let f57 = k84;

    let f58 = l84;

    let f60 = f58 / 2;

    let f62 = f59 + f60;

    let f64 = (f62 * f39) / 100;

    let f70 = f64;

    let f61 = f60 + f57;

    let f63 = (f39 * f61) / 100;

    let f69 = f63;

    let minlevo1 = parseFloat(f69).toFixed(0);
    let mindesno1 = parseFloat(f70).toFixed(0);

    let min1 = `${minlevo1} : ${mindesno1}`;

    let minlevo2 = parseFloat(f63).toFixed(2);
    let mindesno2 = parseFloat(f64).toFixed(2);

    let min2 = `${minlevo2} : ${mindesno2}`;

    let i109 = b109.toFixed(0);

    let l109 = i109 + b43;

    let j109 = c109.toFixed(0);

    let m109 = parseFloat(j109) + parseFloat(d43);

    let minlevo3 = parseFloat(l109).toFixed(0);
    let mindesno3 = parseFloat(m109).toFixed(0);

    let min3 = `TR ${(
      parseFloat(minlevo1) + parseFloat(rezPrvogPoluvremenaDomacin)
    ).toFixed(0)} : ${
      parseFloat(mindesno1) + parseFloat(rezPrvogPoluvremenaGost)
    }`;

    let min = [];

    if (isNaN(minlevo1) || isNaN(mindesno1)) {
      min.push("0 : 0");
    } else {
      min.push(min1);
    }

    if (isNaN(minlevo2) || isNaN(mindesno2)) {
      min.push("0 : 0");
    } else {
      min.push(min2);
    }

    if (isNaN(minlevo3) || isNaN(mindesno3)) {
      min.push("0 : 0");
    } else {
      min.push(min3);
    }

    setMinTacanRezultat(min);

    let b98;

    if (f34 >= n34) {
      b98 = (o92 * j39) / 100;
    } else {
      b98 = 0;
    }

    let c98 = j39 - b98;

    let b105 = c98;

    let b111;

    if (f34 > n34) {
      b111 = b98;
    } else {
      b111 = b105;
    }

    let c111;

    if (b98 === b111) {
      c111 = c98;
    } else {
      c111 = b105;
    }

    let j111 = c111.toFixed(0);

    let m111 = j111 + d43;

    let i111 = b111.toFixed(0);

    let l111 = parseFloat(i111) + parseFloat(b43);

    let f66 = (j39 * f62) / 100;

    let f72 = f66;

    let f65 = (j39 * f61) / 100;

    let f71 = f65;

    let srdlevo1 = parseFloat(f71).toFixed(0);
    let srddesno1 = parseFloat(f72).toFixed(0);

    let srdlevo2 = parseFloat(f65).toFixed(2);
    let srddesno2 = parseFloat(f66).toFixed(2);

    let srdlevo3 = parseFloat(l111).toFixed(0);
    let srddesno3 = parseFloat(m111).toFixed(0);

    let srd1 = `${srdlevo1} : ${srddesno1}`;
    let srd2 = `${srdlevo2} : ${srddesno2}`;

    let srd3 = `TR ${parseFloat(
      parseFloat(srdlevo1) + parseFloat(rezPrvogPoluvremenaDomacin)
    ).toFixed(0)} : ${parseFloat(
      parseFloat(srddesno1) + parseFloat(rezPrvogPoluvremenaGost)
    ).toFixed(0)}`;

    let srd = [];

    if (isNaN(srdlevo1) || isNaN(srddesno1)) {
      srd.push("0 : 0");
    } else {
      srd.push(srd1);
    }

    if (isNaN(srdlevo2) || isNaN(srddesno2)) {
      srd.push("0 : 0");
    } else {
      srd.push(srd2);
    }

    if (isNaN(srdlevo3) || isNaN(srddesno3)) {
      srd.push("0 : 0");
    } else {
      srd.push(srd3);
    }

    setSrdTacanRezultat(srd);

    let n39 = maxocekivani;

    let b100;

    if (f34 >= n34) {
      b100 = (o92 * n39) / 100;
    } else {
      b100 = 0;
    }

    let c100 = parseFloat(n39) - parseFloat(b100);

    let b107 = c100;

    let b113;

    if (f34 > n34) {
      b113 = b100;
    } else {
      b113 = b107;
    }

    let c113;

    if (b100 === b113) {
      c113 = c100;
    } else {
      c113 = b107;
    }

    let i113 = b113.toFixed(0);

    let l113 = parseFloat(i113) + parseFloat(b43);

    let j113 = c113.toFixed(0);

    let m113 = j113 + d43;

    let f67 = (n39 * f61) / 100;

    let f73 = f67;

    let f68 = (n39 * f62) / 100;

    let f74 = f68;

    let makslevo1 = parseFloat(f73).toFixed(0);
    let maksdesno1 = parseFloat(f74).toFixed(0);

    let makslevo2 = parseFloat(f67).toFixed(2);
    let maksdesno2 = parseFloat(f68).toFixed(2);

    let makslevo3 = parseFloat(l113).toFixed(0);
    let maksdesno3 = parseFloat(m113).toFixed(0);

    let maks1 = `${makslevo1} : ${maksdesno1}`;
    let maks2 = `${makslevo2} : ${maksdesno2}`;
    let maks3 = `TR ${parseFloat(
      parseFloat(makslevo1) + parseFloat(rezPrvogPoluvremenaDomacin)
    ).toFixed(0)} : ${parseFloat(
      parseFloat(maksdesno1) + parseFloat(rezPrvogPoluvremenaGost)
    ).toFixed(0)}`;

    let maks = [];

    if (isNaN(makslevo1) || isNaN(maksdesno1)) {
      maks.push("0 : 0");
    } else {
      maks.push(maks1);
    }

    if (isNaN(makslevo2) || isNaN(maksdesno2)) {
      maks.push("0 : 0");
    } else {
      maks.push(maks2);
    }

    if (isNaN(makslevo3) || isNaN(maksdesno3)) {
      maks.push("0 : 0");
    } else {
      maks.push(maks3);
    }

    setMaxTacanRezultat(maks);
  };

  return (
    <div className={classes.container}>
      <Grupa1
        imgText="LIVE INTERAKTIV"
        imgBackgroundColorDark="#16397E"
        imgBackgroundColorLight="#1D499E"
        imgFontColor="whitesmoke"
        label1="VREME POČETKA"
        label2="ZEMLJA"
        label3="DOMAĆIN"
        label4="TAKMIČENJE - LIGA"
        label5="GOST"
      />
      <Grupa28
        imgBackgroundColorDark="#16397E"
        imgBackgroundColorLight="#1D499E"
        imgFontColor="whitesmoke"
        label1={snd}
        label2={sod}
        label3={sog}
        label4={sng}
        label5={sodg}
        label6={sndg}
        label7={sndSog}
        label8={sodSng}
        calculatorType={`Live interaktiv`}
      />
      <Grupa3008
        setSND={setSND}
        setSOD={setSOD}
        setSNDG={setSNDG}
        setSOG={setSOG}
        setSNG={setSNG}
        setSODG={setSODG}
        setSNDSOG={setSNDSOG}
        setSODSNG={setSODSNG}
        kvotaNaKonacanIshod1={kvotaNaKonacanIshod1}
        setKvotaNaKonacanIshod1={setKvotaNaKonacanIshod1}
        kvotaNaKonacanIshod2={kvotaNaKonacanIshod2}
        setKvotaNaKonacanIshod2={setKvotaNaKonacanIshod2}
        kvotaNaKonacanIshodX={kvotaNaKonacanIshodX}
        setKvotaNaKonacanIshodX={setKvotaNaKonacanIshodX}
        domacinDao={domacinDao}
        setDomacinDao={setDomacinDao}
        domacinPrimio={domacinPrimio}
        setDomacinPrimio={setDomacinPrimio}
        brojUtakmicaDomacin={brojUtakmicaDomacin}
        setBrojUtakmicaDomacin={setBrojUtakmicaDomacin}
        brojUtakmicaGost={brojUtakmicaGost}
        setBrojUtakmicaGost={setBrojUtakmicaGost}
        gostDao={gostDao}
        setGostDao={setGostDao}
        gostPrimio={gostPrimio}
        setGostPrimio={setGostPrimio}
        pobedaDomacina={pobedaDomacina}
        setPobedaDomacina={setPobedaDomacina}
        pobedaGosta={pobedaGosta}
        setPobedaGosta={setPobedaGosta}
        nereseno={nereseno}
        setNereseno={setNereseno}
        golMin={golMin}
        setGolMin={setGolMin}
        golMax={golMax}
        setGolMax={setGolMax}
        golBalans={golBalans}
        setGolBalans={setGolBalans}
        tacanRezultatDomacin={tacanRezultatDomacin}
        setTacanRezultatDomacin={setTacanRezultatDomacin}
        tacanRezultatGost={tacanRezultatGost}
        setTacanRezultatGost={setTacanRezultatGost}
        pobedaSaRazlikom={pobedaSaRazlikom}
        setPobedaSaRazlikom={setPobedaSaRazlikom}
        betkoPredlog1={betkoPredlog1}
        setBetkoPredlog1={setBetkoPredlog1}
        betkoPredlog2={betkoPredlog2}
        setBetkoPredlog2={setBetkoPredlog2}
        pobedaSaRazlikomZagrada={pobedaSaRazlikomZagrada}
        setPobedaSaRazlikomZagrada={setPobedaSaRazlikomZagrada}
      />
      <div className={classes.forma}>
        <div className={classes.column1}>
          <div className={classes.baldText} style={{ marginBottom: "10px" }}>
            Upiši trenutni minut utakmice
          </div>
          <input
            style={{ height: "150px", borderRadius: "10px" }}
            className={classes.input1column1}
            type="number"
            min="0"
            onChange={(e) => {
              setTrenutniMinutUtakmice(e.target.value);
            }}
          ></input>
          <div className={classes.baldText} style={{ marginTop: "10px" }}>
            Upiši rezultat prvog pol.
          </div>
          <div className={classes.innercolumn1}>
            <div className={classes.domacin1}>
              <p>Domaćin</p>
              <input
                style={{ height: "150px", borderRadius: "10px" }}
                type="number"
                min="0"
                onChange={(e) => {
                  setRezPrvogPoluvremenaDomacin(e.target.value);
                }}
              ></input>
            </div>
            <div className={classes.gost1}>
              <p>Gost</p>
              <input
                style={{ height: "150px", borderRadius: "10px" }}
                type="number"
                min="0"
                onChange={(e) => {
                  setRezPrvogPoluvremenaGost(e.target.value);
                }}
              ></input>
            </div>
          </div>
        </div>

        <div className={classes.column2}>
          <div className={classes.title}>
            PROJEKCIJE OD 46. MINUTA DO KRAJA UTAKMICE
          </div>
          <div className={classes.maincontent}>
            <div>
              {/* <p className={classes.text1innercolumn1column2}>Pobeda Domacina</p>
              <p className={classes.text2innercolumn1column2}>
                {pobedaDomacina == 0 || isNaN(pobedaDomacina) ? "-" : pobedaDomacina}
                {pobedaDomacina == 0 || isNaN(pobedaDomacina) ? "" : "%"}

              </p> */}
              <p>Min.ocekivani br. gol do kraja</p>
              <p className={classes.text4innercolumn1column2}>
                {isNaN(minOcekivaniBrGolovaDoKraja) ||
                minOcekivaniBrGolovaDoKraja == 0
                  ? "-"
                  : minOcekivaniBrGolovaDoKraja.toFixed(2)}
              </p>
              <p>
                Min.tačan rezultat <br></br>U preostalom vremenu do kraja
              </p>
              <p className={classes.text6innercolumn1column2}>
                {minTacanRezultat[0]}
              </p>
              <p className={classes.text7innercolumn1column2}>
                {minTacanRezultat[1]}
              </p>
              <p className={classes.text8innercolumn1column2}>
                {minTacanRezultat[2]}
              </p>
            </div>
            <div>
              {/* <p className={classes.text1innercolumn2column2}> Nerešen ishod</p>
              <p className={classes.text2innercolumn2column2}>
                {nereseno == 0 || isNaN(nereseno) ? "-" : nereseno}
                {nereseno == 0 || isNaN(nereseno) ? "" : "%"}
              </p> */}
              <p>Srd.ocekivani br. gol do kraja</p>
              <p className={classes.text4innercolumn2column2}>
                {isNaN(srdOcekivaniBrGolovaDoKraja) ||
                srdOcekivaniBrGolovaDoKraja == 0
                  ? "-"
                  : srdOcekivaniBrGolovaDoKraja.toFixed(2)}
              </p>
              <p>
                Srd.tačan rezultat<br></br>U preostalom vremenu do kraja
              </p>
              <p className={classes.text6innercolumn2column2}>
                {srdTacanRezultat[0]}
              </p>
              <p className={classes.text7innercolumn2column2}>
                {srdTacanRezultat[1]}
              </p>
              <p className={classes.text8innercolumn2column2}>
                {srdTacanRezultat[2]}
              </p>
            </div>
            <div>
              {/* <p className={classes.text1innercolumn3column2}>Pobeda Gosta</p>
              <p className={classes.text2innercolumn3column2}>
                {pobedaGosta == 0 || isNaN(pobedaGosta) ? "-" : pobedaGosta}
                {pobedaGosta == 0 || isNaN(pobedaGosta) ? "" : "%"}
              </p> */}
              <p>Max.ocekivani br. gol do kraja</p>
              <p className={classes.text4innercolumn3column2}>
                {isNaN(maxOcekivaniBrGolovaDoKraja) ||
                maxOcekivaniBrGolovaDoKraja == 0
                  ? "-"
                  : maxOcekivaniBrGolovaDoKraja.toFixed(2)}
              </p>
              <p>
                Max.tačan rezultat<br></br>U preostalom vremenu do kraja
              </p>
              <p className={classes.text6innercolumn3column2}>
                {maxTacanRezultat[0]}
              </p>
              <p className={classes.text7innercolumn3column2}>
                {maxTacanRezultat[1]}
              </p>
              <p className={classes.text8innercolumn3column2}>
                {maxTacanRezultat[2]}
              </p>
            </div>
          </div>
        </div>

        <div className={classes.column3}>
          <div className={classes.text1column5} style={{ marginTop: "0px" }}>
            UPIŠI KVOTE &lt; 2.5 &gt; PRE POČETKA
          </div>
          <div className={classes.innercolumn3column1}>
            <div className={classes.ug02}>
              <p> &lt; 2.5 (UG 0-2)</p>
              <input
                style={{ height: "150px", borderRadius: "10px" }}
                type="number"
                min="0"
                onChange={(e) => {
                  setKvotaUG30(e.target.value);
                }}
              ></input>
            </div>
            <div className={classes.ug3}>
              <p> &gt; 2.5 (UG 3+)</p>
              <input
                style={{ height: "150px", borderRadius: "10px" }}
                type="number"
                min="0"
                onChange={(e) => {
                  setKvotaUG02(e.target.value);
                }}
              ></input>
            </div>
          </div>
          <div className={classes.text3column5} style={{ marginTop: "10px" }}>
            UPIŠI KVOTE PRE POČETKA
          </div>
          <div className={classes.innercolumn3column2}>
            <div className={classes.ug02}>
              <p>GG</p>
              <input
                style={{ height: "150px", borderRadius: "10px" }}
                type="number"
                min="0"
                onChange={(e) => {
                  setKvotaGG(e.target.value);
                }}
              ></input>
            </div>
            <div className={classes.ug3}>
              <p>NEGG+</p>
              <input
                style={{ height: "150px", borderRadius: "10px" }}
                type="number"
                min="0"
                onChange={(e) => {
                  setKvotaNEGG(e.target.value);
                }}
              ></input>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LiveInteraktiv;
