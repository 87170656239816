import Grupa1 from '../components/Grupa1'
import Grupa20 from '../components/Grupa20'
import classes from './nivelacijaKvota.module.css'
import { useState, useEffect } from 'react'

const NivelacijaKvota = () => {
  const [upisIgara, setUpisIgara] = useState(Array(14).fill(''))
  const [unosKvota, setUnosKvota] = useState(Array(14).fill(''))
  const [procentualneSanseKladionice, setProcentualneSanseKladionice] =
    useState(Array(14).fill(''))
  const [marzaKladionice, setMarzaKladionice] = useState(Array(4).fill(''))

  const handleChangeUpisIgara = (e, index) => {
    let arr = [...upisIgara]
    arr[index] = e.target.value
    setUpisIgara(arr)
  }

  const handleChangeUnosKvota = (e, index) => {
    let arr = [...unosKvota]
    arr[index] = e.target.value
    setUnosKvota(arr)
  }

  const marzaKladioniceDveIgre = () => {
    let marza = Number(
      Number(procentualneSanseKladionice[0]) +
        Number(procentualneSanseKladionice[1])
    )
    marzaKladionice[0] = Number(Number(marza).toFixed(2) - 100).toFixed(2)
    return
  }

  const marzaKladioniceTriIgre = () => {
    let marza = Number(
      Number(procentualneSanseKladionice[2]) +
        Number(procentualneSanseKladionice[3]) +
        Number(procentualneSanseKladionice[4])
    )
    marzaKladionice[1] = Number(Number(marza - 100).toFixed(2)).toFixed(2)

    return
  }

  const marzaKladioniceCetiriIgre = () => {
    let marza = Number(
      Number(procentualneSanseKladionice[5]) +
        Number(procentualneSanseKladionice[6]) +
        Number(procentualneSanseKladionice[7]) +
        Number(procentualneSanseKladionice[8])
    )
    marzaKladionice[2] = Number(Number(Number(marza) - 100).toFixed(2)).toFixed(
      2
    )
    return
  }

  const marzaKladionicePetIgara = () => {
    let marza = Number(
      Number(procentualneSanseKladionice[9]) +
        Number(procentualneSanseKladionice[10]) +
        Number(procentualneSanseKladionice[11]) +
        Number(procentualneSanseKladionice[12]) +
        Number(procentualneSanseKladionice[13])
    )
    marzaKladionice[3] = Number(Number(Number(marza) - 100)).toFixed(2)

    return
  }
  const calcProcentualneSanseKladionice = (index) => {
    let arr = unosKvota.map((kvota) =>
      kvota ? Number((1 / kvota) * 100).toFixed(2) : ''
    )

    let noviNiz = [...procentualneSanseKladionice]
    noviNiz[index] = arr[index]
    setProcentualneSanseKladionice(arr)
  }

  const E118 = unosKvota[0]
  const G118 = unosKvota[1]
  const H118 = (1 / Number(E118)) * 100
  const I118 = (1 / Number(G118)) * 100
  const K118 = Number(H118) + Number(I118)
  const M118 = Number(K118) - 100
  const N118 = Number(M118) / 2
  const O118 = Number(H118) - Number(N118)
  const Q118 = Number(I118) - Number(N118)
  const S118 = Number(O118) - Number(Q118)
  const U118 = (Number(O118) * Number(M118)) / 100
  const V118 = (Number(Q118) * Number(M118)) / 100
  const W118 = Number(U118) + Number(V118)
  const Y118 = Number(H118) - Number(V118)
  const AA118 = Number(I118) - Number(U118)
  const AC118 = Number(Y118) + Number(AA118)
  const AE118 = () => {
    if (AC118 >= 100) {
      const result = Number(Number(AC118) - 100).toFixed(2)
      return isNaN(result) ? '-' : result
    } else {
      const result = Number(100 - Number(AC118)).toFixed(2)
      return isNaN(result) ? '-' : result
    }
  }

  const AH118 = () => {
    if (AC118 > 100) {
      const result = Number(Number(Y118) - Number(AE118())).toFixed(2)
      return isNaN(result) ? '-' : result
    } else {
      const result = Number(Number(AE118()) + Number(Y118)).toFixed(2)
      return isNaN(result) ? '-' : result
    }
  }

  const AI118 = () => {
    if (AC118 > 100) {
      const result = Number(Number(AA118) - Number(AE118())).toFixed(2)
      return isNaN(result) ? '-' : result
    } else {
      const result = Number(Number(AE118()) + Number(AA118)).toFixed(2)
      return isNaN(result) ? '-' : result
    }
  }

  const AJ118 = Number((1 / Number(AH118())) * 100)
  const AK118 = Number((1 / Number(AI118())) * 100)

  const AJ118Formatted = isNaN(AJ118) ? '-' : AJ118.toFixed(2)
  const AK118Formatted = isNaN(AK118) ? '-' : AK118.toFixed(2)

  const B115 = unosKvota[2]
  const D115 = unosKvota[3]
  const E115 = unosKvota[4]
  const G115 = (1 / Number(B115)) * 100
  const H115 = (1 / Number(D115)) * 100
  const I115 = (1 / Number(E115)) * 100

  const K115 = Number(G115 + H115 + I115)

  const M115 = Number(K115 - 100)
  const N115 = Number(M115) / 3
  const O115 = Number(G115) - Number(N115)
  const Q115 = Number(H115) - Number(N115)
  const S115 = Number(I115) - Number(N115)
  const U115 = (Number(S115) * Number(M115)) / 100
  const V115 = (Number(Q115) * Number(M115)) / 100
  const W115 = (Number(O115) * Number(M115)) / 100
  const Y115 = (Number(U115) + Number(V115) + Number(W115)) / 100
  const AA115 = Number(G115) - Number(U115)
  const AC115 = Number(H115) - Number(V115)
  const AE115 = Number(I115) - Number(V115)
  const AF115 = Number(AA115) + Number(AC115) + Number(AE115)
  const AG115 = () => {
    if (AF115 >= 100) {
      const result = Number((Number(AF115) - 100) / 3).toFixed(2)
      return isNaN(result) ? '-' : result
    } else {
      const result = Number((100 - Number(AF115)) / 3).toFixed(2)
      return isNaN(result) ? '-' : result
    }
  }

  const AH115 = () => {
    const result =
      AF115 >= 100
        ? Number(AA115 - parseFloat(AG115()))
        : Number(AA115 + parseFloat(parseFloat(AG115())))
    return isNaN(result) ? '-' : result.toFixed(2)
  }

  const AI115 = () => {
    const result =
      AF115 >= 100
        ? Number(AC115 - parseFloat(AG115()))
        : Number(AC115 + parseFloat(AG115()))
    return isNaN(result) ? '-' : result.toFixed(2)
  }

  const AJ115 = () => {
    const result =
      AF115 >= 100
        ? parseFloat(AE115 - parseFloat(AG115()))
        : parseFloat(AE115 + parseFloat(AG115()))
    return isNaN(result) ? '-' : result.toFixed(2)
  }

  const AH115Result = AH115()
  const AI115Result = AI115()
  const AJ115Result = AJ115()

  const AK115 =
    isNaN(AH115Result) || isNaN(AI115Result) || isNaN(AJ115Result)
      ? '-'
      : Number(AH115Result) + Number(AI115Result) + parseFloat(AJ115Result)

  const AL115 = isNaN(AH115Result)
    ? '-'
    : Number((1 / Number(AH115Result)) * 100).toFixed(2)
  const AM115 = isNaN(AI115Result)
    ? '-'
    : Number((1 / Number(AI115Result)) * 100).toFixed(2)
  const AN115 = isNaN(parseFloat(AJ115Result))
    ? '-'
    : parseFloat((1 / parseFloat(AJ115Result)) * 100).toFixed(2)

  const E121 = unosKvota[5]
  const G121 = unosKvota[6]
  const H121 = unosKvota[7]
  const I121 = unosKvota[8]

  const K121 = (1 / Number(E121)) * 100
  const M121 = (1 / Number(G121)) * 100
  const N121 = (1 / Number(H121)) * 100
  const O121 = (1 / Number(I121)) * 100

  const Q121 = Number(K121 + M121 + N121 + O121)

  const S121 = Number(Q121) - 100

  const U121 = () => {
    if (Q121 >= 100) {
      const result = Number(Q121) - 100
      return isNaN(result) ? '-' : result
    } else {
      const result = 100 - Number(Q121)
      return isNaN(result) ? '-' : result
    }
  }

  const V121 = isNaN(U121()) ? '-' : Number(U121() / 4)

  const W121 = () => {
    if (Q121 >= 100) {
      const result = Number(Number(K121) - Number(V121)).toFixed(2)
      return isNaN(result) ? '-' : result
    } else {
      const result = Number(Number(K121) + Number(V121)).toFixed(2)
      return isNaN(result) ? '-' : result
    }
  } // procenat nivelacije 6

  const Y121 = () => {
    if (Q121 >= 100) {
      const result = Number(Number(M121) - Number(V121)).toFixed(2)
      return isNaN(result) ? '-' : result
    } else {
      const result = Number(Number(M121) + Number(V121)).toFixed(2)
      return isNaN(result) ? '-' : result
    }
  } // procenat nivelacije 7

  const AA121 = () => {
    if (Q121 >= 100) {
      const result = Number(Number(N121) - Number(V121)).toFixed(2)
      return isNaN(result) ? '-' : result
    } else {
      const result = Number(Number(N121) + Number(V121)).toFixed(2)
      return isNaN(result) ? '-' : result
    }
  } // procenat nivelacije 8

  const AC121 = () => {
    if (Q121 >= 100) {
      const result = Number(Number(O121) - Number(V121)).toFixed(2)
      return isNaN(result) ? '-' : result
    } else {
      const result = Number(Number(O121) + Number(V121)).toFixed(2)
      return isNaN(result) ? '-' : result
    }
  } //procenat nivelacije 9

  const AE121 =
    isNaN(W121()) || isNaN(Y121()) || isNaN(AA121()) || isNaN(AC121())
      ? '-'
      : Number(W121()) + Number(Y121()) + Number(AA121()) + Number(AC121())

  const W122 = isNaN(W121())
    ? '-'
    : Number((1 / Number(W121())) * 100).toFixed(2)
  const Y122 = isNaN(Y121())
    ? '-'
    : Number((1 / Number(Y121())) * 100).toFixed(2)
  const AA122 = isNaN(AA121())
    ? '-'
    : Number((1 / Number(AA121())) * 100).toFixed(2)
  const AC122 = isNaN(AC121())
    ? '-'
    : Number((1 / Number(AC121())) * 100).toFixed(2)

  const U126 = unosKvota[9]
  const V126 = unosKvota[10]
  const W126 = unosKvota[11]
  const Y126 = unosKvota[12]
  const AA126 = unosKvota[13]
  const U128 = (1 / Number(U126)) * 100
  const V128 = (1 / Number(V126)) * 100
  const W128 = (1 / Number(W126)) * 100
  const Y128 = (1 / Number(Y126)) * 100
  const AA128 = (1 / Number(AA126)) * 100
  const AC128 = Number(U128 + V128 + W128 + Y128 + AA128)

  const AC130 = () => {
    if (AC128 >= 100) {
      const result = Number(AC128 - 100)
      return isNaN(result) ? '-' : result
    } else {
      const result = 100 - Number(AC128)
      return isNaN(result) ? '-' : result
    }
  }

  const AE130 = isNaN(AC130()) ? '-' : Number(AC130()) / 5

  const U132 = () => {
    if (AC128 >= 100) {
      const result = Number(Number(U128) - Number(AE130)).toFixed(2)
      return isNaN(result) ? '-' : result
    } else {
      const result = Number(Number(U128) + Number(AE130)).toFixed(2)
      return isNaN(result) ? '-' : result
    }
  }

  const V132 = () => {
    if (AC128 >= 100) {
      const result = Number(Number(V128) - Number(AE130)).toFixed(2)
      return isNaN(result) ? '-' : result
    } else {
      const result = Number(Number(V128) - Number(AE130)).toFixed(2)
      return isNaN(result) ? '-' : result
    }
  }

  const W132 = () => {
    if (AC128 >= 100) {
      const result = Number(Number(W128) - Number(AE130)).toFixed(2)
      return isNaN(result) ? '-' : result
    } else {
      const result = Number(Number(W128) + Number(AE130)).toFixed(2)
      return isNaN(result) ? '-' : result
    }
  }

  const Y132 = () => {
    if (AC128 >= 100) {
      const result = Number(Number(Y128) - Number(AE130)).toFixed(2)
      return isNaN(result) ? '-' : result
    } else {
      const result = Number(Number(Y128) + Number(AE130)).toFixed(2)
      return isNaN(result) ? '-' : result
    }
  }

  const AA132 = () => {
    if (AC128 >= 100) {
      const result = Number(Number(AA128) - Number(AE130)).toFixed(2)
      return isNaN(result) ? '-' : result
    } else {
      const result = Number(Number(AA128) + Number(AE130)).toFixed(2)
      return isNaN(result) ? '-' : result
    }
  }

  const AC132 =
    isNaN(U132()) ||
    isNaN(V132()) ||
    isNaN(W132()) ||
    isNaN(Y132()) ||
    isNaN(AA132())
      ? '-'
      : Number(U132()) +
        Number(V132()) +
        Number(W132()) +
        Number(Y132()) +
        Number(AA132())

  const U134 = isNaN(U132())
    ? '-'
    : Number((1 / Number(U132())) * 100).toFixed(2)
  const V134 = isNaN(V132())
    ? '-'
    : Number((1 / Number(V132())) * 100).toFixed(2)
  const W134 = isNaN(W132())
    ? '-'
    : Number((1 / Number(W132())) * 100).toFixed(2)
  const Y134 = isNaN(Y132())
    ? '-'
    : Number((1 / Number(Y132())) * 100).toFixed(2)
  const AA134 = isNaN(AA132())
    ? '-'
    : Number((1 / Number(AA132())) * 100).toFixed(2)

  useEffect(() => {
    calcProcentualneSanseKladionice()
    marzaKladioniceDveIgre()
    marzaKladioniceTriIgre()
    marzaKladioniceCetiriIgre()
    marzaKladionicePetIgara()
  }, [upisIgara, unosKvota, procentualneSanseKladionice, M118])

  return (
    <div className={classes.container}>
      <Grupa1
        imgText='NIVELACIJA KVOTA'
        imgBackgroundColorDark='#F94343'
        imgBackgroundColorLight='#F84343'
        imgFontColor='#FFFFFF'
        label1='VREME POČETKA'
        label2='ZEMLJA'
        label3='DOMAĆIN'
        label4='TAKMIČENJE - LIGA'
        label5='GOST'
      />
      <Grupa20
        imgBackgroundColorDark='#F94343'
        imgBackgroundColorLight='#F84343'
        imgFontColor='#FFFFFF'
        calculatorType={`Nivelacija kvota`}
      />
      <div className={classes.calculator}>
        <div
          style={{
            width: '500px',
            marginTop: '70px',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <div className={classes.leftCels} style={{ height: '80px' }}>
            <p style={{ fontSize: '50px', color: 'white', marginTop: '17px' }}>
              UPIS IGARA
            </p>
          </div>
          <div
            className={classes.leftCels}
            style={{ height: '100px', marginTop: '15px' }}
          >
            <p style={{ fontSize: '50px', color: 'white', marginTop: '23px' }}>
              UNOS KVOTA
            </p>
          </div>
          <div
            className={classes.leftCels}
            style={{ height: '50px', marginTop: '15px' }}
          >
            <p style={{ fontSize: '20px', color: 'white', marginTop: '14px' }}>
              PROCENTUALNE ŠANSE - KLADIONICA ( % )
            </p>
          </div>
          <div
            className={classes.leftCels}
            style={{ height: '50px', marginTop: '15px' }}
          >
            <p style={{ fontSize: '20px', color: 'white', marginTop: '14px' }}>
              MARŽA KLADIONICE ( % )
            </p>
          </div>
          <div
            className={classes.leftCels}
            style={{ height: '50px', marginTop: '15px' }}
          >
            <p style={{ fontSize: '20px', color: 'white', marginTop: '14px' }}>
              REALNE - NIVELISANE KVOTE
            </p>
          </div>
          <div
            className={classes.leftCels}
            style={{ height: '50px', marginTop: '15px' }}
          >
            <p style={{ fontSize: '20px', color: 'white', marginTop: '14px' }}>
              REALNI - NIVELISANI PROCENTI ( % )
            </p>
          </div>
        </div>
        <div
          style={{
            width: '1100px',
            display: 'flex',
            flexDirection: 'row',
            marginTop: '20px',
            textAlign: 'center',
          }}
        >
          <div
            style={{
              display: 'flex',
              marginLeft: '20px',
              marginRight: '20px',
              width: '115px',
              flexDirection: 'column',
            }}
          >
            <div
              style={{
                width: '110px',
                height: '31px',
                background: 'orangered',
                border: '3px solid black',
              }}
            >
              <p style={{ color: 'white', marginTop: '7px' }}>DVE IGRE</p>
            </div>
            <div
              style={{
                width: '110px',
                height: '20px',
                marginTop: '14px',
                background: 'blue',
                border: '3px solid black',
              }}
            >
              <p style={{ color: 'white', marginTop: '4px', fontSize: '13px' }}>
                UPIS IGARA
              </p>
            </div>

            <div
              style={{
                display: 'flex',
                width: '115px',
                height: '43px',
                marginTop: '14px',
                flexDirection: 'row',
              }}
            >
              <div
                style={{
                  width: '45px',
                  background: 'white',
                  border: '3px solid black',
                }}
              >
                <input
                  style={{
                    fontWeight: 'bold',
                    fontSize: '20px',
                    width: '40px',
                    height: '35px',
                    border: 'none',
                    textAlign: 'center',
                  }}
                  onChange={(e) => handleChangeUpisIgara(e, 0)}
                  type='text'
                  value={upisIgara[0]}
                ></input>
              </div>
              <div
                style={{
                  width: '45px',
                  marginLeft: '10px',
                  background: 'white',
                  border: '3px solid black',
                }}
              >
                <input
                  style={{
                    fontWeight: 'bold',
                    fontSize: '20px',
                    width: '40px',
                    height: '35px',
                    border: 'none',
                    textAlign: 'center',
                  }}
                  onChange={(e) => handleChangeUpisIgara(e, 1)}
                  type='text'
                  value={upisIgara[1]}
                ></input>
              </div>
            </div>

            <div
              style={{
                display: 'flex',
                width: '115px',
                height: '110px',
                marginTop: '14px',
                flexDirection: 'row',
              }}
            >
              <div
                style={{
                  width: '45px',
                  background: 'white',
                  border: '3px solid black',
                }}
              >
                <input
                  style={{
                    fontWeight: 'bold',
                    fontSize: '20px',
                    width: '40px',
                    height: '100px',
                    border: 'none',
                    textAlign: 'center',
                  }}
                  onChange={(e) => handleChangeUnosKvota(e, 0)}
                  type='text'
                  value={unosKvota[0]}
                ></input>
              </div>
              <div
                style={{
                  width: '45px',
                  marginLeft: '10px',
                  background: 'white',
                  border: '3px solid black',
                }}
              >
                <input
                  style={{
                    fontWeight: 'bold',
                    fontSize: '20px',
                    width: '40px',
                    height: '100px',
                    border: 'none',
                    textAlign: 'center',
                  }}
                  onChange={(e) => handleChangeUnosKvota(e, 1)}
                  type='text'
                  value={unosKvota[1]}
                ></input>
              </div>
            </div>

            <div
              style={{
                display: 'flex',
                width: '115px',
                height: '45px',
                marginTop: '14px',
                flexDirection: 'row',
              }}
            >
              <div
                style={{
                  width: '45px',
                  border: '3px solid black',
                  overflow: 'hidden',
                }}
              >
                <p style={{ marginTop: '17px' }}>
                  {procentualneSanseKladionice[0]}
                </p>
              </div>
              <div
                style={{
                  width: '45px',
                  marginLeft: '10px',
                  border: '3px solid black',
                  overflow: 'hidden',
                }}
              >
                <p style={{ marginTop: '17px' }}>
                  {procentualneSanseKladionice[1]}
                </p>
              </div>
            </div>

            <div
              style={{
                width: '110px',
                height: '51px',
                marginTop: '14px',
                background: 'orangered',
                border: '3px solid black',
              }}
            >
              <p style={{ marginTop: '17px' }}>{marzaKladionice[0]}</p>
            </div>

            <div
              style={{
                display: 'flex',
                width: '115px',
                height: '45px',
                marginTop: '14px',
                flexDirection: 'row',
              }}
            >
              <div
                style={{
                  width: '45px',
                  border: '3px solid black',
                  overflow: 'hidden',
                }}
              >
                <p style={{ marginTop: '17px' }}>{AJ118Formatted}</p>
              </div>
              <div
                style={{
                  width: '45px',
                  marginLeft: '10px',
                  border: '3px solid black',
                  overflow: 'hidden',
                }}
              >
                <p style={{ marginTop: '17px' }}>{AK118Formatted}</p>
              </div>
            </div>

            <div
              style={{
                display: 'flex',
                width: '115px',
                height: '45px',
                marginTop: '14px',
                flexDirection: 'row',
              }}
            >
              <div
                style={{
                  width: '45px',
                  border: '3px solid black',
                  overflow: 'hidden',
                }}
              >
                <p style={{ marginTop: '17px' }}>{AH118()}</p>
              </div>
              <div
                style={{
                  width: '45px',
                  marginLeft: '10px',
                  border: '3px solid black',
                  overflow: 'hidden',
                }}
              >
                <p style={{ marginTop: '17px' }}>{AI118()}</p>
              </div>
            </div>
          </div>

          <div
            style={{
              display: 'flex',
              marginLeft: '20px',
              marginRight: '20px',
              width: '175px',
              flexDirection: 'column',
            }}
          >
            <div
              style={{
                width: '170px',
                height: '31px',
                background: 'orangered',
                border: '3px solid black',
              }}
            >
              <p style={{ color: 'white', marginTop: '7px' }}>TRI IGRE</p>
            </div>
            <div
              style={{
                width: '170px',
                height: '20px',
                marginTop: '14px',
                background: 'blue',
                border: '3px solid black',
              }}
            >
              <p style={{ color: 'white', marginTop: '4px', fontSize: '13px' }}>
                UPIS IGARA
              </p>
            </div>

            <div
              style={{
                display: 'flex',
                width: '175px',
                height: '43px',
                marginTop: '14px',
                flexDirection: 'row',
              }}
            >
              <div
                style={{
                  width: '45px',
                  background: 'white',
                  border: '3px solid black',
                }}
              >
                <input
                  style={{
                    fontWeight: 'bold',
                    fontSize: '20px',
                    width: '40px',
                    height: '35px',
                    border: 'none',
                    textAlign: 'center',
                  }}
                  onChange={(e) => handleChangeUpisIgara(e, 2)}
                  type='text'
                  value={upisIgara[2]}
                ></input>
              </div>
              <div
                style={{
                  width: '45px',
                  marginLeft: '10px',
                  background: 'white',
                  border: '3px solid black',
                }}
              >
                <input
                  style={{
                    fontWeight: 'bold',
                    fontSize: '20px',
                    width: '40px',
                    height: '35px',
                    border: 'none',
                    textAlign: 'center',
                  }}
                  onChange={(e) => handleChangeUpisIgara(e, 3)}
                  type='text'
                  value={upisIgara[3]}
                ></input>
              </div>
              <div
                style={{
                  width: '45px',
                  marginLeft: '10px',
                  background: 'white',
                  border: '3px solid black',
                }}
              >
                <input
                  style={{
                    fontWeight: 'bold',
                    fontSize: '20px',
                    width: '40px',
                    height: '35px',
                    border: 'none',
                    textAlign: 'center',
                  }}
                  onChange={(e) => handleChangeUpisIgara(e, 4)}
                  type='text'
                  value={upisIgara[4]}
                ></input>
              </div>
            </div>

            <div
              style={{
                display: 'flex',
                width: '175px',
                height: '110px',
                marginTop: '14px',
                flexDirection: 'row',
              }}
            >
              <div
                style={{
                  width: '45px',
                  background: 'white',
                  border: '3px solid black',
                }}
              >
                <input
                  style={{
                    fontWeight: 'bold',
                    fontSize: '20px',
                    width: '40px',
                    height: '100px',
                    border: 'none',
                    textAlign: 'center',
                  }}
                  onChange={(e) => handleChangeUnosKvota(e, 2)}
                  type='text'
                  value={unosKvota[2]}
                ></input>
              </div>
              <div
                style={{
                  width: '45px',
                  marginLeft: '10px',
                  background: 'white',
                  border: '3px solid black',
                }}
              >
                <input
                  style={{
                    fontWeight: 'bold',
                    fontSize: '20px',
                    width: '40px',
                    height: '100px',
                    border: 'none',
                    textAlign: 'center',
                  }}
                  onChange={(e) => handleChangeUnosKvota(e, 3)}
                  type='text'
                  value={unosKvota[3]}
                ></input>
              </div>
              <div
                style={{
                  width: '45px',
                  marginLeft: '10px',
                  background: 'white',
                  border: '3px solid black',
                }}
              >
                <input
                  style={{
                    fontWeight: 'bold',
                    fontSize: '20px',
                    width: '40px',
                    height: '100px',
                    border: 'none',
                    textAlign: 'center',
                  }}
                  onChange={(e) => handleChangeUnosKvota(e, 4)}
                  type='text'
                  value={unosKvota[4]}
                ></input>
              </div>
            </div>

            <div
              style={{
                display: 'flex',
                width: '175px',
                height: '45px',
                marginTop: '14px',
                flexDirection: 'row',
              }}
            >
              <div
                style={{
                  width: '45px',
                  border: '3px solid black',
                  overflow: 'hidden',
                }}
              >
                <p style={{ marginTop: '17px' }}>
                  {procentualneSanseKladionice[2]}
                </p>
              </div>
              <div
                style={{
                  width: '45px',
                  marginLeft: '10px',
                  border: '3px solid black',
                  overflow: 'hidden',
                }}
              >
                <p style={{ marginTop: '17px' }}>
                  {procentualneSanseKladionice[3]}
                </p>
              </div>
              <div
                style={{
                  width: '45px',
                  marginLeft: '10px',
                  border: '3px solid black',
                  overflow: 'hidden',
                }}
              >
                <p style={{ marginTop: '17px' }}>
                  {procentualneSanseKladionice[4]}
                </p>
              </div>
            </div>

            <div
              style={{
                width: '170px',
                height: '51px',
                marginTop: '14px',
                background: 'orangered',
                border: '3px solid black',
              }}
            >
              <p style={{ marginTop: '17px' }}>{marzaKladionice[1]}</p>
            </div>

            <div
              style={{
                display: 'flex',
                width: '175px',
                height: '45px',
                marginTop: '14px',
                flexDirection: 'row',
              }}
            >
              <div
                style={{
                  width: '45px',
                  border: '3px solid black',
                  overflow: 'hidden',
                }}
              >
                <p style={{ marginTop: '17px' }}>{AL115}</p>
              </div>
              <div
                style={{
                  width: '45px',
                  marginLeft: '10px',
                  border: '3px solid black',
                  overflow: 'hidden',
                }}
              >
                <p style={{ marginTop: '17px' }}>{AM115}</p>
              </div>
              <div
                style={{
                  width: '45px',
                  marginLeft: '10px',
                  border: '3px solid black',
                  overflow: 'hidden',
                }}
              >
                <p style={{ marginTop: '17px' }}>{AN115}</p>
              </div>
            </div>

            <div
              style={{
                display: 'flex',
                width: '175px',
                height: '45px',
                marginTop: '14px',
                flexDirection: 'row',
              }}
            >
              <div
                style={{
                  width: '45px',
                  border: '3px solid black',
                  overflow: 'hidden',
                }}
              >
                <p style={{ marginTop: '17px' }}>{AH115()}</p>
              </div>
              <div
                style={{
                  width: '45px',
                  marginLeft: '10px',
                  border: '3px solid black',
                  overflow: 'hidden',
                }}
              >
                <p style={{ marginTop: '17px' }}>{AI115()}</p>
              </div>
              <div
                style={{
                  width: '45px',
                  marginLeft: '10px',
                  border: '3px solid black',
                  overflow: 'hidden',
                }}
              >
                <p style={{ marginTop: '17px' }}>{AJ115()}</p>
              </div>
            </div>
          </div>

          <div
            style={{
              display: 'flex',
              marginLeft: '20px',
              marginRight: '20px',
              width: '235px',
              flexDirection: 'column',
            }}
          >
            <div
              style={{
                width: '230px',
                height: '31px',
                background: 'orangered',
                border: '3px solid black',
              }}
            >
              <p style={{ color: 'white', marginTop: '7px' }}>CETIRI IGRE</p>
            </div>
            <div
              style={{
                width: '230px',
                height: '20px',
                marginTop: '14px',
                background: 'blue',
                border: '3px solid black',
              }}
            >
              <p style={{ color: 'white', marginTop: '4px', fontSize: '13px' }}>
                UPIS IGARA
              </p>
            </div>

            <div
              style={{
                display: 'flex',
                width: '235px',
                height: '43px',
                marginTop: '14px',
                flexDirection: 'row',
              }}
            >
              <div
                style={{
                  width: '45px',
                  background: 'white',
                  border: '3px solid black',
                }}
              >
                <input
                  style={{
                    fontWeight: 'bold',
                    fontSize: '20px',
                    width: '40px',
                    height: '35px',
                    border: 'none',
                    textAlign: 'center',
                  }}
                  onChange={(e) => handleChangeUpisIgara(e, 5)}
                  type='text'
                  value={upisIgara[5]}
                ></input>
              </div>
              <div
                style={{
                  width: '45px',
                  marginLeft: '10px',
                  background: 'white',
                  border: '3px solid black',
                }}
              >
                <input
                  style={{
                    fontWeight: 'bold',
                    fontSize: '20px',
                    width: '40px',
                    height: '35px',
                    border: 'none',
                    textAlign: 'center',
                  }}
                  onChange={(e) => handleChangeUpisIgara(e, 6)}
                  type='text'
                  value={upisIgara[6]}
                ></input>
              </div>
              <div
                style={{
                  width: '45px',
                  marginLeft: '10px',
                  background: 'white',
                  border: '3px solid black',
                }}
              >
                <input
                  style={{
                    fontWeight: 'bold',
                    fontSize: '20px',
                    width: '40px',
                    height: '35px',
                    border: 'none',
                    textAlign: 'center',
                  }}
                  onChange={(e) => handleChangeUpisIgara(e, 7)}
                  type='text'
                  value={upisIgara[7]}
                ></input>
              </div>
              <div
                style={{
                  width: '45px',
                  marginLeft: '10px',
                  background: 'white',
                  border: '3px solid black',
                }}
              >
                <input
                  style={{
                    fontWeight: 'bold',
                    fontSize: '20px',
                    width: '40px',
                    height: '35px',
                    border: 'none',
                    textAlign: 'center',
                  }}
                  onChange={(e) => handleChangeUpisIgara(e, 8)}
                  type='text'
                  value={upisIgara[8]}
                ></input>
              </div>
            </div>

            <div
              style={{
                display: 'flex',
                width: '235px',
                height: '110px',
                marginTop: '14px',
                flexDirection: 'row',
              }}
            >
              <div
                style={{
                  width: '45px',
                  background: 'white',
                  border: '3px solid black',
                }}
              >
                <input
                  style={{
                    fontWeight: 'bold',
                    fontSize: '20px',
                    width: '40px',
                    height: '100px',
                    border: 'none',
                    textAlign: 'center',
                  }}
                  onChange={(e) => handleChangeUnosKvota(e, 5)}
                  type='text'
                  value={unosKvota[5]}
                ></input>
              </div>
              <div
                style={{
                  width: '45px',
                  marginLeft: '10px',
                  background: 'white',
                  border: '3px solid black',
                }}
              >
                <input
                  style={{
                    fontWeight: 'bold',
                    fontSize: '20px',
                    width: '40px',
                    height: '100px',
                    border: 'none',
                    textAlign: 'center',
                  }}
                  onChange={(e) => handleChangeUnosKvota(e, 6)}
                  type='text'
                  value={unosKvota[6]}
                ></input>
              </div>
              <div
                style={{
                  width: '45px',
                  marginLeft: '10px',
                  background: 'white',
                  border: '3px solid black',
                }}
              >
                <input
                  style={{
                    fontWeight: 'bold',
                    fontSize: '20px',
                    width: '40px',
                    height: '100px',
                    border: 'none',
                    textAlign: 'center',
                  }}
                  onChange={(e) => handleChangeUnosKvota(e, 7)}
                  type='text'
                  value={unosKvota[7]}
                ></input>
              </div>
              <div
                style={{
                  width: '45px',
                  marginLeft: '10px',
                  background: 'white',
                  border: '3px solid black',
                }}
              >
                <input
                  style={{
                    fontWeight: 'bold',
                    fontSize: '20px',
                    width: '40px',
                    height: '100px',
                    border: 'none',
                    textAlign: 'center',
                  }}
                  onChange={(e) => handleChangeUnosKvota(e, 8)}
                  type='text'
                  value={unosKvota[8]}
                ></input>
              </div>
            </div>

            <div
              style={{
                display: 'flex',
                width: '235px',
                height: '45px',
                marginTop: '14px',
                flexDirection: 'row',
              }}
            >
              <div
                style={{
                  width: '45px',
                  border: '3px solid black',
                  overflow: 'hidden',
                }}
              >
                <p style={{ marginTop: '17px' }}>
                  {procentualneSanseKladionice[5]}
                </p>
              </div>
              <div
                style={{
                  width: '45px',
                  marginLeft: '10px',
                  border: '3px solid black',
                  overflow: 'hidden',
                }}
              >
                <p style={{ marginTop: '17px' }}>
                  {procentualneSanseKladionice[6]}
                </p>
              </div>
              <div
                style={{
                  width: '45px',
                  marginLeft: '10px',
                  border: '3px solid black',
                  overflow: 'hidden',
                }}
              >
                <p style={{ marginTop: '17px' }}>
                  {procentualneSanseKladionice[7]}
                </p>
              </div>
              <div
                style={{
                  width: '45px',
                  marginLeft: '10px',
                  border: '3px solid black',
                  overflow: 'hidden',
                }}
              >
                <p style={{ marginTop: '17px' }}>
                  {procentualneSanseKladionice[8]}
                </p>
              </div>
            </div>

            <div
              style={{
                width: '230px',
                height: '51px',
                marginTop: '14px',
                background: 'orangered',
                border: '3px solid black',
              }}
            >
              <p style={{ marginTop: '17px' }}>{marzaKladionice[2]}</p>
            </div>

            <div
              style={{
                display: 'flex',
                width: '235px',
                height: '45px',
                marginTop: '14px',
                flexDirection: 'row',
              }}
            >
              <div
                style={{
                  width: '45px',
                  border: '3px solid black',
                  overflow: 'hidden',
                }}
              >
                <p style={{ marginTop: '17px' }}>{W122}</p>
              </div>
              <div
                style={{
                  width: '45px',
                  marginLeft: '10px',
                  border: '3px solid black',
                  overflow: 'hidden',
                }}
              >
                <p style={{ marginTop: '17px' }}>{Y122}</p>
              </div>
              <div
                style={{
                  width: '45px',
                  marginLeft: '10px',
                  border: '3px solid black',
                  overflow: 'hidden',
                }}
              >
                <p style={{ marginTop: '17px' }}>{AA122}</p>
              </div>
              <div
                style={{
                  width: '45px',
                  marginLeft: '10px',
                  border: '3px solid black',
                  overflow: 'hidden',
                }}
              >
                <p style={{ marginTop: '17px' }}>{AC122}</p>
              </div>
            </div>

            <div
              style={{
                display: 'flex',
                width: '235px',
                height: '45px',
                marginTop: '14px',
                flexDirection: 'row',
              }}
            >
              <div
                style={{
                  width: '45px',
                  border: '3px solid black',
                  overflow: 'hidden',
                }}
              >
                <p style={{ marginTop: '17px' }}>{W121()}</p>
              </div>
              <div
                style={{
                  width: '45px',
                  marginLeft: '10px',
                  border: '3px solid black',
                  overflow: 'hidden',
                }}
              >
                <p style={{ marginTop: '17px' }}>{Y121()}</p>
              </div>
              <div
                style={{
                  width: '45px',
                  marginLeft: '10px',
                  border: '3px solid black',
                  overflow: 'hidden',
                }}
              >
                <p style={{ marginTop: '17px' }}>{AA121()}</p>
              </div>
              <div
                style={{
                  width: '45px',
                  marginLeft: '10px',
                  border: '3px solid black',
                  overflow: 'hidden',
                }}
              >
                <p style={{ marginTop: '17px' }}>{AC121()}</p>
              </div>
            </div>
          </div>

          <div
            style={{
              display: 'flex',
              marginLeft: '20px',
              marginRight: '20px',
              width: '295px',
              flexDirection: 'column',
            }}
          >
            <div
              style={{
                width: '290px',
                height: '31px',
                background: 'orangered',
                border: '3px solid black',
              }}
            >
              <p style={{ color: 'white', marginTop: '7px' }}>PET IGARA</p>
            </div>
            <div
              style={{
                width: '290px',
                height: '20px',
                marginTop: '14px',
                background: 'blue',
                border: '3px solid black',
              }}
            >
              <p style={{ color: 'white', marginTop: '4px', fontSize: '13px' }}>
                UPIS IGARA
              </p>
            </div>

            <div
              style={{
                display: 'flex',
                width: '235px',
                height: '43px',
                marginTop: '14px',
                flexDirection: 'row',
              }}
            >
              <div
                style={{
                  width: '45px',
                  background: 'white',
                  border: '3px solid black',
                }}
              >
                <input
                  style={{
                    fontWeight: 'bold',
                    fontSize: '20px',
                    width: '40px',
                    height: '35px',
                    border: 'none',
                    textAlign: 'center',
                  }}
                  onChange={(e) => handleChangeUpisIgara(e, 9)}
                  type='text'
                  value={upisIgara[9]}
                ></input>
              </div>
              <div
                style={{
                  width: '45px',
                  marginLeft: '10px',
                  background: 'white',
                  border: '3px solid black',
                }}
              >
                <input
                  style={{
                    fontWeight: 'bold',
                    fontSize: '20px',
                    width: '40px',
                    height: '35px',
                    border: 'none',
                    textAlign: 'center',
                  }}
                  onChange={(e) => handleChangeUpisIgara(e, 10)}
                  type='text'
                  value={upisIgara[10]}
                ></input>
              </div>
              <div
                style={{
                  width: '45px',
                  marginLeft: '10px',
                  background: 'white',
                  border: '3px solid black',
                }}
              >
                <input
                  style={{
                    fontWeight: 'bold',
                    fontSize: '20px',
                    width: '40px',
                    height: '35px',
                    border: 'none',
                    textAlign: 'center',
                  }}
                  onChange={(e) => handleChangeUpisIgara(e, 11)}
                  type='text'
                  value={upisIgara[11]}
                ></input>
              </div>
              <div
                style={{
                  width: '45px',
                  marginLeft: '10px',
                  background: 'white',
                  border: '3px solid black',
                }}
              >
                <input
                  style={{
                    fontWeight: 'bold',
                    fontSize: '20px',
                    width: '40px',
                    height: '35px',
                    border: 'none',
                    textAlign: 'center',
                  }}
                  onChange={(e) => handleChangeUpisIgara(e, 12)}
                  type='text'
                  value={upisIgara[12]}
                ></input>
              </div>
              <div
                style={{
                  width: '45px',
                  marginLeft: '10px',
                  background: 'white',
                  border: '3px solid black',
                }}
              >
                <input
                  style={{
                    fontWeight: 'bold',
                    fontSize: '20px',
                    width: '40px',
                    height: '35px',
                    border: 'none',
                    textAlign: 'center',
                  }}
                  onChange={(e) => handleChangeUpisIgara(e, 13)}
                  type='text'
                  value={upisIgara[13]}
                ></input>
              </div>
            </div>

            <div
              style={{
                display: 'flex',
                width: '295',
                height: '110px',
                marginTop: '14px',
                flexDirection: 'row',
              }}
            >
              <div
                style={{
                  width: '45px',
                  background: 'white',
                  border: '3px solid black',
                }}
              >
                <input
                  style={{
                    fontWeight: 'bold',
                    fontSize: '20px',
                    width: '40px',
                    height: '100px',
                    border: 'none',
                    textAlign: 'center',
                  }}
                  onChange={(e) => handleChangeUnosKvota(e, 9)}
                  type='text'
                  value={unosKvota[9]}
                ></input>
              </div>
              <div
                style={{
                  width: '45px',
                  marginLeft: '10px',
                  background: 'white',
                  border: '3px solid black',
                }}
              >
                <input
                  style={{
                    fontWeight: 'bold',
                    fontSize: '20px',
                    width: '40px',
                    height: '100px',
                    border: 'none',
                    textAlign: 'center',
                  }}
                  onChange={(e) => handleChangeUnosKvota(e, 10)}
                  type='text'
                  value={unosKvota[10]}
                ></input>
              </div>
              <div
                style={{
                  width: '45px',
                  marginLeft: '10px',
                  background: 'white',
                  border: '3px solid black',
                }}
              >
                <input
                  style={{
                    fontWeight: 'bold',
                    fontSize: '20px',
                    width: '40px',
                    height: '100px',
                    border: 'none',
                    textAlign: 'center',
                  }}
                  onChange={(e) => handleChangeUnosKvota(e, 11)}
                  type='text'
                  value={unosKvota[11]}
                ></input>
              </div>
              <div
                style={{
                  width: '45px',
                  marginLeft: '10px',
                  background: 'white',
                  border: '3px solid black',
                }}
              >
                <input
                  style={{
                    fontWeight: 'bold',
                    fontSize: '20px',
                    width: '40px',
                    height: '100px',
                    border: 'none',
                    textAlign: 'center',
                  }}
                  onChange={(e) => handleChangeUnosKvota(e, 12)}
                  type='text'
                  value={unosKvota[12]}
                ></input>
              </div>
              <div
                style={{
                  width: '45px',
                  marginLeft: '10px',
                  background: 'white',
                  border: '3px solid black',
                }}
              >
                <input
                  style={{
                    fontWeight: 'bold',
                    fontSize: '20px',
                    width: '40px',
                    height: '100px',
                    border: 'none',
                    textAlign: 'center',
                  }}
                  onChange={(e) => handleChangeUnosKvota(e, 13)}
                  type='text'
                  value={unosKvota[13]}
                ></input>
              </div>
            </div>

            <div
              style={{
                display: 'flex',
                width: '295px',
                height: '45px',
                marginTop: '14px',
                flexDirection: 'row',
              }}
            >
              <div
                style={{
                  width: '45px',
                  border: '3px solid black',
                  overflow: 'hidden',
                }}
              >
                <p style={{ marginTop: '17px' }}>
                  {procentualneSanseKladionice[9]}
                </p>
              </div>
              <div
                style={{
                  width: '45px',
                  marginLeft: '10px',
                  border: '3px solid black',
                  overflow: 'hidden',
                }}
              >
                <p style={{ marginTop: '17px' }}>
                  {procentualneSanseKladionice[10]}
                </p>
              </div>
              <div
                style={{
                  width: '45px',
                  marginLeft: '10px',
                  border: '3px solid black',
                  overflow: 'hidden',
                }}
              >
                <p style={{ marginTop: '17px' }}>
                  {procentualneSanseKladionice[11]}
                </p>
              </div>
              <div
                style={{
                  width: '45px',
                  marginLeft: '10px',
                  border: '3px solid black',
                  overflow: 'hidden',
                }}
              >
                <p style={{ marginTop: '17px' }}>
                  {procentualneSanseKladionice[12]}
                </p>
              </div>
              <div
                style={{
                  width: '45px',
                  marginLeft: '10px',
                  border: '3px solid black',
                  overflow: 'hidden',
                }}
              >
                <p style={{ marginTop: '17px' }}>
                  {procentualneSanseKladionice[13]}
                </p>
              </div>
            </div>

            <div
              style={{
                width: '290px',
                height: '51px',
                marginTop: '14px',
                background: 'orangered',
                border: '3px solid black',
              }}
            >
              <p style={{ marginTop: '17px' }}>{marzaKladionice[3]}</p>
            </div>

            <div
              style={{
                display: 'flex',
                width: '295px',
                height: '45px',
                marginTop: '14px',
                flexDirection: 'row',
              }}
            >
              <div
                style={{
                  width: '45px',
                  border: '3px solid black',
                  overflow: 'hidden',
                }}
              >
                <p style={{ marginTop: '17px' }}>{U134}</p>
              </div>
              <div
                style={{
                  width: '45px',
                  marginLeft: '10px',
                  border: '3px solid black',
                  overflow: 'hidden',
                }}
              >
                <p style={{ marginTop: '17px' }}>{V134}</p>
              </div>
              <div
                style={{
                  width: '45px',
                  marginLeft: '10px',
                  border: '3px solid black',
                  overflow: 'hidden',
                }}
              >
                <p style={{ marginTop: '17px' }}>{W134}</p>
              </div>
              <div
                style={{
                  width: '45px',
                  marginLeft: '10px',
                  border: '3px solid black',
                  overflow: 'hidden',
                }}
              >
                <p style={{ marginTop: '17px' }}>{Y134}</p>
              </div>
              <div
                style={{
                  width: '45px',
                  marginLeft: '10px',
                  border: '3px solid black',
                  overflow: 'hidden',
                }}
              >
                <p style={{ marginTop: '17px' }}>{AA134}</p>
              </div>
            </div>

            <div
              style={{
                display: 'flex',
                width: '295px',
                height: '45px',
                marginTop: '14px',
                flexDirection: 'row',
              }}
            >
              <div
                style={{
                  width: '45px',
                  border: '3px solid black',
                  overflow: 'hidden',
                }}
              >
                <p style={{ marginTop: '17px' }}>{U132()}</p>
              </div>
              <div
                style={{
                  width: '45px',
                  marginLeft: '10px',
                  border: '3px solid black',
                  overflow: 'hidden',
                }}
              >
                <p style={{ marginTop: '17px' }}>{V132()}</p>
              </div>
              <div
                style={{
                  width: '45px',
                  marginLeft: '10px',
                  border: '3px solid black',
                  overflow: 'hidden',
                }}
              >
                <p style={{ marginTop: '17px' }}>{W132()}</p>
              </div>
              <div
                style={{
                  width: '45px',
                  marginLeft: '10px',
                  border: '3px solid black',
                  overflow: 'hidden',
                }}
              >
                <p style={{ marginTop: '17px' }}>{Y132()}</p>
              </div>
              <div
                style={{
                  width: '45px',
                  marginLeft: '10px',
                  border: '3px solid black',
                  overflow: 'hidden',
                }}
              >
                <p style={{ marginTop: '17px' }}>{AA132()}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default NivelacijaKvota
