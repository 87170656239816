import { useState, useEffect } from "react";
import Grupa1 from "../components/Grupa1";
import Grupa3005 from "../components/Grupa3005";
import Grupa300 from "../components/Grupa300";

import classes from "./arbitrazniKalkulator.module.css";
import { parse } from "date-fns";

const ArbitrazniKalkulator = () => {
  const [kvotaInputsArr, setKvotaInputsArr] = useState([
    0, 0, 0, 0, 0, 0, 0, 0, 0,
  ]);
  const [procenatKvoteArr, setProcenatKvoteArr] = useState([
    0, 0, 0, 0, 0, 0, 0, 0, 0,
  ]);
  const [profitKladionice, setProfitKladionice] = useState(0);
  const [procenatPoTipuArr, setProcenatPoTipuArr] = useState([
    0, 0, 0, 0, 0, 0, 0, 0, 0,
  ]);
  const [ukupanUlogUpis, setUkupanUlogUpis] = useState(0);
  const [ulogPoTipuArr, setUlogPoTipuArr] = useState([
    0, 0, 0, 0, 0, 0, 0, 0, 0,
  ]);
  const [dobitakArr, setDobitakArr] = useState([0, 0, 0, 0, 0, 0, 0, 0, 0]);
  const [plusArr, setPlusArr] = useState([0, 0, 0, 0, 0, 0, 0, 0, 0]);

  const handleChange = (index, value) => {
    const newKvotaInputsArr = [...kvotaInputsArr];
    newKvotaInputsArr[index] = value;
    setKvotaInputsArr(newKvotaInputsArr);
  };

  useEffect(() => {
    const calcProcenatKvote = () => {
      const newProcenatKvoteArr = kvotaInputsArr.map((kvota) => {
        let procenat = (1 / kvota) * 100;
        if (procenat === Infinity) {
          procenat = 0;
        }
        return Number(procenat);
      });
      setProcenatKvoteArr(newProcenatKvoteArr);
    };
    calcProcenatKvote();

    const calcProfitKladionice = () => {
      const zbirProcenata = procenatKvoteArr.reduce(
        (accumulator, currentValue) => accumulator + currentValue,
        0
      );
      if (ukupanUlogUpis === 0) {
        setProfitKladionice("-");
      } else {
        const profit = zbirProcenata - 100;
        setProfitKladionice(profit);
      }
    };

    calcProfitKladionice();

    const calcProcenatPoTipuArr = () => {
      let delioc = 0;

      for (let i = 0; i < procenatKvoteArr.length; i++) {
        if (procenatKvoteArr[i] !== 0) {
          delioc++;
        }
      }

      const newProcenatPoTipuArr = procenatKvoteArr.map((kvota) => {
        let procenat = parseFloat(profitKladionice) / delioc;

        if (procenat === Infinity) {
          procenat = 0;
        }
        return Number(procenat);
      });

      // console.log(newProcenatPoTipuArr);

      setProcenatPoTipuArr(newProcenatPoTipuArr);
    };

    calcProcenatPoTipuArr();

    const calcUlogPoTipu = () => {
      const newUlogPoTipu = ulogPoTipuArr.map((value, index) => {
        return dobitakArr[index] / kvotaInputsArr[index];
      });

      setUlogPoTipuArr(newUlogPoTipu);
    };

    calcUlogPoTipu();

    const calcDobitak = () => {
      const dobitak = kvotaInputsArr.map((value, index) => {
        let r65 = parseFloat(ukupanUlogUpis);

        let aj65 = ukupanUlogUpis * (procenatKvoteArr[0] / 100);
        let aj66 = ukupanUlogUpis * (procenatKvoteArr[1] / 100);
        let aj67 = ukupanUlogUpis * (procenatKvoteArr[2] / 100);
        let aj68 = ukupanUlogUpis * (procenatKvoteArr[3] / 100);
        let aj69 = ukupanUlogUpis * (procenatKvoteArr[4] / 100);
        let aj70 = ukupanUlogUpis * (procenatKvoteArr[5] / 100);
        let aj71 = ukupanUlogUpis * (procenatKvoteArr[6] / 100);
        let aj72 = ukupanUlogUpis * (procenatKvoteArr[7] / 100);
        let aj73 = ukupanUlogUpis * (procenatKvoteArr[8] / 100);

        let profit =
          r65 - (aj65 + aj66 + aj67 + aj68 + aj69 + aj70 + aj71 + aj72 + aj73);

        let final = parseFloat(r65) - Math.abs(profit).toFixed(2);

        return final;
      });

      setDobitakArr(dobitak);
    };

    calcDobitak();

    const calcPlus = () => {
      const newPlusArr = dobitakArr.map((value) => {
        let final = value - ukupanUlogUpis;

        return Number(final);
      });

      setPlusArr(newPlusArr);
    };

    calcPlus();
  }, [kvotaInputsArr, procenatKvoteArr, ukupanUlogUpis, profitKladionice]);

  return (
    <div className={classes.container}>
      <Grupa1
        imgText="ARBITRAŽNI KALKULATOR"
        imgBackgroundColorDark="#FF0000"
        imgBackgroundColorLight="#FF0000"
        imgFontColor="#000000"
        label1="VREME POČETKA"
        label2="ZEMLJA"
        label3="DOMAĆIN"
        label4="TAKMIČENJE - LIGA"
        label5="GOST"
      />
      <Grupa3005
        imgBackgroundColorDark="#FF0000"
        imgBackgroundColorLight="#FF0000"
        imgFontColor="#000000"
        calculatorType={`Arbitrazni betko kalkulator`}
      />

      <table className={classes.table} style={{ fontWeight: "bolder" }}>
        <th>KVOTE PO IGRAMA</th>
        <th>UPIS KVOTE</th>
        <th>PROCENAT KVOTE (%)</th>
        <th>PROFIT KLADIONICE</th>
        <th>% PO TIPU</th>
        <th>UKUPAN ULOG - UPIS</th>
        <th>ULOG PO TIPU</th>
        <th>DOBITAK</th>
        <th>PLUS</th>
        <tr>
          <td style={{ height: "40px" }}>KVOTA 1</td>
          <td className={classes.white}>
            <input
              className={classes.smallerInput}
              onChange={(e) => handleChange(0, e.target.value)}
              type="text"
              value={kvotaInputsArr[0]}
            />
          </td>
          <td className={classes.blueviolet}>
            {isNaN(procenatKvoteArr[0]) ||
            procenatKvoteArr[0] === 0 ||
            procenatKvoteArr[0] === Infinity
              ? "-"
              : Number(procenatKvoteArr[0]).toFixed(2)}
            {isNaN(procenatKvoteArr[0]) ||
            procenatKvoteArr[0] === 0 ||
            procenatKvoteArr[0] === Infinity
              ? null
              : "%"}
          </td>
          <td
            className={classes.blueviolet}
            style={{ fontSize: "50px" }}
            rowSpan={9}
          >
            {isNaN(profitKladionice) ||
            profitKladionice === 0 ||
            profitKladionice === Infinity
              ? "-"
              : Number(profitKladionice).toFixed(2)}
            {isNaN(profitKladionice) ||
            profitKladionice === 0 ||
            profitKladionice === Infinity
              ? null
              : "%"}
          </td>
          <td className={classes.blueviolet}>
            {isNaN(procenatPoTipuArr[0]) ||
            procenatPoTipuArr[0] === 0 ||
            procenatPoTipuArr[0] === Infinity
              ? "-"
              : Number(procenatPoTipuArr[0]).toFixed(2)}
            {isNaN(procenatPoTipuArr[0]) ||
            procenatPoTipuArr[0] === 0 ||
            procenatPoTipuArr[0] === Infinity
              ? null
              : "%"}
          </td>
          <td className={classes.white} rowSpan={9}>
            <input
              className={classes.biggerInput}
              onChange={(e) => setUkupanUlogUpis(e.target.value)}
              type="text"
              value={ukupanUlogUpis}
            />
          </td>
          <td className={classes.blueviolet}>
            {isNaN(ulogPoTipuArr[0]) ||
            ulogPoTipuArr[0] === 0 ||
            ulogPoTipuArr[0] === Infinity
              ? "-"
              : Number(ulogPoTipuArr[0].toFixed(2))}
            {isNaN(ulogPoTipuArr[0]) ||
            ulogPoTipuArr[0] === 0 ||
            ulogPoTipuArr[0] === Infinity
              ? null
              : ""}
          </td>
          <td className={classes.blueviolet}>
            {isNaN(dobitakArr[0]) ||
            dobitakArr[0] === 0 ||
            dobitakArr[0] === Infinity
              ? "-"
              : Number(dobitakArr[0]).toFixed(2)}
            {isNaN(dobitakArr[0]) ||
            dobitakArr[0] === 0 ||
            dobitakArr[0] === Infinity
              ? null
              : ""}
          </td>
          <td className={classes.blueviolet}>
            {isNaN(plusArr[0]) || plusArr[0] === 0 || plusArr[0] === Infinity
              ? "-"
              : profitKladionice < 0
              ? Math.abs(Number(plusArr[0]).toFixed(2))
              : Number(plusArr[0]).toFixed(2)}
          </td>
        </tr>
        <tr>
          <td style={{ height: "40px" }}>KVOTA 2</td>
          <td className={classes.white}>
            <input
              className={classes.smallerInput}
              onChange={(e) => handleChange(1, e.target.value)}
              type="text"
              value={kvotaInputsArr[1]}
            />
          </td>
          <td className={classes.blueviolet}>
            {isNaN(procenatKvoteArr[1]) ||
            procenatKvoteArr[1] === 0 ||
            procenatKvoteArr[1] === Infinity
              ? "-"
              : Number(procenatKvoteArr[1]).toFixed(2)}
            {isNaN(procenatKvoteArr[1]) ||
            procenatKvoteArr[1] === 0 ||
            procenatKvoteArr[1] === Infinity
              ? null
              : "%"}
          </td>
          <td className={classes.blueviolet}>
            {isNaN(procenatPoTipuArr[1]) ||
            procenatPoTipuArr[1] === 0 ||
            procenatPoTipuArr[1] === Infinity
              ? "-"
              : Number(procenatPoTipuArr[1]).toFixed(2)}
            {isNaN(procenatPoTipuArr[1]) ||
            procenatPoTipuArr[1] === 0 ||
            procenatPoTipuArr[1] === Infinity
              ? null
              : "%"}
          </td>
          <td className={classes.blueviolet}>
            {isNaN(ulogPoTipuArr[1]) ||
            ulogPoTipuArr[1] === 0 ||
            ulogPoTipuArr[1] === Infinity
              ? "-"
              : Number(ulogPoTipuArr[1].toFixed(2))}
            {isNaN(ulogPoTipuArr[1]) ||
            ulogPoTipuArr[1] === 0 ||
            ulogPoTipuArr[1] === Infinity
              ? null
              : ""}
          </td>
          <td className={classes.blueviolet}>
            {isNaN(dobitakArr[1]) ||
            dobitakArr[1] === 0 ||
            dobitakArr[1] === Infinity
              ? "-"
              : Number(dobitakArr[1]).toFixed(2)}
            {isNaN(dobitakArr[1]) ||
            dobitakArr[1] === 0 ||
            dobitakArr[1] === Infinity
              ? null
              : ""}
          </td>
          <td className={classes.blueviolet}>
            {isNaN(plusArr[1]) || plusArr[1] === 0 || plusArr[1] === Infinity
              ? "-"
              : profitKladionice < 0
              ? Math.abs(Number(plusArr[1]).toFixed(2))
              : Number(plusArr[1]).toFixed(2)}
          </td>
        </tr>
        <tr>
          <td style={{ height: "40px" }}>KVOTA 3</td>
          <td className={classes.white}>
            <input
              className={classes.smallerInput}
              onChange={(e) => handleChange(2, e.target.value)}
              type="text"
              value={kvotaInputsArr[2]}
            />
          </td>
          <td className={classes.blueviolet}>
            {isNaN(procenatKvoteArr[2]) ||
            procenatKvoteArr[2] === 0 ||
            procenatKvoteArr[2] === Infinity
              ? "-"
              : Number(procenatKvoteArr[2]).toFixed(2)}
            {isNaN(procenatKvoteArr[2]) ||
            procenatKvoteArr[2] === 0 ||
            procenatKvoteArr[2] === Infinity
              ? null
              : "%"}
          </td>
          <td className={classes.blueviolet}>
            {isNaN(procenatPoTipuArr[2]) ||
            procenatPoTipuArr[2] === 0 ||
            procenatPoTipuArr[2] === Infinity
              ? "-"
              : Number(procenatPoTipuArr[2]).toFixed(2)}
            {isNaN(procenatPoTipuArr[2]) ||
            procenatPoTipuArr[2] === 0 ||
            procenatPoTipuArr[2] === Infinity
              ? null
              : "%"}
          </td>
          <td className={classes.blueviolet}>
            {isNaN(ulogPoTipuArr[2]) ||
            ulogPoTipuArr[2] === 0 ||
            ulogPoTipuArr[2] === Infinity
              ? "-"
              : Number(ulogPoTipuArr[2].toFixed(2))}
            {isNaN(ulogPoTipuArr[2]) ||
            ulogPoTipuArr[2] === 0 ||
            ulogPoTipuArr[2] === Infinity
              ? null
              : ""}
          </td>
          <td className={classes.blueviolet}>
            {isNaN(dobitakArr[2]) ||
            dobitakArr[2] === 0 ||
            dobitakArr[2] === Infinity
              ? "-"
              : Number(dobitakArr[2]).toFixed(2)}
            {isNaN(dobitakArr[2]) ||
            dobitakArr[2] === 0 ||
            dobitakArr[2] === Infinity
              ? null
              : ""}
          </td>
          <td className={classes.blueviolet}>
            {isNaN(plusArr[2]) || plusArr[2] === 0 || plusArr[2] === Infinity
              ? "-"
              : profitKladionice < 0
              ? Math.abs(Number(plusArr[2]).toFixed(2))
              : Number(plusArr[2]).toFixed(2)}
          </td>
        </tr>
        <tr>
          <td style={{ height: "40px" }}>KVOTA 4</td>
          <td className={classes.white}>
            <input
              className={classes.smallerInput}
              onChange={(e) => handleChange(3, e.target.value)}
              type="text"
              value={kvotaInputsArr[3]}
            />
          </td>
          <td className={classes.blueviolet}>
            {isNaN(procenatKvoteArr[3]) ||
            procenatKvoteArr[3] === 0 ||
            procenatKvoteArr[3] === Infinity
              ? "-"
              : Number(procenatKvoteArr[3]).toFixed(2)}
            {isNaN(procenatKvoteArr[3]) ||
            procenatKvoteArr[3] === 0 ||
            procenatKvoteArr[3] === Infinity
              ? null
              : "%"}
          </td>
          <td className={classes.blueviolet}>
            {isNaN(procenatPoTipuArr[3]) ||
            procenatPoTipuArr[3] === 0 ||
            procenatPoTipuArr[0] === Infinity
              ? "-"
              : Number(procenatPoTipuArr[3]).toFixed(2)}
            {isNaN(procenatPoTipuArr[3]) ||
            procenatPoTipuArr[3] === 0 ||
            procenatPoTipuArr[0] === Infinity
              ? null
              : "%"}
          </td>
          <td className={classes.blueviolet}>
            {isNaN(ulogPoTipuArr[3]) ||
            ulogPoTipuArr[3] === 0 ||
            ulogPoTipuArr[3] === Infinity
              ? "-"
              : Number(ulogPoTipuArr[3].toFixed(2))}
            {isNaN(ulogPoTipuArr[3]) ||
            ulogPoTipuArr[3] === 0 ||
            ulogPoTipuArr[3] === Infinity
              ? null
              : ""}
          </td>
          <td className={classes.blueviolet}>
            {isNaN(dobitakArr[3]) ||
            dobitakArr[3] === 0 ||
            dobitakArr[3] === Infinity
              ? "-"
              : Number(dobitakArr[3]).toFixed(2)}
            {isNaN(dobitakArr[3]) ||
            dobitakArr[3] === 0 ||
            dobitakArr[3] === Infinity
              ? null
              : ""}
          </td>
          <td className={classes.blueviolet}>
            {isNaN(plusArr[3]) || plusArr[3] === 0 || plusArr[3] === Infinity
              ? "-"
              : profitKladionice < 0
              ? Math.abs(Number(plusArr[3]).toFixed(2))
              : Number(plusArr[3]).toFixed(2)}
          </td>
        </tr>
        <tr>
          <td style={{ height: "40px" }}>KVOTA 5</td>
          <td className={classes.white}>
            <input
              className={classes.smallerInput}
              onChange={(e) => handleChange(4, e.target.value)}
              type="text"
              value={kvotaInputsArr[4]}
            />
          </td>
          <td className={classes.blueviolet}>
            {isNaN(procenatKvoteArr[4]) ||
            procenatKvoteArr[4] === 0 ||
            procenatKvoteArr[4] === Infinity
              ? "-"
              : Number(procenatKvoteArr[4]).toFixed(2)}
            {isNaN(procenatKvoteArr[4]) ||
            procenatKvoteArr[4] === 0 ||
            procenatKvoteArr[4] === Infinity
              ? null
              : "%"}
          </td>
          <td className={classes.blueviolet}>
            {isNaN(procenatPoTipuArr[4]) ||
            procenatPoTipuArr[4] === 0 ||
            procenatPoTipuArr[4] === Infinity
              ? "-"
              : Number(procenatPoTipuArr[4]).toFixed(2)}
            {isNaN(procenatPoTipuArr[4]) ||
            procenatPoTipuArr[4] === 0 ||
            procenatPoTipuArr[4] === Infinity
              ? null
              : "%"}
          </td>
          <td className={classes.blueviolet}>
            {isNaN(ulogPoTipuArr[4]) ||
            ulogPoTipuArr[4] === 0 ||
            ulogPoTipuArr[4] === Infinity
              ? "-"
              : Number(ulogPoTipuArr[4].toFixed(2))}
            {isNaN(ulogPoTipuArr[4]) ||
            ulogPoTipuArr[4] === 0 ||
            ulogPoTipuArr[4] === Infinity
              ? null
              : ""}
          </td>
          <td className={classes.blueviolet}>
            {isNaN(dobitakArr[4]) ||
            dobitakArr[4] === 0 ||
            dobitakArr[4] === Infinity
              ? "-"
              : Number(dobitakArr[4]).toFixed(2)}
            {isNaN(dobitakArr[4]) ||
            dobitakArr[4] === 0 ||
            dobitakArr[4] === Infinity
              ? null
              : ""}
          </td>
          <td className={classes.blueviolet}>
            {isNaN(plusArr[4]) || plusArr[4] === 0 || plusArr[4] === Infinity
              ? "-"
              : profitKladionice < 0
              ? Math.abs(Number(plusArr[4]).toFixed(2))
              : Number(plusArr[4]).toFixed(2)}
          </td>
        </tr>
        <tr>
          <td style={{ height: "40px" }}>KVOTA 6</td>
          <td className={classes.white}>
            <input
              className={classes.smallerInput}
              onChange={(e) => handleChange(5, e.target.value)}
              type="text"
              value={kvotaInputsArr[5]}
            />
          </td>
          <td className={classes.blueviolet}>
            {isNaN(procenatKvoteArr[5]) ||
            procenatKvoteArr[5] === 0 ||
            procenatKvoteArr[5] === Infinity
              ? "-"
              : Number(procenatKvoteArr[5]).toFixed(2)}
            {isNaN(procenatKvoteArr[5]) ||
            procenatKvoteArr[5] === 0 ||
            procenatKvoteArr[5] === Infinity
              ? null
              : "%"}
          </td>
          <td className={classes.blueviolet}>
            {isNaN(procenatPoTipuArr[5]) ||
            procenatPoTipuArr[5] === 0 ||
            procenatPoTipuArr[5] === Infinity
              ? "-"
              : Number(procenatPoTipuArr[5]).toFixed(2)}
            {isNaN(procenatPoTipuArr[5]) ||
            procenatPoTipuArr[5] === 0 ||
            procenatPoTipuArr[5] === Infinity
              ? null
              : "%"}
          </td>
          <td className={classes.blueviolet}>
            {isNaN(ulogPoTipuArr[5]) ||
            ulogPoTipuArr[5] === 0 ||
            ulogPoTipuArr[5] === Infinity
              ? "-"
              : Number(ulogPoTipuArr[5].toFixed(2))}
            {isNaN(ulogPoTipuArr[5]) ||
            ulogPoTipuArr[5] === 0 ||
            ulogPoTipuArr[5] === Infinity
              ? null
              : ""}
          </td>
          <td className={classes.blueviolet}>
            {isNaN(dobitakArr[5]) ||
            dobitakArr[5] === 0 ||
            dobitakArr[5] === Infinity
              ? "-"
              : Number(dobitakArr[5]).toFixed(2)}
            {isNaN(dobitakArr[5]) ||
            dobitakArr[5] === 0 ||
            dobitakArr[5] === Infinity
              ? null
              : ""}
          </td>
          <td className={classes.blueviolet}>
            {isNaN(plusArr[5]) || plusArr[5] === 0 || plusArr[5] === Infinity
              ? "-"
              : profitKladionice < 0
              ? Math.abs(Number(plusArr[5]).toFixed(2))
              : Number(plusArr[5]).toFixed(2)}
          </td>
        </tr>
        <tr>
          <td style={{ height: "40px" }}>KVOTA 7</td>
          <td className={classes.white}>
            <input
              className={classes.smallerInput}
              onChange={(e) => handleChange(6, e.target.value)}
              type="text"
              value={kvotaInputsArr[6]}
            />
          </td>
          <td className={classes.blueviolet}>
            {isNaN(procenatKvoteArr[6]) ||
            procenatKvoteArr[6] === 0 ||
            procenatKvoteArr[6] === Infinity
              ? "-"
              : Number(procenatKvoteArr[6]).toFixed(2)}
            {isNaN(procenatKvoteArr[6]) ||
            procenatKvoteArr[6] === 0 ||
            procenatKvoteArr[6] === Infinity
              ? null
              : "%"}
          </td>
          <td className={classes.blueviolet}>
            {isNaN(procenatPoTipuArr[6]) ||
            procenatPoTipuArr[6] === 0 ||
            procenatPoTipuArr[6] === Infinity
              ? "-"
              : Number(procenatPoTipuArr[6]).toFixed(2)}
            {isNaN(procenatPoTipuArr[6]) ||
            procenatPoTipuArr[6] === 0 ||
            procenatPoTipuArr[6] === Infinity
              ? null
              : "%"}
          </td>
          <td className={classes.blueviolet}>
            {isNaN(ulogPoTipuArr[6]) ||
            ulogPoTipuArr[6] === 0 ||
            ulogPoTipuArr[6] === Infinity
              ? "-"
              : Number(ulogPoTipuArr[6].toFixed(2))}
            {isNaN(ulogPoTipuArr[6]) ||
            ulogPoTipuArr[6] === 0 ||
            ulogPoTipuArr[6] === Infinity
              ? null
              : ""}
          </td>
          <td className={classes.blueviolet}>
            {isNaN(dobitakArr[6]) ||
            dobitakArr[6] === 0 ||
            dobitakArr[6] === Infinity
              ? "-"
              : Number(dobitakArr[6]).toFixed(2)}
            {isNaN(dobitakArr[6]) ||
            dobitakArr[6] === 0 ||
            dobitakArr[6] === Infinity
              ? null
              : ""}
          </td>
          <td className={classes.blueviolet}>
            {isNaN(plusArr[6]) || plusArr[6] === 0 || plusArr[6] === Infinity
              ? "-"
              : profitKladionice < 0
              ? Math.abs(Number(plusArr[6]).toFixed(2))
              : Number(plusArr[6]).toFixed(2)}
          </td>
        </tr>
        <tr>
          <td style={{ height: "40px" }}>KVOTA 8</td>
          <td className={classes.white}>
            <input
              className={classes.smallerInput}
              onChange={(e) => handleChange(7, e.target.value)}
              type="text"
              value={kvotaInputsArr[7]}
            />
          </td>
          <td className={classes.blueviolet}>
            {isNaN(procenatKvoteArr[7]) ||
            procenatKvoteArr[7] === 0 ||
            procenatKvoteArr[7] === Infinity
              ? "-"
              : Number(procenatKvoteArr[7]).toFixed(2)}
            {isNaN(procenatKvoteArr[7]) ||
            procenatKvoteArr[7] === 0 ||
            procenatKvoteArr[7] === Infinity
              ? null
              : "%"}
          </td>
          <td className={classes.blueviolet}>
            {isNaN(procenatPoTipuArr[7]) ||
            procenatPoTipuArr[7] === 0 ||
            procenatPoTipuArr[7] === Infinity
              ? "-"
              : Number(procenatPoTipuArr[7]).toFixed(2)}
            {isNaN(procenatPoTipuArr[7]) ||
            procenatPoTipuArr[7] === 0 ||
            procenatPoTipuArr[7] === Infinity
              ? null
              : "%"}
          </td>
          <td className={classes.blueviolet}>
            {isNaN(ulogPoTipuArr[7]) ||
            ulogPoTipuArr[7] === 0 ||
            ulogPoTipuArr[7] === Infinity
              ? "-"
              : Number(ulogPoTipuArr[7].toFixed(2))}
            {isNaN(ulogPoTipuArr[7]) ||
            ulogPoTipuArr[7] === 0 ||
            ulogPoTipuArr[7] === Infinity
              ? null
              : ""}
          </td>
          <td className={classes.blueviolet}>
            {isNaN(dobitakArr[7]) ||
            dobitakArr[7] === 0 ||
            dobitakArr[7] === Infinity
              ? "-"
              : Number(dobitakArr[7]).toFixed(2)}
            {isNaN(dobitakArr[7]) ||
            dobitakArr[7] === 0 ||
            dobitakArr[7] === Infinity
              ? null
              : ""}
          </td>
          <td className={classes.blueviolet}>
            {isNaN(plusArr[7]) || plusArr[7] === 0 || plusArr[7] === Infinity
              ? "-"
              : profitKladionice < 0
              ? Math.abs(Number(plusArr[7]).toFixed(2))
              : Number(plusArr[7]).toFixed(2)}
          </td>
        </tr>
        <tr>
          <td style={{ height: "40px" }}>KVOTA 9</td>
          <td className={classes.white}>
            <input
              className={classes.smallerInput}
              onChange={(e) => handleChange(8, e.target.value)}
              type="text"
              value={kvotaInputsArr[8]}
            />
          </td>
          <td className={classes.blueviolet}>
            {isNaN(procenatKvoteArr[8]) ||
            procenatKvoteArr[8] === 0 ||
            procenatKvoteArr[8] === Infinity
              ? "-"
              : Number(procenatKvoteArr[8]).toFixed(2)}
            {isNaN(procenatKvoteArr[8]) ||
            procenatKvoteArr[8] === 0 ||
            procenatKvoteArr[8] === Infinity
              ? null
              : "%"}
          </td>
          <td className={classes.blueviolet}>
            {isNaN(procenatPoTipuArr[8]) ||
            procenatPoTipuArr[8] === 0 ||
            procenatPoTipuArr[8] === Infinity
              ? "-"
              : Number(procenatPoTipuArr[8]).toFixed(2)}
            {isNaN(procenatPoTipuArr[8]) ||
            procenatPoTipuArr[8] === 0 ||
            procenatPoTipuArr[8] === Infinity
              ? null
              : "%"}
          </td>
          <td className={classes.blueviolet}>
            {isNaN(ulogPoTipuArr[8]) ||
            ulogPoTipuArr[8] === 0 ||
            ulogPoTipuArr[8] === Infinity
              ? "-"
              : Number(ulogPoTipuArr[8].toFixed(2))}
            {isNaN(ulogPoTipuArr[8]) ||
            ulogPoTipuArr[8] === 0 ||
            ulogPoTipuArr[8] === Infinity
              ? null
              : ""}
          </td>
          <td className={classes.blueviolet}>
            {isNaN(dobitakArr[8]) ||
            dobitakArr[8] === 0 ||
            dobitakArr[8] === Infinity
              ? "-"
              : Number(dobitakArr[8]).toFixed(2)}
            {isNaN(dobitakArr[8]) ||
            dobitakArr[8] === 0 ||
            dobitakArr[8] === Infinity
              ? null
              : ""}
          </td>
          <td className={classes.blueviolet}>
            {isNaN(plusArr[8]) || plusArr[8] === 0 || plusArr[8] === Infinity
              ? "-"
              : profitKladionice < 0
              ? Math.abs(Number(plusArr[8]).toFixed(2))
              : Number(plusArr[8]).toFixed(2)}
          </td>
        </tr>
      </table>
    </div>
  );
};

export default ArbitrazniKalkulator;
