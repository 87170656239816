import React, { useContext, useEffect, useState } from 'react'
import classes from './GodisnjaTabela.module.css'
import MisterTipsterDefaultTable from '../components/MisterTipsterDefaultTable'
import axios from 'axios'
import AuthContext from '../context/AuthProvider'
import { message } from 'antd'
const SERVER_URL = process.env.REACT_APP_SERVER_URL

const GodisnjaTabela = () => {
  const [yearTableData, setYearTableDate] = useState([[], []])
  const [searchTerm, setSearchTerm] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const searchParams = new URLSearchParams(window.location.search)
  const year = searchParams.get('period')
  const page = searchParams.get('page')
  const { auth } = useContext(AuthContext)

  useEffect(() => {
    const fetchTableData = async () => {
      setIsLoading(true)
      try {
        const response = await axios.get(
          `${SERVER_URL}/user/get-year-table/${
            auth?._id || 'noId'
          }/${page}/${year}/${searchTerm || null}`
        )
        setYearTableDate(response?.data)
      } catch (error) {
        setYearTableDate([[], []])
        message.error(
          error?.response?.data?.message ||
            'Greška pri preuzimanju podataka, molimo pokušajte kasnije!'
        )
      }
      setIsLoading(false)
    }

    if (year && page) {
      fetchTableData()
    }
  }, [year, page, searchTerm])

  return (
    <div className={classes.yearTableContainer}>
      <MisterTipsterDefaultTable
        data={yearTableData}
        period='year'
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        isLoading={isLoading}
      />
    </div>
  )
}

export default GodisnjaTabela
