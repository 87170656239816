import classes from "./grupa3005.module.css";
import Axios from "axios";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
const SERVER_URL = process.env.REACT_APP_SERVER_URL;

const Grupa3005 = ({
  imgBackgroundColorDark,
  imgBackgroundColorLight,
  imgFontColor,
  label1,
  calculatorType,
}) => {
  const navigate = useNavigate();
  let [tutorialLink, setTutorialLink] = useState("");
  const handlePitaj = () => {
    window.open("https://m.me/betkobet1x2", "_blank");
  };

  useEffect(() => {
    fetchLink();
  }, []);

  const fetchLink = async () => {
    try {
      const tutorial = await Axios.get(
        `${SERVER_URL}/tutorial-calcname?name=${calculatorType}`
      );

      if (tutorial && tutorial.data && tutorial.data._id) {
        setTutorialLink(tutorial.data._id);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleOpiskalkulatora = () => {
    window.open(`../../bet_kalkulatori_tutorijal/${tutorialLink}`, "_blank");
  };

  return (
    <div className={classes.container}>
      <div
        style={{
          background: `linear-gradient(${imgBackgroundColorDark}, ${imgBackgroundColorLight})`,
          color: imgFontColor,
          display: "flex",
        }}
      >
        <div className={classes.left}>
          <div className={classes.segment}>
            <a href="#" className={classes.tooltip}>
              <div style={{ color: imgFontColor }}>
                BETKO - PROČITAJ PRAVILA ORGANIZATORA IGRE ! ! !
              </div>
            </a>
            <div>{label1}</div>
          </div>
        </div>
        <div className={classes.right}>
          <button
            onClick={() => handleOpiskalkulatora()}
            className={classes.btn1}
          >
            OPIS KALKULATORA
          </button>
          <button onClick={() => handlePitaj()} className={classes.btn2}>
            PITAJ
          </button>
        </div>
      </div>
    </div>
  );
};

export default Grupa3005;
