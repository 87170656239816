import React, { useContext, useEffect, useState } from 'react'
import classes from './ProductPreview.module.css'
import CartContext from '../../context/CartContext'
import { message } from 'antd'
import { format } from 'date-fns'
import useCountdown from '../../hooks/useCountdown'
import { useNavigate, useSearchParams } from 'react-router-dom'
import formatPrice from '../../utils/FormatPriceCurrency'
import AuthContext from '../../context/AuthProvider'
import LoginModal from '../LoginModal'

const SERVER_URL = process.env.REACT_APP_SERVER_URL

const ProductPreview = ({
  product,
  setSelecteImagePreview,
  setSelectedProduct,
}) => {
  const [isExpanded, setIsExpanded] = useState(false)
  const [quantity, setQuantity] = useState(1)
  const [atrDesc, setAtrDesc] = useState('')
  const { addToCart, cart } = useContext(CartContext)
  const timeRemaining = useCountdown(product?.expireDate)
  const [searchParams, setSearchParams] = useSearchParams()
  const isExpired = timeRemaining === '00:00:00'
  const isSportTourist = product?.shopType === 'sport-tourist'
  const isAuction = product.shopType === 'auction'
  const [isOpenLoginModal, setIsOpenLoginModal] = useState(false)
  const { auth } = useContext(AuthContext)

  const navigate = useNavigate()

  const displayDescription = (text) => {
    return isExpanded
      ? text
      : `${text?.slice(0, 160)}${text.length > 160 ? '...' : ''}`
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    if (!auth?._id) {
      setIsOpenLoginModal(true)
      return
    }
    message.success('Uspešno ste dodali proizvod u korpu!')
    addToCart(product._id, quantity, atrDesc)
  }

  useEffect(() => {
    const existingProduct = cart.find((item) => item.id === product._id)

    if (existingProduct) {
      setQuantity(existingProduct.quantity || 1)
      setAtrDesc(existingProduct.atrDesc || '')
    }
  }, [cart, product?._id])

  return (
    <div className={classes.productPreview}>
      <div className={classes.productImageWrapperDesktop}>
        <img
          src={`${SERVER_URL}/${product?.images?.url}` || '/tiger.webp'}
          alt={product?.title}
          onClick={() =>
            setSelecteImagePreview(
              `${SERVER_URL}/${product?.images?.url}` || '/tiger.webp'
            )
          }
        />
      </div>
      <div className={classes.productInfo}>
        <button
          className={classes.closeBtn}
          onClick={() => {
            setSelectedProduct(null)
            const newParams = new URLSearchParams(searchParams)
            newParams.delete('productId')
            setSearchParams(newParams)
          }}
        >
          <img src='/cancel.png' alt='cancel' />
          <span>IZLAZ IZ PROIZVODA</span>
        </button>
        <span className={classes.productCategory}>
          {product?.category?.name}
        </span>
        {isSportTourist && (
          <p className={classes.tourDate}>
            OD {format(new Date(product?.startTourDate), 'dd.MM.yyyy.')} DO{' '}
            {format(new Date(product?.endTourDate), 'dd.MM.yyyy.')}
          </p>
        )}
        {isAuction && (
          <span className={classes.auctionExpireDate}>
            TRAJANJE LICITACIJE:{' '}
            {format(new Date(product?.expireDate), 'dd.MM.yyyy. HH:mm')}
          </span>
        )}
        <span className={classes.productTitle}>{product?.title}</span>
        <div className={classes.productImageWrapperMobile}>
          <img
            src={`${SERVER_URL}/${product?.images?.url}` || '/tiger.webp'}
            alt={product?.title}
          />
        </div>
        <div className={classes.productPriceSeparator}>
          <span>
            {product?.cardNumber
              ? 'UPLATI'
              : product?.sms
              ? 'POŠALJI'
              : isAuction
              ? 'TRENUTNA CENA'
              : 'CENA'}
          </span>
        </div>
        <div className={classes.productPrice}>
          <span>
            {product?.cardNumber || product?.sms || formatPrice(product?.price)}
          </span>
          {!product?.cardNumber && !product?.sms && (
            <p>OKVIRNO U EURIMA: {(product?.price / 117).toFixed(2)} €</p>
          )}
        </div>
        <div
          className={`${classes.productStock} ${
            (!product?.onStock && !isAuction) || (isAuction && isExpired)
              ? classes.outOfStock
              : ''
          }`}
        >
          <span>
            {isAuction
              ? timeRemaining
              : product?.onStock
              ? `${isSportTourist ? 'REZERVIŠI MESTO' : 'IMA NA STANJU'}`
              : `${isSportTourist ? 'POPUNJENO' : 'NEMA NA STANJU'}`}
          </span>
        </div>
        {product?.onStock && !isAuction && (
          <form onSubmit={handleSubmit} className={classes.attributesForm}>
            <div className={classes.formQuantityItem}>
              <div className={classes.atrTitle}>UNESI ATRIBUTE</div>
              <div className={classes.atrQuantityField}>
                <label>KOLIČINA: </label>
                <input
                  type='text'
                  id='quantity'
                  name='quantity'
                  pattern='[0-9]*'
                  min='1'
                  value={quantity}
                  onChange={(e) => setQuantity(e.target.value)}
                  onInvalid={(e) =>
                    e.target.setCustomValidity('Molimo unesite ispravan broj')
                  }
                  onInput={(e) => e.target.setCustomValidity('')} // Clear the error message on input
                  required
                />
              </div>
            </div>

            <div className={classes.formDescItem}>
              <textarea
                id='atrDesc'
                name='atrDesc'
                rows='7'
                value={atrDesc}
                placeholder={`\nKLIKNI I UPIŠI\n\nPRIMER UPISA\n...Želim da poručim majicu crvene boje veličine XL, i majicu zelene boje veličine XXL... `}
                onChange={(e) => setAtrDesc(e.target.value)}
              />
            </div>

            {isSportTourist ? (
              <button
                className={classes.addToCartBtn}
                type='button'
                onClick={() => {
                  if (auth?._id) {
                    navigate('/betko_shop/moja-korpa', {
                      state: {
                        sportTour: product,
                        tourQuantity: quantity,
                        tourDesc: atrDesc,
                      },
                    })
                  } else {
                    setIsOpenLoginModal(true)
                  }
                }}
              >
                <span>KLIKNI I REZERVIŠI</span>
              </button>
            ) : (
              <button type='submit' className={classes.addToCartBtn}>
                <span>KLIKNI I DODAJ U KORPU</span>
              </button>
            )}
          </form>
        )}
        {isAuction && !isExpired && !product.cardNumber && !product?.sms && (
          <button
            className={classes.addToCartBtn}
            type='button'
            onClick={() => {
              if (auth._id) {
                navigate('/betko_shop/moja-korpa', {
                  state: { auction: product },
                })
              } else {
                setIsOpenLoginModal(true)
              }
            }}
          >
            <span>KLIKNI I LICITIRAJ</span>
          </button>
        )}
        <div className={classes.productDescription}>
          <p className={classes.productDescription}>
            {displayDescription(product?.description || '')}
          </p>
          {product?.description?.length > 160 && (
            <button
              className={classes.seeMoreBtn}
              onClick={() => setIsExpanded((curr) => !curr)}
            >
              {isExpanded ? 'PRIKAŽI MANJE' : 'SAZNAJ VIŠE'}
            </button>
          )}
        </div>
        {product?.attributes?.length > 0 && !isAuction && (
          <table className={classes.attributesTable}>
            <thead>
              <th>ATRIBUTI</th>
              <th>DOSTUPNO</th>
            </thead>
            <tbody>
              {product.attributes.map((atr) => (
                <tr key={atr._id}>
                  <td>{atr.name}</td>
                  <td>{atr.value.join(', ')}</td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
      <LoginModal
        setIsOpenLoginModal={setIsOpenLoginModal}
        isOpenLoginModal={isOpenLoginModal}
        title='Da bi ste nastavili morate biti ulogovani !'
      />
    </div>
  )
}

export default ProductPreview
