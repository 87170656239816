import React from 'react'
import classes from './BackButton.module.css'
import { useNavigate } from 'react-router-dom'
import { IoMdArrowDropleft } from 'react-icons/io'

const BackButton = () => {
  const navigate = useNavigate()
  return (
    <div className={classes.backBtnWrapper}>
      <button
        className={classes.backButton}
        onClick={() => {
          navigate(-1)
        }}
      >
        <IoMdArrowDropleft />
        <span>NAZAD</span>
      </button>
    </div>
  )
}

export default BackButton
