import { Outlet } from 'react-router-dom'
import Header from '../components/Header'
import Footer from '../components/Footer'
import TitleNav from '../components/TitleNav'
import BetKurs from '../components/BetKurs'
import BetKalkulatori from '../components/BetKalkulatori'
import classes from './homeLayout.module.css'

const HomeLayout = ({ betkoShop }) => {
  return (
    <>
      <Header />
      <TitleNav />
      <div className={classes.container}>
        <BetKurs />
        {betkoShop || <Outlet />}
        <BetKalkulatori />
      </div>
      <Footer />
    </>
  )
}

export default HomeLayout
