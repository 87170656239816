import classes from "./aboutUs.module.css";

const AboutUs = () => {
  return (
    <div className={classes.mainCenter}>
      {/* <div id="disqus_recommendations"></div> */}

      <p>
        Naša referenca je naše znanje u oblasti sportskog klađenja, klađenja i
        sporta uopšteno. Znanje u oblasti kojom se profesionalno bavimo, sticali
        smo kroz studiozni rad na proučavanju svih sekvenci statistike.
        Istraživanja kroz primenjenu statističku matematiku, rad na statističkim
        distribucijama, pravilnim obradama podataka, logičnim rasuđivanjima
        statističkih parametara i podataka, proučavanju rada samih organizatora
        igre, načina funkcionisanja istih, njihovih softwera, psihologiji
        igrača, i na još mnogo toga, jesu nešto čemu smo posvetili svoj
        višegodišnji istraživački rad. Funkcija našeg sajta je usmerena ka tome
        da vas kroz podelu našeg znanja on učini boljim igračima nego što ste to
        bili pre nego što ste počeli da se družite sa nama. Funkcija našeg sajta
        je i da organizatore igre učini boljima u sprovođenju organizacije iste.{" "}
      </p>
    </div>
  );
};

export default AboutUs;
