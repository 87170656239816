const PravilaIgreData = {
  'otvaranje-profila': {
    title: 'OTVARANJE PROFILA',
    texts: [
      'JEDNOSTAVNIM PROCESOM REGISTRACIJE NA BETKOTIP.COM AUTOMATSKI SE OTVARA I VAŠ PROFIL ZA “MISTER TIPSTER” IGRU, SA ČIM POSTAJETE TAKMIČAR U OKVIRU ISTE.',
    ],
  },
  'email-notifikacije': {
    title: 'E-MAIL NOTIFIKACIJE',
    texts: [
      'NA SVOM “MISTER TIPSTER” PROFILU MOŽETE DA ISKLJUČITE E-MAIL OBAVEŠTENJA ZA ČEKIRANJE OPCIJE. TO NIJE PREPORUKA, JER VAM OBAVEŠTENJA POMAŽU U SPROVOĐENJU IGRE I DAJU VAM TAKMIČARSKU PREDNOST ZA OSVAJANJE TAKMIČARSKIH NAGRADA, JER STE SA SVAKIM NEODIGRANIM “TIPSTER MATCH-OM” U ZAOSTATKU ZA OSTALIM TAKMIČARIMA.',
      'REGISTRACIJOM NA BETKOTIP.COM STARTNA JE POSTAVKA DA VAM STIŽU E-MAIL OBAVEŠTENJA NA DNEVNOM NIVOU ZA VAS, ZA SVAKODNEVNI  “TIPSTER MATCH” PROGRAM.',
    ],
  },
  'tipster-match': {
    title: 'TIPSTER MATCH',
    texts: [
      'SVAKODNEVNO IMATE SVOJ “TIPSTER MATCH”, KOJI JE POTREBNO DA ODIGRATE TAKO ŠTO ĆETE JEDNIM KLIKOM PROGZNOZIRATI ISHODE UTAKMICA KOJE SU VAM POSTAVLJENE ZA TAJ DAN.',
      'ZBIR KVOTA PAROVA KOJE STE POGODILI, PRENOSI SE U TABELE TAKMIČENJA, NA OSNOVU ČEGA SE PREMA UKUPNOM ZBIRU U OKVIRU SVAKOG OD TAKMIČENJA ODREĐUJE VAŠ I PLASMAN DRUGIH IGRAČA.',
      'NA ”TIPSTER TABELAMA” POBEĐUJE ONAJ TAKMIČAR ČIJI JE ZBIR POGOĐENIH KVOTA NAJVEĆI U ODNOSU NA DRUGE TAKMIČARE U “TIPSTER MATCH-EVIMA”.',
      'UKOLIKO JE ZBIR POGOĐENIH KVOTA U “TIPSTER TABELAMA” JEDNAK KOD DVA ILI VIŠE  UČESNIKA "MISTER-TIPSTER TAKMIČENJA", PREDNOST NA TABELI U PLASMANU IMAJU TAKMIČARI KOJI SU SE RANIJE REGISTROVALI NA BETKOTIP.COM. ',
      'U SLUČAJU ODLOŽENE FUDBALSKE UTAKMICE KOJA SE NALAZI U ”TIPSTER MATCH-U” , ZA ISTU  SE RAČUNA KVOTA 0.00 , KAO I U SLUČAJU PREKIDA ISTE U BILO KOM PERIODU UTAKMICE.',
      'UKOLIKO SE TAKMIČAR NE POJAVI I NE ODIGRA SVOJ ”TIPSTER MATCH”, ON NEĆE DOBITI POENE, JER IH NIJE NI OSVOJIO, SHODNO TOME DA NIJE POSTAVIO SVOJE TIPOVE. IGRAJTE KAKO BISTE OSVAJALI POENE I BILI KONKURENTI U TAKMIČENJIMA ZA NAGRADE.',
      'TAKMIČAR U ”TIPSTER MATCH-U” SAMO JEDAN PUT MOŽE DA OBELEŽI IZABRANI TIP NA PRIKAZANU UTAKMICU. TAKMIČAR NE MOŽE DA MENJA TIP NAKON ŠTO SE ODLUČIO ZA SVOJ IZBOR ISTOG. NAKON ŠTO TAKMIČAR OBELEŽI TIP, NA SVAKOM OBELEŽENOM TIPU KOCKICA KOJU JE OBELEŽIO POSTAĆE ŽUTE BOJE. ',
      'NAKON ZAVRŠETKA “TIPSTER MATCH-A” POGOĐENI ISHODI ĆE BITI OBELEŽENI ZELENOM BOJOM KOCKICE, A PROMAŠENI CRVENOM BOJOM KOCKICE.',
      'ISHOD SVOG ”TIPSTER MATCH-A” MOŽETE POGLEDATI DIREKTNOM POSETOM BETKOTIP.COM, ULAZOM NA SVOJ “MISTER TIPSTER” NALOG. NAKON ŠTO SE UTAKMICE ZAVRŠE, POSTAVKOM NOVIH "TIPSTER MATCH-EVA ZA SLEDEĆI DAN, UTAKMICE OD PROŠLOG DANA NALAZIĆE SE NA VAŠEM "MISTER TIPSTER PROFILU", U ARHIVIRANOM DELU UTAKMICA.',
    ],
  },
  'tipster-rang-takmicenje': {
    title: 'TIPSTER RANG TAKMIČENJE',
    texts: [
      'TIPSTER RANG TAKMIČENJE ČINE LIGE OD PO DESET ( 10 ) “MISTER TIPSTER” TAKMIČARA.',
      'ZBIR OSVOJENIH BODOVA (ZBIR POGOĐENIH KVOTA)  IZ “TIPSTER MATCH” DUELA U ZBIRU PREDSTAVLJAJU BROJ OSVOJENIH BODOVA.',
      'CIKLUS JEDNOG “TIPSTER RANG TAKMICENJA” TRAJE 10 DANA U KOJIMA TAKMIČAR TIPUJE ISHODE POSTAVLJENIH UTAKMICA SA POSTAVLJENIM BETKO KVOTAMA.',
      'POZICIJE NA TABELI “RANG TAKMIČENJA”, ODREĐUJE BROJ OSVOJIENIH BODOVA (ZBIR POGOĐENIH KVOTA) IZ CIKLUSA AKTUELNE LIGE U “TIPSTER MATCH” TAKMIČENJU. ',
      'UKOLIKO DVA TAKMIČARA OSVOJE ISTI BROJ BODOVA, PREDNOST U VIŠEM PLASMANU IMA TAKMIČAR KOJI SE RANIJE REGISTROVAO NA BETKOTIP.COM.',
      'POZICIJE U RANGOVIMA SE MENJAJU KONTINUIRANO. NASPRAM REZULTATA KOJE OSTVARUJU TAKMIČARI, POZICIJE SE MENJAJU IZ MOMENTA U MOMENAT.',
      'NAKON ZAVRŠENOG CIKLUSA OD DESET DANA, BODOVI U RANG TABELI SE ANULIRAJU, TAKMIČARI ZAPOČINJU NOVI DESETODNEVNI CIKLUS TAKMIČENJA, NA POTPUNO RAVNOPRAVNIM BODOVNIM, ANULIRANIM OSNOVAMA.',
      'PRVI TAKMIČAR IZ “RANGA 1”, NAKON SVAKOG ZAVRŠENOG DESETODNEVNOG CIKLUSA DOBIJA OSVOJENU TAKMIČARSKU NAGRADU, POSTAVLJENU  I ODREĐENU ZA SVAKI CIKLUS PRE POČETKA TAKMIČENJA U MENIJU "FOND TAKMIČARSKIH NAGRADA".',
      'TABELA IZ PRETHODNOG  CIKLUSA OSTAJE U PRIKAZU U ARHIVI, DOBITNIK JE DUŽAN DA SE JAVI DO ISTEKA ROKA ZA PREUZIMANJE NAGRADA ( KORISNIK JE DUŽAN DA SE JAVI ADMINISTRACIJI SAJTA E-MAILOM NA KOM MU JE JE REGISTROVAN NALOG NA BETKOTIP.COM U ROKU OD NAJVIŠE 10 DANA ZA PREUZIMANJE ILI DOSTAVU NAGRADA )',
    ],
  },
  'tipster-mesecno-takmicenje': {
    title: 'TIPSTER MESEČNO TAKMIČENJE',
    texts: [
      'U “TIPSTER MESEČNOM TAKMIČENJU” UČESTVUJU SVI TAKMIČARI.',
      'U “TIPSTER MESEČNOM TAKMIČENJU” TABELA JE UJEDINJENA ZA SVE TAKMIČARE.',
      'U “TIPSTER MESEČNOM TAKMIČENJU” ZBRAJAJU SE SVI OSVOJENI BODOVI NA MESEČNOM NIVOU U OKVIRU SVIH ODIGRANIH “TIPSTER MATCH-EVA”, SVIH TAKMIČARA IZ SVIH RANGOVA ISTOG U ISTAKNUTOM TRJANJU TAKMIČARSKE IGRE ("FOND ZA TAKMIČARSKE NAGRADE").',
      'POZICIJE NA TABELI “TIPSTER MESEČNOG TAKMIČENJA”, ODREĐUJE BROJ OSVOJENIH BODVOA (ZBIR POGOĐENIH KVOTA) IZ CIKLUSA U  “TIPSTER MATCH” TAKMIČENJU U ROKU TRAJANJA TAKMIČENJA. ',
      'UKOLIKO DVA TAKMIČARA OSVOJE ISTI BROJ BODOVA (ZBIR POGOĐENIH KVOTA), PREDNOST U VIŠEM PLASMANU IMA TAKMIČAR KOJI SE RANIJE REGISTROVAO NA BETKOTIP.COM',
      'CIKLUS JEDNOG “TIPSTER MESEČNOG TAKMIČENJA” TRAJE OKVIRNO OD 01. DANA U MESECU DO 01. DANA U SLEDEĆEM MESECU, TJ. PRECIZNO TRI CIKLUSA ZAVRŠENOG RANG TAKMIČENJA, NAKON ČEGA SE TABELA ANULIRA, PRI ČEMU SLEDEĆI CIKLUS SVI TAKMIČARI POČINJU NA RAVNOPRAVNIM OSNOVAMA SA ISTIM BROJEM BODOVA, NULA ( 0 )',
      'PRVE TRI POZICIJE NA TABELI “TIPSTER MESEČNOG TAKMIČENJA”, OSVAJAJU NAGRADE PRIKAZANE U FONDU NAGRADA PRE POČETKA TAKMIČENJA.',
      'TABELA IZ PRETHODNOG MESECA CIKLUSA OSTAJE U PRIKAZU, KORISNICI SU DUŽNI DA SE JAVE DO ISTEKA ROKA ZA PREUZIMANJE NAGRADA ( KORISNIK JE DUŽAN DA SE JAVI ADMINISTRACIJII SAJTA E-MAILOM NA KOM MU JE JE REGISTROVAN NALOG NA BETKOTIP.COM U ROKU OD NAJVIŠE 25 DANA ZA PREUZIMANJE ILI DOSTAVU NAGRADA )',
    ],
  },
  'tipster-godisnje-takmicenje': {
    title: 'TIPSTER GODIŠNJE TAKMIČENJE',
    texts: [
      'U “TIPSTER GODIŠNJEM TAKMIČENJU” UČESTVUJU SVI TAKMIČARI.',
      'U “TIPSTER GODIŠNJEM TAKMIČENJU” TABELA JE UJEDINJENA ZA SVE TAKMIČARE.',
      'U “TIPSTER GODIŠNJEM TAKMIČENJU” ZBRAJAJU SE SVI OSVOJENI POENI NA MESEČNOM NIVOU U OKVIRU SVIH ODIGRANIH “TIPSTER MATCH”, SVIH TAKMIČARA IZ SVIH RANGOVA ISTOG U NAZNAČENOM TRAJANJU GODIŠNJEG CIKLUSA.',
      'POZICIJE NA TABELI “TIPSTER GODISNJEG TAKMIČENJA”, ODREĐUJE BROJ OSVOJENIH BODOVA  (ZBIR POGOĐENIH KVOTA) IZ CIKLUSA U  “TIPSTER MATCH” TAKMIČENJU U NAZNAČENOM PERIODU TAKMIČENJA ("FOND ZA OSVOJENE NAGRADE"). ',
      'UKOLIKO DVA TAKMIČARA OSVOJE ISTI BROJ BODOVA (ISTI ZBIR POGOĐENIH KVOTA), PREDNOST U VIŠEM PLASMANU IMA TAKMIČAR KOJI SE RANIJE REGISTROVAO NA BETKOTIP.COM',
      'CIKLUS JEDNOG “TIPSTER GODIŠNJEG TAKMIČENJA” TRAJE OKVIRNO OD 01. DANA U GODINI DO 01. DANA U SLEDEĆOJ GODINI, SA ZAVRŠENIM CIKLUSOM MESEČNOG TAKMIČENJA I POSLEDNJEG RANG TAKMIČENJA U OKVIRU MESEČNOG TAKMIČENJA, NAKON ČEGA SE TABELA ANULIRA, PRI ČEMU SLEDEĆI CIKLUS SVI TAKMIČARI POČINJU NA RAVNOPRAVNIM OSNOVAMA SA ISTIM BROJEM BODOVA, NULA ( 0 )',
      'PRVE TRI POZICIJE NA TABELI “TIPSTER GODIŠNJEG TAKMIČENJA”, OSVAJAJU NAGRADE PRIKAZANE U "FONDU NAGRADA PRE POCETKA TAKMIČENJA".',
      'TABELA IZ PRETHODNOG GODIŠNJEG CIKLUSA OSTAJE U PRIKAZU , KORISNICI SU DUŽNI DA SE JAVE DO ISTEKA ROKA ZA PREUZIMANJE NAGRADA ( KORISNIK JE DUŽAN DA SE JAVI ADMINISTRACIJII SAJTA E-MAILOM NA KOM MU JE JE REGISTROVAN NALOG NA BETKOTIP.COM U ROKU OD NAJVIŠE 25 DANA ZA PREUZIMANJE ILI DOSTAVU NAGRADA )',
    ],
  },
  'tipster-globalno-takmicenje': {
    title: 'TIPSTER GLOBALNO TAKMIČENJE',
    texts: [
      '"TIPSTER GLOBALNO TAKMIČENJE" SE ODVIJA NA GLOBALNOM NIVOU, REVIJALNOG JE KARAKTERA, I U TABELARNOM PRIKAZU BROJI SVE OSVOJENE BODOVE TAKMIČARA NA GLOBALNOM NIVOU, KROZ ISTORIJU CELOKUPNE "MISTER TIPSTER" IGRE.',
    ],
  },
  'periodi-takmicenja-i-takmicarske-nagrade': {
    title: 'USLOVI I PERIODI TAKMIČENJA I TAKMIČARSKE NAGRADE',
    texts: [
      'ZA SVAKO OD TAKMIČENJA ORGANIZATOR TAKMIČARSKE IGRE UNAPRED ODREĐUJE PERIOD TRAJANJA TAKMIČENJA, TJ. CIKLUS OD KADA DO KADA TRAJE TAKMIČENJE.',
      'ZA SVAKO OD TAKMIČENJA ORGANIZATOR TAKMIČARSKE IGRE UNAPRED ODREĐUJE TAKMIČARSKE NAGRADE PO OSVOJENOM PLASMANU. PROMENE NAGRADA NISU MOGUĆE.',
      'SVA TIPSTER TAKMIČENJA NA BETKOTIP.COM PODRAZUMEVAJU DA JE REČ O TAKMIČARSKIM IGRAMA U OBLASTI ZNANJA I VEŠTINE PROGNOZIRANJA ISHODA SPORTSKIH DOGAĐAJA. TAKMIČARI NEMAJU NIKAKVA MATERIJALNA ULAGANJA.',
      'SVAKI REGISTROVANI KORISNIK NA SAJTU BETKOTIP.COM I NA MOBILNOJ APLIKACIJI BETKO AUTOMATSKI POSTAJE I TAKMIČAR U SVIM TAKMIČARSKIM IGRAMA KOJE SE ODVIJAJU NA BETKOTIP.COM SAJTU, UZ OPCIJU SOPSTVENOG IZBORA DA NE MORA DA SE TAKMIČI, ŠTO POSTIŽE JEDNOSTAVNO TAKO ŠTO NEĆE SPROVODITI TAKMIČARSKE AKTIVNOSTI.',
      'UKOLIKO U ORGANIZACIJI SPORTSKOG DOGAĐAJA DOĐE DO PROMENE VREMENA POČETKA ISTOG, ZA TAJ SPORTSKI DOGAĐAJ ĆE SE RAČUNATI KOEFICIJENT 0 (SLOVIMA : 0) U ZBIRU KVOTA, OSIM UKOLIKO BETKOTIP.COM NE ODREDI DRUGAČIJE (MOGUĆ PRIMER: SPORTSKI DOGAĐAJ JE TREBAO DA POČNE U 12:00 ČASOVA, A POČETAK ISTOG JE POMEREN ZA 13:00 ČASOVA).',
      'U SLUČAJU PROMENE MESTA ODIGRAVANJA SPORTSKOG DOGAĐAJA, PRAVILA IGRE NAZNAČUJU DA TO NE PODRAZUMEVA I PROMENE KVOTA, TJ. KOEFICIJENATA ČIJI ZBIR ULAZI U TAKMIČARSKE REZULTATE, SA ČIM SE ŠTITI REGULARNOST TAKMIČENJA, USKLAĐENO SA ČINJENICOM DA SU NEKI OD TAKMIČARA MOGLI ODIGRATI TAJ SPORTSKI DOGAĐAJ U OKVIRU SVOG MISTER TIPSTER MEČA, PRE NEGO ŠTO JE DOŠLO DO PROMENE MESTA ODIGRAVANJA SPORTSKOG DOGAĐAJA.',
      'BET COIN, TOKEN I FREEBET SU OZNAKE ZA FIKTIVNA SREDSTVA ISKAZANE U NEPOSTOJEĆOJ MONETARNOJ VALUTI. KADA SU ONA PRIKAZANA KAO TAKMIČARSKE NAGRADE OD STRANE BILO KOG DODELJIVAČA ISTIH, TO PODRAZUMEVA DA JE TAKMIČAR DUŽAN, UKOLIKO ŽELI DA IH PREUZME OGOVORI NA SVE USLOVE DODELJIVAČA TAKMIČARSKIH NAGRADA, I DA SE TAKMIČAR APSOLUTNO SLAŽE SA TIM. BETKOTIP.COM NE PREUZIMA ODGOVORNOST ZA EVENTUALNO NEDODELJIVANJE TAKMIČARSKIH NAGRADA OD STRANE TREĆE STRANE, TJ. DODELJIVAČA ISTIH, KAO NI ZA MANJKAVOSTI I NEISPUNJAVANJE USLOVA OD STRANE TAKMIČARA KA DODELJIVAČU OSVOJENIH TAKMIČARSKIH NAGRADA.',
      'BET COIN, TOKEN, BONUS I FREEBET SLUŽE ISKLJUČIVO ZA SPROVOĐENJE TAKMIČARSKE IGRE, I NA BETKOTIP.COM IH JE NEMOGUĆE ISPLATITI U BILO KOJOJ MONETARNOJ VALUTI NITI IH ZAMENITI ZA NOVAC. UKOLIKO JE DODELJIVAČ TAKMIČARSKE NAGRADE TREĆA STRANA, SVA PRAVILA PODLEŽU PRAVILIMA ISTE, UTVRĐENO I USKLAĐENO SA PRAVILIMA SAJTA ILI PLATFORME DODELJIVAČA TAKMIČARSKE NAGRADE ILI TAKMIČARSKIH NAGRADA.',
      'BET COIN, TOKEN I FREEBET SU LIČNA STVAR TAKMIČARA, I NE MOGU SE PREBACIVATI NA DRUGOG TAKMIČARA ILI NA TREĆE LICE.',
      'UKOLIKO JE TREĆA STRANA OBELEŽENA KAO DODELJIVAČ OSVOJENE NAGRADE ILI SU VIŠE STRANA OBELEŽENE KAO DODELJIVAČI NAGRADA ORGANIZATORI IGRE, POTREBNO JE DA TAKMIČAR IMA REGISTROVAN NALOG UZ ISPUNJENE USLOVE SVAKE OD STRANA DODELJIVAČA NAGRADA NA PLATFORMAMA ISTIH I DA ISPUNJAVA SVE POSATVLJENE USLOVE PRI REGISTRACIJI I EVENTUALNO VERIFIKACIJI SVOG NALOGA, KAKO BI MOGAO DA POTRAŽUJE I PREUZME OSVOJENU NAGRADU.',
      'TREĆA STRANA KAO DODELJIVAČ TAKMIČARSKE NAGRADE, MOŽE DA OBUSTAVI DODELU ISTE, UKOLIKO TAKMIČAR IZ BILO KOG RAZLOGA NEISPUNJAVA USLOVE PRAVILA ISTE, KOJI SU JASNO NAZNAČENI NA SAJTOVIMA I NA PLATFORMAMA TREĆE STRANE.',
      'UKOLIKO DODELJIVAČI NAGRADA KAO TREĆE STRANE, ZA ISTE POSTAVLJAJU BET COINE, TOKENE, BONUSE ILI FREEBET SA SVOJIH SAJTOVA, U SLUČAJU DA SU TO NA ISTIMA OZNAKE ZA FIKTIVNA SREDSTVA ISKAZANA U NEPOSTOJEĆOJ MONETARNOJ VALUTI, SMATRA SE DA PREUZIMAJU SVOJU ODGOVORNOST ZA POSTAVKU TAKVIH NAGRADA I ZA DODELU ISTIH.',
      'ZBOG SPECIFIČNOSTI TAKMIČARSKIH IGARA I MOGUĆNOSTI UKLJUČENJA U ISTE U SVAKOM TRENUTKU NJIHOVOG ODVIJANJA (TAKMIČAR KOJI SE NAKNADNO UKLJUČUJE U TAKMIČARSKU IGRU NE MOŽE BITI U PREDNOSTI U ODNOSU NA TAKMIČARE KOJI SU SE PRE UKLJUČILI U ISTU, SHODNO TOME DA SE ZBRAJAJU UKUPNI BODOVI POGOĐENIH KVOTA/KOEFICIJENATA, TE NAKNADNA UKLJUČENJA U TAKMIČARSKU IGRU NE REMETE REGULARNOST TAKMIČENJA) TAKMIČAR MOŽE DA REGISTRUJE I VERIFIKUJE I NAKNADNO NALOG UKOLIKO NEMA ISTI, KOD DODELJIVAČA NAGRADA, BEZ OBZIRA DA LI JE TO URADIO PRE POČETKA TAKMIČENJA (UKOLIKO NEMA NALOG) ILI U TOKU ISTOG. ',
      'UKOLIKO SU DODELJIVAČI NAGRADA TREĆE STRANE, TJ. UKOLIKO NAGRADE NE DODELJUJE DIREKTNO, FIZIČKI ILI NA BILO KOJI DRUGI NAČIN BETKOTIP.COM, KOJI JE U OVOM SLUČAJU SAMO POSREDNIK U TAKMIČARSKOJ IGRI ZA KOJU NAGRADE DODELJUJU TREĆE STRANE, SVE EVENTUALNE PORESKE OBAVEZE I TROŠKOVE VEZANO ZA ISTE NA SEBE PREUZIMA DODELJIVAČI NAGRADA.',
      'U SLUČAJU SUMNJI NA PREVARU, ZLOUPOTREBU PRAVILA AKTUELNE TAKMIČARSKE IGRE ILI TEHNIČKIH PROBLEMA, KAO I FAKTORA KOJI SU VAN NADLEŽNOSTI ORGANIZATORA TAKMIČARSKE IGRE, A MOGU UTICATI NA INTEGRITET I PRAVILNO FUNKCIONISANJE TAKMIČARSKE IGRE, BETKOTIP.COM ZADRŽAVA PRAVO NEDODELJIVANJA OSVOJENE NAGRADE TAKMIČARU, KAO I SVE TAKMIČARSKE DOBITKE PROISTEKLE IZ IGRE U KOJOJ SE SUMNJA NA ZLOUPOTREBU PRAVILA TAKMIČARSKE IGRE. ',
      'BETKOTIP.COM KAO ORGANIZATOR TAKMIČARSKE IGRE ZADRŽAVA PRAVO DA U SVAKOM TRENUTKU, BEZ PRETHODNE NAJAVE, PRIVREMENO ILI U POTPUNOSTI PREKINE TAKMIČARSKU IGRU CELINI ILI NA ODREĐENO VREME, ISKLJUČIVO U SLUČAJU UOČAVANJA NEPRAVILNOSTI PRI ISTOJ, ELEMENTARNIH NEPOGODA ILI BILO KAKVIH NEPREDVIĐENIH TEHNIČKIH PROBLEMA ILI SLIČNO, UKOLIKO ISTI ILI BILO ŠTA UGROŽAAJU REGULARNOST BILO KOJE OD TAKMIČARSKIH IGARA.',
      'TAKMIČARI KOJI SU OSVOJILI TAKMIČARSKE NAGRADE, KAKO JE TO NAZNAČENO U PRAVILIMA ZA SVAKU OD TAKMIČARSKIH IGARA POJEDINAČNO, DUŽNI SU DA SE U PRECIZIRANOM ROKU JAVE SA SVOG E-MAILA NA KOJI SU REGISTROVANI NA E-MAIL  BETKO.PODRSKA@GMAIL.COM   RADI PREUZIMANJA SVOJE NAGRADE. OPCIONO IH U CILJU INFORMISANJA O OSVOJENOJ TAKMIČARSKOJ NAGRADI O TOME MOŽE INFORMISATI I BETKOTIP.COM SA E-MAILA  BETKO.PODRSKA@GMAIL.COM , ILI I SAM DODELJIVAČ ILI DODELJIVAČI TAKMIČARSKE NAGRADE ILI TAKMIČARSKIH NAGRADA. PRILIKOM KOMUNIKACIJE PUTEM E-MAILA, TAKMIČAR JE DUŽAN DA DOSTAVI SVE POTREBNE PODATKE KOJI SE OD NJEGA TRAŽE, A KOJI SU USKLAĐENI SA POTREBAMA DODELE OSVOJENIH TAKMIČARSKIH NAGRADA, NAKON ČEGA SE TI PODACI PROSLEĐUJU DODELJIVAČU TAKMIČARSKIH NAGRADA, RADI PRIMO-PREDAJE ISTIH IZMEĐU TAKMIČARA I ISTOG.',
      'UKOLIKO TAKMIČAR NE PREUZME OSVOJENU TAKMIČARSKU NAGRADU U PREDVIĐENOM I U OKVIRU SVAKOG TAKMIČENJA NAZNAČENOM VREMENSKOM ROKU, TAJ TAKMIČAR BESPOVRATNO GUBI PRAVO NA SVOJU OSVOJENU NAGRADU.',
      'U SLUČAJU BILO KAKVIH ŽALBI VEZANIH ZA BILO ŠTA VEZANOG ZA TAKMIČENJA, SVI POSETIOCI, KORISNICI I TAKMIČARI, MOGU DA NAM SE OBRATE DIREKTNO NA CHATU "PITAJ BETKA", KOJI SE NALAZI NA VELIKOM BROJU STRANICA NAŠEG SAJTA I NAŠE MOBILNE APLIKACIJE (NA SVIM MESTIMA GDE KORISNIK VIDI DA PIŠE "PITAJ BETKA", JEDNIM KLIKOM MOŽE DA STUPI U KONVERZACIJU SA NAMA), ILI DOPISOM NA EMAIL ADRESU BETKO.PODRSKA@GMAIL.COM  . ŽALBE MOGU BITI PODNETE NAJKASNIJE 10 DANA PO ISTEKU DOGAĐAJA ILI TAKMIČENJA KOJI SU PREDMET ISTIH. UKOLIKO ŽALBE NE BUDU PODNETE U DEFINISANOM ROKU, TAKVE ŽALBE SE NEĆE RAZMATRATI.',
      'SVAKI DODELJIVAČ NAGRADA PRE USPOSTAVLJANJE SARADNJE SA BETKOTIP.COM IMA OBAVEZU DA POTPIŠE UGOVOR SA BETKOTIP.COM O POSLOVNO-TEHNIČKOJ SARADNJI, PREMA PRAVILIMA UGOVORA KOJA POSTAVLJA ISKLJUČIVO BETKOTIP.COM ',
      `DEFINICIJE POJMOVA U TAKMIČARSKOJ IGRI ${'\n'}   1 - POBEDA DOMAĆE EKIPE ILI TIMA 1 ${'\n'}  X - NEREŠEN REZULTAT / SPORTSKI DOGAĐAJ NEMA POBEDNIKA ${'\n'}  2 - POBEDA GOSTUJUĆE EKIPE ILI TIMA 2 ${'\n'}  UTAKMICA JE POČELA - UKOLIKO JE SPORTSKI DOGAĐAJ POČEO,TAKMIČAR GUBI PRAVO NA ODIGRAVANJE ISTOG, I TAJ SPORTSKI DOGAĐAJ SE SOFTWERSKI BLOKIRA ZA DALJE ODIGRAVANJE`,
    ],
  },
  'tipster-profil': {
    title: 'TIPSTER PROFIL',
    texts: [
      'VERIFIKACIJU E-MAIL OBAVEŠTENJA MOŽETE DA OBELEŽITE ILI DA UKLONITE  JEDINO SA SVOG "TIPSTER MATCH PROFILA". OSTALI PODACI SU TRANSPARENTNI I DOSTUPNI SVIM TAKMIČARIMA.',
      'U OKVIRU MENIJA “TIPSTER PROFIL” MOŽETE DA POGLEDATE SVOJE, ALI I REZULTATE SVIH DRUGIH REGISTROVANIH KORISNIKA, KOJI SA REGISTRACIJOM NA BETKOTIP.COM PO AUTOMATIZMU UČESTVUJU U “MISTER TIPSTER” TAKMIČENJIMA.',
    ],
  },
}

export default PravilaIgreData
