import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "../config/axios";
import { useMutation } from "@tanstack/react-query";
import classes from "./promeniLozinku.module.css";

const PromeniLozinku = () => {
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [isError, setIsError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");

  const { tempPassword } = useParams();

  const confirmChangePasswordMutation = useMutation({
    mutationFn: async (userData) => {
      await axios.post(
        `/forgottenPassword`,
        { tempPassword, newPassword },
        {
          headers: { "Content-Type": "application/json" },
          withCredentials: true,
        }
      );
    },
    onSuccess: () => {
      navigate("/");
    },
    onError: (error) => {
      if (error.response.status === 400) {
        setIsError(true);
        setErrorMsg(error.response.data.msg);
      }
      if (error.response.status === 500) {
        setIsError(true);
        setErrorMsg(error.response.data.msg);
      }
    },
  });

  const navigate = useNavigate();

  const rp =
    /^(?=.*?[A-Z])(?=(.*[a-z]){1,})(?=(.*[\d]){1,})(?=(.*[\W]){1,})(?!.*\s).{8,}$/;

  const validPassword = rp.test(newPassword);

  const handleChangePassword = () => {
    if (validPassword && newPassword === confirmNewPassword) {
      confirmChangePasswordMutation.mutate();
    }
    if (!validPassword) {
      setIsError(true);
      setErrorMsg("Lozinka nije validna.");
    }
    if (newPassword !== confirmNewPassword) {
      setIsError(true);
      setErrorMsg("Lozinke se ne podudaraju.");
    }
  };

  return (
    <div className={classes.container}>
      <h2>Promeni Lozinku</h2>
      {isError && (
        <div className={classes.error}>
          <p>{errorMsg}</p>
        </div>
      )}
      <p>Unesite novu lozinku:</p>
      <input
        onChange={(e) => setNewPassword(e.target.value)}
        className={classes.input}
        type="password"
      />
      <p>Potvrdite novu lozinku:</p>
      <input
        onChange={(e) => setConfirmNewPassword(e.target.value)}
        className={classes.input}
        type="password"
      />
      <p className={classes.red}>
        Napomena: Nova lozinka mora imati minimum 8 karaktera i mora sadržati
        bar jedno veliko slovo, bar jedno malo slovo, bar jedan broj i bar jedan
        specijalan karakter.
      </p>
      <div className={classes.btnContainers}>
        <button className={classes.btn} onClick={() => navigate(-1)}>
          Nazad
        </button>
        <button className={classes.btn} onClick={() => handleChangePassword()}>
          Pošalji
        </button>
      </div>
    </div>
  );
};

export default PromeniLozinku;
