import { useState, useEffect } from "react";
import Grupa1 from "../components/Grupa1";
import Grupa20 from "../components/Grupa20";
import poredjenjeTimovaFudbalDolnjaSlika from "../images/poredjenjeTimovaDoljnjaSlika.png";
import classes from "./poredenjeTimovaFudbal.module.css";

const PoredenjeTimovaFudbal = () => {
  ////total fields/////
  const [brojUtakmicaDomacin, setBrojUtakmicaDomacin] = useState(0);
  const [brojUtakmicaGost, setBrojUtakmicaGost] = useState(0);
  const [data, setData] = useState({
    minutaPoUtakmici: 0,
    timKola: 0,
    golovi: 0,
    ocekivaniGolovi: 0,
    pogodakNaSvakihMin: 0,
    golovaNaUtakmici: 0,
    sutevaNaUtakmici: 0,
    sutevaUOkvirGolaPoUtakmici: 0,
    promaseneVelikeSanse: 0,
    ucinak: 0,
    ucinakIzSlobodnihUdaraca: 0,
    goloviUnutar16: 0,
    goloviIzvan16: 0,
    goloviGlavom: 0,
    goloviLevomNogom: 0,
    goloviDesnomNogom: 0,
    izboreniPenali: 0,
    dodavanje: 0,
    asistenicije: 0,
    dodiri: 0,
    kreiranjeVelikeSanse: 0,
    kljucnihDodavanja: 0,
    preciznaDodavanjaPoUtakmici: 0,
    preciznaDodavanjaNaSvojojPolovini: 0,
    preciznaDodavanjaNaProtivnickojPolovini: 0,
    precizneDugeLopte: 0,
    preciznoPrebacenihPasova: 0,
    precizniCentrasutevi: 0,
    presecenaDodavanjaPoUtakmici: 0,
    oduzeteLoptePoUtakmici: 0,
    posedOsvojenihLopti: 0,
    prediblanPoUtakmici: 0,
    otklonjeneOpasnostiPoUtakmici: 0,
    skrivljeniSledeciUdarci: 0,
    uspesniDriblinzi: 0,
    ukupniOsvnojeniDueli: 0,
    osvojeniDueliNaTlu: 0,
    osvojeniVazdusniDueli: 0,
    IzgubljeniPosediLopte: 0,
    faulovi: 0,
    pretrpljeniFaulovi: 0,
    ofsajdi: 0,
    zutiKartoni: 0,
    drugiZutiKartoni: 0,
    crveniKartoni: 0,
    ///////////////
    minutaPoUtakmici2: 0,
    timKola2: 0,
    golovi2: 0,
    ocekivaniGolovi2: 0,
    pogodakNaSvakihMin2: 0,
    golovaNaUtakmici2: 0,
    sutevaNaUtakmici2: 0,
    sutevaUOkvirGolaPoUtakmici2: 0,
    promaseneVelikeSanse2: 0,
    ucinak2: 0,
    ucinakIzSlobodnihUdaraca2: 0,
    goloviUnutar162: 0,
    goloviIzvan162: 0,
    goloviGlavom2: 0,
    goloviLevomNogom2: 0,
    goloviDesnomNogom2: 0,
    izboreniPenali2: 0,
    dodavanje2: 0,
    asistenicije2: 0,
    dodiri2: 0,
    kreiranjeVelikeSanse2: 0,
    kljucnihDodavanja2: 0,
    preciznaDodavanjaPoUtakmici2: 0,
    preciznaDodavanjaNaSvojojPolovini2: 0,
    preciznaDodavanjaNaProtivnickojPolovini2: 0,
    precizneDugeLopte2: 0,
    preciznoPrebacenihPasova2: 0,
    precizniCentrasutevi2: 0,
    presecenaDodavanjaPoUtakmici2: 0,
    oduzeteLoptePoUtakmici2: 0,
    posedOsvojenihLopti2: 0,
    prediblanPoUtakmici2: 0,
    otklonjeneOpasnostiPoUtakmici2: 0,
    skrivljeniSledeciUdarci2: 0,
    uspesniDriblinzi2: 0,
    ukupniOsvnojeniDueli2: 0,
    osvojeniDueliNaTlu2: 0,
    osvojeniVazdusniDueli2: 0,
    IzgubljeniPosediLopte2: 0,
    faulovi2: 0,
    pretrpljeniFaulovi2: 0,
    ofsajdi2: 0,
    zutiKartoni2: 0,
    drugiZutiKartoni2: 0,
    crveniKartoni2: 0,
  });

  const [calculatedData, setCalculatedData] = useState({
    minutaPoUtakmici: 0,
    timKola: 0,
    golovi: 0,
    ocekivaniGolovi: 0,
    pogodakNaSvakihMin: 0,
    golovaNaUtakmici: 0,
    sutevaNaUtakmici: 0,
    sutevaUOkvirGolaPoUtakmici: 0,
    promaseneVelikeSanse: 0,
    ucinak: 0,
    ucinakIzSlobodnihUdaraca: 0,
    goloviUnutar16: 0,
    goloviIzvan16: 0,
    goloviGlavom: 0,
    goloviLevomNogom: 0,
    goloviDesnomNogom: 0,
    izboreniPenali: 0,
    dodavanje: 0,
    asistenicije: 0,
    dodiri: 0,
    kreiranjeVelikeSanse: 0,
    kljucnihDodavanja: 0,
    preciznaDodavanjaPoUtakmici: 0,
    preciznaDodavanjaNaSvojojPolovini: 0,
    preciznaDodavanjaNaProtivnickojPolovini: 0,
    precizneDugeLopte: 0,
    preciznoPrebacenihPasova: 0,
    precizniCentrasutevi: 0,
    presecenaDodavanjaPoUtakmici: 0,
    oduzeteLoptePoUtakmici: 0,
    posedOsvojenihLopti: 0,
    prediblanPoUtakmici: 0,
    otklonjeneOpasnostiPoUtakmici: 0,
    skrivljeniSledeciUdarci: 0,
    uspesniDriblinzi: 0,
    ukupniOsvnojeniDueli: 0,
    osvojeniDueliNaTlu: 0,
    osvojeniVazdusniDueli: 0,
    IzgubljeniPosediLopte: 0,
    faulovi: 0,
    pretrpljeniFaulovi: 0,
    ofsajdi: 0,
    zutiKartoni: 0,
    drugiZutiKartoni: 0,
    crveniKartoni: 0,
    ///////////////
    minutaPoUtakmici2: 0,
    timKola2: 0,
    golovi2: 0,
    ocekivaniGolovi2: 0,
    pogodakNaSvakihMin2: 0,
    golovaNaUtakmici2: 0,
    sutevaNaUtakmici2: 0,
    sutevaUOkvirGolaPoUtakmici2: 0,
    promaseneVelikeSanse2: 0,
    ucinak2: 0,
    ucinakIzSlobodnihUdaraca2: 0,
    goloviUnutar162: 0,
    goloviIzvan162: 0,
    goloviGlavom2: 0,
    goloviLevomNogom2: 0,
    goloviDesnomNogom2: 0,
    izboreniPenali2: 0,
    dodavanje2: 0,
    asistenicije2: 0,
    dodiri2: 0,
    kreiranjeVelikeSanse2: 0,
    kljucnihDodavanja2: 0,
    preciznaDodavanjaPoUtakmici2: 0,
    preciznaDodavanjaNaSvojojPolovini2: 0,
    preciznaDodavanjaNaProtivnickojPolovini2: 0,
    precizneDugeLopte2: 0,
    preciznoPrebacenihPasova2: 0,
    precizniCentrasutevi2: 0,
    presecenaDodavanjaPoUtakmici2: 0,
    oduzeteLoptePoUtakmici2: 0,
    posedOsvojenihLopti2: 0,
    prediblanPoUtakmici2: 0,
    otklonjeneOpasnostiPoUtakmici2: 0,
    skrivljeniSledeciUdarci2: 0,
    uspesniDriblinzi2: 0,
    ukupniOsvnojeniDueli2: 0,
    osvojeniDueliNaTlu2: 0,
    osvojeniVazdusniDueli2: 0,
    IzgubljeniPosediLopte2: 0,
    faulovi2: 0,
    pretrpljeniFaulovi2: 0,
    ofsajdi2: 0,
    zutiKartoni2: 0,
    drugiZutiKartoni2: 0,
    crveniKartoni2: 0,
  });

  useEffect(() => {
    handleCalculate();
  }, [data, brojUtakmicaDomacin, brojUtakmicaGost]);

  const handleCalculate = () => {
    let obj = {
      minutaPoUtakmici: 0,
      timKola: 0,
      golovi: 0,
      ocekivaniGolovi: 0,
      pogodakNaSvakihMin: 0,
      golovaNaUtakmici: 0,
      sutevaNaUtakmici: 0,
      sutevaUOkvirGolaPoUtakmici: 0,
      promaseneVelikeSanse: 0,
      ucinak: 0,
      ucinakIzSlobodnihUdaraca: 0,
      goloviUnutar16: 0,
      goloviIzvan16: 0,
      goloviGlavom: 0,
      goloviLevomNogom: 0,
      goloviDesnomNogom: 0,
      izboreniPenali: 0,
      dodavanje: 0,
      asistenicije: 0,
      dodiri: 0,
      kreiranjeVelikeSanse: 0,
      kljucnihDodavanja: 0,
      preciznaDodavanjaPoUtakmici: 0,
      preciznaDodavanjaNaSvojojPolovini: 0,
      preciznaDodavanjaNaProtivnickojPolovini: 0,
      precizneDugeLopte: 0,
      preciznoPrebacenihPasova: 0,
      precizniCentrasutevi: 0,
      presecenaDodavanjaPoUtakmici: 0,
      oduzeteLoptePoUtakmici: 0,
      posedOsvojenihLopti: 0,
      prediblanPoUtakmici: 0,
      otklonjeneOpasnostiPoUtakmici: 0,
      skrivljeniSledeciUdarci: 0,
      uspesniDriblinzi: 0,
      ukupniOsvnojeniDueli: 0,
      osvojeniDueliNaTlu: 0,
      osvojeniVazdusniDueli: 0,
      IzgubljeniPosediLopte: 0,
      faulovi: 0,
      pretrpljeniFaulovi: 0,
      ofsajdi: 0,
      zutiKartoni: 0,
      drugiZutiKartoni: 0,
      crveniKartoni: 0,
      ///////////////
      minutaPoUtakmici2: 0,
      timKola2: 0,
      golovi2: 0,
      ocekivaniGolovi2: 0,
      pogodakNaSvakihMin2: 0,
      golovaNaUtakmici2: 0,
      sutevaNaUtakmici2: 0,
      sutevaUOkvirGolaPoUtakmici2: 0,
      promaseneVelikeSanse2: 0,
      ucinak2: 0,
      ucinakIzSlobodnihUdaraca2: 0,
      goloviUnutar162: 0,
      goloviIzvan162: 0,
      goloviGlavom2: 0,
      goloviLevomNogom2: 0,
      goloviDesnomNogom2: 0,
      izboreniPenali2: 0,
      dodavanje2: 0,
      asistenicije2: 0,
      dodiri2: 0,
      kreiranjeVelikeSanse2: 0,
      kljucnihDodavanja2: 0,
      preciznaDodavanjaPoUtakmici2: 0,
      preciznaDodavanjaNaSvojojPolovini2: 0,
      preciznaDodavanjaNaProtivnickojPolovini2: 0,
      precizneDugeLopte2: 0,
      preciznoPrebacenihPasova2: 0,
      precizniCentrasutevi2: 0,
      presecenaDodavanjaPoUtakmici2: 0,
      oduzeteLoptePoUtakmici2: 0,
      posedOsvojenihLopti2: 0,
      prediblanPoUtakmici2: 0,
      otklonjeneOpasnostiPoUtakmici2: 0,
      skrivljeniSledeciUdarci2: 0,
      uspesniDriblinzi2: 0,
      ukupniOsvnojeniDueli2: 0,
      osvojeniDueliNaTlu2: 0,
      osvojeniVazdusniDueli2: 0,
      IzgubljeniPosediLopte2: 0,
      faulovi2: 0,
      pretrpljeniFaulovi2: 0,
      ofsajdi2: 0,
      zutiKartoni2: 0,
      drugiZutiKartoni2: 0,
      crveniKartoni2: 0,
    };
    if (brojUtakmicaDomacin) {
      const keys = Object.keys(data);

      for (let key of keys) {
        if (data && data[key] && !key.includes(2)) {
          if (key === "pogodakNaSvakihMin") {
            obj = {
              ...obj,
              [key]: parseFloat(
                (data[key] / data.minutaPoUtakmici) * 100
              ).toFixed(2),
            };
          } else if (key === "minutaPoUtakmici") {
            obj = {
              ...obj,
              [key]: parseFloat(data[key] / 90).toFixed(2),
            };
          } else {
            obj = {
              ...obj,
              [key]: parseFloat(
                (data[key] / brojUtakmicaDomacin) * 100
              ).toFixed(2),
            };
          }
        }
      }

      setCalculatedData(obj);
    }

    if (brojUtakmicaGost) {
      const keys = Object.keys(data);

      for (let key of keys) {
        if (data && data[key] && key.includes(2)) {
          if (key === "pogodakNaSvakihMin2") {
            obj = {
              ...obj,
              [key]: parseFloat(
                (data[key] / data.minutaPoUtakmici2) * 100
              ).toFixed(2),
            };
          } else if (key === "minutaPoUtakmici2") {
            obj = {
              ...obj,
              [key]: parseFloat(data[key] / 90).toFixed(2),
            };
          } else {
            obj = {
              ...obj,
              [key]: parseFloat((data[key] / brojUtakmicaGost) * 100).toFixed(
                2
              ),
            };
          }
        }
      }
      setCalculatedData(obj);
    }
  };

  return (
    <div className={classes.container}>
      <Grupa1
        imgText="POREĐENJE TIMOVA - FUDBAL"
        imgBackgroundColorDark="#15BF28"
        imgBackgroundColorLight="#18D514"
        imgFontColor="#FFFFFF"
        label1="VREME POČETKA"
        label2="ZEMLJA"
        label3="DOMAĆIN"
        label4="TAKMIČENJE - LIGA"
        label5="GOST"
      />
      <Grupa20
        imgBackgroundColorDark="#15BF28"
        imgBackgroundColorLight="#18D514"
        imgFontColor="#FFFFFF"
        calculatorType={`Poredjenje timova - fudbal`}
      />
      <img src={poredjenjeTimovaFudbalDolnjaSlika} />
      <div className={classes.calculator}>
        <div className={classes.row}>
          <div className={classes.col1}></div>
          <div className={classes.col2}>
            <input
              style={{
                border: "none",
                width: "60px",
                fontWeight: "bold",
                fontSize: "20px",
                marginTop: "2px",
                fontWeight: "bold",
                fontSize: "20px",
                textAlign: "center",
              }}
              type="number"
              min="0"
              onChange={(e) => {
                setBrojUtakmicaDomacin(e.target.value);
              }}
            />
          </div>
          <div className={classes.col3}>UTAKMICE</div>
          <div className={classes.col4}>
            <input
              style={{
                border: "none",
                width: "60px",
                fontWeight: "bold",
                fontSize: "20px",
                marginTop: "2px",
                fontWeight: "bold",
                fontSize: "20px",
                textAlign: "center",
              }}
              onChange={(e) => {
                setBrojUtakmicaGost(e.target.value);
              }}
              type="number"
              min="0"
            />
          </div>
          <div className={classes.col5}></div>
        </div>
        <div className={classes.row}>
          <div className={classes.col1}>
            {parseFloat(calculatedData.minutaPoUtakmici).toFixed(2)} %
          </div>
          <div className={classes.col2}>
            <input
              style={{
                border: "none",
                width: "60px",
                fontWeight: "bold",
                fontSize: "20px",
                marginTop: "2px",
                textAlign: "center",
              }}
              onChange={(e) => {
                setData({ ...data, minutaPoUtakmici: e.target.value });
              }}
              type="number"
              min="0"
            />
          </div>
          <div className={classes.col3}>Minuta po utakmici</div>
          <div className={classes.col4}>
            <input
              style={{
                border: "none",
                width: "60px",
                fontWeight: "bold",
                fontSize: "20px",
                marginTop: "2px",
                textAlign: "center",
              }}
              onChange={(e) => {
                setData({ ...data, minutaPoUtakmici2: e.target.value });
              }}
              type="number"
              min="0"
            />
          </div>
          <div className={classes.col5}>
            {calculatedData.minutaPoUtakmici2.toFixed(2)} %
          </div>
        </div>
        <div className={classes.row}>
          <div className={classes.col1}>{calculatedData.timKola} %</div>
          <div className={classes.col2}>
            <input
              style={{
                border: "none",
                width: "60px",
                fontWeight: "bold",
                fontSize: "20px",
                marginTop: "2px",
                textAlign: "center",
              }}
              onChange={(e) => {
                setData({ ...data, timKola: e.target.value });
              }}
              type="number"
              min="0"
            />
          </div>
          <div className={classes.col3}>Tim kola</div>
          <div className={classes.col4}>
            <input
              style={{
                border: "none",
                width: "60px",
                fontWeight: "bold",
                fontSize: "20px",
                marginTop: "2px",
                textAlign: "center",
              }}
              onChange={(e) => {
                setData({ ...data, timKola2: e.target.value });
              }}
              type="number"
              min="0"
            />
          </div>
          <div className={classes.col5}>{calculatedData.timKola2} %</div>
        </div>
        <div className={classes.row}>
          <div className={classes.col1}>{calculatedData.golovi} %</div>
          <div className={classes.col2}>
            <input
              style={{
                border: "none",
                width: "60px",
                fontWeight: "bold",
                fontSize: "20px",
                marginTop: "2px",
                textAlign: "center",
              }}
              onChange={(e) => {
                setData({ ...data, golovi: e.target.value });
              }}
              type="number"
              min="0"
            />
          </div>
          <div className={classes.col3}>Golovi</div>
          <div className={classes.col4}>
            <input
              style={{
                border: "none",
                width: "60px",
                fontWeight: "bold",
                fontSize: "20px",
                marginTop: "2px",
                textAlign: "center",
              }}
              onChange={(e) => {
                setData({ ...data, golovi2: e.target.value });
              }}
              type="number"
              min="0"
            />
          </div>
          <div className={classes.col5}>{calculatedData.golovi2} %</div>
        </div>
        <div className={classes.row}>
          <div className={classes.col1}>
            {" "}
            {calculatedData.ocekivaniGolovi} %
          </div>
          <div className={classes.col2}>
            <input
              style={{
                border: "none",
                width: "60px",
                fontWeight: "bold",
                fontSize: "20px",
                marginTop: "2px",
                textAlign: "center",
              }}
              onChange={(e) => {
                setData({ ...data, ocekivaniGolovi: e.target.value });
              }}
              type="number"
              min="0"
            />
          </div>
          <div className={classes.col3}>Očekivani golovi</div>
          <div className={classes.col4}>
            <input
              style={{
                border: "none",
                width: "60px",
                fontWeight: "bold",
                fontSize: "20px",
                marginTop: "2px",
                textAlign: "center",
              }}
              onChange={(e) => {
                setData({ ...data, ocekivaniGolovi2: e.target.value });
              }}
              type="number"
              min="0"
            />
          </div>
          <div className={classes.col5}>
            {calculatedData.ocekivaniGolovi2} %
          </div>
        </div>
        <div className={classes.row}>
          <div className={classes.col1}>
            {calculatedData.pogodakNaSvakihMin} %
          </div>
          <div className={classes.col2}>
            <input
              style={{
                border: "none",
                width: "60px",
                fontWeight: "bold",
                fontSize: "20px",
                marginTop: "2px",
                textAlign: "center",
              }}
              onChange={(e) => {
                setData({ ...data, pogodakNaSvakihMin: e.target.value });
              }}
              type="number"
              min="0"
            />
          </div>
          <div className={classes.col3}>Pogodak na svakih (minuta)</div>
          <div className={classes.col4}>
            <input
              style={{
                border: "none",
                width: "60px",
                fontWeight: "bold",
                fontSize: "20px",
                marginTop: "2px",
                textAlign: "center",
              }}
              onChange={(e) => {
                setData({ ...data, pogodakNaSvakihMin2: e.target.value });
              }}
              type="number"
              min="0"
            />
          </div>
          <div className={classes.col5}>
            {calculatedData.pogodakNaSvakihMin2} %
          </div>
        </div>
        <div className={classes.row}>
          <div className={classes.col1}>
            {calculatedData.golovaNaUtakmici} %
          </div>
          <div className={classes.col2}>
            <input
              style={{
                border: "none",
                width: "60px",
                fontWeight: "bold",
                fontSize: "20px",
                marginTop: "2px",
                textAlign: "center",
              }}
              onChange={(e) => {
                setData({ ...data, golovaNaUtakmici: e.target.value });
              }}
              type="number"
              min="0"
            />
          </div>
          <div className={classes.col3}>Golova po utakmici</div>
          <div className={classes.col4}>
            <input
              style={{
                border: "none",
                width: "60px",
                fontWeight: "bold",
                fontSize: "20px",
                marginTop: "2px",
                textAlign: "center",
              }}
              onChange={(e) => {
                setData({ ...data, golovaNaUtakmici2: e.target.value });
              }}
              type="number"
              min="0"
            />
          </div>
          <div className={classes.col5}>
            {calculatedData.golovaNaUtakmici2} %
          </div>
        </div>
        <div className={classes.row}>
          <div className={classes.col1}>
            {" "}
            {calculatedData.sutevaNaUtakmici} %
          </div>
          <div className={classes.col2}>
            <input
              style={{
                border: "none",
                width: "60px",
                fontWeight: "bold",
                fontSize: "20px",
                marginTop: "2px",
                textAlign: "center",
              }}
              onChange={(e) => {
                setData({ ...data, sutevaNaUtakmici: e.target.value });
              }}
              type="number"
              min="0"
            />
          </div>
          <div className={classes.col3}>Šuteva po utakmici</div>
          <div className={classes.col4}>
            <input
              style={{
                border: "none",
                width: "60px",
                fontWeight: "bold",
                fontSize: "20px",
                marginTop: "2px",
                textAlign: "center",
              }}
              onChange={(e) => {
                setData({ ...data, sutevaNaUtakmici2: e.target.value });
              }}
              type="number"
              min="0"
            />
          </div>
          <div className={classes.col5}>
            {calculatedData.sutevaNaUtakmici2} %
          </div>
        </div>
        <div className={classes.row}>
          <div className={classes.col1}>
            {calculatedData.sutevaUOkvirGolaPoUtakmici} %
          </div>
          <div className={classes.col2}>
            <input
              style={{
                border: "none",
                width: "60px",
                fontWeight: "bold",
                fontSize: "20px",
                marginTop: "2px",
                textAlign: "center",
              }}
              onChange={(e) => {
                setData({
                  ...data,
                  sutevaUOkvirGolaPoUtakmici: e.target.value,
                });
              }}
              type="number"
              min="0"
            />
          </div>
          <div className={classes.col3}>Šuteva u okvir gola po utakmici</div>
          <div className={classes.col4}>
            <input
              style={{
                border: "none",
                width: "60px",
                fontWeight: "bold",
                fontSize: "20px",
                marginTop: "2px",
                textAlign: "center",
              }}
              onChange={(e) => {
                setData({
                  ...data,
                  sutevaUOkvirGolaPoUtakmici2: e.target.value,
                });
              }}
              type="number"
              min="0"
            />
          </div>
          <div className={classes.col5}>
            {calculatedData.sutevaUOkvirGolaPoUtakmici2} %
          </div>
        </div>
        <div className={classes.row}>
          <div className={classes.col1}>
            {calculatedData.promaseneVelikeSanse} %
          </div>
          <div className={classes.col2}>
            <input
              style={{
                border: "none",
                width: "60px",
                fontWeight: "bold",
                fontSize: "20px",
                marginTop: "2px",
                textAlign: "center",
              }}
              onChange={(e) => {
                setData({ ...data, promaseneVelikeSanse: e.target.value });
              }}
              type="number"
              min="0"
            />
          </div>
          <div className={classes.col3}>Promašene velike šanse</div>
          <div className={classes.col4}>
            <input
              style={{
                border: "none",
                width: "60px",
                fontWeight: "bold",
                fontSize: "20px",
                marginTop: "2px",
                textAlign: "center",
              }}
              onChange={(e) => {
                setData({ ...data, promaseneVelikeSanse2: e.target.value });
              }}
              type="number"
              min="0"
            />
          </div>
          <div className={classes.col5}>
            {calculatedData.promaseneVelikeSanse2} %
          </div>
        </div>
        <div className={classes.row}>
          <div className={classes.col1}>{calculatedData.ucinak} %</div>
          <div className={classes.col2}>
            <input
              style={{
                border: "none",
                width: "60px",
                fontWeight: "bold",
                fontSize: "20px",
                marginTop: "2px",
                textAlign: "center",
              }}
              onChange={(e) => {
                setData({ ...data, ucinak: e.target.value });
              }}
              type="number"
              min="0"
            />
          </div>
          <div className={classes.col3}>Učinak</div>
          <div className={classes.col4}>
            <input
              style={{
                border: "none",
                width: "60px",
                fontWeight: "bold",
                fontSize: "20px",
                marginTop: "2px",
                textAlign: "center",
              }}
              onChange={(e) => {
                setData({ ...data, ucinak2: e.target.value });
              }}
              type="number"
              min="0"
            />
          </div>
          <div className={classes.col5}>{calculatedData.ucinak2} %</div>
        </div>
        <div className={classes.row}>
          <div className={classes.col1}>
            {calculatedData.ucinakIzSlobodnihUdaraca} %
          </div>
          <div className={classes.col2}>
            <input
              style={{
                border: "none",
                width: "60px",
                fontWeight: "bold",
                fontSize: "20px",
                marginTop: "2px",
                textAlign: "center",
              }}
              onChange={(e) => {
                setData({ ...data, ucinakIzSlobodnihUdaraca: e.target.value });
              }}
              type="number"
              min="0"
            />
          </div>
          <div className={classes.col3}>Učinak iz slobodnih udaraca</div>
          <div className={classes.col4}>
            <input
              style={{
                border: "none",
                width: "60px",
                fontWeight: "bold",
                fontSize: "20px",
                marginTop: "2px",
                textAlign: "center",
              }}
              onChange={(e) => {
                setData({ ...data, ucinakIzSlobodnihUdaraca2: e.target.value });
              }}
              type="number"
              min="0"
            />
          </div>
          <div className={classes.col5}>
            {calculatedData.ucinakIzSlobodnihUdaraca2} %
          </div>
        </div>
        <div className={classes.row}>
          <div className={classes.col1}>{calculatedData.goloviUnutar16} %</div>
          <div className={classes.col2}>
            <input
              style={{
                border: "none",
                width: "60px",
                fontWeight: "bold",
                fontSize: "20px",
                marginTop: "2px",
                textAlign: "center",
              }}
              onChange={(e) => {
                setData({ ...data, goloviUnutar16: e.target.value });
              }}
              type="number"
              min="0"
            />
          </div>
          <div className={classes.col3}>Golovi unutar 16m</div>
          <div className={classes.col4}>
            <input
              style={{
                border: "none",
                width: "60px",
                fontWeight: "bold",
                fontSize: "20px",
                marginTop: "2px",
                textAlign: "center",
              }}
              onChange={(e) => {
                setData({ ...data, goloviUnutar162: e.target.value });
              }}
              type="number"
              min="0"
            />
          </div>
          <div className={classes.col5}>{calculatedData.goloviUnutar162} %</div>
        </div>
        <div className={classes.row}>
          <div className={classes.col1}>{calculatedData.goloviIzvan16} %</div>
          <div className={classes.col2}>
            <input
              style={{
                border: "none",
                width: "60px",
                fontWeight: "bold",
                fontSize: "20px",
                marginTop: "2px",
                textAlign: "center",
              }}
              onChange={(e) => {
                setData({ ...data, goloviIzvan16: e.target.value });
              }}
              type="number"
              min="0"
            />
          </div>
          <div className={classes.col3}>Golovi izvan 16m</div>
          <div className={classes.col4}>
            <input
              style={{
                border: "none",
                width: "60px",
                fontWeight: "bold",
                fontSize: "20px",
                marginTop: "2px",
                textAlign: "center",
              }}
              onChange={(e) => {
                setData({ ...data, goloviIzvan162: e.target.value });
              }}
              type="number"
              min="0"
            />
          </div>
          <div className={classes.col5}>{calculatedData.goloviIzvan162} %</div>
        </div>
        <div className={classes.row}>
          <div className={classes.col1}>{calculatedData.goloviGlavom} %</div>
          <div className={classes.col2}>
            <input
              style={{
                border: "none",
                width: "60px",
                fontWeight: "bold",
                fontSize: "20px",
                marginTop: "2px",
                textAlign: "center",
              }}
              onChange={(e) => {
                setData({ ...data, goloviGlavom: e.target.value });
              }}
              type="number"
              min="0"
            />
          </div>
          <div className={classes.col3}>Golovi glavom</div>
          <div className={classes.col4}>
            <input
              style={{
                border: "none",
                width: "60px",
                fontWeight: "bold",
                fontSize: "20px",
                marginTop: "2px",
                textAlign: "center",
              }}
              onChange={(e) => {
                setData({ ...data, goloviGlavom2: e.target.value });
              }}
              type="number"
              min="0"
            />
          </div>
          <div className={classes.col5}>{calculatedData.goloviGlavom2} %</div>
        </div>
        <div className={classes.row}>
          <div className={classes.col1}>
            {calculatedData.goloviLevomNogom} %
          </div>
          <div className={classes.col2}>
            <input
              style={{
                border: "none",
                width: "60px",
                fontWeight: "bold",
                fontSize: "20px",
                marginTop: "2px",
                textAlign: "center",
              }}
              onChange={(e) => {
                setData({ ...data, goloviLevomNogom: e.target.value });
              }}
              type="number"
              min="0"
            />
          </div>
          <div className={classes.col3}>Golovi levom nogom</div>
          <div className={classes.col4}>
            <input
              style={{
                border: "none",
                width: "60px",
                fontWeight: "bold",
                fontSize: "20px",
                marginTop: "2px",
                textAlign: "center",
              }}
              onChange={(e) => {
                setData({ ...data, goloviLevomNogom2: e.target.value });
              }}
              type="number"
              min="0"
            />
          </div>
          <div className={classes.col5}>
            {calculatedData.goloviLevomNogom2} %
          </div>
        </div>
        <div className={classes.row}>
          <div className={classes.col1}>
            {calculatedData.goloviDesnomNogom} %
          </div>
          <div className={classes.col2}>
            <input
              style={{
                border: "none",
                width: "60px",
                fontWeight: "bold",
                fontSize: "20px",
                marginTop: "2px",
                textAlign: "center",
              }}
              onChange={(e) => {
                setData({ ...data, goloviDesnomNogom: e.target.value });
              }}
              type="number"
              min="0"
            />
          </div>
          <div className={classes.col3}>Golovi desnom nogom</div>
          <div className={classes.col4}>
            <input
              style={{
                border: "none",
                width: "60px",
                fontWeight: "bold",
                fontSize: "20px",
                marginTop: "2px",
                textAlign: "center",
              }}
              onChange={(e) => {
                setData({ ...data, goloviDesnomNogom2: e.target.value });
              }}
              type="number"
              min="0"
            />
          </div>
          <div className={classes.col5}>
            {calculatedData.goloviDesnomNogom2} %
          </div>
        </div>
        <div className={classes.row}>
          <div className={classes.col1}>{calculatedData.izboreniPenali} %</div>
          <div className={classes.col2}>
            <input
              style={{
                border: "none",
                width: "60px",
                fontWeight: "bold",
                fontSize: "20px",
                marginTop: "2px",
                textAlign: "center",
              }}
              onChange={(e) => {
                setData({ ...data, izboreniPenali: e.target.value });
              }}
              type="number"
              min="0"
            />
          </div>
          <div className={classes.col3}>Izboreni penali</div>
          <div className={classes.col4}>
            <input
              style={{
                border: "none",
                width: "60px",
                fontWeight: "bold",
                fontSize: "20px",
                marginTop: "2px",
                textAlign: "center",
              }}
              onChange={(e) => {
                setData({ ...data, izboreniPenali2: e.target.value });
              }}
              type="number"
              min="0"
            />
          </div>
          <div className={classes.col5}>{calculatedData.izboreniPenali2} %</div>
        </div>
        <div className={classes.row}>
          <div className={classes.col1}>{calculatedData.dodavanje} %</div>
          <div className={classes.col2}>
            <input
              style={{
                border: "none",
                width: "60px",
                fontWeight: "bold",
                fontSize: "20px",
                marginTop: "2px",
                textAlign: "center",
              }}
              onChange={(e) => {
                setData({ ...data, dodavanje: e.target.value });
              }}
              type="number"
              min="0"
            />
          </div>
          <div className={classes.col3}>Dodavanje</div>
          <div className={classes.col4}>
            <input
              style={{
                border: "none",
                width: "60px",
                fontWeight: "bold",
                fontSize: "20px",
                marginTop: "2px",
                textAlign: "center",
              }}
              onChange={(e) => {
                setData({ ...data, dodavanje2: e.target.value });
              }}
              type="number"
              min="0"
            />
          </div>
          <div className={classes.col5}>{calculatedData.dodavanje2} %</div>
        </div>
        <div className={classes.row}>
          <div className={classes.col1}>{calculatedData.asistenicije} %</div>
          <div className={classes.col2}>
            <input
              style={{
                border: "none",
                width: "60px",
                fontWeight: "bold",
                fontSize: "20px",
                marginTop: "2px",
                textAlign: "center",
              }}
              onChange={(e) => {
                setData({ ...data, asistenicije: e.target.value });
              }}
              type="number"
              min="0"
            />
          </div>
          <div className={classes.col3}>Asistencije</div>
          <div className={classes.col4}>
            <input
              style={{
                border: "none",
                width: "60px",
                fontWeight: "bold",
                fontSize: "20px",
                marginTop: "2px",
                textAlign: "center",
              }}
              onChange={(e) => {
                setData({ ...data, asistenicije2: e.target.value });
              }}
              type="number"
              min="0"
            />
          </div>
          <div className={classes.col5}>{calculatedData.asistenicije2} %</div>
        </div>
        <div className={classes.row}>
          <div className={classes.col1}>{calculatedData.dodiri} %</div>
          <div className={classes.col2}>
            <input
              style={{
                border: "none",
                width: "60px",
                fontWeight: "bold",
                fontSize: "20px",
                marginTop: "2px",
                textAlign: "center",
              }}
              onChange={(e) => {
                setData({ ...data, dodiri: e.target.value });
              }}
              type="number"
              min="0"
            />
          </div>
          <div className={classes.col3}>Dodiri</div>
          <div className={classes.col4}>
            <input
              style={{
                border: "none",
                width: "60px",
                fontWeight: "bold",
                fontSize: "20px",
                marginTop: "2px",
                textAlign: "center",
              }}
              onChange={(e) => {
                setData({ ...data, dodiri2: e.target.value });
              }}
              type="number"
              min="0"
            />
          </div>
          <div className={classes.col5}>{calculatedData.dodiri2} %</div>
        </div>

        <div className={classes.row}>
          <div className={classes.col1}>
            {calculatedData.kreiranjeVelikeSanse} %
          </div>
          <div className={classes.col2}>
            <input
              style={{
                border: "none",
                width: "60px",
                fontWeight: "bold",
                fontSize: "20px",
                marginTop: "2px",
                textAlign: "center",
              }}
              onChange={(e) => {
                setData({ ...data, kreiranjeVelikeSanse: e.target.value });
              }}
              type="number"
              min="0"
            />
          </div>
          <div className={classes.col3}>Kreiranje velike šanse</div>
          <div className={classes.col4}>
            <input
              style={{
                border: "none",
                width: "60px",
                fontWeight: "bold",
                fontSize: "20px",
                marginTop: "2px",
                textAlign: "center",
              }}
              onChange={(e) => {
                setData({ ...data, kreiranjeVelikeSanse2: e.target.value });
              }}
              type="number"
              min="0"
            />
          </div>
          <div className={classes.col5}>
            {calculatedData.kreiranjeVelikeSanse2} %
          </div>
        </div>

        <div className={classes.row}>
          <div className={classes.col1}>
            {calculatedData.kljucnihDodavanja} %
          </div>
          <div className={classes.col2}>
            <input
              style={{
                border: "none",
                width: "60px",
                fontWeight: "bold",
                fontSize: "20px",
                marginTop: "2px",
                textAlign: "center",
              }}
              onChange={(e) => {
                setData({ ...data, kljucnihDodavanja: e.target.value });
              }}
              type="number"
              min="0"
            />
          </div>
          <div className={classes.col3}>Ključnih dodavanja</div>
          <div className={classes.col4}>
            <input
              style={{
                border: "none",
                width: "60px",
                fontWeight: "bold",
                fontSize: "20px",
                marginTop: "2px",
                textAlign: "center",
              }}
              onChange={(e) => {
                setData({ ...data, kljucnihDodavanja2: e.target.value });
              }}
              type="number"
              min="0"
            />
          </div>
          <div className={classes.col5}>
            {calculatedData.kljucnihDodavanja2} %
          </div>
        </div>

        <div className={classes.row}>
          <div className={classes.col1}>
            {calculatedData.preciznaDodavanjaPoUtakmici} %
          </div>
          <div className={classes.col2}>
            <input
              style={{
                border: "none",
                width: "60px",
                fontWeight: "bold",
                fontSize: "20px",
                marginTop: "2px",
                textAlign: "center",
              }}
              onChange={(e) => {
                setData({
                  ...data,
                  preciznaDodavanjaPoUtakmici: e.target.value,
                });
              }}
              type="number"
              min="0"
            />
          </div>
          <div className={classes.col3}>Precizna dodavanja po utakmici</div>
          <div className={classes.col4}>
            <input
              style={{
                border: "none",
                width: "60px",
                fontWeight: "bold",
                fontSize: "20px",
                marginTop: "2px",
                textAlign: "center",
              }}
              onChange={(e) => {
                setData({
                  ...data,
                  preciznaDodavanjaPoUtakmici2: e.target.value,
                });
              }}
              type="number"
              min="0"
            />
          </div>
          <div className={classes.col5}>
            {calculatedData.preciznaDodavanjaPoUtakmici2} %
          </div>
        </div>

        <div className={classes.row}>
          <div className={classes.col1}>
            {calculatedData.preciznaDodavanjaNaSvojojPolovini} %
          </div>
          <div className={classes.col2}>
            <input
              style={{
                border: "none",
                width: "60px",
                fontWeight: "bold",
                fontSize: "20px",
                marginTop: "2px",
                textAlign: "center",
              }}
              onChange={(e) => {
                setData({
                  ...data,
                  preciznaDodavanjaNaSvojojPolovini: e.target.value,
                });
              }}
              type="number"
              min="0"
            />
          </div>
          <div className={classes.col3}>
            Precizna dodavanja na svojoj polovini
          </div>
          <div className={classes.col4}>
            <input
              style={{
                border: "none",
                width: "60px",
                fontWeight: "bold",
                fontSize: "20px",
                marginTop: "2px",
                textAlign: "center",
              }}
              onChange={(e) => {
                setData({
                  ...data,
                  preciznaDodavanjaNaSvojojPolovini2: e.target.value,
                });
              }}
              type="number"
              min="0"
            />
          </div>
          <div className={classes.col5}>
            {calculatedData.preciznaDodavanjaNaSvojojPolovini2} %
          </div>
        </div>

        <div className={classes.row}>
          <div className={classes.col1}>
            {calculatedData.preciznaDodavanjaNaProtivnickojPolovini} %
          </div>
          <div className={classes.col2}>
            <input
              style={{
                border: "none",
                width: "60px",
                fontWeight: "bold",
                fontSize: "20px",
                marginTop: "2px",
                textAlign: "center",
              }}
              onChange={(e) => {
                setData({
                  ...data,
                  preciznaDodavanjaNaProtivnickojPolovini: e.target.value,
                });
              }}
              type="number"
              min="0"
            />
          </div>
          <div className={classes.col3}>
            Precizna dodavanja na protivničkoj polovini
          </div>
          <div className={classes.col4}>
            <input
              style={{
                border: "none",
                width: "60px",
                fontWeight: "bold",
                fontSize: "20px",
                marginTop: "2px",
                textAlign: "center",
              }}
              onChange={(e) => {
                setData({
                  ...data,
                  preciznaDodavanjaNaProtivnickojPolovini2: e.target.value,
                });
              }}
              type="number"
              min="0"
            />
          </div>
          <div className={classes.col5}>
            {calculatedData.preciznaDodavanjaNaProtivnickojPolovini2} %
          </div>
        </div>

        <div className={classes.row}>
          <div className={classes.col1}>
            {calculatedData.precizneDugeLopte} %
          </div>
          <div className={classes.col2}>
            <input
              style={{
                border: "none",
                width: "60px",
                fontWeight: "bold",
                fontSize: "20px",
                marginTop: "2px",
                textAlign: "center",
              }}
              onChange={(e) => {
                setData({
                  ...data,
                  precizneDugeLopte: e.target.value,
                });
              }}
              type="number"
              min="0"
            />
          </div>
          <div className={classes.col3}>Precizne duge lopte</div>
          <div className={classes.col4}>
            <input
              style={{
                border: "none",
                width: "60px",
                fontWeight: "bold",
                fontSize: "20px",
                marginTop: "2px",
                textAlign: "center",
              }}
              onChange={(e) => {
                setData({
                  ...data,
                  precizneDugeLopte2: e.target.value,
                });
              }}
              type="number"
              min="0"
            />
          </div>
          <div className={classes.col5}>
            {calculatedData.precizneDugeLopte2} %
          </div>
        </div>

        <div className={classes.row}>
          <div className={classes.col1}>
            {calculatedData.preciznoPrebacenihPasova} %
          </div>
          <div className={classes.col2}>
            <input
              style={{
                border: "none",
                width: "60px",
                fontWeight: "bold",
                fontSize: "20px",
                marginTop: "2px",
                textAlign: "center",
              }}
              onChange={(e) => {
                setData({
                  ...data,
                  preciznoPrebacenihPasova: e.target.value,
                });
              }}
              type="number"
              min="0"
            />
          </div>
          <div className={classes.col3}>Precizne prebačenih pasova</div>
          <div className={classes.col4}>
            <input
              style={{
                border: "none",
                width: "60px",
                fontWeight: "bold",
                fontSize: "20px",
                marginTop: "2px",
                textAlign: "center",
              }}
              onChange={(e) => {
                setData({
                  ...data,
                  preciznoPrebacenihPasova2: e.target.value,
                });
              }}
              type="number"
              min="0"
            />
          </div>
          <div className={classes.col5}>
            {calculatedData.preciznoPrebacenihPasova2} %
          </div>
        </div>

        <div className={classes.row}>
          <div className={classes.col1}>
            {calculatedData.precizniCentrasutevi} %
          </div>
          <div className={classes.col2}>
            <input
              style={{
                border: "none",
                width: "60px",
                fontWeight: "bold",
                fontSize: "20px",
                marginTop: "2px",
                textAlign: "center",
              }}
              onChange={(e) => {
                setData({
                  ...data,
                  precizniCentrasutevi: e.target.value,
                });
              }}
              type="number"
              min="0"
            />
          </div>
          <div className={classes.col3}>Precizni centrašutevi</div>
          <div className={classes.col4}>
            <input
              style={{
                border: "none",
                width: "60px",
                fontWeight: "bold",
                fontSize: "20px",
                marginTop: "2px",
                textAlign: "center",
              }}
              onChange={(e) => {
                setData({
                  ...data,
                  precizniCentrasutevi2: e.target.value,
                });
              }}
              type="number"
              min="0"
            />
          </div>
          <div className={classes.col5}>
            {calculatedData.precizniCentrasutevi2} %
          </div>
        </div>

        <div className={classes.row}>
          <div className={classes.col1}>
            {calculatedData.presecenaDodavanjaPoUtakmici} %
          </div>
          <div className={classes.col2}>
            <input
              style={{
                border: "none",
                width: "60px",
                fontWeight: "bold",
                fontSize: "20px",
                marginTop: "2px",
                textAlign: "center",
              }}
              onChange={(e) => {
                setData({
                  ...data,
                  presecenaDodavanjaPoUtakmici: e.target.value,
                });
              }}
              type="number"
              min="0"
            />
          </div>
          <div className={classes.col3}>Presečena dodavanja po utakmici</div>
          <div className={classes.col4}>
            <input
              style={{
                border: "none",
                width: "60px",
                fontWeight: "bold",
                fontSize: "20px",
                marginTop: "2px",
                textAlign: "center",
              }}
              onChange={(e) => {
                setData({
                  ...data,
                  presecenaDodavanjaPoUtakmici2: e.target.value,
                });
              }}
              type="number"
              min="0"
            />
          </div>
          <div className={classes.col5}>
            {calculatedData.presecenaDodavanjaPoUtakmici2} %
          </div>
        </div>

        <div className={classes.row}>
          <div className={classes.col1}>
            {calculatedData.oduzeteLoptePoUtakmici} %
          </div>
          <div className={classes.col2}>
            <input
              style={{
                border: "none",
                width: "60px",
                fontWeight: "bold",
                fontSize: "20px",
                marginTop: "2px",
                textAlign: "center",
              }}
              onChange={(e) => {
                setData({
                  ...data,
                  oduzeteLoptePoUtakmici: e.target.value,
                });
              }}
              type="number"
              min="0"
            />
          </div>
          <div className={classes.col3}>Oduzete lopte po utakmici</div>
          <div className={classes.col4}>
            <input
              style={{
                border: "none",
                width: "60px",
                fontWeight: "bold",
                fontSize: "20px",
                marginTop: "2px",
                textAlign: "center",
              }}
              onChange={(e) => {
                setData({
                  ...data,
                  oduzeteLoptePoUtakmici2: e.target.value,
                });
              }}
              type="number"
              min="0"
            />
          </div>
          <div className={classes.col5}>
            {calculatedData.oduzeteLoptePoUtakmici2} %
          </div>
        </div>

        <div className={classes.row}>
          <div className={classes.col1}>
            {calculatedData.posedOsvojenihLopti} %
          </div>
          <div className={classes.col2}>
            <input
              style={{
                border: "none",
                width: "60px",
                fontWeight: "bold",
                fontSize: "20px",
                marginTop: "2px",
                textAlign: "center",
              }}
              onChange={(e) => {
                setData({
                  ...data,
                  posedOsvojenihLopti: e.target.value,
                });
              }}
              type="number"
              min="0"
            />
          </div>
          <div className={classes.col3}>Posed osvojenih lopti</div>
          <div className={classes.col4}>
            <input
              style={{
                border: "none",
                width: "60px",
                fontWeight: "bold",
                fontSize: "20px",
                marginTop: "2px",
                textAlign: "center",
              }}
              onChange={(e) => {
                setData({
                  ...data,
                  posedOsvojenihLopti2: e.target.value,
                });
              }}
              type="number"
              min="0"
            />
          </div>
          <div className={classes.col5}>
            {calculatedData.posedOsvojenihLopti2} %
          </div>
        </div>

        <div className={classes.row}>
          <div className={classes.col1}>
            {calculatedData.prediblanPoUtakmici} %
          </div>
          <div className={classes.col2}>
            <input
              style={{
                border: "none",
                width: "60px",
                fontWeight: "bold",
                fontSize: "20px",
                marginTop: "2px",
                textAlign: "center",
              }}
              onChange={(e) => {
                setData({
                  ...data,
                  prediblanPoUtakmici: e.target.value,
                });
              }}
              type="number"
              min="0"
            />
          </div>
          <div className={classes.col3}>Prediblan po utakmici</div>
          <div className={classes.col4}>
            <input
              style={{
                border: "none",
                width: "60px",
                fontWeight: "bold",
                fontSize: "20px",
                marginTop: "2px",
                textAlign: "center",
              }}
              onChange={(e) => {
                setData({
                  ...data,
                  prediblanPoUtakmici2: e.target.value,
                });
              }}
              type="number"
              min="0"
            />
          </div>
          <div className={classes.col5}>
            {calculatedData.prediblanPoUtakmici2} %
          </div>
        </div>

        <div className={classes.row}>
          <div className={classes.col1}>
            {calculatedData.otklonjeneOpasnostiPoUtakmici} %
          </div>
          <div className={classes.col2}>
            <input
              style={{
                border: "none",
                width: "60px",
                fontWeight: "bold",
                fontSize: "20px",
                marginTop: "2px",
                textAlign: "center",
              }}
              onChange={(e) => {
                setData({
                  ...data,
                  otklonjeneOpasnostiPoUtakmici: e.target.value,
                });
              }}
              type="number"
              min="0"
            />
          </div>
          <div className={classes.col3}>Otklonjene opasnosti po utakmici</div>
          <div className={classes.col4}>
            <input
              style={{
                border: "none",
                width: "60px",
                fontWeight: "bold",
                fontSize: "20px",
                marginTop: "2px",
                textAlign: "center",
              }}
              onChange={(e) => {
                setData({
                  ...data,
                  otklonjeneOpasnostiPoUtakmici2: e.target.value,
                });
              }}
              type="number"
              min="0"
            />
          </div>
          <div className={classes.col5}>
            {calculatedData.otklonjeneOpasnostiPoUtakmici2} %
          </div>
        </div>

        <div className={classes.row}>
          <div className={classes.col1}>
            {calculatedData.skrivljeniSledeciUdarci} %
          </div>
          <div className={classes.col2}>
            <input
              style={{
                border: "none",
                width: "60px",
                fontWeight: "bold",
                fontSize: "20px",
                marginTop: "2px",
                textAlign: "center",
              }}
              onChange={(e) => {
                setData({
                  ...data,
                  skrivljeniSledeciUdarci: e.target.value,
                });
              }}
              type="number"
              min="0"
            />
          </div>
          <div className={classes.col3}>Skrivljeni slobodni udarci</div>
          <div className={classes.col4}>
            <input
              style={{
                border: "none",
                width: "60px",
                fontWeight: "bold",
                fontSize: "20px",
                marginTop: "2px",
                textAlign: "center",
              }}
              onChange={(e) => {
                setData({
                  ...data,
                  skrivljeniSledeciUdarci2: e.target.value,
                });
              }}
              type="number"
              min="0"
            />
          </div>
          <div className={classes.col5}>
            {calculatedData.skrivljeniSledeciUdarci2} %
          </div>
        </div>

        <div className={classes.row}>
          <div className={classes.col1}>
            {calculatedData.uspesniDriblinzi} %
          </div>
          <div className={classes.col2}>
            <input
              style={{
                border: "none",
                width: "60px",
                fontWeight: "bold",
                fontSize: "20px",
                marginTop: "2px",
                textAlign: "center",
              }}
              onChange={(e) => {
                setData({
                  ...data,
                  uspesniDriblinzi: e.target.value,
                });
              }}
              type="number"
              min="0"
            />
          </div>
          <div className={classes.col3}>Uspešni driblinzi</div>
          <div className={classes.col4}>
            <input
              style={{
                border: "none",
                width: "60px",
                fontWeight: "bold",
                fontSize: "20px",
                marginTop: "2px",
                textAlign: "center",
              }}
              onChange={(e) => {
                setData({
                  ...data,
                  uspesniDriblinzi2: e.target.value,
                });
              }}
              type="number"
              min="0"
            />
          </div>
          <div className={classes.col5}>
            {calculatedData.uspesniDriblinzi2} %
          </div>
        </div>

        <div className={classes.row}>
          <div className={classes.col1}>
            {calculatedData.ukupniOsvnojeniDueli} %
          </div>
          <div className={classes.col2}>
            <input
              style={{
                border: "none",
                width: "60px",
                fontWeight: "bold",
                fontSize: "20px",
                marginTop: "2px",
                textAlign: "center",
              }}
              onChange={(e) => {
                setData({
                  ...data,
                  ukupniOsvnojeniDueli: e.target.value,
                });
              }}
              type="number"
              min="0"
            />
          </div>
          <div className={classes.col3}>Uspešni osvojeni dueli</div>
          <div className={classes.col4}>
            <input
              style={{
                border: "none",
                width: "60px",
                fontWeight: "bold",
                fontSize: "20px",
                marginTop: "2px",
                textAlign: "center",
              }}
              onChange={(e) => {
                setData({
                  ...data,
                  ukupniOsvnojeniDueli2: e.target.value,
                });
              }}
              type="number"
              min="0"
            />
          </div>
          <div className={classes.col5}>
            {calculatedData.ukupniOsvnojeniDueli2} %
          </div>
        </div>

        <div className={classes.row}>
          <div className={classes.col1}>
            {calculatedData.osvojeniDueliNaTlu} %
          </div>
          <div className={classes.col2}>
            <input
              style={{
                border: "none",
                width: "60px",
                fontWeight: "bold",
                fontSize: "20px",
                marginTop: "2px",
                textAlign: "center",
              }}
              onChange={(e) => {
                setData({
                  ...data,
                  osvojeniDueliNaTlu: e.target.value,
                });
              }}
              type="number"
              min="0"
            />
          </div>
          <div className={classes.col3}>Osvojeni dueli na tlu</div>
          <div className={classes.col4}>
            <input
              style={{
                border: "none",
                width: "60px",
                fontWeight: "bold",
                fontSize: "20px",
                marginTop: "2px",
                textAlign: "center",
              }}
              onChange={(e) => {
                setData({
                  ...data,
                  osvojeniDueliNaTlu2: e.target.value,
                });
              }}
              type="number"
              min="0"
            />
          </div>
          <div className={classes.col5}>
            {calculatedData.osvojeniDueliNaTlu2} %
          </div>
        </div>

        <div className={classes.row}>
          <div className={classes.col1}>
            {calculatedData.osvojeniVazdusniDueli} %
          </div>
          <div className={classes.col2}>
            <input
              style={{
                border: "none",
                width: "60px",
                fontWeight: "bold",
                fontSize: "20px",
                marginTop: "2px",
                textAlign: "center",
              }}
              onChange={(e) => {
                setData({
                  ...data,
                  osvojeniVazdusniDueli: e.target.value,
                });
              }}
              type="number"
              min="0"
            />
          </div>
          <div className={classes.col3}>Osvojeni vazdušni dueli</div>
          <div className={classes.col4}>
            <input
              style={{
                border: "none",
                width: "60px",
                fontWeight: "bold",
                fontSize: "20px",
                marginTop: "2px",
                textAlign: "center",
              }}
              onChange={(e) => {
                setData({
                  ...data,
                  osvojeniVazdusniDueli2: e.target.value,
                });
              }}
              type="number"
              min="0"
            />
          </div>
          <div className={classes.col5}>
            {calculatedData.osvojeniVazdusniDueli2} %
          </div>
        </div>

        <div className={classes.row}>
          <div className={classes.col1}>
            {calculatedData.IzgubljeniPosediLopte} %
          </div>
          <div className={classes.col2}>
            <input
              style={{
                border: "none",
                width: "60px",
                fontWeight: "bold",
                fontSize: "20px",
                marginTop: "2px",
                textAlign: "center",
              }}
              onChange={(e) => {
                setData({
                  ...data,
                  IzgubljeniPosediLopte: e.target.value,
                });
              }}
              type="number"
              min="0"
            />
          </div>
          <div className={classes.col3}>Izgubljeni posedi lopte</div>
          <div className={classes.col4}>
            <input
              style={{
                border: "none",
                width: "60px",
                fontWeight: "bold",
                fontSize: "20px",
                marginTop: "2px",
                textAlign: "center",
              }}
              onChange={(e) => {
                setData({
                  ...data,
                  IzgubljeniPosediLopte2: e.target.value,
                });
              }}
              type="number"
              min="0"
            />
          </div>
          <div className={classes.col5}>
            {calculatedData.IzgubljeniPosediLopte2} %
          </div>
        </div>

        <div className={classes.row}>
          <div className={classes.col1}>{calculatedData.faulovi} %</div>
          <div className={classes.col2}>
            <input
              style={{
                border: "none",
                width: "60px",
                fontWeight: "bold",
                fontSize: "20px",
                marginTop: "2px",
                textAlign: "center",
              }}
              onChange={(e) => {
                setData({
                  ...data,
                  faulovi: e.target.value,
                });
              }}
              type="number"
              min="0"
            />
          </div>
          <div className={classes.col3}>Faulovi</div>
          <div className={classes.col4}>
            <input
              style={{
                border: "none",
                width: "60px",
                fontWeight: "bold",
                fontSize: "20px",
                marginTop: "2px",
                textAlign: "center",
              }}
              onChange={(e) => {
                setData({
                  ...data,
                  faulovi2: e.target.value,
                });
              }}
              type="number"
              min="0"
            />
          </div>
          <div className={classes.col5}>{calculatedData.faulovi2} %</div>
        </div>

        <div className={classes.row}>
          <div className={classes.col1}>
            {calculatedData.pretrpljeniFaulovi} %
          </div>
          <div className={classes.col2}>
            <input
              style={{
                border: "none",
                width: "60px",
                fontWeight: "bold",
                fontSize: "20px",
                marginTop: "2px",
                textAlign: "center",
              }}
              onChange={(e) => {
                setData({
                  ...data,
                  pretrpljeniFaulovi: e.target.value,
                });
              }}
              type="number"
              min="0"
            />
          </div>
          <div className={classes.col3}>Pretrpljeni faulovi</div>
          <div className={classes.col4}>
            <input
              style={{
                border: "none",
                width: "60px",
                fontWeight: "bold",
                fontSize: "20px",
                marginTop: "2px",
                textAlign: "center",
              }}
              onChange={(e) => {
                setData({
                  ...data,
                  pretrpljeniFaulovi2: e.target.value,
                });
              }}
              type="number"
              min="0"
            />
          </div>
          <div className={classes.col5}>
            {calculatedData.pretrpljeniFaulovi2} %
          </div>
        </div>

        <div className={classes.row}>
          <div className={classes.col1}>{calculatedData.ofsajdi} %</div>
          <div className={classes.col2}>
            <input
              style={{
                border: "none",
                width: "60px",
                fontWeight: "bold",
                fontSize: "20px",
                marginTop: "2px",
                textAlign: "center",
              }}
              onChange={(e) => {
                setData({
                  ...data,
                  ofsajdi: e.target.value,
                });
              }}
              type="number"
              min="0"
            />
          </div>
          <div className={classes.col3}>Ofsajdi</div>
          <div className={classes.col4}>
            <input
              style={{
                border: "none",
                width: "60px",
                fontWeight: "bold",
                fontSize: "20px",
                marginTop: "2px",
                textAlign: "center",
              }}
              onChange={(e) => {
                setData({
                  ...data,
                  ofsajdi2: e.target.value,
                });
              }}
              type="number"
              min="0"
            />
          </div>
          <div className={classes.col5}>{calculatedData.ofsajdi2} %</div>
        </div>

        <div className={classes.row}>
          <div className={classes.col1}>{calculatedData.zutiKartoni} %</div>
          <div className={classes.col2}>
            <input
              style={{
                border: "none",
                width: "60px",
                fontWeight: "bold",
                fontSize: "20px",
                marginTop: "2px",
                textAlign: "center",
              }}
              onChange={(e) => {
                setData({
                  ...data,
                  zutiKartoni: e.target.value,
                });
              }}
              type="number"
              min="0"
            />
          </div>
          <div className={classes.col3}>Žuti kartoni</div>
          <div className={classes.col4}>
            <input
              style={{
                border: "none",
                width: "60px",
                fontWeight: "bold",
                fontSize: "20px",
                marginTop: "2px",
                textAlign: "center",
              }}
              onChange={(e) => {
                setData({
                  ...data,
                  zutiKartoni2: e.target.value,
                });
              }}
              type="number"
              min="0"
            />
          </div>
          <div className={classes.col5}>{calculatedData.zutiKartoni2} %</div>
        </div>

        <div className={classes.row}>
          <div className={classes.col1}>
            {calculatedData.drugiZutiKartoni} %
          </div>
          <div className={classes.col2}>
            <input
              style={{
                border: "none",
                width: "60px",
                fontWeight: "bold",
                fontSize: "20px",
                marginTop: "2px",
                textAlign: "center",
              }}
              onChange={(e) => {
                setData({
                  ...data,
                  drugiZutiKartoni: e.target.value,
                });
              }}
              type="number"
              min="0"
            />
          </div>
          <div className={classes.col3}>Drugi žuti kartoni</div>
          <div className={classes.col4}>
            <input
              style={{
                border: "none",
                width: "60px",
                fontWeight: "bold",
                fontSize: "20px",
                marginTop: "2px",
                textAlign: "center",
              }}
              onChange={(e) => {
                setData({
                  ...data,
                  drugiZutiKartoni2: e.target.value,
                });
              }}
              type="number"
              min="0"
            />
          </div>
          <div className={classes.col5}>
            {calculatedData.drugiZutiKartoni2} %
          </div>
        </div>

        <div className={classes.row}>
          <div className={classes.col1}>{calculatedData.crveniKartoni} %</div>
          <div className={classes.col2}>
            <input
              style={{
                border: "none",
                width: "60px",
                fontWeight: "bold",
                fontSize: "20px",
                marginTop: "2px",
                textAlign: "center",
              }}
              onChange={(e) => {
                setData({
                  ...data,
                  crveniKartoni: e.target.value,
                });
              }}
              type="number"
              min="0"
            />
          </div>
          <div className={classes.col3}>Crveni kartoni</div>
          <div className={classes.col4}>
            <input
              style={{
                border: "none",
                width: "60px",
                fontWeight: "bold",
                fontSize: "20px",
                marginTop: "2px",
                textAlign: "center",
              }}
              onChange={(e) => {
                setData({
                  ...data,
                  crveniKartoni2: e.target.value,
                });
              }}
              type="number"
              min="0"
            />
          </div>
          <div className={classes.col5}>{calculatedData.crveniKartoni2} %</div>
        </div>
      </div>
    </div>
  );
};

export default PoredenjeTimovaFudbal;
