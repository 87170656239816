import { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import AdminPostSearchModule from "./AdminPostSearchModule";
import AdminSinglePost from "./AdminSinglePost";
import Spinner from "../components/Spinner";
import classes from "./adminNasiPredlozi.module.css";

const AdminNasiPredlozi = () => {
  const [isArray, setIsArray] = useState(false);
  const navigate = useNavigate();
  const axiosPrivate = useAxiosPrivate();

  const getAllNasiPredloziPosts = async () => {
    const { data } = await axiosPrivate.get("/getAllNasiPredloziPosts");
    const isArray = await Array.isArray(data.result);
    if (isArray) {
      setIsArray(true);
    }
    return data;
  };
  const allNasiPredloziPostsQuery = useQuery({
    queryKey: ["NasiPredloziPosts"],
    queryFn: getAllNasiPredloziPosts,
    staleTime: 0,
    refetchInterval: 1000 * 60 * 60 * 12,
  });

  if (allNasiPredloziPostsQuery.isError) return <h2>Error...</h2>;

  const handleNewPost = () => {
    navigate("/betko-admin/kreiraj-novi-post", {
      state: "nasiPredlozi",
    });
  };

  return (
    <div className={classes.container}>
      {allNasiPredloziPostsQuery.isLoading ? (
        <Spinner />
      ) : (
        <>
          <h1>VAŠI PREDLOZI</h1>
          <div className={classes.top}>
            {isArray && allNasiPredloziPostsQuery.data.result !== undefined && (
              <AdminPostSearchModule type="nasiPredlozi" />
            )}
            <button onClick={() => handleNewPost()} className={classes.btn}>
              Dodaj Novi
            </button>
          </div>
          <div className={classes.postContainer}>
            <div>
              {isArray && allNasiPredloziPostsQuery.data.result !== undefined
                ? allNasiPredloziPostsQuery.data.result.map((post) => {
                    return (
                      <AdminSinglePost
                        post={post}
                        key={post._id}
                        type="nasiPredlozi"
                      />
                    );
                  })
                : allNasiPredloziPostsQuery.data.msg}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default AdminNasiPredlozi;
