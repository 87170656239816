import Grupa1 from '../components/Grupa1'
import Grupa28 from '../components/Grupa28'
import Grupa311 from '../components/Grupa311'
import classes from './procenaTacnogRezultata.module.css'
import { useEffect, useState } from 'react'

const ProcenaTacnogRezultata = () => {
  const [ki1, setKi1] = useState()
  const [kiX, setKiX] = useState()
  const [ki2, setKi2] = useState()
  const [ug02, setUg02] = useState()
  const [ug3, setUg3] = useState()
  const [domPlus, setDomacinPlus] = useState()
  const [domMinus, setDomMinus] = useState()
  const [domacinBrojUtakmica, setDomacinBrojUtakmica] = useState()
  const [gostMinus, setGostMinus] = useState()
  const [gostPlus, setGostPlus] = useState()
  const [gostBrojUtakmica, setGostBrojUtakmica] = useState()

  const B27 = Number(ki1)
  const D27 = Number(kiX)
  const F27 = Number(ki2)
  const H27 = Number(ug02)
  const J27 = Number(ug3)
  const L27 = Number(domPlus)
  const N27 = Number(domMinus)
  const P27 = Number(domacinBrojUtakmica)
  const Q27 = Number(gostMinus)
  const S27 = Number(gostPlus)
  const U27 = Number(gostBrojUtakmica)

  const L66 = L27
  const M66 = N27
  const N66 = P27
  const O66 = Q27
  const P66 = S27
  const Q66 = U27
  const C70 = B27
  const D70 = D27
  const E70 = F27
  const G70 = H27
  const H70 = J27

  const I70 = (1 / G70) * 100
  const J70 = (1 / H70) * 100
  const K70 = I70 + J70
  const L70 = K70 - 100
  const M70 = L70 / 2
  const N70 = I70 - M70
  const O70 = J70 - M70

  const P70 = () => {
    if (N70 < 0) {
      return 0
    } else {
      return N70
    }
  }

  const Q70 = () => {
    if (O70 < 0) {
      return 0
    } else {
      return O70
    }
  }

  const B23 = L66 / N66
  const D23 = P27 / Q27
  const F23 = Q27 / U27
  const H23 = S27 / U27
  const J23 = (D23 + F23) / 2
  const L23 = (B23 + H23) / 2
  const N23 = (B23 + F23) / 2
  const P23 = (D23 + H23) / 2
  const T66 = (M66 + O66) / (N66 + Q66)
  const T64 = (L66 + P66) / (N66 + Q66)
  const R70 = (Q70() * 5) / 100
  const S66 = (R70 * 2 * 25) / 100
  const S70 = B23
  const T70 = D23
  const U70 = F23
  const V70 = H23
  const O68 = O70
  const P68 = P70()
  const Q68 = Q70()
  const R68 = R70 - S66
  const T68 = Math.min(T64, T66)
  const U68 = T68 * 100
  const W59 = (R68 + T68) / 2
  const X59 = () => {
    if (W59 >= 5) {
      return W59 - (W59 * 3.12) / 100
    } else if (W59 >= 4) {
      return W59 - (W59 * 2.14) / 100
    } else if (W59 >= 3) {
      return W59 - (W59 * 1.21) / 100
    } else if (W59 >= 2) {
      return W59 + (W59 * 1.16) / 100
    } else if (W59 < 2) {
      return W59 + (W59 * 1.11) / 100
    }
  }
  const W61 = (R70 + S70 + T70 + U70 + V70) / 5

  let SND = (L66 / N66).toFixed(2)
  let SOD = (N27 / P27).toFixed(2)
  let SOG = (Q27 / U27).toFixed(2)
  let SNG = (S27 / U27).toFixed(2)
  let SODG = ((parseFloat(SOD) + parseFloat(SOG)) / 2).toFixed(2)
  let SNDG = ((B23 + H23) / 2).toFixed(2)
  let SNDSOG = ((B23 + F23) / 2).toFixed(2)
  let SODSNG = ((parseFloat(SOD) + parseFloat(SNG)) / 2).toFixed(2)

  const X61 = () => {
    if (W61 >= 5) {
      return W61 - (W61 * 3.12) / 100
    } else if (W61 >= 4) {
      return W61 - (W61 * 2.14) / 100
    } else if (W61 >= 3) {
      return W61 - (W61 * 1.21) / 100
    } else if (W61 >= 2) {
      return W61 + (W61 * 1.16) / 100
    } else if (W61 < 2) {
      return W61 + (W61 * 1.11) / 100
    }
  }

  const O72 = O70
  const P72 = P70()
  const Q72 = Q70()
  const R72 = R70 + S66
  const T72 = Math.max(T64, T66)

  const W63 = (R72 + T72) / 2

  const X63 = () => {
    if (W63 >= 5) {
      return W63 - (W63 * 3.12) / 100
    } else if (W63 >= 4) {
      return W63 - (W63 * 2.14) / 100
    } else if (W63 >= 3) {
      return W63 - 1.21 / 100
    } else if (W63 >= 2) {
      return W63 + (W63 * 1.16) / 100
    } else if (W63 < 2) {
      return W63 + (W63 * 1.11) / 100
    }
  }

  const W68 = X59()
  const W72 = X63()

  let W70

  if (W61 >= 5) {
    W70 = W61 - (W61 * 3.12) / 100
  } else if (W61 >= 4) {
    W70 = W61 - (W61 * 2.14) / 100
  } else if (W61 >= 3) {
    W70 = W61 - 1.21 / 100
  } else if (W61 >= 2) {
    W70 = W61 + (W61 * 1.16) / 100
  } else if (W61 < 2) {
    W70 = W61 + (W61 * 1.11) / 100
  }

  const C74 = C70
  const D74 = D70
  const E74 = E70

  const F74 = (1 / C74) * 100
  const G74 = (1 / D74) * 100
  const H74 = (1 / E74) * 100
  const I74 = F74 + G74 + H74 - 100
  const J74 = F74 - I74 / 3
  const K74 = G74 - I74 / 3
  const L74 = H74 - I74 / 3
  const M74 = (J74 * I74) / 100
  const N74 = (K74 * I74) / 100
  const O74 = (L74 * I74) / 100
  const P74 = F74 - M74
  const Q74 = G74 - N74
  const R74 = H74 - O74
  const S74 = P74 + Q74 / 2
  const T74 = 100 - S74

  let W70Second = (W68 + W72) / 2

  const Y70 = (W70Second * S74) / 100

  const Z70 = (W70Second * T74) / 100

  const AA70 = (Y70 * 45) / 100
  const AB70 = (Z70 * 45) / 100

  const AC70 = () => {
    if (Y70 < 0) {
      return 0
    } else {
      return Math.round(Y70)
    }
  }

  const AD70 = () => {
    if (Z70 < 0) {
      return 0
    } else {
      return Math.round(Z70)
    }
  }

  const AE70 = () => {
    if (AA70 < 0) {
      return 0
    } else {
      return Math.round(AA70)
    }
  }

  const AF70 = () => {
    if (AB70 < 0) {
      return 0
    } else {
      return Math.round(AB70)
    }
  }

  const AG70 = `${AC70()} : ${AD70()}  (${AE70()} : ${AF70()})`

  const AH70 = () => {
    if (Y70 < 0) {
      return 0
    } else {
      return Y70
    }
  }

  const AI70 = () => {
    if (Z70 < 0) {
      return 0
    } else {
      return Z70
    }
  }

  const AJ70 = () => {
    if (AA70 < 0) {
      return 0
    } else {
      return AA70
    }
  }

  const AK70 = () => {
    if (AB70 < 0) {
      return 0
    } else {
      return AB70
    }
  }
  // console.log(W68, S74);
  const Y68 = (W68 * S74) / 100

  const Z68 = (W68 * T74) / 100
  const AA68 = Y68 * 0.45
  const AB68 = (Z68 * 45) / 100
  const AC68 = () => {
    if (Y68 < 0) {
      return 0
    } else {
      return parseFloat(Y68).toFixed(0)
    }
  }

  const AD68 = () => {
    if (Z68 < 0) {
      return 0
    } else {
      return parseFloat(Z68).toFixed(0)
    }
  }

  const AE68 = () => {
    if (AA68 < 0) {
      return 0
    } else {
      return parseFloat(AA68).toFixed(0)
    }
  }

  const AF68 = () => {
    if (AB68 < 0) {
      return 0
    } else {
      return parseFloat(AB68).toFixed(0)
    }
  }

  const AG68 = `${AC68()} : ${AD68()}  (${AE68()} : ${AF68()})`

  const AH68 = () => {
    if (Y68 < 0) {
      return 0
    } else {
      return Y68
    }
  }

  const AI68 = () => {
    if (Z68 < 0) {
      return 0
    } else {
      return Z68
    }
  }

  const AJ68 = () => {
    if (AA68 < 0) {
      return 0
    } else {
      return AA68
    }
  }

  const AK68 = () => {
    if (AB68 < 0) {
      return 0
    } else {
      return AB68
    }
  }

  const Y72 = (W72 * S74) / 100
  const Z72 = (T74 * W72) / 100
  const AA72 = (Y72 * 45) / 100
  const AB72 = (Z72 * 45) / 100
  const AC72 = () => {
    if (Y72 < 0) {
      return 0
    } else {
      return Math.round(Y72)
    }
  }

  const AD72 = () => {
    if (Z72 < 0) {
      return 0
    } else {
      return Math.round(Z72)
    }
  }

  const AE72 = () => {
    if (AA72 < 0) {
      return 0
    } else {
      return Math.round(AA72)
    }
  }

  const AF72 = () => {
    if (AB72 < 0) {
      return 0
    } else {
      return Math.round(AB72)
    }
  }

  const AG72 = `${AC72()} : ${AD72()}  (${AE72()} : ${AF72()})`

  // console.log(Z68, Z70, Z72);

  const AH72 = () => {
    if (Y72 < 0) {
      return 0
    } else {
      return Y72
    }
  }

  const AI72 = () => {
    if (Z72 < 0) {
      return 0
    } else {
      return Z72
    }
  }

  const AJ72 = () => {
    if (AA72 < 0) {
      return 0
    } else {
      return AA72
    }
  }

  const AK72 = () => {
    if (AB72 < 0) {
      return 0
    } else {
      return AB72
    }
  }

  const P77 = P74 + Q74
  const Q77 = P74 + R74
  const R77 = Q74 + R74

  const B23Value =
    isNaN(B23) || B23 === Infinity || B23 === 0 ? '-' : B23.toFixed(2)
  const D23Value =
    isNaN(D23) || D23 === Infinity || D23 === 0 ? '-' : B23.toFixed(2)
  const F23Value =
    isNaN(F23) || F23 === Infinity || F23 === 0 ? '-' : F23.toFixed(2)
  const H23Value =
    isNaN(H23) || H23 === Infinity || H23 === 0 ? '-' : H23.toFixed(2)
  const J23Value =
    isNaN(J23) || J23 === Infinity || J23 === 0 ? '-' : J23.toFixed(2)
  const L23Value =
    isNaN(L23) || L23 === Infinity || L23 === 0 ? '-' : L23.toFixed(2)
  const N23Value =
    isNaN(N23) || N23 === Infinity || N23 === 0 ? '-' : N23.toFixed(2)
  const P23Value =
    isNaN(P23) || P23 === Infinity || P23 === 0 ? '-' : P23.toFixed(2)

  const B30Value =
    isNaN(P74) || P74 === Infinity || P74 === 0 ? null : P74.toFixed(2)
  const D30Value =
    isNaN(Q74) || Q74 === Infinity || Q74 === 0 ? null : Q74.toFixed(2)
  const F30Value =
    isNaN(R74) || R74 === Infinity || R74 === 0 ? null : R74.toFixed(2)
  const H30Value =
    isNaN(P70()) || P70() === Infinity || P70() === 0 ? null : P70().toFixed(2)
  const J30Value =
    isNaN(Number(H30Value)) ||
    Number(H30Value) === Infinity ||
    Number(H30Value) === 0
      ? null
      : Number(100 - H30Value).toFixed(2)

  const L31 = (R70 + S70 + parseFloat(SOD) + U70 + V70 + W70Second) / 6

  const L31Value =
    isNaN(L31) || L31 === Infinity || L31 === 0 ? null : L31.toFixed(2)
  const B36Value =
    isNaN(P77) || P77 === Infinity || P77 === 0 ? null : P77.toFixed(2)
  const H36Value =
    isNaN(Q77) || Q77 === Infinity || Q77 === 0 ? null : Q77.toFixed(2)
  const P36Value =
    isNaN(R77) || R77 === Infinity || R77 === 0 ? null : R77.toFixed(2)
  const H42Value =
    isNaN(AH68()) || AH68() === Infinity || AH68() === 0
      ? null
      : AH68().toFixed(2)
  const J42Value =
    isNaN(AI68()) || AI68() === Infinity || AI68() === 0
      ? null
      : AI68().toFixed(2)
  const L42Value =
    isNaN(AJ68()) || AJ68() === Infinity || AJ68() === 0
      ? null
      : AJ68().toFixed(2)
  const N42Value =
    isNaN(AK68()) || AK68() === Infinity || AK68() === 0
      ? null
      : AK68().toFixed(2)

  const P42Value =
    isNaN(AC68()) || AC68() === Infinity ? null : Math.round(AC68())

  const R42Value =
    isNaN(AD68()) || AD68() === Infinity ? null : Math.round(AD68())
  const S42Value =
    isNaN(AE68()) || AE68() === Infinity ? null : Math.round(AE68())
  const T42Value =
    isNaN(AF68()) || AF68() === Infinity ? null : Math.round(AF68())

  const H45Value =
    isNaN(AH70()) || AH70() === Infinity || AH70() === 0
      ? null
      : AH70().toFixed(2)
  const J45Value =
    isNaN(AI70()) || AI70() === Infinity || AI70() === 0
      ? null
      : AI70().toFixed(2)
  const L45Value =
    isNaN(AJ70()) || AJ70() === Infinity || AJ70() === 0
      ? null
      : AJ70().toFixed(2)
  const N45Value =
    isNaN(AK70()) || AK70() === Infinity || AK70() === 0
      ? null
      : AK70().toFixed(2)

  const P45Value =
    isNaN(AC70()) || AC70() === Infinity ? null : Math.round(AC70())
  const R45Value =
    isNaN(AD70()) || AD70() === Infinity ? null : Math.round(AD70())
  const S45Value =
    isNaN(AE70()) || AE70() === Infinity ? null : Math.round(AE70())
  const T45Value =
    isNaN(AF70()) || AF70() === Infinity ? null : Math.round(AF70())

  const H48Value =
    isNaN(AH72()) || AH72() === Infinity || AH72() === 0
      ? null
      : AH72().toFixed(2)
  const J48Value =
    isNaN(AI72()) || AI72() === Infinity || AI72() === 0
      ? null
      : AI72().toFixed(2)
  const L48Value =
    isNaN(AJ72()) || AJ72() === Infinity || AJ72() === 0
      ? null
      : AJ72().toFixed(2)
  const N48Value =
    isNaN(AK72()) || AK72() === Infinity || AK72() === 0
      ? null
      : AK72().toFixed(2)

  const P48Value =
    isNaN(AC72()) || AC72() === Infinity ? null : Math.round(AC72())
  const R48Value =
    isNaN(AD72()) || AD72() === Infinity ? null : Math.round(AD72())
  const S48Value =
    isNaN(AE72()) || AE72() === Infinity ? null : Math.round(AE72())
  const T48Value =
    isNaN(AF72()) || AF72() === Infinity ? null : Math.round(AF72())

  // useEffect(() => {
  //   console.log(AG68);
  // }, []);

  return (
    <div className={classes.container}>
      <Grupa1
        imgText='PROCENA TAČNOG REZULTATA'
        imgBackgroundColorDark='#062C81'
        imgBackgroundColorLight='#0D2869'
        imgFontColor='whitesmoke'
        label1='VREME POČETKA'
        label2='ZEMLJA'
        label3='DOMAĆIN'
        label4='TAKMIČENJE - LIGA'
        label5='GOST'
      />
      <Grupa28
        imgBackgroundColorDark='#062C81'
        imgBackgroundColorLight='#0D2869'
        imgFontColor='whitesmoke'
        label1={!isNaN(SND) ? SND : ''}
        label2={!isNaN(SOD) ? SOD : ''}
        label3={!isNaN(SOG) ? SOG : ''}
        label4={!isNaN(SNG) ? SNG : ''}
        label5={!isNaN(SODG) ? SODG : ''}
        label6={!isNaN(SNDG) ? SNDG : ''}
        label7={!isNaN(SNDSOG) ? SNDSOG : ''}
        label8={!isNaN(SODSNG) ? SODSNG : ''}
        calculatorType={`Procena tacnog rezultata`}
      />
      <div style={{ display: 'flex', gap: '20px', marginTop: '5px' }}>
        <input
          onChange={(e) => setKi1(e.target.value)}
          type='text'
          style={{
            width: '85px',
            height: '40px',
            border: '4px solid black',
            borderRadius: '5px',
            fontWeight: 'bold',
            fontSize: '20px',
            textAlign: 'center',
          }}
          value={ki1}
          placeholder='KI 1'
        />
        <input
          onChange={(e) => setKiX(e.target.value)}
          type='text'
          style={{
            width: '85px',
            height: '40px',
            border: '4px solid black',
            borderRadius: '5px',
            fontWeight: 'bold',
            fontSize: '20px',
            textAlign: 'center',
          }}
          value={kiX}
          placeholder='KI X'
        />
        <input
          onChange={(e) => setKi2(e.target.value)}
          type='text'
          style={{
            width: '85px',
            height: '40px',
            border: '4px solid black',
            borderRadius: '5px',
            fontWeight: 'bold',
            fontSize: '20px',
            textAlign: 'center',
          }}
          value={ki2}
          placeholder='KI 2'
        />
        <input
          onChange={(e) => setUg02(e.target.value)}
          type='text'
          style={{
            width: '85px',
            height: '40px',
            border: '4px solid black',
            borderRadius: '5px',
            fontWeight: 'bold',
            fontSize: '20px',
            textAlign: 'center',
          }}
          value={ug02}
          placeholder='UG 0-2'
        />
        <input
          onChange={(e) => setUg3(e.target.value)}
          type='text'
          style={{
            width: '85px',
            height: '40px',
            border: '4px solid black',
            borderRadius: '5px',
            fontWeight: 'bold',
            fontSize: '20px',
            textAlign: 'center',
          }}
          value={ug3}
          placeholder='UG 3+'
        />
        <input
          onChange={(e) => setDomacinPlus(e.target.value)}
          type='text'
          style={{
            width: '85px',
            height: '40px',
            border: '4px solid black',
            borderRadius: '5px',
            fontWeight: 'bold',
            fontSize: '20px',
            textAlign: 'center',
          }}
          value={domPlus}
          placeholder='DOM +'
        />
        <input
          onChange={(e) => setDomMinus(e.target.value)}
          type='text'
          style={{
            width: '85px',
            height: '40px',
            border: '4px solid black',
            borderRadius: '5px',
            fontWeight: 'bold',
            fontSize: '20px',
            textAlign: 'center',
          }}
          value={domMinus}
          placeholder='DOM -'
        />
        <input
          onChange={(e) => setDomacinBrojUtakmica(e.target.value)}
          type='text'
          style={{
            width: '85px',
            height: '40px',
            border: '4px solid black',
            borderRadius: '5px',
            fontWeight: 'bold',
            fontSize: '20px',
            textAlign: 'center',
          }}
          value={domacinBrojUtakmica}
          placeholder='DOM. BR. UT.'
        />
        <input
          onChange={(e) => setGostMinus(e.target.value)}
          type='text'
          style={{
            width: '85px',
            height: '40px',
            border: '4px solid black',
            borderRadius: '5px',
            fontWeight: 'bold',
            fontSize: '20px',
            textAlign: 'center',
          }}
          value={gostMinus}
          placeholder='GOST -'
        />
        <input
          onChange={(e) => setGostPlus(e.target.value)}
          type='text'
          style={{
            width: '85px',
            height: '40px',
            border: '4px solid black',
            borderRadius: '5px',
            fontWeight: 'bold',
            fontSize: '20px',
            textAlign: 'center',
          }}
          value={gostPlus}
          placeholder='GOST +'
        />
        <input
          onChange={(e) => setGostBrojUtakmica(e.target.value)}
          type='text'
          style={{
            width: '85px',
            height: '40px',
            border: '4px solid black',
            borderRadius: '5px',
            fontWeight: 'bold',
            fontSize: '20px',
            textAlign: 'center',
          }}
          value={gostBrojUtakmica}
          placeholder='GOST BR. UT.'
        />
      </div>

      <div className={classes.calculator}>
        <p className={classes.treci}>
          {B30Value} {B30Value && '%'}
        </p>
        <p className={classes.cetvrti}>
          {D30Value} {D30Value && '%'}
        </p>
        <p className={classes.peti}>
          {F30Value} {F30Value && '%'}
        </p>
        <p className={classes.sesti}>
          {H30Value} {H30Value && '%'}
        </p>
        <p className={classes.sedmi}>
          {J30Value} {J30Value && '%'}
        </p>
        <p className={classes.osmi}>
          {B36Value} {B36Value && '%'}
        </p>
        <p className={classes.deveti}>
          {H36Value} {H36Value && '%'}
        </p>
        <p className={classes.deseti}>
          {P36Value} {P36Value && '%'}
        </p>
        <p className={classes.jedanaesti}>{L31Value}</p>
        <p className={classes.dvanaesti}>{H42Value}</p>
        <p className={classes.trinaesti}>{J42Value}</p>
        <p className={classes.cetrnaesti}>{L42Value}</p>
        <p className={classes.petnaesti}>{N42Value}</p>
        <p className={classes.sesnaesti}>{P42Value}</p>
        <p className={classes.sedamnaesti}>{R42Value}</p>
        <p className={classes.osamnaesti}>{S42Value}</p>
        <p className={classes.devetnaesti}>{T42Value}</p>
        <p className={classes.dvadeseti}>{H45Value}</p>
        <p className={classes.dvadesetprvi}>{J45Value}</p>
        <p className={classes.dvadesetdrugi}>{L45Value}</p>
        <p className={classes.dvadesettreci}>{N45Value}</p>
        <p className={classes.dvadesetcetvrti}>{P45Value}</p>
        <p className={classes.dvadesetpeti}>{R45Value}</p>
        <p className={classes.dvadesetsesti}>{S45Value}</p>
        <p className={classes.dvadesetsedmi}>{T45Value}</p>
        <p className={classes.dvadesetosmi}>{H48Value}</p>
        <p className={classes.dvadesetdeveti}>{J48Value}</p>
        <p className={classes.trideseti}>{L48Value}</p>
        <p className={classes.tridesetprvi}>{N48Value}</p>
        <p className={classes.tridesetdrugi}>{P48Value}</p>
        <p className={classes.tridesettreci}>{R48Value}</p>
        <p className={classes.tridesetcetvrti}>{S48Value}</p>
        <p className={classes.tridesetpeti}>{T48Value}</p>
      </div>
    </div>
  )
}

export default ProcenaTacnogRezultata
