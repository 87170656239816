import classes from "./adminOpstiUslovi.module.css";

const AdminOpstiUslovi = () => {
  return (
    <div className={classes.container}>
      <h2>Stranica - OPSTI USLOVI</h2>
    </div>
  );
};

export default AdminOpstiUslovi;
