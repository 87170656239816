import { useState, useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import AdminPostSearchModule from "./AdminPostSearchModule";
import AdminSinglePost from "./AdminSinglePost";
import Spinner from "../components/Spinner";
import classes from "./adminKorisnickiPaketi.module.css";
import { Select, notification } from "antd";

const { Option } = Select;

const AdminKorisnickiPaketi = () => {
  const [isArray, setIsArray] = useState(false);
  const navigate = useNavigate();
  const axiosPrivate = useAxiosPrivate();
  const [generalTerms, setGeneralTerms] = useState();

  useEffect(() => {
    fetchGeneralTerms();
  }, []);

  const getAllKorisnickiPaketi = async () => {
    const { data } = await axiosPrivate.get("/users-packages");
    const isArray = await Array.isArray(data.result);
    if (isArray) {
      setIsArray(true);
    }
    return data;
  };

  const allKorisnickiPaketi = useQuery({
    queryKey: ["korisnickiPaketiPosts"],
    queryFn: getAllKorisnickiPaketi,
    staleTime: 0,
    refetchInterval: 1000 * 60 * 60 * 12,
  });

  if (allKorisnickiPaketi.isError) return <h2>Error...</h2>;

  const handleNewPost = () => {
    navigate("/betko-admin/kreiraj-novi-post-korisnickipaketi");
  };

  const handleChangeSelected = async (e) => {
    try {
      await axiosPrivate.put(`/general-terms/${e}`);

      notification.success({
        message: "Uspešno izmenjeni opšti uslovi.",
      });
    } catch (error) {
      notification.error({
        message: "Greška pri menjanju objave za opšte uslove!",
      });
      console.log(error);
    }
  };

  const fetchGeneralTerms = async () => {
    const res = await axiosPrivate.get(`/general-terms`);

    setGeneralTerms(res.data._id);
  };

  return (
    <div className={classes.container}>
      {allKorisnickiPaketi.isLoading ? (
        <Spinner />
      ) : (
        <>
          <h1>KORISNIČKI PAKETI</h1>
          <div className={classes.top}>
            {isArray && allKorisnickiPaketi.data.result !== undefined && (
              <AdminPostSearchModule type="korisnickiPaketi" />
            )}
            <button onClick={() => handleNewPost()} className={classes.btn}>
              Dodaj Novi
            </button>
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <p>Opsti uslovi:</p>
            <Select
              style={{ minWidth: "300px", marginLeft: "10px" }}
              showSearch
              filterOption={(input, option) =>
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
              onChange={(e) => {
                handleChangeSelected(e);
              }}
              defaultValue={generalTerms}
            >
              {allKorisnickiPaketi &&
                allKorisnickiPaketi.data &&
                allKorisnickiPaketi.data.result &&
                allKorisnickiPaketi.data.result.map((item, index) => {
                  return (
                    <Option key={item._id} value={item._id}>
                      {item.title}
                    </Option>
                  );
                })}
            </Select>
          </div>
          <div className={classes.postContainer}>
            <div>
              {isArray && allKorisnickiPaketi.data.result !== undefined
                ? allKorisnickiPaketi.data.result.map((post) => {
                    return (
                      <AdminSinglePost
                        post={post}
                        key={post._id}
                        type="korisnickiPaketi"
                      />
                    );
                  })
                : allKorisnickiPaketi.data.msg}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default AdminKorisnickiPaketi;
