import React from 'react'
import classes from './CardPlaceHolder.module.css'

const CardPlaceHolder = ({ isShopPage }) => {
  return (
    <div
      className={`${classes.cardPlaceholder} ${
        isShopPage ? classes.shopPlaceholder : classes.homePagePlaceholder
      }`}
    >
      <div className={classes.imageWrapper}>
        <img src={'/tiger-placeholder.webp'} alt='product placeholder' />
      </div>
      <span className={classes.cardTitle}>
        OVO JE MESTO <br /> ZA TVOJU PONUDU
      </span>
    </div>
  )
}

export default CardPlaceHolder
