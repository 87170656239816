import { useState, useEffect, useContext } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import useRefreshToken from "../hooks/useRefreshToken";
import AuthContext from "../context/AuthProvider";
import Spinner from "./Spinner";

const PersistLogin = () => {
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const refresh = useRefreshToken();
  const { auth } = useContext(AuthContext);

  // console.log("auth - persistLogin: ", auth);

  useEffect(() => {
    let isMounted = true;
    const verifyRefreshToken = async () => {
      try {
        await refresh();
      } catch (error) {
        console.log(error);
        // if (error.response.data === "Unauthorized") {
        //   console.log("tu li sam persist error: ");
        //   navigate("/registracija_i_logovanje");
        // }
      } finally {
        isMounted && setIsLoading(false);
      }
    };

    !auth?.accessToken ? verifyRefreshToken() : setIsLoading(false);

    return () => (isMounted = false);
  }, []);

  // useEffect(() => {
  // console.log(`Persist Login - IsLoading: ${isLoading}`);
  // console.log(
  //   `Persist Login - accessToken: ${JSON.stringify(auth?.accessToken)}`
  // );
  // console.log(`Persist Login - roles: ${JSON.stringify(auth?.roles)}`);
  // console.log(
  //   `Persist Login - paidToDate: ${JSON.stringify(auth?.paidToDate)}`
  // );
  // console.log(`Persist Login - email: ${JSON.stringify(auth?.email)}`);
  // }, [isLoading]);

  // return <>{isLoading ? <p>Loading...</p> : <Outlet />}</>;
  return <>{isLoading ? <Spinner /> : <Outlet />}</>;
};

export default PersistLogin;
