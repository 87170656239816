import { useState } from "react";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import { useQuery } from "@tanstack/react-query";
import SinglePost from "../components/SinglePost";
import Spinner from "../components/Spinner";
import classes from "./betKalkulatoriTutorial.module.css";

const BetKalkulatoriTutorial = () => {
  const [posts, setPosts] = useState([]);
  const [isMsg, setIsMsg] = useState(false);
  const axiosPrivate = useAxiosPrivate();

  const getAllBetKalkulatoriTutorijalPosts = async () => {
    const { data } = await axiosPrivate.get(
      "/getAllBetKalkulatoriTutorijalPosts"
    );
    data.msg ? setIsMsg(true) : setPosts(data.result);
    !data.msg ? setIsMsg(false) : setPosts([]);
    return data;
  };
  const allBetKalkulatoriTutorijalPostsQuery = useQuery({
    queryKey: ["betKalkulatoriTutorijal"],
    queryFn: getAllBetKalkulatoriTutorijalPosts,
    staleTime: 0,
    refetchInterval: 1000 * 60 * 60 * 12,
  });
  return (
    <div className={classes.container}>
      {allBetKalkulatoriTutorijalPostsQuery.isLoading || posts.length === 0 ? (
        <div className={classes.spinner}>
          <Spinner />
        </div>
      ) : (
        <>
          {!isMsg ? (
            posts.map((post) => {
              return (
                <SinglePost
                  key={post._id}
                  post={post}
                  postId={post._id}
                  type="betKalkulatoriTutorijal"
                />
              );
            })
          ) : (
            <div className={classes.msg}>
              <p>{allBetKalkulatoriTutorijalPostsQuery.data.msg}</p>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default BetKalkulatoriTutorial;
