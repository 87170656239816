import classes from "./betKursTutorial.module.css";

const BetKursTutorial = () => {
  return (
    <div className={classes.mainCenter}>
      <p>
        Sa leve strane sajta nalazi se meni koji za tendenciju ima edukaciju
        igračkog tela i usmerenje na dobre i loše stvari koje prati igra, ali i
        o opisu svega onoga što je čini. Izborom tema glavnog menija i tema
        pod-menija imate mogućnost da prelistavanjem, čitanjem i tako reći
        učenjem iz obimnog materijala koji je postavljen na sajtu, postanete
        bolji igrač i bolji prognozer. BET KURS Vam ukazuje i na mnoge zamke
        koje Vas čekaju u igri, ali i na mnoge prednosti. Iskoristite mogućnosti
        koje Vam ovaj svojevrsni, originalni i sveobuhvatni materijal nudi, kako
        biste znali više o sportskom klađenju.
      </p>
      <p>
        U okviru menija BET KURS, kao i u okviru svih drugih menija, nećemo biti
        statični, jer je zamisao i ideja našeg sajta usmerena ka tome da on živi
        na dnevnom nivou, a to će podrazumevati unos novih materijala, što
        aktuelnih, što statističkih obrada, što novih kalkulatora, te isto tako
        i novih materijala u BET KURS delu sajta.
      </p>
      <p>
        Unutar menija PREMIUM dopunjavamo edukativni materijal, pri čemu taj
        meni jeste nastavak BET KURS menija. Namena tog menija jeste da kada
        prođete kompletan BET KURS kroz čitanje, dalje možete da nastavite da
        pratite naše nove objave na temu edukacije vezane za igru, i da uz nas
        učite o igri kroz selektivno praćenje PREMIUM menija, kroz nove
        materijale koje dopunjavamo u okviru edukativnog dela sajta.
      </p>
      <p>
        Pratite nas, posećujte naš sajt, i učinite potrebno da budete bolji
        kladioničar, sa više znanja, uz čiju implementaciju uvek možete da
        napravite iskorake u personalnoj igri i u učinku vezanom za istu. Kroz
        naše upute istovremno možete izbeći sve one zamke koje čine osnovnu i
        veoma bitnu razliku između granica zabave, kao užitka u igri, i kocke,
        kao realne opasnosti i nečega što svakako nije dobar, preporučljiv i
        odgovoran način da spovodite svoju igru u delo, i u realizaciju.
      </p>
    </div>
  );
};

export default BetKursTutorial;
