import axios from 'axios'
import React, { useEffect, useRef, useState } from 'react'
import classes from './News.module.css'
import { IoStarSharp } from 'react-icons/io5'
import { useNavigate } from 'react-router-dom'

const News = () => {
  const newsContentRef = useRef(null)
  const [allNews, setAllNews] = useState(null)
  const navigate = useNavigate()
  const SERVER_URL = process.env.REACT_APP_SERVER_URL

  useEffect(() => {
    const getAllNews = async () => {
      try {
        const response = await axios.get(`${SERVER_URL}/get-all-news`)
        setAllNews(response.data)
      } catch (err) {
        console.error('Error fetching news:', err)
      }
    }

    getAllNews()
  }, [])

  // Duplicate content to avoid animation gap
  useEffect(() => {
    const newsContent = newsContentRef.current

    const duplicateContent = () => {
      if (!newsContent.dataset.duplicated) {
        const originalContent = newsContent.innerHTML
        newsContent.innerHTML += originalContent
        newsContent.dataset.duplicated = 'true'
      }
    }

    const setAnimationDuration = () => {
      const contentWidth = newsContent.scrollWidth
      const speed = 100 // pixels per second
      const duration = contentWidth / speed
      newsContent.style.animationDuration = `${duration}s`
    }

    if (allNews?.allNews.length > 0) {
      duplicateContent()
      setAnimationDuration()
    }
  }, [JSON.stringify(allNews?.allNews)])

  return (
    <div className={classes.newsContainer}>
      <div
        className={classes.sideTitle}
        onClick={() => navigate('/bet_portal/65611ea0f4ca9761eb5249a3')}
      >
        KLIKNI <br /> PORTAL
      </div>
      <div style={{ display: 'flex' }}>
        <div className={classes.newsContent} ref={newsContentRef}>
          <IoStarSharp className={classes.redStartIcon} />
          <IoStarSharp className={classes.redStartIcon} />
          {allNews?.allNews?.map((newsItem) => (
            <div key={newsItem._id} className={classes.newsItem}>
              {newsItem.content}
              <IoStarSharp className={classes.redStartIcon} />
            </div>
          ))}
        </div>
      </div>
      <div
        className={`${classes.sideTitle} ${classes.sideTitleRight}`}
        onClick={() => navigate('/bet_portal/653e46c052a098b10de971c3')}
      >
        KLIKNI <br /> KLADŽE
      </div>
    </div>
  )
}

export default News
