import { useNavigate } from 'react-router-dom'
import classes from './shopAdminSidePanel.module.css'
import { useContext } from 'react'
import AuthContext from '../context/AuthProvider'

const ShopAdminSidePanel = () => {
  const navigate = useNavigate()
  const { auth } = useContext(AuthContext)
  const isAdmin = auth.roles.includes(5151)

  return (
    <div className={classes.container}>
      <div className={classes.main}>
        <ul>
          <li onClick={() => navigate('/betko-shop-admin/home')}>HOME</li>
          {isAdmin && (
            <>
              <li onClick={() => navigate('/betko-shop-admin/prodavci')}>
                PRODAVCI
              </li>
              <li onClick={() => navigate('/betko-shop-admin/porudzbine')}>
                PORUDŽBINE
              </li>
              <li onClick={() => navigate('/betko-shop-admin/kategorije')}>
                KATEGORIJE
              </li>
              <li onClick={() => navigate('/betko-shop-admin/preuzmi-korisnike-i-porudzbine')}>
                PREUZMI PODATKE
              </li>
            </>
          )}
          <li onClick={() => navigate('/betko-shop-admin/proizvodi')}>
            PROIZVODI
          </li>
        </ul>
      </div>
    </div>
  )
}

export default ShopAdminSidePanel
