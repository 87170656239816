import React, { useEffect, useState } from 'react'
import HomeLayout from '../../layout/HomeLayout'
import BetkoShopHome from './BetkoShopHome'
import classes from './BetkoShopLayout.module.css'
import ShopHeader from '../../components/BetkoShop/ShopHeader'
import LoginModal from '../../components/LoginModal'
import { Modal } from 'antd'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'

const BetkoShopLayout = ({ children }) => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768)
  const [isOpenLoginModal, setIsOpenLoginModal] = useState(false)
  const [searchParams, setSearchParams] = useSearchParams()

  const [searchTerm, setSearchTerm] = useState(searchParams.get('searchTerm'))
  const navigate = useNavigate()
  const location = useLocation()

  const onChangeSearchTerm = (e) => {
    const newSearchTerm = e.target.value
    setSearchTerm(newSearchTerm)
    const params = new URLSearchParams(searchParams)
    params.set('searchTerm', newSearchTerm)

    if (location.pathname !== '/betko_shop/shop') {
      navigate(`/betko_shop/shop?${params.toString()}`, {
        state: { from: 'nonShopPage' },
      })
    } else {
      setSearchParams(params)
    }
  }

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768)
    }

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  if (!isMobile) {
    return (
      <>
        <HomeLayout
          betkoShop={
            <div className={classes.betkoShopLayout}>
              <ShopHeader
                setIsOpenLoginModal={setIsOpenLoginModal}
                onChangeSearchTerm={onChangeSearchTerm}
                searchTerm={searchTerm}
              />
              {children}
            </div>
          }
        />
        <LoginModal
          setIsOpenLoginModal={setIsOpenLoginModal}
          isOpenLoginModal={isOpenLoginModal}
          title='Dobro došli na BETKO SHOP!'
        />
      </>
    )
  }

  return (
    <div className={classes.betkoShopLayout}>
      <ShopHeader
        setIsOpenLoginModal={setIsOpenLoginModal}
        onChangeSearchTerm={onChangeSearchTerm}
        searchTerm={searchTerm}
      />
      {children}
      <LoginModal
        setIsOpenLoginModal={setIsOpenLoginModal}
        isOpenLoginModal={isOpenLoginModal}
        title='Dobro došli na BETKO SHOP!'
      />
    </div>
  )
}

export default BetkoShopLayout
