import Grupa3 from "../components/Grupa3";
import Grupa20 from "../components/Grupa20";
import classes from "./paroli.module.css";
import { useState, useEffect } from "react";

const Paroli = () => {
  const [ulogUpis, setUlogUpis] = useState(undefined);
  const [ulogRes, setUlogRes] = useState([0, 0]);
  const [kvota, setKvota] = useState([undefined, undefined, undefined]);
  const [evDobitak, setEvDobitak] = useState([0, 0, 0]);
  const [plus, setPlus] = useState([0, 0, 0]);
  const [ukupno, setUkupno] = useState(0);
  const [render, setReRender] = useState(false);

  const handleChangeUlog = (e) => {
    if (e === "") {
      setUlogUpis(0);
    } else {
      setUlogUpis(parseFloat(e));
    }
  };

  const handleChangeKvota = (e, index) => {
    let kvotaarr = kvota;
    if (e === "") {
      kvotaarr[index] = null;

      setKvota(kvotaarr);
    } else {
      kvotaarr[index] = parseFloat(e);

      setKvota(kvotaarr);
    }
    setReRender(!render);
  };

  const handleCalculate = () => {
    if (ulogUpis && kvota[0]) {
      let evdobitak1 = ulogUpis * kvota[0];

      let plus1 = evdobitak1 - ulogUpis;

      let ulog2 = plus1;

      let evdobitak2 = ulogRes[0] * kvota[1];

      let plus2 = evdobitak2 - ulogUpis;

      let ulog3 = plus2;

      let evdobitak3 = ulogRes[1] * kvota[2];

      let plus3 = evdobitak3 - ulogUpis;

      if (kvota[0] && kvota[0] !== 0) {
        let prvi = isNaN(plus1) ? 0 : plus1;
        let drugi = isNaN(plus2) ? 0 : plus2;
        let treci = isNaN(plus3) ? 0 : plus3;

        let ukupnolet = prvi + drugi + treci;
        setUkupno(ukupnolet - ulogUpis);
      } else {
        setUkupno(0);
      }

      setUlogRes([ulog2, ulog3]);

      setEvDobitak([evdobitak1, evdobitak2, evdobitak3]);

      setPlus([plus1, plus2, plus3]);

      setReRender(!render);
    } else {
      setUlogRes([0, 0]);

      setEvDobitak([0, 0, 0]);

      setPlus([0, 0, 0]);
    }
  };

  useEffect(() => {
    handleCalculate();
  }, [ulogUpis, kvota, render]);

  return (
    <div className={classes.container}>
      <Grupa3
        imgText="PAROLI"
        imgBackgroundColorDark="#062C82"
        imgBackgroundColorLight="#0E2660"
        imgFontColor="#F43308"
      />
      <Grupa20
        imgBackgroundColorDark="#062C82"
        imgBackgroundColorLight="#0E2660"
        imgFontColor="#F43308"
        calculatorType={`Paroli`}
      />

      <div className={classes.calculator}>
        <div className={classes.tabela}>
          <div className={classes.column} style={{ width: "20%" }}>
            <div className={classes.headerCelija}>
              <p>SERIJA BR.</p>
            </div>
            <div
              className={classes.celija}
              style={{ background: "rebeccapurple" }}
            >
              <p style={{ fontSize: "30px", marginTop: "5px" }}>1</p>
            </div>
            <div
              className={classes.celija}
              style={{ background: "rebeccapurple" }}
            >
              <p style={{ fontSize: "30px", marginTop: "5px" }}>2</p>
            </div>
            <div
              className={classes.celija}
              style={{ background: "rebeccapurple", borderBottom: "none" }}
            >
              <p style={{ fontSize: "30px", marginTop: "5px" }}>3</p>
            </div>
          </div>

          <div className={classes.column} style={{ width: "13%" }}>
            <div className={classes.headerCelija}>
              <p>ULOG</p>
            </div>
            <div className={classes.celija}>
              <input
                type="number"
                value={ulogUpis}
                onChange={(e) => {
                  handleChangeUlog(e.target.value);
                }}
              ></input>
            </div>
            <div className={classes.celija} style={{ background: "orangered" }}>
              <p style={{ fontSize: "30px", marginTop: "5px" }}>
                {isNaN(ulogRes[0]) ? 0 : ulogRes[0].toFixed(2)}
              </p>
            </div>
            <div
              className={classes.celija}
              style={{ background: "orangered", borderBottom: "none" }}
            >
              <p style={{ fontSize: "30px", marginTop: "5px" }}>
                {isNaN(ulogRes[1]) ? 0 : ulogRes[1].toFixed(2)}
              </p>
            </div>
          </div>

          <div className={classes.column} style={{ width: "13%" }}>
            <div className={classes.headerCelija}>
              <p>KVOTA.</p>
            </div>
            <div className={classes.celija}>
              <input
                type="number"
                onChange={(e) => {
                  handleChangeKvota(e.target.value, 0);
                }}
                value={kvota[0]}
              ></input>
            </div>
            <div className={classes.celija}>
              <input
                type="number"
                onChange={(e) => {
                  handleChangeKvota(e.target.value, 1);
                }}
                value={kvota[1]}
              ></input>
            </div>
            <div className={classes.celija} style={{ borderBottom: "none" }}>
              <input
                type="number"
                onChange={(e) => {
                  handleChangeKvota(e.target.value, 2);
                }}
                value={kvota[2]}
              ></input>
            </div>
          </div>

          <div className={classes.column} style={{ width: "20%" }}>
            <div className={classes.headerCelija}>
              <p>EV. DOBITAK</p>
            </div>
            <div className={classes.celija} style={{ background: "orangered" }}>
              <p style={{ fontSize: "30px", marginTop: "5px" }}>
                {isNaN(evDobitak[0]) ? 0 : evDobitak[0].toFixed(2)}
              </p>
            </div>
            <div className={classes.celija} style={{ background: "orangered" }}>
              <p style={{ fontSize: "30px", marginTop: "5px" }}>
                {isNaN(evDobitak[1]) ? 0 : evDobitak[1].toFixed(2)}
              </p>
            </div>
            <div
              className={classes.celija}
              style={{ background: "orangered", borderBottom: "none" }}
            >
              <p style={{ fontSize: "30px", marginTop: "5px" }}>
                {isNaN(evDobitak[2]) ? 0 : evDobitak[2].toFixed(2)}
              </p>
            </div>
          </div>

          <div className={classes.column} style={{ width: "20%" }}>
            <div className={classes.headerCelija}>
              <p>PLUS</p>
            </div>
            <div className={classes.celija} style={{ background: "orangered" }}>
              <p style={{ fontSize: "30px", marginTop: "5px" }}>
                {isNaN(plus[0]) ? 0 : plus[0].toFixed(2)}
              </p>
            </div>
            <div className={classes.celija} style={{ background: "orangered" }}>
              <p style={{ fontSize: "30px", marginTop: "5px" }}>
                {isNaN(plus[1]) ? 0 : plus[1].toFixed(2)}
              </p>
            </div>
            <div
              className={classes.celija}
              style={{ background: "orangered", borderBottom: "none" }}
            >
              <p style={{ fontSize: "30px", marginTop: "5px" }}>
                {isNaN(plus[2]) ? 0 : plus[2].toFixed(2)}
              </p>
            </div>
          </div>

          <div
            className={classes.column}
            style={{ width: "13%", background: "darkblue" }}
          >
            <div className={classes.headerCelija}>
              <p style={{ fontSize: "70px", marginTop: "0px" }}>+</p>
            </div>
            <div
              className={classes.celija}
              style={{
                background: "orangered",
                height: "160px",
                borderBottom: "none",
              }}
            >
              <p style={{ fontSize: "50px", marginTop: "55px" }}>
                {isNaN(ukupno) ? 0 : ukupno.toFixed(2)}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Paroli;
