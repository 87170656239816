import React, { useEffect, useState } from 'react'
import classes from './MisterTipsterAwards.module.css'
import { message, Modal } from 'antd'
import axios from '../config/axios'
import { IoMdArrowDropleft } from 'react-icons/io'
import { useNavigate } from 'react-router-dom'
import Pagination from '../components/Pagination'
const urlRegex = /(https?:\/\/[^\s]+?(?=\s|$))/g
const SERVER_URL = process.env.REACT_APP_SERVER_URL

const MisterTipsterAwards = () => {
  const [awards, setAwards] = useState(null)
  const [awardArchive, setAllAwardsArchive] = useState()
  const [currentPage, setCurrentPage] = useState(0)
  const [selectedAward, setSelectedAward] = useState(null)
  const [previewAwardModal, setPreviewAwardModal] = useState(false)
  const navigate = useNavigate()

  useEffect(() => {
    const getAwards = async () => {
      try {
        const response = await axios.get(`${SERVER_URL}/get-all-awards`)
        setAwards({
          y: response?.data[0]?.year[0],
          m: response?.data[0]?.month[0],
          r: response?.data[0]?.rank[0],
        })
      } catch (err) {
        message.error(
          err?.response?.data?.message || 'Greška pri preuzimanju nagrada!'
        )
      }
    }

    getAwards()
  }, [])

  useEffect(() => {
    const getAllAwardsArchive = async () => {
      try {
        const response = await axios.get(
          `${SERVER_URL}/get-all-user-awards/${currentPage}/6`
        )

        setAllAwardsArchive(response?.data)
      } catch (error) {
        setAllAwardsArchive([])
        message.error(
          error?.response?.data?.message ||
            'Greška pri preuzimanju arhive nagrada!'
        )
      }
    }

    getAllAwardsArchive()
  }, [currentPage])

  const formatAwards = (text) => {
    if (!text) {
      return
    }
    const segments = text.split(urlRegex)
    const formattedText = []

    for (let i = 0; i < segments.length; i++) {
      const segment = segments[i]
      if (segment?.match(urlRegex)) {
        // If the segment is a URL, create an anchor element
        const url = segment.trim()
        formattedText.push(
          <a href={url} target='_blank' rel='noopener noreferrer' key={i}>
            KLIKNI I POGLEDAJ NAGRADU
          </a>
        )
      } else {
        formattedText.push(<span key={i}>{segment}</span>)
      }
    }

    return formattedText
  }

  const paginationProps = {
    setCurrentPage: setCurrentPage,
    limit: 6,
    currentPage: currentPage,
    totalCount: awardArchive?.totalCount,
    totalPages: Math.ceil(awardArchive?.totalCount / 6),
    buttonLimit: 3,
  }

  return (
    <div className={classes.tipsterAwardsPage}>
      <Modal
        open={previewAwardModal}
        title='OPIS NAGRADE'
        onCancel={() => {
          setPreviewAwardModal(false)
          setSelectedAward(null)
        }}
        closable
        footer={null}
        className={classes.awardPreviewModal}
      >
        <p>{selectedAward?.award}</p>
        <p>{selectedAward?.description}</p>
      </Modal>
      {currentPage === 0 && (
        <>
          <div className={classes.titleSticky}>
            <button
              className={classes.backButton}
              onClick={() => navigate('/mister_tipster')}
            >
              <IoMdArrowDropleft />
              <span>NAZAD</span>
            </button>
            <div className={classes.mainTitle}>
              <h3>FOND TAKMIČARSKIH IGARA</h3>
            </div>
          </div>
          <div className={classes.awards}>
            <div className={classes.awardsItem}>
              <div className={classes.awardsItemHeader}>
                <h4>GODIŠNJA TABELA</h4>
              </div>
              <p className={classes.period}>{awards?.y?.period}</p>
              <ul>
                <li>1. NAGRADA: {formatAwards(awards?.y?.firstPlace)}</li>
                <li>2. NAGRADA: {formatAwards(awards?.y?.secondPlace)}</li>
                <li>3. NAGRADA: {formatAwards(awards?.y?.thirdPlace)}</li>
              </ul>
            </div>
            <div className={classes.awardsItem}>
              <div className={classes.awardsItemHeader}>
                <h4>UKUPNA MESEČNA TABELA</h4>
              </div>
              <p className={classes.period}>{awards?.m?.period}</p>
              <ul>
                <li>1. MESTO: {formatAwards(awards?.m?.firstPlace)}</li>
                <li>
                  2. MESTO:
                  {formatAwards(awards?.m?.secondPlace)}
                </li>
                <li>3. MESTO: {formatAwards(awards?.m?.thirdPlace)}</li>
              </ul>
            </div>
            <div className={classes.awardsItem}>
              <div className={classes.awardsItemHeader}>
                <h4>TABELA RANGA</h4>
              </div>
              <p className={classes.period}>{awards?.r?.period}</p>
              <ul>
                <li>{formatAwards(awards?.r?.firstPlace)}</li>
              </ul>
            </div>
          </div>
        </>
      )}
      <div
        className={classes.tableTile}
        onClick={() => {
          if (currentPage === 0) {
            setCurrentPage(1)
          }
        }}
      >
        {currentPage >= 1 && (
          <button
            onClick={() => setCurrentPage((curr) => curr - 1)}
            className={classes.tableBack}
          >
            <IoMdArrowDropleft />
            <span>NAZAD</span>
          </button>
        )}
        <span>SPISAK DOBITNIKA</span>
      </div>
      {currentPage > 0 && (
        <div className={classes.tableContainer}>
          <table className={classes.table}>
            <thead>
              <tr>
                <th>TAKMICENJE</th>
                <th>NAGRADA</th>
                <th>PERIOD TAKMICENJA</th>
                <th>TAKMICAR</th>
                <th>OPIS NAGRADE</th>
              </tr>
            </thead>
            <tbody>
              {awardArchive?.paginatedData?.map((item, index) => (
                <tr key={index}>
                  <td>
                    {item.table === 'year'
                      ? 'GODIŠNJE'
                      : item.table === 'month'
                      ? 'MESEČNO'
                      : 'RANG'}
                  </td>
                  <td>
                    {item.place === 'firstPlace'
                      ? '1. NAGRADA'
                      : item.place === 'secondPlace'
                      ? '2. Nagrada'
                      : '3. Nagrada'}
                  </td>
                  <td>{item.period}</td>
                  <td>{item.user?.username || item.user?.userName}</td>
                  <td
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      setPreviewAwardModal(true)
                      setSelectedAward(item)
                    }}
                  >
                    OPIS NAGRADE
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <button
            onClick={() => setCurrentPage(0)}
            className={classes.backToActual}
          >
            VRATI SE NA AKTUELNO TAKMIČENJE
          </button>
          <Pagination {...paginationProps} />
        </div>
      )}
    </div>
  )
}

export default MisterTipsterAwards
