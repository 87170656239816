import Grupa3 from "../components/Grupa3";
import Grupa20 from "../components/Grupa20";
import classes from "./devetMagicnihLinija.module.css";

const DevetMagicnihLinija = () => {
  return (
    <div className={classes.container}>
      <Grupa3
        imgText="DEVET MAGIČNIH LINIJA"
        imgBackgroundColorDark="#0C5AD6"
        imgBackgroundColorLight="#0A64EF"
        imgFontColor="#F43308"
      />
      <Grupa20
        imgBackgroundColorDark="#0C5AD6"
        imgBackgroundColorLight="#0A64EF"
        imgFontColor="#F43308"
        linkOpisKalkulatora="/bet_kalkulatori_tutorijal/645b0c5dcd9c8da743a1951b"
      />

        <div className={classes.calculator} style={{marginLeft:"10px", display:"flex", flexDirection:"column", fontWeight:"bolder"}}>

            {/*PRVA TABELA*/}
            <div style={{width:"99.3%", display:"flex", flexDirection:"row", border:"2px solid black"}}>

                <div style={{height:"100%", width:"50px"}}>
                    <div className={classes.celijaGornjeTabele} style={{background:"darkblue", border:"2px solid black"}}>
                    </div>
                    <div className={classes.celijaGornjeTabele} style={{background:"darkblue", border:"2px solid black"}}>
                        <p style={{marginTop:"8px"}}>PAR BR.</p>
                    </div>
                    <div className={classes.celijaGornjeTabele} style={{background:"darkblue", border:"2px solid black"}}>
                        <p style={{marginTop:"8px"}}>PAR BR.</p>
                    </div>
                    <div className={classes.celijaGornjeTabele} style={{background:"darkblue", border:"2px solid black"}}>
                        <p style={{marginTop:"8px"}}>PAR BR.</p>
                    </div>
                    <div className={classes.celijaGornjeTabele} style={{background:"darkblue", border:"2px solid black"}}>
                        <p style={{marginTop:"8px"}}>PAR BR.</p>
                    </div>
                </div>

                <div style={{height:"100%", width:"50px"}}>
                    <div className={classes.celijaGornjeTabele} style={{background:"red", border:"2px solid black"}}>
                    </div>
                    <div className={classes.celijaGornjeTabele} style={{background:"red", border:"2px solid black"}}>
                        <p style={{marginTop:"8px"}}>1</p>
                    </div>
                    <div className={classes.celijaGornjeTabele} style={{background:"red", border:"2px solid black"}}>
                        <p style={{marginTop:"8px"}}>2</p>
                    </div>
                    <div className={classes.celijaGornjeTabele} style={{background:"red", border:"2px solid black"}}>
                        <p style={{marginTop:"8px"}}>3</p>
                    </div>
                    <div className={classes.celijaGornjeTabele} style={{background:"red", border:"2px solid black"}}>
                        <p style={{marginTop:"8px"}}>4</p>
                    </div>
                </div>

                <div style={{height:"100%", width:"95px"}}>
                    <div className={classes.celijaGornjeTabele} style={{background:"orangered", border:"2px solid black"}}>
                        <p style={{marginTop:"8px"}}>DOMACIN</p>
                    </div>
                    <div className={classes.celijaGornjeTabele} style={{background:"orangered", border:"2px solid black"}}>
                        <input className={classes.inputCelija} style={{width:"100%" , fontWeight:"bolder"}}></input>
                    </div>
                    <div className={classes.celijaGornjeTabele} style={{background:"orangered", border:"2px solid black"}}>
                        <input className={classes.inputCelija} style={{width:"100%", fontWeight:"bolder"}}></input>
                    </div>
                    <div className={classes.celijaGornjeTabele} style={{background:"orangered", border:"2px solid black"}}>
                        <input className={classes.inputCelija} style={{width:"100%", fontWeight:"bolder"}}></input>
                    </div>
                    <div className={classes.celijaGornjeTabele} style={{background:"orangered", border:"2px solid black"}}>
                        <input className={classes.inputCelija} style={{width:"100%", fontWeight:"bolder"}}></input>
                    </div>
                </div>

                <div style={{height:"100%", width:"50px"}}>
                    <div className={classes.celijaGornjeTabele} style={{background:"black", border:"2px solid black"}}>
                    </div>
                    <div className={classes.celijaGornjeTabele} style={{background:"darkblue", border:"2px solid black"}}>
                        <p style={{marginTop:"8px"}}>VS</p>
                    </div>
                    <div className={classes.celijaGornjeTabele} style={{background:"darkblue", border:"2px solid black"}}>
                        <p style={{marginTop:"8px"}}>VS</p>
                    </div>
                    <div className={classes.celijaGornjeTabele} style={{background:"darkblue", border:"2px solid black"}}>
                        <p style={{marginTop:"8px"}}>VS</p>
                    </div>
                    <div className={classes.celijaGornjeTabele} style={{background:"darkblue", border:"2px solid black"}}>
                        <p style={{marginTop:"8px"}}>VS</p>
                    </div>
                </div>

                <div style={{height:"100%", width:"95px"}}>
                    <div className={classes.celijaGornjeTabele} style={{background:"orangered", border:"2px solid black"}}>
                        <p style={{marginTop:"8px"}}>GOST</p>
                    </div>
                    <div className={classes.celijaGornjeTabele} style={{background:"orangered", border:"2px solid black"}}>
                        <input className={classes.inputCelija} style={{width:"100%", fontWeight:"bolder"}}></input>
                    </div>
                    <div className={classes.celijaGornjeTabele} style={{background:"orangered", border:"2px solid black"}}>
                        <input className={classes.inputCelija} style={{width:"100%", fontWeight:"bolder"}}></input>
                    </div>
                    <div className={classes.celijaGornjeTabele} style={{background:"orangered", border:"2px solid black"}}>
                        <input className={classes.inputCelija} style={{width:"100%", fontWeight:"bolder"}}></input>
                    </div>
                    <div className={classes.celijaGornjeTabele} style={{background:"orangered", border:"2px solid black"}}>
                        <input className={classes.inputCelija} style={{width:"100%", fontWeight:"bolder"}}></input>
                    </div>
                </div>

                <div style={{height:"100%", width:"95px"}}>
                    <div className={classes.celijaGornjeTabele} style={{background:"RED", border:"2px solid black"}}>
                        <p style={{marginTop:"8px", color:"black"}}>UPIS IGRE</p>
                    </div>
                    <div className={classes.celijaGornjeTabele} style={{background:"orangered", border:"2px solid black"}}>
                        <input className={classes.inputCelija} style={{width:"100%", fontWeight:"bolder"}}></input>
                    </div>
                    <div className={classes.celijaGornjeTabele} style={{background:"orangered", border:"2px solid black"}}>
                        <input className={classes.inputCelija} style={{width:"100%", fontWeight:"bolder"}}></input>
                    </div>
                    <div className={classes.celijaGornjeTabele} style={{background:"orangered", border:"2px solid black"}}>
                        <input className={classes.inputCelija} style={{width:"100%", fontWeight:"bolder"}}></input>
                    </div>
                    <div className={classes.celijaGornjeTabele} style={{background:"orangered", border:"2px solid black"}}>
                        <input className={classes.inputCelija} style={{width:"100%", fontWeight:"bolder"}}></input>
                    </div>
                </div>

                <div style={{height:"100%", width:"95px"}}>
                    <div className={classes.celijaGornjeTabele} style={{background:"red", border:"2px solid black"}}>
                        <p style={{marginTop:"8px", color:"black"}}>DOMACIN</p>
                    </div>
                    <div className={classes.celijaGornjeTabele} style={{background:"orangered", border:"2px solid black"}}>
                        <input className={classes.inputCelija} style={{width:"100%", fontWeight:"bolder"}}></input>
                    </div>
                    <div className={classes.celijaGornjeTabele} style={{background:"orangered", border:"2px solid black"}}>
                        <input className={classes.inputCelija} style={{width:"100%", fontWeight:"bolder"}}></input>
                    </div>
                    <div className={classes.celijaGornjeTabele} style={{background:"orangered", border:"2px solid black"}}>
                        <input className={classes.inputCelija} style={{width:"100%", fontWeight:"bolder"}}></input>
                    </div>
                    <div className={classes.celijaGornjeTabele} style={{background:"orangered", border:"2px solid black"}}>
                        <input className={classes.inputCelija} style={{width:"100%", fontWeight:"bolder"}}></input>
                    </div>
                </div>

                <div style={{height:"100%", width:"173px"}}>
                    <div className={classes.celijaGornjeTabele} style={{background:"RED", border:"2px solid black"}}>
                        <p style={{marginTop:"8px", color:"black"}}>UPIS POGODAKA(DA/NE)</p>
                    </div>
                    <div className={classes.celijaGornjeTabele} style={{background:"orangered", border:"2px solid black"}}>
                        <input className={classes.inputCelija} style={{width:"100%", fontWeight:"bolder"}}></input>
                    </div>
                    <div className={classes.celijaGornjeTabele} style={{background:"orangered", border:"2px solid black"}}>
                        <input className={classes.inputCelija} style={{width:"100%", fontWeight:"bolder"}}></input>
                    </div>
                    <div className={classes.celijaGornjeTabele} style={{background:"orangered", border:"2px solid black"}}>
                        <input className={classes.inputCelija} style={{width:"100%", fontWeight:"bolder"}}></input>
                    </div>
                    <div className={classes.celijaGornjeTabele} style={{background:"orangered", border:"2px solid black"}}>
                        <input className={classes.inputCelija} style={{width:"100%", fontWeight:"bolder"}}></input>
                    </div>
                </div>

                <div style={{height:"100%", width:"95px"}}>
                    <div className={classes.celijaGornjeTabele} style={{background:"RED", border:"2px solid black"}}>
                        <p style={{marginTop:"8px", color:"black"}}>UPIS IGRE</p>
                    </div>
                    <div className={classes.celijaGornjeTabele} style={{background:"orangered", border:"2px solid black"}}>
                        <input className={classes.inputCelija} style={{width:"100%", fontWeight:"bolder"}}></input>
                    </div>
                    <div className={classes.celijaGornjeTabele} style={{background:"orangered", border:"2px solid black"}}>
                        <input className={classes.inputCelija} style={{width:"100%", fontWeight:"bolder"}}></input>
                    </div>
                    <div className={classes.celijaGornjeTabele} style={{background:"orangered", border:"2px solid black"}}>
                        <input className={classes.inputCelija} style={{width:"100%", fontWeight:"bolder"}}></input>
                    </div>
                    <div className={classes.celijaGornjeTabele} style={{background:"orangered", border:"2px solid black"}}>
                        <input className={classes.inputCelija} style={{width:"100%", fontWeight:"bolder"}}></input>
                    </div>
                </div>

                <div style={{height:"100%", width:"95px"}}>
                    <div className={classes.celijaGornjeTabele} style={{background:"RED", border:"2px solid black"}}>
                        <p style={{marginTop:"8px", color:"black"}}>UPIS KVOTE</p>
                    </div>
                    <div className={classes.celijaGornjeTabele} style={{background:"orangered", border:"2px solid black"}}>
                        <input className={classes.inputCelija} style={{width:"100%", fontWeight:"bolder"}}></input>
                    </div>
                    <div className={classes.celijaGornjeTabele} style={{background:"orangered", border:"2px solid black"}}>
                        <input className={classes.inputCelija} style={{width:"100%", fontWeight:"bolder"}}></input>
                    </div>
                    <div className={classes.celijaGornjeTabele} style={{background:"orangered", border:"2px solid black"}}>
                        <input className={classes.inputCelija} style={{width:"100%", fontWeight:"bolder"}}></input>
                    </div>
                    <div className={classes.celijaGornjeTabele} style={{background:"orangered", border:"2px solid black"}}>
                        <input className={classes.inputCelija} style={{width:"100%", fontWeight:"bolder"}}></input>
                    </div>
                </div>

                <div style={{height:"100%", width:"173px"}}>
                    <div className={classes.celijaGornjeTabele} style={{background:"RED", border:"2px solid black"}}>
                        <p style={{marginTop:"8px", color:"black"}}>UPIS POGODAKA(DA/NE)</p>
                    </div>
                    <div className={classes.celijaGornjeTabele} style={{background:"orangered", border:"2px solid black"}}>
                        <input className={classes.inputCelija} style={{width:"100%", fontWeight:"bolder"}}></input>
                    </div>
                    <div className={classes.celijaGornjeTabele} style={{background:"orangered", border:"2px solid black"}}>
                        <input className={classes.inputCelija} style={{width:"100%", fontWeight:"bolder"}}></input>
                    </div>
                    <div className={classes.celijaGornjeTabele} style={{background:"orangered", border:"2px solid black"}}>
                        <input className={classes.inputCelija} style={{width:"100%", fontWeight:"bolder"}}></input>
                    </div>
                    <div className={classes.celijaGornjeTabele} style={{background:"orangered", border:"2px solid black"}}>
                        <input className={classes.inputCelija} style={{width:"100%", fontWeight:"bolder"}}></input>
                    </div>
                </div>

                <div style={{height:"100%", width:"95px"}}>
                    <div className={classes.celijaGornjeTabele} style={{background:"RED", border:"2px solid black"}}>
                        <p style={{marginTop:"8px", color:"black"}}>UPIS IGRE</p>
                    </div>
                    <div className={classes.celijaGornjeTabele} style={{background:"orangered", border:"2px solid black"}}>
                        <input className={classes.inputCelija} style={{width:"100%", fontWeight:"bolder"}}></input>
                    </div>
                    <div className={classes.celijaGornjeTabele} style={{background:"orangered", border:"2px solid black"}}>
                        <input className={classes.inputCelija} style={{width:"100%", fontWeight:"bolder"}}></input>
                    </div>
                    <div className={classes.celijaGornjeTabele} style={{background:"orangered", border:"2px solid black"}}>
                        <input className={classes.inputCelija} style={{width:"100%", fontWeight:"bolder"}}></input>
                    </div>
                    <div className={classes.celijaGornjeTabele} style={{background:"orangered", border:"2px solid black"}}>
                        <input className={classes.inputCelija} style={{width:"100%", fontWeight:"bolder"}}></input>
                    </div>
                </div>

                <div style={{height:"100%", width:"95px"}}>
                    <div className={classes.celijaGornjeTabele} style={{background:"RED", border:"2px solid black"}}>
                        <p style={{marginTop:"8px", color:"black"}}>UPIS KVOTE</p>
                    </div>
                    <div className={classes.celijaGornjeTabele} style={{background:"orangered", border:"2px solid black"}}>
                        <input className={classes.inputCelija} style={{width:"100%", fontWeight:"bolder"}}></input>
                    </div>
                    <div className={classes.celijaGornjeTabele} style={{background:"orangered", border:"2px solid black"}}>
                        <input className={classes.inputCelija} style={{width:"100%", fontWeight:"bolder"}}></input>
                    </div>
                    <div className={classes.celijaGornjeTabele} style={{background:"orangered", border:"2px solid black"}}>
                        <input className={classes.inputCelija} style={{width:"100%", fontWeight:"bolder"}}></input>
                    </div>
                    <div className={classes.celijaGornjeTabele} style={{background:"orangered", border:"2px solid black"}}>
                        <input className={classes.inputCelija} style={{width:"100%", fontWeight:"bolder"}}></input>
                    </div>
                </div>

                <div style={{height:"100%", width:"174px"}}>
                    <div className={classes.celijaGornjeTabele} style={{background:"RED", border:"2px solid black"}}>
                        <p style={{marginTop:"8px", color:"black"}}>UPIS POGODAKA(DA/NE)</p>
                    </div>
                    <div className={classes.celijaGornjeTabele} style={{background:"orangered", border:"2px solid black"}}>
                        <input className={classes.inputCelija} style={{width:"100%", fontWeight:"bolder"}}></input>
                    </div>
                    <div className={classes.celijaGornjeTabele} style={{background:"orangered", border:"2px solid black"}}>
                        <input className={classes.inputCelija} style={{width:"100%", fontWeight:"bolder"}}></input>
                    </div>
                    <div className={classes.celijaGornjeTabele} style={{background:"orangered", border:"2px solid black"}}>
                        <input className={classes.inputCelija} style={{width:"100%", fontWeight:"bolder"}}></input>
                    </div>
                    <div className={classes.celijaGornjeTabele} style={{background:"orangered", border:"2px solid black"}}>
                        <input className={classes.inputCelija} style={{width:"100%", fontWeight:"bolder"}}></input>
                    </div>
                </div>
            </div>

            {/*DRUGA TABELA PRVA PODTABELA*/}
            <div style={{width:"99.3%", marginTop:"10px", display:"flex", flexDirection:"row", border:"2px solid black", background:"darkblue"}}>

                <div style={{height:"100%", width:"332px"}}>

                    <div className={classes.celijaGornjeTabele} style={{height:"64px",background:"orangered", border:"2px solid black"}}>
                        <p style={{fontSize:"25px", marginTop:"17px"}}>SKOR DOBITKA</p>
                    </div>

                    <div className={classes.celijaGornjeTabele} style={{display:"flex", flexDirection:"row", border:"2px solid black"}}>
                        <div style={{width:"50%", borderRight:"2px solid black", background:"orangered"}}>
                            <p style={{marginTop:"8px"}}>TIKET BR 1</p>
                        </div>
                        <div style={{width:"50%", borderRight:"2px solid black", background:"green"}}>
                            <p style={{marginTop:"8px", color:"black"}}>FALSE</p>
                        </div>
                    </div>

                    <div className={classes.celijaGornjeTabele} style={{display:"flex", flexDirection:"row", border:"2px solid black"}}>
                        <div style={{width:"50%", borderRight:"2px solid black", background:"orangered"}}>
                            <p style={{marginTop:"8px"}}>TIKET BR 2</p>
                        </div>
                        <div style={{width:"50%", borderRight:"2px solid black", background:"green"}}>
                            <p style={{marginTop:"8px", color:"black"}}>FALSE</p>
                        </div>
                    </div>

                    <div className={classes.celijaGornjeTabele} style={{display:"flex", flexDirection:"row", border:"2px solid black"}}>
                        <div style={{width:"50%", borderRight:"2px solid black", background:"orangered"}}>
                            <p style={{marginTop:"8px"}}>TIKET BR 3</p>
                        </div>
                        <div style={{width:"50%", borderRight:"2px solid black", background:"green"}}>
                            <p style={{marginTop:"8px", color:"black"}}>FALSE</p>
                        </div>
                    </div>

                    <div className={classes.celijaGornjeTabele} style={{display:"flex", flexDirection:"row", border:"2px solid black"}}>
                        <div style={{width:"50%", borderRight:"2px solid black", background:"orangered"}}>
                            <p style={{marginTop:"8px"}}>TIKET BR 4</p>
                        </div>
                        <div style={{width:"50%", borderRight:"2px solid black", background:"green"}}>
                            <p style={{marginTop:"8px", color:"black"}}>FALSE</p>
                        </div>
                    </div>

                    <div className={classes.celijaGornjeTabele} style={{display:"flex", flexDirection:"row", border:"2px solid black"}}>
                        <div style={{width:"50%", borderRight:"2px solid black", background:"orangered"}}>
                            <p style={{marginTop:"8px"}}>TIKET BR 5</p>
                        </div>
                        <div style={{width:"50%", borderRight:"2px solid black", background:"green"}}>
                            <p style={{marginTop:"8px", color:"black"}}>FALSE</p>
                        </div>
                    </div>

                    <div className={classes.celijaGornjeTabele} style={{display:"flex", flexDirection:"row", border:"2px solid black"}}>
                        <div style={{width:"50%", borderRight:"2px solid black", background:"orangered"}}>
                            <p style={{marginTop:"8px"}}>TIKET BR 6</p>
                        </div>
                        <div style={{width:"50%", borderRight:"2px solid black", background:"green"}}>
                            <p style={{marginTop:"8px", color:"black"}}>FALSE</p>
                        </div>
                    </div>

                    <div className={classes.celijaGornjeTabele} style={{display:"flex", flexDirection:"row", border:"2px solid black"}}>
                        <div style={{width:"50%", borderRight:"2px solid black", background:"orangered"}}>
                            <p style={{marginTop:"8px"}}>TIKET BR 7</p>
                        </div>
                        <div style={{width:"50%", borderRight:"2px solid black", background:"green"}}>
                            <p style={{marginTop:"8px", color:"black"}}>FALSE</p>
                        </div>
                    </div>

                    <div className={classes.celijaGornjeTabele} style={{display:"flex", flexDirection:"row", border:"2px solid black"}}>
                        <div style={{width:"50%", borderRight:"2px solid black", background:"orangered"}}>
                            <p style={{marginTop:"8px"}}>TIKET BR 8</p>
                        </div>
                        <div style={{width:"50%", borderRight:"2px solid black", background:"green"}}>
                            <p style={{marginTop:"8px", color:"black"}}>FALSE</p>
                        </div>
                    </div>

                    <div className={classes.celijaGornjeTabele} style={{display:"flex", flexDirection:"row", border:"2px solid black"}}>
                        <div style={{width:"50%", borderRight:"2px solid black", background:"orangered"}}>
                            <p style={{marginTop:"8px"}}>TIKET BR 9</p>
                        </div>
                        <div style={{width:"50%", borderRight:"2px solid black", background:"green"}}>
                            <p style={{marginTop:"8px", color:"black"}}>FALSE</p>
                        </div>
                    </div>

                    <div className={classes.celijaGornjeTabele} style={{display:"flex", flexDirection:"row", border:"2px solid black", background:"darkblue"}}>

                    </div>

                    <div className={classes.celijaGornjeTabele} style={{height:"191px", display:"flex", flexDirection:"row", border:"2px solid black"}}>
                        <div style={{width:"50%", borderRight:"2px solid black", background:"orangered"}}>
                            <p style={{marginTop:"90px", fontSize:"17px"}}>UKUPAN DOBITAK ></p>
                        </div>
                        <div style={{width:"50%", borderRight:"2px solid black", background:"rebeccapurple"}}>
                            <p style={{marginTop:"90px", fontSize:"17px"}}>0</p>
                        </div>
                    </div>

                </div>


                <div style={{display:"flex", flexDirection:"row"}}>
                    <div style={{height:"100%", width:"364px"}}>

                        <div className={classes.celijaGornjeTabele} style={{display:"flex", flexDirection:"row", border:"2px solid black", borderBottom:"none"}}>
                            <div className={classes.celijaGornjeTabele} style={{width:"51px", borderRight:"2px solid black", background:"darkblue"}}>
                                <p style={{marginTop:"9px"}}>ULOG ></p>
                            </div>
                            <div className={classes.celijaGornjeTabele} style={{width:"101px", borderRight:"2px solid black", background:"red"}}>
                                <p style={{marginTop:"8px", fontSize:"17px", color:"black"}}>0</p>
                            </div>
                            <div className={classes.celijaGornjeTabele} style={{width:"105px", borderRight:"2px solid black", background:"darkblue"}}>
                                <p style={{marginTop:"8px", fontSize:"17px"}}>SISTEM</p>
                            </div>
                            <div className={classes.celijaGornjeTabele} style={{width:"50px", borderRight:"2px solid darkblue", background:"darkblue"}}>
                                <p style={{marginTop:"9px"}}>3/4, 3/4</p>
                            </div>
                            <div className={classes.celijaGornjeTabele} style={{width:"50px", background:"darkblue"}}>
                                <p style={{marginTop:"8px", fontSize:"17px"}}>2</p>
                            </div>
                        </div>

                        <div style={{display:"flex", flexDirection:"row", border:"2px solid black"}}>

                            <div style={{display:"flex", flexDirection:"column"}}>

                                <div className={classes.celijaGornjeTabele} style={{width:"51px", borderTop:"2px solid black", borderBottom:"2px solid black", borderRight:"2px solid black", background:"darkblue"}}>
                                    <p style={{marginTop:"8px"}}>PAR BR.</p>
                                </div>
                                <div className={classes.celijaGornjeTabele} style={{width:"51px", borderTop:"2px solid black", borderBottom:"2px solid black", borderRight:"2px solid black", background:"darkblue"}}>
                                    <p style={{marginTop:"8px"}}>PAR BR.</p>
                                </div>
                                <div className={classes.celijaGornjeTabele} style={{width:"51px", borderTop:"2px solid black", borderBottom:"2px solid black", borderRight:"2px solid black", background:"darkblue"}}>
                                    <p style={{marginTop:"8px"}}>PAR BR.</p>
                                </div>
                                <div className={classes.celijaGornjeTabele} style={{width:"51px", borderTop:"2px solid black", borderBottom:"2px solid black", borderRight:"2px solid black", background:"darkblue"}}>
                                    <p style={{marginTop:"8px"}}>PAR BR.</p>
                                </div>

                            </div>

                            <div style={{display:"flex", flexDirection:"column"}}>

                                <div className={classes.celijaGornjeTabele} style={{width:"51px", borderTop:"2px solid black", borderBottom:"2px solid black", borderRight:"2px solid black", background:"#305496"}}>
                                    <p style={{marginTop:"8px"}}>1</p>
                                </div>
                                <div className={classes.celijaGornjeTabele} style={{width:"51px", borderTop:"2px solid black", borderBottom:"2px solid black", borderRight:"2px solid black", background:"#305496"}}>
                                    <p style={{marginTop:"8px"}}>2</p>
                                </div>
                                <div className={classes.celijaGornjeTabele} style={{width:"51px", borderTop:"2px solid black", borderBottom:"2px solid black", borderRight:"2px solid black", background:"#305496"}}>
                                    <p style={{marginTop:"8px"}}>3</p>
                                </div>
                                <div className={classes.celijaGornjeTabele} style={{width:"51px", borderTop:"2px solid black", borderBottom:"2px solid black", borderRight:"2px solid black", background:"#305496"}}>
                                    <p style={{marginTop:"8px"}}>4</p>
                                </div>

                            </div>

                            <div style={{display:"flex", flexDirection:"column"}}>

                                <div className={classes.celijaGornjeTabele} style={{width:"101px", borderTop:"2px solid black", borderBottom:"2px solid black", borderRight:"2px solid black", background:"#305496"}}>
                                    <p style={{marginTop:"8px"}}>0</p>
                                </div>
                                <div className={classes.celijaGornjeTabele} style={{width:"101px", borderTop:"2px solid black", borderBottom:"2px solid black", borderRight:"2px solid black", background:"#305496"}}>
                                    <p style={{marginTop:"8px"}}>0</p>
                                </div>
                                <div className={classes.celijaGornjeTabele} style={{width:"101px", borderTop:"2px solid black", borderBottom:"2px solid black", borderRight:"2px solid black", background:"#305496"}}>
                                    <p style={{marginTop:"8px"}}>0</p>
                                </div>
                                <div className={classes.celijaGornjeTabele} style={{width:"101px", borderTop:"2px solid black", borderBottom:"2px solid black", borderRight:"2px solid black", background:"#305496"}}>
                                    <p style={{marginTop:"8px"}}>0</p>
                                </div>

                            </div>

                            <div style={{display:"flex", flexDirection:"column"}}>

                                <div className={classes.celijaGornjeTabele} style={{width:"51px", borderTop:"2px solid black", borderBottom:"2px solid black", borderRight:"2px solid black", background:"#305496"}}>
                                    <p style={{marginTop:"8px"}}>0,00</p>
                                </div>
                                <div className={classes.celijaGornjeTabele} style={{width:"51px", borderTop:"2px solid black", borderBottom:"2px solid black", borderRight:"2px solid black", background:"#305496"}}>
                                    <p style={{marginTop:"8px"}}>0,00</p>
                                </div>
                                <div className={classes.celijaGornjeTabele} style={{width:"51px", borderTop:"2px solid black", borderBottom:"2px solid black", borderRight:"2px solid black", background:"#305496"}}>
                                    <p style={{marginTop:"8px"}}>0,00</p>
                                </div>
                                <div className={classes.celijaGornjeTabele} style={{width:"51px", borderTop:"2px solid black", borderBottom:"2px solid black", borderRight:"2px solid black", background:"#305496"}}>
                                    <p style={{marginTop:"8px"}}>0,00</p>
                                </div>

                            </div>

                            <div style={{display:"flex", flexDirection:"column"}}>

                                <div className={classes.celijaGornjeTabele} style={{width:"103px", borderTop:"2px solid black", borderBottom:"2px solid black", borderRight:"2px solid black", borderLeft:"none", background:"red"}}>
                                    <p style={{marginTop:"8px", color:"black"}}>0</p>
                                </div>
                                <div className={classes.celijaGornjeTabele} style={{width:"103px", borderTop:"2px solid black", borderBottom:"2px solid black", borderRight:"2px solid black", borderLeft:"none", background:"red"}}>
                                    <p style={{marginTop:"8px", color:"black"}}>0</p>
                                </div>
                                <div className={classes.celijaGornjeTabele} style={{width:"103px", borderTop:"2px solid black", borderBottom:"2px solid black", borderRight:"2px solid black", borderLeft:"none", background:"red"}}>
                                    <p style={{marginTop:"8px", color:"black"}}>0</p>
                                </div>
                                <div className={classes.celijaGornjeTabele} style={{width:"103px", borderTop:"2px solid black", borderBottom:"2px solid black", borderRight:"2px solid black", borderLeft:"none", background:"red"}}>
                                    <p style={{marginTop:"8px", color:"black"}}>0</p>
                                </div>

                            </div>
                        </div>

                        <div className={classes.celijaGornjeTabele} style={{display:"flex", flexDirection:"row", border:"2px solid black", borderTop:"none"}}>
                            <div className={classes.celijaGornjeTabele} style={{width:"51px", borderRight:"2px solid black", background:"rebeccapurple"}}>
                                <p style={{marginTop:"8px"}}>1 KOMB</p>
                            </div>
                            <div className={classes.celijaGornjeTabele} style={{width:"101px", borderRight:"2px solid black", background:"rebeccapurple"}}>
                                <p style={{marginTop:"8px"}}>0,00</p>
                            </div>
                            <div className={classes.celijaGornjeTabele} style={{width:"52px", borderRight:"2px solid darkblue", background:"darkblue"}}>
                                <p style={{marginTop:"8px"}}>KVOTA></p>
                            </div>
                            <div className={classes.celijaGornjeTabele} style={{width:"52px", borderRight:"2px solid black", background:"darkblue"}}>
                                <p style={{marginTop:"8px"}}>0,00</p>
                            </div>
                            <div className={classes.celijaGornjeTabele} style={{width:"101px", background:"rebeccapurple"}}>
                                <p style={{marginTop:"8px"}}>FALSE</p>
                            </div>
                        </div>


                        <div className={classes.celijaGornjeTabele} style={{display:"flex", flexDirection:"row", border:"2px solid black", borderBottom:"none"}}>
                            <div className={classes.celijaGornjeTabele} style={{width:"51px", borderRight:"2px solid black", background:"darkblue"}}>
                                <p style={{marginTop:"9px"}}>ULOG ></p>
                            </div>
                            <div className={classes.celijaGornjeTabele} style={{width:"101px", borderRight:"2px solid black", background:"red"}}>
                                <p style={{marginTop:"8px", fontSize:"17px", color:"black"}}>0</p>
                            </div>
                            <div className={classes.celijaGornjeTabele} style={{width:"105px", borderRight:"2px solid black", background:"darkblue"}}>
                                <p style={{marginTop:"8px", fontSize:"17px"}}>SISTEM</p>
                            </div>
                            <div className={classes.celijaGornjeTabele} style={{width:"50px", borderRight:"2px solid darkblue", background:"darkblue"}}>
                                <p style={{marginTop:"9px"}}>3/4, 3/4</p>
                            </div>
                            <div className={classes.celijaGornjeTabele} style={{width:"50px", background:"darkblue"}}>
                                <p style={{marginTop:"8px", fontSize:"17px"}}>2</p>
                            </div>
                        </div>

                        <div style={{display:"flex", flexDirection:"row", border:"2px solid black"}}>

                            <div style={{display:"flex", flexDirection:"column"}}>

                                <div className={classes.celijaGornjeTabele} style={{width:"51px", borderTop:"2px solid black", borderBottom:"2px solid black", borderRight:"2px solid black", background:"darkblue"}}>
                                    <p style={{marginTop:"8px"}}>PAR BR.</p>
                                </div>
                                <div className={classes.celijaGornjeTabele} style={{width:"51px", borderTop:"2px solid black", borderBottom:"2px solid black", borderRight:"2px solid black", background:"darkblue"}}>
                                    <p style={{marginTop:"8px"}}>PAR BR.</p>
                                </div>
                                <div className={classes.celijaGornjeTabele} style={{width:"51px", borderTop:"2px solid black", borderBottom:"2px solid black", borderRight:"2px solid black", background:"darkblue"}}>
                                    <p style={{marginTop:"8px"}}>PAR BR.</p>
                                </div>
                                <div className={classes.celijaGornjeTabele} style={{width:"51px", borderTop:"2px solid black", borderBottom:"2px solid black", borderRight:"2px solid black", background:"darkblue"}}>
                                    <p style={{marginTop:"8px"}}>PAR BR.</p>
                                </div>

                            </div>

                            <div style={{display:"flex", flexDirection:"column"}}>

                                <div className={classes.celijaGornjeTabele} style={{width:"51px", borderTop:"2px solid black", borderBottom:"2px solid black", borderRight:"2px solid black", background:"#305496"}}>
                                    <p style={{marginTop:"8px"}}>1</p>
                                </div>
                                <div className={classes.celijaGornjeTabele} style={{width:"51px", borderTop:"2px solid black", borderBottom:"2px solid black", borderRight:"2px solid black", background:"#305496"}}>
                                    <p style={{marginTop:"8px"}}>2</p>
                                </div>
                                <div className={classes.celijaGornjeTabele} style={{width:"51px", borderTop:"2px solid black", borderBottom:"2px solid black", borderRight:"2px solid black", background:"#305496"}}>
                                    <p style={{marginTop:"8px"}}>3</p>
                                </div>
                                <div className={classes.celijaGornjeTabele} style={{width:"51px", borderTop:"2px solid black", borderBottom:"2px solid black", borderRight:"2px solid black", background:"#305496"}}>
                                    <p style={{marginTop:"8px"}}>4</p>
                                </div>

                            </div>

                            <div style={{display:"flex", flexDirection:"column"}}>

                                <div className={classes.celijaGornjeTabele} style={{width:"101px", borderTop:"2px solid black", borderBottom:"2px solid black", borderRight:"2px solid black", background:"#305496"}}>
                                    <p style={{marginTop:"8px"}}>0</p>
                                </div>
                                <div className={classes.celijaGornjeTabele} style={{width:"101px", borderTop:"2px solid black", borderBottom:"2px solid black", borderRight:"2px solid black", background:"#305496"}}>
                                    <p style={{marginTop:"8px"}}>0</p>
                                </div>
                                <div className={classes.celijaGornjeTabele} style={{width:"101px", borderTop:"2px solid black", borderBottom:"2px solid black", borderRight:"2px solid black", background:"#305496"}}>
                                    <p style={{marginTop:"8px"}}>0</p>
                                </div>
                                <div className={classes.celijaGornjeTabele} style={{width:"101px", borderTop:"2px solid black", borderBottom:"2px solid black", borderRight:"2px solid black", background:"#305496"}}>
                                    <p style={{marginTop:"8px"}}>0</p>
                                </div>

                            </div>

                            <div style={{display:"flex", flexDirection:"column"}}>

                                <div className={classes.celijaGornjeTabele} style={{width:"51px", borderTop:"2px solid black", borderBottom:"2px solid black", borderRight:"2px solid black", background:"#305496"}}>
                                    <p style={{marginTop:"8px"}}>0,00</p>
                                </div>
                                <div className={classes.celijaGornjeTabele} style={{width:"51px", borderTop:"2px solid black", borderBottom:"2px solid black", borderRight:"2px solid black", background:"#305496"}}>
                                    <p style={{marginTop:"8px"}}>0,00</p>
                                </div>
                                <div className={classes.celijaGornjeTabele} style={{width:"51px", borderTop:"2px solid black", borderBottom:"2px solid black", borderRight:"2px solid black", background:"#305496"}}>
                                    <p style={{marginTop:"8px"}}>0,00</p>
                                </div>
                                <div className={classes.celijaGornjeTabele} style={{width:"51px", borderTop:"2px solid black", borderBottom:"2px solid black", borderRight:"2px solid black", background:"#305496"}}>
                                    <p style={{marginTop:"8px"}}>0,00</p>
                                </div>

                            </div>

                            <div style={{display:"flex", flexDirection:"column"}}>

                                <div className={classes.celijaGornjeTabele} style={{width:"103px", borderTop:"2px solid black", borderBottom:"2px solid black", borderRight:"2px solid black", borderLeft:"none", background:"red"}}>
                                    <p style={{marginTop:"8px", color:"black"}}>0</p>
                                </div>
                                <div className={classes.celijaGornjeTabele} style={{width:"103px", borderTop:"2px solid black", borderBottom:"2px solid black", borderRight:"2px solid black", borderLeft:"none", background:"red"}}>
                                    <p style={{marginTop:"8px", color:"black"}}>0</p>
                                </div>
                                <div className={classes.celijaGornjeTabele} style={{width:"103px", borderTop:"2px solid black", borderBottom:"2px solid black", borderRight:"2px solid black", borderLeft:"none", background:"red"}}>
                                    <p style={{marginTop:"8px", color:"black"}}>0</p>
                                </div>
                                <div className={classes.celijaGornjeTabele} style={{width:"103px", borderTop:"2px solid black", borderBottom:"2px solid black", borderRight:"2px solid black", borderLeft:"none", background:"red"}}>
                                    <p style={{marginTop:"8px", color:"black"}}>0</p>
                                </div>

                            </div>
                        </div>

                        <div className={classes.celijaGornjeTabele} style={{display:"flex", flexDirection:"row", border:"2px solid black", borderTop:"none"}}>
                            <div className={classes.celijaGornjeTabele} style={{width:"51px", borderRight:"2px solid black", background:"rebeccapurple"}}>
                                <p style={{marginTop:"8px"}}>1 KOMB</p>
                            </div>
                            <div className={classes.celijaGornjeTabele} style={{width:"101px", borderRight:"2px solid black", background:"rebeccapurple"}}>
                                <p style={{marginTop:"8px"}}>0,00</p>
                            </div>
                            <div className={classes.celijaGornjeTabele} style={{width:"52px", borderRight:"2px solid darkblue", background:"darkblue"}}>
                                <p style={{marginTop:"8px"}}>KVOTA></p>
                            </div>
                            <div className={classes.celijaGornjeTabele} style={{width:"52px", borderRight:"2px solid black", background:"darkblue"}}>
                                <p style={{marginTop:"8px"}}>0,00</p>
                            </div>
                            <div className={classes.celijaGornjeTabele} style={{width:"101px", background:"rebeccapurple"}}>
                                <p style={{marginTop:"8px"}}>FALSE</p>
                            </div>
                        </div>

                        <div className={classes.celijaGornjeTabele} style={{display:"flex", flexDirection:"row", border:"2px solid black", borderBottom:"none"}}>
                            <div className={classes.celijaGornjeTabele} style={{width:"51px", borderRight:"2px solid black", background:"darkblue"}}>
                                <p style={{marginTop:"9px"}}>ULOG ></p>
                            </div>
                            <div className={classes.celijaGornjeTabele} style={{width:"101px", borderRight:"2px solid black", background:"red"}}>
                                <p style={{marginTop:"8px", fontSize:"17px", color:"black"}}>0</p>
                            </div>
                            <div className={classes.celijaGornjeTabele} style={{width:"105px", borderRight:"2px solid black", background:"darkblue"}}>
                                <p style={{marginTop:"8px", fontSize:"17px"}}>SISTEM</p>
                            </div>
                            <div className={classes.celijaGornjeTabele} style={{width:"50px", borderRight:"2px solid darkblue", background:"darkblue"}}>
                                <p style={{marginTop:"9px"}}>3/4, 3/4</p>
                            </div>
                            <div className={classes.celijaGornjeTabele} style={{width:"50px", background:"darkblue"}}>
                                <p style={{marginTop:"8px", fontSize:"17px"}}>2</p>
                            </div>
                        </div>

                        <div style={{display:"flex", flexDirection:"row", border:"2px solid black"}}>

                            <div style={{display:"flex", flexDirection:"column"}}>

                                <div className={classes.celijaGornjeTabele} style={{width:"51px", borderTop:"2px solid black", borderBottom:"2px solid black", borderRight:"2px solid black", background:"darkblue"}}>
                                    <p style={{marginTop:"8px"}}>PAR BR.</p>
                                </div>
                                <div className={classes.celijaGornjeTabele} style={{width:"51px", borderTop:"2px solid black", borderBottom:"2px solid black", borderRight:"2px solid black", background:"darkblue"}}>
                                    <p style={{marginTop:"8px"}}>PAR BR.</p>
                                </div>
                                <div className={classes.celijaGornjeTabele} style={{width:"51px", borderTop:"2px solid black", borderBottom:"2px solid black", borderRight:"2px solid black", background:"darkblue"}}>
                                    <p style={{marginTop:"8px"}}>PAR BR.</p>
                                </div>
                                <div className={classes.celijaGornjeTabele} style={{width:"51px", borderTop:"2px solid black", borderBottom:"2px solid black", borderRight:"2px solid black", background:"darkblue"}}>
                                    <p style={{marginTop:"8px"}}>PAR BR.</p>
                                </div>

                            </div>

                            <div style={{display:"flex", flexDirection:"column"}}>

                                <div className={classes.celijaGornjeTabele} style={{width:"51px", borderTop:"2px solid black", borderBottom:"2px solid black", borderRight:"2px solid black", background:"#305496"}}>
                                    <p style={{marginTop:"8px"}}>1</p>
                                </div>
                                <div className={classes.celijaGornjeTabele} style={{width:"51px", borderTop:"2px solid black", borderBottom:"2px solid black", borderRight:"2px solid black", background:"#305496"}}>
                                    <p style={{marginTop:"8px"}}>2</p>
                                </div>
                                <div className={classes.celijaGornjeTabele} style={{width:"51px", borderTop:"2px solid black", borderBottom:"2px solid black", borderRight:"2px solid black", background:"#305496"}}>
                                    <p style={{marginTop:"8px"}}>3</p>
                                </div>
                                <div className={classes.celijaGornjeTabele} style={{width:"51px", borderTop:"2px solid black", borderBottom:"2px solid black", borderRight:"2px solid black", background:"#305496"}}>
                                    <p style={{marginTop:"8px"}}>4</p>
                                </div>

                            </div>

                            <div style={{display:"flex", flexDirection:"column"}}>

                                <div className={classes.celijaGornjeTabele} style={{width:"101px", borderTop:"2px solid black", borderBottom:"2px solid black", borderRight:"2px solid black", background:"#305496"}}>
                                    <p style={{marginTop:"8px"}}>0</p>
                                </div>
                                <div className={classes.celijaGornjeTabele} style={{width:"101px", borderTop:"2px solid black", borderBottom:"2px solid black", borderRight:"2px solid black", background:"#305496"}}>
                                    <p style={{marginTop:"8px"}}>0</p>
                                </div>
                                <div className={classes.celijaGornjeTabele} style={{width:"101px", borderTop:"2px solid black", borderBottom:"2px solid black", borderRight:"2px solid black", background:"#305496"}}>
                                    <p style={{marginTop:"8px"}}>0</p>
                                </div>
                                <div className={classes.celijaGornjeTabele} style={{width:"101px", borderTop:"2px solid black", borderBottom:"2px solid black", borderRight:"2px solid black", background:"#305496"}}>
                                    <p style={{marginTop:"8px"}}>0</p>
                                </div>

                            </div>

                            <div style={{display:"flex", flexDirection:"column"}}>

                                <div className={classes.celijaGornjeTabele} style={{width:"51px", borderTop:"2px solid black", borderBottom:"2px solid black", borderRight:"2px solid black", background:"#305496"}}>
                                    <p style={{marginTop:"8px"}}>0,00</p>
                                </div>
                                <div className={classes.celijaGornjeTabele} style={{width:"51px", borderTop:"2px solid black", borderBottom:"2px solid black", borderRight:"2px solid black", background:"#305496"}}>
                                    <p style={{marginTop:"8px"}}>0,00</p>
                                </div>
                                <div className={classes.celijaGornjeTabele} style={{width:"51px", borderTop:"2px solid black", borderBottom:"2px solid black", borderRight:"2px solid black", background:"#305496"}}>
                                    <p style={{marginTop:"8px"}}>0,00</p>
                                </div>
                                <div className={classes.celijaGornjeTabele} style={{width:"51px", borderTop:"2px solid black", borderBottom:"2px solid black", borderRight:"2px solid black", background:"#305496"}}>
                                    <p style={{marginTop:"8px"}}>0,00</p>
                                </div>

                            </div>

                            <div style={{display:"flex", flexDirection:"column"}}>

                                <div className={classes.celijaGornjeTabele} style={{width:"103px", borderTop:"2px solid black", borderBottom:"2px solid black", borderRight:"2px solid black", borderLeft:"none", background:"red"}}>
                                    <p style={{marginTop:"8px", color:"black"}}>0</p>
                                </div>
                                <div className={classes.celijaGornjeTabele} style={{width:"103px", borderTop:"2px solid black", borderBottom:"2px solid black", borderRight:"2px solid black", borderLeft:"none", background:"red"}}>
                                    <p style={{marginTop:"8px", color:"black"}}>0</p>
                                </div>
                                <div className={classes.celijaGornjeTabele} style={{width:"103px", borderTop:"2px solid black", borderBottom:"2px solid black", borderRight:"2px solid black", borderLeft:"none", background:"red"}}>
                                    <p style={{marginTop:"8px", color:"black"}}>0</p>
                                </div>
                                <div className={classes.celijaGornjeTabele} style={{width:"103px", borderTop:"2px solid black", borderBottom:"2px solid black", borderRight:"2px solid black", borderLeft:"none", background:"red"}}>
                                    <p style={{marginTop:"8px", color:"black"}}>0</p>
                                </div>

                            </div>
                        </div>

                        <div className={classes.celijaGornjeTabele} style={{display:"flex", flexDirection:"row", border:"2px solid black", borderTop:"none"}}>
                            <div className={classes.celijaGornjeTabele} style={{width:"51px", borderRight:"2px solid black", background:"rebeccapurple"}}>
                                <p style={{marginTop:"8px"}}>1 KOMB</p>
                            </div>
                            <div className={classes.celijaGornjeTabele} style={{width:"101px", borderRight:"2px solid black", background:"rebeccapurple"}}>
                                <p style={{marginTop:"8px"}}>0,00</p>
                            </div>
                            <div className={classes.celijaGornjeTabele} style={{width:"52px", borderRight:"2px solid darkblue", background:"darkblue"}}>
                                <p style={{marginTop:"8px"}}>KVOTA></p>
                            </div>
                            <div className={classes.celijaGornjeTabele} style={{width:"52px", borderRight:"2px solid black", background:"darkblue"}}>
                                <p style={{marginTop:"8px"}}>0,00</p>
                            </div>
                            <div className={classes.celijaGornjeTabele} style={{width:"101px", background:"rebeccapurple"}}>
                                <p style={{marginTop:"8px"}}>FALSE</p>
                            </div>
                        </div>


                    </div>


                    <div style={{height:"100%", width:"364px"}}>

                        <div className={classes.celijaGornjeTabele} style={{display:"flex", flexDirection:"row", border:"2px solid black", borderBottom:"none"}}>
                            <div className={classes.celijaGornjeTabele} style={{width:"51px", borderRight:"2px solid black", background:"darkblue"}}>
                                <p style={{marginTop:"9px"}}>ULOG ></p>
                            </div>
                            <div className={classes.celijaGornjeTabele} style={{width:"101px", borderRight:"2px solid black", background:"red"}}>
                                <p style={{marginTop:"8px", fontSize:"17px", color:"black"}}>0</p>
                            </div>
                            <div className={classes.celijaGornjeTabele} style={{width:"105px", borderRight:"2px solid black", background:"darkblue"}}>
                                <p style={{marginTop:"8px", fontSize:"17px"}}>SISTEM</p>
                            </div>
                            <div className={classes.celijaGornjeTabele} style={{width:"50px", borderRight:"2px solid darkblue", background:"darkblue"}}>
                                <p style={{marginTop:"9px"}}>3/4, 3/4</p>
                            </div>
                            <div className={classes.celijaGornjeTabele} style={{width:"50px", background:"darkblue"}}>
                                <p style={{marginTop:"8px", fontSize:"17px"}}>2</p>
                            </div>
                        </div>

                        <div style={{display:"flex", flexDirection:"row", border:"2px solid black"}}>

                            <div style={{display:"flex", flexDirection:"column"}}>

                                <div className={classes.celijaGornjeTabele} style={{width:"51px", borderTop:"2px solid black", borderBottom:"2px solid black", borderRight:"2px solid black", background:"darkblue"}}>
                                    <p style={{marginTop:"8px"}}>PAR BR.</p>
                                </div>
                                <div className={classes.celijaGornjeTabele} style={{width:"51px", borderTop:"2px solid black", borderBottom:"2px solid black", borderRight:"2px solid black", background:"darkblue"}}>
                                    <p style={{marginTop:"8px"}}>PAR BR.</p>
                                </div>
                                <div className={classes.celijaGornjeTabele} style={{width:"51px", borderTop:"2px solid black", borderBottom:"2px solid black", borderRight:"2px solid black", background:"darkblue"}}>
                                    <p style={{marginTop:"8px"}}>PAR BR.</p>
                                </div>
                                <div className={classes.celijaGornjeTabele} style={{width:"51px", borderTop:"2px solid black", borderBottom:"2px solid black", borderRight:"2px solid black", background:"darkblue"}}>
                                    <p style={{marginTop:"8px"}}>PAR BR.</p>
                                </div>

                            </div>

                            <div style={{display:"flex", flexDirection:"column"}}>

                                <div className={classes.celijaGornjeTabele} style={{width:"51px", borderTop:"2px solid black", borderBottom:"2px solid black", borderRight:"2px solid black", background:"#305496"}}>
                                    <p style={{marginTop:"8px"}}>1</p>
                                </div>
                                <div className={classes.celijaGornjeTabele} style={{width:"51px", borderTop:"2px solid black", borderBottom:"2px solid black", borderRight:"2px solid black", background:"#305496"}}>
                                    <p style={{marginTop:"8px"}}>2</p>
                                </div>
                                <div className={classes.celijaGornjeTabele} style={{width:"51px", borderTop:"2px solid black", borderBottom:"2px solid black", borderRight:"2px solid black", background:"#305496"}}>
                                    <p style={{marginTop:"8px"}}>3</p>
                                </div>
                                <div className={classes.celijaGornjeTabele} style={{width:"51px", borderTop:"2px solid black", borderBottom:"2px solid black", borderRight:"2px solid black", background:"#305496"}}>
                                    <p style={{marginTop:"8px"}}>4</p>
                                </div>

                            </div>

                            <div style={{display:"flex", flexDirection:"column"}}>

                                <div className={classes.celijaGornjeTabele} style={{width:"101px", borderTop:"2px solid black", borderBottom:"2px solid black", borderRight:"2px solid black", background:"#305496"}}>
                                    <p style={{marginTop:"8px"}}>0</p>
                                </div>
                                <div className={classes.celijaGornjeTabele} style={{width:"101px", borderTop:"2px solid black", borderBottom:"2px solid black", borderRight:"2px solid black", background:"#305496"}}>
                                    <p style={{marginTop:"8px"}}>0</p>
                                </div>
                                <div className={classes.celijaGornjeTabele} style={{width:"101px", borderTop:"2px solid black", borderBottom:"2px solid black", borderRight:"2px solid black", background:"#305496"}}>
                                    <p style={{marginTop:"8px"}}>0</p>
                                </div>
                                <div className={classes.celijaGornjeTabele} style={{width:"101px", borderTop:"2px solid black", borderBottom:"2px solid black", borderRight:"2px solid black", background:"#305496"}}>
                                    <p style={{marginTop:"8px"}}>0</p>
                                </div>

                            </div>

                            <div style={{display:"flex", flexDirection:"column"}}>

                                <div className={classes.celijaGornjeTabele} style={{width:"51px", borderTop:"2px solid black", borderBottom:"2px solid black", borderRight:"2px solid black", background:"#305496"}}>
                                    <p style={{marginTop:"8px"}}>0,00</p>
                                </div>
                                <div className={classes.celijaGornjeTabele} style={{width:"51px", borderTop:"2px solid black", borderBottom:"2px solid black", borderRight:"2px solid black", background:"#305496"}}>
                                    <p style={{marginTop:"8px"}}>0,00</p>
                                </div>
                                <div className={classes.celijaGornjeTabele} style={{width:"51px", borderTop:"2px solid black", borderBottom:"2px solid black", borderRight:"2px solid black", background:"#305496"}}>
                                    <p style={{marginTop:"8px"}}>0,00</p>
                                </div>
                                <div className={classes.celijaGornjeTabele} style={{width:"51px", borderTop:"2px solid black", borderBottom:"2px solid black", borderRight:"2px solid black", background:"#305496"}}>
                                    <p style={{marginTop:"8px"}}>0,00</p>
                                </div>

                            </div>

                            <div style={{display:"flex", flexDirection:"column"}}>

                                <div className={classes.celijaGornjeTabele} style={{width:"103px", borderTop:"2px solid black", borderBottom:"2px solid black", borderRight:"2px solid black", borderLeft:"none", background:"red"}}>
                                    <p style={{marginTop:"8px", color:"black"}}>0</p>
                                </div>
                                <div className={classes.celijaGornjeTabele} style={{width:"103px", borderTop:"2px solid black", borderBottom:"2px solid black", borderRight:"2px solid black", borderLeft:"none", background:"red"}}>
                                    <p style={{marginTop:"8px", color:"black"}}>0</p>
                                </div>
                                <div className={classes.celijaGornjeTabele} style={{width:"103px", borderTop:"2px solid black", borderBottom:"2px solid black", borderRight:"2px solid black", borderLeft:"none", background:"red"}}>
                                    <p style={{marginTop:"8px", color:"black"}}>0</p>
                                </div>
                                <div className={classes.celijaGornjeTabele} style={{width:"103px", borderTop:"2px solid black", borderBottom:"2px solid black", borderRight:"2px solid black", borderLeft:"none", background:"red"}}>
                                    <p style={{marginTop:"8px", color:"black"}}>0</p>
                                </div>

                            </div>
                        </div>

                        <div className={classes.celijaGornjeTabele} style={{display:"flex", flexDirection:"row", border:"2px solid black", borderTop:"none"}}>
                            <div className={classes.celijaGornjeTabele} style={{width:"51px", borderRight:"2px solid black", background:"rebeccapurple"}}>
                                <p style={{marginTop:"8px"}}>1 KOMB</p>
                            </div>
                            <div className={classes.celijaGornjeTabele} style={{width:"101px", borderRight:"2px solid black", background:"rebeccapurple"}}>
                                <p style={{marginTop:"8px"}}>0,00</p>
                            </div>
                            <div className={classes.celijaGornjeTabele} style={{width:"52px", borderRight:"2px solid darkblue", background:"darkblue"}}>
                                <p style={{marginTop:"8px"}}>KVOTA></p>
                            </div>
                            <div className={classes.celijaGornjeTabele} style={{width:"52px", borderRight:"2px solid black", background:"darkblue"}}>
                                <p style={{marginTop:"8px"}}>0,00</p>
                            </div>
                            <div className={classes.celijaGornjeTabele} style={{width:"101px", background:"rebeccapurple"}}>
                                <p style={{marginTop:"8px"}}>FALSE</p>
                            </div>
                        </div>

                        <div className={classes.celijaGornjeTabele} style={{display:"flex", flexDirection:"row", border:"2px solid black", borderBottom:"none"}}>
                            <div className={classes.celijaGornjeTabele} style={{width:"51px", borderRight:"2px solid black", background:"darkblue"}}>
                                <p style={{marginTop:"9px"}}>ULOG ></p>
                            </div>
                            <div className={classes.celijaGornjeTabele} style={{width:"101px", borderRight:"2px solid black", background:"red"}}>
                                <p style={{marginTop:"8px", fontSize:"17px", color:"black"}}>0</p>
                            </div>
                            <div className={classes.celijaGornjeTabele} style={{width:"105px", borderRight:"2px solid black", background:"darkblue"}}>
                                <p style={{marginTop:"8px", fontSize:"17px"}}>SISTEM</p>
                            </div>
                            <div className={classes.celijaGornjeTabele} style={{width:"50px", borderRight:"2px solid darkblue", background:"darkblue"}}>
                                <p style={{marginTop:"9px"}}>3/4, 3/4</p>
                            </div>
                            <div className={classes.celijaGornjeTabele} style={{width:"50px", background:"darkblue"}}>
                                <p style={{marginTop:"8px", fontSize:"17px"}}>2</p>
                            </div>
                        </div>

                        <div style={{display:"flex", flexDirection:"row", border:"2px solid black"}}>

                            <div style={{display:"flex", flexDirection:"column"}}>

                                <div className={classes.celijaGornjeTabele} style={{width:"51px", borderTop:"2px solid black", borderBottom:"2px solid black", borderRight:"2px solid black", background:"darkblue"}}>
                                    <p style={{marginTop:"8px"}}>PAR BR.</p>
                                </div>
                                <div className={classes.celijaGornjeTabele} style={{width:"51px", borderTop:"2px solid black", borderBottom:"2px solid black", borderRight:"2px solid black", background:"darkblue"}}>
                                    <p style={{marginTop:"8px"}}>PAR BR.</p>
                                </div>
                                <div className={classes.celijaGornjeTabele} style={{width:"51px", borderTop:"2px solid black", borderBottom:"2px solid black", borderRight:"2px solid black", background:"darkblue"}}>
                                    <p style={{marginTop:"8px"}}>PAR BR.</p>
                                </div>
                                <div className={classes.celijaGornjeTabele} style={{width:"51px", borderTop:"2px solid black", borderBottom:"2px solid black", borderRight:"2px solid black", background:"darkblue"}}>
                                    <p style={{marginTop:"8px"}}>PAR BR.</p>
                                </div>

                            </div>

                            <div style={{display:"flex", flexDirection:"column"}}>

                                <div className={classes.celijaGornjeTabele} style={{width:"51px", borderTop:"2px solid black", borderBottom:"2px solid black", borderRight:"2px solid black", background:"#305496"}}>
                                    <p style={{marginTop:"8px"}}>1</p>
                                </div>
                                <div className={classes.celijaGornjeTabele} style={{width:"51px", borderTop:"2px solid black", borderBottom:"2px solid black", borderRight:"2px solid black", background:"#305496"}}>
                                    <p style={{marginTop:"8px"}}>2</p>
                                </div>
                                <div className={classes.celijaGornjeTabele} style={{width:"51px", borderTop:"2px solid black", borderBottom:"2px solid black", borderRight:"2px solid black", background:"#305496"}}>
                                    <p style={{marginTop:"8px"}}>3</p>
                                </div>
                                <div className={classes.celijaGornjeTabele} style={{width:"51px", borderTop:"2px solid black", borderBottom:"2px solid black", borderRight:"2px solid black", background:"#305496"}}>
                                    <p style={{marginTop:"8px"}}>4</p>
                                </div>

                            </div>

                            <div style={{display:"flex", flexDirection:"column"}}>

                                <div className={classes.celijaGornjeTabele} style={{width:"101px", borderTop:"2px solid black", borderBottom:"2px solid black", borderRight:"2px solid black", background:"#305496"}}>
                                    <p style={{marginTop:"8px"}}>0</p>
                                </div>
                                <div className={classes.celijaGornjeTabele} style={{width:"101px", borderTop:"2px solid black", borderBottom:"2px solid black", borderRight:"2px solid black", background:"#305496"}}>
                                    <p style={{marginTop:"8px"}}>0</p>
                                </div>
                                <div className={classes.celijaGornjeTabele} style={{width:"101px", borderTop:"2px solid black", borderBottom:"2px solid black", borderRight:"2px solid black", background:"#305496"}}>
                                    <p style={{marginTop:"8px"}}>0</p>
                                </div>
                                <div className={classes.celijaGornjeTabele} style={{width:"101px", borderTop:"2px solid black", borderBottom:"2px solid black", borderRight:"2px solid black", background:"#305496"}}>
                                    <p style={{marginTop:"8px"}}>0</p>
                                </div>

                            </div>

                            <div style={{display:"flex", flexDirection:"column"}}>

                                <div className={classes.celijaGornjeTabele} style={{width:"51px", borderTop:"2px solid black", borderBottom:"2px solid black", borderRight:"2px solid black", background:"#305496"}}>
                                    <p style={{marginTop:"8px"}}>0,00</p>
                                </div>
                                <div className={classes.celijaGornjeTabele} style={{width:"51px", borderTop:"2px solid black", borderBottom:"2px solid black", borderRight:"2px solid black", background:"#305496"}}>
                                    <p style={{marginTop:"8px"}}>0,00</p>
                                </div>
                                <div className={classes.celijaGornjeTabele} style={{width:"51px", borderTop:"2px solid black", borderBottom:"2px solid black", borderRight:"2px solid black", background:"#305496"}}>
                                    <p style={{marginTop:"8px"}}>0,00</p>
                                </div>
                                <div className={classes.celijaGornjeTabele} style={{width:"51px", borderTop:"2px solid black", borderBottom:"2px solid black", borderRight:"2px solid black", background:"#305496"}}>
                                    <p style={{marginTop:"8px"}}>0,00</p>
                                </div>

                            </div>

                            <div style={{display:"flex", flexDirection:"column"}}>

                                <div className={classes.celijaGornjeTabele} style={{width:"103px", borderTop:"2px solid black", borderBottom:"2px solid black", borderRight:"2px solid black", borderLeft:"none", background:"red"}}>
                                    <p style={{marginTop:"8px", color:"black"}}>0</p>
                                </div>
                                <div className={classes.celijaGornjeTabele} style={{width:"103px", borderTop:"2px solid black", borderBottom:"2px solid black", borderRight:"2px solid black", borderLeft:"none", background:"red"}}>
                                    <p style={{marginTop:"8px", color:"black"}}>0</p>
                                </div>
                                <div className={classes.celijaGornjeTabele} style={{width:"103px", borderTop:"2px solid black", borderBottom:"2px solid black", borderRight:"2px solid black", borderLeft:"none", background:"red"}}>
                                    <p style={{marginTop:"8px", color:"black"}}>0</p>
                                </div>
                                <div className={classes.celijaGornjeTabele} style={{width:"103px", borderTop:"2px solid black", borderBottom:"2px solid black", borderRight:"2px solid black", borderLeft:"none", background:"red"}}>
                                    <p style={{marginTop:"8px", color:"black"}}>0</p>
                                </div>

                            </div>
                        </div>

                        <div className={classes.celijaGornjeTabele} style={{display:"flex", flexDirection:"row", border:"2px solid black", borderTop:"none"}}>
                            <div className={classes.celijaGornjeTabele} style={{width:"51px", borderRight:"2px solid black", background:"rebeccapurple"}}>
                                <p style={{marginTop:"8px"}}>1 KOMB</p>
                            </div>
                            <div className={classes.celijaGornjeTabele} style={{width:"101px", borderRight:"2px solid black", background:"rebeccapurple"}}>
                                <p style={{marginTop:"8px"}}>0,00</p>
                            </div>
                            <div className={classes.celijaGornjeTabele} style={{width:"52px", borderRight:"2px solid darkblue", background:"darkblue"}}>
                                <p style={{marginTop:"8px"}}>KVOTA></p>
                            </div>
                            <div className={classes.celijaGornjeTabele} style={{width:"52px", borderRight:"2px solid black", background:"darkblue"}}>
                                <p style={{marginTop:"8px"}}>0,00</p>
                            </div>
                            <div className={classes.celijaGornjeTabele} style={{width:"101px", background:"rebeccapurple"}}>
                                <p style={{marginTop:"8px"}}>FALSE</p>
                            </div>
                        </div>

                        <div className={classes.celijaGornjeTabele} style={{display:"flex", flexDirection:"row", border:"2px solid black", borderBottom:"none"}}>
                            <div className={classes.celijaGornjeTabele} style={{width:"51px", borderRight:"2px solid black", background:"darkblue"}}>
                                <p style={{marginTop:"9px"}}>ULOG ></p>
                            </div>
                            <div className={classes.celijaGornjeTabele} style={{width:"101px", borderRight:"2px solid black", background:"red"}}>
                                <p style={{marginTop:"8px", fontSize:"17px", color:"black"}}>0</p>
                            </div>
                            <div className={classes.celijaGornjeTabele} style={{width:"105px", borderRight:"2px solid black", background:"darkblue"}}>
                                <p style={{marginTop:"8px", fontSize:"17px"}}>SISTEM</p>
                            </div>
                            <div className={classes.celijaGornjeTabele} style={{width:"50px", borderRight:"2px solid darkblue", background:"darkblue"}}>
                                <p style={{marginTop:"9px"}}>3/4, 3/4</p>
                            </div>
                            <div className={classes.celijaGornjeTabele} style={{width:"50px", background:"darkblue"}}>
                                <p style={{marginTop:"8px", fontSize:"17px"}}>2</p>
                            </div>
                        </div>

                        <div style={{display:"flex", flexDirection:"row", border:"2px solid black"}}>

                            <div style={{display:"flex", flexDirection:"column"}}>

                                <div className={classes.celijaGornjeTabele} style={{width:"51px", borderTop:"2px solid black", borderBottom:"2px solid black", borderRight:"2px solid black", background:"darkblue"}}>
                                    <p style={{marginTop:"8px"}}>PAR BR.</p>
                                </div>
                                <div className={classes.celijaGornjeTabele} style={{width:"51px", borderTop:"2px solid black", borderBottom:"2px solid black", borderRight:"2px solid black", background:"darkblue"}}>
                                    <p style={{marginTop:"8px"}}>PAR BR.</p>
                                </div>
                                <div className={classes.celijaGornjeTabele} style={{width:"51px", borderTop:"2px solid black", borderBottom:"2px solid black", borderRight:"2px solid black", background:"darkblue"}}>
                                    <p style={{marginTop:"8px"}}>PAR BR.</p>
                                </div>
                                <div className={classes.celijaGornjeTabele} style={{width:"51px", borderTop:"2px solid black", borderBottom:"2px solid black", borderRight:"2px solid black", background:"darkblue"}}>
                                    <p style={{marginTop:"8px"}}>PAR BR.</p>
                                </div>

                            </div>

                            <div style={{display:"flex", flexDirection:"column"}}>

                                <div className={classes.celijaGornjeTabele} style={{width:"51px", borderTop:"2px solid black", borderBottom:"2px solid black", borderRight:"2px solid black", background:"#305496"}}>
                                    <p style={{marginTop:"8px"}}>1</p>
                                </div>
                                <div className={classes.celijaGornjeTabele} style={{width:"51px", borderTop:"2px solid black", borderBottom:"2px solid black", borderRight:"2px solid black", background:"#305496"}}>
                                    <p style={{marginTop:"8px"}}>2</p>
                                </div>
                                <div className={classes.celijaGornjeTabele} style={{width:"51px", borderTop:"2px solid black", borderBottom:"2px solid black", borderRight:"2px solid black", background:"#305496"}}>
                                    <p style={{marginTop:"8px"}}>3</p>
                                </div>
                                <div className={classes.celijaGornjeTabele} style={{width:"51px", borderTop:"2px solid black", borderBottom:"2px solid black", borderRight:"2px solid black", background:"#305496"}}>
                                    <p style={{marginTop:"8px"}}>4</p>
                                </div>

                            </div>

                            <div style={{display:"flex", flexDirection:"column"}}>

                                <div className={classes.celijaGornjeTabele} style={{width:"101px", borderTop:"2px solid black", borderBottom:"2px solid black", borderRight:"2px solid black", background:"#305496"}}>
                                    <p style={{marginTop:"8px"}}>0</p>
                                </div>
                                <div className={classes.celijaGornjeTabele} style={{width:"101px", borderTop:"2px solid black", borderBottom:"2px solid black", borderRight:"2px solid black", background:"#305496"}}>
                                    <p style={{marginTop:"8px"}}>0</p>
                                </div>
                                <div className={classes.celijaGornjeTabele} style={{width:"101px", borderTop:"2px solid black", borderBottom:"2px solid black", borderRight:"2px solid black", background:"#305496"}}>
                                    <p style={{marginTop:"8px"}}>0</p>
                                </div>
                                <div className={classes.celijaGornjeTabele} style={{width:"101px", borderTop:"2px solid black", borderBottom:"2px solid black", borderRight:"2px solid black", background:"#305496"}}>
                                    <p style={{marginTop:"8px"}}>0</p>
                                </div>

                            </div>

                            <div style={{display:"flex", flexDirection:"column"}}>

                                <div className={classes.celijaGornjeTabele} style={{width:"51px", borderTop:"2px solid black", borderBottom:"2px solid black", borderRight:"2px solid black", background:"#305496"}}>
                                    <p style={{marginTop:"8px"}}>0,00</p>
                                </div>
                                <div className={classes.celijaGornjeTabele} style={{width:"51px", borderTop:"2px solid black", borderBottom:"2px solid black", borderRight:"2px solid black", background:"#305496"}}>
                                    <p style={{marginTop:"8px"}}>0,00</p>
                                </div>
                                <div className={classes.celijaGornjeTabele} style={{width:"51px", borderTop:"2px solid black", borderBottom:"2px solid black", borderRight:"2px solid black", background:"#305496"}}>
                                    <p style={{marginTop:"8px"}}>0,00</p>
                                </div>
                                <div className={classes.celijaGornjeTabele} style={{width:"51px", borderTop:"2px solid black", borderBottom:"2px solid black", borderRight:"2px solid black", background:"#305496"}}>
                                    <p style={{marginTop:"8px"}}>0,00</p>
                                </div>

                            </div>

                            <div style={{display:"flex", flexDirection:"column"}}>

                                <div className={classes.celijaGornjeTabele} style={{width:"103px", borderTop:"2px solid black", borderBottom:"2px solid black", borderRight:"2px solid black", borderLeft:"none", background:"red"}}>
                                    <p style={{marginTop:"8px", color:"black"}}>0</p>
                                </div>
                                <div className={classes.celijaGornjeTabele} style={{width:"103px", borderTop:"2px solid black", borderBottom:"2px solid black", borderRight:"2px solid black", borderLeft:"none", background:"red"}}>
                                    <p style={{marginTop:"8px", color:"black"}}>0</p>
                                </div>
                                <div className={classes.celijaGornjeTabele} style={{width:"103px", borderTop:"2px solid black", borderBottom:"2px solid black", borderRight:"2px solid black", borderLeft:"none", background:"red"}}>
                                    <p style={{marginTop:"8px", color:"black"}}>0</p>
                                </div>
                                <div className={classes.celijaGornjeTabele} style={{width:"103px", borderTop:"2px solid black", borderBottom:"2px solid black", borderRight:"2px solid black", borderLeft:"none", background:"red"}}>
                                    <p style={{marginTop:"8px", color:"black"}}>0</p>
                                </div>

                            </div>
                        </div>

                        <div className={classes.celijaGornjeTabele} style={{display:"flex", flexDirection:"row", border:"2px solid black", borderTop:"none"}}>
                            <div className={classes.celijaGornjeTabele} style={{width:"51px", borderRight:"2px solid black", background:"rebeccapurple"}}>
                                <p style={{marginTop:"8px"}}>1 KOMB</p>
                            </div>
                            <div className={classes.celijaGornjeTabele} style={{width:"101px", borderRight:"2px solid black", background:"rebeccapurple"}}>
                                <p style={{marginTop:"8px"}}>0,00</p>
                            </div>
                            <div className={classes.celijaGornjeTabele} style={{width:"52px", borderRight:"2px solid darkblue", background:"darkblue"}}>
                                <p style={{marginTop:"8px"}}>KVOTA></p>
                            </div>
                            <div className={classes.celijaGornjeTabele} style={{width:"52px", borderRight:"2px solid black", background:"darkblue"}}>
                                <p style={{marginTop:"8px"}}>0,00</p>
                            </div>
                            <div className={classes.celijaGornjeTabele} style={{width:"101px", background:"rebeccapurple"}}>
                                <p style={{marginTop:"8px"}}>FALSE</p>
                            </div>
                        </div>

                    </div>

                    <div style={{height:"100%", width:"364px", overflow:"hidden"}}>

                        <div className={classes.celijaGornjeTabele} style={{display:"flex", flexDirection:"row", border:"2px solid black", borderBottom:"none"}}>
                            <div className={classes.celijaGornjeTabele} style={{width:"51px", borderRight:"2px solid black", background:"darkblue"}}>
                                <p style={{marginTop:"9px"}}>ULOG ></p>
                            </div>
                            <div className={classes.celijaGornjeTabele} style={{width:"101px", borderRight:"2px solid black", background:"red"}}>
                                <p style={{marginTop:"8px", fontSize:"17px", color:"black"}}>0</p>
                            </div>
                            <div className={classes.celijaGornjeTabele} style={{width:"105px", borderRight:"2px solid black", background:"darkblue"}}>
                                <p style={{marginTop:"8px", fontSize:"17px"}}>SISTEM</p>
                            </div>
                            <div className={classes.celijaGornjeTabele} style={{width:"50px", borderRight:"2px solid darkblue", background:"darkblue"}}>
                                <p style={{marginTop:"9px"}}>3/4, 3/4</p>
                            </div>
                            <div className={classes.celijaGornjeTabele} style={{width:"50px", background:"darkblue"}}>
                                <p style={{marginTop:"8px", fontSize:"17px"}}>2</p>
                            </div>
                        </div>

                        <div style={{display:"flex", flexDirection:"row", border:"2px solid black"}}>

                            <div style={{display:"flex", flexDirection:"column"}}>

                                <div className={classes.celijaGornjeTabele} style={{width:"51px", borderTop:"2px solid black", borderBottom:"2px solid black", borderRight:"2px solid black", background:"darkblue"}}>
                                    <p style={{marginTop:"8px"}}>PAR BR.</p>
                                </div>
                                <div className={classes.celijaGornjeTabele} style={{width:"51px", borderTop:"2px solid black", borderBottom:"2px solid black", borderRight:"2px solid black", background:"darkblue"}}>
                                    <p style={{marginTop:"8px"}}>PAR BR.</p>
                                </div>
                                <div className={classes.celijaGornjeTabele} style={{width:"51px", borderTop:"2px solid black", borderBottom:"2px solid black", borderRight:"2px solid black", background:"darkblue"}}>
                                    <p style={{marginTop:"8px"}}>PAR BR.</p>
                                </div>
                                <div className={classes.celijaGornjeTabele} style={{width:"51px", borderTop:"2px solid black", borderBottom:"2px solid black", borderRight:"2px solid black", background:"darkblue"}}>
                                    <p style={{marginTop:"8px"}}>PAR BR.</p>
                                </div>

                            </div>

                            <div style={{display:"flex", flexDirection:"column"}}>

                                <div className={classes.celijaGornjeTabele} style={{width:"51px", borderTop:"2px solid black", borderBottom:"2px solid black", borderRight:"2px solid black", background:"#305496"}}>
                                    <p style={{marginTop:"8px"}}>1</p>
                                </div>
                                <div className={classes.celijaGornjeTabele} style={{width:"51px", borderTop:"2px solid black", borderBottom:"2px solid black", borderRight:"2px solid black", background:"#305496"}}>
                                    <p style={{marginTop:"8px"}}>2</p>
                                </div>
                                <div className={classes.celijaGornjeTabele} style={{width:"51px", borderTop:"2px solid black", borderBottom:"2px solid black", borderRight:"2px solid black", background:"#305496"}}>
                                    <p style={{marginTop:"8px"}}>3</p>
                                </div>
                                <div className={classes.celijaGornjeTabele} style={{width:"51px", borderTop:"2px solid black", borderBottom:"2px solid black", borderRight:"2px solid black", background:"#305496"}}>
                                    <p style={{marginTop:"8px"}}>4</p>
                                </div>

                            </div>

                            <div style={{display:"flex", flexDirection:"column"}}>

                                <div className={classes.celijaGornjeTabele} style={{width:"101px", borderTop:"2px solid black", borderBottom:"2px solid black", borderRight:"2px solid black", background:"#305496"}}>
                                    <p style={{marginTop:"8px"}}>0</p>
                                </div>
                                <div className={classes.celijaGornjeTabele} style={{width:"101px", borderTop:"2px solid black", borderBottom:"2px solid black", borderRight:"2px solid black", background:"#305496"}}>
                                    <p style={{marginTop:"8px"}}>0</p>
                                </div>
                                <div className={classes.celijaGornjeTabele} style={{width:"101px", borderTop:"2px solid black", borderBottom:"2px solid black", borderRight:"2px solid black", background:"#305496"}}>
                                    <p style={{marginTop:"8px"}}>0</p>
                                </div>
                                <div className={classes.celijaGornjeTabele} style={{width:"101px", borderTop:"2px solid black", borderBottom:"2px solid black", borderRight:"2px solid black", background:"#305496"}}>
                                    <p style={{marginTop:"8px"}}>0</p>
                                </div>

                            </div>

                            <div style={{display:"flex", flexDirection:"column"}}>

                                <div className={classes.celijaGornjeTabele} style={{width:"51px", borderTop:"2px solid black", borderBottom:"2px solid black", borderRight:"2px solid black", background:"#305496"}}>
                                    <p style={{marginTop:"8px"}}>0,00</p>
                                </div>
                                <div className={classes.celijaGornjeTabele} style={{width:"51px", borderTop:"2px solid black", borderBottom:"2px solid black", borderRight:"2px solid black", background:"#305496"}}>
                                    <p style={{marginTop:"8px"}}>0,00</p>
                                </div>
                                <div className={classes.celijaGornjeTabele} style={{width:"51px", borderTop:"2px solid black", borderBottom:"2px solid black", borderRight:"2px solid black", background:"#305496"}}>
                                    <p style={{marginTop:"8px"}}>0,00</p>
                                </div>
                                <div className={classes.celijaGornjeTabele} style={{width:"51px", borderTop:"2px solid black", borderBottom:"2px solid black", borderRight:"2px solid black", background:"#305496"}}>
                                    <p style={{marginTop:"8px"}}>0,00</p>
                                </div>

                            </div>

                            <div style={{display:"flex", flexDirection:"column"}}>

                                <div className={classes.celijaGornjeTabele} style={{width:"103px", borderTop:"2px solid black", borderBottom:"2px solid black", borderRight:"2px solid black", borderLeft:"none", background:"red"}}>
                                    <p style={{marginTop:"8px", color:"black"}}>0</p>
                                </div>
                                <div className={classes.celijaGornjeTabele} style={{width:"103px", borderTop:"2px solid black", borderBottom:"2px solid black", borderRight:"2px solid black", borderLeft:"none", background:"red"}}>
                                    <p style={{marginTop:"8px", color:"black"}}>0</p>
                                </div>
                                <div className={classes.celijaGornjeTabele} style={{width:"103px", borderTop:"2px solid black", borderBottom:"2px solid black", borderRight:"2px solid black", borderLeft:"none", background:"red"}}>
                                    <p style={{marginTop:"8px", color:"black"}}>0</p>
                                </div>
                                <div className={classes.celijaGornjeTabele} style={{width:"103px", borderTop:"2px solid black", borderBottom:"2px solid black", borderRight:"2px solid black", borderLeft:"none", background:"red"}}>
                                    <p style={{marginTop:"8px", color:"black"}}>0</p>
                                </div>

                            </div>
                        </div>

                        <div className={classes.celijaGornjeTabele} style={{display:"flex", flexDirection:"row", border:"2px solid black", borderTop:"none"}}>
                            <div className={classes.celijaGornjeTabele} style={{width:"51px", borderRight:"2px solid black", background:"rebeccapurple"}}>
                                <p style={{marginTop:"8px"}}>1 KOMB</p>
                            </div>
                            <div className={classes.celijaGornjeTabele} style={{width:"101px", borderRight:"2px solid black", background:"rebeccapurple"}}>
                                <p style={{marginTop:"8px"}}>0,00</p>
                            </div>
                            <div className={classes.celijaGornjeTabele} style={{width:"52px", borderRight:"2px solid darkblue", background:"darkblue"}}>
                                <p style={{marginTop:"8px"}}>KVOTA></p>
                            </div>
                            <div className={classes.celijaGornjeTabele} style={{width:"52px", borderRight:"2px solid black", background:"darkblue"}}>
                                <p style={{marginTop:"8px"}}>0,00</p>
                            </div>
                            <div className={classes.celijaGornjeTabele} style={{width:"101px", background:"rebeccapurple"}}>
                                <p style={{marginTop:"8px"}}>FALSE</p>
                            </div>
                        </div>

                        <div className={classes.celijaGornjeTabele} style={{display:"flex", flexDirection:"row", border:"2px solid black", borderBottom:"none"}}>
                            <div className={classes.celijaGornjeTabele} style={{width:"51px", borderRight:"2px solid black", background:"darkblue"}}>
                                <p style={{marginTop:"9px"}}>ULOG ></p>
                            </div>
                            <div className={classes.celijaGornjeTabele} style={{width:"101px", borderRight:"2px solid black", background:"red"}}>
                                <p style={{marginTop:"8px", fontSize:"17px", color:"black"}}>0</p>
                            </div>
                            <div className={classes.celijaGornjeTabele} style={{width:"105px", borderRight:"2px solid black", background:"darkblue"}}>
                                <p style={{marginTop:"8px", fontSize:"17px"}}>SISTEM</p>
                            </div>
                            <div className={classes.celijaGornjeTabele} style={{width:"50px", borderRight:"2px solid darkblue", background:"darkblue"}}>
                                <p style={{marginTop:"9px"}}>3/4, 3/4</p>
                            </div>
                            <div className={classes.celijaGornjeTabele} style={{width:"50px", background:"darkblue"}}>
                                <p style={{marginTop:"8px", fontSize:"17px"}}>2</p>
                            </div>
                        </div>

                        <div style={{display:"flex", flexDirection:"row", border:"2px solid black"}}>

                            <div style={{display:"flex", flexDirection:"column"}}>

                                <div className={classes.celijaGornjeTabele} style={{width:"51px", borderTop:"2px solid black", borderBottom:"2px solid black", borderRight:"2px solid black", background:"darkblue"}}>
                                    <p style={{marginTop:"8px"}}>PAR BR.</p>
                                </div>
                                <div className={classes.celijaGornjeTabele} style={{width:"51px", borderTop:"2px solid black", borderBottom:"2px solid black", borderRight:"2px solid black", background:"darkblue"}}>
                                    <p style={{marginTop:"8px"}}>PAR BR.</p>
                                </div>
                                <div className={classes.celijaGornjeTabele} style={{width:"51px", borderTop:"2px solid black", borderBottom:"2px solid black", borderRight:"2px solid black", background:"darkblue"}}>
                                    <p style={{marginTop:"8px"}}>PAR BR.</p>
                                </div>
                                <div className={classes.celijaGornjeTabele} style={{width:"51px", borderTop:"2px solid black", borderBottom:"2px solid black", borderRight:"2px solid black", background:"darkblue"}}>
                                    <p style={{marginTop:"8px"}}>PAR BR.</p>
                                </div>

                            </div>

                            <div style={{display:"flex", flexDirection:"column"}}>

                                <div className={classes.celijaGornjeTabele} style={{width:"51px", borderTop:"2px solid black", borderBottom:"2px solid black", borderRight:"2px solid black", background:"#305496"}}>
                                    <p style={{marginTop:"8px"}}>1</p>
                                </div>
                                <div className={classes.celijaGornjeTabele} style={{width:"51px", borderTop:"2px solid black", borderBottom:"2px solid black", borderRight:"2px solid black", background:"#305496"}}>
                                    <p style={{marginTop:"8px"}}>2</p>
                                </div>
                                <div className={classes.celijaGornjeTabele} style={{width:"51px", borderTop:"2px solid black", borderBottom:"2px solid black", borderRight:"2px solid black", background:"#305496"}}>
                                    <p style={{marginTop:"8px"}}>3</p>
                                </div>
                                <div className={classes.celijaGornjeTabele} style={{width:"51px", borderTop:"2px solid black", borderBottom:"2px solid black", borderRight:"2px solid black", background:"#305496"}}>
                                    <p style={{marginTop:"8px"}}>4</p>
                                </div>

                            </div>

                            <div style={{display:"flex", flexDirection:"column"}}>

                                <div className={classes.celijaGornjeTabele} style={{width:"101px", borderTop:"2px solid black", borderBottom:"2px solid black", borderRight:"2px solid black", background:"#305496"}}>
                                    <p style={{marginTop:"8px"}}>0</p>
                                </div>
                                <div className={classes.celijaGornjeTabele} style={{width:"101px", borderTop:"2px solid black", borderBottom:"2px solid black", borderRight:"2px solid black", background:"#305496"}}>
                                    <p style={{marginTop:"8px"}}>0</p>
                                </div>
                                <div className={classes.celijaGornjeTabele} style={{width:"101px", borderTop:"2px solid black", borderBottom:"2px solid black", borderRight:"2px solid black", background:"#305496"}}>
                                    <p style={{marginTop:"8px"}}>0</p>
                                </div>
                                <div className={classes.celijaGornjeTabele} style={{width:"101px", borderTop:"2px solid black", borderBottom:"2px solid black", borderRight:"2px solid black", background:"#305496"}}>
                                    <p style={{marginTop:"8px"}}>0</p>
                                </div>

                            </div>

                            <div style={{display:"flex", flexDirection:"column"}}>

                                <div className={classes.celijaGornjeTabele} style={{width:"51px", borderTop:"2px solid black", borderBottom:"2px solid black", borderRight:"2px solid black", background:"#305496"}}>
                                    <p style={{marginTop:"8px"}}>0,00</p>
                                </div>
                                <div className={classes.celijaGornjeTabele} style={{width:"51px", borderTop:"2px solid black", borderBottom:"2px solid black", borderRight:"2px solid black", background:"#305496"}}>
                                    <p style={{marginTop:"8px"}}>0,00</p>
                                </div>
                                <div className={classes.celijaGornjeTabele} style={{width:"51px", borderTop:"2px solid black", borderBottom:"2px solid black", borderRight:"2px solid black", background:"#305496"}}>
                                    <p style={{marginTop:"8px"}}>0,00</p>
                                </div>
                                <div className={classes.celijaGornjeTabele} style={{width:"51px", borderTop:"2px solid black", borderBottom:"2px solid black", borderRight:"2px solid black", background:"#305496"}}>
                                    <p style={{marginTop:"8px"}}>0,00</p>
                                </div>

                            </div>

                            <div style={{display:"flex", flexDirection:"column"}}>

                                <div className={classes.celijaGornjeTabele} style={{width:"103px", borderTop:"2px solid black", borderBottom:"2px solid black", borderRight:"2px solid black", borderLeft:"none", background:"red"}}>
                                    <p style={{marginTop:"8px", color:"black"}}>0</p>
                                </div>
                                <div className={classes.celijaGornjeTabele} style={{width:"103px", borderTop:"2px solid black", borderBottom:"2px solid black", borderRight:"2px solid black", borderLeft:"none", background:"red"}}>
                                    <p style={{marginTop:"8px", color:"black"}}>0</p>
                                </div>
                                <div className={classes.celijaGornjeTabele} style={{width:"103px", borderTop:"2px solid black", borderBottom:"2px solid black", borderRight:"2px solid black", borderLeft:"none", background:"red"}}>
                                    <p style={{marginTop:"8px", color:"black"}}>0</p>
                                </div>
                                <div className={classes.celijaGornjeTabele} style={{width:"103px", borderTop:"2px solid black", borderBottom:"2px solid black", borderRight:"2px solid black", borderLeft:"none", background:"red"}}>
                                    <p style={{marginTop:"8px", color:"black"}}>0</p>
                                </div>

                            </div>
                        </div>

                        <div className={classes.celijaGornjeTabele} style={{display:"flex", flexDirection:"row", border:"2px solid black", borderTop:"none"}}>
                            <div className={classes.celijaGornjeTabele} style={{width:"51px", borderRight:"2px solid black", background:"rebeccapurple"}}>
                                <p style={{marginTop:"8px"}}>1 KOMB</p>
                            </div>
                            <div className={classes.celijaGornjeTabele} style={{width:"101px", borderRight:"2px solid black", background:"rebeccapurple"}}>
                                <p style={{marginTop:"8px"}}>0,00</p>
                            </div>
                            <div className={classes.celijaGornjeTabele} style={{width:"52px", borderRight:"2px solid darkblue", background:"darkblue"}}>
                                <p style={{marginTop:"8px"}}>KVOTA></p>
                            </div>
                            <div className={classes.celijaGornjeTabele} style={{width:"52px", borderRight:"2px solid black", background:"darkblue"}}>
                                <p style={{marginTop:"8px"}}>0,00</p>
                            </div>
                            <div className={classes.celijaGornjeTabele} style={{width:"101px", background:"rebeccapurple"}}>
                                <p style={{marginTop:"8px"}}>FALSE</p>
                            </div>
                        </div>

                        <div className={classes.celijaGornjeTabele} style={{display:"flex", flexDirection:"row", border:"2px solid black", borderBottom:"none"}}>
                            <div className={classes.celijaGornjeTabele} style={{width:"51px", borderRight:"2px solid black", background:"darkblue"}}>
                                <p style={{marginTop:"9px"}}>ULOG ></p>
                            </div>
                            <div className={classes.celijaGornjeTabele} style={{width:"101px", borderRight:"2px solid black", background:"red"}}>
                                <p style={{marginTop:"8px", fontSize:"17px", color:"black"}}>0</p>
                            </div>
                            <div className={classes.celijaGornjeTabele} style={{width:"105px", borderRight:"2px solid black", background:"darkblue"}}>
                                <p style={{marginTop:"8px", fontSize:"17px"}}>SISTEM</p>
                            </div>
                            <div className={classes.celijaGornjeTabele} style={{width:"50px", borderRight:"2px solid darkblue", background:"darkblue"}}>
                                <p style={{marginTop:"9px"}}>3/4, 3/4</p>
                            </div>
                            <div className={classes.celijaGornjeTabele} style={{width:"50px", background:"darkblue"}}>
                                <p style={{marginTop:"8px", fontSize:"17px"}}>2</p>
                            </div>
                        </div>

                        <div style={{display:"flex", flexDirection:"row", border:"2px solid black"}}>

                            <div style={{display:"flex", flexDirection:"column"}}>

                                <div className={classes.celijaGornjeTabele} style={{width:"51px", borderTop:"2px solid black", borderBottom:"2px solid black", borderRight:"2px solid black", background:"darkblue"}}>
                                    <p style={{marginTop:"8px"}}>PAR BR.</p>
                                </div>
                                <div className={classes.celijaGornjeTabele} style={{width:"51px", borderTop:"2px solid black", borderBottom:"2px solid black", borderRight:"2px solid black", background:"darkblue"}}>
                                    <p style={{marginTop:"8px"}}>PAR BR.</p>
                                </div>
                                <div className={classes.celijaGornjeTabele} style={{width:"51px", borderTop:"2px solid black", borderBottom:"2px solid black", borderRight:"2px solid black", background:"darkblue"}}>
                                    <p style={{marginTop:"8px"}}>PAR BR.</p>
                                </div>
                                <div className={classes.celijaGornjeTabele} style={{width:"51px", borderTop:"2px solid black", borderBottom:"2px solid black", borderRight:"2px solid black", background:"darkblue"}}>
                                    <p style={{marginTop:"8px"}}>PAR BR.</p>
                                </div>

                            </div>

                            <div style={{display:"flex", flexDirection:"column"}}>

                                <div className={classes.celijaGornjeTabele} style={{width:"51px", borderTop:"2px solid black", borderBottom:"2px solid black", borderRight:"2px solid black", background:"#305496"}}>
                                    <p style={{marginTop:"8px"}}>1</p>
                                </div>
                                <div className={classes.celijaGornjeTabele} style={{width:"51px", borderTop:"2px solid black", borderBottom:"2px solid black", borderRight:"2px solid black", background:"#305496"}}>
                                    <p style={{marginTop:"8px"}}>2</p>
                                </div>
                                <div className={classes.celijaGornjeTabele} style={{width:"51px", borderTop:"2px solid black", borderBottom:"2px solid black", borderRight:"2px solid black", background:"#305496"}}>
                                    <p style={{marginTop:"8px"}}>3</p>
                                </div>
                                <div className={classes.celijaGornjeTabele} style={{width:"51px", borderTop:"2px solid black", borderBottom:"2px solid black", borderRight:"2px solid black", background:"#305496"}}>
                                    <p style={{marginTop:"8px"}}>4</p>
                                </div>

                            </div>

                            <div style={{display:"flex", flexDirection:"column"}}>

                                <div className={classes.celijaGornjeTabele} style={{width:"101px", borderTop:"2px solid black", borderBottom:"2px solid black", borderRight:"2px solid black", background:"#305496"}}>
                                    <p style={{marginTop:"8px"}}>0</p>
                                </div>
                                <div className={classes.celijaGornjeTabele} style={{width:"101px", borderTop:"2px solid black", borderBottom:"2px solid black", borderRight:"2px solid black", background:"#305496"}}>
                                    <p style={{marginTop:"8px"}}>0</p>
                                </div>
                                <div className={classes.celijaGornjeTabele} style={{width:"101px", borderTop:"2px solid black", borderBottom:"2px solid black", borderRight:"2px solid black", background:"#305496"}}>
                                    <p style={{marginTop:"8px"}}>0</p>
                                </div>
                                <div className={classes.celijaGornjeTabele} style={{width:"101px", borderTop:"2px solid black", borderBottom:"2px solid black", borderRight:"2px solid black", background:"#305496"}}>
                                    <p style={{marginTop:"8px"}}>0</p>
                                </div>

                            </div>

                            <div style={{display:"flex", flexDirection:"column"}}>

                                <div className={classes.celijaGornjeTabele} style={{width:"51px", borderTop:"2px solid black", borderBottom:"2px solid black", borderRight:"2px solid black", background:"#305496"}}>
                                    <p style={{marginTop:"8px"}}>0,00</p>
                                </div>
                                <div className={classes.celijaGornjeTabele} style={{width:"51px", borderTop:"2px solid black", borderBottom:"2px solid black", borderRight:"2px solid black", background:"#305496"}}>
                                    <p style={{marginTop:"8px"}}>0,00</p>
                                </div>
                                <div className={classes.celijaGornjeTabele} style={{width:"51px", borderTop:"2px solid black", borderBottom:"2px solid black", borderRight:"2px solid black", background:"#305496"}}>
                                    <p style={{marginTop:"8px"}}>0,00</p>
                                </div>
                                <div className={classes.celijaGornjeTabele} style={{width:"51px", borderTop:"2px solid black", borderBottom:"2px solid black", borderRight:"2px solid black", background:"#305496"}}>
                                    <p style={{marginTop:"8px"}}>0,00</p>
                                </div>

                            </div>

                            <div style={{display:"flex", flexDirection:"column"}}>

                                <div className={classes.celijaGornjeTabele} style={{width:"103px", borderTop:"2px solid black", borderBottom:"2px solid black", borderRight:"2px solid black", borderLeft:"none", background:"red"}}>
                                    <p style={{marginTop:"8px", color:"black"}}>0</p>
                                </div>
                                <div className={classes.celijaGornjeTabele} style={{width:"103px", borderTop:"2px solid black", borderBottom:"2px solid black", borderRight:"2px solid black", borderLeft:"none", background:"red"}}>
                                    <p style={{marginTop:"8px", color:"black"}}>0</p>
                                </div>
                                <div className={classes.celijaGornjeTabele} style={{width:"103px", borderTop:"2px solid black", borderBottom:"2px solid black", borderRight:"2px solid black", borderLeft:"none", background:"red"}}>
                                    <p style={{marginTop:"8px", color:"black"}}>0</p>
                                </div>
                                <div className={classes.celijaGornjeTabele} style={{width:"103px", borderTop:"2px solid black", borderBottom:"2px solid black", borderRight:"2px solid black", borderLeft:"none", background:"red"}}>
                                    <p style={{marginTop:"8px", color:"black"}}>0</p>
                                </div>

                            </div>
                        </div>

                        <div className={classes.celijaGornjeTabele} style={{display:"flex", flexDirection:"row", border:"2px solid black", borderTop:"none"}}>
                            <div className={classes.celijaGornjeTabele} style={{width:"51px", borderRight:"2px solid black", background:"rebeccapurple"}}>
                                <p style={{marginTop:"8px"}}>1 KOMB</p>
                            </div>
                            <div className={classes.celijaGornjeTabele} style={{width:"101px", borderRight:"2px solid black", background:"rebeccapurple"}}>
                                <p style={{marginTop:"8px"}}>0,00</p>
                            </div>
                            <div className={classes.celijaGornjeTabele} style={{width:"52px", borderRight:"2px solid darkblue", background:"darkblue"}}>
                                <p style={{marginTop:"8px"}}>KVOTA></p>
                            </div>
                            <div className={classes.celijaGornjeTabele} style={{width:"52px", borderRight:"2px solid black", background:"darkblue"}}>
                                <p style={{marginTop:"8px"}}>0,00</p>
                            </div>
                            <div className={classes.celijaGornjeTabele} style={{width:"101px", background:"rebeccapurple"}}>
                                <p style={{marginTop:"8px"}}>FALSE</p>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>

    </div>
  );
};

export default DevetMagicnihLinija;
