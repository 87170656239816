import { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import axios from "../config/axios";
import Spinner from "../components/Spinner";

import classes from "./BetKursSinglePage.module.css";

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

const BetKursSinglePage = () => {
  const { id } = useParams();
  const [data, setData] = useState({});

  const fetchData = async () => {
    const { data } = await axios.get(`/betcourse-post/${id}`);

    setData(data.post);
  };

  useEffect(() => {
    fetchData();
  }, [id]);

  return (
    <div className={classes.mainCenter}>
      {!data ? (
        <div className={classes.spinner}>
          <Spinner />
        </div>
      ) : (
        <div className={classes.content}>
          <div className={classes.chaptertitle}>
            {data.chapter?.title?.toUpperCase()}
          </div>
          <div className={classes.title}>{data.title?.toUpperCase()}</div>

          <div className={classes.contentRow}>
            {data &&
              data.items &&
              data.items.length > 0 &&
              data.items.map((item, index) => {
                if (item.type === "text") {
                  return (
                    <div
                      key={index}
                      style={{
                        color: item.color,
                        fontWeight: item.bold ? "bolder" : false,
                        fontSize: item.fontSize ? `${item.fontSize}px` : "30px",
                        fontStyle: item.italic ? "italic" : false,
                        marginTop: item.marginTop,
                        marginBottom: item.marginBottom,
                      }}
                    >
                      {item.description}
                    </div>
                  );
                }
                if (item.type === "video") {
                  // console.log(item);
                  return (
                    <video
                      key={index}
                      width="720"
                      height="360"
                      controls
                      style={{ marginBottom: "10px" }}
                      controlsList="nodownload"
                    >
                      <source
                        src={`${SERVER_URL}/${item.video.url}`}
                        type="video/mp4"
                      />
                    </video>
                  );
                }
                if (item.type === "img") {
                  return (
                    <a
                      key={index}
                      target="_blank"
                      href={item?.imageLink}
                      className={classes.itemImageATag}
                    >
                      <img
                        className={classes.itemImage}
                        src={`${SERVER_URL}/${item.image?.url}`}
                      ></img>
                    </a>
                  );
                }
              })}
          </div>
        </div>
      )}
    </div>
  );
};

export default BetKursSinglePage;
