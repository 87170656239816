import React, { useEffect, useState } from 'react'
import classes from './ApkUpdate.module.css'
import downloadApk from '../utils/DownloadApk'

const ApkUpdate = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768)

  const isAndroid = /Android/i.test(navigator.userAgent)

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768)
    }

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  useEffect(() => {
    if (isMobile && isAndroid) {
      downloadApk()
    } else {
      window.location.replace('/')
    }
  }, [isMobile])

  return (
    <div className={classes.apkUpdate}>
      <img
        src='/updatePlaceholder.png'
        alt='apk update'
      />
    </div>
  )
}

export default ApkUpdate
