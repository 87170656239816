import { useNavigate } from 'react-router-dom'
import classes from './shopAdminHeader.module.css'

const ShopAdminHeader = () => {
  const navigate = useNavigate()
  return (
    <div className={classes.container}>
      <h1>
        Admin Panel -{' '}
        <span onClick={() => navigate('/betko_shop')}>Betko Shop</span>
      </h1>
    </div>
  )
}

export default ShopAdminHeader
