import { useState, useEffect } from 'react';

function useCountdown(targetTime) {
  const [timeRemaining, setTimeRemaining] = useState('');

  useEffect(() => {
    function updateCountdown() {
      const now = new Date();
      const differenceInMs = new Date(targetTime) - now;

      if (differenceInMs <= 0) {
        setTimeRemaining('00:00:00');
        clearInterval(intervalId);
        return;
      }

      const hours = Math.floor(differenceInMs / (1000 * 60 * 60));
      const minutes = Math.floor((differenceInMs % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((differenceInMs % (1000 * 60)) / 1000);

      const formattedTime = [
        String(hours).padStart(2, '0'),
        String(minutes).padStart(2, '0'),
        String(seconds).padStart(2, '0')
      ].join(':');

      setTimeRemaining(formattedTime);
    }

    const intervalId = setInterval(updateCountdown, 1000);
    updateCountdown();

    return () => clearInterval(intervalId);
  }, [targetTime]);

  return timeRemaining;
}

export default useCountdown;
