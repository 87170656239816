import Grupa3 from "../components/Grupa3";
import Grupa20 from "../components/Grupa20";
import classes from "./fibonacci.module.css";
import { useState, useEffect } from "react";

const Fibonacci = () => {
  const [ulogArr, setUlogArr] = useState([
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
    0,
  ]);
  const [kvotaInputsArr, setKvotaInputsArr] = useState([
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  ]);
  const [eventualniDobitakArr, setEventualniDobitakArr] = useState([
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  ]);
  const [plusMinusArr, setPlusMinusArr] = useState([
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  ]);
  const [minimalnaIsplataKvotaArr, setMinimalnaIsplataKvotaArr] = useState([
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  ]);
  const [nizArr, setNizArr] = useState([
    1, 1, 3, 5, 8, 13, 21, 34, 55, 89, 144, 233, 377, 610, 987, 1597, 2584,
    4181, 6765, 10946, 17711, 28657, 46368, 75025, 121393, 196418,
  ]);

  const [rerender, setReRender] = useState(false);

  const handleChangeKvotaArr = (index, e) => {
    let arr = kvotaInputsArr;

    arr[index] = parseFloat(e.target.value);

    setKvotaInputsArr(arr);
    setReRender((curr) => !curr);
  };

  const handleCalculate = () => {
    let sumofulog = 0;

    for (let i = 0; i < ulogArr.length; i++) {
      sumofulog = sumofulog + ulogArr[i];
    }

    let ulogarrlet = ulogArr;

    let j = 0;
    for (let i = 1; i < nizArr.length; i++) {
      if (kvotaInputsArr[i - 1] > 0) {
        let ulog = ulogArr[0] * nizArr[i];

        ulogarrlet[j] = ulog;
      }
      j++;
    }

    setUlogArr(ulogarrlet);

    let eventualnidobitaklet = eventualniDobitakArr;

    for (let i = 0; i < kvotaInputsArr.length; i++) {
      let ev = ulogArr[i] * kvotaInputsArr[i];

      eventualnidobitaklet[i] = ev;
    }

    setEventualniDobitakArr(eventualnidobitaklet);

    let plusminuslet = plusMinusArr;

    for (let i = 0; i < plusMinusArr.length; i++) {
      let plusminus;
      if (i === 0) {
        plusminus = eventualniDobitakArr[i] - ulogArr[i];
      } else {
        plusminus = eventualniDobitakArr[i];
      }
      plusminuslet[i] = plusminus;
    }

    setPlusMinusArr(plusminuslet);

    let minarr = minimalnaIsplataKvotaArr;

    for (let i = 0; i < minimalnaIsplataKvotaArr.length; i++) {
      let minIsp;
      if (i === 0) {
        minIsp = 1.01;
      } else {
        minIsp = sumofulog / ulogArr[i] + 0.01;
      }

      if (isNaN(minIsp)) {
        minarr[i] = 0;
      } else {
        minarr[i] = minIsp.toFixed(2);
      }
    }

    setMinimalnaIsplataKvotaArr(minarr);

    setReRender(false);
  };

  const handleChangeUlogArr = (index, e) => {
    let arr = ulogArr;

    arr[index] = parseFloat(e.target.value);

    setUlogArr(arr);
    setReRender((curr) => !curr);
  };

  useEffect(() => {
    handleCalculate();
  }, [ulogArr, kvotaInputsArr, rerender]);

  return (
    <div className={classes.container}>
      <Grupa3
        imgText="FIBONACCI"
        imgBackgroundColorDark="#672995"
        imgBackgroundColorLight="#7730AD"
        imgFontColor="#FFFFFF"
      />
      <Grupa20
        imgBackgroundColorDark="#672995"
        imgBackgroundColorLight="#7730AD"
        imgFontColor="#FFFFFF"
        linkOpisKalkulatora="/bet_kalkulatori_tutorijal/645b0d0ecd9c8da743a19553"
      />
      <div className={classes.calculator} style={{ fontWeight: "bolder" }}>
        <div className={classes.firstRow}>
          <div className={classes.brIgaraRow}>
            <p style={{ fontSize: "28px", marginTop: "17px" }}>BR. IGARA</p>
          </div>
          <div className={classes.nizRow}>
            <p style={{ fontSize: "28px", marginTop: "17px" }}>NIZ</p>
          </div>
          <div className={classes.ulogRow}>
            <p style={{ fontSize: "28px", marginTop: "17px" }}>ULOG</p>
          </div>
          <div className={classes.kvotaRow}>
            <p style={{ fontSize: "28px", marginTop: "17px" }}>KVOTA</p>
          </div>

          <div className={classes.eventualniDobitakRow}>
            <p style={{ fontSize: "28px", marginTop: "5px" }}>
              EVENTUALNI DOBITAK
            </p>
          </div>

          <div className={classes.plusMinusRow}>
            <p style={{ fontSize: "28px", marginTop: "17px" }}>PLUS(MINUS)</p>
          </div>
          <div className={classes.minimalnaIsplativaKvotaRow}>
            <p style={{ fontSize: "28px", marginTop: "5px" }}>
              MINIMALNA ISPLATIVA KVOTA
            </p>
          </div>
        </div>

        <div className={classes.row}>
          <div className={classes.brIgaraRow}>0</div>
          <div className={classes.nizRow}>1</div>
          <div className={classes.ulogRow}>
            <input
              className={classes.odvojenoInputPolje}
              style={{ paddingLeft: "14px" }}
              onChange={(e) => {
                handleChangeUlogArr(0, e);
              }}
              type="number"
              min="0"
            />
          </div>
          <div className={classes.kvotaRow}>-</div>

          <div className={classes.eventualniDobitakRow}>-</div>

          <div className={classes.plusMinusRow}>-</div>
          <div className={classes.minimalnaIsplativaKvotaRow}>-</div>
        </div>
        <div className={classes.row}>
          <div className={classes.brIgaraRow}>1</div>
          <div className={classes.nizRow}>1</div>
          <div className={classes.ulogRow}>{ulogArr[0]}</div>
          <div className={classes.kvotaRow2}>
            <input
              className={classes.inputPolje}
              onChange={(e) => {
                handleChangeKvotaArr(0, e);
              }}
              type="number"
              min="0"
            />
          </div>

          <div className={classes.eventualniDobitakRow}>
            {eventualniDobitakArr[0]}
          </div>

          <div className={classes.plusMinusRow}>{plusMinusArr[0]}</div>
          <div className={classes.minimalnaIsplativaKvotaRow}>
            {minimalnaIsplataKvotaArr[0] === "Infinity"
              ? "-"
              : minimalnaIsplataKvotaArr[0]}
          </div>
        </div>
        <div className={classes.row}>
          <div className={classes.brIgaraRow}>2</div>
          <div className={classes.nizRow}>3</div>
          <div className={classes.ulogRow}>{ulogArr[1]}</div>
          <div className={classes.kvotaRow2}>
            <input
              className={classes.inputPolje}
              onChange={(e) => {
                handleChangeKvotaArr(1, e);
              }}
              type="number"
              min="0"
            />
          </div>

          <div className={classes.eventualniDobitakRow}>
            {eventualniDobitakArr[1]}
          </div>

          <div className={classes.plusMinusRow}>{plusMinusArr[1]}</div>
          <div className={classes.minimalnaIsplativaKvotaRow}>
            {minimalnaIsplataKvotaArr[1] === "Infinity"
              ? "-"
              : minimalnaIsplataKvotaArr[1]}
          </div>
        </div>
        <div className={classes.row}>
          <div className={classes.brIgaraRow}>3</div>
          <div className={classes.nizRow}>5</div>
          <div className={classes.ulogRow}>{ulogArr[2]}</div>
          <div className={classes.kvotaRow2}>
            <input
              className={classes.inputPolje}
              onChange={(e) => {
                handleChangeKvotaArr(2, e);
              }}
              type="number"
              min="0"
            />
          </div>

          <div className={classes.eventualniDobitakRow}>
            {eventualniDobitakArr[2]}
          </div>

          <div className={classes.plusMinusRow}>{plusMinusArr[2]}</div>
          <div className={classes.minimalnaIsplativaKvotaRow}>
            {minimalnaIsplataKvotaArr[2] === "Infinity"
              ? "-"
              : minimalnaIsplataKvotaArr[2]}
          </div>
        </div>
        <div className={classes.row}>
          <div className={classes.brIgaraRow}>4</div>
          <div className={classes.nizRow}>8</div>
          <div className={classes.ulogRow}>{ulogArr[3]}</div>
          <div className={classes.kvotaRow2}>
            <input
              className={classes.inputPolje}
              onChange={(e) => {
                handleChangeKvotaArr(3, e);
              }}
              type="number"
              min="0"
            />
          </div>

          <div className={classes.eventualniDobitakRow}>
            {eventualniDobitakArr[3]}
          </div>

          <div className={classes.plusMinusRow}>{plusMinusArr[3]}</div>
          <div className={classes.minimalnaIsplativaKvotaRow}>
            {minimalnaIsplataKvotaArr[3] === "Infinity"
              ? "-"
              : minimalnaIsplataKvotaArr[3]}
          </div>
        </div>
        <div className={classes.row}>
          <div className={classes.brIgaraRow}>5</div>
          <div className={classes.nizRow}>13</div>
          <div className={classes.ulogRow}>{ulogArr[4]}</div>
          <div className={classes.kvotaRow2}>
            <input
              className={classes.inputPolje}
              onChange={(e) => {
                handleChangeKvotaArr(4, e);
              }}
              type="number"
              min="0"
            />
          </div>

          <div className={classes.eventualniDobitakRow}>
            {eventualniDobitakArr[4]}
          </div>

          <div className={classes.plusMinusRow}>{plusMinusArr[4]}</div>
          <div className={classes.minimalnaIsplativaKvotaRow}>
            {minimalnaIsplataKvotaArr[4] === "Infinity"
              ? "-"
              : minimalnaIsplataKvotaArr[4]}
          </div>
        </div>
        <div className={classes.row}>
          <div className={classes.brIgaraRow}>6</div>
          <div className={classes.nizRow}>21</div>
          <div className={classes.ulogRow}>{ulogArr[5]}</div>
          <div className={classes.kvotaRow2}>
            <input
              className={classes.inputPolje}
              onChange={(e) => {
                handleChangeKvotaArr(5, e);
              }}
              type="number"
              min="0"
            />
          </div>

          <div className={classes.eventualniDobitakRow}>
            {eventualniDobitakArr[5]}
          </div>

          <div className={classes.plusMinusRow}>{plusMinusArr[5]}</div>
          <div className={classes.minimalnaIsplativaKvotaRow}>
            {minimalnaIsplataKvotaArr[5] === "Infinity"
              ? "-"
              : minimalnaIsplataKvotaArr[5]}
          </div>
        </div>
        <div className={classes.row}>
          <div className={classes.brIgaraRow}>7</div>
          <div className={classes.nizRow}>34</div>
          <div className={classes.ulogRow}>{ulogArr[6]}</div>
          <div className={classes.kvotaRow2}>
            <input
              className={classes.inputPolje}
              onChange={(e) => {
                handleChangeKvotaArr(6, e);
              }}
              type="number"
              min="0"
            />
          </div>

          <div className={classes.eventualniDobitakRow}>
            {eventualniDobitakArr[6]}
          </div>

          <div className={classes.plusMinusRow}>{plusMinusArr[6]}</div>
          <div className={classes.minimalnaIsplativaKvotaRow}>
            {minimalnaIsplataKvotaArr[6] === "Infinity"
              ? "-"
              : minimalnaIsplataKvotaArr[6]}
          </div>
        </div>
        <div className={classes.row}>
          <div className={classes.brIgaraRow}>8</div>
          <div className={classes.nizRow}>55</div>
          <div className={classes.ulogRow}>{ulogArr[7]}</div>
          <div className={classes.kvotaRow2}>
            {" "}
            <input
              className={classes.inputPolje}
              onChange={(e) => {
                handleChangeKvotaArr(7, e);
              }}
              type="number"
              min="0"
            />
          </div>
          <div className={classes.eventualniDobitakRow}>
            {eventualniDobitakArr[7]}
          </div>

          <div className={classes.plusMinusRow}>{plusMinusArr[7]}</div>
          <div className={classes.minimalnaIsplativaKvotaRow}>
            {minimalnaIsplataKvotaArr[7] === "Infinity"
              ? "-"
              : minimalnaIsplataKvotaArr[7]}
          </div>
        </div>
        <div className={classes.row}>
          <div className={classes.brIgaraRow}>9</div>
          <div className={classes.nizRow}>89</div>
          <div className={classes.ulogRow}>{ulogArr[8]}</div>
          <div className={classes.kvotaRow2}>
            <input
              className={classes.inputPolje}
              onChange={(e) => {
                handleChangeKvotaArr(8, e);
              }}
              type="number"
              min="0"
            />
          </div>
          <div className={classes.eventualniDobitakRow}>
            {eventualniDobitakArr[8]}
          </div>

          <div className={classes.plusMinusRow}>{plusMinusArr[8]}</div>
          <div className={classes.minimalnaIsplativaKvotaRow}>
            {minimalnaIsplataKvotaArr[8] === "Infinity"
              ? "-"
              : minimalnaIsplataKvotaArr[8]}
          </div>
        </div>
        <div className={classes.row}>
          <div className={classes.brIgaraRow}>10</div>
          <div className={classes.nizRow}>144</div>
          <div className={classes.ulogRow}>{ulogArr[9]}</div>
          <div className={classes.kvotaRow2}>
            <input
              className={classes.inputPolje}
              onChange={(e) => {
                handleChangeKvotaArr(9, e);
              }}
              type="number"
              min="0"
            />
          </div>
          <div className={classes.eventualniDobitakRow}>
            {eventualniDobitakArr[9]}
          </div>

          <div className={classes.plusMinusRow}>{plusMinusArr[9]}</div>
          <div className={classes.minimalnaIsplativaKvotaRow}>
            {minimalnaIsplataKvotaArr[9] === "Infinity"
              ? "-"
              : minimalnaIsplataKvotaArr[9]}
          </div>
        </div>
        <div className={classes.row}>
          <div className={classes.brIgaraRow}>11</div>
          <div className={classes.nizRow}>233</div>
          <div className={classes.ulogRow}>{ulogArr[10]}</div>
          <div className={classes.kvotaRow2}>
            <input
              className={classes.inputPolje}
              onChange={(e) => {
                handleChangeKvotaArr(10, e);
              }}
              type="number"
              min="0"
            />
          </div>
          <div className={classes.eventualniDobitakRow}>
            {eventualniDobitakArr[10]}
          </div>

          <div className={classes.plusMinusRow}>{plusMinusArr[10]}</div>
          <div className={classes.minimalnaIsplativaKvotaRow}>
            {minimalnaIsplataKvotaArr[10] === "Infinity"
              ? "-"
              : minimalnaIsplataKvotaArr[10]}
          </div>
        </div>
        <div className={classes.row}>
          <div className={classes.brIgaraRow}>12</div>
          <div className={classes.nizRow}>377</div>
          <div className={classes.ulogRow}>{ulogArr[11]}</div>
          <div className={classes.kvotaRow2}>
            <input
              className={classes.inputPolje}
              onChange={(e) => {
                handleChangeKvotaArr(11, e);
              }}
              type="number"
              min="0"
            />
          </div>
          <div className={classes.eventualniDobitakRow}>
            {eventualniDobitakArr[11]}
          </div>

          <div className={classes.plusMinusRow}>{plusMinusArr[11]}</div>
          <div className={classes.minimalnaIsplativaKvotaRow}>
            {minimalnaIsplataKvotaArr[11] === "Infinity"
              ? "-"
              : minimalnaIsplataKvotaArr[11]}
          </div>
        </div>
        <div className={classes.row}>
          <div className={classes.brIgaraRow}>13</div>
          <div className={classes.nizRow}>610</div>
          <div className={classes.ulogRow}>{ulogArr[12]}</div>
          <div className={classes.kvotaRow2}>
            <input
              className={classes.inputPolje}
              onChange={(e) => {
                handleChangeKvotaArr(12, e);
              }}
              type="number"
              min="0"
            />
          </div>
          <div className={classes.eventualniDobitakRow}>
            {eventualniDobitakArr[12]}
          </div>

          <div className={classes.plusMinusRow}>{plusMinusArr[12]}</div>
          <div className={classes.minimalnaIsplativaKvotaRow}>
            {minimalnaIsplataKvotaArr[12] === "Infinity"
              ? "-"
              : minimalnaIsplataKvotaArr[12]}
          </div>
        </div>
        <div className={classes.row}>
          <div className={classes.brIgaraRow}>14</div>
          <div className={classes.nizRow}>987</div>
          <div className={classes.ulogRow}>{ulogArr[13]}</div>
          <div className={classes.kvotaRow2}>
            <input
              className={classes.inputPolje}
              onChange={(e) => {
                handleChangeKvotaArr(13, e);
              }}
              type="number"
              min="0"
            />
          </div>
          <div className={classes.eventualniDobitakRow}>
            {eventualniDobitakArr[13]}
          </div>

          <div className={classes.plusMinusRow}>{plusMinusArr[13]}</div>
          <div className={classes.minimalnaIsplativaKvotaRow}>
            {minimalnaIsplataKvotaArr[13] === "Infinity"
              ? "-"
              : minimalnaIsplataKvotaArr[13]}
          </div>
        </div>
        <div className={classes.row}>
          <div className={classes.brIgaraRow}>15</div>
          <div className={classes.nizRow}>1597</div>
          <div className={classes.ulogRow}>{ulogArr[14]}</div>
          <div className={classes.kvotaRow2}>
            {" "}
            <input
              className={classes.inputPolje}
              onChange={(e) => {
                handleChangeKvotaArr(14, e);
              }}
              type="number"
              min="0"
            />
          </div>
          <div className={classes.eventualniDobitakRow}>
            {eventualniDobitakArr[14]}
          </div>

          <div className={classes.plusMinusRow}>{plusMinusArr[14]}</div>
          <div className={classes.minimalnaIsplativaKvotaRow}>
            {minimalnaIsplataKvotaArr[14] === "Infinity"
              ? "-"
              : minimalnaIsplataKvotaArr[14]}
          </div>
        </div>
        <div className={classes.row}>
          <div className={classes.brIgaraRow}>16</div>
          <div className={classes.nizRow}>2584</div>
          <div className={classes.ulogRow}>{ulogArr[15]}</div>
          <div className={classes.kvotaRow2}>
            <input
              className={classes.inputPolje}
              onChange={(e) => {
                handleChangeKvotaArr(15, e);
              }}
              type="number"
              min="0"
            />
          </div>
          <div className={classes.eventualniDobitakRow}>
            {eventualniDobitakArr[15]}
          </div>

          <div className={classes.plusMinusRow}>{plusMinusArr[15]}</div>
          <div className={classes.minimalnaIsplativaKvotaRow}>
            {minimalnaIsplataKvotaArr[15] === "Infinity"
              ? "-"
              : minimalnaIsplataKvotaArr[15]}
          </div>
        </div>
        <div className={classes.row}>
          <div className={classes.brIgaraRow}>17</div>
          <div className={classes.nizRow}>4181</div>
          <div className={classes.ulogRow}>{ulogArr[16]}</div>
          <div className={classes.kvotaRow2}>
            <input
              className={classes.inputPolje}
              onChange={(e) => {
                handleChangeKvotaArr(16, e);
              }}
              type="number"
              min="0"
            />
          </div>
          <div className={classes.eventualniDobitakRow}>
            {eventualniDobitakArr[16]}
          </div>

          <div className={classes.plusMinusRow}>{plusMinusArr[16]}</div>
          <div className={classes.minimalnaIsplativaKvotaRow}>
            {minimalnaIsplataKvotaArr[16] === "Infinity"
              ? "-"
              : minimalnaIsplataKvotaArr[16]}
          </div>
        </div>
        <div className={classes.row}>
          <div className={classes.brIgaraRow}>18</div>
          <div className={classes.nizRow}>6765</div>
          <div className={classes.ulogRow}>{ulogArr[17]}</div>
          <div className={classes.kvotaRow2}>
            <input
              className={classes.inputPolje}
              onChange={(e) => {
                handleChangeKvotaArr(17, e);
              }}
              type="number"
              min="0"
            />
          </div>
          <div className={classes.eventualniDobitakRow}>
            {eventualniDobitakArr[17]}
          </div>

          <div className={classes.plusMinusRow}>{plusMinusArr[17]}</div>
          <div className={classes.minimalnaIsplativaKvotaRow}>
            {minimalnaIsplataKvotaArr[17] === "Infinity"
              ? "-"
              : minimalnaIsplataKvotaArr[17]}
          </div>
        </div>
        <div className={classes.row}>
          <div className={classes.brIgaraRow}>19</div>
          <div className={classes.nizRow}>10944</div>
          <div className={classes.ulogRow}>{ulogArr[18]}</div>
          <div className={classes.kvotaRow2}>
            <input
              className={classes.inputPolje}
              onChange={(e) => {
                handleChangeKvotaArr(18, e);
              }}
              type="number"
              min="0"
            />
          </div>
          <div className={classes.eventualniDobitakRow}>
            {eventualniDobitakArr[18]}
          </div>

          <div className={classes.plusMinusRow}>{plusMinusArr[18]}</div>
          <div className={classes.minimalnaIsplativaKvotaRow}>
            {minimalnaIsplataKvotaArr[18] === "Infinity"
              ? "-"
              : minimalnaIsplataKvotaArr[18]}
          </div>
        </div>
        <div className={classes.row}>
          <div className={classes.brIgaraRow}>20</div>
          <div className={classes.nizRow}>17711</div>
          <div className={classes.ulogRow}>{ulogArr[19]}</div>
          <div className={classes.kvotaRow2}>
            <input
              className={classes.inputPolje}
              onChange={(e) => {
                handleChangeKvotaArr(19, e);
              }}
              type="number"
              min="0"
            />
          </div>
          <div className={classes.eventualniDobitakRow}>
            {eventualniDobitakArr[19]}
          </div>

          <div className={classes.plusMinusRow}>{plusMinusArr[19]}</div>
          <div className={classes.minimalnaIsplativaKvotaRow}>
            {minimalnaIsplataKvotaArr[19] === "Infinity"
              ? "-"
              : minimalnaIsplataKvotaArr[19]}
          </div>
        </div>
        <div className={classes.row}>
          <div className={classes.brIgaraRow}>21</div>
          <div className={classes.nizRow}>28657</div>
          <div className={classes.ulogRow}>{ulogArr[20]}</div>
          <div className={classes.kvotaRow2}>
            <input
              className={classes.inputPolje}
              onChange={(e) => {
                handleChangeKvotaArr(20, e);
              }}
              type="number"
              min="0"
            />
          </div>
          <div className={classes.eventualniDobitakRow}>
            {eventualniDobitakArr[20]}
          </div>

          <div className={classes.plusMinusRow}>{plusMinusArr[20]}</div>
          <div className={classes.minimalnaIsplativaKvotaRow}>
            {minimalnaIsplataKvotaArr[20] === "Infinity"
              ? "-"
              : minimalnaIsplataKvotaArr[20]}
          </div>
        </div>
        <div className={classes.row}>
          <div className={classes.brIgaraRow}>22</div>
          <div className={classes.nizRow}>46368</div>
          <div className={classes.ulogRow}>{ulogArr[21]}</div>
          <div className={classes.kvotaRow2}>
            <input
              className={classes.inputPolje}
              onChange={(e) => {
                handleChangeKvotaArr(21, e);
              }}
              type="number"
              min="0"
            />
          </div>
          <div className={classes.eventualniDobitakRow}>
            {eventualniDobitakArr[21]}
          </div>

          <div className={classes.plusMinusRow}>{plusMinusArr[21]}</div>
          <div className={classes.minimalnaIsplativaKvotaRow}>
            {minimalnaIsplataKvotaArr[21] === "Infinity"
              ? "-"
              : minimalnaIsplataKvotaArr[21]}
          </div>
        </div>
        <div className={classes.row}>
          <div className={classes.brIgaraRow}>23</div>
          <div className={classes.nizRow}>75025</div>
          <div className={classes.ulogRow}>{ulogArr[22]}</div>
          <div className={classes.kvotaRow2}>
            <input
              className={classes.inputPolje}
              onChange={(e) => {
                handleChangeKvotaArr(22, e);
              }}
              type="number"
              min="0"
            />
          </div>
          <div className={classes.eventualniDobitakRow}>
            {eventualniDobitakArr[22]}
          </div>

          <div className={classes.plusMinusRow}>{plusMinusArr[22]}</div>
          <div className={classes.minimalnaIsplativaKvotaRow}>
            {minimalnaIsplataKvotaArr[22] === "Infinity"
              ? "-"
              : minimalnaIsplataKvotaArr[22]}
          </div>
        </div>
        <div className={classes.row}>
          <div className={classes.brIgaraRow}>24</div>
          <div className={classes.nizRow}>121393</div>
          <div className={classes.ulogRow}>{ulogArr[23]}</div>
          <div className={classes.kvotaRow2}>
            <input
              className={classes.inputPolje}
              onChange={(e) => {
                handleChangeKvotaArr(23, e);
              }}
              type="number"
              min="0"
            />
          </div>
          <div className={classes.eventualniDobitakRow}>
            {eventualniDobitakArr[23]}
          </div>

          <div className={classes.plusMinusRow}>{plusMinusArr[23]}</div>
          <div className={classes.minimalnaIsplativaKvotaRow}>
            {minimalnaIsplataKvotaArr[23] === "Infinity"
              ? "-"
              : minimalnaIsplataKvotaArr[23]}
          </div>
        </div>
        <div className={classes.row}>
          <div className={classes.brIgaraRow}>25</div>
          <div className={classes.nizRow}>196418</div>
          <div className={classes.ulogRow}>{ulogArr[24]}</div>
          <div className={classes.kvotaRow2}>
            <input
              className={classes.inputPolje}
              onChange={(e) => {
                handleChangeKvotaArr(24, e);
              }}
              type="number"
              min="0"
            />
          </div>
          <div className={classes.eventualniDobitakRow}>
            {eventualniDobitakArr[24]}
          </div>

          <div className={classes.plusMinusRow}>{plusMinusArr[24]}</div>
          <div className={classes.minimalnaIsplativaKvotaRow}>
            {minimalnaIsplataKvotaArr[24] === "Infinity"
              ? "-"
              : minimalnaIsplataKvotaArr[24]}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Fibonacci;
