import Grupa3 from "../components/Grupa3";
import Grupa20 from "../components/Grupa20";
import classes from "./skraceniGrupisaniSistemi.module.css";

const SrkaceniGrupisaniSistemi = () => {
  const rows = 46;
  const columns = 38;
  const limitedColumnsRows = [1];
  const limitForSelectedRows = 8;
  const arrayOfArrays = [];

  for (let i = 0; i < rows; i++) {
    const innerArray = [];

    const currentColumnsLimit = limitedColumnsRows.includes(i)
      ? limitForSelectedRows
      : columns;

    for (let j = 0; j < currentColumnsLimit; j++) {
      innerArray.push(`${i}-${j}`);
    }

    arrayOfArrays.push(innerArray);
  }
  return (
    <div className={classes.container}>
      <Grupa3
        imgText="SKRAĆENI GRUPISANI SISTEMI"
        imgBackgroundColorDark="#052C83"
        imgBackgroundColorLight="#0D2869"
        imgFontColor="#00B0F0"
      />
      <Grupa20
        imgBackgroundColorDark="#052C83"
        imgBackgroundColorLight="#0D2869"
        imgFontColor="#00B0F0"
        calculatorType={`Skraceni grupisani betko sistemi`}
      />
      <div className={classes.bottom}>
        <div className={classes.calculator}>
          <table border="1">
            <tbody>
              {arrayOfArrays.map((row, rowIndex) => (
                <tr style={{ display: "flex", width: "100%" }} key={rowIndex}>
                  {rowIndex === 0 ||
                  rowIndex === 1 ||
                  rowIndex === 20 ||
                  rowIndex === 45 ? (
                    <div style={{ width: "100%" }}>
                      {rowIndex === 0 ? (
                        <div style={{ display: "flex" }}>
                          <div
                            style={{
                              width: "5%",
                              textAlign: "center",
                              backgroundColor: "rgb(5, 44, 131",
                              color: "white",
                              border: "1px solid black",
                            }}
                          >
                            R.br.
                          </div>
                          <div
                            style={{
                              width: "5%",
                              textAlign: "center",
                              backgroundColor: "rgb(5, 44, 131",
                              color: "white",
                              border: "1px solid black",
                            }}
                          >
                            h
                          </div>
                          <div
                            style={{
                              width: "11%",
                              textAlign: "center",
                              backgroundColor: "rgb(5, 44, 131",
                              color: "white",
                              border: "1px solid black",
                            }}
                          >
                            DOMAĆIN
                          </div>
                          <div
                            style={{
                              width: "11%",
                              textAlign: "center",
                              backgroundColor: "rgb(5, 44, 131",
                              color: "white",
                              border: "1px solid black",
                            }}
                          >
                            GOST
                          </div>
                          <div
                            style={{
                              width: "5%",
                              textAlign: "center",
                              backgroundColor: "rgb(5, 44, 131",
                              color: "white",
                              border: "1px solid black",
                            }}
                          >
                            ZEMLJA
                          </div>
                          <div
                            style={{
                              width: "5%",
                              textAlign: "center",
                              backgroundColor: "rgb(5, 44, 131",
                              color: "white",
                              border: "1px solid black",
                            }}
                          >
                            IGRA
                          </div>
                          <div
                            style={{
                              width: "5%",
                              textAlign: "center",
                              backgroundColor: "rgb(5, 44, 131",
                              color: "white",
                              border: "1px solid black",
                            }}
                          >
                            KVOTA
                          </div>
                          <td
                            style={{
                              width: "1.5%",
                              backgroundColor: "rgb(5, 44, 131)",
                              color: "white",
                              borderColor: "black",
                            }}
                          >
                            1
                          </td>
                          <td
                            style={{
                              width: "1.5%",
                              backgroundColor: "rgb(5, 44, 131)",
                              color: "white",
                              borderColor: "black",
                            }}
                          >
                            2
                          </td>
                          <td
                            style={{
                              width: "1.5%",
                              backgroundColor: "rgb(5, 44, 131)",
                              color: "white",
                              borderColor: "black",
                            }}
                          >
                            3
                          </td>
                          <td
                            style={{
                              width: "1.5%",
                              backgroundColor: "rgb(5, 44, 131)",
                              color: "white",
                              borderColor: "black",
                            }}
                          >
                            4
                          </td>
                          <td
                            style={{
                              width: "1.5%",
                              backgroundColor: "rgb(5, 44, 131)",
                              color: "white",
                              borderColor: "black",
                            }}
                          >
                            5
                          </td>
                          <td
                            style={{
                              width: "1.5%",
                              backgroundColor: "rgb(5, 44, 131)",
                              color: "white",
                              borderColor: "black",
                            }}
                          >
                            6
                          </td>
                          <td
                            style={{
                              width: "1.5%",
                              backgroundColor: "rgb(5, 44, 131)",
                              color: "white",
                              borderColor: "black",
                            }}
                          >
                            7
                          </td>
                          <td
                            style={{
                              width: "1.5%",
                              backgroundColor: "rgb(5, 44, 131)",
                              color: "white",
                              borderColor: "black",
                            }}
                          >
                            8
                          </td>
                          <td
                            style={{
                              width: "1.5%",
                              backgroundColor: "rgb(5, 44, 131)",
                              color: "white",
                              borderColor: "black",
                            }}
                          >
                            9
                          </td>
                          <td
                            style={{
                              width: "1.5%",
                              backgroundColor: "rgb(5, 44, 131)",
                              color: "white",
                              borderColor: "black",
                            }}
                          >
                            10
                          </td>
                          <td
                            style={{
                              width: "1.5%",
                              backgroundColor: "rgb(5, 44, 131)",
                              color: "white",
                              borderColor: "black",
                            }}
                          >
                            11
                          </td>
                          <td
                            style={{
                              width: "1.5%",
                              backgroundColor: "rgb(5, 44, 131)",
                              color: "white",
                              borderColor: "black",
                            }}
                          >
                            12
                          </td>
                          <td
                            style={{
                              width: "1.5%",
                              backgroundColor: "rgb(5, 44, 131)",
                              color: "white",
                              borderColor: "black",
                            }}
                          >
                            13
                          </td>
                          <td
                            style={{
                              width: "1.5%",
                              backgroundColor: "rgb(5, 44, 131)",
                              color: "white",
                              borderColor: "black",
                            }}
                          >
                            14
                          </td>
                          <td
                            style={{
                              width: "1.5%",
                              backgroundColor: "rgb(5, 44, 131)",
                              color: "white",
                              borderColor: "black",
                            }}
                          >
                            15
                          </td>
                          <td
                            style={{
                              width: "1.5%",
                              backgroundColor: "rgb(5, 44, 131)",
                              color: "white",
                              borderColor: "black",
                            }}
                          >
                            16
                          </td>
                          <td
                            style={{
                              width: "1.5%",
                              backgroundColor: "rgb(5, 44, 131)",
                              color: "white",
                              borderColor: "black",
                            }}
                          >
                            17
                          </td>
                          <td
                            style={{
                              width: "1.5%",
                              backgroundColor: "rgb(5, 44, 131)",
                              color: "white",
                              borderColor: "black",
                            }}
                          >
                            18
                          </td>
                          <td
                            style={{
                              width: "1.5%",
                              backgroundColor: "rgb(5, 44, 131)",
                              color: "white",
                              borderColor: "black",
                            }}
                          >
                            19
                          </td>
                          <td
                            style={{
                              width: "1.5%",
                              backgroundColor: "rgb(5, 44, 131)",
                              color: "white",
                              borderColor: "black",
                            }}
                          >
                            20
                          </td>
                          <td
                            style={{
                              width: "1.5%",
                              backgroundColor: "rgb(5, 44, 131)",
                              color: "white",
                              borderColor: "black",
                            }}
                          >
                            21
                          </td>
                          <td
                            style={{
                              width: "1.5%",
                              backgroundColor: "rgb(5, 44, 131)",
                              color: "white",
                              borderColor: "black",
                            }}
                          >
                            22
                          </td>
                          <td
                            style={{
                              width: "1.5%",
                              backgroundColor: "rgb(5, 44, 131)",
                              color: "white",
                              borderColor: "black",
                            }}
                          >
                            23
                          </td>
                          <td
                            style={{
                              width: "1.5%",
                              backgroundColor: "rgb(5, 44, 131)",
                              color: "white",
                              borderColor: "black",
                            }}
                          >
                            24
                          </td>
                          <td
                            style={{
                              width: "1.5%",
                              backgroundColor: "rgb(5, 44, 131)",
                              color: "white",
                              borderColor: "black",
                            }}
                          >
                            25
                          </td>
                          <td
                            style={{
                              width: "1.5%",
                              backgroundColor: "rgb(5, 44, 131)",
                              color: "white",
                              borderColor: "black",
                            }}
                          >
                            26
                          </td>
                          <td
                            style={{
                              width: "1.5%",
                              backgroundColor: "rgb(5, 44, 131)",
                              color: "white",
                              borderColor: "black",
                            }}
                          >
                            27
                          </td>
                          <td
                            style={{
                              width: "1.5%",
                              backgroundColor: "rgb(5, 44, 131)",
                              color: "white",
                              borderColor: "black",
                            }}
                          >
                            28
                          </td>
                          <td
                            style={{
                              width: "1.5%",
                              backgroundColor: "rgb(5, 44, 131)",
                              color: "white",
                              borderColor: "black",
                            }}
                          >
                            29
                          </td>
                          <td
                            style={{
                              width: "1.5%",
                              backgroundColor: "rgb(5, 44, 131)",
                              color: "white",
                              borderColor: "black",
                            }}
                          >
                            30
                          </td>
                          <td
                            style={{
                              width: "1.5%",
                              backgroundColor: "rgb(5, 44, 131)",
                              color: "white",
                              borderColor: "black",
                            }}
                          >
                            31
                          </td>
                        </div>
                      ) : (
                        ""
                      )}
                      {rowIndex === 1 ? (
                        <div
                          style={{
                            width: "100%",
                            textAlign: "center",
                            backgroundColor: "rgb(5, 44, 131",
                            color: "white",
                            border: "1px solid black",
                            fontSize: "22px",
                          }}
                        >
                          SKRAĆENI SISTEM 3/8 x2 PARA PO GRUPI I PO REDOSLEDU *
                          USLOV POGODITI 3/8 DUBLOVA
                        </div>
                      ) : (
                        ""
                      )}

                      {rowIndex === 20 ? (
                        <div
                          style={{
                            width: "100%",
                            textAlign: "center",
                            backgroundColor: "rgb(5, 44, 131",
                            color: "white",
                            border: "1px solid black",
                            fontSize: "22px",
                          }}
                        >
                          SKRAĆENI SISTEM 6/8 x3 PARA PO GRUPI I PO REDOSLEDU *
                          USLOV POGODITI 3/8 TRIPLOVA
                        </div>
                      ) : (
                        ""
                      )}

                      {rowIndex === 45 ? (
                        <div
                          style={{
                            width: "100%",
                            textAlign: "center",
                            backgroundColor: "#00c157",
                            color: "black",
                            border: "1px solid black",
                            fontSize: "22px",
                          }}
                        >
                          ZBIRKU SISTEMA ĆEMO KONTINUIRANO NADOGRAĐIVATI UNUTAR
                          MENIJA 'PREMIUM'
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  ) : (
                    row.map((col, colIndex) => {
                      if (colIndex == 2 || colIndex == 3) {
                        return (
                          <div
                            style={{ width: "11%", border: "1px solid gray" }}
                          >
                            <input
                              style={{ width: "95%", border: "none" }}
                            ></input>
                          </div>
                        );
                      }
                      if (
                        colIndex == 0 ||
                        colIndex == 1 ||
                        colIndex == 4 ||
                        colIndex == 5 ||
                        colIndex == 6
                      ) {
                        return (
                          <div
                            style={{ width: "5%", border: "1px solid gray" }}
                          >
                            <input
                              style={{ width: "95%", border: "none" }}
                            ></input>
                          </div>
                        );
                      } else {
                        if (
                          colIndex === 7 &&
                          (rowIndex === 10 ||
                            rowIndex === 11 ||
                            rowIndex === 14 ||
                            rowIndex === 15 ||
                            rowIndex === 18 ||
                            rowIndex === 19 ||
                            rowIndex === 21 ||
                            rowIndex === 22 ||
                            rowIndex === 23 ||
                            rowIndex === 24 ||
                            rowIndex === 25 ||
                            rowIndex === 26 ||
                            rowIndex === 27 ||
                            rowIndex === 28 ||
                            rowIndex === 29 ||
                            rowIndex === 30 ||
                            rowIndex === 31 ||
                            rowIndex === 32)
                        ) {
                          return (
                            <td
                              style={{
                                width: "1.5%",
                                backgroundColor: "#ffc700",
                                color: "black",
                                textAlign: "center",
                                fontSize: "12px",
                                fontWeight: "bolder",
                              }}
                              key={colIndex}
                            >
                              &lt;
                            </td>
                          );
                        } else if (
                          colIndex === 8 &&
                          (rowIndex === 2 ||
                            rowIndex === 3 ||
                            rowIndex === 4 ||
                            rowIndex === 5 ||
                            rowIndex === 16 ||
                            rowIndex === 17 ||
                            rowIndex === 21 ||
                            rowIndex === 22 ||
                            rowIndex === 23 ||
                            rowIndex === 30 ||
                            rowIndex === 31 ||
                            rowIndex === 32 ||
                            rowIndex === 33 ||
                            rowIndex === 34 ||
                            rowIndex === 35 ||
                            rowIndex === 42 ||
                            rowIndex === 43 ||
                            rowIndex === 44)
                        ) {
                          return (
                            <td
                              style={{
                                width: "1.5%",
                                backgroundColor: "#ffc700",
                                color: "black",
                                textAlign: "center",
                                fontSize: "12px",
                                fontWeight: "bolder",
                              }}
                              key={colIndex}
                            >
                              &lt;
                            </td>
                          );
                        } else if (
                          colIndex === 9 &&
                          (rowIndex === 6 ||
                            rowIndex === 7 ||
                            rowIndex === 8 ||
                            rowIndex === 9 ||
                            rowIndex === 12 ||
                            rowIndex === 13 ||
                            rowIndex === 21 ||
                            rowIndex === 22 ||
                            rowIndex === 23 ||
                            rowIndex === 33 ||
                            rowIndex === 34 ||
                            rowIndex === 35 ||
                            rowIndex === 36 ||
                            rowIndex === 37 ||
                            rowIndex === 38 ||
                            rowIndex === 39 ||
                            rowIndex === 40 ||
                            rowIndex === 41)
                        ) {
                          return (
                            <td
                              style={{
                                width: "1.5%",
                                backgroundColor: "#ffc700",
                                color: "black",
                                textAlign: "center",
                                fontSize: "12px",
                                fontWeight: "bolder",
                              }}
                              key={colIndex}
                            >
                              &lt;
                            </td>
                          );
                        } else if (
                          colIndex === 10 &&
                          (rowIndex === 4 ||
                            rowIndex === 5 ||
                            rowIndex === 8 ||
                            rowIndex === 9 ||
                            rowIndex === 18 ||
                            rowIndex === 19 ||
                            rowIndex === 24 ||
                            rowIndex === 25 ||
                            rowIndex === 26 ||
                            rowIndex === 27 ||
                            rowIndex === 28 ||
                            rowIndex === 29 ||
                            rowIndex === 33 ||
                            rowIndex === 34 ||
                            rowIndex === 35 ||
                            rowIndex === 42 ||
                            rowIndex === 43 ||
                            rowIndex === 44)
                        ) {
                          return (
                            <td
                              style={{
                                width: "1.5%",
                                backgroundColor: "#ffc700",
                                color: "black",
                                textAlign: "center",
                                fontSize: "12px",
                                fontWeight: "bolder",
                              }}
                              key={colIndex}
                            >
                              &lt;
                            </td>
                          );
                        } else if (
                          colIndex === 11 &&
                          (rowIndex === 2 ||
                            rowIndex === 3 ||
                            rowIndex === 6 ||
                            rowIndex === 7 ||
                            rowIndex === 14 ||
                            rowIndex === 15 ||
                            rowIndex === 24 ||
                            rowIndex === 25 ||
                            rowIndex === 26 ||
                            rowIndex === 27 ||
                            rowIndex === 28 ||
                            rowIndex === 29 ||
                            rowIndex === 36 ||
                            rowIndex === 37 ||
                            rowIndex === 38 ||
                            rowIndex === 39 ||
                            rowIndex === 40 ||
                            rowIndex === 41)
                        ) {
                          return (
                            <td
                              style={{
                                width: "1.5%",
                                backgroundColor: "#ffc700",
                                color: "black",
                                textAlign: "center",
                                fontSize: "12px",
                                fontWeight: "bolder",
                              }}
                              key={colIndex}
                            >
                              &lt;
                            </td>
                          );
                        } else if (
                          colIndex === 12 &&
                          (rowIndex === 10 ||
                            rowIndex === 11 ||
                            rowIndex === 12 ||
                            rowIndex === 13 ||
                            rowIndex === 16 ||
                            rowIndex === 17 ||
                            rowIndex === 30 ||
                            rowIndex === 31 ||
                            rowIndex === 32 ||
                            rowIndex === 36 ||
                            rowIndex === 37 ||
                            rowIndex === 38 ||
                            rowIndex === 39 ||
                            rowIndex === 40 ||
                            rowIndex === 41 ||
                            rowIndex === 42 ||
                            rowIndex === 43 ||
                            rowIndex === 44)
                        ) {
                          return (
                            <td
                              style={{
                                width: "1.5%",
                                backgroundColor: "#ffc700",
                                color: "black",
                                textAlign: "center",
                                fontSize: "12px",
                                fontWeight: "bolder",
                              }}
                              key={colIndex}
                            >
                              &lt;
                            </td>
                          );
                        } else if (
                          colIndex === 13 &&
                          (rowIndex === 2 ||
                            rowIndex === 3 ||
                            rowIndex === 8 ||
                            rowIndex === 9 ||
                            rowIndex === 10 ||
                            rowIndex === 11)
                        ) {
                          return (
                            <td
                              style={{
                                width: "1.5%",
                                backgroundColor: "#ffc700",
                                color: "black",
                                textAlign: "center",
                                fontSize: "12px",
                                fontWeight: "bolder",
                              }}
                              key={colIndex}
                            >
                              &lt;
                            </td>
                          );
                        } else if (
                          colIndex === 14 &&
                          (rowIndex === 4 ||
                            rowIndex === 5 ||
                            rowIndex === 12 ||
                            rowIndex === 13 ||
                            rowIndex === 14 ||
                            rowIndex === 15)
                        ) {
                          return (
                            <td
                              style={{
                                width: "1.5%",
                                backgroundColor: "#ffc700",
                                color: "black",
                                textAlign: "center",
                                fontSize: "12px",
                                fontWeight: "bolder",
                              }}
                              key={colIndex}
                            >
                              &lt;
                            </td>
                          );
                        } else if (
                          colIndex === 15 &&
                          (rowIndex === 6 ||
                            rowIndex === 7 ||
                            rowIndex === 16 ||
                            rowIndex === 17 ||
                            rowIndex === 18 ||
                            rowIndex === 19)
                        ) {
                          return (
                            <td
                              style={{
                                width: "1.5%",
                                backgroundColor: "#ffc700",
                                color: "black",
                                textAlign: "center",
                                fontSize: "12px",
                                fontWeight: "bolder",
                              }}
                              key={colIndex}
                            >
                              &lt;
                            </td>
                          );
                        } else if (
                          colIndex === 16 &&
                          (rowIndex === 8 ||
                            rowIndex === 9 ||
                            rowIndex === 14 ||
                            rowIndex === 15 ||
                            rowIndex === 16 ||
                            rowIndex === 17)
                        ) {
                          return (
                            <td
                              style={{
                                width: "1.5%",
                                backgroundColor: "#ffc700",
                                color: "black",
                                textAlign: "center",
                                fontSize: "12px",
                                fontWeight: "bolder",
                              }}
                              key={colIndex}
                            >
                              &lt;
                            </td>
                          );
                        } else if (
                          colIndex === 17 &&
                          (rowIndex === 10 ||
                            rowIndex === 11 ||
                            rowIndex === 7 ||
                            rowIndex === 6 ||
                            rowIndex === 5 ||
                            rowIndex === 4)
                        ) {
                          return (
                            <td
                              style={{
                                width: "1.5%",
                                backgroundColor: "#ffc700",
                                color: "black",
                                textAlign: "center",
                                fontSize: "12px",
                                fontWeight: "bolder",
                              }}
                              key={colIndex}
                            >
                              &lt;
                            </td>
                          );
                        } else if (
                          colIndex === 18 &&
                          (rowIndex === 2 ||
                            rowIndex === 3 ||
                            rowIndex === 12 ||
                            rowIndex === 13 ||
                            rowIndex === 18 ||
                            rowIndex === 19)
                        ) {
                          return (
                            <td
                              style={{
                                width: "1.5%",
                                backgroundColor: "#ffc700",
                                color: "black",
                                textAlign: "center",
                                fontSize: "12px",
                                fontWeight: "bolder",
                              }}
                              key={colIndex}
                            >
                              &lt;
                            </td>
                          );
                        } else {
                          return (
                            <td style={{ width: "1.5%" }} key={colIndex}></td>
                          );
                        }
                      }
                    })
                  )}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default SrkaceniGrupisaniSistemi;
