import React from 'react'
import classes from './Tabele.module.css'
import { IoStarSharp } from 'react-icons/io5'
import { useNavigate } from 'react-router-dom'
import { IoMdArrowDropleft } from 'react-icons/io'

const Tabele = () => {
  const navigate = useNavigate()

  return (
    <div className={classes.tablesPageContainer}>
      <button
        className={classes.backButton}
        onClick={() => navigate('/mister_tipster')}
      >
        <IoMdArrowDropleft />
        <span>NAZAD</span>
      </button>
      <div className={classes.tablesTitleDiv}>
        <IoStarSharp />
        OTVORI I OSVOJI
        <IoStarSharp />
      </div>
      <button
        onClick={() => {
          navigate('/mister_tipster/tabele/tabela-ranga?page=1')
        }}
        className={classes.tableNavBtn}
      >
        TABELA RANGA
      </button>
      <button
        onClick={() => {
          navigate('/mister_tipster/tabele/mesecna-tabela?page=1')
        }}
        className={classes.tableNavBtn}
      >
        MESEČNA TABELA
      </button>
      <button
        onClick={() => {
          navigate('/mister_tipster/tabele/godisnja-tabela?page=1')
        }}
        className={classes.tableNavBtn}
      >
        GODIŠNJA TABELA
      </button>
      <button
        onClick={() => {
          navigate('/mister_tipster/tabele/globalna-tabela?page=1')
        }}
        className={classes.tableNavBtn}
      >
        GLOBALNA TABELA
      </button>
      <div className={classes.tigerImgWrapper}>
        <img src='/tiger.webp' alt='Tiger' />
      </div>
    </div>
  )
}

export default Tabele
