import { useResolvedPath } from "react-router-dom";
import Fibonacci from "../betKalkulatori/kalkulatoriStrategije/Fibonacci";
import FudbalSveIgre from "../betKalkulatori/kalkulatoriProcene/FudbalSveIgre";
import ProcenaTacnogBrojaGolova from "../betKalkulatori/kalkulatoriProcene/ProcenaTacnogBrojaGolova";
import ProcenaTacnogRezultata from "../betKalkulatori/kalkulatoriProcene/ProcenaTacnogRezultata";
import LiveInteraktiv from "../betKalkulatori/kalkulatoriProcene/LiveInteraktiv";
import Kosarka from "../betKalkulatori/kalkulatoriProcene/Kosarka";
import KosarkaPoeniIgraca from "../betKalkulatori/kalkulatoriProcene/KosarkaPoeniIgraca";
import Hokej from "../betKalkulatori/kalkulatoriProcene/Hokej";
import AmerickiFudbal from "../betKalkulatori/kalkulatoriProcene/AmerickiFudbal";
import Bejzbol from "../betKalkulatori/kalkulatoriProcene/Bejzbol";
import StoniTenis from "../betKalkulatori/kalkulatoriProcene/StoniTenis";
import Tenis from "../betKalkulatori/kalkulatoriProcene/Tenis";
import Vaterpolo from "../betKalkulatori/kalkulatoriProcene/Vaterpolo";
import Rukomet from "../betKalkulatori/kalkulatoriProcene/Rukomet";
import Badminton from "../betKalkulatori/kalkulatoriProcene/Badminton";
import Odbojka from "../betKalkulatori/kalkulatoriProcene/Odbojka";
import OdbojkaNaPesku from "../betKalkulatori/kalkulatoriProcene/OdbojkaNaPesku";
import Futsal from "../betKalkulatori/kalkulatoriProcene/Futsal";
import Boks from "../betKalkulatori/kalkulatoriProcene/Boks";
import Mma from "../betKalkulatori/kalkulatoriProcene/Mma";
import Bilijar from "../betKalkulatori/kalkulatoriProcene/Bilijar";
import Esports from "../betKalkulatori/kalkulatoriProcene/Esports";
import Pikado from "../betKalkulatori/kalkulatoriProcene/Pikado";
import ProjekcijaUtakmice from "../betKalkulatori/kalkulatoriStatistike/ProjekcijaUtakmice";
import Tradicija from "../betKalkulatori/kalkulatoriStatistike/Tradicija";
import PoredenjeNizova from "../betKalkulatori/kalkulatoriStatistike/PoredenjeNizova";
import StatistikaSudija from "../betKalkulatori/kalkulatoriStatistike/StatistikaSudija";
import PoredenjeTimovaFudbal from "../betKalkulatori/kalkulatoriStatistike/PoredenjeTimovaFudbal";
import PoredenjeGolmanaFudbal from "../betKalkulatori/kalkulatoriStatistike/PoredenjeGolmanaFudbal";
import PoredenjeIgracaFudbal from "../betKalkulatori/kalkulatoriStatistike/PoredenjeIgracaFudbal";
import PoredenjeIgracaKosarka from "../betKalkulatori/kalkulatoriStatistike/PoredenjeIgracaKosarka";
import NivelacijaKvota from "../betKalkulatori/kalkulatoriKvota/NivelacijaKvota";
import ProcenaVrednostiTiketa from "../betKalkulatori/kalkulatoriKvota/ProcenaVrednostiTiketa";
import OdbranaTiketa from "../betKalkulatori/kalkulatoriKvota/OdbranaTiketa";
import PoredenjeKvotaMarza from "../betKalkulatori/kalkulatoriKvota/PoredenjeKvotaMarza";
import Dalembert from "../betKalkulatori/kalkulatoriStrategije/Dalembert";
import Paroli from "../betKalkulatori/kalkulatoriStrategije/Paroli";
import DevetMagicnihLinija from "../betKalkulatori/kalkulatoriStrategije/DevetMagicnihLinija";
import PozitivnaLinija from "../betKalkulatori/kalkulatoriProgresije/PozitivnaLinija";
import PozitivnaProgresija from "../betKalkulatori/kalkulatoriProgresije/PozitivnaProgresija";
import ArbitrazniKalkulator from "../betKalkulatori/kalkulatoriProgresije/ArbitrazniKalkulator";
import NegativnaProgresija from "../betKalkulatori/kalkulatoriProgresije/NegativnaProgresija";
import IsplativeKvoteIBrojKombinacija from "../betKalkulatori/kalkulatoriSistema/IsplativeKvoteIBrojKombinacija";
import TablicaGrupisanihBetkoSistema from "../betKalkulatori/kalkulatoriSistema/TablicaGrupisanihBetkoSistema";
import TablicaUslovnihKvotaIBrojKombinacija from "../betKalkulatori/kalkulatoriSistema/TablicaUslovnihKvotaIBrojKombinacija";
import PuniSistemi from "../betKalkulatori/kalkulatoriSistema/PuniSistemi";
import PuniGrupisaniBetkoSistemi from "../betKalkulatori/kalkulatoriSistema/PuniGrupisaniBetkoSistemi";
import SistemiUSistemu from "../betKalkulatori/kalkulatoriSistema/SistemiUSistemu";
import SkraceniSistemi from "../betKalkulatori/kalkulatoriSistema/SkraceniSistemi";
import SrkaceniGrupisaniSistemi from "../betKalkulatori/kalkulatoriSistema/SrkaceniGrupisaniSistemi";
import JednotrecinskiSistemi from "../betKalkulatori/kalkulatoriSistema/JednotrecinskiSistemi";
import ProcentualniBetkoSistemi from "../betKalkulatori/kalkulatoriSistema/ProcentualniBetkoSistemi";
import ViseuslovniSistemi from "../betKalkulatori/kalkulatoriSistema/ViseuslovniSistemi";
import ComboBombo from "../betKalkulatori/kalkulatoriSistema/ComboBombo";
import Zatvaranje from "../betKalkulatori/kalkulatoriSistema/Zatvaranje";

const BetKalkulatoriRute = ({ path }) => {
  const url = useResolvedPath(path).pathname;
  const decodedURL = decodeURIComponent(url);

  return (
    <>
      {/* kalkulatori procene */}
      {decodedURL === "/bet_kalkulatori/fudbal_-_sve_igre" && <FudbalSveIgre />}
      {decodedURL === "/bet_kalkulatori/procena_tačnog_broja_golova" && (
        <ProcenaTacnogBrojaGolova />
      )}
      {decodedURL === "/bet_kalkulatori/procena_tačnog_rezultata" && (
        <ProcenaTacnogRezultata />
      )}
      {decodedURL === "/bet_kalkulatori/live_interaktiv" && <LiveInteraktiv />}
      {decodedURL === "/bet_kalkulatori/košarka" && <Kosarka />}
      {decodedURL === "/bet_kalkulatori/košarka_poeni_igrača" && (
        <KosarkaPoeniIgraca />
      )}
      {decodedURL === "/bet_kalkulatori/hokej" && <Hokej />}
      {decodedURL === "/bet_kalkulatori/američki_fudbal" && <AmerickiFudbal />}
      {decodedURL === "/bet_kalkulatori/bejzbol" && <Bejzbol />}
      {decodedURL === "/bet_kalkulatori/stoni_tenis" && <StoniTenis />}
      {decodedURL === "/bet_kalkulatori/tenis" && <Tenis />}
      {decodedURL === "/bet_kalkulatori/vaterpolo" && <Vaterpolo />}
      {decodedURL === "/bet_kalkulatori/rukomet" && <Rukomet />}
      {decodedURL === "/bet_kalkulatori/badminton" && <Badminton />}
      {decodedURL === "/bet_kalkulatori/odbojka" && <Odbojka />}
      {decodedURL === "/bet_kalkulatori/odbojka_na_pesku" && <OdbojkaNaPesku />}
      {decodedURL === "/bet_kalkulatori/futsal" && <Futsal />}
      {decodedURL === "/bet_kalkulatori/boks" && <Boks />}
      {decodedURL === "/bet_kalkulatori/mma" && <Mma />}
      {decodedURL === "/bet_kalkulatori/bilijar" && <Bilijar />}
      {decodedURL === "/bet_kalkulatori/esports" && <Esports />}
      {decodedURL === "/bet_kalkulatori/pikado" && <Pikado />}
      {/* kalkulatori statistike */}
      {decodedURL === "/bet_kalkulatori/projekcija_utakmice" && (
        <ProjekcijaUtakmice />
      )}
      {decodedURL === "/bet_kalkulatori/tradicija" && <Tradicija />}
      {decodedURL === "/bet_kalkulatori/poređenje_nizova" && (
        <PoredenjeNizova />
      )}
      {decodedURL === "/bet_kalkulatori/statistika_sudija" && (
        <StatistikaSudija />
      )}
      {decodedURL === "/bet_kalkulatori/poređenje_timova_-_fudbal" && (
        <PoredenjeTimovaFudbal />
      )}
      {decodedURL === "/bet_kalkulatori/poređenje_golmana_-_fudbal" && (
        <PoredenjeGolmanaFudbal />
      )}
      {decodedURL === "/bet_kalkulatori/poređenje_igrača_-_fudbal" && (
        <PoredenjeIgracaFudbal />
      )}
      {decodedURL === "/bet_kalkulatori/poređenje_igrača_-_košarka" && (
        <PoredenjeIgracaKosarka />
      )}
      {/* kalkulatori kvota */}
      {decodedURL === "/bet_kalkulatori/nivelacija_kvota" && (
        <NivelacijaKvota />
      )}
      {decodedURL === "/bet_kalkulatori/procena_vrednosti_tiketa" && (
        <ProcenaVrednostiTiketa />
      )}
      {decodedURL === "/bet_kalkulatori/odbrana_tiketa" && <OdbranaTiketa />}
      {decodedURL === "/bet_kalkulatori/poređenje_kvota_-_marža" && (
        <PoredenjeKvotaMarza />
      )}

      {/* kalkulatori strategije */}
      {decodedURL === "/bet_kalkulatori/fibonacci" && <Fibonacci />}
      {decodedURL === "/bet_kalkulatori/d_alembert" && <Dalembert />}
      {decodedURL === "/bet_kalkulatori/paroli" && <Paroli />}
      {decodedURL === "/bet_kalkulatori/devet_magičnih_linija" && (
        <DevetMagicnihLinija />
      )}

      {/* kalkulatori progresije */}
      {decodedURL === "/bet_kalkulatori/pozitivna_linija" && (
        <PozitivnaLinija />
      )}
      {decodedURL === "/bet_kalkulatori/pozitivna_progresija" && (
        <PozitivnaProgresija />
      )}
      {decodedURL === "/bet_kalkulatori/arbitražni_kalkulator" && (
        <ArbitrazniKalkulator />
      )}
      {decodedURL === "/bet_kalkulatori/negativna_progresija" && (
        <NegativnaProgresija />
      )}

      {/* kalkulatori sistema */}
      {decodedURL === "/bet_kalkulatori/isplative_kvote_i_broj_kombinacija" && (
        <IsplativeKvoteIBrojKombinacija />
      )}
      {decodedURL === "/bet_kalkulatori/tablica_grupisanih_betko_sistema" && (
        <TablicaGrupisanihBetkoSistema />
      )}
      {decodedURL ===
        "/bet_kalkulatori/tablica_uslovnih_kvota_i_broj_kombinacija" && (
        <TablicaUslovnihKvotaIBrojKombinacija />
      )}
      {decodedURL === "/bet_kalkulatori/puni_sistemi" && <PuniSistemi />}
      {decodedURL === "/bet_kalkulatori/puni_grupisani_betko_sistemi" && (
        <PuniGrupisaniBetkoSistemi />
      )}
      {decodedURL === "/bet_kalkulatori/sistemi_u_sistemu" && (
        <SistemiUSistemu />
      )}
      {decodedURL === "/bet_kalkulatori/skraćeni_sistemi" && (
        <SkraceniSistemi />
      )}
      {decodedURL === "/bet_kalkulatori/skraćeni_grupisani_sistemi" && (
        <SrkaceniGrupisaniSistemi />
      )}
      {decodedURL === "/bet_kalkulatori/jednotrećinski_sistemi" && (
        <JednotrecinskiSistemi />
      )}
      {decodedURL === "/bet_kalkulatori/procentualni_betko_sistemi" && (
        <ProcentualniBetkoSistemi />
      )}
      {decodedURL === "/bet_kalkulatori/višeuslovni_sistemi" && (
        <ViseuslovniSistemi />
      )}
      {decodedURL === "/bet_kalkulatori/combo_bombo" && <ComboBombo />}
      {decodedURL === "/bet_kalkulatori/zatvaranje" && <Zatvaranje />}
    </>
  );
};

export default BetKalkulatoriRute;
