import React, { useContext, useEffect, useState } from 'react'
import classes from './MisterTipsterRangTable.module.css'
import { FaSearch } from 'react-icons/fa'
import { Select } from 'antd'
import { useLocation, useNavigate } from 'react-router-dom'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { IoMdArrowDropleft } from 'react-icons/io'
import Pagination from './Pagination'
import Spinner from '../components/Spinner'
import AuthContext from '../context/AuthProvider'
import getUserName from '../utils/GetUserName'
const { Option } = Select
dayjs.extend(utc)

const MisterTipsterRangTable = ({
  data,
  period,
  intervals,
  searchTerm,
  setSearchTerm,
  isLoading,
}) => {
  const navigate = useNavigate()
  const SERVER_URL = process.env.REACT_APP_SERVER_URL
  const searchParams = new URLSearchParams(window.location.search)
  const pageParams = searchParams.get('page')
  const periodParams = searchParams.get('period')
  const [selectedPeriod, setSelectedPeriod] = useState(periodParams)
  const { auth } = useContext(AuthContext)
  const [currentPage, setCurrentPage] = useState(
    pageParams ? parseInt(pageParams) : 1
  )
  const [from, setFrom] = useState(null)
  const locationState = useLocation().state

  useEffect(() => {
    locationState?.from && setFrom(locationState?.from)
  }, [locationState])

  const getRank = (position) => Math.ceil(position / 10)

  // QUICK FIX !!! //
  useEffect(() => {
    setSelectedPeriod(periodParams)
  }, [periodParams])

  const formatDate = (date) => {
    const [year, month, day] = date.split('-')
    return `${day}.${month}.${year}.`
  }

  // Update URL when currentPage or selectedPeriod changes
  useEffect(() => {
    const params = new URLSearchParams()
    if (currentPage) params.set('page', currentPage)
    if (selectedPeriod) params.set('period', selectedPeriod)

    navigate(`?${params.toString()}`, { replace: true })
  }, [currentPage, selectedPeriod, navigate])

  const paginationProps = {
    setCurrentPage: setCurrentPage,
    limit: 10,
    currentPage: currentPage,
    totalCount: data?.totalCount,
    totalPages: data?.pagesCount,
    buttonLimit: 3,
  }

  return (
    <div className={classes.container}>
      <div className={classes.sticky}>
        <button
          className={classes.backButton}
          onClick={() => navigate(from || '/mister_tipster/tabele')}
        >
          <IoMdArrowDropleft />
          <span>NAZAD</span>
        </button>
        <div className={classes.stickyInner}>
          <div className={classes.totalHeaderTotalRank}>UK. RANG PLASMAN</div>
          <div className={classes.xx}>
            <div className={classes.componentHeader}>
              <button
                className={classes.searchButton}
                onClick={() => console.log('searchTerm')}
              >
                <FaSearch />
              </button>
              <input
                type='text'
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
              <button
                className={classes.otherTableBtn}
                onClick={() =>
                  navigate(`/mister_tipster/tabele/globalna-tabela?page=1`)
                }
              >
                KLIKNI I POGLEDAJ <br />
                GLOBALNU TABELU
              </button>
            </div>
            <div
              className={`${classes.tableTitle} ${
                period === 'global' ? classes.isGlobalTable : ''
              }`}
            >
              <button
                className={classes.gameDescription}
                onClick={() => navigate(`/mister_tipster/opis-igre`)}
              >
                OPIS IGRE
              </button>
              <h3>RANG TABELA</h3>
              <Select
                style={{ width: 250 }}
                className={classes.tablePeriodPicker}
                onChange={(curr) => setSelectedPeriod(curr)}
                value={selectedPeriod}
              >
                {intervals?.map((interval) => (
                  <Option
                    key={`${interval.startDate}-${interval.endDate}`}
                    value={`${interval.startDate}-${interval.endDate}`}
                  >
                    {formatDate(interval.startDate)}-{' '}
                    {formatDate(interval.endDate)}
                  </Option>
                ))}
              </Select>
            </div>
            <div className={classes.tableHeader}>
              <div className={classes.tableHeaderRank}>PLASMAN</div>
              <div className={classes.tableHeaderTeam}>DRES</div>
              <div className={classes.tableHeaderPlayer}>IGRAČ</div>
              <div className={classes.tableHeaderTotalScore}>ZBIR.KV.</div>
            </div>
          </div>
        </div>

        {data?.currentUser?._id &&
          data?.paginatedData?.length > 0 &&
          currentPage === 1 && (
            <>
              <div
                className={`${classes.rankSeparator} ${classes.currentSeparator}`}
              >
                RANG {getRank(data?.currentPosition)}
              </div>
              <div
                className={`${classes.tableItem} ${classes.currentUserTableItem}`}
              >
                <div className={classes.totalItemTotalRank}>
                  {data?.currentPosition}.
                </div>
                <div className={classes.tableItemRank}>
                  {data?.currentPosition - (currentPage - 1) * 10}.
                </div>
                <div className={classes.tableItemTeam}>
                  <img
                    src={
                      data?.currentUser?.team?.teamImage?.url
                        ? `${SERVER_URL}/${data?.currentUser?.team?.teamImage?.url}`
                        : '/no_team_image.png'
                    }
                    alt='team'
                  />
                </div>
                <div
                  className={`${classes.tableItemPlayer} ${classes.tableCurrentUser}`}
                  onClick={() =>
                    navigate(
                      `/mister_tipster/profil?user=${data?.currentUser?._id}`
                    )
                  }
                >
                  <div> </div>
                  <span>{getUserName(data?.currentUser?.userName)} </span>

                  <button
                    onClick={(e) => {
                      e.stopPropagation()
                      navigate('/moj_nalog')
                    }}
                    className={classes.editBtn}
                  >
                    PROMENI IME
                  </button>
                </div>
                <div className={classes.tableItemTotalScore}>
                  {data?.currentUser?.tablePoints?.rankPoints || 0}
                </div>
              </div>
            </>
          )}
      </div>
      {isLoading ? (
        <div className={classes.spinner}>
          <Spinner />
        </div>
      ) : (
        <div className={classes.tableBody}>
          {data?.paginatedData?.map((user, index) => (
            <div className={classes.tableItemWrapper} key={user?._id}>
              {index === 0 && (
                <div className={classes.rankSeparator}>RANG {currentPage}</div>
              )}
              <div className={classes.tableItem}>
                <div className={classes.totalItemTotalRank}>
                  {(currentPage - 1) * 10 + index + 1}.
                </div>

                <div
                  className={`${classes.tableItemRank} ${
                    currentPage % 2 === 0 ? classes.tableItemAccent : ''
                  }`}
                >
                  {index + 1}.
                </div>
                <div className={classes.tableItemTeam}>
                  <img
                    src={
                      user?.team?.teamImage?.url
                        ? `${SERVER_URL}/${user?.team?.teamImage?.url}`
                        : '/no_team_image.png'
                    }
                    alt='team'
                  />
                </div>
                <div
                  className={`${classes.tableItemPlayer} ${
                    currentPage % 2 === 0 ? classes.tableItemAccent : ''
                  }`}
                  onClick={() => {
                    navigate(
                      `/mister_tipster${
                        auth?._id !== user?._id
                          ? `/profil?user=${user._id}`
                          : ''
                      }`
                    )
                  }}
                >
                  {getUserName(user?.userName)}
                </div>
                <div className={classes.tableItemTotalScore}>
                  {user?.tablePoints?.rankPoints || 0}
                </div>
              </div>
            </div>
          ))}
          {data?.paginatedData?.length > 0 ? (
            <div className={classes.pagination}>
              <Pagination {...paginationProps} />
            </div>
          ) : (
            <p className={classes.noData}>Nema podataka za izabrani period!</p>
          )}
        </div>
      )}
    </div>
  )
}

export default MisterTipsterRangTable
