import React, {useState, createContext, useMemo} from "react";

const AppContext = createContext({})

export const AppProvider = ({children}) => {
  const [openSemafori, setOpenSemafori] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)
  const [openLiveComments, setOpenLiveComments] = useState(false)

  const values = useMemo(() => {
    return {
      currentPage,
      setCurrentPage,
      openSemafori,
      setOpenSemafori,
      openLiveComments,
      setOpenLiveComments,
    }
  },[
    currentPage,
    setCurrentPage,
    openSemafori,
    setOpenSemafori,
    openLiveComments,
    setOpenLiveComments,
  ])

  return (
    <AppContext.Provider value={values}>
      {children}
    </AppContext.Provider>
  )
}

export default AppContext