import React, { useEffect, useState } from 'react'
import classes from './BetkoShopHome.module.css'
import ProductCard from '../../components/BetkoShop/ProductCard'
import { useNavigate } from 'react-router-dom'
import BetkoShopLayout from './BetkoShopLayout'
import axios from 'axios'
import CardPlaceHolder from '../../components/BetkoShop/CardPlaceHolder'

const SERVER_URL = process.env.REACT_APP_SERVER_URL

const BetkoShopHome = () => {
  const [popularProducts, setPopularProducts] = useState(null)
  const navigate = useNavigate()

  useEffect(() => {
    const getAllProducts = async () => {
      try {
        const response = await axios.get(
          `${SERVER_URL}/get-all-popular-products`
        )
        setPopularProducts(response.data)
      } catch (err) {
        console.error('Popular products error:', err)
      }
    }

    getAllProducts()
  }, [])

  return (
    <BetkoShopLayout>
      <div className={classes.shopHomePage}>
        <div className={classes.featuredProducts}>
          <div className={classes.featuredShop}>
            <h2>SHOP</h2>
            {popularProducts?.shopPopularProduct?._id	 ? (
              <ProductCard
                isHomePage={true}
                product={popularProducts?.shopPopularProduct}
              />
            ) : (
              <CardPlaceHolder />
            )}
          </div>
          <div className={classes.featuredAuctions}>
            <h2>LICITACIJE</h2>

            {popularProducts?.auctionPopularProduct?._id ? (
              <ProductCard
                isHomePage={true}
                product={popularProducts?.auctionPopularProduct}
              />
            ) : (
              <CardPlaceHolder />
            )}
          </div>
          <div className={classes.featuredSportTurist}>
            <h2>TURIST</h2>

            {popularProducts?.sportTouristPopularProduct?._id ? (
              <ProductCard
                isHomePage={true}
                product={popularProducts?.sportTouristPopularProduct}
              />
            ) : (
              <CardPlaceHolder />
            )}
          </div>
        </div>
        <div className={classes.sectionsMenu}>
          <div className={classes.shopSection}>
            <h2>SHOP</h2>
            <div className={classes.sectionButtonsWrapper}>
              <button
                onClick={() =>
                  navigate(
                    '/betko_shop/uslovi?menu=shop&title=uslovi-za-korisnike'
                  )
                }
              >
                USLOVI ZA KORISNIKE
              </button>
              <button
                onClick={() =>
                  navigate('/betko_shop/uslovi?menu=shop&title=saznaj-vise')
                }
              >
                SAZNAJ VIŠE
              </button>
              <button
                onClick={() =>
                  navigate(
                    '/betko_shop/uslovi?menu=shop&title=uslovi-za-ponudjace'
                  )
                }
              >
                USLOVI ZA PONUĐAČE
              </button>
            </div>
          </div>
          <div className={classes.auctionsSection}>
            <h2>LICITACIJE</h2>
            <div className={classes.sectionButtonsWrapper}>
              <button
                onClick={() =>
                  navigate(
                    '/betko_shop/uslovi?menu=licitacije&title=uslovi-za-korisnike'
                  )
                }
              >
                USLOVI ZA KORISNIKE
              </button>
              <button
                onClick={() =>
                  navigate(
                    '/betko_shop/uslovi?menu=licitacije&title=saznaj-vise'
                  )
                }
              >
                SAZNAJ VIŠE
              </button>
              <button
                onClick={() =>
                  navigate(
                    '/betko_shop/uslovi?menu=licitacije&title=uslovi-za-ponudjace'
                  )
                }
              >
                USLOVI ZA PONUĐAČE
              </button>
            </div>
          </div>
          <div className={classes.sportTouristSection}>
            <h2>SPORT TURIST</h2>
            <div className={classes.sectionButtonsWrapper}>
              <button
                onClick={() =>
                  navigate(
                    '/betko_shop/uslovi?menu=sport-turist&title=uslovi-za-korisnike'
                  )
                }
              >
                USLOVI ZA KORISNIKE
              </button>
              <button
                onClick={() =>
                  navigate(
                    '/betko_shop/uslovi?menu=sport-turist&title=saznaj-vise'
                  )
                }
              >
                SAZNAJ VIŠE
              </button>
              <button
                onClick={() =>
                  navigate(
                    '/betko_shop/uslovi?menu=sport-turist&title=uslovi-za-ponudjace'
                  )
                }
              >
                USLOVI ZA PONUĐAČE
              </button>
            </div>
          </div>
        </div>
      </div>
    </BetkoShopLayout>
  )
}

export default BetkoShopHome
