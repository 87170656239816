import classes from './infoNav.module.css'
import { useNavigate } from 'react-router-dom'
import { useContext, useState } from 'react'
import AuthContext from '../context/AuthProvider'
import AppContext from '../context/AppContext'
import { LuRefreshCw } from 'react-icons/lu'
import OpenAndConquerModal from './openAndConquer/OpenAndConquerModal'

const InfoNav = () => {
  const navigate = useNavigate()
  const { setLogin, auth } = useContext(AuthContext)
  const { openSemafori, setOpenSemafori } = useContext(AppContext)
  const [isModalOpenAndConquerOpen, setIsModalOpenAndConquerOpen] =
    useState(false)

  return (
    <div className={classes.container}>
      <div className={classes.navLeft}>
        <ul className={classes.infoNavUl}>
          <li
            onClick={() => {
              navigate('/bet_portal')
              setOpenSemafori(false)
            }}
            className={classes.infoNavLi}
          >
            BET PORTAL
          </li>
          <span className={classes.dot}>•</span>
          <li
            onClick={() => {
              navigate('/statistika')
              setOpenSemafori(false)
            }}
            className={classes.infoNavLi}
          >
            STATISTIKA
          </li>
          <span className={classes.dot}>•</span>
          <li
            onClick={() => {
              navigate('/bet_kurs_novo')
              setOpenSemafori(false)
            }}
            className={classes.infoNavLi}
            style={{ color: 'red' }}
          >
            PREMIUM
          </li>
          <span className={classes.dot}>•</span>
          <li
            onClick={() => navigate('/rezultati_uzivo')}
            className={classes.infoNavLi}
            style={{ color: 'blue' }}
          >
            REZULTATI UŽIVO
          </li>
          <span className={classes.dot}>•</span>
          <li className={`${classes.infoNavLi} ${classes.infoNavReload}`}>
            <button onClick={() => navigate(0)}>
              <LuRefreshCw />
              <span className='reload-title'>Osveži</span>
            </button>
          </li>
        </ul>
      </div>
      <div className={classes.navCenter}>
        <ul className={classes.infoNavUl}>
          <li
            onClick={() => {
              setIsModalOpenAndConquerOpen(true)
            }}
            className={classes.infoNavUlOtvoriIOsvoji}
          >
            OTVORI I OSVOJI
          </li>
          <li
            onClick={() => {
              navigate('/korisnicki_paketi')
              setOpenSemafori(false)
            }}
            className={classes.infoNavUlKorisnickiPaketi}
          >
            KORISNIČKI PAKETI
          </li>
          <li
            onClick={() => {
              navigate('/?page=1')
              setOpenSemafori(false)
            }}
            className={classes.infoNavUlBetkoZid}
          >
            BETKO INFO ZID
          </li>
          <li
            onClick={() => navigate('/betko_shop')}
            className={classes.infoNavUlOtvoriIOsvoji}
          >
            BETKO SHOP
          </li>
        </ul>
      </div>
      <div className={classes.navRight}>
        <ul className={classes.infoNavUl}>
          <li className={`${classes.infoNavLi} ${classes.infoNavReload}`}>
            <button onClick={() => navigate(0)}>
              <LuRefreshCw />
              <span className='reload-title'>Osveži</span>
            </button>
          </li>
          <span className={classes.dot}>•</span>
          <li
            style={{ display: 'flex', alignItems: 'center', gap: '5px' }}
            onClick={() => setOpenSemafori((prev) => !prev)}
            className={classes.infoNavLi}
          >
            <span style={{ color: 'blue' }}>SEMAFOR</span>
          </li>
          <span className={classes.dot}>•</span>

          <li
            onClick={() => {
              navigate('/info_blok')
              setOpenSemafori(false)
            }}
            className={classes.infoNavLi}
          >
            INFO BLOK
          </li>
          <span className={classes.dot}>•</span>
          <li
            onClick={() => {
              navigate('/vasi_predlozi')
              setOpenSemafori(false)
            }}
            className={classes.infoNavLi}
          >
            VAŠI PREDLOZI
          </li>
          <span className={classes.dot}>•</span>
          <li
            onClick={() => {
              navigate('/opsti_uslovi')
              setOpenSemafori(false)
            }}
            className={classes.infoNavLi}
          >
            OPŠTI USLOVI
          </li>
        </ul>
      </div>

      {openSemafori && (
        <div className={classes.popUpContainer}>
          <div className={classes.buttonsContainer}>
            <button
              onClick={() => {
                setOpenSemafori(false)
                if (auth && Object.keys(auth).length < 1) {
                  navigate('/registracija_i_logovanje')
                  setLogin(false)
                } else {
                  navigate('/moj_nalog')
                }
              }}
            >
              {auth && Object.keys(auth).length < 1
                ? 'REGISTRACIJA'
                : 'MOJ NALOG'}
            </button>
            <div>
              <button onClick={() => setOpenSemafori(false)}>
                <img src='/close-mini-icon.svg' width={40} height={40} />
              </button>
              <span>IZLAZ</span>
            </div>
            <button
              onClick={() => {
                setOpenSemafori(false)
                if (auth && Object.keys(auth).length < 1) {
                  navigate('/registracija_i_logovanje')
                  setLogin(false)
                } else {
                  navigate('/moj_nalog')
                }
              }}
            >
              {auth && Object.keys(auth).length < 1 ? 'LOGOVANJE' : 'MOJ NALOG'}
            </button>
          </div>

          <div style={{ margin: '5px 0px' }}>
            <button type='button'>
              <span>POGLEDAJTE</span>
              <span>
                SEM{' '}
                <img
                  style={{ marginBottom: '7px' }}
                  src='/green_star.svg'
                  width={30}
                  height={30}
                />{' '}
                FOR !
              </span>
            </button>
          </div>

          <div className={classes.popUpGridContainer}>
            <div>
              <button
                onClick={() => {
                  navigate('/info_blok/653f22fb52a098b10de97cd7')
                  setOpenSemafori(false)
                }}
              >
                <span>BETKO TOP</span>
                <span>PREDLOZI</span>
              </button>
            </div>
            <div>
              <button
                onClick={() => {
                  navigate('/info_blok/653f219b52a098b10de97c84')
                  setOpenSemafori(false)
                }}
              >
                <span>KLASIK</span>
                <span>SISTEMI</span>
              </button>
            </div>
            <div>
              <button
                onClick={() => {
                  navigate('/info_blok/653f11b752a098b10de979d7')
                  setOpenSemafori(false)
                }}
              >
                <span>BETKO</span>
                <span>SPECIJAL</span>
              </button>
            </div>
            <div>
              <button
                onClick={() => {
                  navigate('/info_blok/656e60f9b3cfb2837182da1b')
                  setOpenSemafori(false)
                }}
              >
                <span>KAMIKAZA</span>
                <span>PREDLOZI</span>
              </button>
            </div>
            <div>
              <button
                style={{ background: 'blue', borderColor: 'black' }}
                onClick={() => {
                  navigate('/info_blok/655d648ae0dc5f730f30aef8')
                  setOpenSemafori(false)
                }}
              >
                <span>KOD BETKA</span>
                <span>ZA DŽABE</span>
              </button>
            </div>
            <div>
              <button
                onClick={() => {
                  navigate('/info_blok/653f09bb52a098b10de9785c')
                  setOpenSemafori(false)
                }}
              >
                <span>KASKADER</span>
                <span>PREDLOZI</span>
              </button>
            </div>
            <div>
              <button
                onClick={() => {
                  navigate('/info_blok/653f112952a098b10de979bc')
                  setOpenSemafori(false)
                }}
              >
                <span>KREJZI</span>
                <span>SISTEMI</span>
              </button>
            </div>
            <div>
              <button
                onClick={() => {
                  navigate('/info_blok/653f0db152a098b10de9790a')
                  setOpenSemafori(false)
                }}
              >
                <span>BETKO</span>
                <span>LIVE PREDLOZI</span>
              </button>
            </div>
            <div>
              <button
                onClick={() => {
                  navigate('/info_blok/653f182c52a098b10de97aab')
                  setOpenSemafori(false)
                }}
              >
                <span>BETKO PREMIUM</span>
                <span>INFORMACIJE</span>
              </button>
            </div>
            <div>
              <button
                style={{ background: '#c00000', borderColor: '#0b0d24' }}
                onClick={() => {
                  navigate('/korisnicki_paketi')
                  setOpenSemafori(false)
                }}
              >
                <span>KORISNIČKI</span>
                <span>PAKETI</span>
              </button>
            </div>
            <div>
              <button
                onClick={() => {
                  navigate('/info_blok/656e60f9b3cfb2837182da1b')
                  setOpenSemafori(false)
                }}
              >
                <span>KAMIKAZA</span>
                <span>PREDLOZI</span>
              </button>
            </div>
            <div>
              <button
                onClick={() => {
                  navigate('/info_blok/653f1f4c52a098b10de97c0e')
                  setOpenSemafori(false)
                }}
              >
                <span>BETKO</span>
                <span>STRATEGIJE</span>
              </button>
            </div>
            <div>
              <button
                onClick={() => {
                  navigate('/info_blok/653f094752a098b10de97845')
                  setOpenSemafori(false)
                }}
              >
                <span>BETKO LIVE</span>
                <span>STRATEGIJE</span>
              </button>
            </div>
            <div>
              <button
                style={{ color: '#ffc000' }}
                onClick={() => {
                  navigate('/info_blok/65629574f4ca9761eb52c686')
                  setOpenSemafori(false)
                }}
              >
                <span>BETKO KVIZ</span>
                <span>OTVORI I OSVOJI</span>
              </button>
            </div>
            <div>
              <a
                onClick={() => {
                  setOpenSemafori(false)
                }}
                href='https://www.messenger.com/login.php?next=https%3A%2F%2Fwww.messenger.com%2Ft%2F100090673000468%2F'
                target='_blank'
                style={{ background: '#0b0d24', color: 'red' }}
              >
                <span>PITAJ BETKA</span>
              </a>
            </div>
          </div>
          <a
            className={classes.downloadContainer}
            href={
              auth?._id
                ? 'https://drive.google.com/drive/folders/1ROB8DtVQxaj-GWq5AvZv86VfXfP7wHon'
                : '/registracija_i_logovanje'
            }
            rel='noreferrer'
            target='_blank'
          >
            <span>PREUZMI BESPLATNO !</span>
          </a>
          <div
            className={`${classes.downloadContainer} ${classes.downloadInfo}`}
          >
            <p>
              DA BI STE PREUZELI BESPLATAN SADRŽAJ <br /> MORATE BITI ULOGOVANI
              (REGISTROVANI) NA SAJT/APLIKACIJU
            </p>
          </div>
        </div>
      )}
      <OpenAndConquerModal
        isOpen={isModalOpenAndConquerOpen}
        setIsOpen={setIsModalOpenAndConquerOpen}
      />
    </div>
  )
}

export default InfoNav
