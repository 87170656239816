import Grupa1 from "../components/Grupa1";
import Grupa28 from "../components/Grupa28";
import classes from "./tradicija.module.css";

const Tradicija = () => {
  return (
    <div className={classes.container}>
      <Grupa1
        imgText="TRADICIJA"
        imgBackgroundColorDark="#2257B9"
        imgBackgroundColorLight="#2D72EF"
        imgFontColor="#FFFF00"
        label1="VREME POČETKA"
        label2="ZEMLJA"
        label3="DOMAĆIN"
        label4="TAKMIČENJE - LIGA"
        label5="GOST"
      />
      <Grupa28
        imgBackgroundColorDark="#2257B9"
        imgBackgroundColorLight="#2D72EF"
        imgFontColor="#FFFF00"
        label1="-"
        label2="-"
        label3="-"
        label4="-"
        label5="-"
        label6="-"
        label7="-"
        label8="-"
        linkOpisKalkulatora="/bet_kalkulatori_tutorijal/645b10ebcd9c8da743a19672"
      />

        <div className={classes.calculator}>
            <div className={classes.zaglavlje}>
                <table style={{ textAlign: "center", fontWeight: "bold", borderCollapse:"collapse" }}>
                    <tr className={classes.zaglavljeTabele}>
                        <td className={classes.celijaZaglavljaTabele}  style={{minWidth:"380px", maxWidth: "380px"}} rowSpan="5">KO JE ISTI DOMACIN BIO DOMACIN PISI 1, AKO NE</td>
                        <td className={classes.celijaZaglavljaTabele}  style={{minWidth:"140px", maxWidth: "140px"}} colSpan="2">REZ.MEDJ.TRAD.</td>
                        <td className={classes.celijaZaglavljaTabele}  style={{minWidth:"70px", maxWidth: "70px"}} rowSpan="5">Golovi</td>
                        <td className={classes.celijaZaglavljaTabele}  style={{minWidth:"275px", maxWidth: "275px", background:"#00B050"}} rowSpan="2">DOMACI TEREN(DOMACINA)</td>
                        <td className={classes.celijaZaglavljaTabele}  style={{minWidth:"275px", maxWidth: "275px", background:"#2F5496"}} rowSpan="2">ZAJEDNO</td>
                        <td className={classes.celijaZaglavljaTabele}  style={{minWidth:"275px", maxWidth: "275px", background:"#FFC000", color:"black"}} rowSpan="2">KONTRA TEREN</td>
                    </tr>
                    <tr className={classes.zaglavljeTabele}>
                        <td className={classes.celijaZaglavljaTabele}  style={{minWidth:"70px", maxWidth: "70px"}}>TIM 1</td>
                        <td className={classes.celijaZaglavljaTabele}  style={{minWidth:"70px", maxWidth: "70px"}}>TIM 2</td>
                    </tr>
                    <tr className={classes.zaglavljeTabele}>
                        <td className={classes.celijaZaglavljaTabele}  style={{minWidth:"70px", maxWidth: "70px"}}>DAO</td>
                        <td className={classes.celijaZaglavljaTabele}  style={{minWidth:"70px", maxWidth: "70px"}}>DAO</td>
                        <td className={classes.celijaZaglavljaTabele}  style={{minWidth:"70px", maxWidth: "70px", background:"#00B050"}}>BR. DOM. TEREN</td>
                        <td className={classes.celijaZaglavljaTabele}  style={{minWidth:"275px", maxWidth: "275px", background:"#2F5496"}}>BR. UT. ZAJEDNO</td>
                        <td className={classes.celijaZaglavljaTabele}  style={{minWidth:"275px", maxWidth: "275px", background:"#FFC000", color:"black"}}>BR. UT. KONTRA TEREN</td>
                    </tr>
                    <tr className={classes.zaglavljeTabele}>
                        <td className={classes.celijaZaglavljaTabele}  style={{minWidth:"70px", maxWidth: "70px"}} rowSpan="2"> GOLOVA</td>
                        <td className={classes.celijaZaglavljaTabele}  style={{minWidth:"70px", maxWidth: "70px"}} rowSpan="2">GOLOVA</td>
                        <td className={classes.celijaZaglavljaTabele}  style={{minWidth:"70px", maxWidth: "70px", background:"#2EF729", color:"black"}}>2</td>
                        <td className={classes.celijaZaglavljaTabele}  style={{minWidth:"275px", maxWidth: "275px", background:"#285CF9"}}>5</td>
                        <td className={classes.celijaZaglavljaTabele}  style={{minWidth:"275px", maxWidth: "275px", background:"#FFFF00", color:"black"}}>3</td>
                    </tr>
                    <tr className={classes.zaglavljeTabele}>
                        <td className={classes.celijaZaglavljaTabele}  style={{minWidth:"70px", maxWidth: "70px", background:"#00B050"}}>GOL RAZLIKA</td>
                        <td className={classes.celijaZaglavljaTabele}  style={{minWidth:"275px", maxWidth: "275px", background:"#2F5496"}}>GOL RAZLIKA</td>
                        <td className={classes.celijaZaglavljaTabele}  style={{minWidth:"275px", maxWidth: "275px", background:"#FFC000", color:"black"}}>GOL RAZLIKA</td>
                    </tr>

                </table>
            </div>


            <div className={classes.glavnaTabla} style={{marginLeft: "5px", width:"100%", display:"flex", flexDirection:"row"}}>
                <div style={{justifyContent: "spaceBetween"}}>
                    <div className={classes.celijaGlavneTabele} style={{ width:"377px"}}>
                        <input className={classes.inputCelija}></input>
                    </div>
                    <div className={classes.celijaGlavneTabele} style={{ width:"377px", borderTop:"none"}}>
                        <input className={classes.inputCelija}></input>
                    </div>
                    <div className={classes.celijaGlavneTabele} style={{ width:"377px", borderTop:"none"}}>
                        <input className={classes.inputCelija}></input>
                    </div>
                    <div className={classes.celijaGlavneTabele} style={{ width:"377px", borderTop:"none"}}>
                        <input className={classes.inputCelija}></input>
                    </div>
                    <div className={classes.celijaGlavneTabele} style={{ width:"377px", borderTop:"none"}}>
                        <input className={classes.inputCelija}></input>
                    </div>
                    <div className={classes.celijaGlavneTabele} style={{ width:"377px", borderTop:"none"}}>
                        <input className={classes.inputCelija}></input>
                    </div>
                    <div className={classes.celijaGlavneTabele} style={{ width:"377px", borderTop:"none"}}>
                        <input className={classes.inputCelija}></input>
                    </div>
                    <div className={classes.celijaGlavneTabele} style={{ width:"377px", borderTop:"none"}}>
                        <input className={classes.inputCelija}></input>
                    </div>
                    <div className={classes.celijaGlavneTabele} style={{ width:"377px", borderTop:"none"}}>
                        <input className={classes.inputCelija}></input>
                    </div>
                    <div className={classes.celijaGlavneTabele} style={{ width:"377px", borderTop:"none"}}>
                        <input className={classes.inputCelija}></input>
                    </div>
                    <div className={classes.celijaGlavneTabele} style={{ width:"377px", borderTop:"none"}}>
                        <input className={classes.inputCelija}></input>
                    </div>
                    <div className={classes.celijaGlavneTabele} style={{ width:"377px", borderTop:"none"}}>
                        <input className={classes.inputCelija}></input>
                    </div>
                    <div className={classes.celijaGlavneTabele} style={{ width:"377px", borderTop:"none"}}>
                        <input className={classes.inputCelija}></input>
                    </div>
                    <div className={classes.celijaGlavneTabele} style={{ width:"377px", borderTop:"none"}}>
                        <input className={classes.inputCelija}></input>
                    </div>
                    <div className={classes.celijaGlavneTabele} style={{ width:"377px", borderTop:"none"}}>
                        <input className={classes.inputCelija}></input>
                    </div>
                    <div className={classes.celijaGlavneTabele} style={{ width:"377px", borderTop:"none"}}>
                        <input className={classes.inputCelija}></input>
                    </div>
                    <div className={classes.celijaGlavneTabele} style={{ width:"377px", borderTop:"none"}}>
                        <input className={classes.inputCelija}></input>
                    </div>
                    <div className={classes.celijaGlavneTabele} style={{ width:"377px", borderTop:"none"}}>
                        <input className={classes.inputCelija}></input>
                    </div>
                    <div className={classes.celijaGlavneTabele} style={{ width:"377px", borderTop:"none"}}>
                        <input className={classes.inputCelija}></input>
                    </div>
                    <div className={classes.celijaGlavneTabele} style={{ width:"377px", borderTop:"none"}}>
                        <input className={classes.inputCelija}></input>
                    </div>


                </div>

                <div style={{justifyContent: "spaceBetween"}}>
                    <div className={classes.celijaGlavneTabele} style={{ width:"72px", borderLeft:"none"}}>
                        <input className={classes.inputCelija}></input>
                    </div>
                    <div className={classes.celijaGlavneTabele} style={{ width:"72px", borderLeft:"none", borderTop:"none"}}>
                        <input className={classes.inputCelija}></input>
                    </div>
                    <div className={classes.celijaGlavneTabele} style={{ width:"72px", borderLeft:"none", borderTop:"none"}}>
                        <input className={classes.inputCelija}></input>
                    </div>
                    <div className={classes.celijaGlavneTabele} style={{ width:"72px", borderLeft:"none", borderTop:"none"}}>
                        <input className={classes.inputCelija}></input>
                    </div>
                    <div className={classes.celijaGlavneTabele} style={{ width:"72px", borderLeft:"none", borderTop:"none"}}>
                        <input className={classes.inputCelija}></input>
                    </div>
                    <div className={classes.celijaGlavneTabele} style={{ width:"72px", borderLeft:"none", borderTop:"none"}}>
                        <input className={classes.inputCelija}></input>
                    </div>
                    <div className={classes.celijaGlavneTabele} style={{ width:"72px", borderLeft:"none", borderTop:"none"}}>
                        <input className={classes.inputCelija}></input>
                    </div>
                    <div className={classes.celijaGlavneTabele} style={{ width:"72px", borderLeft:"none", borderTop:"none"}}>
                        <input className={classes.inputCelija}></input>
                    </div>
                    <div className={classes.celijaGlavneTabele} style={{ width:"72px", borderLeft:"none", borderTop:"none"}}>
                        <input className={classes.inputCelija}></input>
                    </div>
                    <div className={classes.celijaGlavneTabele} style={{ width:"72px", borderLeft:"none", borderTop:"none"}}>
                        <input className={classes.inputCelija}></input>
                    </div>
                    <div className={classes.celijaGlavneTabele} style={{ width:"72px", borderLeft:"none", borderTop:"none"}}>
                        <input className={classes.inputCelija}></input>
                    </div>
                    <div className={classes.celijaGlavneTabele} style={{ width:"72px", borderLeft:"none", borderTop:"none"}}>
                        <input className={classes.inputCelija}></input>
                    </div>
                    <div className={classes.celijaGlavneTabele} style={{ width:"72px", borderLeft:"none", borderTop:"none"}}>
                        <input className={classes.inputCelija}></input>
                    </div>
                    <div className={classes.celijaGlavneTabele} style={{ width:"72px", borderLeft:"none", borderTop:"none"}}>
                        <input className={classes.inputCelija}></input>
                    </div>
                    <div className={classes.celijaGlavneTabele} style={{ width:"72px", borderLeft:"none", borderTop:"none"}}>
                        <input className={classes.inputCelija}></input>
                    </div>
                    <div className={classes.celijaGlavneTabele} style={{ width:"72px", borderLeft:"none", borderTop:"none"}}>
                        <input className={classes.inputCelija}></input>
                    </div>
                    <div className={classes.celijaGlavneTabele} style={{ width:"72px", borderLeft:"none", borderTop:"none"}}>
                        <input className={classes.inputCelija}></input>
                    </div>
                    <div className={classes.celijaGlavneTabele} style={{ width:"72px", borderLeft:"none", borderTop:"none"}}>
                        <input className={classes.inputCelija}></input>
                    </div>
                    <div className={classes.celijaGlavneTabele} style={{ width:"72px", borderLeft:"none", borderTop:"none"}}>
                        <input className={classes.inputCelija}></input>
                    </div>
                    <div className={classes.celijaGlavneTabele} style={{ width:"72px", borderLeft:"none", borderTop:"none"}}>
                        <input className={classes.inputCelija}></input>
                    </div>
                </div>

                <div style={{justifyContent: "spaceBetween"}}>
                    <div className={classes.celijaGlavneTabele} style={{ width:"72px", borderLeft:"none"}}>
                        <input className={classes.inputCelija}></input>
                    </div>
                    <div className={classes.celijaGlavneTabele} style={{ width:"72px", borderLeft:"none", borderTop:"none"}}>
                        <input className={classes.inputCelija}></input>
                    </div>
                    <div className={classes.celijaGlavneTabele} style={{ width:"72px", borderLeft:"none", borderTop:"none"}}>
                        <input className={classes.inputCelija}></input>
                    </div>
                    <div className={classes.celijaGlavneTabele} style={{ width:"72px", borderLeft:"none", borderTop:"none"}}>
                        <input className={classes.inputCelija}></input>
                    </div>
                    <div className={classes.celijaGlavneTabele} style={{ width:"72px", borderLeft:"none", borderTop:"none"}}>
                        <input className={classes.inputCelija}></input>
                    </div>
                    <div className={classes.celijaGlavneTabele} style={{ width:"72px", borderLeft:"none", borderTop:"none"}}>
                        <input className={classes.inputCelija}></input>
                    </div>
                    <div className={classes.celijaGlavneTabele} style={{ width:"72px", borderLeft:"none", borderTop:"none"}}>
                        <input className={classes.inputCelija}></input>
                    </div>
                    <div className={classes.celijaGlavneTabele} style={{ width:"72px", borderLeft:"none", borderTop:"none"}}>
                        <input className={classes.inputCelija}></input>
                    </div>
                    <div className={classes.celijaGlavneTabele} style={{ width:"72px", borderLeft:"none", borderTop:"none"}}>
                        <input className={classes.inputCelija}></input>
                    </div>
                    <div className={classes.celijaGlavneTabele} style={{ width:"72px", borderLeft:"none", borderTop:"none"}}>
                        <input className={classes.inputCelija}></input>
                    </div>
                    <div className={classes.celijaGlavneTabele} style={{ width:"72px", borderLeft:"none", borderTop:"none"}}>
                        <input className={classes.inputCelija}></input>
                    </div>
                    <div className={classes.celijaGlavneTabele} style={{ width:"72px", borderLeft:"none", borderTop:"none"}}>
                        <input className={classes.inputCelija}></input>
                    </div>
                    <div className={classes.celijaGlavneTabele} style={{ width:"72px", borderLeft:"none", borderTop:"none"}}>
                        <input className={classes.inputCelija}></input>
                    </div>
                    <div className={classes.celijaGlavneTabele} style={{ width:"72px", borderLeft:"none", borderTop:"none"}}>
                        <input className={classes.inputCelija}></input>
                    </div>
                    <div className={classes.celijaGlavneTabele} style={{ width:"72px", borderLeft:"none", borderTop:"none"}}>
                        <input className={classes.inputCelija}></input>
                    </div>
                    <div className={classes.celijaGlavneTabele} style={{ width:"72px", borderLeft:"none", borderTop:"none"}}>
                        <input className={classes.inputCelija}></input>
                    </div>
                    <div className={classes.celijaGlavneTabele} style={{ width:"72px", borderLeft:"none", borderTop:"none"}}>
                        <input className={classes.inputCelija}></input>
                    </div>
                    <div className={classes.celijaGlavneTabele} style={{ width:"72px", borderLeft:"none", borderTop:"none"}}>
                        <input className={classes.inputCelija}></input>
                    </div>
                    <div className={classes.celijaGlavneTabele} style={{ width:"72px", borderLeft:"none", borderTop:"none"}}>
                        <input className={classes.inputCelija}></input>
                    </div>
                    <div className={classes.celijaGlavneTabele} style={{ width:"72px", borderLeft:"none", borderTop:"none"}}>
                        <input className={classes.inputCelija}></input>
                    </div>
                </div>

                <div style={{justifyContent: "spaceBetween"}}>
                    <div className={classes.celijaGlavneTabele} style={{ width:"72px", background:"darkblue", borderLeft:"none"}}><p style={{marginTop:"6px"}}>asd</p>
                    </div>
                    <div className={classes.celijaGlavneTabele} style={{ width:"72px", background:"darkblue", borderLeft:"none", borderTop:"none"}}><p style={{marginTop:"6px"}}>asd</p>
                    </div>
                    <div className={classes.celijaGlavneTabele} style={{ width:"72px", background:"darkblue", borderLeft:"none", borderTop:"none"}}><p style={{marginTop:"6px"}}>asd</p>
                    </div>
                    <div className={classes.celijaGlavneTabele} style={{ width:"72px", background:"darkblue", borderLeft:"none", borderTop:"none"}}><p style={{marginTop:"6px"}}>asd</p>
                    </div>
                    <div className={classes.celijaGlavneTabele} style={{ width:"72px", background:"darkblue", borderLeft:"none", borderTop:"none"}}><p style={{marginTop:"6px"}}>asd</p>
                    </div>
                    <div className={classes.celijaGlavneTabele} style={{ width:"72px", background:"darkblue", borderLeft:"none", borderTop:"none"}}><p style={{marginTop:"6px"}}>asd</p>
                    </div>
                    <div className={classes.celijaGlavneTabele} style={{ width:"72px", background:"darkblue", borderLeft:"none", borderTop:"none"}}><p style={{marginTop:"6px"}}>asd</p>
                    </div>
                    <div className={classes.celijaGlavneTabele} style={{ width:"72px", background:"darkblue", borderLeft:"none", borderTop:"none"}}><p style={{marginTop:"6px"}}>asd</p>
                    </div>
                    <div className={classes.celijaGlavneTabele} style={{ width:"72px", background:"darkblue", borderLeft:"none", borderTop:"none"}}><p style={{marginTop:"6px"}}>asd</p>
                    </div>
                    <div className={classes.celijaGlavneTabele} style={{ width:"72px", background:"darkblue", borderLeft:"none", borderTop:"none"}}><p style={{marginTop:"6px"}}>asd</p>
                    </div>
                    <div className={classes.celijaGlavneTabele} style={{ width:"72px", background:"darkblue", borderLeft:"none", borderTop:"none"}}><p style={{marginTop:"6px"}}>asd</p>
                    </div>
                    <div className={classes.celijaGlavneTabele} style={{ width:"72px", background:"darkblue", borderLeft:"none", borderTop:"none"}}><p style={{marginTop:"6px"}}>asd</p>
                    </div>
                    <div className={classes.celijaGlavneTabele} style={{ width:"72px", background:"darkblue", borderLeft:"none", borderTop:"none"}}><p style={{marginTop:"6px"}}>asd</p>
                    </div>
                    <div className={classes.celijaGlavneTabele} style={{ width:"72px", background:"darkblue", borderLeft:"none", borderTop:"none"}}><p style={{marginTop:"6px"}}>asd</p>
                    </div>
                    <div className={classes.celijaGlavneTabele} style={{ width:"72px", background:"darkblue", borderLeft:"none", borderTop:"none"}}><p style={{marginTop:"6px"}}>asd</p>
                    </div>
                    <div className={classes.celijaGlavneTabele} style={{ width:"72px", background:"darkblue", borderLeft:"none", borderTop:"none"}}><p style={{marginTop:"6px"}}>asd</p>
                    </div>
                    <div className={classes.celijaGlavneTabele} style={{ width:"72px", background:"darkblue", borderLeft:"none", borderTop:"none"}}><p style={{marginTop:"6px"}}>asd</p>
                    </div>
                    <div className={classes.celijaGlavneTabele} style={{ width:"72px", background:"darkblue", borderLeft:"none", borderTop:"none"}}><p style={{marginTop:"6px"}}>asd</p>
                    </div>
                    <div className={classes.celijaGlavneTabele} style={{ width:"72px", background:"darkblue", borderLeft:"none", borderTop:"none"}}><p style={{marginTop:"6px"}}>asd</p>
                    </div>
                    <div className={classes.celijaGlavneTabele} style={{ width:"72px", background:"darkblue", borderLeft:"none", borderTop:"none"}}><p style={{marginTop:"6px"}}>asd</p>
                    </div>

                </div>

                <div style={{justifyContent: "spaceBetween"}}>
                    <div className={classes.celijaGlavneTabele} style={{ width:"277px", background:"#2EF729", borderLeft:"none"}}>
                        <p style={{marginTop:"6px",color:"black"}}>asd</p>
                    </div>
                    <div className={classes.celijaGlavneTabele} style={{ width:"277px", background:"#2EF729", borderLeft:"none", borderTop:"none", display: "flex", flexDirection: "row"}}>
                        <div style={{width:"138.5px", borderRight:"2px solid gray"}}><p style={{marginTop:"6px", color:"black"}}>asd</p></div>
                        <div style={{width:"138.5px"}}><p style={{marginTop:"6px", color:"black"}}>asd</p></div>
                    </div>
                    <div className={classes.celijaGlavneTabele} style={{ width:"277px", background:"#2EF729", borderLeft:"none", borderTop:"none"}}>
                        <p style={{marginTop:"6px",color:"black"}}>asd</p>
                    </div>
                    <div className={classes.celijaGlavneTabele} style={{ width:"277px", background:"#2EF729", borderLeft:"none", borderTop:"none", display: "flex", flexDirection: "row"}}>
                        <div style={{width:"92.33px", borderRight:"2px solid gray"}}><p style={{marginTop:"6px", color:"black"}}>asd</p></div>
                        <div style={{width:"92.33px", borderRight:"2px solid gray"}}><p style={{marginTop:"6px", color:"black"}}>asd</p></div>
                        <div style={{width:"92.33px"}}><p style={{marginTop:"6px", color:"black"}}>asd</p></div>
                    </div>
                    <div className={classes.celijaGlavneTabele} style={{ width:"277px", background:"#2EF729", borderLeft:"none", borderTop:"none", display: "flex", flexDirection: "row"}}>
                        <div style={{width:"92.33px", borderRight:"2px solid gray"}}><p style={{marginTop:"6px", color:"black"}}>asd</p></div>
                        <div style={{width:"92.33px", borderRight:"2px solid gray"}}><p style={{marginTop:"6px", color:"black"}}>asd</p></div>
                        <div style={{width:"92.33px"}}><p style={{marginTop:"6px", color:"black"}}>asd</p></div>
                    </div>
                    <div className={classes.celijaGlavneTabele} style={{ width:"277px", background:"#2EF729", borderLeft:"none", borderTop:"none", display: "flex", flexDirection: "row"}}>
                        <div style={{width:"138.5px", borderRight:"2px solid gray"}}><p style={{marginTop:"6px", color:"black"}}>asd</p></div>
                        <div style={{width:"138.5px"}}><p style={{marginTop:"6px", color:"black"}}>asd</p></div>
                    </div>
                    <div className={classes.celijaGlavneTabele} style={{ width:"277px", background:"#2EF729", borderLeft:"none", borderTop:"none", display: "flex", flexDirection: "row"}}>
                        <div style={{width:"138.5px", borderRight:"2px solid gray"}}><p style={{marginTop:"6px", color:"black"}}>asd</p></div>
                        <div style={{width:"138.5px"}}><p style={{marginTop:"6px", color:"black"}}>asd</p></div>
                    </div>
                    <div className={classes.celijaGlavneTabele} style={{ width:"277px", background:"#2EF729", borderLeft:"none", borderTop:"none", display: "flex", flexDirection: "row"}}>
                        <div style={{width:"138.5px", borderRight:"2px solid gray"}}><p style={{marginTop:"6px", color:"black"}}>asd</p></div>
                        <div style={{width:"138.5px"}}><p style={{marginTop:"6px", color:"black"}}>asd</p></div>
                    </div>
                    <div className={classes.celijaGlavneTabele} style={{ width:"277px", background:"#2EF729", borderLeft:"none", borderTop:"none", display: "flex", flexDirection: "row"}}>
                        <div style={{width:"138.5px", borderRight:"2px solid gray"}}><p style={{marginTop:"6px", color:"black"}}>asd</p></div>
                        <div style={{width:"138.5px"}}><p style={{marginTop:"6px", color:"black"}}>asd</p></div>
                    </div>
                    <div className={classes.celijaGlavneTabele} style={{ width:"277px", background:"#2EF729", borderLeft:"none", borderTop:"none", display: "flex", flexDirection: "row"}}>
                        <div style={{width:"138.5px", borderRight:"2px solid gray"}}><p style={{marginTop:"6px", color:"black"}}>asd</p></div>
                        <div style={{width:"138.5px"}}><p style={{marginTop:"6px", color:"black"}}>asd</p></div>
                    </div>
                    <div className={classes.celijaGlavneTabele} style={{ width:"277px", background:"#2EF729", borderLeft:"none", borderTop:"none", display: "flex", flexDirection: "row"}}>
                        <div style={{width:"138.5px", borderRight:"2px solid gray"}}><p style={{marginTop:"6px", color:"black"}}>asd</p></div>
                        <div style={{width:"138.5px"}}><p style={{marginTop:"6px", color:"black"}}>asd</p></div>
                    </div>
                    <div className={classes.celijaGlavneTabele} style={{ width:"277px", background:"#2EF729", borderLeft:"none", borderTop:"none", display: "flex", flexDirection: "row"}}>
                        <div style={{width:"138.5px", borderRight:"2px solid gray"}}><p style={{marginTop:"6px", color:"black"}}>asd</p></div>
                        <div style={{width:"138.5px"}}><p style={{marginTop:"6px", color:"black"}}>asd</p></div>
                    </div>
                    <div className={classes.celijaGlavneTabele} style={{ width:"277px", background:"#2EF729", borderLeft:"none", borderTop:"none", display: "flex", flexDirection: "row"}}>
                        <div style={{width:"138.5px", borderRight:"2px solid gray"}}><p style={{marginTop:"6px", color:"black"}}>asd</p></div>
                        <div style={{width:"138.5px"}}><p style={{marginTop:"6px", color:"black"}}>asd</p></div>
                    </div>
                    <div className={classes.celijaPrazneTabele} style={{ width:"277px", background:"darkblue", borderLeft:"none", borderTop:"none", display: "flex", flexDirection: "row"}}>
                        <div style={{width:"138.5px", borderRight:"2px solid darkblue"}}><p style={{marginTop:"6px", color:"black"}}></p></div>
                        <div style={{width:"138.5px"}}><p style={{marginTop:"6px", color:"black"}}></p></div>
                    </div>
                    <div className={classes.celijaPrazneTabele2} style={{ width:"277px", background:"#2EF729", borderLeft:"none", borderTop:"none", display: "flex", flexDirection: "row"}}>
                        <div style={{width:"138.5px", borderRight:"2px solid gray"}}><p style={{marginTop:"22px", color:"black"}}>asd</p></div>
                        <div style={{width:"138.5px"}}><p style={{marginTop:"22px", color:"black"}}>asd</p></div>
                    </div>
                    <div className={classes.celijaGlavneTabele} style={{ width:"277px", background:"#00B050", borderLeft:"none", borderTop:"none"}}><p style={{marginTop:"6px", color:"black"}}>asd</p></div>
                    <div className={classes.celijaPrazneTabele2} style={{ width:"277px", background:"#2EF729", borderLeft:"none", borderTop:"none", display: "flex", flexDirection: "row"}}>
                        <div style={{width:"138.5px", borderRight:"2px solid gray"}}><p style={{marginTop:"22px", color:"black"}}>asd</p></div>
                        <div style={{width:"138.5px"}}><p style={{marginTop:"22px", color:"black"}}>asd</p></div>
                    </div>
                    </div>

                <div style={{justifyContent: "spaceBetween"}}>
                    <div className={classes.celijaGlavneTabele} style={{ width:"277px", background:"#285cf9", borderLeft:"none"}}>
                        <p style={{marginTop:"6px"}}>asd</p>
                    </div>
                    <div className={classes.celijaGlavneTabele} style={{ width:"277px", background:"#285cf9", borderLeft:"none", borderTop:"none", display: "flex", flexDirection: "row"}}>
                        <div style={{width:"138.5px", borderRight:"2px solid gray"}}><p style={{marginTop:"6px"}}>asd</p></div>
                        <div style={{width:"138.5px"}}><p style={{marginTop:"6px"}}>asd</p></div>
                    </div>
                    <div className={classes.celijaGlavneTabele} style={{ width:"277px", background:"#285cf9", borderLeft:"none", borderTop:"none"}}>
                        <p style={{marginTop:"6px"}}>asd</p>
                    </div>
                    <div className={classes.celijaGlavneTabele} style={{ width:"277px", background:"#285cf9", borderLeft:"none", borderTop:"none", display: "flex", flexDirection: "row"}}>
                        <div style={{width:"92.33px", borderRight:"2px solid gray"}}><p style={{marginTop:"6px"}}>asd</p></div>
                        <div style={{width:"92.33px", borderRight:"2px solid gray"}}><p style={{marginTop:"6px"}}>asd</p></div>
                        <div style={{width:"92.33px"}}><p style={{marginTop:"6px"}}>asd</p></div>
                    </div>
                    <div className={classes.celijaGlavneTabele} style={{ width:"277px", background:"#285cf9", borderLeft:"none", borderTop:"none", display: "flex", flexDirection: "row"}}>
                        <div style={{width:"92.33px", borderRight:"2px solid gray"}}><p style={{marginTop:"6px"}}>asd</p></div>
                        <div style={{width:"92.33px", borderRight:"2px solid gray"}}><p style={{marginTop:"6px"}}>asd</p></div>
                        <div style={{width:"92.33px"}}><p style={{marginTop:"6px"}}>asd</p></div>
                    </div>
                    <div className={classes.celijaGlavneTabele} style={{ width:"277px", background:"#285cf9", borderLeft:"none", borderTop:"none", display: "flex", flexDirection: "row"}}>
                        <div style={{width:"138.5px", borderRight:"2px solid gray"}}><p style={{marginTop:"6px"}}>asd</p></div>
                        <div style={{width:"138.5px"}}><p style={{marginTop:"6px"}}>asd</p></div>
                    </div>
                    <div className={classes.celijaGlavneTabele} style={{ width:"277px", background:"#285cf9", borderLeft:"none", borderTop:"none", display: "flex", flexDirection: "row"}}>
                        <div style={{width:"138.5px", borderRight:"2px solid gray"}}><p style={{marginTop:"6px"}}>asd</p></div>
                        <div style={{width:"138.5px"}}><p style={{marginTop:"6px"}}>asd</p></div>
                    </div>
                    <div className={classes.celijaGlavneTabele} style={{ width:"277px", background:"#285cf9", borderLeft:"none", borderTop:"none", display: "flex", flexDirection: "row"}}>
                        <div style={{width:"138.5px", borderRight:"2px solid gray"}}><p style={{marginTop:"6px"}}>asd</p></div>
                        <div style={{width:"138.5px"}}><p style={{marginTop:"6px"}}>asd</p></div>
                    </div>
                    <div className={classes.celijaGlavneTabele} style={{ width:"277px", background:"#285cf9", borderLeft:"none", borderTop:"none", display: "flex", flexDirection: "row"}}>
                        <div style={{width:"138.5px", borderRight:"2px solid gray"}}><p style={{marginTop:"6px"}}>asd</p></div>
                        <div style={{width:"138.5px"}}><p style={{marginTop:"6px"}}>asd</p></div>
                    </div>
                    <div className={classes.celijaGlavneTabele} style={{ width:"277px", background:"#285cf9", borderLeft:"none", borderTop:"none", display: "flex", flexDirection: "row"}}>
                        <div style={{width:"138.5px", borderRight:"2px solid gray"}}><p style={{marginTop:"6px"}}>asd</p></div>
                        <div style={{width:"138.5px"}}><p style={{marginTop:"6px"}}>asd</p></div>
                    </div>
                    <div className={classes.celijaGlavneTabele} style={{ width:"277px", background:"#285cf9", borderLeft:"none", borderTop:"none", display: "flex", flexDirection: "row"}}>
                        <div style={{width:"138.5px", borderRight:"2px solid gray"}}><p style={{marginTop:"6px"}}>asd</p></div>
                        <div style={{width:"138.5px"}}><p style={{marginTop:"6px"}}>asd</p></div>
                    </div>
                    <div className={classes.celijaGlavneTabele} style={{ width:"277px", background:"#285cf9", borderLeft:"none", borderTop:"none", display: "flex", flexDirection: "row"}}>
                        <div style={{width:"138.5px", borderRight:"2px solid gray"}}><p style={{marginTop:"6px"}}>asd</p></div>
                        <div style={{width:"138.5px"}}><p style={{marginTop:"6px"}}>asd</p></div>
                    </div>
                    <div className={classes.celijaGlavneTabele} style={{ width:"277px", background:"#285cf9", borderLeft:"none", borderTop:"none", display: "flex", flexDirection: "row"}}>
                        <div style={{width:"138.5px", borderRight:"2px solid gray"}}><p style={{marginTop:"6px"}}>asd</p></div>
                        <div style={{width:"138.5px"}}><p style={{marginTop:"6px"}}>asd</p></div>
                    </div>
                    <div className={classes.celijaPrazneTabele} style={{ width:"277px", background:"darkblue", borderLeft:"none", borderTop:"none", display: "flex", flexDirection: "row"}}>
                        <div style={{width:"100%", borderRight:"2px solid darkblue"}}><p style={{marginTop:"12px", fontSize:"35px"}}>asd</p></div>
                    </div>
                    <div className={classes.celijaPrazneTabele2} style={{ width:"277px", background:"#285cf9", borderLeft:"none", borderTop:"none", display: "flex", flexDirection: "row"}}>
                        <div style={{width:"138.5px", borderRight:"2px solid gray"}}><p style={{marginTop:"22px"}}>asd</p></div>
                        <div style={{width:"138.5px"}}><p style={{marginTop:"22px"}}>asd</p></div>
                    </div>
                    <div className={classes.celijaGlavneTabele} style={{ width:"277px", background:"#2F5496", borderLeft:"none", borderTop:"none"}}><p style={{marginTop:"6px"}}>asd</p></div>
                    <div className={classes.celijaPrazneTabele2} style={{ width:"277px", background:"#285cf9", borderLeft:"none", borderTop:"none", display: "flex", flexDirection: "row"}}>
                        <div style={{width:"138.5px", borderRight:"2px solid gray"}}><p style={{marginTop:"22px"}}>asd</p></div>
                        <div style={{width:"138.5px"}}><p style={{marginTop:"22px"}}>asd</p></div>
                    </div>

                </div>

                <div style={{justifyContent: "spaceBetween"}}>
                    <div className={classes.celijaGlavneTabele} style={{ width:"277px", background:"#ffff00", borderLeft:"none"}}>
                        <p style={{marginTop:"6px", color:"black"}}>asd</p>
                    </div>
                    <div className={classes.celijaGlavneTabele} style={{ width:"277px", background:"#ffff00", borderLeft:"none", borderTop:"none", display: "flex", flexDirection: "row"}}>
                        <div style={{width:"138.5px", borderRight:"2px solid gray"}}><p style={{marginTop:"6px", color:"black",}}>asd</p></div>
                        <div style={{width:"138.5px"}}><p style={{marginTop:"6px", color:"black"}}>asd</p></div>
                    </div>
                    <div className={classes.celijaGlavneTabele} style={{ width:"277px", background:"#ffff00", borderLeft:"none", borderTop:"none"}}>
                        <p style={{marginTop:"6px", color:"black"}}>asd</p>
                    </div>
                    <div className={classes.celijaGlavneTabele} style={{ width:"277px", background:"#ffff00", borderLeft:"none", borderTop:"none", display: "flex", flexDirection: "row"}}>
                        <div style={{width:"92.33px", borderRight:"2px solid gray"}}><p style={{marginTop:"6px", color:"black",}}>asd</p></div>
                        <div style={{width:"92.33px", borderRight:"2px solid gray"}}><p style={{marginTop:"6px", color:"black",}}>asd</p></div>
                        <div style={{width:"92.33px"}}><p style={{marginTop:"6px", color:"black"}}>asd</p></div>
                    </div>
                    <div className={classes.celijaGlavneTabele} style={{ width:"277px", background:"#ffff00", borderLeft:"none", borderTop:"none", display: "flex", flexDirection: "row"}}>
                        <div style={{width:"92.33px", borderRight:"2px solid gray"}}><p style={{marginTop:"6px", color:"black"}}>asd</p></div>
                        <div style={{width:"92.33px", borderRight:"2px solid gray"}}><p style={{marginTop:"6px", color:"black"}}>asd</p></div>
                        <div style={{width:"92.33px"}}><p style={{marginTop:"6px", color:"black"}}>asd</p></div>
                    </div>
                    <div className={classes.celijaGlavneTabele} style={{ width:"277px", background:"#ffff00", borderLeft:"none", borderTop:"none", display: "flex", flexDirection: "row"}}>
                        <div style={{width:"138.5px", borderRight:"2px solid gray"}}><p style={{marginTop:"6px", color:"black"}}>asd</p></div>
                        <div style={{width:"138.5px"}}><p style={{marginTop:"6px", color:"black"}}>asd</p></div>
                    </div>
                    <div className={classes.celijaGlavneTabele} style={{ width:"277px", background:"#ffff00", borderLeft:"none", borderTop:"none", display: "flex", flexDirection: "row"}}>
                        <div style={{width:"138.5px", borderRight:"2px solid gray"}}><p style={{marginTop:"6px", color:"black"}}>asd</p></div>
                        <div style={{width:"138.5px"}}><p style={{marginTop:"6px", color:"black"}}>asd</p></div>
                    </div>
                    <div className={classes.celijaGlavneTabele} style={{ width:"277px", background:"#ffff00", borderLeft:"none", borderTop:"none", display: "flex", flexDirection: "row"}}>
                        <div style={{width:"138.5px", borderRight:"2px solid gray"}}><p style={{marginTop:"6px", color:"black"}}>asd</p></div>
                        <div style={{width:"138.5px"}}><p style={{marginTop:"6px", color:"black"}}>asd</p></div>
                    </div>
                    <div className={classes.celijaGlavneTabele} style={{ width:"277px", background:"#ffff00", borderLeft:"none", borderTop:"none", display: "flex", flexDirection: "row"}}>
                        <div style={{width:"138.5px", borderRight:"2px solid gray"}}><p style={{marginTop:"6px", color:"black"}}>asd</p></div>
                        <div style={{width:"138.5px"}}><p style={{marginTop:"6px", color:"black"}}>asd</p></div>
                    </div>
                    <div className={classes.celijaGlavneTabele} style={{ width:"277px", background:"#ffff00", borderLeft:"none", borderTop:"none", display: "flex", flexDirection: "row"}}>
                        <div style={{width:"138.5px", borderRight:"2px solid gray"}}><p style={{marginTop:"6px", color:"black"}}>asd</p></div>
                        <div style={{width:"138.5px"}}><p style={{marginTop:"6px", color:"black"}}>asd</p></div>
                    </div>
                    <div className={classes.celijaGlavneTabele} style={{ width:"277px", background:"#ffff00", borderLeft:"none", borderTop:"none", display: "flex", flexDirection: "row"}}>
                        <div style={{width:"138.5px", borderRight:"2px solid gray"}}><p style={{marginTop:"6px", color:"black"}}>asd</p></div>
                        <div style={{width:"138.5px"}}><p style={{marginTop:"6px", color:"black"}}>asd</p></div>
                    </div>
                    <div className={classes.celijaGlavneTabele} style={{ width:"277px", background:"#ffff00", borderLeft:"none", borderTop:"none", display: "flex", flexDirection: "row"}}>
                        <div style={{width:"138.5px", borderRight:"2px solid gray"}}><p style={{marginTop:"6px", color:"black"}}>asd</p></div>
                        <div style={{width:"138.5px"}}><p style={{marginTop:"6px", color:"black"}}>asd</p></div>
                    </div>
                    <div className={classes.celijaGlavneTabele} style={{ width:"277px", background:"#ffff00", borderLeft:"none", borderTop:"none", display: "flex", flexDirection: "row"}}>
                        <div style={{width:"138.5px", borderRight:"2px solid gray"}}><p style={{marginTop:"6px", color:"black"}}>asd</p></div>
                        <div style={{width:"138.5px"}}><p style={{marginTop:"6px", color:"black"}}>asd</p></div>
                    </div>
                    <div className={classes.celijaPrazneTabele} style={{ width:"277px", background:"darkblue", borderLeft:"none", borderTop:"none", display: "flex", flexDirection: "row"}}>
                        <div style={{width:"138.5px", borderRight:"2px solid darkblue"}}><p style={{marginTop:"6px", color:"black"}}></p></div>
                        <div style={{width:"138.5px"}}><p style={{marginTop:"6px", color:"black"}}></p></div>
                    </div>
                    <div className={classes.celijaPrazneTabele2} style={{ width:"277px", background:"#ffff00", borderLeft:"none", borderTop:"none", display: "flex", flexDirection: "row"}}>
                        <div style={{width:"138.5px", borderRight:"2px solid gray"}}><p style={{marginTop:"22px", color:"black"}}>asd</p></div>
                        <div style={{width:"138.5px"}}><p style={{marginTop:"22px", color:"black"}}>asd</p></div>
                    </div>
                    <div className={classes.celijaGlavneTabele} style={{ width:"277px", background:"#FFC000", borderLeft:"none", borderTop:"none"}}><p style={{marginTop:"6px", color:"black"}}>asd</p></div>
                    <div className={classes.celijaPrazneTabele2} style={{ width:"277px", background:"#ffff00", borderLeft:"none", borderTop:"none", display: "flex", flexDirection: "row"}}>
                        <div style={{width:"138.5px", borderRight:"2px solid gray"}}><p style={{marginTop:"22px", color:"black"}}>asd</p></div>
                        <div style={{width:"138.5px"}}><p style={{marginTop:"22px", color:"black"}}>asd</p></div>
                    </div>

                </div>
            </div>
        </div>
    </div>
  );


};


export default Tradicija;
