import BackButton from '../components/BackButton'
import classes from './SafePurchase.module.css'

const SafePurchase = () => {
  return (
    <div className={classes.spPage}>
      <BackButton />
      <h1>ONLINE KUPOVINA PAKETA</h1>

      <h2> PODACI O PRODAJNOM MESTU</h2>

      <h3>JJ MONTENEGRO COMPANY</h3>

      <p>
        <span>Registracioni br/maticni: 50972148</span>
        <span>PIB 03365999</span>
        <span className={classes.underline}>
          Trg Dara Petkovica 1 85332 Tivat Montenegro , ({' '}
          <a href='www.betkotip.com' target='_blank'>
            www.betkotip.com
          </a>{' '}
          )
        </span>
      </p>

      <p>
        Kontakt :{' '}
        <span className={`${classes.underline} ${classes.inline}`}>
          betko.prodaja@gmail.com
        </span>
      </p>

      <h2>PROIZVODI I/ILI USLUGE</h2>

      <p>
        Prodaja konsultantskih paketa u vidu tekstualnog i edukativnog online
        kursa, matematčkih kalkulatora, kao i usluga konsultacija u oblasti i u
        okvirima konsultantskih usluga vezanih za sport i sportske događaje.
      </p>

      <p>
        Cene konsultantskih usluga su zavisne od izbora paketa istih od strane
        kupca. Cene su jasno naznačene na sajtu betkotip.com . U cenama
        pretplatničkih paketa su uključeni i uračunati svi poreski dodaci i sva
        druga davanja. Uplatilac nema dodatne troškove van okvira prikazanih
        cena paketa. Sve eventualne dodatne troškove po bilo kom osnovu, snosi
        prodavac pretplatničkih paketa. Za valutu plaćanja se koristi isključivo
        euro.
      </p>

      <h2>USLOVI PRODAJE</h2>

      <p>
        Kupac mora biti registrovan na sajt betkotip.com, pri čemu u procesu
        registracije potvrđuje da je navršio osamnaest godina, kao i svoju
        saglasnost sa opštim uslovima korišćenja sajta.
      </p>
      <p>Kupac ima opciju izbora načina plaćanja :</p>

      <p>
        - Uplata putem online plaćanja <br />- Uplata putem uplatnice
      </p>

      <p>
        Kupac pre uplate prolazi kroz sve potrebne filtere ispunjavanja svih
        uslova plaćanja, svih strana koje učestvuju u procesu kupo-prodaje.
        Ukoliko je kupac po automatizmu procesa prošao kroz sve potrebne
        filtere, on nesmetano može da izvrši uplatu. U suprotnom će kupca
        kontaktirati bilo koja od strana po bilo kom mogućem osnovu, kako bi
        ukazala kupcu zbog čega eventualno ne može da realizuje uplatu, tj.
        transakciju prenosa iste, bilo da je reč o sajtu betkotip.com, kompaniji
        koja posluje u saradnji sa sajtom, ili o samoj banci koja prima, ili
        preko koje kupac prosleđuje željenu uplatu na odobrenje i na
        realizaciju.
      </p>

      <p>
        Način isporuke proizvoda podrazumeva otključavanje korisničkog paketa na
        sajtu betkotip.com na koji se korisnik pretplatio uplatom paketa na
        račun kompanije JJ MONTENEGRO COMPANY. Rok isporuke proizvoda, tj,
        otključavanje pretplatničkog paketa je definisano na sajtu betkotip.com
        , a to je trenutno 24h od momenta kada uplata prođe proces transakcije
        prenosa iste na relaciji kupac- prodaac. U slučaju bilo kakvih promena
        uslova, kupac će prvi biti blagovremeno obavešten o istima. Naša
        organizacija u celosti poštuje i uvažava kupca naših "Proizvoda", i
        krajnjeg potrošača i njegovo zadovoljstvo smatra najvažnijim subjektom u
        svakom jednom, pojedinačnom procesu kupo-prodaje.
      </p>

      <p>
        Pretplatnički paketi na sajtu betkotip.com ničim nemaju i ne
        podrazumevaju bilo kakvu dostavu u obliku fizičkog dostavljanja paketa,
        a samim tim ni troškove koje bi ista mogla proizvesti.
      </p>

      <p>
        Kupac je pre obavljanja procesa online kupovine obavezan da se
        registruje na sajt betkotip.com , sa čim prihvata sve uslove vezane za
        korišćenje istog, koji su ujedno i ugovorne odredbe regulisane između
        kupca i prodavca konsultatntskih usluga i svih drugih usluga sajta
        betkotip.com .Kupac tehnički nije u mogućnosti da koristi pretplatničke
        pakete na sajtu betkotip.com dok ne obavi registraciju na betkotip.com i
        istim putem ne prihvati potpunu saglasnost sa ugovornim odredbama našeg
        sajta.
      </p>
      <p>
        Registracijom na betkotip.com, kojom kupac prihvata saglasnost sa svim
        opštim uslovima i odredbama sajta, kupac zaključuje svoju saglasnost sa
        opštim uslovima koji su sastavni deo ugovora između kupca, kao korisnika
        sajta, i betkotip.com kao pružaoca usluge.
      </p>

      <p>Sajt betkotip.com nema odredbe koje su vezane za carinske propise.</p>

      <p>
        Objašnjenja vezano za upotrebu alata na sajtu betkotip.com su detaljno
        prikazana u menijima na samom sajtu. Korisnik ima apsolutnu korisničku
        podršku u okviru objašnjenja unutar menija koji opisuju način upotrebe
        kursa, kalkualtora i premium paketa koji nudi informacije o raznim
        sportskim događajima, u skladu sa svim pravilima i postulatima koje
        korisnik prihvata pri registraciji na sajt betkotip.com . Takođe,
        korisniku je omogućeno i da se u svakom trenutku javi administraciji
        sajta, putem klika na link "PITAJ BETKA" ili e-mail dopisom na adresu
        betko.podrska @gmail.com ili betko.prodaja@gmail.com
      </p>

      <p>
        Kupac koji je realizovao kupovinu pretplatničkih paketa, ima konstantnu
        i stalnu podršku za sva pitanja i za sve što ga zanima vezano za
        kupljeni "Naš Proizvod", u bilo kom trenutku trajanja kupljene usluge.
      </p>

      <p>
        Kupac ima sva zakonska i jasno definisana prava na raskid ugovora po
        jasno definisanim zakonskim uslovima, koje ne određuje naš sajt, niti
        bilo koje kompanije u saradnji sa istim, a koje određuju jasno
        definisane zakonske odredbe. U slučaju da kupac ima bilo kakve osnovane
        primedbe po bilo kom osnovu koji je usklađen sa zakonskim odredbama, i
        da zakonskim putem potražuje raskid ugovora ili raskid istog, mi ćemo
        postupiti u skladu sa poštovanjem zakona i primenom tog poštovanja na
        delu.
      </p>

      <p>
        Sajt betkotip.com stoji na raspolaganju svakom jednom kupcu pojedinačno,
        u vidu svake moguće podrške, uključujući i sve navedeno usmereno i ka
        mogućem kupcu, i kao kupcu koji je realizovao kupovinu pretplatničkih
        paketa na našem sajtu.
      </p>

      <h2>OPIS NAČINA ZAŠTITE POVERLJIVIH PODATAKA</h2>

      <p>
        U okviru segmenta online plaćanja, kupci koji realizuju kupovinu na
        sajtu betkotip.com , mogu računati na potpunu zaštitu vezanu za navedene
        lične podatke i podatke o karticama.
      </p>

      <h2>
        IZJAVA O ZAŠTITI I PRIKUPLJANJU LIČNIH PODATAKA I O NJIHOVOM KORIŠĆENJU
      </h2>

      <p>
        U okviru segmenta online plaćanja, kao segmenta sajta koji obuhvata
        zahteve ovog tipa, betkotip.com se obavezuje na to da korisnici koji su
        ostvarili online kupovinu, sa ličnim realnim podacima koje su ostavili u
        namenu online kupovine, te podatke ničim neće zloupotrebljhavati ni u
        kakve marketinške ili bilo kakve druge svrhe, uključujući i to da ti
        podaci neće biti deljeni, davani ili na bilo koji način dostupni bilo
        kojim trećim licima, osim sajtu betkotip.com i kompaniji "JJ MONTENEGRO
        COMPANY" ka kojoj se obavlja plaćanje korisničkih paketa na sajtu
        betkotip.com
      </p>

      <p className={classes.parWithPadding}>
        Sajt betkotip.com obavezuje se pružati zaštitu ličnim podacima kupaca,
        na način da prikuplja samo neophodne, osnovne podatke o kupcima/
        korisnicima koji su nužni za ispunjenje naših obaveza; informiše kupce o
        načinu korišćenja prikupljenih podataka, redovno daje kupcima mogućnost
        izbora o upotrebi njihovih podataka, uključujući mogućnost odluke žele
        li ili ne da se njihovo ime ukloni s lista koje se koriste za
        marketinške kampanje. Svi se podaci o korisnicima strogo čuvaju i
        dostupni su samo radnicima kojima su ti podaci nužni za obavljanje
        posla. Svi korisnici sajta betkotip.com i poslovni partneri istog
        odgovorni su za poštovanje načela zaštite privatnosti.
      </p>
      <p>
        Sva plaćanja će biti izvršena u crnogorskoj valuti. Naplaćeni iznos na
        računu vaše kreditne kartice konvertuje se u vašu lokalnu valutu prema
        kursu kartičnih udruženja.
      </p>
    </div>
  )
}

export default SafePurchase
