import React, { useEffect } from 'react'
import classes from './SuccessfulPayment.module.css'
import { FaCheck } from 'react-icons/fa'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import { notification } from 'antd'
const SERVER_URL = process.env.REACT_APP_SERVER_URL

const SuccessfulPayment = () => {
  const params = Object.fromEntries(new URLSearchParams(window.location.search))
  const navigate = useNavigate()

  useEffect(() => {
    const sendSuccessPaymentInfo = async () => {
      try {
        const response = await axios.post(`${SERVER_URL}/create-transaction`, {
          ...params,
        })
        notification.success({
          message:
          response?.data?.message ||
            'Uspešno sačuvana transakcija !',
        })
      } catch (error) {
        notification.error({
          message:
            error?.response?.data?.message ||
            'Greška. Pokušajte ponovo ili se obratite našoj korisničkoj podršci!',
        })
      }
    }

    if (params.Success === '1') {
      sendSuccessPaymentInfo()
    } else {
      navigate('/korisnicki_paketi/kupi-paket')
    }
  }, [])

  return (
    <div className={classes.successPageContianer}>
      <div className={classes.iconWrapper}>
        <FaCheck />
      </div>
      <h2>
        NALOG ZA PLAĆANJE <br /> JE USPEŠNO POSLAT!
      </h2>
      <p>
        VAŠ NALOG ZA PLAĆANJE JE USPEŠNO POSLAT U BANKU. <br />
        NAKON ŠTO PLAĆANJE BUDE IZVRŠENO, DOBIĆETE POTVRDU PUTEM PUSH
        NOTIFIKACIJE,
        <br /> UKOLIKO SU AKTIVIRANE NA VAŠEM UREĐAJU
      </p>
      <p>VAŠ PRETPLATNIČKI PAKET ĆE BITI AKTIVIRAN U ROKU OD 24h.</p>
      <button onClick={() => navigate('/')}>OK.</button>
    </div>
  )
}

export default SuccessfulPayment
