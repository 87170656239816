// import { Modal } from 'antd'
// import React from 'react'
// import classes from './OpenAndConquerModal.module.css'
// import { IoStarSharp } from 'react-icons/io5'
// import { useNavigate } from 'react-router-dom'

// const OpenAndConquerModal = ({ isOpen, setIsOpen }) => {
//   const navigate = useNavigate()

//   return (
//     <Modal
//       open={isOpen}
//       footer={null}
//       closable={false}
//       maskClosable={true}
//       onCancel={() => {
//         setIsOpen(false)
//       }}
//       width={1100}
//       centered
//     >
//       <div className={classes.openAndConquerModal}>
//         <button
//           onClick={() => {
//             setIsOpen(false)
//             navigate('/')
//           }}
//           className={classes.conquerBackBtn}
//         >
//           KLIKNI I VRATI SE NA BETKOTIP.COM
//         </button>
//         <div className={classes.conquerTitleDiv}>
//           <IoStarSharp />
//           OTVORI I OSVOJI
//           <IoStarSharp />
//         </div>
//         <div className={`${classes.beWinner} ${classes.soon}`}>USKORO !</div>

//         <div className={classes.tigerImgWrapper}>
//           <img src='/tiger.webp' alt='Tiger' />
//         </div>
//         <div className={classes.beWinner}>BUDI POBEDNIK !</div>
//       </div>
//     </Modal>
//   )
// }

// export default OpenAndConquerModal

// REVERT AFTER MOBILE BUILD IS READY !!!!!!

import { Modal } from 'antd'
import React from 'react'
import classes from './OpenAndConquerModal.module.css'
import { IoStarSharp } from 'react-icons/io5'
import { useNavigate } from 'react-router-dom'

const OpenAndConquerModal = ({ isOpen, setIsOpen }) => {
  const navigate = useNavigate()

  return (
    <Modal
      open={isOpen}
      footer={null}
      closable={false}
      maskClosable={true}
      onCancel={() => {
        setIsOpen(false)
      }}
      width={1100}
      centered
    >
      <div className={classes.openAndConquerModal}>
        <button
          onClick={() => {
            setIsOpen(false)
            navigate('/')
          }}
          className={classes.conquerBackBtn}
        >
          KLIKNI I VRATI SE NA BETKOTIP.COM
        </button>
        <div className={classes.conquerTitleDiv}>
          <IoStarSharp />
          OTVORI I OSVOJI
          <IoStarSharp />
        </div>
        <button
          onClick={() => {
            navigate('/mister_tipster')
            setIsOpen(false)
          }}
          className={classes.conquerNavBtn}
        >
          MISTER TIPSTER
        </button>
        <button
          onClick={() => {
            navigate('/mister_tipster/tabele')
            setIsOpen(false)
          }}
          className={classes.conquerNavBtn}
        >
          TABELE
        </button>
        <button
          onClick={() => {
            navigate('/mister_tipster/nagrade')
            setIsOpen(false)
          }}
          className={classes.conquerNavBtn}
        >
          TAKMIČARSKE NAGRADE
        </button>
        <div className={classes.tigerImgWrapper}>
          <img src='/tiger.webp' alt='Tiger' />
        </div>
        <div className={classes.beWinner}>BUDI POBEDNIK !</div>
      </div>
    </Modal>
  )
}

export default OpenAndConquerModal
