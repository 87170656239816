import Grupa1 from "../components/Grupa1";
import Grupa20 from "../components/Grupa20";
import Grupa302 from "../components/Grupa302";
import classes from "./statistikaSudija.module.css";
import { useState, useEffect } from "react";

const StatistikaSudija = () => {
  const [upisTakmicenja, setUpisTakmicenja] = useState(Array(9).fill(""));
  const [brojUtakmica, setBrojUtakmica] = useState(Array(9).fill(""));
  const [zutiKartoni, setZutiKartoni] = useState(Array(9).fill(""));
  const [prosekZutiKartoni, setProsekZutiKartoni] = useState(Array(9).fill(""));
  const [crveniKartoni, setCrveniKartoni] = useState(Array(9).fill(""));
  const [prosekCrveniKartoni, setProsekCrveniKartoni] = useState(
    Array(9).fill("")
  );
  const [penali, setPenali] = useState(Array(9).fill(""));
  const [prosekPenala, setProsekPenala] = useState(Array(9).fill(""));
  const [ukupnoZuti, setUkupnoZuti] = useState(0);
  const [ukupnoCrveni, setUkupnoCrveni] = useState(0);
  const [ukupnoPenali, setUkupnoPenali] = useState(0);
  const [ukupanProsekZutih, setUkupanProsekZutih] = useState(0);
  const [ukupanProsekCrveni, setUkupanProsekCrvenih] = useState(0);
  const [ukupanProsekPenala, setUkupanProsekPenala] = useState(0);
  const [ukupanBrojUtakmica, setUkupanBrojUtakmica] = useState(0);

  const handleUpisUtakmica = (e, index) => {
    let arr = [...upisTakmicenja];
    arr[index] = e.target.value;
    setUpisTakmicenja(arr);
  };

  const handleBrojUtakmica = (e, index) => {
    let arr = [...brojUtakmica];
    arr[index] = e.target.value;
    setBrojUtakmica(arr);
  };

  const handleUpisZutiKartoni = (e, index) => {
    let arr = [...zutiKartoni];
    arr[index] = e.target.value;
    setZutiKartoni(arr);
  };

  const handleUpisCrveniKartoni = (e, index) => {
    let arr = [...crveniKartoni];
    arr[index] = e.target.value;
    setCrveniKartoni(arr);
  };

  const handlePenali = (e, index) => {
    let arr = [...penali];
    arr[index] = e.target.value;
    setPenali(arr);
  };

  const calcUkupnoZutiKartoni = () => {
    const zbir = zutiKartoni.reduce(
      (acc, value) => Number(acc) + Number(value),
      0
    );
    setUkupnoZuti(zbir);
  };

  const calcUkupnoCrveniKartoni = () => {
    const zbir = crveniKartoni.reduce(
      (acc, value) => Number(acc) + Number(value),
      0
    );
    setUkupnoCrveni(zbir);
  };

  const calcUkupnoPenli = () => {
    const zbir = penali.reduce((acc, value) => Number(acc) + Number(value), 0);
    setUkupnoPenali(zbir);
  };

  const calcProsekZutiKartoni = () => {
    const prosekZuti = zutiKartoni.map((kartoni, index) => {
      const prosek = ((kartoni / brojUtakmica[index]) * 100).toFixed(2);
      return isNaN(prosek) ? "0.00" : prosek + "%";
    });

    setProsekZutiKartoni(prosekZuti);
  };

  const calcProsekCrveniKartoni = () => {
    const prosekCrvenih = crveniKartoni.map((kartoni, index) => {
      const prosek = Number((kartoni / brojUtakmica[index]) * 100).toFixed(2);
      return isNaN(prosek) ? "0.00" : prosek + "%";
    });

    setProsekCrveniKartoni(prosekCrvenih);
  };

  const calcProsekPenali = () => {
    const prosekPenali = penali.map((penali, index) => {
      const prosek = ((penali / brojUtakmica[index]) * 100).toFixed(2);
      return isNaN(prosek) ? "0.00" : prosek + "%";
    });

    setProsekPenala(prosekPenali);
  };

  const calcUkupnoUtakmica = () => {
    const zbir = brojUtakmica.reduce(
      (acc, value) => Number(acc) + Number(value),
      0
    );
    setUkupanBrojUtakmica(zbir);
  };

  const calcUkupanProsekZutih = () => {
    const ukupanProsekZutihKartona = Number(
      (Number(ukupnoZuti) / Number(ukupanBrojUtakmica)) * 100
    ).toFixed(2);
    setUkupanProsekZutih(ukupanProsekZutihKartona);

    if (isNaN(ukupanProsekZutihKartona)) {
      setUkupanProsekZutih(0);
    } else {
      setUkupanProsekZutih(ukupanProsekZutihKartona + "%");
    }
  };

  const calcUkupanProsekCrvenih = () => {
    const ukupanProsekCrvenihKartona = Number(
      (Number(ukupnoCrveni) / Number(ukupanBrojUtakmica)) * 100
    ).toFixed(2);
    if (isNaN(ukupanProsekCrvenihKartona)) {
      setUkupanProsekCrvenih(0);
    } else {
      setUkupanProsekCrvenih(ukupanProsekCrvenihKartona + "%");
    }
  };

  const calcUkupanProsekPenala = () => {
    const ukupanProsekPenala = Number(
      (Number(ukupnoPenali) / Number(ukupanBrojUtakmica)) * 100
    ).toFixed(2);
    if (isNaN(ukupanProsekPenala)) {
      setUkupanProsekPenala(0);
    } else {
      setUkupanProsekPenala(ukupanProsekPenala);
    }
  };

  useEffect(() => {
    calcUkupnoZutiKartoni();
    calcProsekZutiKartoni();
    calcProsekCrveniKartoni();
    calcProsekPenali();
    calcUkupnoPenli();
    calcUkupnoCrveniKartoni();
    calcUkupnoUtakmica();
    calcUkupanProsekZutih();
    calcUkupanProsekCrvenih();
    calcUkupanProsekPenala();
  }, [
    zutiKartoni,
    brojUtakmica,
    crveniKartoni,
    penali,
    ukupanBrojUtakmica,
    ukupnoZuti,
    ukupnoPenali,
    ukupnoCrveni,
    ukupanProsekZutih,
    ukupanProsekPenala,
    ukupanProsekCrveni,
  ]);

  return (
    <div className={classes.container}>
      <Grupa1
        imgText="STATISTIKA SUDIJA"
        imgBackgroundColorDark="#000000"
        imgBackgroundColorLight="#000000"
        imgFontColor="#FFFF00"
        label1="VREME POČETKA"
        label2="ZEMLJA"
        label3="DOMAĆIN"
        label4="TAKMIČENJE - LIGA"
        label5="GOST"
      />
      <Grupa20
        imgBackgroundColorDark="#000000"
        imgBackgroundColorLight="#000000"
        imgFontColor="#FFFF00"
        calculatorType={`Statistika sudija`}
      />
      <div
        style={{
          display: "flex",
          width: "100%",
          gap: "10px",
          fontWeight: "bold",
          fontSize: "20px",
        }}
      >
        <input
          style={{
            fontWeight: "bold",
            fontSize: "20px",
          }}
          className={classes.upisImeSudije}
          placeholder="UPIŠI IME SUDIJE"
        />
        <input
          style={{
            fontWeight: "bold",
            fontSize: "20px",
          }}
          className={classes.upisZemljaSudije}
          placeholder="UPIŠI ZEMLJU SUDIJE"
        />
      </div>

      <table className={classes.calculatorTabela} border="1">
        <tr>
          <td
            style={{
              width: "50.3%",
              height: "80px",
              fontSize: "40px",
              background: "black",
              color: "yellow",
              borderRadius: "5px",
            }}
            rowSpan="2"
          >
            UPIS TAKMICENJA
          </td>
          <td
            style={{
              width: "10%",
              height: "80px",
              fontSize: "25px",
              background: "black",
              color: "yellow",
              borderRadius: "5px",
            }}
            rowSpan="2"
          >
            UKUPNO<br></br>BR. UT.
          </td>
          <td
            style={{
              width: "5%",
              height: "40px",
              background: "yellow",
              fontSize: "20px",
              borderRadius: "5px",
            }}
          >
            {ukupnoZuti}
          </td>
          <td
            style={{
              width: "8.3%",
              height: "40px",
              background: "yellow",
              fontSize: "20px",
              borderRadius: "5px",
            }}
          >
            {ukupanProsekZutih}
          </td>
          <td
            style={{
              width: "5%",
              height: "40px",
              background: "red",
              color: "white",
              fontSize: "20px",
              fontWeight: "bold",
              borderRadius: "5px",
            }}
          >
            {ukupnoCrveni}
          </td>
          <td
            style={{
              width: "8.2%",
              background: "red",
              color: "white",
              fontSize: "20px",
              borderRadius: "5px",
            }}
          >
            {ukupanProsekCrveni}
          </td>
          <td
            style={{
              width: "5%",
              height: "40px",
              background: "dodgerblue",
              fontWeight: "bold",
              color: "white",
              fontSize: "20px",
              borderRadius: "5px",
            }}
          >
            {ukupnoPenali}
          </td>
          <td
            style={{
              width: "8.3%",
              background: "dodgerblue",
              color: "white",
              fontSize: "20px",
              borderRadius: "5px",
            }}
          >
            {ukupanProsekPenala} {ukupanProsekPenala == 0 ? null : "%"}
          </td>
        </tr>
        <tr>
          <td
            style={{
              background: "yellow",
              fontSize: "19px",
              borderRadius: "5px",
            }}
            colSpan="2"
          >
            Prosek žuti K.
          </td>
          <td
            style={{
              background: "red",
              color: "white",
              fontSize: "19px",
              borderRadius: "5px",
            }}
            colSpan="2"
          >
            Prosek crveni K.
          </td>
          <td
            style={{
              background: "dodgerblue",
              color: "white",
              fontWeight: "bold",
              fontSize: "19px",
              borderRadius: "5px",
            }}
            colSpan="2"
          >
            Prosek penala
          </td>
        </tr>
      </table>
      <div
        className="table"
        style={{ width: "100%", display: "flex", flexDirection: "column" }}
      >
        {/*<div style={{display:"flex", marginTop:"20px", height:"80px"}} className="header">*/}
        {/*    <div style={{width:"50%", justifyContent: "center", textAlign:"center", fontSize:"60px", color:"yellow", background:"black"}}>Upis takmicenja</div>*/}
        {/*    <div style={{width:"10%", border: "5px solid black !important"}}>Ukupno <br></br> BR.UT</div>*/}
        {/*    <div style={{width:"13.3%"}}><div style={{display:"flex", justifyContent:"space-between"}}><div>{ukupnoZuti}</div><div>{ukupanProsekZutih}</div></div><div>PROSEK ZUTI KARTONI</div></div>*/}
        {/*    <div style={{width:"13.3%"}}><div  style={{display:"flex", justifyContent:"space-between"}}><div>{ukupnoCrveni}</div><div>{ukupanProsekCrveni}</div></div><div> BROJ CRVENI KARTONI</div></div>*/}
        {/*    <div style={{width:"13.3%"}}><div style={{display:"flex", justifyContent:"space-between"}}><div>{ukupnoPenali}</div><div>{ukupanProsekPenala}</div></div><div>PROSEK PENALI</div></div>*/}
        {/*</div>*/}

        <div style={{ display: "flex" }}>
          <div
            className="upisTakmicenja"
            style={{ display: "flex", flexDirection: "column", width: "50%" }}
          >
            {upisTakmicenja.map((item, index) => {
              return (
                <input
                  style={{
                    height: "40px",
                    borderRadius: "5px",
                    fontWeight: "bold",
                    fontSize: "20px",
                    textAlign: "center",
                  }}
                  onChange={(e) => handleUpisUtakmica(e, index)}
                  key={index}
                  value={item}
                />
              );
            })}
          </div>
          <div
            className="brojUtakmica"
            style={{ display: "flex", flexDirection: "column", width: "10%" }}
          >
            {brojUtakmica.map((item, index) => {
              return (
                <input
                  style={{
                    height: "40px",
                    borderRadius: "5px",
                    fontWeight: "bold",
                    fontSize: "20px",
                    textAlign: "center",
                  }}
                  onChange={(e) => handleBrojUtakmica(e, index)}
                  key={index}
                  value={item}
                />
              );
            })}
          </div>
          <div
            className="zutiKartoni"
            style={{ display: "flex", flexDirection: "column", width: "5%" }}
          >
            {zutiKartoni.map((item, index) => {
              return (
                <input
                  style={{
                    height: "40px",
                    borderRadius: "5px",
                    fontWeight: "bold",
                    fontSize: "20px",
                    textAlign: "center",
                  }}
                  onChange={(e) => handleUpisZutiKartoni(e, index)}
                  key={index}
                  value={item}
                />
              );
            })}
          </div>
          <div
            className="ProsekZutiKartoni"
            style={{ display: "flex", flexDirection: "column", width: "8.3%" }}
          >
            {prosekZutiKartoni.map((item, index) => {
              return (
                <input
                  style={{
                    height: "40px",
                    borderRadius: "5px",
                    backgroundColor: "yellow",
                    fontWeight: "bold",
                    fontSize: "20px",
                    color: "black",
                    textAlign: "center",
                  }}
                  key={index}
                  value={item}
                />
              );
            })}
          </div>
          <div
            className="crveniKartoni"
            style={{ display: "flex", flexDirection: "column", width: "5%" }}
          >
            {crveniKartoni.map((item, index) => {
              return (
                <input
                  style={{
                    height: "40px",
                    borderRadius: "5px",
                    textAlign: "center",
                    fontWeight: "bold",
                    fontSize: "20px",
                  }}
                  onChange={(e) => handleUpisCrveniKartoni(e, index)}
                  key={index}
                  value={item}
                />
              );
            })}
          </div>
          <div
            className="prosekCrveniKartoni"
            style={{ display: "flex", flexDirection: "column", width: "8.3%" }}
          >
            {prosekCrveniKartoni.map((item, index) => {
              return (
                <input
                  style={{
                    height: "40px",
                    borderRadius: "5px",
                    backgroundColor: "red",
                    color: "white",
                    fontWeight: "bold",
                    fontSize: "20px",
                    textAlign: "center",
                  }}
                  key={index}
                  value={item}
                />
              );
            })}
          </div>
          <div
            className="penali"
            style={{ display: "flex", flexDirection: "column", width: "5%" }}
          >
            {penali.map((item, index) => {
              return (
                <input
                  style={{
                    height: "40px",
                    borderRadius: "5px",
                    fontWeight: "bold",
                    fontSize: "20px",
                    textAlign: "center",
                  }}
                  onChange={(e) => handlePenali(e, index)}
                  key={index}
                  value={item}
                />
              );
            })}
          </div>
          <div
            className="prosekPenala"
            style={{ display: "flex", flexDirection: "column", width: "8.3%" }}
          >
            {prosekPenala.map((item, index) => {
              return (
                <input
                  style={{
                    height: "40px",
                    borderRadius: "5px",
                    backgroundColor: "dodgerblue",
                    fontWeight: "bold",
                    fontSize: "20px",
                    color: "white",
                    textAlign: "center",
                  }}
                  key={index}
                  value={item}
                />
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default StatistikaSudija;
