import classes from './titleNav.module.css'
import { useNavigate } from 'react-router-dom'
import upAndDownArrow from '../icons/upAndDownArrows.svg'
import { useLocation } from 'react-router-dom'
import { useContext } from 'react'
import AppContext from '../context/AppContext'

const TitleNav = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const { currentPage } = useContext(AppContext)

  let title
  if (
    location.pathname.startsWith('/bet_kurs') ||
    location.pathname.startsWith('/bet_kalkulatori')
  ) {
    title = decodeURIComponent(location.pathname.split('/')[2]) || 'INFO BLOK'
  } else {
    title = decodeURIComponent(location.pathname.split('/')[1]) || 'INFO BLOK'
  }
  if (
    location.pathname === '/bet_kurs' ||
    location.pathname.startsWith('/bet_kurs')
  ) {
    title = 'bet_kurs'
  }
  if (
    location.pathname === '/bet_kalkulatori' ||
    location.pathname.startsWith('/bet_kalkulatori')
  ) {
    title = 'bet_kalkulatori'
  }
  if (
    location.pathname === '/bet_kurs_novo' ||
    location.pathname.startsWith('/bet_kurs_novo')
  ) {
    title = 'premium'
  }
  if (location.pathname === '/bet_kurs_novo/post') {
    title = 'premium'
  }
  if (location.pathname === '/bet_kurs_tutorijal') {
    title = 'bet_kurs_tutorial'
  }
  if (
    location.pathname === '/bet_kalkulatori_tutorijal' ||
    location.pathname.startsWith('/bet_kalkulatori_tutorijal')
  ) {
    title = 'bet_kalkulatori_tutorial'
  }
  if (location.pathname === '/rezultati_uzivo') {
    title = 'REZULTATI UŽIVO'
  }
  if (location.pathname === '/sportske-price') {
    title = 'SPORTSKE PRIČE'
  }
  if (location.pathname === '/' && currentPage === 1) {
    title = 'BETKO INFO ZID'
  }
  if (location.pathname === '/' && currentPage !== 1) {
    title = 'SPORTSKE PRIČE'
  }
  if (location.pathname.includes('/glavne-kartice')) {
    title = 'BETKO GLAVNE KARTICE'
  }
  if (location.pathname.includes('/politika-privatnosti')) {
    title = 'POLITIKA PRIVATNOSTI'
  }
  if (location.pathname.includes('/uspesno-placanje')) {
    title = 'USPEŠNO PLAĆANJE'
  }
  if (location.pathname.includes('/neuspesno-placanje')) {
    title = 'NEUSPEŠNO PLAĆANJE'
  }
  if (location.pathname.includes('/korisnicki_paketi')) {
    title = 'KORISNIČKI PAKETI'
  }

  return (
    <div className={classes.infoBlok}>
      <div className={classes.infoLeft}>
        <p onClick={() => navigate('/bet_kurs')}>BET KURS</p>
      </div>

      {location.pathname === '/' ? (
        <div className={classes.infoWallButtonsWrapper}>
          <button onClick={() => navigate('/glavne-kartice')}>
            GLAVNE KARTICE
          </button>
          <button
            className={classes.betBtn}
            onClick={() => navigate('/bet_portal/653e47fa52a098b10de971f2')}
          >
            BET
          </button>
          <button
            className={classes.netBtn}
            onClick={() => navigate('/bet_portal/65611f28f4ca9761eb5249ad')}
          >
            NET
          </button>
          <button onClick={() => navigate('/sportske-price')}>
            SPORTSKE PRIČE
          </button>
        </div>
      ) : location.pathname.includes('/glavne-kartice') ? (
        <button
          onClick={() => navigate('/')}
          className={classes.allCommentsLinkButton}
        >
          KLIKNI I OTVORI <span>SVI KOMENTARI</span> NA JEDNOM MESTU
        </button>
      ) : (
        <div
          className={`${classes.title} ${
            !location.pathname.includes('bet_kalkulatori') &&
            !location.pathname.includes('betko_shop')
              ? classes.titleRounded
              : ''
          }`}
        >
          <p>
            {title.replaceAll('_', ' ').toUpperCase() === 'NAŠI PREDLOZI'
              ? 'VAŠI PREDLOZI'
              : title.replaceAll('_', ' ').toUpperCase()}
          </p>
        </div>
      )}

      <div className={classes.infoRight}>
        <p onClick={() => navigate('/bet_kalkulatori')}>BET KALKULATORI</p>
      </div>
    </div>
  )
}

export default TitleNav
