import { useState, useEffect } from 'react'
import axios from '../config/axios'
import Spinner from '../components/Spinner'
import classes from './PokreniKalkulatore.module.css'
import Comments from '../components/Comments'
import React from 'react'
import ReactPlayer from 'react-player'

const handleContextMenu = (event) => {
  event.preventDefault()
}

const SERVER_URL = process.env.REACT_APP_SERVER_URL

const InfoBlokSinglePage = () => {
  const [id, setId] = useState()
  const [data, setData] = useState({})
  const [commentCounter, setCommentCounter] = useState({
    totalComments: 0,
    commentsLength: 0,
    answersLength: 0,
  })

  const fetchData = async () => {
    const { data } = await axios.get(`/start-calculators`)
    setId(data._id)
    setData(data)
  }

  useEffect(() => {
    fetchData()
  }, [id])

  return (
    <div className={classes.mainCenter}>
      {!data ? (
        <div className={classes.spinner}>
          <Spinner />
        </div>
      ) : (
        <div className={classes.content}>
          <div className={classes.title}>{data.title?.toUpperCase()}</div>
          <div className={classes.firstRow}>
            {data && data.featureVideo && data.featureVideo.url ? (
              <div className={classes.featureVideoDiv}>
                <ReactPlayer
                  url={`${SERVER_URL}/${data.featureVideo?.url}`}
                  playing={true} // Enables autoplay
                  muted={true} // Mutes the video
                  loop
                  width='337px'
                  height='267px'
                  onContextMenu={handleContextMenu}
                />
              </div>
            ) : (
              <img
                className={classes.featureImage}
                src={`${SERVER_URL}/${data.featureImage?.url}`}
              ></img>
            )}

            {data && data.marketingVideo && data.marketingVideo.url ? (
              <div className={classes.featureVideoDiv}>
                <a
                  className={classes.marketingImageATag}
                  href={data.marketingLink}
                  target='_blank'
                >
                  <ReactPlayer
                    url={`${SERVER_URL}/${data.marketingVideo?.url}`}
                    playing={true} // Enables autoplay
                    muted={true} // Mutes the video
                    loop
                    width='337px'
                    height='267px'
                    onContextMenu={handleContextMenu}
                  />
                </a>
              </div>
            ) : (
              <a
                className={classes.marketingImageATag}
                href={data.marketingLink}
                target='_blank'
              >
                <img
                  className={classes.marketingImage}
                  src={`${SERVER_URL}/${data.marketingImage?.url}`}
                ></img>
              </a>
            )}
            <a
              className={classes.marketingImageATag}
              // href={chatImage?.link}
              target='_blank'
            >
              {/* <img
                className={classes.marketingImage}
                // src={`${SERVER_URL}/${chatImage?.image?.url}`}
              ></img> */}
            </a>
          </div>

          <div className={classes.contentRow}>
            {data &&
              data.items &&
              data.items.length > 0 &&
              data.items.map((item, index) => {
                if (item.type === 'text') {
                  return (
                    <div
                      style={{
                        color: item.color,
                        fontWeight: item.bold ? 'bolder' : false,
                        fontSize: item.fontSize ? `${item.fontSize}px` : '30px',
                        fontStyle: item.italic ? 'italic' : false,
                        marginTop: item.marginTop,
                        marginBottom: item.marginBottom,
                      }}
                    >
                      {item.description}
                    </div>
                  )
                }
                if (item.type === 'img') {
                  return (
                    <a href={item?.imageLink} className={classes.itemImageATag}>
                      <img
                        className={classes.itemImage}
                        src={`${SERVER_URL}/${item.image?.url}`}
                      ></img>
                    </a>
                  )
                }
              })}
          </div>
          <div className={classes.comment}>K O M E N T A R I ( Š I ) </div>

          <div className={classes.textField}>
            {data.textField?.toUpperCase()}
          </div>
        </div>
      )}
      {id && (
        <Comments
          id={id}
          title={data.title}
          setCommentCounter={setCommentCounter}
        />
      )}
    </div>
  )
}

export default InfoBlokSinglePage
