import { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import AdminPostSearchModule from "./AdminPostSearchModule";
import AdminSinglePost from "./AdminSinglePost";
import Spinner from "../components/Spinner";
import classes from "./adminBetKalkulatoriTutorijal.module.css";

const AdminBetKalkulatoriTutorijal = () => {
  const [isArray, setIsArray] = useState(false);
  const navigate = useNavigate();
  const axiosPrivate = useAxiosPrivate();

  const getAllBetKalkulatoriTutorijalPosts = async () => {
    const { data } = await axiosPrivate.get(
      "/getAllBetKalkulatoriTutorijalPosts"
    );
    const isArray = await Array.isArray(data.result);
    if (isArray) {
      setIsArray(true);
    }
    return data;
  };
  const allBetKalkulatoriTutorijalPostsQuery = useQuery({
    queryKey: ["BetKalkulatoriTutorijal"],
    queryFn: getAllBetKalkulatoriTutorijalPosts,
    staleTime: 1000,
    refetchInterval: 1000 * 60 * 60 * 12,
  });

  if (allBetKalkulatoriTutorijalPostsQuery.isError) return <h2>Error...</h2>;

  const handleNewPost = () => {
    navigate("/betko-admin/kreiraj-novi-post-tutorijalikalkulatora", {
      state: "betKalkulatoriTutorijal",
    });
  };

  return (
    <div className={classes.container}>
      {allBetKalkulatoriTutorijalPostsQuery.isLoading ? (
        <Spinner />
      ) : (
        <>
          <h1>BET KALKULATORI TUTORIJAL</h1>
          <div className={classes.top}>
            {isArray &&
              allBetKalkulatoriTutorijalPostsQuery.data.result !==
                undefined && (
                <AdminPostSearchModule type="betKalkulatoriTutorijal" />
              )}
            <button onClick={() => handleNewPost()} className={classes.btn}>
              Dodaj Novi
            </button>
          </div>
          <div className={classes.postContainer}>
            <div>
              {isArray &&
              allBetKalkulatoriTutorijalPostsQuery.data.result !== undefined
                ? allBetKalkulatoriTutorijalPostsQuery.data.result.map(
                    (post) => {
                      return (
                        <AdminSinglePost
                          post={post}
                          key={post._id}
                          type="betKalkulatoriTutorijal"
                        />
                      );
                    }
                  )
                : allBetKalkulatoriTutorijalPostsQuery.data.msg}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default AdminBetKalkulatoriTutorijal;
