import Grupa1 from "../components/Grupa1";
import Grupa20 from "../components/Grupa20";
import classes from "./poredenjeNizova.module.css";

const PoredenjeKvota = () => {
  return (
    <div className={classes.container}>
      <Grupa1
        imgText="POREĐENJE NIZOVA"
        imgBackgroundColorDark="#062C82"
        imgBackgroundColorLight="#0D286B"
        imgFontColor="#06F811"
        label1="VREME POČETKA"
        label2="ZEMLJA"
        label3="DOMAĆIN"
        label4="TAKMIČENJE - LIGA"
        label5="GOST"
      />
      <Grupa20
        imgBackgroundColorDark="#062C82"
        imgBackgroundColorLight="#0D286B"
        imgFontColor="#06F811"
        linkOpisKalkulatora="/bet_kalkulatori_tutorijal/645b10a9cd9c8da743a1965d"
      />
    </div>
  );
};

export default PoredenjeKvota;
