import classes from "./saradnja.module.css";
import saradnjaImg from "../images/saradnja.png";

const Saradnja = () => {
  return (
    <div className={classes.mainCenter}>
      <p>
        Fleksibilan, normalan, otvoren i iskren pristup, jesu naš način rada.
        Želimo da sarađujemo sa svim subjektima na tržištu koji smatraju da
        saradnja sa nama može da bude produktivna za njih, a ujedno sagledavaju
        interese kroz šire tačke gledišta. Sa svima smo, bez obzira na status na
        tržištu, apsolutno spremni da razgovaramo o saradnji, da podelimo
        iskustva, i postignemo dogovor, ukoliko obe strane ili više strana
        prepoznaju zdrave staze i put koji vodi ka istima.
      </p>
      <img src={saradnjaImg} alt="saradnja" />
      <p>
        U okviru saradnje koju nudimo, podrazumevano možemo da Vam ponudimo
        marketinški prostor na samom sajtu. Organizatorima igre je dostupan
        prostor sa leve i sa desne strane našeg sajta, usmeren ka tome da sa
        posetiocima našeg sajta i sa korisnicima usluga istog ostvare direktnu
        interakciju kroz posredstvo našeg sajta. Bilo kroz edukaciju samih
        korisnika kroz ‘’BET KURS’’ ili kroz upotrebu ‘’BET KALKULATORA’’,
        linkovi sa leve i sa desne strane otvaraju mogućnost olakšanog pristupa
        kladioničara sajtu ili sajtovima organizatora igre, dok organizatoru
        igre pomažu u tome da ih igrač lakše uoči i pristupi igri na njihovom
        sajtu ili aplikaciji. Takođe, u okviru istog prostora organizator igre
        može da oglasi i stranicu sa promotivnim sadržajem akcije koju sprovodi,
        pri kom postavljeni link uz fotografiju sa transparentnom pozadinom vodi
        igrača na stranicu organizatora igre koja opisuje, objašnjava i dalje
        usmerava igrače na akcije u sprovođenju. To nije i jedino što možemo da
        vam ponudimo, pogotovo ne kao organizatoru igre.
      </p>
      <p>
        Naš razvojni put u primeni znanja koje posedujemo i koje smo kroz svoj
        rad sticali, podrazumeva mnogo više od marketinškog prostora na samom
        sajtu. Opcije od konsultantskih usluga, ozbiljnih i studioznih
        personaliovanih recenzija platformi i aplikacija organizatora igre, do
        vrhunskih projekata koje imamo, a koji imaju i više nego jasnu i
        postojanu tendenciju, usmerenu ka tektonskim promenama na tržištu
        sportskog klađenja, te viralno i monetarno liderski preciziranu tržišnu
        poziciju, takođe jesu deo naše ponude.
      </p>
      <p>
        U našim projektima, koje smatramo inovantnim, originalnim i sa viralno
        neograničenim rešenjima, idemo u susret budućnosti sportskog klađenja.
        Naša tendencija jeste da sadašnjost sportskog klađenja postane prošlost,
        tako što ćemo zajedno svojim kapacitetima uvek ići potreban korak ispred
        tržišta i svega onoga što čini trenutnu sliku istog. U našim vizijama i
        u načinu na koji sagledavamo saradničke odnose, apsolutno ima mesta za
        sve, koji objektivno svojim kapacitetima logistike u saradnji sa nama i
        u primeni zajedničkog znanja, mogu da doprinesu zajedničkom timskom
        razvoju, i koji pri svom poslovanju žele da idu napred, ne bi li u
        svakom trenutku bili korak ispred ostalih, i svoje konkurencije
      </p>
    </div>
  );
};

export default Saradnja;
