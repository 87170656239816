import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import classes from "./neovlascen.module.css";
import { IoStarSharp } from "react-icons/io5";
import neovlascen1 from "../images/neovlascen_1.png";
import neovlascen2 from "../images/neovlascen_2.png";
import neovlascen3 from "../images/neovlascen_3.png";
import neovlascen4 from "../images/neovlascen_4.png";
import neovlascen5 from "../images/neovlascen_5.png";

const Neovlascen = () => {
  const navigate = useNavigate()
  const [showPopUp, setShowPopUp] = useState(false)

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const premiumPopUp = localStorage.getItem('PremiumPopUp')
      console.log(premiumPopUp)

      if (!premiumPopUp || premiumPopUp === 'false') {
        setShowPopUp(true)

        setTimeout(() => {
          setShowPopUp(false)
        }, 20000)
      }
    }
  }, [])

  const handleLocalStorage = (e) => {
    const { checked } = e.target

    localStorage.setItem('PremiumPopUp', checked)
  }

  return (
    <div className={classes.mainCenter}>
      <div className={classes.content}>
        {showPopUp ? (
          <div className={classes.popUpContainer}>
            <div>
              <div>
                <p>
                  UKOLIKO NEMATE PRETPLATNIČKI PAKET IZLAZOM IZ OVOG PROZORA POGLEDAJTE MENI "KORISNIČKI PAKETI" I ISPRATITE KORAKE DO OTKLJUČAVANJA SADRŽAJA PREDLOGA, KOJE MOŽETE VIDETI NA "BETKO SEMAFORIMA", KAKO BI VAM BILI DOSTUPNI PRE POČETKA UTAKMICA !
                </p>
              </div>

              <div>
                <button onClick={() => setShowPopUp(false)}>
                  <img src='/close-mini-icon.svg' width={35} height={35} />
                  <span>IZLAZ IZ PROZORA</span>
                </button>
              </div>

              <div>
                <div>
                  <p>UKOLIKO STE U PRETPLATI ZA "BETKO PAKETE" UPOZNAJTE SE SA SADRŽAJEM U OPISU "KARTICA" I UŽIVAJTE U NAŠIM</p>
                </div>

                <div className={classes.smallerClickAndSee}>
                  <button onClick={() => navigate('/korisnicki_paketi')}>
                    <span><IoStarSharp style={{ fontSize: '20px' }} /></span>
                    <span>KLIKNI I POGLEDAJ - KORISNIČKI PAKETI</span>
                  </button>
                </div>
              </div>
            </div>

            <div className={classes.checkboxPopUpContainer}>
              <span>AKO STE SHVATILI FUNKCIJU "PREMIUM" MENIJA, OBELEŽITE DA VAM VIŠE NE ISKAČE OVAJ PROZOR</span>
              <div>
                <input type='checkbox' onChange={(e) => handleLocalStorage(e)} />
              </div>
            </div>
          </div>
        ) : (
          <div className={classes.newMenuContainer}>
            <div>
              <button onClick={() => navigate('/korisnicki_paketi')}>
                <span><IoStarSharp style={{ fontSize: '40px' }} /></span>
                <span>KLIKNI I POGLEDAJ - KORISNIČKI PAKETI</span>
              </button>
            </div>
          </div>
        )}

        <img src={neovlascen1} alt="slika1" />
        <img src={neovlascen2} alt="slika2" />
        <img src={neovlascen3} alt="slika3" />
        <img src={neovlascen4} alt="slika4" />
        <img src={neovlascen5} alt="slika5" />
      </div>
    </div>
  );
};

export default Neovlascen;
