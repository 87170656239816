import { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "../config/axios";
import { useMutation } from "@tanstack/react-query";
import classes from "./zaboravioSamLozinku.module.css";

const ZaboravioSamLozinku = () => {
  const [email, setEmail] = useState("");
  const [isError, setIsError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const navigate = useNavigate();

  const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  const validEmail = re.test(email);

  const changePasswordMutation = useMutation({
    mutationFn: async (userData) => {
      await axios.get(`/forgottenPassword/${email}`, {
        headers: { "Content-Type": "application/json" },
        withCredentials: true,
      });
    },
    onSuccess: () => {
      navigate("/");
    },
    onError: (error) => {
      if (error.response.status === 404) {
        setIsError(true);
        setErrorMsg("Email nije pronadjen.");
      }
      if (error.response.status === 500) {
        setIsError(true);
        setErrorMsg("Nešto nije u redu pokušajte kasnije.");
      }
    },
  });

  const handleChangePassword = () => {
    if (validEmail) {
      changePasswordMutation.mutate();
    } else {
      setIsError(true);
      setErrorMsg("Email adresa nije validna.");
    }
  };

  return (
    <div className={classes.container}>
      <h2>Dodela nove lozinke</h2>
      {isError && (
        <div className={classes.error}>
          <p>{errorMsg}</p>
        </div>
      )}
      <p>Unesite vaš email:</p>
      <input
        onChange={(e) => setEmail(e.target.value)}
        className={classes.input}
        type="email"
      />
      <p className={classes.red}>
        Napomena: U polje iznad unesite vašu email adresu. Ukoliko je adresa
        ispravna uskoro će vam u vaš mail box stići link za promenu lozinke.
        Proverite i spam folder. Kliknite na taj link. Nakon toga imate 15
        minuta da promenite vašu lozinku.
      </p>
      <div className={classes.btnContainers}>
        <button className={classes.btn} onClick={() => navigate(-1)}>
          Nazad
        </button>
        <button onClick={() => handleChangePassword()} className={classes.btn}>
          Pošalji
        </button>
      </div>
    </div>
  );
};

export default ZaboravioSamLozinku;
