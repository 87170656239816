import { message, Modal, Table, Button } from 'antd'
import axios from 'axios'
import React, { useContext, useEffect, useState } from 'react'
import classes from './AdminNews.module.css'
import AuthContext from '../context/AuthProvider'

const AdminNews = () => {
  const SERVER_URL = process.env.REACT_APP_SERVER_URL
  const { auth } = useContext(AuthContext)
  const [allNews, setAllNews] = useState([])
  const [addNewsModalOpen, setAddNewsModalOpen] = useState(false)
  const [editNewsModalOpen, setEditNewsModalOpen] = useState(false)
  const [deleteModalOpen, setDeleteModalOpen] = useState(false)
  const [selectedNews, setSelectedNews] = useState(null)
  const [newsContent, setNewsContent] = useState('')
  const [refetchTrigger, setRefetchTrigger] = useState(false)

  useEffect(() => {
    const getAllNews = async () => {
      try {
        const response = await axios.get(`${SERVER_URL}/get-all-news`)
        setAllNews(response?.data)
      } catch (err) {
        console.error('Error fetching news:', err)
      }
    }

    getAllNews()
  }, [refetchTrigger])

  const handleAddNews = async () => {
    try {
      await axios.post(
        `${SERVER_URL}/create-news`,
        { content: newsContent },
        {
          withCredentials: false,
          headers: { Authorization: `Bearer ${auth.accessToken}` },
        }
      )
      setRefetchTrigger((curr) => !curr)
      setNewsContent('')
      setAddNewsModalOpen(false)
      message.success('Vest uspešno dodata!')
    } catch (err) {
      console.error(err?.message || err)
      message.error(err?.message || err)
    }
  }

  const handleEditNews = async () => {
    if (selectedNews?.content?.trim().length === 0) {
      message.error('Molimo dodajte sadržaj vesti!')
      return
    }
    try {
      await axios.put(
        `${SERVER_URL}/update-news/${selectedNews._id}`,
        { content: selectedNews.content },
        {
          withCredentials: false,
          headers: { Authorization: `Bearer ${auth.accessToken}` },
        }
      )
      setRefetchTrigger((curr) => !curr)
      setEditNewsModalOpen(false)
      message.success('Vest uspešno izmenjena!')
      console.log()
    } catch (err) {
      console.error(err?.message || err)
      message.error(err?.message || err)
    }
  }

  const handleDeleteNews = async () => {
    try {
      await axios.delete(`${SERVER_URL}/delete-news/${selectedNews._id}`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${auth.accessToken}` },
      })
      setRefetchTrigger((curr) => !curr)
      setDeleteModalOpen(false)
      message.success('Vest uspešno obrisana!')
    } catch (err) {
      console.error(err?.message || err)
      message.error(err?.message || err)
    }
  }

  const columns = [
    {
      title: 'Sadržaj',
      dataIndex: 'content',
      key: 'content',
      width: '90%',
      ellipsis: true,
      className: 'truncate-text',
    },
    {
      title: 'Akcije',
      key: 'actions',
      render: (_, record) => (
        <span>
          <Button type='link' onClick={() => handleOpenEditModal(record)}>
            Izmeni
          </Button>
          <Button type='link' onClick={() => handleOpenDeleteModal(record)}>
            Obriši
          </Button>
        </span>
      ),
    },
  ]

  const handleOpenEditModal = (record) => {
    setSelectedNews(record)
    setEditNewsModalOpen(true)
  }

  const handleOpenDeleteModal = (record) => {
    setSelectedNews(record)
    setDeleteModalOpen(true)
  }

  return (
    <div className={classes.newsPageWrapper}>
      <div className={classes.topWrapper}>
        <h2>Sve Vesti</h2>
        <Button type='primary' onClick={() => setAddNewsModalOpen(true)}>
          Dodaj vest
        </Button>
      </div>

      <Table
        dataSource={allNews?.allNews || []}
        columns={columns}
        rowKey='_id'
        className={classes.newsTable}
        pagination={false}
      />

      {/* Add News Modal */}
      <Modal
        title='Dodaj Vest'
        open={addNewsModalOpen}
        onOk={handleAddNews}
        onCancel={() => setAddNewsModalOpen(false)}
        className={classes.newsModal}
        okText='Dodaj'
        cancelText='Otkaži'
      >
        <textarea
          value={newsContent}
          onChange={(e) => setNewsContent(e.target.value)}
          placeholder='Sadržaj vesti'
        />
      </Modal>

      {/* Edit News Modal */}
      <Modal
        title='Izmeni Vest'
        open={editNewsModalOpen}
        onOk={handleEditNews}
        onCancel={() => setEditNewsModalOpen(false)}
        className={classes.newsModal}
        okText='Izmeni'
        cancelText='Otkaži'
      >
        <textarea
          value={selectedNews?.content || ''}
          onChange={(e) =>
            setSelectedNews({ ...selectedNews, content: e.target.value })
          }
          placeholder='Sadržaj vesti'
        />
      </Modal>

      {/* Delete News Modal */}
      <Modal
        title='Obriši Vest'
        open={deleteModalOpen}
        onOk={handleDeleteNews}
        onCancel={() => setDeleteModalOpen(false)}
        okText='Obriši'
        cancelText='Otkaži'
      >
        Da li ste sigurni da želite da obrišete ovu vest?
      </Modal>
    </div>
  )
}

export default AdminNews
