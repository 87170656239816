import Grupa3 from "../components/Grupa3";
import Grupa20 from "../components/Grupa20";
import classes from "./negativnaProgresija.module.css";
import { useState } from "react";
import { useEffect } from "react";

const NegativnaProgresija = () => {
  const [ulog, setUlog] = useState([
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  ]);

  const [upisKvota, setUpisKvota] = useState([
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  ]);

  const [eventualniDobitak, setEventualniDobitak] = useState([
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  ]);

  const [prolaz, setProlaz] = useState([
    "NE",
    "NE",
    "NE",
    "NE",
    "NE",
    "NE",
    "NE",
    "NE",
    "NE",
    "NE",
    "NE",
    "NE",
    "NE",
    "NE",
    "NE",
    "NE",
    "NE",
    "NE",
    "NE",
    "NE",
    "NE",
    "NE",
    "NE",
    "NE",
    "NE",
  ]);

  const [gubitak, setGubitak] = useState([
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  ]);

  const [isplativostOdnosaUlogaiKvote, setIspativostOdnosaUlogaiKvote] =
    useState([
      0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
    ]);

  const [poslednjiEventualniDobitak, setPoslednjiEvenutualniDobitak] =
    useState(0);

  const ukupno = isplativostOdnosaUlogaiKvote.reduce(
    (accumulator, currentValue) => accumulator + currentValue,
    0
  );

  const [ukupanUlog, setUkupanUlog] = useState(0);

  const handleChange = (index, e) => {
    const newValue = e.target.value;
    const updatedUlog = [...ulog];
    updatedUlog[index] = newValue;
    setUlog(updatedUlog);
  };

  const handleChangeKvote = (index, e) => {
    const newValue = e.target.value;
    const updatedKvota = [...upisKvota];
    updatedKvota[index] = newValue;
    setUpisKvota(updatedKvota);
  };

  const calcUkupanUlog = () => {
    let ukupanUlogVrednost = ulog.reduce(
      (accumulator, value) => Number(accumulator) + Number(value),
      0
    );
    setUkupanUlog(ukupanUlogVrednost);
  };

  const calcEventualniDobitak = () => {
    const newEventualniDobitak = ulog.map((value, index) => {
      const final = value * upisKvota[index];
      return Number(final.toFixed(2));
    });
    setEventualniDobitak(newEventualniDobitak);

    let zadnji = 0;

    for (let i = 0; i < newEventualniDobitak.length; i++) {
      if (newEventualniDobitak[i] !== 0) {
        zadnji = newEventualniDobitak[i];
      }
    }

    setPoslednjiEvenutualniDobitak(zadnji);
  };

  const calcGubitak = () => {
    const newGubitak = ulog.map((value, index) => {
      return value;
    });

    setGubitak(newGubitak);
  };

  const calcIsplativostOdnosaUlogaIKvote = () => {
    const newOdnos = eventualniDobitak.map((value, index) => {
      const newValue = ukupanUlog * 2;
      return Number(newValue.toFixed(2));
    });

    setIspativostOdnosaUlogaiKvote(newOdnos);
  };

  useEffect(() => {
    calcUkupanUlog();
    calcEventualniDobitak();
    calcGubitak();
    calcIsplativostOdnosaUlogaIKvote();
  }, [ulog, upisKvota, eventualniDobitak, gubitak]);

  return (
    <div className={classes.container}>
      <Grupa3
        imgText="NEGATIVNA PROGRESIJA"
        imgBackgroundColorDark="#282677"
        imgBackgroundColorLight="#282677"
        imgFontColor="#FFFFFF"
      />
      <Grupa20
        imgBackgroundColorDark="#282677"
        imgBackgroundColorLight="#282677"
        imgFontColor="#FFFFFF"
        calculatorType={`Negativna progresija`}
      />

      <div className={classes.calculator} style={{ fontWeight: "bolder" }}>
        <div className={classes.row}>
          <div
            className={classes.upisUlogFirstRow}
            style={{ height: "50px", background: "#002060" }}
          >
            <p
              style={{
                textAlign: "center",
                fontWeight: "bolder",
                color: "white",
                marginTop: "7px",
              }}
            >
              UNOS
              <br />
              ULOGA
            </p>
          </div>

          <div
            className={classes.upisKvotaRow}
            style={{ height: "50px", background: "#002060" }}
          >
            <p
              style={{
                textAlign: "center",
                color: "white",
                fontWeight: "bolder",
                marginTop: "7px",
              }}
            >
              UPIS
              <br />
              KVOTA
            </p>
          </div>

          <div
            className={classes.eventualniDobitakRow}
            style={{ height: "50px", background: "#002060" }}
          >
            <p
              style={{
                textAlign: "center",
                fontWeight: "bolder",
                marginTop: "7px",
              }}
            >
              EVENTUALNI
              <br />
              DOBITAK
            </p>
          </div>

          <div
            className={classes.prolazNeRow}
            style={{ height: "50px", background: "#002060" }}
          >
            <p
              style={{
                textAlign: "center",
                fontWeight: "bolder",
                marginTop: "7px",
              }}
            >
              PROLAZ
              <br />
              NE
            </p>
          </div>

          <div
            className={classes.gubitakRow}
            style={{ height: "50px", background: "#002060" }}
          >
            <p
              style={{
                textAlign: "center",
                fontWeight: "bolder",
                marginTop: "15px",
              }}
            >
              GUBITAK
            </p>
          </div>

          <div
            className={classes.isplativostRow}
            style={{
              height: "50px",
              background: "#002060",
              border: "3px black solid",
            }}
          >
            <p
              style={{
                textAlign: "center",
                fontWeight: "bolder",
                marginTop: "0px",
              }}
            >
              ISPLATIVOST ODNOSA
              <br />
              SLEDECEG
              <br />I ULOGA I KVOTE
            </p>
          </div>
        </div>

        <div className={classes.row}>
          <div className={classes.upisUlogFirstRow}>
            <input
              type="text"
              value={ulog[0]}
              onChange={(e) => handleChange(0, e)}
            />
          </div>
          <div className={classes.upisKvotaRow}>
            <input
              type="text"
              value={upisKvota[0]}
              onChange={(e) => handleChangeKvote(0, e)}
            />
          </div>
          <div className={classes.eventualniDobitakRow}>
            {eventualniDobitak[0] == 0 ? "-" : eventualniDobitak[0]}
          </div>

          <div className={classes.prolazNeRow}>{prolaz[0]}</div>
          <div className={classes.gubitakRow}>
            {gubitak[0] == 0 ? "-" : `- ${gubitak[0]}`}
          </div>
          <div className={classes.isplativostRow}>
            {/*{isplativostOdnosaUlogaiKvote[0] == 0 ? "-" : isplativostOdnosaUlogaiKvote[0]}*/}
          </div>
        </div>
        <div className={classes.row}>
          <div className={classes.upisUlogFirstRow}>
            <input
              type="text"
              value={ulog[1]}
              onChange={(e) => handleChange(1, e)}
            />
          </div>
          <div className={classes.upisKvotaRow}>
            <input
              type="text"
              value={upisKvota[1]}
              onChange={(e) => handleChangeKvote(1, e)}
            />
          </div>
          <div className={classes.eventualniDobitakRow}>
            {eventualniDobitak[1] == 0 ? "-" : eventualniDobitak[1]}
          </div>

          <div className={classes.prolazNeRow}>{prolaz[1]}</div>
          <div className={classes.gubitakRow}>
            {gubitak[1] == 0 ? "-" : `- ${gubitak[1]}`}
          </div>
          <div className={classes.isplativostRow}>
            {/*{isplativostOdnosaUlogaiKvote[1] == 0 ? "-" : isplativostOdnosaUlogaiKvote[1]}*/}
          </div>
        </div>
        <div className={classes.row}>
          <div className={classes.upisUlogFirstRow}>
            <input
              type="text"
              value={ulog[2]}
              onChange={(e) => handleChange(2, e)}
            />
          </div>
          <div className={classes.upisKvotaRow}>
            <input
              type="text"
              value={upisKvota[2]}
              onChange={(e) => handleChangeKvote(2, e)}
            />
          </div>
          <div className={classes.eventualniDobitakRow}>
            {eventualniDobitak[2] == 0 ? "-" : eventualniDobitak[2]}
          </div>

          <div className={classes.prolazNeRow}>{prolaz[2]}</div>
          <div className={classes.gubitakRow}>
            {gubitak[2] == 0 ? "-" : `- ${gubitak[2]}`}
          </div>
          <div className={classes.isplativostRow}>
            {/*{isplativostOdnosaUlogaiKvote[2] == 0 ? "-" : isplativostOdnosaUlogaiKvote[2]}*/}
          </div>
        </div>
        <div className={classes.row}>
          <div className={classes.upisUlogFirstRow}>
            <input
              type="text"
              value={ulog[3]}
              onChange={(e) => handleChange(3, e)}
            />
          </div>
          <div className={classes.upisKvotaRow}>
            <input
              type="text"
              value={upisKvota[3]}
              onChange={(e) => handleChangeKvote(3, e)}
            />
          </div>
          <div className={classes.eventualniDobitakRow}>
            {eventualniDobitak[3] == 0 ? "-" : eventualniDobitak[3]}
          </div>

          <div className={classes.prolazNeRow}>{prolaz[3]}</div>
          <div className={classes.gubitakRow}>
            {gubitak[3] == 0 ? "-" : `- ${gubitak[3]}`}
          </div>
          <div className={classes.isplativostRow}>
            {/*{isplativostOdnosaUlogaiKvote[3] == 0 ? "-" : isplativostOdnosaUlogaiKvote[3]}*/}
          </div>
        </div>
        <div className={classes.row}>
          <div className={classes.upisUlogFirstRow}>
            <input
              type="text"
              value={ulog[4]}
              onChange={(e) => handleChange(4, e)}
            />
          </div>
          <div className={classes.upisKvotaRow}>
            <input
              type="text"
              value={upisKvota[4]}
              onChange={(e) => handleChangeKvote(4, e)}
            />
          </div>
          <div className={classes.eventualniDobitakRow}>
            {eventualniDobitak[4] == 0 ? "-" : eventualniDobitak[4]}
          </div>

          <div className={classes.prolazNeRow}>{prolaz[4]}</div>
          <div className={classes.gubitakRow}>
            {gubitak[4] == 0 ? "-" : `- ${gubitak[4]}`}
          </div>
          <div className={classes.isplativostRow}>
            {/*{isplativostOdnosaUlogaiKvote[4] == 0 ? "-" : isplativostOdnosaUlogaiKvote[4]}*/}
          </div>
        </div>
        <div className={classes.row}>
          <div className={classes.upisUlogFirstRow}>
            <input
              type="text"
              value={ulog[5]}
              onChange={(e) => handleChange(5, e)}
            />
          </div>
          <div className={classes.upisKvotaRow}>
            <input
              type="text"
              value={upisKvota[5]}
              onChange={(e) => handleChangeKvote(5, e)}
            />
          </div>
          <div className={classes.eventualniDobitakRow}>
            {eventualniDobitak[5] == 0 ? "-" : eventualniDobitak[5]}
          </div>

          <div className={classes.prolazNeRow}>
            {prolaz[5] == 0 ? "-" : prolaz[5]}
          </div>
          <div className={classes.gubitakRow}>
            {gubitak[5] == 0 ? "-" : `- ${gubitak[5]}`}
          </div>
          <div className={classes.isplativostRow}>
            {/*{isplativostOdnosaUlogaiKvote[5] == 0 ? "-" : isplativostOdnosaUlogaiKvote[5]}*/}{" "}
          </div>
        </div>
        <div className={classes.row}>
          <div className={classes.upisUlogFirstRow}>
            <input
              type="text"
              value={ulog[6]}
              onChange={(e) => handleChange(6, e)}
            />
          </div>
          <div className={classes.upisKvotaRow}>
            <input
              type="text"
              value={upisKvota[6]}
              onChange={(e) => handleChangeKvote(6, e)}
            />
          </div>
          <div className={classes.eventualniDobitakRow}>
            {eventualniDobitak[6] == 0 ? "-" : eventualniDobitak[6]}
          </div>

          <div className={classes.prolazNeRow}>
            {prolaz[6] == 0 ? "-" : prolaz[6]}
          </div>
          <div className={classes.gubitakRow}>
            {gubitak[6] == 0 ? "-" : `- ${gubitak[6]}`}
          </div>
          <div className={classes.isplativostRow}>
            {/*{isplativostOdnosaUlogaiKvote[6] == 0 ? "-" : isplativostOdnosaUlogaiKvote[6]}*/}
          </div>
        </div>
        <div className={classes.row}>
          <div className={classes.upisUlogFirstRow}>
            <input
              type="text"
              value={ulog[7]}
              onChange={(e) => handleChange(7, e)}
            />
          </div>
          <div className={classes.upisKvotaRow}>
            <input
              type="text"
              value={upisKvota[7]}
              onChange={(e) => handleChangeKvote(7, e)}
            />
          </div>
          <div className={classes.eventualniDobitakRow}>
            {eventualniDobitak[7] == 0 ? "-" : eventualniDobitak[7]}
          </div>

          <div className={classes.prolazNeRow}>{prolaz[7]}</div>
          <div className={classes.gubitakRow}>
            {gubitak[7] == 0 ? "-" : `- ${gubitak[7]}`}
          </div>
          <div className={classes.isplativostRow}>
            {/*{isplativostOdnosaUlogaiKvote[7] == 0 ? "-" : isplativostOdnosaUlogaiKvote[7]}*/}
          </div>
        </div>
        <div className={classes.row}>
          <div className={classes.upisUlogFirstRow}>
            <input
              type="text"
              value={ulog[8]}
              onChange={(e) => handleChange(8, e)}
            />
          </div>
          <div className={classes.upisKvotaRow}>
            <input
              type="text"
              value={upisKvota[8]}
              onChange={(e) => handleChangeKvote(8, e)}
            />
          </div>
          <div className={classes.eventualniDobitakRow}>
            {eventualniDobitak[8] == 0 ? "-" : eventualniDobitak[8]}
          </div>

          <div className={classes.prolazNeRow}>{prolaz[8]}</div>
          <div className={classes.gubitakRow}>
            {gubitak[8] == 0 ? "-" : `- ${gubitak[8]}`}
          </div>
          <div className={classes.isplativostRow}>
            {/*{isplativostOdnosaUlogaiKvote[8] == 0 ? "-" : isplativostOdnosaUlogaiKvote[8]}*/}
          </div>
        </div>
        <div className={classes.row}>
          <div className={classes.upisUlogFirstRow}>
            <input
              type="text"
              value={ulog[9]}
              onChange={(e) => handleChange(9, e)}
            />
          </div>
          <div className={classes.upisKvotaRow}>
            <input
              type="text"
              value={upisKvota[9]}
              onChange={(e) => handleChangeKvote(9, e)}
            />
          </div>
          <div className={classes.eventualniDobitakRow}>
            {eventualniDobitak[9] == 0 ? "-" : eventualniDobitak[9]}
          </div>

          <div className={classes.prolazNeRow}>{prolaz[9]}</div>
          <div className={classes.gubitakRow}>
            {gubitak[9] == 0 ? "-" : `- ${gubitak[9]}`}
          </div>
          <div className={classes.isplativostRow}>
            {/*{isplativostOdnosaUlogaiKvote[9] == 0 ? "-" : isplativostOdnosaUlogaiKvote[9]}*/}
          </div>
        </div>
        <div className={classes.row}>
          <div className={classes.upisUlogFirstRow}>
            <input
              type="text"
              value={ulog[10]}
              onChange={(e) => handleChange(10, e)}
            />
          </div>
          <div className={classes.upisKvotaRow}>
            <input
              type="text"
              value={upisKvota[10]}
              onChange={(e) => handleChangeKvote(10, e)}
            />
          </div>
          <div className={classes.eventualniDobitakRow}>
            {eventualniDobitak[10] == 0 ? "-" : eventualniDobitak[10]}
          </div>

          <div className={classes.prolazNeRow}>{prolaz[10]}</div>
          <div className={classes.gubitakRow}>
            {gubitak[10] == 0 ? "-" : `- ${gubitak[10]}`}
          </div>
          <div className={classes.isplativostRow}>
            {/*{isplativostOdnosaUlogaiKvote[10] == 0 ? "-" : isplativostOdnosaUlogaiKvote[10]}*/}
          </div>
        </div>
        <div className={classes.row}>
          <div className={classes.upisUlogFirstRow}>
            <input
              type="text"
              value={ulog[11]}
              onChange={(e) => handleChange(11, e)}
            />
          </div>
          <div className={classes.upisKvotaRow}>
            <input
              type="text"
              value={upisKvota[11]}
              onChange={(e) => handleChangeKvote(11, e)}
            />
          </div>
          <div className={classes.eventualniDobitakRow}>
            {eventualniDobitak[11] == 0 ? "-" : eventualniDobitak[11]}
          </div>

          <div className={classes.prolazNeRow}>
            {prolaz[11] == 0 ? "-" : prolaz[11]}
          </div>
          <div className={classes.gubitakRow}>
            {gubitak[11] == 0 ? "-" : `- ${gubitak[11]}`}
          </div>
          <div className={classes.isplativostRow}>
            ULOG: {ukupanUlog == 0 ? "-" : ukupanUlog} x 2.00
          </div>
        </div>
        <div className={classes.row}>
          <div className={classes.upisUlogFirstRow}>
            <input
              type="text"
              value={ulog[12]}
              onChange={(e) => handleChange(12, e)}
            />
          </div>
          <div className={classes.upisKvotaRow}>
            <input
              type="text"
              value={upisKvota[12]}
              onChange={(e) => handleChangeKvote(12, e)}
            />
          </div>
          <div className={classes.eventualniDobitakRow}>
            {eventualniDobitak[12] == 0 ? "-" : eventualniDobitak[12]}
          </div>

          <div className={classes.prolazNeRow}>
            {prolaz[12] == 0 ? "-" : prolaz[12]}
          </div>
          <div className={classes.gubitakRow}>
            {gubitak[12] == 0 ? "-" : `- ${gubitak[12]}`}
          </div>
          <div className={classes.isplativostRow}>
            {/*{isplativostOdnosaUlogaiKvote[12] == 0 ? "-" : isplativostOdnosaUlogaiKvote[12]}*/}
          </div>
        </div>
        <div className={classes.row}>
          <div className={classes.upisUlogFirstRow}>
            <input
              type="text"
              value={ulog[13]}
              onChange={(e) => handleChange(13, e)}
            />
          </div>
          <div className={classes.upisKvotaRow}>
            <input
              type="text"
              value={upisKvota[13]}
              onChange={(e) => handleChangeKvote(13, e)}
            />
          </div>
          <div className={classes.eventualniDobitakRow}>
            {eventualniDobitak[13] == 0 ? "-" : eventualniDobitak[13]}
          </div>

          <div className={classes.prolazNeRow}>{prolaz[13]}</div>
          <div className={classes.gubitakRow}>
            {gubitak[13] == 0 ? "-" : `- ${gubitak[13]}`}
          </div>
          <div className={classes.isplativostRow}>
            {/*{isplativostOdnosaUlogaiKvote[13] == 0 ? "-" : isplativostOdnosaUlogaiKvote[13]}*/}
          </div>
        </div>
        <div className={classes.row}>
          <div className={classes.upisUlogFirstRow}>
            <input
              type="text"
              value={ulog[14]}
              onChange={(e) => handleChange(14, e)}
            />
          </div>
          <div className={classes.upisKvotaRow}>
            <input
              type="text"
              value={upisKvota[14]}
              onChange={(e) => handleChangeKvote(14, e)}
            />
          </div>
          <div className={classes.eventualniDobitakRow}>
            {eventualniDobitak[14] == 0 ? "-" : eventualniDobitak[14]}
          </div>

          <div className={classes.prolazNeRow}>{prolaz[14]}</div>
          <div className={classes.gubitakRow}>
            {gubitak[14] == 0 ? "-" : `- ${gubitak[14]}`}
          </div>
          <div className={classes.isplativostRow}>
            {/*{isplativostOdnosaUlogaiKvote[14] == 0 ? "-" : isplativostOdnosaUlogaiKvote[14]}*/}
          </div>
        </div>
        <div className={classes.row}>
          <div className={classes.upisUlogFirstRow}>
            <input
              type="text"
              value={ulog[15]}
              onChange={(e) => handleChange(15, e)}
            />
          </div>
          <div className={classes.upisKvotaRow}>
            <input
              type="text"
              value={upisKvota[15]}
              onChange={(e) => handleChangeKvote(15, e)}
            />
          </div>
          <div className={classes.eventualniDobitakRow}>
            {eventualniDobitak[15] == 0 ? "-" : eventualniDobitak[15]}
          </div>

          <div className={classes.prolazNeRow}>{prolaz[15]}</div>
          <div className={classes.gubitakRow}>
            {gubitak[15] == 0 ? "-" : `- ${gubitak[15]}`}
          </div>
          <div className={classes.isplativostRow}>
            {/*{isplativostOdnosaUlogaiKvote[15] == 0 ? "-" : isplativostOdnosaUlogaiKvote[15]}*/}
          </div>
        </div>
        <div className={classes.row}>
          <div className={classes.upisUlogFirstRow}>
            <input
              type="text"
              value={ulog[16]}
              onChange={(e) => handleChange(16, e)}
            />
          </div>
          <div className={classes.upisKvotaRow}>
            <input
              type="text"
              value={upisKvota[16]}
              onChange={(e) => handleChangeKvote(16, e)}
            />
          </div>
          <div className={classes.eventualniDobitakRow}>
            {eventualniDobitak[16] == 0 ? "-" : eventualniDobitak[16]}
          </div>

          <div className={classes.prolazNeRow}>{prolaz[16]}</div>
          <div className={classes.gubitakRow}>
            {gubitak[16] == 0 ? "-" : `- ${gubitak[16]}`}
          </div>
          <div className={classes.isplativostRow}>
            {/*{isplativostOdnosaUlogaiKvote[16] == 0 ? "-" : isplativostOdnosaUlogaiKvote[16]}*/}
          </div>
        </div>
        <div className={classes.row}>
          <div className={classes.upisUlogFirstRow}>
            <input
              type="text"
              value={ulog[17]}
              onChange={(e) => handleChange(17, e)}
            />
          </div>
          <div className={classes.upisKvotaRow}>
            <input
              type="text"
              value={upisKvota[17]}
              onChange={(e) => handleChangeKvote(17, e)}
            />
          </div>
          <div className={classes.eventualniDobitakRow}>
            {eventualniDobitak[17] == 0 ? "-" : eventualniDobitak[17]}
          </div>

          <div className={classes.prolazNeRow}>{prolaz[17]}</div>
          <div className={classes.gubitakRow}>
            {gubitak[17] == 0 ? "-" : `- ${gubitak[17]}`}
          </div>
          <div className={classes.isplativostRow}>
            {/*{isplativostOdnosaUlogaiKvote[17] == 0 ? "-" : isplativostOdnosaUlogaiKvote[17]}*/}
          </div>
        </div>
        <div className={classes.row}>
          <div className={classes.upisUlogFirstRow}>
            <input
              type="text"
              value={ulog[18]}
              onChange={(e) => handleChange(18, e)}
            />
          </div>
          <div className={classes.upisKvotaRow}>
            <input
              type="text"
              value={upisKvota[18]}
              onChange={(e) => handleChangeKvote(18, e)}
            />
          </div>
          <div className={classes.eventualniDobitakRow}>
            {eventualniDobitak[18] == 0 ? "-" : eventualniDobitak[18]}
          </div>

          <div className={classes.prolazNeRow}>{prolaz[18]}</div>
          <div className={classes.gubitakRow}>
            {gubitak[18] == 0 ? "-" : `- ${gubitak[18]}`}
          </div>
          <div className={classes.isplativostRow}>
            {/*{isplativostOdnosaUlogaiKvote[18] == 0 ? "-" : isplativostOdnosaUlogaiKvote[18]}*/}
          </div>
        </div>
        <div className={classes.row}>
          <div className={classes.upisUlogFirstRow}>
            <input
              type="text"
              value={ulog[19]}
              onChange={(e) => handleChange(19, e)}
            />
          </div>
          <div className={classes.upisKvotaRow}>
            <input
              type="text"
              value={upisKvota[19]}
              onChange={(e) => handleChangeKvote(19, e)}
            />
          </div>
          <div className={classes.eventualniDobitakRow}>
            {eventualniDobitak[19] == 0 ? "-" : eventualniDobitak[19]}
          </div>

          <div className={classes.prolazNeRow}>{prolaz[19]}</div>
          <div className={classes.gubitakRow}>
            {gubitak[19] == 0 ? "-" : `- ${gubitak[19]}`}
          </div>
          <div className={classes.isplativostRow}>
            {/*{isplativostOdnosaUlogaiKvote[19] == 0 ? "-" : isplativostOdnosaUlogaiKvote[19]}*/}
          </div>
        </div>
        <div className={classes.row}>
          <div className={classes.upisUlogFirstRow}>
            <input
              type="text"
              value={ulog[20]}
              onChange={(e) => handleChange(20, e)}
            />
          </div>
          <div className={classes.upisKvotaRow}>
            <input
              type="text"
              value={upisKvota[20]}
              onChange={(e) => handleChangeKvote(20, e)}
            />
          </div>
          <div className={classes.eventualniDobitakRow}>
            {eventualniDobitak[20] == 0 ? "-" : eventualniDobitak[20]}
          </div>

          <div className={classes.prolazNeRow}>{prolaz[20]}</div>
          <div className={classes.gubitakRow}>
            {gubitak[20] == 0 ? "-" : `- ${gubitak[20]}`}
          </div>
          <div className={classes.isplativostRow}>
            {/*{isplativostOdnosaUlogaiKvote[20] == 0 ? "-" : isplativostOdnosaUlogaiKvote[20]}*/}
          </div>
        </div>
        <div className={classes.row}>
          <div className={classes.upisUlogFirstRow}>
            <input
              type="text"
              value={ulog[21]}
              onChange={(e) => handleChange(21, e)}
            />
          </div>
          <div className={classes.upisKvotaRow}>
            <input
              type="text"
              value={upisKvota[21]}
              onChange={(e) => handleChangeKvote(21, e)}
            />
          </div>
          <div className={classes.eventualniDobitakRow}>
            {eventualniDobitak[21] == 0 ? "-" : eventualniDobitak[21]}
          </div>

          <div className={classes.prolazNeRow}>{prolaz[21]}</div>
          <div className={classes.gubitakRow}>
            {gubitak[21] == 0 ? "-" : `- ${gubitak[21]}`}
          </div>
          <div className={classes.isplativostRow}>
            {/*{isplativostOdnosaUlogaiKvote[21] == 0 ? "-" : isplativostOdnosaUlogaiKvote[21]}*/}
          </div>
        </div>
        <div className={classes.row}>
          <div className={classes.upisUlogFirstRow}>
            <input
              type="text"
              value={ulog[22]}
              onChange={(e) => handleChange(22, e)}
            />
          </div>
          <div className={classes.upisKvotaRow}>
            <input
              type="text"
              value={upisKvota[22]}
              onChange={(e) => handleChangeKvote(22, e)}
            />
          </div>
          <div className={classes.eventualniDobitakRow}>
            {eventualniDobitak[22] == 0 ? "-" : eventualniDobitak[22]}
          </div>

          <div className={classes.prolazNeRow}>{prolaz[22]}</div>
          <div className={classes.gubitakRow}>
            {gubitak[22] == 0 ? "-" : `- ${gubitak[22]}`}
          </div>
          <div className={classes.isplativostRow}>
            {/*{isplativostOdnosaUlogaiKvote[22] == 0 ? "-" : isplativostOdnosaUlogaiKvote[22]}*/}
          </div>
        </div>
        <div className={classes.row}>
          <div className={classes.upisUlogFirstRow}>
            <input
              type="text"
              value={ulog[23]}
              onChange={(e) => handleChange(23, e)}
            />
          </div>
          <div className={classes.upisKvotaRow}>
            <input
              type="text"
              value={upisKvota[23]}
              onChange={(e) => handleChangeKvote(23, e)}
            />
          </div>
          <div className={classes.eventualniDobitakRow}>
            {eventualniDobitak[23] == 0 ? "-" : eventualniDobitak[23]}
          </div>

          <div className={classes.prolazNeRow}>{prolaz[23]}</div>
          <div className={classes.gubitakRow}>
            {gubitak[23] == 0 ? "-" : `- ${gubitak[23]}`}
          </div>
          <div className={classes.isplativostRow}>
            {/*{isplativostOdnosaUlogaiKvote[23] == 0 ? "-" : isplativostOdnosaUlogaiKvote[23]}*/}
          </div>
        </div>
        <div className={classes.row}>
          <div className={classes.upisUlogFirstRow}>
            <input
              type="text"
              value={ulog[24]}
              onChange={(e) => handleChange(24, e)}
            />
          </div>
          <div className={classes.upisKvotaRow}>
            <input
              type="text"
              value={upisKvota[24]}
              onChange={(e) => handleChangeKvote(24, e)}
            />
          </div>
          <div className={classes.eventualniDobitakRow}>
            {eventualniDobitak[24] == 0 ? "-" : eventualniDobitak[24]}
          </div>

          <div className={classes.prolazNeRow}>{prolaz[24]}</div>
          <div className={classes.gubitakRow}>
            {gubitak[24] == 0 ? "-" : `- ${gubitak[24]}`}
          </div>
          <div className={classes.isplativostRow}>
            {/*{isplativostOdnosaUlogaiKvote[24] == 0 ? "-" : isplativostOdnosaUlogaiKvote[24]}*/}
          </div>
        </div>

        <div className={classes.row}>
          <div
            className={classes.footerFirstRow}
            style={{ height: "30px", background: "red" }}
          >
            <p
              style={{
                textAlign: "center",
                fontWeight: "bolder",
                marginTop: "7px",
              }}
            >
              ULOG:
            </p>
          </div>

          <div
            className={classes.footerSecondRow}
            style={{ height: "30px", background: "red" }}
          >
            <p
              style={{
                textAlign: "center",
                fontWeight: "bolder",
                marginTop: "7px",
              }}
            >
              {ukupanUlog}
            </p>
          </div>

          <div
            className={classes.footerThirdRow}
            style={{ height: "30px", background: "#002060" }}
          >
            <p
              style={{
                textAlign: "center",
                fontWeight: "bolder",
                marginTop: "7px",
              }}
            ></p>
          </div>

          <div
            className={classes.footerForthRow}
            style={{ height: "30px", background: "#002060" }}
          >
            <p
              style={{
                textAlign: "center",
                fontWeight: "bolder",
                marginTop: "7px",
              }}
            ></p>
          </div>

          <div
            className={classes.gubitakRow}
            style={{ height: "30px", background: "#002060" }}
          >
            <p
              style={{
                textAlign: "center",
                fontWeight: "bolder",
                marginTop: "7px",
              }}
            ></p>
          </div>

          <div
            className={classes.isplativostRow}
            style={{
              height: "30px",
              background: "#002060",
              border: "3px black solid",
            }}
          >
            <p
              style={{
                textAlign: "center",
                fontWeight: "bolder",
                marginTop: "0px",
              }}
            ></p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NegativnaProgresija;
