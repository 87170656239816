import Grupa1 from "../components/Grupa1";
import Grupa28 from "../components/Grupa28";
import Grupa308 from "../components/Grupa308";
import classes from "./bejzbol.module.css";
import { useState } from "react";

const Bejzbol = () => {
  //////zaglavlje////////
  const [snd, setSND] = useState("-");
  const [sod, setSOD] = useState("-");
  const [sog, setSOG] = useState("-");
  const [sng, setSNG] = useState("-");
  const [sodg, setSODG] = useState("-");
  const [sndg, setSNDG] = useState("-");
  const [sndSog, setSNDSOG] = useState("-");
  const [sodSng, setSODSNG] = useState("-");
  //////input polja////
  const [upisKvoteNaKonacanIshod1, setUpisKvoteNaKonacanIshod1] = useState();
  const [upisKvoteNaKonacanIshod2, setUpisKvoteNaKonacanIshod2] = useState();
  const [upisDomPlus, setUpisDomPlus] = useState();
  const [upisDomMinus, setUpisDomMinus] = useState();
  const [upisGostPlus, setUpisGostPlus] = useState();
  const [upisGostMinus, setUpisGostMinus] = useState();
  const [brUtakmicaDomacin, setBrUtakmicaDomacin] = useState();
  const [brUtakmicaGost, setBrUtakmicaGost] = useState();
  //////vrednosti za prikaz////
  const [procenatPobede1, setProcenatPobede1] = useState(0);
  const [procenatPobede2, setProcenatPobede2] = useState(0);
  const [opcijaTacnogRezultata1, setOpcijaTacnogRezultata1] = useState();
  const [opcijaTacnogRezultata2, setOpcijaTacnogRezultata2] = useState();
  const [bodMin, setBodMin] = useState(0);
  const [bodMax, setBodMax] = useState(0);
  const [bodBalans, setBodBalans] = useState(0);
  const [betkopredlog, setBetkoPredlog] = useState("x");

  return (
    <div className={classes.container}>
      <Grupa1
        imgText="BEJZBOL"
        imgBackgroundColorDark="#062C82"
        imgBackgroundColorLight="#0D2766"
        imgFontColor="#F9511D"
        label1="VREME POČETKA"
        label2="ZEMLJA"
        label3="DOMAĆIN"
        label4="TAKMIČENJE - LIGA"
        label5="GOST"
      />
      <Grupa28
        imgBackgroundColorDark="#062C82"
        imgBackgroundColorLight="#0D2766"
        imgFontColor="#F9511D"
        label1={snd}
        label2={sod}
        label3={sog}
        label4={sng}
        label5={sodg}
        label6={sndg}
        label7={sndSog}
        label8={sodSng}
        calculatorType={`Bejzbol`}
      />
      <Grupa308
        upisKvoteNaKonacanIshod1={upisKvoteNaKonacanIshod1}
        upisKvoteNaKonacanIshod2={upisKvoteNaKonacanIshod2}
        upisDomPlus={upisDomPlus}
        upisDomMinus={upisDomMinus}
        upisGostPlus={upisGostPlus}
        upisGostMinus={upisGostMinus}
        brUtakmicaDomacin={brUtakmicaDomacin}
        brUtakmicaGost={brUtakmicaGost}
        setSND={setSND}
        setSOD={setSOD}
        setSOG={setSOG}
        setSNG={setSNG}
        setSODG={setSODG}
        setSNDG={setSNDG}
        setSNDSOG={setSNDSOG}
        setSODSNG={setSODSNG}
        setUpisKvoteNaKonacanIshod1={setUpisKvoteNaKonacanIshod1}
        setUpisKvoteNaKonacanIshod2={setUpisKvoteNaKonacanIshod2}
        setUpisDomPlus={setUpisDomPlus}
        setUpisDomMinus={setUpisDomMinus}
        setUpisGostPlus={setUpisGostPlus}
        setUpisGostMinus={setUpisGostMinus}
        setBrUtakmicaDomacin={setBrUtakmicaDomacin}
        setBrUtakmicaGost={setBrUtakmicaGost}
        setProcenatPobede1={setProcenatPobede1}
        setProcenatPobede2={setProcenatPobede2}
        setOpcijaTacnogRezultata1={setOpcijaTacnogRezultata1}
        setOpcijaTacnogRezultata2={setOpcijaTacnogRezultata2}
        setBodMin={setBodMin}
        setBodMax={setBodMax}
        setBodBalans={setBodBalans}
        setBetkoPredlog={setBetkoPredlog}
      />
      <div className={classes.calculator}>
        <p className={classes.procenatPobede1}>
          {procenatPobede1 == 0 || isNaN(procenatPobede1)
            ? ""
            : procenatPobede1}{" "}
          {procenatPobede1 == 0 || isNaN(procenatPobede1) ? "" : "%"}
        </p>
        <p className={classes.procenatPobede2}>
          {procenatPobede2 == 0 || isNaN(procenatPobede2)
            ? ""
            : procenatPobede2}{" "}
          {procenatPobede2 == 0 || isNaN(procenatPobede2) ? "" : "%"}
        </p>
        <p className={classes.opcijaTacnogRezultata1}>
          {isNaN(opcijaTacnogRezultata1) ? "" : opcijaTacnogRezultata1}
        </p>
        <p className={classes.opcijaTacnogRezultata2}>
          {isNaN(opcijaTacnogRezultata2) ? "" : opcijaTacnogRezultata2}
        </p>
        <p className={classes.bodMin}>
          {bodMin == 0 || isNaN(bodMin) ? "" : bodMin}
        </p>
        <p className={classes.bodMax}>
          {bodMax == 0 || isNaN(bodMax) ? "" : bodMax}
        </p>
        <p className={classes.bodBalans}>
          {bodBalans == 0 || isNaN(bodBalans) ? "" : bodBalans}
        </p>
        <p className={classes.betkopredlog}>
          {betkopredlog == 0 || isNaN(betkopredlog) ? "" : betkopredlog}
        </p>
      </div>
    </div>
  );
};

export default Bejzbol;
