import React, { useCallback, useEffect, useRef, useState } from 'react'
import classes from './AdminAvatarGallery.module.css'
import Axios from 'axios'
import { Button, Modal, notification } from 'antd';

function AdminAvatarGallery() {
  const SERVER_URL = process.env.REACT_APP_SERVER_URL;
  const imageRef = useRef()

  // refetch
  const [refetch, setRefetch] = useState(false)
  // avatar images
  const [images, setImages] = useState([])
  // modal
  const [isDelModalVisible, setIsDelModalVisible] = useState(false)
  // selected item for delete
  const [selectedItem, setSelectedItem] = useState({})

  // ------- fetch functions -------

  const fetchImages = useCallback(async () => {
    try {
      await Axios.get(`${SERVER_URL}/images/get-gallery`, { withCredentials: false }).then(res => {
        if (res.status === 200) {
          const onlyAvatars = res.data.filter(item => item.type === 'avatar')
          setImages(onlyAvatars)
        }
      }).catch(error => {
        console.error(error)
      })
    } catch (error) {
      console.error(error)
    }
  }, [])

  // ------- useEffect -------

  useEffect(() => {
    fetchImages()

    return () => { }
  }, [refetch])

  // ------- functions -------

  const handleImageUpload = async (e) => {
    const formData = new FormData()
    const payload = imageRef.current.files[0]
    formData.append('file', payload)
    formData.imageType = 'avatar'

    try {
      await Axios.post(`${SERVER_URL}/images/upload-image`, formData, { withCredentials: false, headers: { type: 'avatar' } }).then(res => {
        if (res.status === 201) {
          setRefetch(prev => !prev)
        }
      }).catch(error => {
        console.error(error)
      })
    } catch (error) {
      console.error(error)
    }
  }

  const handleDeleteImage = async (item) => {
    const id = item._id
    try {
      await Axios.delete(`${SERVER_URL}/images/delete/${id}`, { withCredentials: false }).then(res => {
        if (res.status === 200) {

          // notification
          notification.success({
            message: 'Slika uspešno obrisana',
            placement: 'topRight'
          })
          // refetch
          setRefetch(prev => !prev)
        }
      })
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <div className={classes.container}>
      <div className={classes.imageUploadContainer}>
        <h1 className={classes.title}>
          Avatar Galerija:
        </h1>

        <div className={classes.imageUploadButton}>
          <input
            ref={imageRef}
            type='file'
            id='avatarImage'
            name='avatarImage'
            accept="image/*"
            className={classes.imageInput}
            onChange={(e) => handleImageUpload(e)}
          />
          <label htmlFor='avatarImage'>Objavi sliku</label>
        </div>

        {images && images.length > 0 && (
          <div className={classes.imagesInfo}>
            <h1>{images.length} slike/a</h1>
          </div>
        )}
      </div>

      <div className={classes.imagesContainer}>
        {images && images.length > 0 && images.map((item, index) => {
          return (
            <div key={index}>
              <img
                className={classes.avatarImage}
                src={`${SERVER_URL}/${item.url}`}
                alt={`image ${index + 1}`}
              />
              <button
                className={classes.delButton}
                onClick={() => {
                  setSelectedItem(item)
                  setIsDelModalVisible(true)
                }}
              >
                <span>Obriši</span>
              </button>
            </div>
          )
        })}
      </div>

      <Modal
        footer={[]}
        open={isDelModalVisible}
        title="Da li ste sigurni da želite da obrišete sliku?"
        onCancel={() => {
          setIsDelModalVisible(false);
        }}
      >
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-evenly",
            marginTop: "20px",
          }}
        >
          <Button
            onClick={(e) => {
              handleDeleteImage(selectedItem);
              setIsDelModalVisible(false);
            }}
          >
            Obriši
          </Button>
          <Button
            onClick={(e) => {
              setIsDelModalVisible(false);
            }}
          >
            Odustani
          </Button>
        </div>
      </Modal>
    </div>
  )
}

export default AdminAvatarGallery
