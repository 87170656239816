import Grupa3 from "../components/Grupa3";
import Grupa21 from "../components/Grupa21";
import classes from "./poredenjeKvotaMarza.module.css";
import { useState } from "react";

const PoredenjeKvotaMarza = () => {
  const [fix, setFix] = useState(0);
  const [ug02, setUG02] = useState(0);

  return (
    <div className={classes.container}>
      <Grupa3
        imgText="POREDJENJE KVOTA - MARŽA"
        imgBackgroundColorDark="#082C7E"
        imgBackgroundColorLight="#0D2869"
        imgFontColor="#38F81E"
      />
      <Grupa21
        imgBackgroundColorDark="#082C7E"
        imgBackgroundColorLight="#0D2869"
        imgFontColor="#38F81E"
        label1={fix}
        label2={ug02}
        linkOpisKalkulatora="/bet_kalkulatori_tutorijal/645b0d72cd9c8da743a1956d"
      />

        <div className={classes.calculator} style={{marginLeft:"10px", display:"flex", flexDirection:"column", fontWeight:"bolder"}}>
            <div style={{width:"100%", display:"flex", flexDirection:"column", textAlign:"center", color:"darkblue"}}>
                <div style={{width:"100%", display:"flex", flexDirection:"row"}}>

                    <div style={{justifyContent: "spaceBetween"}}>
                        <div className={classes.celijaSrednje} style={{ width:"315px", background:"black"}}>
                            <p style={{marginTop:"20px", fontSize:"25px", color:"#91FB86"}}>UPIS KLADIONICE</p>
                        </div>
                    </div>

                    <div style={{justifyContent: "spaceBetween"}}>
                        <div className={classes} style={{ width:"460px", height:"35px", background:"#91FB86", overflow:"hidden", whiteSpace:"nowrap"}}>
                            <p style={{marginTop:"7px", fontSize:"20px", color:"darkblue"}}>TAČKA ODVAJA DECIMALU</p>
                        </div>
                        <div style={{width:"100%", display:"flex", flexDirection:"row"}}>
                            <div className={classes} style={{ width:"72px", height:"35px", background:"#91FB86",}}>
                                <p style={{marginTop:"7px", fontSize:"20px", color:"darkblue"}}>KL 1</p>
                            </div>
                            <div className={classes} style={{ width:"72px", height:"35px", background:"#91FB86",}}>
                                <p style={{marginTop:"7px", fontSize:"20px", color:"darkblue"}}>KL X</p>
                            </div>
                            <div className={classes} style={{ width:"72px", height:"35px", background:"#91FB86",}}>
                                <p style={{marginTop:"7px", fontSize:"20px", color:"darkblue"}}>KL 2</p>
                            </div>
                            <div className={classes} style={{ width:"82px", height:"35px", background:"#91FB86",}}>
                                <p style={{marginTop:"7px", fontSize:"20px", color:"darkblue"}}>K1 1</p>
                            </div>
                            <div className={classes} style={{ width:"82px", height:"35px", background:"#91FB86",}}>
                                <p style={{marginTop:"7px", fontSize:"20px", color:"darkblue"}}>KL X</p>
                            </div>
                            <div className={classes} style={{ width:"80px", height:"35px", background:"#91FB86",}}>
                                <p style={{marginTop:"7px", fontSize:"20px", color:"darkblue"}}>KL 2</p>
                            </div>
                        </div>
                    </div>

                    <div style={{justifyContent: "spaceBetween"}}>
                        <div className={classes.celijaSrednje} style={{ width:"175px", background:"black"}}>
                            <p style={{marginTop:"20px", fontSize:"25px", color:"#91FB86"}}>MARŽE NA FIX</p>
                        </div>
                    </div>
                    <div style={{justifyContent: "spaceBetween"}}>
                        <div className={classes} style={{ width:"308", height:"35px", background:"#91FB86", overflow:"hidden", whiteSpace:"nowrap"}}>
                            <p style={{marginTop:"7px", fontSize:"20px", color:"darkblue"}}>TAČKA ODVAJA DECIMALU</p>
                        </div>
                        <div style={{width:"100%", display:"flex", flexDirection:"row"}}>
                            <div className={classes} style={{ width:"72px", height:"35px", background:"#91FB86",}}>
                                <p style={{marginTop:"7px", fontSize:"20px", color:"darkblue"}}>UG 0-2</p>
                            </div>
                            <div className={classes} style={{ width:"72px", height:"35px", background:"#91FB86",}}>
                                <p style={{marginTop:"5px", fontSize:"20px", color:"darkblue"}}>UG 3+</p>
                            </div>
                            <div className={classes} style={{ width:"82px", height:"35px", background:"#91FB86",}}>
                                <p style={{marginTop:"5px", fontSize:"20px", color:"darkblue"}}>UG 0-2</p>
                            </div>
                            <div className={classes} style={{ width:"82px", height:"35px", background:"#91FB86",}}>
                                <p style={{marginTop:"5px", fontSize:"20px", color:"darkblue"}}>UG 3+</p>
                            </div>
                        </div>
                    </div>
                    <div style={{justifyContent: "spaceBetween"}}>
                        <div className={classes.celijaSrednje} style={{ width:"172px", background:"black"}}>
                            <p style={{marginTop:"20px", fontSize:"25px", color:"#91FB86"}}>MARŽE NA 2.5</p>
                        </div>
                    </div>
                </div>
            </div>




            <div style={{width:"100%", display:"flex", flexDirection:"column", textAlign:"center", color:"darkblue"}}>
                <div style={{width:"100%", height:"20px", display:"flex", flexDirection:"row"}}>

                    <div style={{justifyContent: "spaceBetween"}}>
                        <div className={classes.celijaTabele} style={{ width:"312px", borderTop:"none"}}>
                            <input className={classes.inputCelija} style={{width:"308px", height:"28px"}}></input>
                        </div>
                        <div className={classes.celijaTabele} style={{ width:"312px", borderTop:"none"}}>
                            <input className={classes.inputCelija} style={{width:"308px", height:"28px"}}></input>
                        </div>
                        <div className={classes.celijaTabele} style={{ width:"312px", borderTop:"none"}}>
                            <input className={classes.inputCelija} style={{width:"308px", height:"28px"}}></input>
                        </div>
                        <div className={classes.celijaTabele} style={{ width:"312px", borderTop:"none"}}>
                            <input className={classes.inputCelija} style={{width:"308px", height:"28px"}}></input>
                        </div>
                        <div className={classes.celijaTabele} style={{ width:"312px", borderTop:"none"}}>
                            <input className={classes.inputCelija} style={{width:"308px", height:"28px"}}></input>
                        </div>
                        <div className={classes.celijaTabele} style={{ width:"312px", borderTop:"none"}}>
                            <input className={classes.inputCelija} style={{width:"308px", height:"28px"}}></input>
                        </div>
                        <div className={classes.celijaTabele} style={{ width:"312px", borderTop:"none"}}>
                            <input className={classes.inputCelija} style={{width:"308px", height:"28px"}}></input>
                        </div>
                        <div className={classes.celijaTabele} style={{ width:"312px", borderTop:"none"}}>
                            <input className={classes.inputCelija} style={{width:"308px", height:"28px"}}></input>
                        </div>
                        <div className={classes.celijaTabele} style={{ width:"312px", borderTop:"none"}}>
                            <input className={classes.inputCelija} style={{width:"308px", height:"28px"}}></input>
                        </div>
                        <div className={classes.celijaTabele} style={{ width:"312px", borderTop:"none"}}>
                            <input className={classes.inputCelija} style={{width:"308px", height:"28px"}}></input>
                        </div>
                        <div className={classes.celijaTabele} style={{ width:"312px", borderTop:"none"}}>
                            <input className={classes.inputCelija} style={{width:"308px", height:"28px"}}></input>
                        </div>
                        <div className={classes.celijaTabele} style={{ width:"312px", borderTop:"none"}}>
                            <input className={classes.inputCelija} style={{width:"308px", height:"28px"}}></input>
                        </div>
                        <div className={classes.celijaTabele} style={{ width:"312px", borderTop:"none"}}>
                            <input className={classes.inputCelija} style={{width:"308px", height:"28px"}}></input>
                        </div>
                        <div style={{ width:"316px", background:"black", height:"35px"}}></div>

                    </div>

                    <div style={{justifyContent: "spaceBetween"}}>
                        <div className={classes.celijaTabele} style={{ width:"70px", borderTop:"none", borderLeft:"none"}}>
                            <input className={classes.inputCelija} style={{width:"66px", height:"28px"}}></input>
                        </div>
                        <div className={classes.celijaTabele} style={{ width:"70px", borderTop:"none", borderLeft:"none"}}>
                            <input className={classes.inputCelija} style={{width:"66px", height:"28px"}}></input>
                        </div>
                        <div className={classes.celijaTabele} style={{ width:"70px", borderTop:"none", borderLeft:"none"}}>
                            <input className={classes.inputCelija} style={{width:"66px", height:"28px"}}></input>
                        </div>
                        <div className={classes.celijaTabele} style={{ width:"70px", borderTop:"none", borderLeft:"none"}}>
                            <input className={classes.inputCelija} style={{width:"66px", height:"28px"}}></input>
                        </div>
                        <div className={classes.celijaTabele} style={{ width:"70px", borderTop:"none", borderLeft:"none"}}>
                            <input className={classes.inputCelija} style={{width:"66px", height:"28px"}}></input>
                        </div>
                        <div className={classes.celijaTabele} style={{ width:"70px", borderTop:"none", borderLeft:"none"}}>
                            <input className={classes.inputCelija} style={{width:"66px", height:"28px"}}></input>
                        </div>
                        <div className={classes.celijaTabele} style={{ width:"70px", borderTop:"none", borderLeft:"none"}}>
                            <input className={classes.inputCelija} style={{width:"66px", height:"28px"}}></input>
                        </div>
                        <div className={classes.celijaTabele} style={{ width:"70px", borderTop:"none", borderLeft:"none"}}>
                            <input className={classes.inputCelija} style={{width:"66px", height:"28px"}}></input>
                        </div>
                        <div className={classes.celijaTabele} style={{ width:"70px", borderTop:"none", borderLeft:"none"}}>
                            <input className={classes.inputCelija} style={{width:"66px", height:"28px"}}></input>
                        </div>
                        <div className={classes.celijaTabele} style={{ width:"70px", borderTop:"none", borderLeft:"none"}}>
                            <input className={classes.inputCelija} style={{width:"66px", height:"28px"}}></input>
                        </div>
                        <div className={classes.celijaTabele} style={{ width:"70px", borderTop:"none", borderLeft:"none"}}>
                            <input className={classes.inputCelija} style={{width:"66px", height:"28px"}}></input>
                        </div>
                        <div className={classes.celijaTabele} style={{ width:"70px", borderTop:"none", borderLeft:"none"}}>
                            <input className={classes.inputCelija} style={{width:"66px", height:"28px"}}></input>
                        </div>
                        <div className={classes.celijaTabele} style={{ width:"70px", borderTop:"none", borderLeft:"none"}}>
                            <input className={classes.inputCelija} style={{width:"66px", height:"28px"}}></input>
                        </div>
                        <div style={{ width:"72px", background:"#91FB86", height:"35px"}}></div>

                    </div>
                    <div style={{justifyContent: "spaceBetween"}}>
                        <div className={classes.celijaTabele} style={{ width:"70px", borderTop:"none", borderLeft:"none"}}>
                            <input className={classes.inputCelija} style={{width:"66px", height:"28px"}}></input>
                        </div>
                        <div className={classes.celijaTabele} style={{ width:"70px", borderTop:"none", borderLeft:"none"}}>
                            <input className={classes.inputCelija} style={{width:"66px", height:"28px"}}></input>
                        </div>
                        <div className={classes.celijaTabele} style={{ width:"70px", borderTop:"none", borderLeft:"none"}}>
                            <input className={classes.inputCelija} style={{width:"66px", height:"28px"}}></input>
                        </div>
                        <div className={classes.celijaTabele} style={{ width:"70px", borderTop:"none", borderLeft:"none"}}>
                            <input className={classes.inputCelija} style={{width:"66px", height:"28px"}}></input>
                        </div>
                        <div className={classes.celijaTabele} style={{ width:"70px", borderTop:"none", borderLeft:"none"}}>
                            <input className={classes.inputCelija} style={{width:"66px", height:"28px"}}></input>
                        </div>
                        <div className={classes.celijaTabele} style={{ width:"70px", borderTop:"none", borderLeft:"none"}}>
                            <input className={classes.inputCelija} style={{width:"66px", height:"28px"}}></input>
                        </div>
                        <div className={classes.celijaTabele} style={{ width:"70px", borderTop:"none", borderLeft:"none"}}>
                            <input className={classes.inputCelija} style={{width:"66px", height:"28px"}}></input>
                        </div>
                        <div className={classes.celijaTabele} style={{ width:"70px", borderTop:"none", borderLeft:"none"}}>
                            <input className={classes.inputCelija} style={{width:"66px", height:"28px"}}></input>
                        </div>
                        <div className={classes.celijaTabele} style={{ width:"70px", borderTop:"none", borderLeft:"none"}}>
                            <input className={classes.inputCelija} style={{width:"66px", height:"28px"}}></input>
                        </div>
                        <div className={classes.celijaTabele} style={{ width:"70px", borderTop:"none", borderLeft:"none"}}>
                            <input className={classes.inputCelija} style={{width:"66px", height:"28px"}}></input>
                        </div>
                        <div className={classes.celijaTabele} style={{ width:"70px", borderTop:"none", borderLeft:"none"}}>
                            <input className={classes.inputCelija} style={{width:"66px", height:"28px"}}></input>
                        </div>
                        <div className={classes.celijaTabele} style={{ width:"70px", borderTop:"none", borderLeft:"none"}}>
                            <input className={classes.inputCelija} style={{width:"66px", height:"28px"}}></input>
                        </div>
                        <div className={classes.celijaTabele} style={{ width:"70px", borderTop:"none", borderLeft:"none"}}>
                            <input className={classes.inputCelija} style={{width:"66px", height:"28px"}}></input>
                        </div>
                        <div style={{ width:"72px", background:"#91FB86", height:"35px"}}></div>

                    </div>
                    <div style={{justifyContent: "spaceBetween"}}>
                        <div className={classes.celijaTabele} style={{ width:"70px", borderTop:"none", borderLeft:"none"}}>
                            <input className={classes.inputCelija} style={{width:"66px", height:"28px"}}></input>
                        </div>
                        <div className={classes.celijaTabele} style={{ width:"70px", borderTop:"none", borderLeft:"none"}}>
                            <input className={classes.inputCelija} style={{width:"66px", height:"28px"}}></input>
                        </div>
                        <div className={classes.celijaTabele} style={{ width:"70px", borderTop:"none", borderLeft:"none"}}>
                            <input className={classes.inputCelija} style={{width:"66px", height:"28px"}}></input>
                        </div>
                        <div className={classes.celijaTabele} style={{ width:"70px", borderTop:"none", borderLeft:"none"}}>
                            <input className={classes.inputCelija} style={{width:"66px", height:"28px"}}></input>
                        </div>
                        <div className={classes.celijaTabele} style={{ width:"70px", borderTop:"none", borderLeft:"none"}}>
                            <input className={classes.inputCelija} style={{width:"66px", height:"28px"}}></input>
                        </div>
                        <div className={classes.celijaTabele} style={{ width:"70px", borderTop:"none", borderLeft:"none"}}>
                            <input className={classes.inputCelija} style={{width:"66px", height:"28px"}}></input>
                        </div>
                        <div className={classes.celijaTabele} style={{ width:"70px", borderTop:"none", borderLeft:"none"}}>
                            <input className={classes.inputCelija} style={{width:"66px", height:"28px"}}></input>
                        </div>
                        <div className={classes.celijaTabele} style={{ width:"70px", borderTop:"none", borderLeft:"none"}}>
                            <input className={classes.inputCelija} style={{width:"66px", height:"28px"}}></input>
                        </div>
                        <div className={classes.celijaTabele} style={{ width:"70px", borderTop:"none", borderLeft:"none"}}>
                            <input className={classes.inputCelija} style={{width:"66px", height:"28px"}}></input>
                        </div>
                        <div className={classes.celijaTabele} style={{ width:"70px", borderTop:"none", borderLeft:"none"}}>
                            <input className={classes.inputCelija} style={{width:"66px", height:"28px"}}></input>
                        </div>
                        <div className={classes.celijaTabele} style={{ width:"70px", borderTop:"none", borderLeft:"none"}}>
                            <input className={classes.inputCelija} style={{width:"66px", height:"28px"}}></input>
                        </div>
                        <div className={classes.celijaTabele} style={{ width:"70px", borderTop:"none", borderLeft:"none"}}>
                            <input className={classes.inputCelija} style={{width:"66px", height:"28px"}}></input>
                        </div>
                        <div className={classes.celijaTabele} style={{ width:"70px", borderTop:"none", borderLeft:"none"}}>
                            <input className={classes.inputCelija} style={{width:"66px", height:"28px"}}></input>
                        </div>
                        <div style={{ width:"72px", background:"#91FB86", height:"35px"}}></div>

                    </div>
                    <div style={{justifyContent: "spaceBetween"}}>
                        <div className={classes.celijaTabele} style={{ width:"80px", borderTop:"none", borderLeft:"none", background:"#91FB86"}}><p style={{marginTop:"6px", color:"black"}}>asd</p></div>
                        <div className={classes.celijaTabele} style={{ width:"80px", borderTop:"none", borderLeft:"none", background:"#91FB86"}}><p style={{marginTop:"6px", color:"black"}}>asd</p></div>
                        <div className={classes.celijaTabele} style={{ width:"80px", borderTop:"none", borderLeft:"none", background:"#91FB86"}}><p style={{marginTop:"6px", color:"black"}}>asd</p></div>
                        <div className={classes.celijaTabele} style={{ width:"80px", borderTop:"none", borderLeft:"none", background:"#91FB86"}}><p style={{marginTop:"6px", color:"black"}}>asd</p></div>
                        <div className={classes.celijaTabele} style={{ width:"80px", borderTop:"none", borderLeft:"none", background:"#91FB86"}}><p style={{marginTop:"6px", color:"black"}}>asd</p></div>
                        <div className={classes.celijaTabele} style={{ width:"80px", borderTop:"none", borderLeft:"none", background:"#91FB86"}}><p style={{marginTop:"6px", color:"black"}}>asd</p></div>
                        <div className={classes.celijaTabele} style={{ width:"80px", borderTop:"none", borderLeft:"none", background:"#91FB86"}}><p style={{marginTop:"6px", color:"black"}}>asd</p></div>
                        <div className={classes.celijaTabele} style={{ width:"80px", borderTop:"none", borderLeft:"none", background:"#91FB86"}}><p style={{marginTop:"6px", color:"black"}}>asd</p></div>
                        <div className={classes.celijaTabele} style={{ width:"80px", borderTop:"none", borderLeft:"none", background:"#91FB86"}}><p style={{marginTop:"6px", color:"black"}}>asd</p></div>
                        <div className={classes.celijaTabele} style={{ width:"80px", borderTop:"none", borderLeft:"none", background:"#91FB86"}}><p style={{marginTop:"6px", color:"black"}}>asd</p></div>
                        <div className={classes.celijaTabele} style={{ width:"80px", borderTop:"none", borderLeft:"none", background:"#91FB86"}}><p style={{marginTop:"6px", color:"black"}}>asd</p></div>
                        <div className={classes.celijaTabele} style={{ width:"80px", borderTop:"none", borderLeft:"none", background:"#91FB86"}}><p style={{marginTop:"6px", color:"black"}}>asd</p></div>
                        <div className={classes.celijaTabele} style={{ width:"80px", borderTop:"none", borderLeft:"none", background:"#91FB86"}}><p style={{marginTop:"6px", color:"black"}}>asd</p></div>
                        <div style={{ width:"82px", background:"#91FB86", height:"35px"}}></div>
                    </div>

                    <div style={{justifyContent: "spaceBetween"}}>
                        <div className={classes.celijaTabele} style={{ width:"80px", borderTop:"none", borderLeft:"none", background:"#91FB86"}}><p style={{marginTop:"6px", color:"black"}}>asd</p></div>
                        <div className={classes.celijaTabele} style={{ width:"80px", borderTop:"none", borderLeft:"none", background:"#91FB86"}}><p style={{marginTop:"6px", color:"black"}}>asd</p></div>
                        <div className={classes.celijaTabele} style={{ width:"80px", borderTop:"none", borderLeft:"none", background:"#91FB86"}}><p style={{marginTop:"6px", color:"black"}}>asd</p></div>
                        <div className={classes.celijaTabele} style={{ width:"80px", borderTop:"none", borderLeft:"none", background:"#91FB86"}}><p style={{marginTop:"6px", color:"black"}}>asd</p></div>
                        <div className={classes.celijaTabele} style={{ width:"80px", borderTop:"none", borderLeft:"none", background:"#91FB86"}}><p style={{marginTop:"6px", color:"black"}}>asd</p></div>
                        <div className={classes.celijaTabele} style={{ width:"80px", borderTop:"none", borderLeft:"none", background:"#91FB86"}}><p style={{marginTop:"6px", color:"black"}}>asd</p></div>
                        <div className={classes.celijaTabele} style={{ width:"80px", borderTop:"none", borderLeft:"none", background:"#91FB86"}}><p style={{marginTop:"6px", color:"black"}}>asd</p></div>
                        <div className={classes.celijaTabele} style={{ width:"80px", borderTop:"none", borderLeft:"none", background:"#91FB86"}}><p style={{marginTop:"6px", color:"black"}}>asd</p></div>
                        <div className={classes.celijaTabele} style={{ width:"80px", borderTop:"none", borderLeft:"none", background:"#91FB86"}}><p style={{marginTop:"6px", color:"black"}}>asd</p></div>
                        <div className={classes.celijaTabele} style={{ width:"80px", borderTop:"none", borderLeft:"none", background:"#91FB86"}}><p style={{marginTop:"6px", color:"black"}}>asd</p></div>
                        <div className={classes.celijaTabele} style={{ width:"80px", borderTop:"none", borderLeft:"none", background:"#91FB86"}}><p style={{marginTop:"6px", color:"black"}}>asd</p></div>
                        <div className={classes.celijaTabele} style={{ width:"80px", borderTop:"none", borderLeft:"none", background:"#91FB86"}}><p style={{marginTop:"6px", color:"black"}}>asd</p></div>
                        <div className={classes.celijaTabele} style={{ width:"80px", borderTop:"none", borderLeft:"none", background:"#91FB86"}}><p style={{marginTop:"6px", color:"black"}}>asd</p></div>
                        <div style={{ width:"82px", background:"#91FB86", height:"35px"}}></div>
                    </div>

                    <div style={{justifyContent: "spaceBetween"}}>
                        <div className={classes.celijaTabele} style={{ width:"80px", borderTop:"none", borderLeft:"none", background:"#91FB86"}}><p style={{marginTop:"6px", color:"black"}}>asd</p></div>
                        <div className={classes.celijaTabele} style={{ width:"80px", borderTop:"none", borderLeft:"none", background:"#91FB86"}}><p style={{marginTop:"6px", color:"black"}}>asd</p></div>
                        <div className={classes.celijaTabele} style={{ width:"80px", borderTop:"none", borderLeft:"none", background:"#91FB86"}}><p style={{marginTop:"6px", color:"black"}}>asd</p></div>
                        <div className={classes.celijaTabele} style={{ width:"80px", borderTop:"none", borderLeft:"none", background:"#91FB86"}}><p style={{marginTop:"6px", color:"black"}}>asd</p></div>
                        <div className={classes.celijaTabele} style={{ width:"80px", borderTop:"none", borderLeft:"none", background:"#91FB86"}}><p style={{marginTop:"6px", color:"black"}}>asd</p></div>
                        <div className={classes.celijaTabele} style={{ width:"80px", borderTop:"none", borderLeft:"none", background:"#91FB86"}}><p style={{marginTop:"6px", color:"black"}}>asd</p></div>
                        <div className={classes.celijaTabele} style={{ width:"80px", borderTop:"none", borderLeft:"none", background:"#91FB86"}}><p style={{marginTop:"6px", color:"black"}}>asd</p></div>
                        <div className={classes.celijaTabele} style={{ width:"80px", borderTop:"none", borderLeft:"none", background:"#91FB86"}}><p style={{marginTop:"6px", color:"black"}}>asd</p></div>
                        <div className={classes.celijaTabele} style={{ width:"80px", borderTop:"none", borderLeft:"none", background:"#91FB86"}}><p style={{marginTop:"6px", color:"black"}}>asd</p></div>
                        <div className={classes.celijaTabele} style={{ width:"80px", borderTop:"none", borderLeft:"none", background:"#91FB86"}}><p style={{marginTop:"6px", color:"black"}}>asd</p></div>
                        <div className={classes.celijaTabele} style={{ width:"80px", borderTop:"none", borderLeft:"none", background:"#91FB86"}}><p style={{marginTop:"6px", color:"black"}}>asd</p></div>
                        <div className={classes.celijaTabele} style={{ width:"80px", borderTop:"none", borderLeft:"none", background:"#91FB86"}}><p style={{marginTop:"6px", color:"black"}}>asd</p></div>
                        <div className={classes.celijaTabele} style={{ width:"80px", borderTop:"none", borderLeft:"none", background:"#91FB86"}}><p style={{marginTop:"6px", color:"black"}}>asd</p></div>
                        <div style={{ width:"82px", background:"#91FB86", height:"35px"}}></div>
                    </div>

                    <div style={{justifyContent: "spaceBetween"}}>
                        <div className={classes.celijaTabele} style={{ width:"170px", borderTop:"none", borderLeft:"none", background:"black", marginLeft:"10px solid black"}}><p style={{marginTop:"4px", color:"#91FB86", fontSize:"22px"}}>asd</p></div>
                        <div className={classes.celijaTabele} style={{ width:"170px", borderTop:"none", borderLeft:"none", background:"black"}}><p style={{marginTop:"4px", color:"#91FB86", fontSize:"22px"}}>asd</p></div>
                        <div className={classes.celijaTabele} style={{ width:"170px", borderTop:"none", borderLeft:"none", background:"black"}}><p style={{marginTop:"4px", color:"#91FB86", fontSize:"22px"}}>asd</p></div>
                        <div className={classes.celijaTabele} style={{ width:"170px", borderTop:"none", borderLeft:"none", background:"black"}}><p style={{marginTop:"4px", color:"#91FB86", fontSize:"22px"}}>asd</p></div>
                        <div className={classes.celijaTabele} style={{ width:"170px", borderTop:"none", borderLeft:"none", background:"black"}}><p style={{marginTop:"4px", color:"#91FB86", fontSize:"22px"}}>asd</p></div>
                        <div className={classes.celijaTabele} style={{ width:"170px", borderTop:"none", borderLeft:"none", background:"black"}}><p style={{marginTop:"4px", color:"#91FB86", fontSize:"22px"}}>asd</p></div>
                        <div className={classes.celijaTabele} style={{ width:"170px", borderTop:"none", borderLeft:"none", background:"black"}}><p style={{marginTop:"4px", color:"#91FB86", fontSize:"22px"}}>asd</p></div>
                        <div className={classes.celijaTabele} style={{ width:"170px", borderTop:"none", borderLeft:"none", background:"black"}}><p style={{marginTop:"4px", color:"#91FB86", fontSize:"22px"}}>asd</p></div>
                        <div className={classes.celijaTabele} style={{ width:"170px", borderTop:"none", borderLeft:"none", background:"black"}}><p style={{marginTop:"4px", color:"#91FB86", fontSize:"22px"}}>asd</p></div>
                        <div className={classes.celijaTabele} style={{ width:"170px", borderTop:"none", borderLeft:"none", background:"black"}}><p style={{marginTop:"4px", color:"#91FB86", fontSize:"22px"}}>asd</p></div>
                        <div className={classes.celijaTabele} style={{ width:"170px", borderTop:"none", borderLeft:"none", background:"black"}}><p style={{marginTop:"4px", color:"#91FB86", fontSize:"22px"}}>asd</p></div>
                        <div className={classes.celijaTabele} style={{ width:"170px", borderTop:"none", borderLeft:"none", background:"black"}}><p style={{marginTop:"4px", color:"#91FB86", fontSize:"22px"}}>asd</p></div>
                        <div className={classes.celijaTabele} style={{ width:"170px", borderTop:"none", borderLeft:"none", background:"black"}}><p style={{marginTop:"4px", color:"#91FB86", fontSize:"22px"}}>asd</p></div>
                        <div style={{ width:"172px", background:"black", height:"35px"}}></div>
                    </div>

                    <div style={{justifyContent: "spaceBetween"}}>
                        <div className={classes.celijaTabele} style={{ width:"70px", borderTop:"none", borderLeft:"none"}}>
                            <input className={classes.inputCelija} style={{width:"66px", height:"28px"}}></input>
                        </div>
                        <div className={classes.celijaTabele} style={{ width:"70px", borderTop:"none", borderLeft:"none"}}>
                            <input className={classes.inputCelija} style={{width:"66px", height:"28px"}}></input>
                        </div>
                        <div className={classes.celijaTabele} style={{ width:"70px", borderTop:"none", borderLeft:"none"}}>
                            <input className={classes.inputCelija} style={{width:"66px", height:"28px"}}></input>
                        </div>
                        <div className={classes.celijaTabele} style={{ width:"70px", borderTop:"none", borderLeft:"none"}}>
                            <input className={classes.inputCelija} style={{width:"66px", height:"28px"}}></input>
                        </div>
                        <div className={classes.celijaTabele} style={{ width:"70px", borderTop:"none", borderLeft:"none"}}>
                            <input className={classes.inputCelija} style={{width:"66px", height:"28px"}}></input>
                        </div>
                        <div className={classes.celijaTabele} style={{ width:"70px", borderTop:"none", borderLeft:"none"}}>
                            <input className={classes.inputCelija} style={{width:"66px", height:"28px"}}></input>
                        </div>
                        <div className={classes.celijaTabele} style={{ width:"70px", borderTop:"none", borderLeft:"none"}}>
                            <input className={classes.inputCelija} style={{width:"66px", height:"28px"}}></input>
                        </div>
                        <div className={classes.celijaTabele} style={{ width:"70px", borderTop:"none", borderLeft:"none"}}>
                            <input className={classes.inputCelija} style={{width:"66px", height:"28px"}}></input>
                        </div>
                        <div className={classes.celijaTabele} style={{ width:"70px", borderTop:"none", borderLeft:"none"}}>
                            <input className={classes.inputCelija} style={{width:"66px", height:"28px"}}></input>
                        </div>
                        <div className={classes.celijaTabele} style={{ width:"70px", borderTop:"none", borderLeft:"none"}}>
                            <input className={classes.inputCelija} style={{width:"66px", height:"28px"}}></input>
                        </div>
                        <div className={classes.celijaTabele} style={{ width:"70px", borderTop:"none", borderLeft:"none"}}>
                            <input className={classes.inputCelija} style={{width:"66px", height:"28px"}}></input>
                        </div>
                        <div className={classes.celijaTabele} style={{ width:"70px", borderTop:"none", borderLeft:"none"}}>
                            <input className={classes.inputCelija} style={{width:"66px", height:"28px"}}></input>
                        </div>
                        <div className={classes.celijaTabele} style={{ width:"70px", borderTop:"none", borderLeft:"none"}}>
                            <input className={classes.inputCelija} style={{width:"66px", height:"28px"}}></input>
                        </div>
                        <div style={{ width:"72px", background:"#91FB86", height:"35px"}}></div>
                    </div>

                    <div style={{justifyContent: "spaceBetween"}}>
                        <div className={classes.celijaTabele} style={{ width:"70px", borderTop:"none", borderLeft:"none"}}>
                            <input className={classes.inputCelija} style={{width:"66px", height:"28px"}}></input>
                        </div>
                        <div className={classes.celijaTabele} style={{ width:"70px", borderTop:"none", borderLeft:"none"}}>
                            <input className={classes.inputCelija} style={{width:"66px", height:"28px"}}></input>
                        </div>
                        <div className={classes.celijaTabele} style={{ width:"70px", borderTop:"none", borderLeft:"none"}}>
                            <input className={classes.inputCelija} style={{width:"66px", height:"28px"}}></input>
                        </div>
                        <div className={classes.celijaTabele} style={{ width:"70px", borderTop:"none", borderLeft:"none"}}>
                            <input className={classes.inputCelija} style={{width:"66px", height:"28px"}}></input>
                        </div>
                        <div className={classes.celijaTabele} style={{ width:"70px", borderTop:"none", borderLeft:"none"}}>
                            <input className={classes.inputCelija} style={{width:"66px", height:"28px"}}></input>
                        </div>
                        <div className={classes.celijaTabele} style={{ width:"70px", borderTop:"none", borderLeft:"none"}}>
                            <input className={classes.inputCelija} style={{width:"66px", height:"28px"}}></input>
                        </div>
                        <div className={classes.celijaTabele} style={{ width:"70px", borderTop:"none", borderLeft:"none"}}>
                            <input className={classes.inputCelija} style={{width:"66px", height:"28px"}}></input>
                        </div>
                        <div className={classes.celijaTabele} style={{ width:"70px", borderTop:"none", borderLeft:"none"}}>
                            <input className={classes.inputCelija} style={{width:"66px", height:"28px"}}></input>
                        </div>
                        <div className={classes.celijaTabele} style={{ width:"70px", borderTop:"none", borderLeft:"none"}}>
                            <input className={classes.inputCelija} style={{width:"66px", height:"28px"}}></input>
                        </div>
                        <div className={classes.celijaTabele} style={{ width:"70px", borderTop:"none", borderLeft:"none"}}>
                            <input className={classes.inputCelija} style={{width:"66px", height:"28px"}}></input>
                        </div>
                        <div className={classes.celijaTabele} style={{ width:"70px", borderTop:"none", borderLeft:"none"}}>
                            <input className={classes.inputCelija} style={{width:"66px", height:"28px"}}></input>
                        </div>
                        <div className={classes.celijaTabele} style={{ width:"70px", borderTop:"none", borderLeft:"none"}}>
                            <input className={classes.inputCelija} style={{width:"66px", height:"28px"}}></input>
                        </div>
                        <div className={classes.celijaTabele} style={{ width:"70px", borderTop:"none", borderLeft:"none"}}>
                            <input className={classes.inputCelija} style={{width:"66px", height:"28px"}}></input>
                        </div>
                        <div style={{ width:"72px", background:"#91FB86", height:"35px"}}></div>
                    </div>

                    <div style={{justifyContent: "spaceBetween"}}>
                        <div className={classes.celijaTabele} style={{ width:"80px", borderTop:"none", borderLeft:"none", background:"#91FB86"}}><p style={{marginTop:"6px", color:"black"}}>asd</p></div>
                        <div className={classes.celijaTabele} style={{ width:"80px", borderTop:"none", borderLeft:"none", background:"#91FB86"}}><p style={{marginTop:"6px", color:"black"}}>asd</p></div>
                        <div className={classes.celijaTabele} style={{ width:"80px", borderTop:"none", borderLeft:"none", background:"#91FB86"}}><p style={{marginTop:"6px", color:"black"}}>asd</p></div>
                        <div className={classes.celijaTabele} style={{ width:"80px", borderTop:"none", borderLeft:"none", background:"#91FB86"}}><p style={{marginTop:"6px", color:"black"}}>asd</p></div>
                        <div className={classes.celijaTabele} style={{ width:"80px", borderTop:"none", borderLeft:"none", background:"#91FB86"}}><p style={{marginTop:"6px", color:"black"}}>asd</p></div>
                        <div className={classes.celijaTabele} style={{ width:"80px", borderTop:"none", borderLeft:"none", background:"#91FB86"}}><p style={{marginTop:"6px", color:"black"}}>asd</p></div>
                        <div className={classes.celijaTabele} style={{ width:"80px", borderTop:"none", borderLeft:"none", background:"#91FB86"}}><p style={{marginTop:"6px", color:"black"}}>asd</p></div>
                        <div className={classes.celijaTabele} style={{ width:"80px", borderTop:"none", borderLeft:"none", background:"#91FB86"}}><p style={{marginTop:"6px", color:"black"}}>asd</p></div>
                        <div className={classes.celijaTabele} style={{ width:"80px", borderTop:"none", borderLeft:"none", background:"#91FB86"}}><p style={{marginTop:"6px", color:"black"}}>asd</p></div>
                        <div className={classes.celijaTabele} style={{ width:"80px", borderTop:"none", borderLeft:"none", background:"#91FB86"}}><p style={{marginTop:"6px", color:"black"}}>asd</p></div>
                        <div className={classes.celijaTabele} style={{ width:"80px", borderTop:"none", borderLeft:"none", background:"#91FB86"}}><p style={{marginTop:"6px", color:"black"}}>asd</p></div>
                        <div className={classes.celijaTabele} style={{ width:"80px", borderTop:"none", borderLeft:"none", background:"#91FB86"}}><p style={{marginTop:"6px", color:"black"}}>asd</p></div>
                        <div className={classes.celijaTabele} style={{ width:"80px", borderTop:"none", borderLeft:"none", background:"#91FB86"}}><p style={{marginTop:"6px", color:"black"}}>asd</p></div>
                        <div style={{ width:"82px", background:"#91FB86", height:"35px"}}></div>
                    </div>
                    <div style={{justifyContent: "spaceBetween"}}>
                        <div className={classes.celijaTabele} style={{ width:"80px", borderTop:"none", borderLeft:"none", background:"#91FB86"}}><p style={{marginTop:"6px", color:"black"}}>asd</p></div>
                        <div className={classes.celijaTabele} style={{ width:"80px", borderTop:"none", borderLeft:"none", background:"#91FB86"}}><p style={{marginTop:"6px", color:"black"}}>asd</p></div>
                        <div className={classes.celijaTabele} style={{ width:"80px", borderTop:"none", borderLeft:"none", background:"#91FB86"}}><p style={{marginTop:"6px", color:"black"}}>asd</p></div>
                        <div className={classes.celijaTabele} style={{ width:"80px", borderTop:"none", borderLeft:"none", background:"#91FB86"}}><p style={{marginTop:"6px", color:"black"}}>asd</p></div>
                        <div className={classes.celijaTabele} style={{ width:"80px", borderTop:"none", borderLeft:"none", background:"#91FB86"}}><p style={{marginTop:"6px", color:"black"}}>asd</p></div>
                        <div className={classes.celijaTabele} style={{ width:"80px", borderTop:"none", borderLeft:"none", background:"#91FB86"}}><p style={{marginTop:"6px", color:"black"}}>asd</p></div>
                        <div className={classes.celijaTabele} style={{ width:"80px", borderTop:"none", borderLeft:"none", background:"#91FB86"}}><p style={{marginTop:"6px", color:"black"}}>asd</p></div>
                        <div className={classes.celijaTabele} style={{ width:"80px", borderTop:"none", borderLeft:"none", background:"#91FB86"}}><p style={{marginTop:"6px", color:"black"}}>asd</p></div>
                        <div className={classes.celijaTabele} style={{ width:"80px", borderTop:"none", borderLeft:"none", background:"#91FB86"}}><p style={{marginTop:"6px", color:"black"}}>asd</p></div>
                        <div className={classes.celijaTabele} style={{ width:"80px", borderTop:"none", borderLeft:"none", background:"#91FB86"}}><p style={{marginTop:"6px", color:"black"}}>asd</p></div>
                        <div className={classes.celijaTabele} style={{ width:"80px", borderTop:"none", borderLeft:"none", background:"#91FB86"}}><p style={{marginTop:"6px", color:"black"}}>asd</p></div>
                        <div className={classes.celijaTabele} style={{ width:"80px", borderTop:"none", borderLeft:"none", background:"#91FB86"}}><p style={{marginTop:"6px", color:"black"}}>asd</p></div>
                        <div className={classes.celijaTabele} style={{ width:"80px", borderTop:"none", borderLeft:"none", background:"#91FB86"}}><p style={{marginTop:"6px", color:"black"}}>asd</p></div>
                        <div style={{ width:"82px", background:"#91FB86", height:"35px"}}></div>
                    </div>
                    <div style={{justifyContent: "spaceBetween"}}>
                        <div className={classes.celijaTabele} style={{ width:"170px", borderTop:"none", borderLeft:"none", background:"black"}}><p style={{marginTop:"4px", color:"#91FB86", fontSize:"22px"}}>asd</p></div>
                        <div className={classes.celijaTabele} style={{ width:"170px", borderTop:"none", borderLeft:"none", background:"black"}}><p style={{marginTop:"4px", color:"#91FB86", fontSize:"22px"}}>asd</p></div>
                        <div className={classes.celijaTabele} style={{ width:"170px", borderTop:"none", borderLeft:"none", background:"black"}}><p style={{marginTop:"4px", color:"#91FB86", fontSize:"22px"}}>asd</p></div>
                        <div className={classes.celijaTabele} style={{ width:"170px", borderTop:"none", borderLeft:"none", background:"black"}}><p style={{marginTop:"4px", color:"#91FB86", fontSize:"22px"}}>asd</p></div>
                        <div className={classes.celijaTabele} style={{ width:"170px", borderTop:"none", borderLeft:"none", background:"black"}}><p style={{marginTop:"4px", color:"#91FB86", fontSize:"22px"}}>asd</p></div>
                        <div className={classes.celijaTabele} style={{ width:"170px", borderTop:"none", borderLeft:"none", background:"black"}}><p style={{marginTop:"4px", color:"#91FB86", fontSize:"22px"}}>asd</p></div>
                        <div className={classes.celijaTabele} style={{ width:"170px", borderTop:"none", borderLeft:"none", background:"black"}}><p style={{marginTop:"4px", color:"#91FB86", fontSize:"22px"}}>asd</p></div>
                        <div className={classes.celijaTabele} style={{ width:"170px", borderTop:"none", borderLeft:"none", background:"black"}}><p style={{marginTop:"4px", color:"#91FB86", fontSize:"22px"}}>asd</p></div>
                        <div className={classes.celijaTabele} style={{ width:"170px", borderTop:"none", borderLeft:"none", background:"black"}}><p style={{marginTop:"4px", color:"#91FB86", fontSize:"22px"}}>asd</p></div>
                        <div className={classes.celijaTabele} style={{ width:"170px", borderTop:"none", borderLeft:"none", background:"black"}}><p style={{marginTop:"4px", color:"#91FB86", fontSize:"22px"}}>asd</p></div>
                        <div className={classes.celijaTabele} style={{ width:"170px", borderTop:"none", borderLeft:"none", background:"black"}}><p style={{marginTop:"4px", color:"#91FB86", fontSize:"22px"}}>asd</p></div>
                        <div className={classes.celijaTabele} style={{ width:"170px", borderTop:"none", borderLeft:"none", background:"black"}}><p style={{marginTop:"4px", color:"#91FB86", fontSize:"22px"}}>asd</p></div>
                        <div className={classes.celijaTabele} style={{ width:"170px", borderTop:"none", borderLeft:"none", background:"black"}}><p style={{marginTop:"4px", color:"#91FB86", fontSize:"22px"}}>asd</p></div>
                        <div style={{ width:"172px", background:"black", height:"35px"}}></div>
                    </div>

                </div>
            </div>
        </div>

    </div>
  );
};

export default PoredenjeKvotaMarza;
