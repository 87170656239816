import { useNavigate, useLocation } from "react-router-dom";
import classes from "./adminAddNewForm.module.css";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import { useState, useRef, useEffect } from "react";
import {
  Modal,
  notification,
  Input,
  InputNumber,
  Select,
  Button,
  Checkbox,
} from "antd";
import Axios from "axios";
import { DeleteOutlined } from "@ant-design/icons";

const { TextArea } = Input;
const { Option } = Select;

const AdminAddNewForm = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [title, setTitle] = useState();
  const [pageTitle, setPageTitle] = useState();
  const [reRender, setReRender] = useState();
  const [imageType, setImageType] = useState("");
  const [textField, setTextField] = useState("");
  const [images, setImages] = useState([]);
  const [filteredImages, setFilteredImages] = useState([]);

  const [videos, setVideos] = useState([]);
  const [isVisibleVideoModal, setIsVisibleVideoModal] = useState(false);
  const [selectedFeatureImage, setSelectedFeatureImage] = useState();
  const [videoType, setVideoType] = useState("");
  const [selectedFeatureVideo, setSelectedFeatureVideo] = useState();
  const [selectedMarketingVideo, setSelectedMarketingVideo] = useState();
  const [selectedMarketingImage, setSelectedMarketingImage] = useState();
  const [items, setItems] = useState([]);
  const [marketingLink, setMarketingLink] = useState("");
  const [itemIndex, setItemIndex] = useState([]);
  const [isVisibleModal, setIsVisibleModal] = useState(false);
  const [isSaveButtonDisabled, setIsSaveButtonDisabled] = useState(false);
  const axiosPrivate = useAxiosPrivate();
  const SERVER_URL = process.env.REACT_APP_SERVER_URL;

  const getPageTitle = () => {
    if (location.state === "statistika") {
      setPageTitle("statistiku");
    } else if (location.state === "infoBlok") {
      setPageTitle("info blok");
    } else if (location.state === "betPortal") {
      setPageTitle("bet portal");
    } else if (location.state === "betKursNovo") {
      setPageTitle("premium");
    } else if (location.state === "betKurs") {
      setPageTitle("bet kurs (stari)");
    } else if (location.state === "nasiPredlozi") {
      setPageTitle("nase predloge");
    } else if (location.state === "betKalkulatoriTutorijal") {
      setPageTitle("tutorijale bet kalkulatora");
    }
  };

  const fetchVideos = async () => {
    try {
      const videosArr = await Axios.get(`${SERVER_URL}/videos/get-all`, {
        withCredentials: false,
      });

      setVideos(videosArr.data);
    } catch (error) {
      console.log("err", error);
    }
  };

  const handleShowVideoModal = () => {
    setIsVisibleVideoModal(true);
  };

  const handleRemoveFromAdding = (index) => {
    let array = items;
    array.splice(index, 1);

    setItems(array);
    setReRender(!reRender);
  };

  const handleSubmit = async () => {
    try {
      const data = {
        title,
        featureImage: selectedFeatureImage?._id ? selectedFeatureImage?._id : null,
        featureVideo: selectedFeatureVideo?._id ? selectedFeatureVideo?._id : null,
        marketingVideo: selectedMarketingVideo?._id ? selectedMarketingVideo?._id : null,
        marketingImage: selectedMarketingImage?._id ? selectedMarketingImage?._id : null,
        marketingLink: marketingLink,
        textField: textField,
        items,
      };

      console.log('DATAA ON SUBMIT', data);

      if (!data.title) {
        notification.error({ message: "Unesite naslov posta!" });
        return;
      }
      if (!data.featureImage && !data.featureVideo) {
        notification.error({ message: "Izaberite naslovnu sliku/video!" });
        return;
      }
      if (!data.marketingImage && !data.marketingVideo) {
        notification.error({ message: "Izaberite marketing sliku/video!" });
        return;
      }
      if (!data.marketingLink) {
        data.marketingLink = "https://betkotip.com";
      }

      if (!data.textField) {
        notification.error({ message: "Unesite text polje!" });
        return;
      }

      if (data.items) {
        for (let i = 0; i < data.items.length; i++) {
          if (data.items[i].type === "img") {
            if (data.items[i].image === "") {
              notification.error({
                message: "Stavka ne može postojati bez izabrane slike!",
              });
              return;
            }
          }
          if (data.items[i].type === "video") {
            if (data.items[i].video === "") {
              notification.error({
                message: "Stavka ne može postojati bez izabranog videa!",
              });
              return;
            }
          }

          if (data.items[i].type === "text") {
            if (data.items[i].description === "") {
              notification.error({
                message: "Stavka ne može postojati bez opisa!",
              });
              return;
            }
            if (data.items[i].color === "") {
              notification.error({
                message: "Stavka ne može postojati bez odabrane boje!",
              });
              return;
            }
          }
        }
      }

      setIsSaveButtonDisabled(true);
      if (location.state === "statistika") {
        await axiosPrivate.post(`${SERVER_URL}/statistics`, data);

        notification.success({ message: "Uspešno objavljena statistika." });
      } else if (location.state === "infoBlok") {
        await axiosPrivate.post(`${SERVER_URL}/info-block`, data);

        notification.success({ message: "Uspešno objavljen info blok." });
      } else if (location.state === "betPortal") {
        await axiosPrivate.post(`${SERVER_URL}/bet-portal`, data);

        notification.success({ message: "Uspešno objavljen bet portal." });
      } else if (location.state === "betKursNovo") {
        await axiosPrivate.post(`${SERVER_URL}/betcoursenew`, data);

        notification.success({ message: "Uspešno objavljen premium post." });
      } else if (location.state === "betKurs") {
        await axiosPrivate.post(`${SERVER_URL}/betcourse`, data);

        notification.success({ message: "Uspešno objavljen bet kurs." });
      } else if (location.state === "nasiPredlozi") {
        await axiosPrivate.post(`${SERVER_URL}/your-suggestions`, data);

        notification.success({ message: "Uspešno objavljen vas predlog." });
      } else {
        notification.error({
          message: "Ovaj post nije sredjen",
        });
        return;
      }

      setTimeout(() => {
        window.location.reload();
      }, 300);
    } catch (error) {
      notification.error({ message: error });
    }
  };

  const handleShowModal = (e) => {
    if (e === "featureVideo") {
      setIsVisibleVideoModal(true);
      setVideoType(e);
    } else if (e === "marketingVideo") {
      setIsVisibleVideoModal(true);
      setVideoType(e);
    } else {
      setImageType(e);
      setIsVisibleModal(true);
    }
  };

  const handleCancel = () => {
    setIsVisibleModal(false);
    setIsVisibleVideoModal(false);
  };

  const fetchImages = async () => {
    try {
      const imagesArr = await Axios.get(`${SERVER_URL}/images/get-all`, {
        withCredentials: false,
      });

      setImages(imagesArr.data);
      setFilteredImages(imagesArr.data);
    } catch (error) {
      console.log("err", error);
    }
  };

  useEffect(() => {
    fetchImages();
    fetchVideos();
    getPageTitle();
  }, []);

  const handleSelectImage = (image) => {
    if (imageType === "featureImage") {
      setSelectedFeatureImage(image);
      setSelectedFeatureVideo(null);
    } else if (imageType === "marketingImage") {
      setSelectedMarketingImage(image);
      setSelectedMarketingVideo(null);
    } else if (imageType === "itemImage") {
      let arr = items;

      arr[itemIndex].image = image;

      setItems(arr);
    }
    setIsVisibleModal(false);
  };

  const handleSelectVideo = (video) => {
    if (videoType == "featureVideo") {
      setSelectedFeatureVideo(video);
      setSelectedFeatureImage(null);
      setIsVisibleVideoModal(false);
      setReRender(!reRender);
    } else if (videoType == "marketingVideo") {
      setSelectedMarketingVideo(video);
      setSelectedMarketingImage(null);
      setIsVisibleVideoModal(false);
      setReRender(!reRender);
    } else {
      let arr = items;

      arr[itemIndex].video = video;

      setItems(arr);

      setIsVisibleVideoModal(false);
      setReRender(!reRender);
    }
  };

  const handleChangeTextArea = (e, index) => {
    let array = items;
    array[index].description = e.target.value;

    setItems(array);
  };

  const handleChangeTextColor = (e, index) => {
    let array = items;
    array[index].color = e;

    setItems(array);
  };

  const handleChangeTextFontSize = (e, index) => {
    let array = items;
    array[index].fontSize = e;

    setItems(array);
  };

  const handleChangeBold = (e, index) => {
    let array = items;
    array[index].bold = !array[index].bold;

    setItems(array);
  };

  const handleChangeItalic = (e, index) => {
    let array = items;
    array[index].italic = !array[index].italic;

    setItems(array);
  };

  const handleChangeMarginTop = (e, index) => {
    let array = items;
    array[index].marginTop = e;

    setItems(array);
  };

  const handleChangeMarginBottom = (e, index) => {
    let array = items;
    array[index].marginBottom = e;

    setItems(array);
  };

  const handleChangeImageLink = (e, index) => {
    let array = items;
    array[index].imageLink = e.target.value;

    setItems(array);
  };

  const handleChangeMarketingLink = (e) => {
    setMarketingLink(e.target.value);
  };

  const handleChangeTextField = (e) => {
    setTextField(e.target.value);
  };

  const filterImages = (e) => {
    if (e) {
      let filtered = images.filter((item) => item.imageType === e);

      setFilteredImages(filtered);
    } else {
      setFilteredImages(images);
    }
  };

  // console.log(items);

  return (
    <div className={classes.container}>
      <h1 style={{ marginLeft: "10px" }}>Nova objava za {pageTitle}</h1>
      <div className={classes.main}>
        <p>Naslov:</p>
        <input
          value={title}
          onChange={(e) => {
            setTitle(e.target.value);
          }}
        ></input>
      </div>
      <div className={classes.main}>
        <p>Naslovna slika:</p>
        {selectedFeatureImage && selectedFeatureImage.url && (
          <img
            style={{ width: "50px" }}
            src={`${SERVER_URL}/${selectedFeatureImage.url}`}
          />
        )}
        <button
          className={classes.addImageBtn}
          onClick={() => {
            handleShowModal("featureImage");
          }}
        >
          {selectedFeatureImage && selectedFeatureImage.url
            ? selectedFeatureImage.url
            : "Izaberite sliku"}
        </button>
        {(selectedFeatureImage && selectedFeatureImage.url && 
          <Button
            icon={<DeleteOutlined />}
            type="primary"
            style={{
              padding: "0px 0px 0px 20px",
              width: "60px",
              marginLeft: "15px",
              marginTop: '8px',
              display: "flex",
              alignItems: "center",
            }}
            danger
            onClick={() => setSelectedFeatureImage(null)}
          ></Button>
        )}
        <button
          className={classes.addImageBtn}
          onClick={() => {
            handleShowModal("featureVideo");
          }}
        >
          {selectedFeatureVideo && selectedFeatureVideo.url
            ? selectedFeatureVideo.url
            : "Izaberite video"}
        </button>
        {(selectedFeatureVideo && selectedFeatureVideo.url && 
          <Button
            icon={<DeleteOutlined />}
            type="primary"
            style={{
              padding: "0px 0px 0px 20px",
              width: "60px",
              marginLeft: "15px",
              marginTop: '8px',
              display: "flex",
              alignItems: "center",
            }}
            danger
            onClick={() => setSelectedFeatureVideo(null)}
          ></Button>
        )}
      </div>
      <div className={classes.main}>
        <p>Marketing slika:</p>
        {selectedMarketingImage && selectedMarketingImage.url && (
          <img
            style={{ width: "50px" }}
            src={`${SERVER_URL}/${selectedMarketingImage.url}`}
          />
        )}
        <button
          className={classes.addImageBtn}
          onClick={() => {
            handleShowModal("marketingImage");
          }}
        >
          {selectedMarketingImage && selectedMarketingImage.url
            ? selectedMarketingImage.url
            : "Izaberite sliku"}
        </button>
        {(selectedMarketingImage && selectedMarketingImage.url && 
          <Button
            icon={<DeleteOutlined />}
            type="primary"
            style={{
              padding: "0px 0px 0px 20px",
              width: "60px",
              marginLeft: "15px",
              marginTop: '8px',
              display: "flex",
              alignItems: "center",
            }}
            danger
            onClick={() => setSelectedMarketingImage(null)}
          ></Button>
        )}
        <button
          className={classes.addImageBtn}
          onClick={() => {
            handleShowModal("marketingVideo");
          }}
        >
          {selectedMarketingVideo && selectedMarketingVideo.url
            ? selectedMarketingVideo.url
            : "Izaberite video"}
        </button>
        {(selectedMarketingVideo && selectedMarketingVideo.url && 
          <Button
            icon={<DeleteOutlined />}
            type="primary"
            style={{
              padding: "0px 0px 0px 20px",
              width: "60px",
              marginLeft: "15px",
              marginTop: '8px',
              display: "flex",
              alignItems: "center",
            }}
            danger
            onClick={() => setSelectedMarketingVideo(null)}
          ></Button>
        )}
      </div>
      <div className={classes.main}>
        <p>Marketing slika - link:</p>
        <Input
          className={classes.inputMarketingLink}
          onChange={(e) => {
            handleChangeMarketingLink(e);
          }}
        ></Input>
      </div>

      <div className={classes.main}>
        <p>Text polje (ispod komentariši):</p>
        <Input
          className={classes.inputMarketingLink}
          onChange={(e) => {
            handleChangeTextField(e);
          }}
        ></Input>
      </div>

      <div>
        <button
          className={classes.addImageBtn}
          onClick={() => {
            setItems([
              ...items,
              {
                type: "text",
                description: "",
                color: "",
                bold: false,
                italic: false,
                marginTop: 0,
                marginBottom: 0,
              },
            ]);
          }}
        >
          Dodaj tekst
        </button>
        <button
          className={classes.addImageBtn}
          onClick={() => {
            let array = items;
            let obj = { type: "img", image: "" };
            array.push(obj);

            setItems([...array]);
          }}
        >
          Dodaj sliku
        </button>
        <button
          className={classes.addImageBtn}
          onClick={() => {
            let array = items;
            let obj = { type: "video", video: "" };
            array.push(obj);

            setItems([...array]);
          }}
        >
          Dodaj video
        </button>
      </div>

      {items && items.length > 0 && (
        <div className={classes.labelRow}>Stavke </div>
      )}

      {items &&
        items.length > 0 &&
        items.map((item, index) => {
          if (item.type === "text") {
            return (
              <div className={classes.textitem}>
                <div className={classes.textItemLeft}>
                  <div className={classes.item}>
                    <p>Tekst:</p>
                    <TextArea
                      className={classes.textAreaInput}
                      onChange={(e) => {
                        handleChangeTextArea(e, index);
                      }}
                    ></TextArea>
                  </div>
                  <div className={classes.item}>
                    <p>Bold:</p>
                    <Checkbox
                      onChange={(e) => {
                        handleChangeBold(e, index);
                      }}
                      type="checkbox"
                    ></Checkbox>
                  </div>
                  <div className={classes.item}>
                    <p>Italic:</p>
                    <Checkbox
                      onChange={(e) => {
                        handleChangeItalic(e, index);
                      }}
                      type="checkbox"
                    ></Checkbox>
                  </div>
                  <div className={classes.item}>
                    <p>Boja teksta:</p>
                    <Select
                      onChange={(e) => {
                        handleChangeTextColor(e, index);
                      }}
                      className={classes.colorSelect}
                    >
                      <Option value="red">Crvena</Option>
                      <Option value="blue">Plava</Option>
                      <Option value="#001a4f">Tamno plava</Option>
                      <Option value="#f92910">Narandžasta</Option>
                      <Option value="#301934">Ljubičasta</Option>
                      <Option value="green">Zelena</Option>
                    </Select>
                  </div>
                  <div className={classes.item}>
                    <p>Veličina teksta:</p>
                    <Select
                      value={item.fontSize}
                      onChange={(e) => {
                        handleChangeTextFontSize(e, index);
                      }}
                      className={classes.colorSelect}
                    >
                      <Option value="10">10px</Option>
                      <Option value="20">20px</Option>
                      <Option value="30">30px</Option>
                      <Option value="40">40px</Option>
                      <Option value="50">50px</Option>
                    </Select>
                  </div>
                  <div className={classes.item}>
                    <p>Margine (top-bottom):</p>
                    <InputNumber
                      onChange={(e) => {
                        handleChangeMarginTop(e, index);
                      }}
                      type="number"
                      max={10}
                      className={classes.inputNumberMargin}
                      min={0}
                    ></InputNumber>
                    <InputNumber
                      onChange={(e) => {
                        handleChangeMarginBottom(e, index);
                      }}
                      type="number"
                      max={10}
                      className={classes.inputNumberMargin}
                      min={0}
                    ></InputNumber>
                  </div>
                </div>
                <Button
                  icon={<DeleteOutlined />}
                  type="primary"
                  style={{
                    padding: "0px 0px 0px 20px",
                    width: "60px",
                    marginLeft: "15px",
                    display: "flex",
                    alignItems: "center",
                  }}
                  danger
                  onClick={() => handleRemoveFromAdding(index)}
                ></Button>
              </div>
            );
          } else if (item.type === "img") {
            return (
              <div className={classes.imageitem}>
                <div className={classes.imageItemLeft}>
                  <img
                    style={{ width: "50px", borderRadius: "10px" }}
                    src={`${SERVER_URL}/${item.image.url}`}
                  />
                  <Input
                    onChange={(e) => {
                      handleChangeImageLink(e, index);
                    }}
                    className={classes.inputNumberMargin}
                    style={{ marginRight: "10px" }}
                  ></Input>
                  <button
                    className={classes.addImageBtn}
                    onClick={() => {
                      handleShowModal("itemImage");
                      setItemIndex(index);
                    }}
                  >
                    {items[index] && items[index].image.url
                      ? items[index].image.url
                      : "Izaberite sliku"}
                  </button>
                </div>
                <Button
                  icon={<DeleteOutlined />}
                  type="primary"
                  style={{
                    padding: "0px 0px 0px 20px",
                    width: "60px",
                    marginLeft: "15px",
                    display: "flex",
                    alignItems: "center",
                  }}
                  danger
                  onClick={() => handleRemoveFromAdding(index)}
                ></Button>
              </div>
            );
          } else {
            return (
              <div className={classes.imageitem}>
                <div className={classes.imageItemLeft}>
                  {item && item.video && item.video.url && (
                    <video
                      key={`${SERVER_URL}/${item.video.url}`}
                      width="320"
                      height="240"
                      controls
                    >
                      <source
                        src={`${SERVER_URL}/${item.video.url}`}
                        type="video/mp4"
                      />
                    </video>
                  )}
                  <button
                    className={classes.addImageBtn}
                    onClick={() => {
                      handleShowVideoModal();
                      setItemIndex(index);
                    }}
                  >
                    {items[index] && items[index].video.url
                      ? items[index].video.url
                      : "Izaberite video"}
                  </button>
                </div>
                <Button
                  icon={<DeleteOutlined />}
                  type="primary"
                  style={{
                    padding: "0px 0px 0px 20px",
                    width: "60px",
                    marginLeft: "15px",
                    display: "flex",
                    alignItems: "center",
                  }}
                  danger
                  onClick={() => handleRemoveFromAdding(index)}
                ></Button>
              </div>
            );
          }
        })}
      <Modal
        width={"80%"}
        footer={[]}
        title="Kliknite sliku da biste je odabrali"
        visible={isVisibleModal}
        onCancel={handleCancel}
      >
        <div>
          <Select
            style={{ minWidth: "200px" }}
            onChange={(e) => {
              filterImages(e);
            }}
            defaultValue={""}
          >
            <Option value="">Sve</Option>
            <Option value="home">Početna strana</Option>
            <Option value="gallery">Gallery</Option>
          </Select>
        </div>
        <div className={classes.imagesList}>
          {filteredImages &&
            filteredImages.length > 0 &&
            filteredImages.map((item, index) => {
              return (
                <div className={classes.imageItem}>
                  <img
                    onClick={() => {
                      handleSelectImage(item);
                    }}
                    className="img"
                    src={`${SERVER_URL}/${item.url}`}
                    alt={item.url}
                  />
                </div>
              );
            })}
        </div>
      </Modal>
      <Modal
        width={"80%"}
        footer={[]}
        title="VIDEO GALERIJA"
        visible={isVisibleVideoModal}
        onCancel={handleCancel}
      >
        <div className={classes.videosList}>
          {videos &&
            videos.length > 0 &&
            videos.map((item, index) => {
              return (
                <div className={classes.videoItem}>
                  <video width="320" height="240" controls>
                    <source
                      src={`${SERVER_URL}/${item.url}`}
                      type="video/mp4"
                    />
                  </video>
                  <Button
                    onClick={() => {
                      handleSelectVideo(item);
                    }}
                    className={classes.selectVideoButton}
                  >
                    Izaberite video
                  </Button>
                </div>
              );
            })}
        </div>
      </Modal>
      <div className={classes.statistikaFooter}>
        <button
          className={classes.submitButton}
          disabled={isSaveButtonDisabled}
          onClick={() => {
            handleSubmit();
          }}
        >
          Sačuvaj
        </button>
      </div>
    </div>
  );
};

export default AdminAddNewForm;
