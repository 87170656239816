import Grupa2 from "../components/Grupa2";
import Grupa20 from "../components/Grupa20";
import poredjenjeIgracaFudbalDolnjaSlika from "../images/poredjenjeIgracaFudbalDoljnjaSlika.png";
import classes from "./poredenjeIgracaFudbal.module.css";
import { useState, useEffect } from "react";

const PoredenjeIgracaFudbal = () => {
  ////total fields/////
  const [brojUtakmicaDomacin, setBrojUtakmicaDomacin] = useState(0);
  const [brojUtakmicaGost, setBrojUtakmicaGost] = useState(0);
  const [data, setData] = useState({
    minutaPoUtakmici: 0,
    timKola: 0,
    poceo: 0,
    golovi: 0,
    ocekivaniGolovi: 0,
    goloviPoMinutima: 0,
    golovaNaUtakmici: 0,
    sutevaNaUtakmici: 0,
    sutevaUOkvirGolaPoUtakmici: 0,
    promaseneVelikeSanse: 0,
    ucinak: 0,
    ucinakIzSlobodnihUdaraca: 0,
    goloviUnutar16: 0,
    goloviIzvan16: 0,
    goloviGlavom: 0,
    goloviIzSlobodnihUdaraca: 0,
    goloviLevomNogom: 0,
    goloviDesnomNogom: 0,
    izboreniPenali: 0,
    dodavanje: 0,
    asistenicije: 0,
    dodiri: 0,
    kreiranjeVelikeSanse: 0,
    kljucnaDodavanja: 0,
    preciznaPoUtakmici: 0,
    preciznaNaSvojojPolovini: 0,
    preciznaNaProtivnickojPolovini: 0,
    precizneDugeLopte: 0,
    precizniPasovi: 0,
    precizniCentarsutevi: 0,
    presecanjePoUtakmici: 0,
    oduzeteLoptePoUtakmici: 0,
    osvojeniPosedi: 0,
    predriblanPoUtakmici: 0,
    otklonjeneOpasnostiPoUtakmici: 0,
    skrivljeniSlobodniUdarci: 0,
    uspesniDriblinzi: 0,
    ukupniOsvojeniDueli: 0,
    osvojeniDueliNaZemlji: 0,
    osvojeniVazdusniDueli: 0,
    izgubljeniPosed: 0,
    faulovi: 0,
    pretrpljeniFaulovi: 0,
    ofsajdi: 0,
    zutiKartoni: 0,
    drugiZutiKarton: 0,
    crveniKarton: 0,
    ///////////////
    minutaPoUtakmici2: 0,
    timKola2: 0,
    poceo2: 0,
    golovi2: 0,
    ocekivaniGolovi2: 0,
    goloviPoMinutima2: 0,
    golovaNaUtakmici2: 0,
    sutevaNaUtakmici2: 0,
    sutevaUOkvirGolaPoUtakmici2: 0,
    promaseneVelikeSanse2: 0,
    ucinak2: 0,
    ucinakIzSlobodnihUdaraca2: 0,
    goloviIzSlobodnihUdaraca2: 0,
    goloviUnutar162: 0,
    goloviIzvan162: 0,
    goloviGlavom2: 0,
    goloviLevomNogom2: 0,
    goloviDesnomNogom2: 0,
    izboreniPenali2: 0,
    dodavanje2: 0,
    asistenicije2: 0,
    dodiri2: 0,
    kreiranjeVelikeSanse2: 0,
    kljucnaDodavanja2: 0,
    preciznaPoUtakmici2: 0,
    preciznaNaSvojojPolovini2: 0,
    preciznaNaProtivnickojPolovini2: 0,
    precizneDugeLopte2: 0,
    precizniPasovi2: 0,
    precizniCentarsutevi2: 0,
    presecanjePoUtakmici2: 0,
    oduzeteLoptePoUtakmici2: 0,
    osvojeniPosedi2: 0,
    predriblanPoUtakmici2: 0,
    otklonjeneOpasnostiPoUtakmici2: 0,
    skrivljeniSlobodniUdarci2: 0,
    uspesniDriblinzi2: 0,
    ukupniOsvojeniDueli2: 0,
    osvojeniDueliNaZemlji2: 0,
    osvojeniVazdusniDueli2: 0,
    izgubljeniPosed2: 0,
    faulovi2: 0,
    pretrpljeniFaulovi2: 0,
    ofsajdi2: 0,
    zutiKartoni2: 0,
    drugiZutiKarton2: 0,
    crveniKarton2: 0,
  });

  const [calculatedData, setCalculatedData] = useState({
    minutaPoUtakmici: 0,
    poceo: 0,
    ukupnoOdigrano: 0,
    timKola: 0,
    goloviIzSlobodnihUdaraca: 0,
    golovi: 0,
    ocekivaniGolovi: 0,
    goloviPoMinutima: 0,
    golovaNaUtakmici: 0,
    sutevaNaUtakmici: 0,
    sutevaUOkvirGolaPoUtakmici: 0,
    promaseneVelikeSanse: 0,
    ucinak: 0,
    ucinakIzSlobodnihUdaraca: 0,
    goloviUnutar16: 0,
    goloviIzvan16: 0,
    goloviGlavom: 0,
    goloviLevomNogom: 0,
    goloviDesnomNogom: 0,
    izboreniPenali: 0,
    dodavanje: 0,
    asistenicije: 0,
    dodiri: 0,
    kreiranjeVelikeSanse: 0,
    kljucnaDodavanja: 0,
    preciznaPoUtakmici: 0,
    preciznaNaSvojojPolovini: 0,
    preciznaNaProtivnickojPolovini: 0,
    precizneDugeLopte: 0,
    precizniPasovi: 0,
    precizniCentarsutevi: 0,
    presecanjePoUtakmici: 0,
    oduzeteLoptePoUtakmici: 0,
    osvojeniPosedi: 0,
    predriblanPoUtakmici: 0,
    otklonjeneOpasnostiPoUtakmici: 0,
    skrivljeniSlobodniUdarci: 0,
    uspesniDriblinzi: 0,
    ukupniOsvojeniDueli: 0,
    osvojeniDueliNaZemlji: 0,
    osvojeniVazdusniDueli: 0,
    izgubljeniPosed: 0,
    faulovi: 0,
    pretrpljeniFaulovi: 0,
    ofsajdi: 0,
    zutiKartoni: 0,
    drugiZutiKarton: 0,
    crveniKarton: 0,
    ///////////////
    minutaPoUtakmici2: 0,
    ukupnoOdigrano2: 0,
    poceo2: 0,
    timKola2: 0,
    golovi2: 0,
    ocekivaniGolovi2: 0,
    goloviPoMinutima2: 0,
    golovaNaUtakmici2: 0,
    sutevaNaUtakmici2: 0,
    sutevaUOkvirGolaPoUtakmici2: 0,
    promaseneVelikeSanse2: 0,
    ucinak2: 0,
    ucinakIzSlobodnihUdaraca2: 0,
    goloviUnutar162: 0,
    goloviIzSlobodnihUdaraca2: 0,
    goloviIzvan162: 0,
    goloviGlavom2: 0,
    goloviLevomNogom2: 0,
    goloviDesnomNogom2: 0,
    izboreniPenali2: 0,
    dodavanje2: 0,
    asistenicije2: 0,
    dodiri2: 0,
    kreiranjeVelikeSanse2: 0,
    kljucnaDodavanja2: 0,
    preciznaPoUtakmici2: 0,
    preciznaNaSvojojPolovini2: 0,
    preciznaNaProtivnickojPolovini2: 0,
    precizneDugeLopte2: 0,
    precizniPasovi2: 0,
    precizniCentarsutevi2: 0,
    presecanjePoUtakmici2: 0,
    oduzeteLoptePoUtakmici2: 0,
    osvojeniPosedi2: 0,
    predriblanPoUtakmici2: 0,
    otklonjeneOpasnostiPoUtakmici2: 0,
    skrivljeniSlobodniUdarci2: 0,
    uspesniDriblinzi2: 0,
    ukupniOsvojeniDueli2: 0,
    osvojeniDueliNaZemlji2: 0,
    osvojeniVazdusniDueli2: 0,
    izgubljeniPosed2: 0,
    faulovi2: 0,
    pretrpljeniFaulovi2: 0,
    ofsajdi2: 0,
    zutiKartoni2: 0,
    drugiZutiKarton2: 0,
    crveniKarton2: 0,
  });

  useEffect(() => {
    handleCalculate();
  }, [data, brojUtakmicaDomacin, brojUtakmicaGost]);

  const handleCalculate = () => {
    let obj = {
      minutaPoUtakmici: 0,
      poceo: 0,
      ukupnoOdigrano: 0,
      ukupnoOdigrano: 0,
      goloviIzSlobodnihUdaraca: 0,
      timKola: 0,
      golovi: 0,
      ocekivaniGolovi: 0,
      goloviPoMinutima: 0,
      golovaNaUtakmici: 0,
      sutevaNaUtakmici: 0,
      sutevaUOkvirGolaPoUtakmici: 0,
      promaseneVelikeSanse: 0,
      ucinak: 0,
      ucinakIzSlobodnihUdaraca: 0,
      goloviUnutar16: 0,
      goloviIzvan16: 0,
      goloviGlavom: 0,
      goloviLevomNogom: 0,
      goloviDesnomNogom: 0,
      izboreniPenali: 0,
      dodavanje: 0,
      asistenicije: 0,
      dodiri: 0,
      kreiranjeVelikeSanse: 0,
      kljucnaDodavanja: 0,
      preciznaPoUtakmici: 0,
      preciznaNaSvojojPolovini: 0,
      preciznaNaProtivnickojPolovini: 0,
      precizneDugeLopte: 0,
      precizniPasovi: 0,
      precizniCentarsutevi: 0,
      presecanjePoUtakmici: 0,
      oduzeteLoptePoUtakmici: 0,
      osvojeniPosedi: 0,
      predriblanPoUtakmici: 0,
      otklonjeneOpasnostiPoUtakmici: 0,
      skrivljeniSlobodniUdarci: 0,
      uspesniDriblinzi: 0,
      ukupniOsvojeniDueli: 0,
      osvojeniDueliNaZemlji: 0,
      osvojeniVazdusniDueli: 0,
      izgubljeniPosed: 0,
      faulovi: 0,
      pretrpljeniFaulovi: 0,
      ofsajdi: 0,
      zutiKartoni: 0,
      drugiZutiKarton: 0,
      crveniKarton: 0,
      ///////////////
      minutaPoUtakmici2: 0,
      timKola2: 0,
      ukupnoOdigrano2: 0,
      ukupnoOdigrano2: 0,
      goloviIzSlobodnihUdaraca2: 0,
      poceo2: 0,
      golovi2: 0,
      ocekivaniGolovi2: 0,
      goloviPoMinutima2: 0,
      golovaNaUtakmici2: 0,
      sutevaNaUtakmici2: 0,
      sutevaUOkvirGolaPoUtakmici2: 0,
      promaseneVelikeSanse2: 0,
      ucinak2: 0,
      ucinakIzSlobodnihUdaraca2: 0,
      goloviUnutar162: 0,
      goloviIzvan162: 0,
      goloviGlavom2: 0,
      goloviLevomNogom2: 0,
      goloviDesnomNogom2: 0,
      izboreniPenali2: 0,
      dodavanje2: 0,
      asistenicije2: 0,
      dodiri2: 0,
      kreiranjeVelikeSanse2: 0,
      kljucnaDodavanja2: 0,
      preciznaPoUtakmici2: 0,
      preciznaNaSvojojPolovini2: 0,
      preciznaNaProtivnickojPolovini2: 0,
      precizneDugeLopte2: 0,
      precizniPasovi2: 0,
      precizniCentarsutevi2: 0,
      presecanjePoUtakmici2: 0,
      oduzeteLoptePoUtakmici2: 0,
      osvojeniPosedi2: 0,
      predriblanPoUtakmici2: 0,
      otklonjeneOpasnostiPoUtakmici2: 0,
      skrivljeniSlobodniUdarci2: 0,
      uspesniDriblinzi2: 0,
      ukupniOsvojeniDueli2: 0,
      osvojeniDueliNaZemlji2: 0,
      osvojeniVazdusniDueli2: 0,
      izgubljeniPosed2: 0,
      faulovi2: 0,
      pretrpljeniFaulovi2: 0,
      ofsajdi2: 0,
      zutiKartoni2: 0,
      drugiZutiKarton2: 0,
      crveniKarton2: 0,
    };
    if (brojUtakmicaDomacin) {
      const keys = Object.keys(data);

      for (let key of keys) {
        if (data && data[key] && !key.includes(2)) {
          if (key === "goloviPoMinutima") {
            obj = {
              ...obj,
              [key]: parseFloat(
                (data[key] / data.minutaPoUtakmici) * 100
              ).toFixed(2),
            };
          } else if (key === "minutaPoUtakmici") {
            obj = {
              ...obj,
              [key]: parseFloat(data[key] / 90).toFixed(2),
            };
          } else {
            obj = {
              ...obj,
              [key]: parseFloat(
                (data[key] / brojUtakmicaDomacin) * 100
              ).toFixed(2),
            };
          }
        }
      }
      setCalculatedData(obj);
    }

    if (brojUtakmicaGost) {
      const keys = Object.keys(data);

      for (let key of keys) {
        if (data && data[key] && key.includes(2)) {
          if (key === "goloviPoMinutima") {
            obj = {
              ...obj,
              [key]: parseFloat(
                (data[key] / data.minutaPoUtakmici2) * 100
              ).toFixed(2),
            };
          } else if (key === "minutaPoUtakmici2") {
            obj = {
              ...obj,
              [key]: parseFloat(data[key] / 90).toFixed(2),
            };
          } else {
            obj = {
              ...obj,
              [key]: parseFloat((data[key] / brojUtakmicaGost) * 100).toFixed(
                2
              ),
            };
          }
        }
      }
      setCalculatedData(obj);
    }
  };

  return (
    <div className={classes.container}>
      <Grupa2
        imgText="POREĐENJE IGRAČA - FUDBAL"
        imgBackgroundColorDark="#062C81"
        imgBackgroundColorLight="#0D2765"
        imgFontColor="#FFFFFF"
        label1="ZEMLJA"
        label2="KLUB"
        label3="IGRAČ 1"
        label4="KLUB"
        label5="ZEMLJA"
        label6="IGRAČ 2"
      />
      <Grupa20
        imgBackgroundColorDark="#062C81"
        imgBackgroundColorLight="#0D2765"
        imgFontColor="#FFFFFF"
        calculatorType={`Poredjenje igraca - fudbal`}
        />
      <img src={poredjenjeIgracaFudbalDolnjaSlika} />
      <div className={classes.calculator}>
        <div className={classes.row}>
          <div className={classes.col1}></div>
          <div className={classes.col2}>
            <input
              style={{
                border: "none",
                width: "60px",
                fontWeight: "bold",
                fontSize: "20px",
                marginTop: "2px",
                textAlign: "center",
              }}
              type="number"
              min="0"
              onChange={(e) => {
                setBrojUtakmicaDomacin(e.target.value);
              }}
            />
          </div>
          <div className={classes.col3}>UTAKMICE</div>
          <div className={classes.col4}>
            <input
              style={{
                border: "none",
                width: "60px",
                fontWeight: "bold",
                fontSize: "20px",
                marginTop: "2px",
                textAlign: "center",
              }}
              onChange={(e) => {
                setBrojUtakmicaGost(e.target.value);
              }}
              type="number"
              min="0"
            />
          </div>
          <div className={classes.col5}></div>
        </div>

        <div className={classes.row}>
          <div className={classes.col1}>{calculatedData.ukupnoOdigrano} %</div>
          <div className={classes.col2}>
            <input
              style={{
                border: "none",
                width: "60px",
                fontWeight: "bold",
                fontSize: "20px",
                marginTop: "2px",
                textAlign: "center",
              }}
              onChange={(e) => {
                setData({ ...data, ukupnoOdigrano: e.target.value });
              }}
              type="number"
              min="0"
            />
          </div>
          <div className={classes.col3}>Ukupno odigrano</div>
          <div className={classes.col4}>
            <input
              style={{
                border: "none",
                width: "60px",
                fontWeight: "bold",
                fontSize: "20px",
                marginTop: "2px",
                textAlign: "center",
              }}
              onChange={(e) => {
                setData({ ...data, ukupnoOdigrano2: e.target.value });
              }}
              type="number"
              min="0"
            />
          </div>
          <div className={classes.col5}>{calculatedData.ukupnoOdigrano2} %</div>
        </div>

        <div className={classes.row}>
          <div className={classes.col1}>{calculatedData.poceo} %</div>
          <div className={classes.col2}>
            <input
              style={{
                border: "none",
                width: "60px",
                fontWeight: "bold",
                fontSize: "20px",
                marginTop: "2px",
                textAlign: "center",
              }}
              onChange={(e) => {
                setData({ ...data, poceo: e.target.value });
              }}
              type="number"
              min="0"
            />
          </div>
          <div className={classes.col3}>Poceo</div>
          <div className={classes.col4}>
            <input
              style={{
                border: "none",
                width: "60px",
                fontWeight: "bold",
                fontSize: "20px",
                marginTop: "2px",
                textAlign: "center",
              }}
              onChange={(e) => {
                setData({ ...data, poceo2: e.target.value });
              }}
              type="number"
              min="0"
            />
          </div>
          <div className={classes.col5}>{calculatedData.poceo2} %</div>
        </div>

        <div className={classes.row}>
          <div className={classes.col1}>
            {(calculatedData.minutaPoUtakmici / 90).toFixed(2)} %
          </div>
          <div className={classes.col2}>
            <input
              style={{
                border: "none",
                width: "60px",
                fontWeight: "bold",
                fontSize: "20px",
                marginTop: "2px",
                textAlign: "center",
              }}
              onChange={(e) => {
                setData({ ...data, minutaPoUtakmici: e.target.value });
              }}
              type="number"
              min="0"
            />
          </div>
          <div className={classes.col3}>Minuta po utakmici</div>
          <div className={classes.col4}>
            <input
              style={{
                border: "none",
                width: "60px",
                fontWeight: "bold",
                fontSize: "20px",
                marginTop: "2px",
                textAlign: "center",
              }}
              onChange={(e) => {
                setData({ ...data, minutaPoUtakmici2: e.target.value });
              }}
              type="number"
              min="0"
            />
          </div>
          <div className={classes.col5}>
            {(calculatedData.minutaPoUtakmici2 / 90).toFixed(2)} %
          </div>
        </div>

        <div className={classes.row}>
          <div className={classes.col1}>{calculatedData.timKola} %</div>
          <div className={classes.col2}>
            <input
              style={{
                border: "none",
                width: "60px",
                fontWeight: "bold",
                fontSize: "20px",
                marginTop: "2px",
                textAlign: "center",
              }}
              onChange={(e) => {
                setData({ ...data, timKola: e.target.value });
              }}
              type="number"
              min="0"
            />
          </div>
          <div className={classes.col3}>Tim kola</div>
          <div className={classes.col4}>
            <input
              style={{
                border: "none",
                width: "60px",
                fontWeight: "bold",
                fontSize: "20px",
                marginTop: "2px",
                textAlign: "center",
              }}
              onChange={(e) => {
                setData({ ...data, timKola2: e.target.value });
              }}
              type="number"
              min="0"
            />
          </div>
          <div className={classes.col5}>{calculatedData.timKola2} %</div>
        </div>
        <div className={classes.row}>
          <div className={classes.col1}>{calculatedData.golovi} %</div>
          <div className={classes.col2}>
            <input
              style={{
                border: "none",
                width: "60px",
                fontWeight: "bold",
                fontSize: "20px",
                marginTop: "2px",
                textAlign: "center",
              }}
              onChange={(e) => {
                setData({ ...data, golovi: e.target.value });
              }}
              type="number"
              min="0"
            />
          </div>
          <div className={classes.col3}>Golovi</div>
          <div className={classes.col4}>
            <input
              style={{
                border: "none",
                width: "60px",
                fontWeight: "bold",
                fontSize: "20px",
                marginTop: "2px",
                textAlign: "center",
              }}
              onChange={(e) => {
                setData({ ...data, golovi2: e.target.value });
              }}
              type="number"
              min="0"
            />
          </div>
          <div className={classes.col5}>{calculatedData.golovi2} %</div>
        </div>
        <div className={classes.row}>
          <div className={classes.col1}>
            {" "}
            {calculatedData.ocekivaniGolovi} %
          </div>
          <div className={classes.col2}>
            <input
              style={{
                border: "none",
                width: "60px",
                fontWeight: "bold",
                fontSize: "20px",
                marginTop: "2px",
                textAlign: "center",
              }}
              onChange={(e) => {
                setData({ ...data, ocekivaniGolovi: e.target.value });
              }}
              type="number"
              min="0"
            />
          </div>
          <div className={classes.col3}>Očekivani golovi</div>
          <div className={classes.col4}>
            <input
              style={{
                border: "none",
                width: "60px",
                fontWeight: "bold",
                fontSize: "20px",
                marginTop: "2px",
                textAlign: "center",
              }}
              onChange={(e) => {
                setData({ ...data, ocekivaniGolovi2: e.target.value });
              }}
              type="number"
              min="0"
            />
          </div>
          <div className={classes.col5}>
            {calculatedData.ocekivaniGolovi2} %
          </div>
        </div>
        <div className={classes.row}>
          <div className={classes.col1}>
            {calculatedData.goloviPoMinutima} %
          </div>
          <div className={classes.col2}>
            <input
              style={{
                border: "none",
                width: "60px",
                fontWeight: "bold",
                fontSize: "20px",
                marginTop: "2px",
                textAlign: "center",
              }}
              onChange={(e) => {
                setData({ ...data, goloviPoMinutima: e.target.value });
              }}
              type="number"
              min="0"
            />
          </div>
          <div className={classes.col3}>Golovi po minutima</div>
          <div className={classes.col4}>
            <input
              style={{
                border: "none",
                width: "60px",
                fontWeight: "bold",
                fontSize: "20px",
                marginTop: "2px",
                textAlign: "center",
              }}
              onChange={(e) => {
                setData({ ...data, goloviPoMinutima2: e.target.value });
              }}
              type="number"
              min="0"
            />
          </div>
          <div className={classes.col5}>
            {calculatedData.goloviPoMinutima2} %
          </div>
        </div>
        <div className={classes.row}>
          <div className={classes.col1}>
            {calculatedData.golovaNaUtakmici} %
          </div>
          <div className={classes.col2}>
            <input
              style={{
                border: "none",
                width: "60px",
                fontWeight: "bold",
                fontSize: "20px",
                marginTop: "2px",
                textAlign: "center",
              }}
              onChange={(e) => {
                setData({ ...data, golovaNaUtakmici: e.target.value });
              }}
              type="number"
              min="0"
            />
          </div>
          <div className={classes.col3}>Golova po utakmici</div>
          <div className={classes.col4}>
            <input
              style={{
                border: "none",
                width: "60px",
                fontWeight: "bold",
                fontSize: "20px",
                marginTop: "2px",
                textAlign: "center",
              }}
              onChange={(e) => {
                setData({ ...data, golovaNaUtakmici2: e.target.value });
              }}
              type="number"
              min="0"
            />
          </div>
          <div className={classes.col5}>
            {calculatedData.golovaNaUtakmici2} %
          </div>
        </div>
        <div className={classes.row}>
          <div className={classes.col1}>
            {" "}
            {calculatedData.sutevaNaUtakmici} %
          </div>
          <div className={classes.col2}>
            <input
              style={{
                border: "none",
                width: "60px",
                fontWeight: "bold",
                fontSize: "20px",
                marginTop: "2px",
                textAlign: "center",
              }}
              onChange={(e) => {
                setData({ ...data, sutevaNaUtakmici: e.target.value });
              }}
              type="number"
              min="0"
            />
          </div>
          <div className={classes.col3}>Šuteva po utakmici</div>
          <div className={classes.col4}>
            <input
              style={{
                border: "none",
                width: "60px",
                fontWeight: "bold",
                fontSize: "20px",
                marginTop: "2px",
                textAlign: "center",
              }}
              onChange={(e) => {
                setData({ ...data, sutevaNaUtakmici2: e.target.value });
              }}
              type="number"
              min="0"
            />
          </div>
          <div className={classes.col5}>
            {calculatedData.sutevaNaUtakmici2} %
          </div>
        </div>
        <div className={classes.row}>
          <div className={classes.col1}>
            {calculatedData.sutevaUOkvirGolaPoUtakmici} %
          </div>
          <div className={classes.col2}>
            <input
              style={{
                border: "none",
                width: "60px",
                fontWeight: "bold",
                fontSize: "20px",
                marginTop: "2px",
                textAlign: "center",
              }}
              onChange={(e) => {
                setData({
                  ...data,
                  sutevaUOkvirGolaPoUtakmici: e.target.value,
                });
              }}
              type="number"
              min="0"
            />
          </div>
          <div className={classes.col3}>Šuteva u okvir gola po utakmici</div>
          <div className={classes.col4}>
            <input
              style={{
                border: "none",
                width: "60px",
                fontWeight: "bold",
                fontSize: "20px",
                marginTop: "2px",
                textAlign: "center",
              }}
              onChange={(e) => {
                setData({
                  ...data,
                  sutevaUOkvirGolaPoUtakmici2: e.target.value,
                });
              }}
              type="number"
              min="0"
            />
          </div>
          <div className={classes.col5}>
            {calculatedData.sutevaUOkvirGolaPoUtakmici2} %
          </div>
        </div>
        <div className={classes.row}>
          <div className={classes.col1}>
            {calculatedData.promaseneVelikeSanse} %
          </div>
          <div className={classes.col2}>
            <input
              style={{
                border: "none",
                width: "60px",
                fontWeight: "bold",
                fontSize: "20px",
                marginTop: "2px",
                textAlign: "center",
              }}
              onChange={(e) => {
                setData({ ...data, promaseneVelikeSanse: e.target.value });
              }}
              type="number"
              min="0"
            />
          </div>
          <div className={classes.col3}>Promašene velike šanse</div>
          <div className={classes.col4}>
            <input
              style={{
                border: "none",
                width: "60px",
                fontWeight: "bold",
                fontSize: "20px",
                marginTop: "2px",
                textAlign: "center",
              }}
              onChange={(e) => {
                setData({ ...data, promaseneVelikeSanse2: e.target.value });
              }}
              type="number"
              min="0"
            />
          </div>
          <div className={classes.col5}>
            {calculatedData.promaseneVelikeSanse2} %
          </div>
        </div>
        <div className={classes.row}>
          <div className={classes.col1}>{calculatedData.ucinak} %</div>
          <div className={classes.col2}>
            <input
              style={{
                border: "none",
                width: "60px",
                fontWeight: "bold",
                fontSize: "20px",
                marginTop: "2px",
                textAlign: "center",
              }}
              onChange={(e) => {
                setData({ ...data, ucinak: e.target.value });
              }}
              type="number"
              min="0"
            />
          </div>
          <div className={classes.col3}>Učinak</div>
          <div className={classes.col4}>
            <input
              style={{
                border: "none",
                width: "60px",
                fontWeight: "bold",
                fontSize: "20px",
                marginTop: "2px",
                textAlign: "center",
              }}
              onChange={(e) => {
                setData({ ...data, ucinak2: e.target.value });
              }}
              type="number"
              min="0"
            />
          </div>
          <div className={classes.col5}>{calculatedData.ucinak2} %</div>
        </div>

        <div className={classes.row}>
          <div className={classes.col1}>
            {calculatedData.goloviIzSlobodnihUdaraca} %
          </div>
          <div className={classes.col2}>
            <input
              style={{
                border: "none",
                width: "60px",
                fontWeight: "bold",
                fontSize: "20px",
                marginTop: "2px",
                textAlign: "center",
              }}
              onChange={(e) => {
                setData({ ...data, goloviIzSlobodnihUdaraca: e.target.value });
              }}
              type="number"
              min="0"
            />
          </div>
          <div className={classes.col3}>Golovi iz slobodnih udaraca</div>
          <div className={classes.col4}>
            <input
              style={{
                border: "none",
                width: "60px",
                fontWeight: "bold",
                fontSize: "20px",
                marginTop: "2px",
                textAlign: "center",
              }}
              onChange={(e) => {
                setData({ ...data, goloviIzSlobodnihUdaraca2: e.target.value });
              }}
              type="number"
              min="0"
            />
          </div>
          <div className={classes.col5}>
            {calculatedData.goloviIzSlobodnihUdaraca2} %
          </div>
        </div>

        <div className={classes.row}>
          <div className={classes.col1}>
            {calculatedData.ucinakIzSlobodnihUdaraca} %
          </div>
          <div className={classes.col2}>
            <input
              style={{
                border: "none",
                width: "60px",
                fontWeight: "bold",
                fontSize: "20px",
                marginTop: "2px",
                textAlign: "center",
              }}
              onChange={(e) => {
                setData({ ...data, ucinakIzSlobodnihUdaraca: e.target.value });
              }}
              type="number"
              min="0"
            />
          </div>
          <div className={classes.col3}>Učinak iz slobodnih udaraca</div>
          <div className={classes.col4}>
            <input
              style={{
                border: "none",
                width: "60px",
                fontWeight: "bold",
                fontSize: "20px",
                marginTop: "2px",
                textAlign: "center",
              }}
              onChange={(e) => {
                setData({ ...data, ucinakIzSlobodnihUdaraca2: e.target.value });
              }}
              type="number"
              min="0"
            />
          </div>
          <div className={classes.col5}>
            {calculatedData.ucinakIzSlobodnihUdaraca2} %
          </div>
        </div>
        <div className={classes.row}>
          <div className={classes.col1}>{calculatedData.goloviUnutar16} %</div>
          <div className={classes.col2}>
            <input
              style={{
                border: "none",
                width: "60px",
                fontWeight: "bold",
                fontSize: "20px",
                marginTop: "2px",
                textAlign: "center",
              }}
              onChange={(e) => {
                setData({ ...data, goloviUnutar16: e.target.value });
              }}
              type="number"
              min="0"
            />
          </div>
          <div className={classes.col3}>Golovi unutar 16m</div>
          <div className={classes.col4}>
            <input
              style={{
                border: "none",
                width: "60px",
                fontWeight: "bold",
                fontSize: "20px",
                marginTop: "2px",
                textAlign: "center",
              }}
              onChange={(e) => {
                setData({ ...data, goloviUnutar162: e.target.value });
              }}
              type="number"
              min="0"
            />
          </div>
          <div className={classes.col5}>{calculatedData.goloviUnutar162} %</div>
        </div>
        <div className={classes.row}>
          <div className={classes.col1}>{calculatedData.goloviIzvan16} %</div>
          <div className={classes.col2}>
            <input
              style={{
                border: "none",
                width: "60px",
                fontWeight: "bold",
                fontSize: "20px",
                marginTop: "2px",
                textAlign: "center",
              }}
              onChange={(e) => {
                setData({ ...data, goloviIzvan16: e.target.value });
              }}
              type="number"
              min="0"
            />
          </div>
          <div className={classes.col3}>Golovi izvan 16m</div>
          <div className={classes.col4}>
            <input
              style={{
                border: "none",
                width: "60px",
                fontWeight: "bold",
                fontSize: "20px",
                marginTop: "2px",
                textAlign: "center",
              }}
              onChange={(e) => {
                setData({ ...data, goloviIzvan162: e.target.value });
              }}
              type="number"
              min="0"
            />
          </div>
          <div className={classes.col5}>{calculatedData.goloviIzvan162} %</div>
        </div>
        <div className={classes.row}>
          <div className={classes.col1}>{calculatedData.goloviGlavom} %</div>
          <div className={classes.col2}>
            <input
              style={{
                border: "none",
                width: "60px",
                fontWeight: "bold",
                fontSize: "20px",
                marginTop: "2px",
                textAlign: "center",
              }}
              onChange={(e) => {
                setData({ ...data, goloviGlavom: e.target.value });
              }}
              type="number"
              min="0"
            />
          </div>
          <div className={classes.col3}>Golovi glavom</div>
          <div className={classes.col4}>
            <input
              style={{
                border: "none",
                width: "60px",
                fontWeight: "bold",
                fontSize: "20px",
                marginTop: "2px",
                textAlign: "center",
              }}
              onChange={(e) => {
                setData({ ...data, goloviGlavom2: e.target.value });
              }}
              type="number"
              min="0"
            />
          </div>
          <div className={classes.col5}>{calculatedData.goloviGlavom2} %</div>
        </div>
        <div className={classes.row}>
          <div className={classes.col1}>
            {calculatedData.goloviLevomNogom} %
          </div>
          <div className={classes.col2}>
            <input
              style={{
                border: "none",
                width: "60px",
                fontWeight: "bold",
                fontSize: "20px",
                marginTop: "2px",
                textAlign: "center",
              }}
              onChange={(e) => {
                setData({ ...data, goloviLevomNogom: e.target.value });
              }}
              type="number"
              min="0"
            />
          </div>
          <div className={classes.col3}>Golovi levom nogom</div>
          <div className={classes.col4}>
            <input
              style={{
                border: "none",
                width: "60px",
                fontWeight: "bold",
                fontSize: "20px",
                marginTop: "2px",
                textAlign: "center",
              }}
              onChange={(e) => {
                setData({ ...data, goloviLevomNogom2: e.target.value });
              }}
              type="number"
              min="0"
            />
          </div>
          <div className={classes.col5}>
            {calculatedData.goloviLevomNogom2} %
          </div>
        </div>
        <div className={classes.row}>
          <div className={classes.col1}>
            {calculatedData.goloviDesnomNogom} %
          </div>
          <div className={classes.col2}>
            <input
              style={{
                border: "none",
                width: "60px",
                fontWeight: "bold",
                fontSize: "20px",
                marginTop: "2px",
                textAlign: "center",
              }}
              onChange={(e) => {
                setData({ ...data, goloviDesnomNogom: e.target.value });
              }}
              type="number"
              min="0"
            />
          </div>
          <div className={classes.col3}>Golovi desnom nogom</div>
          <div className={classes.col4}>
            <input
              style={{
                border: "none",
                width: "60px",
                fontWeight: "bold",
                fontSize: "20px",
                marginTop: "2px",
                textAlign: "center",
              }}
              onChange={(e) => {
                setData({ ...data, goloviDesnomNogom2: e.target.value });
              }}
              type="number"
              min="0"
            />
          </div>
          <div className={classes.col5}>
            {calculatedData.goloviDesnomNogom2} %
          </div>
        </div>

        <div className={classes.row}>
          <div className={classes.col1}>{calculatedData.izboreniPenali} %</div>
          <div className={classes.col2}>
            <input
              style={{
                border: "none",
                width: "60px",
                fontWeight: "bold",
                fontSize: "20px",
                marginTop: "2px",
                textAlign: "center",
              }}
              onChange={(e) => {
                setData({ ...data, izboreniPenali: e.target.value });
              }}
              type="number"
              min="0"
            />
          </div>
          <div className={classes.col3}>Izboreni penali</div>
          <div className={classes.col4}>
            <input
              style={{
                border: "none",
                width: "60px",
                fontWeight: "bold",
                fontSize: "20px",
                marginTop: "2px",
                textAlign: "center",
              }}
              onChange={(e) => {
                setData({ ...data, izboreniPenali2: e.target.value });
              }}
              type="number"
              min="0"
            />
          </div>
          <div className={classes.col5}>{calculatedData.izboreniPenali2} %</div>
        </div>

        <div className={classes.row}>
          <div className={classes.col1}>{calculatedData.dodavanje} %</div>
          <div className={classes.col2}>
            <input
              style={{
                border: "none",
                width: "60px",
                fontWeight: "bold",
                fontSize: "20px",
                marginTop: "2px",
                textAlign: "center",
              }}
              onChange={(e) => {
                setData({ ...data, dodavanje: e.target.value });
              }}
              type="number"
              min="0"
            />
          </div>
          <div className={classes.col3}>Dodavanje</div>
          <div className={classes.col4}>
            <input
              style={{
                border: "none",
                width: "60px",
                fontWeight: "bold",
                fontSize: "20px",
                marginTop: "2px",
                textAlign: "center",
              }}
              onChange={(e) => {
                setData({ ...data, dodavanje2: e.target.value });
              }}
              type="number"
              min="0"
            />
          </div>
          <div className={classes.col5}>{calculatedData.dodavanje2} %</div>
        </div>

        <div className={classes.row}>
          <div className={classes.col1}>{calculatedData.asistencije} %</div>
          <div className={classes.col2}>
            <input
              style={{
                border: "none",
                width: "60px",
                fontWeight: "bold",
                fontSize: "20px",
                marginTop: "2px",
                textAlign: "center",
              }}
              onChange={(e) => {
                setData({ ...data, asistencije: e.target.value });
              }}
              type="number"
              min="0"
            />
          </div>
          <div className={classes.col3}>Asistencije</div>
          <div className={classes.col4}>
            <input
              style={{
                border: "none",
                width: "60px",
                fontWeight: "bold",
                fontSize: "20px",
                marginTop: "2px",
                textAlign: "center",
              }}
              onChange={(e) => {
                setData({ ...data, asistencije2: e.target.value });
              }}
              type="number"
              min="0"
            />
          </div>
          <div className={classes.col5}>{calculatedData.asistencije2} %</div>
        </div>

        <div className={classes.row}>
          <div className={classes.col1}>{calculatedData.dodiri} %</div>
          <div className={classes.col2}>
            <input
              style={{
                border: "none",
                width: "60px",
                fontWeight: "bold",
                fontSize: "20px",
                marginTop: "2px",
                textAlign: "center",
              }}
              onChange={(e) => {
                setData({ ...data, dodiri: e.target.value });
              }}
              type="number"
              min="0"
            />
          </div>
          <div className={classes.col3}>Dodiri</div>
          <div className={classes.col4}>
            <input
              style={{
                border: "none",
                width: "60px",
                fontWeight: "bold",
                fontSize: "20px",
                marginTop: "2px",
                textAlign: "center",
              }}
              onChange={(e) => {
                setData({ ...data, dodiri2: e.target.value });
              }}
              type="number"
              min="0"
            />
          </div>
          <div className={classes.col5}>{calculatedData.dodiri2} %</div>
        </div>

        <div className={classes.row}>
          <div className={classes.col1}>
            {calculatedData.kreiraneVelikeSanse} %
          </div>
          <div className={classes.col2}>
            <input
              style={{
                border: "none",
                width: "60px",
                fontWeight: "bold",
                fontSize: "20px",
                marginTop: "2px",
                textAlign: "center",
              }}
              onChange={(e) => {
                setData({ ...data, kreiraneVelikeSanse: e.target.value });
              }}
              type="number"
              min="0"
            />
          </div>
          <div className={classes.col3}>Kreirane velike šanse</div>
          <div className={classes.col4}>
            <input
              style={{
                border: "none",
                width: "60px",
                fontWeight: "bold",
                fontSize: "20px",
                marginTop: "2px",
                textAlign: "center",
              }}
              onChange={(e) => {
                setData({ ...data, kreiraneVelikeSanse2: e.target.value });
              }}
              type="number"
              min="0"
            />
          </div>
          <div className={classes.col5}>
            {calculatedData.kreiraneVelikeSanse2} %
          </div>
        </div>

        <div className={classes.row}>
          <div className={classes.col1}>
            {calculatedData.kljucnaDodavanja} %
          </div>
          <div className={classes.col2}>
            <input
              style={{
                border: "none",
                width: "60px",
                fontWeight: "bold",
                fontSize: "20px",
                marginTop: "2px",
                textAlign: "center",
              }}
              onChange={(e) => {
                setData({ ...data, kljucnaDodavanja: e.target.value });
              }}
              type="number"
              min="0"
            />
          </div>
          <div className={classes.col3}>Ključna dodavanja</div>
          <div className={classes.col4}>
            <input
              style={{
                border: "none",
                width: "60px",
                fontWeight: "bold",
                fontSize: "20px",
                marginTop: "2px",
                textAlign: "center",
              }}
              onChange={(e) => {
                setData({ ...data, kljucnaDodavanja2: e.target.value });
              }}
              type="number"
              min="0"
            />
          </div>
          <div className={classes.col5}>
            {calculatedData.kljucnaDodavanja2} %
          </div>
        </div>

        <div className={classes.row}>
          <div className={classes.col1}>
            {calculatedData.preciznaPoUtakmici} %
          </div>
          <div className={classes.col2}>
            <input
              style={{
                border: "none",
                width: "60px",
                fontWeight: "bold",
                fontSize: "20px",
                marginTop: "2px",
                textAlign: "center",
              }}
              onChange={(e) => {
                setData({ ...data, preciznaPoUtakmici: e.target.value });
              }}
              type="number"
              min="0"
            />
          </div>
          <div className={classes.col3}>Precizna dodavanja po utakmici</div>
          <div className={classes.col4}>
            <input
              style={{
                border: "none",
                width: "60px",
                fontWeight: "bold",
                fontSize: "20px",
                marginTop: "2px",
                textAlign: "center",
              }}
              onChange={(e) => {
                setData({ ...data, preciznaPoUtakmici2: e.target.value });
              }}
              type="number"
              min="0"
            />
          </div>
          <div className={classes.col5}>
            {calculatedData.preciznaPoUtakmici2} %
          </div>
        </div>

        <div className={classes.row}>
          <div className={classes.col1}>
            {calculatedData.preciznaNaSvojojPolovini} %
          </div>
          <div className={classes.col2}>
            <input
              style={{
                border: "none",
                width: "60px",
                fontWeight: "bold",
                fontSize: "20px",
                marginTop: "2px",
                textAlign: "center",
              }}
              onChange={(e) => {
                setData({ ...data, preciznaNaSvojojPolovini: e.target.value });
              }}
              type="number"
              min="0"
            />
          </div>
          <div className={classes.col3}>
            Precizna dodavanja na svojoj polovini
          </div>
          <div className={classes.col4}>
            <input
              style={{
                border: "none",
                width: "60px",
                fontWeight: "bold",
                fontSize: "20px",
                marginTop: "2px",
                textAlign: "center",
              }}
              onChange={(e) => {
                setData({ ...data, preciznaNaSvojojPolovini2: e.target.value });
              }}
              type="number"
              min="0"
            />
          </div>
          <div className={classes.col5}>
            {calculatedData.preciznaNaSvojojPolovini2} %
          </div>
        </div>

        <div className={classes.row}>
          <div className={classes.col1}>
            {calculatedData.preciznaNaProtivnickojPolovini} %
          </div>
          <div className={classes.col2}>
            <input
              style={{
                border: "none",
                width: "60px",
                fontWeight: "bold",
                fontSize: "20px",
                marginTop: "2px",
                textAlign: "center",
              }}
              onChange={(e) => {
                setData({
                  ...data,
                  preciznaNaProtivnickojPolovini: e.target.value,
                });
              }}
              type="number"
              min="0"
            />
          </div>
          <div className={classes.col3}>
            Precizna dodavanja na protivničkoj polovini
          </div>
          <div className={classes.col4}>
            <input
              style={{
                border: "none",
                width: "60px",
                fontWeight: "bold",
                fontSize: "20px",
                marginTop: "2px",
                textAlign: "center",
              }}
              onChange={(e) => {
                setData({
                  ...data,
                  preciznaNaProtivnickojPolovini2: e.target.value,
                });
              }}
              type="number"
              min="0"
            />
          </div>
          <div className={classes.col5}>
            {calculatedData.preciznaNaProtivnickojPolovini2} %
          </div>
        </div>

        <div className={classes.row}>
          <div className={classes.col1}>
            {calculatedData.precizneDugeLopte} %
          </div>
          <div className={classes.col2}>
            <input
              style={{
                border: "none",
                width: "60px",
                fontWeight: "bold",
                fontSize: "20px",
                marginTop: "2px",
                textAlign: "center",
              }}
              onChange={(e) => {
                setData({ ...data, precizneDugeLopte: e.target.value });
              }}
              type="number"
              min="0"
            />
          </div>
          <div className={classes.col3}>Precizne duge lopte</div>
          <div className={classes.col4}>
            <input
              style={{
                border: "none",
                width: "60px",
                fontWeight: "bold",
                fontSize: "20px",
                marginTop: "2px",
                textAlign: "center",
              }}
              onChange={(e) => {
                setData({ ...data, precizneDugeLopte2: e.target.value });
              }}
              type="number"
              min="0"
            />
          </div>
          <div className={classes.col5}>
            {calculatedData.precizneDugeLopte2} %
          </div>
        </div>

        <div className={classes.row}>
          <div className={classes.col1}>{calculatedData.precizniPasovi} %</div>
          <div className={classes.col2}>
            <input
              style={{
                border: "none",
                width: "60px",
                fontWeight: "bold",
                fontSize: "20px",
                marginTop: "2px",
                textAlign: "center",
              }}
              onChange={(e) => {
                setData({ ...data, precizniPasovi: e.target.value });
              }}
              type="number"
              min="0"
            />
          </div>
          <div className={classes.col3}>Precizni pasovi</div>
          <div className={classes.col4}>
            <input
              style={{
                border: "none",
                width: "60px",
                fontWeight: "bold",
                fontSize: "20px",
                marginTop: "2px",
                textAlign: "center",
              }}
              onChange={(e) => {
                setData({ ...data, precizniPasovi2: e.target.value });
              }}
              type="number"
              min="0"
            />
          </div>
          <div className={classes.col5}>{calculatedData.precizniPasovi2} %</div>
        </div>

        <div className={classes.row}>
          <div className={classes.col1}>
            {calculatedData.precizniCentarsutevi} %
          </div>
          <div className={classes.col2}>
            <input
              style={{
                border: "none",
                width: "60px",
                fontWeight: "bold",
                fontSize: "20px",
                marginTop: "2px",
                textAlign: "center",
              }}
              onChange={(e) => {
                setData({ ...data, precizniCentarsutevi: e.target.value });
              }}
              type="number"
              min="0"
            />
          </div>
          <div className={classes.col3}>Precizni centrašutevi</div>
          <div className={classes.col4}>
            <input
              style={{
                border: "none",
                width: "60px",
                fontWeight: "bold",
                fontSize: "20px",
                marginTop: "2px",
                textAlign: "center",
              }}
              onChange={(e) => {
                setData({ ...data, precizniCentarsutevi2: e.target.value });
              }}
              type="number"
              min="0"
            />
          </div>
          <div className={classes.col5}>
            {calculatedData.precizniCentarsutevi2} %
          </div>
        </div>

        <div className={classes.row}>
          <div className={classes.col1}>
            {calculatedData.presecanjePoUtakmici} %
          </div>
          <div className={classes.col2}>
            <input
              style={{
                border: "none",
                width: "60px",
                fontWeight: "bold",
                fontSize: "20px",
                marginTop: "2px",
                textAlign: "center",
              }}
              onChange={(e) => {
                setData({ ...data, presecanjePoUtakmici: e.target.value });
              }}
              type="number"
              min="0"
            />
          </div>
          <div className={classes.col3}>Presecanje po utakmici</div>
          <div className={classes.col4}>
            <input
              style={{
                border: "none",
                width: "60px",
                fontWeight: "bold",
                fontSize: "20px",
                marginTop: "2px",
                textAlign: "center",
              }}
              onChange={(e) => {
                setData({ ...data, presecanjePoUtakmici2: e.target.value });
              }}
              type="number"
              min="0"
            />
          </div>
          <div className={classes.col5}>
            {calculatedData.presecanjePoUtakmici2} %
          </div>
        </div>

        <div className={classes.row}>
          <div className={classes.col1}>
            {calculatedData.oduzeteLoptePoUtakmici} %
          </div>
          <div className={classes.col2}>
            <input
              style={{
                border: "none",
                width: "60px",
                fontWeight: "bold",
                fontSize: "20px",
                marginTop: "2px",
                textAlign: "center",
              }}
              onChange={(e) => {
                setData({ ...data, oduzeteLoptePoUtakmici: e.target.value });
              }}
              type="number"
              min="0"
            />
          </div>
          <div className={classes.col3}>Oduzete lopte po utakmici</div>
          <div className={classes.col4}>
            <input
              style={{
                border: "none",
                width: "60px",
                fontWeight: "bold",
                fontSize: "20px",
                marginTop: "2px",
                textAlign: "center",
              }}
              onChange={(e) => {
                setData({ ...data, oduzeteLoptePoUtakmici2: e.target.value });
              }}
              type="number"
              min="0"
            />
          </div>
          <div className={classes.col5}>
            {calculatedData.oduzeteLoptePoUtakmici2} %
          </div>
        </div>

        <div className={classes.row}>
          <div className={classes.col1}>{calculatedData.osvojeniPosedi} %</div>
          <div className={classes.col2}>
            <input
              style={{
                border: "none",
                width: "60px",
                fontWeight: "bold",
                fontSize: "20px",
                marginTop: "2px",
                textAlign: "center",
              }}
              onChange={(e) => {
                setData({ ...data, osvojeniPosedi: e.target.value });
              }}
              type="number"
              min="0"
            />
          </div>
          <div className={classes.col3}>Osvojeni posedi</div>
          <div className={classes.col4}>
            <input
              style={{
                border: "none",
                width: "60px",
                fontWeight: "bold",
                fontSize: "20px",
                marginTop: "2px",
                textAlign: "center",
              }}
              onChange={(e) => {
                setData({ ...data, osvojeniPosedi2: e.target.value });
              }}
              type="number"
              min="0"
            />
          </div>
          <div className={classes.col5}>{calculatedData.osvojeniPosedi2} %</div>
        </div>

        <div className={classes.row}>
          <div className={classes.col1}>
            {calculatedData.predriblanPoUtakmici} %
          </div>
          <div className={classes.col2}>
            <input
              style={{
                border: "none",
                width: "60px",
                fontWeight: "bold",
                fontSize: "20px",
                marginTop: "2px",
                textAlign: "center",
              }}
              onChange={(e) => {
                setData({ ...data, predriblanPoUtakmici: e.target.value });
              }}
              type="number"
              min="0"
            />
          </div>
          <div className={classes.col3}>Predriblan po utakmici</div>
          <div className={classes.col4}>
            <input
              style={{
                border: "none",
                width: "60px",
                fontWeight: "bold",
                fontSize: "20px",
                marginTop: "2px",
                textAlign: "center",
              }}
              onChange={(e) => {
                setData({ ...data, predriblanPoUtakmici2: e.target.value });
              }}
              type="number"
              min="0"
            />
          </div>
          <div className={classes.col5}>
            {calculatedData.predriblanPoUtakmici2} %
          </div>
        </div>

        <div className={classes.row}>
          <div className={classes.col1}>
            {calculatedData.otklonjeneOpasnostiPoUtakmici} %
          </div>
          <div className={classes.col2}>
            <input
              style={{
                border: "none",
                width: "60px",
                fontWeight: "bold",
                fontSize: "20px",
                marginTop: "2px",
                textAlign: "center",
              }}
              onChange={(e) => {
                setData({
                  ...data,
                  otklonjeneOpasnostiPoUtakmici: e.target.value,
                });
              }}
              type="number"
              min="0"
            />
          </div>
          <div className={classes.col3}>Otklonjene opasnosti po utakmici</div>
          <div className={classes.col4}>
            <input
              style={{
                border: "none",
                width: "60px",
                fontWeight: "bold",
                fontSize: "20px",
                marginTop: "2px",
                textAlign: "center",
              }}
              onChange={(e) => {
                setData({
                  ...data,
                  otklonjeneOpasnostiPoUtakmici2: e.target.value,
                });
              }}
              type="number"
              min="0"
            />
          </div>
          <div className={classes.col5}>
            {calculatedData.otklonjeneOpasnostiPoUtakmici2} %
          </div>
        </div>

        <div className={classes.row}>
          <div className={classes.col1}>
            {calculatedData.skrivljeniSlobodniUdarci} %
          </div>
          <div className={classes.col2}>
            <input
              style={{
                border: "none",
                width: "60px",
                fontWeight: "bold",
                fontSize: "20px",
                marginTop: "2px",
                textAlign: "center",
              }}
              onChange={(e) => {
                setData({ ...data, skrivljeniSlobodniUdarci: e.target.value });
              }}
              type="number"
              min="0"
            />
          </div>
          <div className={classes.col3}>Skrivljeni slobodni udarci</div>
          <div className={classes.col4}>
            <input
              style={{
                border: "none",
                width: "60px",
                fontWeight: "bold",
                fontSize: "20px",
                marginTop: "2px",
                textAlign: "center",
              }}
              onChange={(e) => {
                setData({ ...data, skrivljeniSlobodniUdarci2: e.target.value });
              }}
              type="number"
              min="0"
            />
          </div>
          <div className={classes.col5}>
            {calculatedData.skrivljeniSlobodniUdarci2} %
          </div>
        </div>

        <div className={classes.row}>
          <div className={classes.col1}>
            {calculatedData.uspesniDriblinzi} %
          </div>
          <div className={classes.col2}>
            <input
              style={{
                border: "none",
                width: "60px",
                fontWeight: "bold",
                fontSize: "20px",
                marginTop: "2px",
                textAlign: "center",
              }}
              onChange={(e) => {
                setData({ ...data, uspesniDriblinzi: e.target.value });
              }}
              type="number"
              min="0"
            />
          </div>
          <div className={classes.col3}>Uspešni driblinzi</div>
          <div className={classes.col4}>
            <input
              style={{
                border: "none",
                width: "60px",
                fontWeight: "bold",
                fontSize: "20px",
                marginTop: "2px",
                textAlign: "center",
              }}
              onChange={(e) => {
                setData({ ...data, uspesniDriblinzi2: e.target.value });
              }}
              type="number"
              min="0"
            />
          </div>
          <div className={classes.col5}>
            {calculatedData.uspesniDriblinzi2} %
          </div>
        </div>

        <div className={classes.row}>
          <div className={classes.col1}>
            {calculatedData.ukupniOsvojeniDueli} %
          </div>
          <div className={classes.col2}>
            <input
              style={{
                border: "none",
                width: "60px",
                fontWeight: "bold",
                fontSize: "20px",
                marginTop: "2px",
                textAlign: "center",
              }}
              onChange={(e) => {
                setData({ ...data, ukupniOsvojeniDueli: e.target.value });
              }}
              type="number"
              min="0"
            />
          </div>
          <div className={classes.col3}>Ukupni osvojeni dueli</div>
          <div className={classes.col4}>
            <input
              style={{
                border: "none",
                width: "60px",
                fontWeight: "bold",
                fontSize: "20px",
                marginTop: "2px",
                textAlign: "center",
              }}
              onChange={(e) => {
                setData({ ...data, ukupniOsvojeniDueli2: e.target.value });
              }}
              type="number"
              min="0"
            />
          </div>
          <div className={classes.col5}>
            {calculatedData.ukupniOsvojeniDueli2} %
          </div>
        </div>

        <div className={classes.row}>
          <div className={classes.col1}>
            {calculatedData.osvojeniDueliNaZemlji} %
          </div>
          <div className={classes.col2}>
            <input
              style={{
                border: "none",
                width: "60px",
                fontWeight: "bold",
                fontSize: "20px",
                marginTop: "2px",
                textAlign: "center",
              }}
              onChange={(e) => {
                setData({ ...data, osvojeniDueliNaZemlji: e.target.value });
              }}
              type="number"
              min="0"
            />
          </div>
          <div className={classes.col3}>Osvojeni dueli na zemlji</div>
          <div className={classes.col4}>
            <input
              style={{
                border: "none",
                width: "60px",
                fontWeight: "bold",
                fontSize: "20px",
                marginTop: "2px",
                textAlign: "center",
              }}
              onChange={(e) => {
                setData({ ...data, osvojeniDueliNaZemlji2: e.target.value });
              }}
              type="number"
              min="0"
            />
          </div>
          <div className={classes.col5}>
            {calculatedData.osvojeniDueliNaZemlji2} %
          </div>
        </div>

        <div className={classes.row}>
          <div className={classes.col1}>
            {calculatedData.osvojeniVazdusniDueli} %
          </div>
          <div className={classes.col2}>
            <input
              style={{
                border: "none",
                width: "60px",
                fontWeight: "bold",
                fontSize: "20px",
                marginTop: "2px",
                textAlign: "center",
              }}
              onChange={(e) => {
                setData({ ...data, osvojeniVazdusniDueli: e.target.value });
              }}
              type="number"
              min="0"
            />
          </div>
          <div className={classes.col3}>Osvojeni vazdušni dueli</div>
          <div className={classes.col4}>
            <input
              style={{
                border: "none",
                width: "60px",
                fontWeight: "bold",
                fontSize: "20px",
                marginTop: "2px",
                textAlign: "center",
              }}
              onChange={(e) => {
                setData({ ...data, osvojeniVazdusniDueli2: e.target.value });
              }}
              type="number"
              min="0"
            />
          </div>
          <div className={classes.col5}>
            {calculatedData.osvojeniVazdusniDueli2} %
          </div>
        </div>

        <div className={classes.row}>
          <div className={classes.col1}>{calculatedData.izgubljeniPosed} %</div>
          <div className={classes.col2}>
            <input
              style={{
                border: "none",
                width: "60px",
                fontWeight: "bold",
                fontSize: "20px",
                marginTop: "2px",
                textAlign: "center",
              }}
              onChange={(e) => {
                setData({ ...data, izgubljeniPosed: e.target.value });
              }}
              type="number"
              min="0"
            />
          </div>
          <div className={classes.col3}>Izgubljeni posed</div>
          <div className={classes.col4}>
            <input
              style={{
                border: "none",
                width: "60px",
                fontWeight: "bold",
                fontSize: "20px",
                marginTop: "2px",
                textAlign: "center",
              }}
              onChange={(e) => {
                setData({ ...data, izgubljeniPosed2: e.target.value });
              }}
              type="number"
              min="0"
            />
          </div>
          <div className={classes.col5}>
            {calculatedData.izgubljeniPosed2} %
          </div>
        </div>

        <div className={classes.row}>
          <div className={classes.col1}>{calculatedData.faulovi} %</div>
          <div className={classes.col2}>
            <input
              style={{
                border: "none",
                width: "60px",
                fontWeight: "bold",
                fontSize: "20px",
                marginTop: "2px",
                textAlign: "center",
              }}
              onChange={(e) => {
                setData({ ...data, faulovi: e.target.value });
              }}
              type="number"
              min="0"
            />
          </div>
          <div className={classes.col3}>Faulovi</div>
          <div className={classes.col4}>
            <input
              style={{
                border: "none",
                width: "60px",
                fontWeight: "bold",
                fontSize: "20px",
                marginTop: "2px",
                textAlign: "center",
              }}
              onChange={(e) => {
                setData({ ...data, faulovi2: e.target.value });
              }}
              type="number"
              min="0"
            />
          </div>
          <div className={classes.col5}>{calculatedData.faulovi2} %</div>
        </div>

        <div className={classes.row}>
          <div className={classes.col1}>
            {calculatedData.pretrpljeniFaulovi} %
          </div>
          <div className={classes.col2}>
            <input
              style={{
                border: "none",
                width: "60px",
                fontWeight: "bold",
                fontSize: "20px",
                marginTop: "2px",
                textAlign: "center",
              }}
              onChange={(e) => {
                setData({ ...data, pretrpljeniFaulovi: e.target.value });
              }}
              type="number"
              min="0"
            />
          </div>
          <div className={classes.col3}>Pretrpljeni faulovi</div>
          <div className={classes.col4}>
            <input
              style={{
                border: "none",
                width: "60px",
                fontWeight: "bold",
                fontSize: "20px",
                marginTop: "2px",
                textAlign: "center",
              }}
              onChange={(e) => {
                setData({ ...data, pretrpljeniFaulovi2: e.target.value });
              }}
              type="number"
              min="0"
            />
          </div>
          <div className={classes.col5}>
            {calculatedData.pretrpljeniFaulovi2} %
          </div>
        </div>

        <div className={classes.row}>
          <div className={classes.col1}>{calculatedData.ofsajdi} %</div>
          <div className={classes.col2}>
            <input
              style={{
                border: "none",
                width: "60px",
                fontWeight: "bold",
                fontSize: "20px",
                marginTop: "2px",
                textAlign: "center",
              }}
              onChange={(e) => {
                setData({ ...data, ofsajdi: e.target.value });
              }}
              type="number"
              min="0"
            />
          </div>
          <div className={classes.col3}>Ofsajdi</div>
          <div className={classes.col4}>
            <input
              style={{
                border: "none",
                width: "60px",
                fontWeight: "bold",
                fontSize: "20px",
                marginTop: "2px",
                textAlign: "center",
              }}
              onChange={(e) => {
                setData({ ...data, ofsajdi2: e.target.value });
              }}
              type="number"
              min="0"
            />
          </div>
          <div className={classes.col5}>{calculatedData.ofsajdi2} %</div>
        </div>

        <div className={classes.row}>
          <div className={classes.col1}>{calculatedData.zutiKartoni} %</div>
          <div className={classes.col2}>
            <input
              style={{
                border: "none",
                width: "60px",
                fontWeight: "bold",
                fontSize: "20px",
                marginTop: "2px",
                textAlign: "center",
              }}
              onChange={(e) => {
                setData({ ...data, zutiKartoni: e.target.value });
              }}
              type="number"
              min="0"
            />
          </div>
          <div className={classes.col3}>Žuti karton</div>
          <div className={classes.col4}>
            <input
              style={{
                border: "none",
                width: "60px",
                fontWeight: "bold",
                fontSize: "20px",
                marginTop: "2px",
                textAlign: "center",
              }}
              onChange={(e) => {
                setData({ ...data, zutiKartoni2: e.target.value });
              }}
              type="number"
              min="0"
            />
          </div>
          <div className={classes.col5}>{calculatedData.zutiKartoni2} %</div>
        </div>

        <div className={classes.row}>
          <div className={classes.col1}>{calculatedData.drugiZutiKarton} %</div>
          <div className={classes.col2}>
            <input
              style={{
                border: "none",
                width: "60px",
                fontWeight: "bold",
                fontSize: "20px",
                marginTop: "2px",
                textAlign: "center",
              }}
              onChange={(e) => {
                setData({ ...data, drugiZutiKarton: e.target.value });
              }}
              type="number"
              min="0"
            />
          </div>
          <div className={classes.col3}>Drugi žuti karton</div>
          <div className={classes.col4}>
            <input
              style={{
                border: "none",
                width: "60px",
                fontWeight: "bold",
                fontSize: "20px",
                marginTop: "2px",
                textAlign: "center",
              }}
              onChange={(e) => {
                setData({ ...data, drugiZutiKarton2: e.target.value });
              }}
              type="number"
              min="0"
            />
          </div>
          <div className={classes.col5}>
            {calculatedData.drugiZutiKarton2} %
          </div>
        </div>

        <div className={classes.row}>
          <div className={classes.col1}>{calculatedData.crveniKarton} %</div>
          <div className={classes.col2}>
            <input
              style={{
                border: "none",
                width: "60px",
                fontWeight: "bold",
                fontSize: "20px",
                marginTop: "2px",
                textAlign: "center",
              }}
              onChange={(e) => {
                setData({ ...data, crveniKarton: e.target.value });
              }}
              type="number"
              min="0"
            />
          </div>
          <div className={classes.col3}>Crveni karton</div>
          <div className={classes.col4}>
            <input
              style={{
                border: "none",
                width: "60px",
                fontWeight: "bold",
                fontSize: "20px",
                marginTop: "2px",
                textAlign: "center",
              }}
              onChange={(e) => {
                setData({ ...data, crveniKarton2: e.target.value });
              }}
              type="number"
              min="0"
            />
          </div>
          <div className={classes.col5}>{calculatedData.crveniKarton2} %</div>
        </div>
      </div>
    </div>
  );
};

export default PoredenjeIgracaFudbal;
