import React from 'react'
import { useContext, useState } from 'react'
import classes from './LoginModal.module.css'
import axios from '../config/axios'
import { Link, useNavigate } from 'react-router-dom'
import { useMutation } from '@tanstack/react-query'
import { IoClose } from 'react-icons/io5'
import AuthContext from '../context/AuthProvider'
import { Modal } from 'antd'

// NOTE: KOMPONENTA JE SAMO KOPIRANA IZ Comments.jsx
const LoginModal = ({ setIsOpenLoginModal, title, isOpenLoginModal }) => {
  const [loginModal, setLoginModal] = useState(true)
  const [registerModal, setRegisterModal] = useState(false)
  const [login, setLogin] = useState(true)
  const [email, setEmail] = useState('')
  const [userName, setUserName] = useState('')
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [checked, setChecked] = useState(false)
  const [checkedTerms, setCheckedTerms] = useState(false)
  const [isLoginError, setIsLoginError] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [loginErrorMessage, setLoginErrorMessage] = useState('')
  const [loginEmail, setLoginEmail] = useState('')
  const [loginPassword, setLoginPassword] = useState('')
  const navigate = useNavigate()
  const { setRefetch, setAuth } = useContext(AuthContext)
  const [isError, setIsError] = useState(false)
  const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
  const rp =
    /^(?=.*?[A-Z])(?=(.*[a-z]){1,})(?=(.*[\d]){1,})(?=(.*[\W]){1,})(?!.*\s).{8,}$/
  const validEmail = re.test(email)
  const validPassword = rp.test(password)
  const validLoginEmail = re.test(loginEmail)
  const validLoginPassword = rp.test(loginPassword)

  const handleRegistration = (e) => {
    e.preventDefault()

    if (!userName) {
      setIsError(true)
      setErrorMessage('Username polje je obavezno')
    }
    if (validEmail && validPassword && checked && userName && checkedTerms) {
      setIsError(false)
      setErrorMessage('')
      registerMutation.mutate({ userName, email, password })
    }
    if (!checked) {
      setIsError(true)
      setErrorMessage('Niste potvrdili broj godina')
    }
    if (!checkedTerms) {
      setIsError(true)
      setErrorMessage('Niste potvrdili opšte uslove')
    }
    if (password !== confirmPassword) {
      setIsError(true)
      setErrorMessage('Lozinke se ne podudaraju')
    }
    if (!validPassword) {
      setIsError(true)
      setErrorMessage(
        'Lozinka mora biti minimum 8 karaktera, sadržati bar jedno veliko slovo, malo slovo, broj i specijalan karakter'
      )
    }
    if (!validEmail) {
      setIsError(true)
      setErrorMessage('Email nije ispravan')
    }
  }

  const handleLogin = (e) => {
    e.preventDefault()
    if (validLoginEmail && validLoginPassword) {
      setIsLoginError(false)
      setLoginErrorMessage('')
      loginMutation.mutate({ email: loginEmail, password: loginPassword })
    }
    if (!validLoginPassword) {
      setIsLoginError(true)
      setLoginErrorMessage(
        'Lozinka mora biti minimum 8 karaktera, sadržati bar jedno veliko slovo, malo slovo, broj i specijalan karakter'
      )
    }
    if (!validLoginEmail) {
      setIsLoginError(true)
      setLoginErrorMessage('Email nije ispravan')
    }
  }

  const registerMutation = useMutation({
    mutationFn: (userData) => {
      return axios.post('/register', userData)
    },
    onSuccess: () => {
      setLogin(true)
      setRegisterModal(false)
      setLoginModal(true)
      setEmail('')
      setPassword('')
    },

    onError: (error) => {
      console.log(error)
      if (error.response.status === 410) {
        setIsError(true)
        setErrorMessage('Username već postoji')
      }
      if (error.response.status === 409) {
        setIsError(true)
        setErrorMessage('Ovaj email je već registrovan')
      }
      if (error.response.status === 401) {
        setIsError(true)
        setErrorMessage('Email nije registrovan')
      }
      if (error.response.status === 429) {
        setIsError(true)
        setErrorMessage(
          'Previse pokusaja logovanja, pokusajte opet za 60 sekundi.'
        )
      }
    },
  })

  const loginMutation = useMutation({
    mutationFn: async (userData) => {
      const response = await axios.post('/auth', userData, {
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true,
      })
      const accessToken = response?.data?.accessToken
      const roles = response?.data?.roles
      const paidToDate = new Date(response?.data?.paidToDate).getTime()
      const email = response?.data?.email
      const _id = response?.data?._id
      const commentRestricted = response?.data?.commentRestricted
      const status = response?.data?.status
      const username = response?.data?.username
      const emailNotification = response?.data?.emailNotification
      const commentNotification = response?.data?.commentNotification
      const moderator = response?.data?.moderator
      const isMisterTipster = response?.data?.isMisterTipster
      const accessAdminShop = response?.data?.accessAdminShop
      const name = response?.data?.name
      const address = response?.data?.address
      const place = response?.data?.place
      const zipCode = response?.data?.zipCode
      const phoneNumber = response?.data?.phoneNumber
      const misterTipsterNotification =
        response?.data?.misterTipsterNotification

      setAuth({
        email,
        accessToken,
        roles,
        paidToDate,
        _id,
        commentRestricted,
        status,
        username,
        emailNotification,
        commentNotification,
        moderator,
        isMisterTipster,
        accessAdminShop,
        name,
        address,
        place,
        zipCode,
        phoneNumber,
        misterTipsterNotification,
      })
      setRefetch((curr) => !curr)
    },
    onSuccess: () => {
      setIsOpenLoginModal(false)
    },
    onError: (error) => {
      if (error.response.status === 401) {
        setIsLoginError(true)
        setLoginErrorMessage('Pogrešan email ili password.')
      }
      if (error.response.status === 429) {
        setIsLoginError(true)
        setLoginErrorMessage(
          'Previse pokusaja logovanja, pokusajte opet za 60 sekundi.'
        )
      }
    },
  })
  return (
    <Modal
      open={isOpenLoginModal}
      footer={null}
      onCancel={() => setIsOpenLoginModal(false)}
      className={classes.loginModal}
      closable={false}
    >
      {loginModal ? (
        <div
          className={`${classes.loginContainerModal} ${
            loginModal && classes.active
          }`}
        >
          <div className={classes.loginRow}>
            <IoClose
              onClick={() => {
                setIsOpenLoginModal(false)
              }}
              style={{ color: 'red', fontSize: '50px', cursor: 'pointer' }}
            />
          </div>

          <div className={`${classes.loginRow} ${classes.loginRowInfo}`}>
            <p>
              {title || 'Za komentarisanje je potrebno da budete ulogovani'}
            </p>
          </div>

          <div className={`${classes.loginRow} ${classes.logovanje}`}>
            <p>Logovanje</p>
            {isLoginError && (
              <div className={classes.errorRegDiv}>{loginErrorMessage}</div>
            )}
            <form onSubmit={() => {}} className={classes.loginForm}>
              <div className={classes.loginFormRow}>
                <input
                  type='email'
                  name='email'
                  placeholder='Email'
                  required=''
                  onChange={(e) => setLoginEmail(e.target.value)}
                  value={loginEmail}
                />
              </div>

              <div className={classes.loginFormRow}>
                <input
                  type='password'
                  name='pswd'
                  placeholder='Lozinka'
                  required=''
                  onChange={(e) => setLoginPassword(e.target.value)}
                  value={loginPassword}
                />
              </div>

              <div className={`${classes.loginFormRow} ${classes.forgotPass}`}>
                <button
                  onClick={() => navigate('/zaboravio_sam_lozinku')}
                  type='button'
                >
                  Zaboravio sam lozinku
                </button>
              </div>

              <div className={`${classes.loginFormRow} ${classes.loginButton}`}>
                <button
                  disabled={loginMutation.isLoading}
                  onClick={handleLogin}
                  type='submit'
                >
                  Login
                </button>
              </div>

              <div className={`${classes.loginFormRow} ${classes.registrujSe}`}>
                <span>Nemaš nalog?</span>
                <button
                  onClick={() => {
                    setLoginModal(false)
                    setRegisterModal(true)
                  }}
                  type='button'
                >
                  Registruj se
                </button>
              </div>
            </form>
          </div>
        </div>
      ) : (
        <div
          className={`${classes.registerContainerModal} ${
            registerModal && classes.active
          }`}
        >
          <div className={classes.loginRow}>
            <IoClose
              onClick={() => {
                setIsOpenLoginModal(false)
              }}
              style={{ color: 'red', fontSize: '50px', cursor: 'pointer' }}
            />
          </div>

          <div className={`${classes.loginRow} ${classes.loginRowInfo}`}>
            {title || 'Za komentarisanje je potrebno da budete ulogovani'}
          </div>

          <div className={`${classes.loginRow} ${classes.logovanje}`}>
            <p>Registracija</p>
            {isError && (
              <div className={classes.errorRegDiv}>{errorMessage}</div>
            )}
            <form onSubmit={() => {}} className={classes.loginForm}>
              <div className={classes.loginFormRow}>
                <input
                  type='text'
                  // name='username'
                  placeholder='Username'
                  required=''
                  value={userName}
                  onChange={(e) => setUserName(e.target.value)}
                />
              </div>

              <div className={classes.loginFormRow}>
                <input
                  type='email'
                  name='email'
                  placeholder='Email'
                  required=''
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>

              <div className={classes.loginFormRow}>
                <input
                  className={classes.input}
                  type='password'
                  name='txt'
                  placeholder='Lozinka'
                  required=''
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>

              <div className={classes.loginFormRow}>
                <input
                  type='password'
                  name='pswd'
                  placeholder='Ponovi Lozinku'
                  required=''
                  onChange={(e) => setConfirmPassword(e.target.value)}
                />
              </div>

              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  gap: '2px',
                }}
              >
                <label className={classes.labelChk} htmlFor='potvrda'>
                  Potvrđujem da imam više od 18 godina
                </label>

                <input
                  className={classes.checkbox}
                  type='checkbox'
                  name='potvrda'
                  id='potvrda'
                  checked={checked}
                  onChange={() => setChecked((prev) => !prev)}
                />
              </div>

              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  gap: '2px',
                }}
              >
                <label className={classes.labelChk} htmlFor='opstiUslovi'>
                  Saglasan sam i prihvatam opšte uslove{' '}
                  <Link
                    style={{ color: 'rgb(180,0,255,1)' }}
                    to='/opsti_uslovi'
                    target='_blank'
                  >
                    (opšti uslovi)
                  </Link>{' '}
                  i politiku privatnosti{' '}
                  <Link
                    style={{ color: 'rgb(180,0,255,1)' }}
                    to='/politika-privatnosti'
                    target='_blank'
                  >
                    (politika privatnosti)
                  </Link>{' '}
                  korišćenja betkotip.com
                </label>

                <div style={{ display: 'block' }}>
                  <input
                    className={classes.checkbox}
                    type='checkbox'
                    name='opstiUslovi'
                    id='opstiUslovi'
                    checked={checkedTerms}
                    onChange={() => setCheckedTerms((prev) => !prev)}
                  />
                </div>
              </div>

              <div className={`${classes.loginFormRow} ${classes.loginButton}`}>
                <button
                  disabled={registerMutation.isLoading}
                  onClick={handleRegistration}
                  type='submit'
                >
                  Registracija
                </button>
              </div>

              <div className={`${classes.loginFormRow} ${classes.registrujSe}`}>
                <button
                  onClick={() => {
                    setRegisterModal(false)
                    setLoginModal(true)
                  }}
                  type='button'
                >
                  Uloguj se
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </Modal>
  )
}

export default LoginModal
