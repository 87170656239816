import Grupa1 from "../components/Grupa1";
import Grupa28 from "../components/Grupa28";
import classes from "./projekcijaUtakmice.module.css";
import { useState } from "react";
import { useEffect } from "react";

import styles from "../components/grupa3006.module.css";
import { is } from "date-fns/locale";
const ProjekcijaUtakmice = () => {
  const tips = [
    "KI 1",
    "KI X",
    "KI 2",
    "UG 0-2",
    "UG 3+",
    "DOM +",
    "DOM -",
    "DOM. BR. UT.",
    "GOST-",
    "GOST+",
    "GOST BR. UT.",
  ];
  const [odds, setOdds] = useState(new Array(11).fill());
  const [ocekivanBrGolova, setOcekivanBrGolova] = useState(0);

  const handleChangeOdds = (e, index) => {
    const newOdds = [...odds];
    newOdds[index] = e.target.value;
    setOdds(newOdds);
  };

  const L27 = odds[5];
  const N27 = odds[6];
  const P27 = odds[7];
  const Q27 = odds[8];
  const S27 = odds[9];
  const U27 = odds[10];
  const B27 = odds[0];
  const D27 = odds[1];
  const F27 = odds[2];
  const H27 = odds[3];
  const J27 = odds[4];

  const L63 = Number(L27);
  const M63 = Number(N27);
  const N63 = Number(P27);
  const O63 = Number(Q27);
  const P63 = Number(S27);
  const Q63 = Number(U27);
  const C68 = Number(B27);
  const D68 = Number(D27);
  const E68 = Number(F27);
  const G66 = Number(H27);
  const H66 = Number(J27);

  const F68 = 1 / Number(C68);
  const F68Value = isNaN(F68) ? "-" : Number(F68 * 100).toFixed(2);
  const G68 = 1 / D68;
  const G68Value = isNaN(G68) ? "-" : Number(G68 * 100).toFixed(2);
  const H68 = 1 / E68;
  const H68Value = isNaN(H68) ? "-" : Number(H68 * 100).toFixed(2);
  const I68 = Number(Number(F68) + Number(G68) + Number(H68) - 1);
  const I68Value = isNaN(I68) ? "-" : Number(I68 * 100).toFixed(2);
  const J68 = F68 - I68 / 3;
  const J68Value = isNaN(J68) ? "-" : Number(J68 * 100).toFixed(2);
  const K68 = G68 - I68 / 3;
  const K68Value = isNaN(K68) ? "-" : Number(K68 * 100).toFixed(2);
  const L68 = H68 - I68 / 3;
  const L68Value = isNaN(L68) ? "-" : Number(L68 * 100).toFixed(2);
  const M68 = J68 * I68;
  const M68Value = isNaN(M68) ? "-" : Number(M68 * 100).toFixed(2);
  const N68 = K68 * I68;
  const N68Value = isNaN(N68) ? "-" : Number(N68 * 100).toFixed(2);
  const O68 = L68 * I68;
  const O68Value = isNaN(O68) ? "-" : Number(O68 * 100).toFixed(2);
  const P68 = F68 - M68;
  const P68Value = isNaN(P68) ? "-" : Number(P68 * 100).toFixed(2);
  const Q68 = G68 - N68;
  const Q68Value = isNaN(Q68) ? "-" : Number(Q68 * 100).toFixed(2);
  const R68 = H68 - O68;
  const R68Value = isNaN(R68) ? "-" : Number(R68 * 100).toFixed(2);
  const S68 = P68 + Q68 / 2;
  const S68Value = isNaN(S68) ? "-" : Number(S68 * 100).toFixed(2); // PROCENAT DOMACIN
  const T68 = 1 - S68;
  const T68Value = isNaN(T68) ? "-" : Number(T68 * 100).toFixed(2); //PROCENAT GOST
  const I66 = 1 / G66;
  const I66Value = isNaN(I66) ? "-" : Number(I66 * 100).toFixed(2);
  const J66 = 1 / H66;
  const J66Value = isNaN(J66) ? "-" : Number(J66 * 100).toFixed(2);
  const K66 = Number(I66) + Number(J66);
  const K66Value = isNaN(K66) ? "-" : Number(K66 * 100).toFixed(2);
  const L66 = K66 - 1;
  const L66Value = isNaN(L66) ? "-" : Number(L66 * 100).toFixed(2);
  const M66 = L66 / 2;
  const M66Value = isNaN(M66) ? "-" : Number(M66 * 100).toFixed(2);
  const N66 = I66 - M66;
  const N66Value = isNaN(N66) ? "-" : Number(N66 * 100).toFixed(2);
  const O66 = J66 - M66;
  const O66Value = isNaN(O66) ? "-" : Number(O66 * 100).toFixed(2);
  const P66 = () => {
    if (N66 < 0) {
      return 0;
    } else {
      return N66;
    }
  };

  const P66Value = isNaN(P66()) ? "-" : Number(P66() * 100).toFixed(2);
  const Q66 = () => {
    if (O66 < 0) {
      return 0;
    } else {
      return O66;
    }
  };

  const Q66Value = isNaN(Q66()) ? "-" : Number(Q66() * 100).toFixed(2);

  const B23 = L63 / N63;
  const B23Value = isNaN(B23) ? "-" : Number(B23).toFixed(2);
  const D23 = N63 / O63;
  const D23Value = isNaN(D23) ? "-" : Number(D23).toFixed(2);
  const F23 = O63 / P63;
  const F23Value = isNaN(F23) ? "-" : Number(F23).toFixed(2);
  const H23 = P63 / Q63;
  const H23Value = isNaN(H23) ? "-" : Number(H23).toFixed(2);

  const R66 = Q66() * 5;
  const R66Value = isNaN(R66) ? "-" : Number(R66).toFixed(2);
  const S66 = Number(L27 / P27); //SND
  const T66 = Number(N27 / P27); //SOD
  const U66 = Number(Q27 / U27); //SOG
  const V66 = Number(S27 / U27); //SNG

  const SODG = Number(T66 + U66) / 2;

  const SNDG = Number(S66 + V66) / 2;
  const SNDSOG = Number(S66 + U66) / 2;
  const SODSNG = Number(T66 + V66) / 2;

  const W66 =
    (Number(R66) + Number(S66) + Number(T66) + Number(U66) + Number(V66)) / 5; // PROVERITI
  const W66Value = isNaN(W66) ? "-" : Number(W66).toFixed(2);
  const B75 = Number(W66);

  const C75 = Number(B75);
  const I33 = Number(W66).toFixed(2); // Ukupan broj golova
  const F32 = I33 * S68; // Ocekivan broj golova domacin
  const F32Value = isNaN(F32) ? "-" : Number(F32).toFixed(2);
  const V68 = Number(F32);
  const O32 = I33 * T68; //Ocekivan broj golova gost
  const O32Value = isNaN(O32) ? "-" : Number(O32).toFixed(2);
  const W68 = Number(O32);
  const V70 = Number(Math.round(V68));
  const W70 = Number(Math.round(W68));
  const X70 = V70 + " : " + W70; // Tacan rezultat
  const F80 = S68;
  const F80Value = isNaN(F80) ? "-" : Number(F80 * 100).toFixed(2);
  const O80 = T68;
  const O80Value = isNaN(O80) ? "-" : Number(O80 * 100).toFixed(2);
  const D32 = (F32 * 55) / 100; // Golovi domacin drugo poluvreme
  const D32Value = isNaN(D32) ? "-" : Number(D32).toFixed(2);
  const B32 = (F32 * 45) / 100;

  const B32Value = isNaN(B32) ? "-" : Number(B32).toFixed(2);
  const B67 = Number(Number(I33) * Number(T68)); // Gost 90 minuta
  const B67Value = isNaN(B67) ? "-" : Number(B67).toFixed(2);
  const B68 = Number((Number(B67) * 55) / 100);
  const B68Value = isNaN(B68) ? "-" : Number(B68).toFixed(2);
  const B69 = Number((Number(B67) * 45) / 100);
  const B69Value = isNaN(B69) ? "-" : Number(B69).toFixed(2);
  const C71 = 0.912408759124087;
  const D71 = 2.5;
  const E71 = 23;
  const F71 = 8;
  const G71 = 10;
  const H71 = 5;
  const I71 = 26;
  const J71 = 9;
  const K71 = 4;
  const L71 = 36;
  const M71 = 5;
  const N71 = 22;
  const O71 = 4;
  const P71 = 807;
  const Q71 = 651;
  const R71 = 23;
  const S71 = 187;
  const T71 = 83;
  const E75 = Number(Math.round((Number(C75) / Number(D71)) * Number(E71)));
  const F75 = Number(Math.round((Number(C75) / Number(D71)) * Number(F71)));
  const G75 = Number(Math.round(Number((C75 / Number(D71)) * Number(G71))));
  const H75 = Number(Math.round((Number(C75) / Number(D71)) * Number(H71)));
  const I75 = Number(Math.round((Number(C75) / Number(D71)) * Number(I71)));
  const J75 = Number(Math.round((Number(C75) / Number(D71)) * Number(J71)));
  const K75 = Number(Math.round((Number(C75) / Number(D71)) * Number(K71)));
  const L75 = Number(Math.round(Number((C75 / Number(D71)) * Number(L71))));
  const M75 = Number(Math.round((Number(C75) / Number(D71)) * Number(M71)));
  const N75 = Number(Math.round((Number(C75) / Number(D71)) * Number(N71)));
  const O75 = Number(Math.round((Number(C75) / Number(D71)) * Number(O71)));
  const P75 = Number((Number(C75) / Number(D71)) * Number(P71));
  const Q75 = Number(Math.round((Number(C75) / Number(D71)) * Number(Q71)));
  const R75 = Number(Math.round((Number(C75) / Number(R71)) * Number(R71)));
  const S75 = Number(Math.round((Number(C75) / Number(D71)) * Number(S71)));
  const T75 = Number(Math.round((Number(C75) / Number(D71)) * Number(T71)));
  const F82 = Number(F75) * Number(S68); // Sutevi u okvir gola domacin
  const F82Value = isNaN(F82) ? "-" : Math.round(F82);
  const D82 = (Number(F82) * 55) / 100; // sutevi u okvir gola domacin 2P
  const D82Value = isNaN(D82) ? "-" : Number(D82).toFixed(2);
  const B82 = (Number(F82) * 45) / 100; // sutevi u okvir gola domacin PP
  const B82Value = isNaN(B82) ? "-" : Number(B82).toFixed(2);
  const O82 = Number(F75) * Number(T68); // sutevi u okvir gola gost
  const O82Value = isNaN(O82) ? "-" : Math.round(O82);
  const R82 = (Number(O82) * 55) / 100; // sutevi u okvir gola gost 2P
  const R82Value = isNaN(R82) ? "-" : Number(R82).toFixed(2);
  const T82 = (Number(O82) * 45) / 100;
  const T82Value = isNaN(T82) ? "-" : Number(T82).toFixed(2);
  const F83 = G75 * S68; // sutevi van okvira gola domacin
  const F83Value = Math.round(F83);

  const D83 = (Number(F83) * 55) / 100; //sutevi van okvira gola domacin 2p
  const D83Value = isNaN(D83) ? "-" : Number(D83).toFixed(2);
  const B83 = (Number(F83) * 45) / 100; //sutevi van okvira gola domacin pp
  const B83Value = isNaN(B83) ? "-" : Number(B83).toFixed(2);
  const O83 = G75 * T68; // sutevi van okvira gola gost
  const O83Value = isNaN(O83) ? "-" : Math.round(O83);
  const R83 = (Number(O83) * 55) / 100; //sutevi van okvira gola gost 2p
  const R83Value = isNaN(R83) ? "-" : Number(R83).toFixed(2);
  const T83 = (Number(O83) * 45) / 100; //sutevi van okvira gola gost pp
  const T83Value = isNaN(T83) ? "-" : Number(T83).toFixed(2);
  const F84 = H75 * S68; // blokirani sutevi domacin
  const F84Value = isNaN(F84) ? "-" : Math.round(F84);

  const D84 = (Number(F84) * 55) / 100; // blokirani sutevi drugo poluvreme domacin
  const D84Value = isNaN(D84) ? "-" : Number(D84).toFixed(2);
  const B84 = (Number(F84) * 45) / 100; // blokirani sutevi prvo poluvreme domacin
  const B84Value = isNaN(B84) ? "-" : Number(B84).toFixed(2);
  const O84 = H75 * T68; // blokirani sutevi gost
  const O84Value = isNaN(O84) ? "-" : Math.round(O84);
  const R84 = (Number(O84) * 55) / 100; // blokirani sutevi gost drgo poluvreme
  const R84Value = isNaN(R84) ? "-" : Number(R84).toFixed(2);
  const T84 = (Number(O84) * 45) / 100; // blokirani sutevi gost prvo poluvreme
  const T84Value = isNaN(T84) ? "-" : Number(T84).toFixed(2);
  const F81 = F82Value + F83Value + F84Value; // sutevi ka golu domacin
  const F81Value = isNaN(F81) ? "-" : Math.round(F81);
  const D81 = (Number(F81) * 55) / 100; // sutevi ka golu 2p domacin
  const D81Value = isNaN(D81) ? "-" : Number(D81).toFixed(2);
  const B81 = (Number(F81) * 45) / 100; // sutevi ka golu pp domacin
  const B81Value = isNaN(B81) ? "-" : Number(B81).toFixed(2);
  const O81 = O82Value + O83Value + O84Value; // sutevi ka golu gost
  const O81Value = isNaN(O81) ? "-" : Math.round(O81);
  const R81 = (Number(O81) * 55) / 100; // sutevi ka golu 2p gost
  const R81Value = isNaN(R81) ? "-" : Number(R81).toFixed(2);
  const T81 = (Number(O81) * 45) / 100; // sutevi ka golu pp gost
  const T81Value = isNaN(T81) ? "-" : Number(T81).toFixed(2);
  const F85 = I75 * S68; // slobodni udarci domacin
  const F85Value = isNaN(F85) ? "-" : Math.round(F85);
  const D85 = (Number(F85) * 55) / 100; // slobodni udarci domacin 2p
  const D85Value = isNaN(D85) ? "-" : Number(D85).toFixed(2);
  const B85 = (Number(F85) * 45) / 100; // slobodni udarci domacin pp
  const B85Value = isNaN(B85) ? "-" : Number(B85).toFixed(2);
  const O85 = I75 * T68; // slobodni udarci gost
  const O85Value = isNaN(O85) ? "-" : Math.round(O85);
  const R85 = (Number(O85) * 55) / 100; // slobodni udarci gost 2p;
  const R85Value = isNaN(R85) ? "-" : Number(R85).toFixed(2);
  const T85 = (Number(O85) * 45) / 100; // slobodni udarci gost pp;
  const T85Value = isNaN(T85) ? "-" : Number(T85).toFixed(2);
  const F86 = J75 * S68; // korneri domacin;
  const F86Value = isNaN(F86) ? "-" : Math.round(F86);
  const D86 = (Number(F86) * 55) / 100; // korneri domacin 2p
  const D86Value = isNaN(D86) ? "-" : Number(D86).toFixed(2);
  const B86 = (Number(F86) * 45) / 100; // korneri domacin pp
  const B86Value = isNaN(B86) ? "-" : Number(B86).toFixed(2);
  const O86 = J75 * T68; // korneri gost
  const O86Value = isNaN(O86) ? "-" : Math.round(O86);
  const R86 = (Number(O86) * 55) / 100; // korneri gost 2p
  const R86Value = isNaN(R86) ? "-" : Number(R86).toFixed(2);
  const T86 = (Number(O86) * 45) / 100; // korneri gost pp
  const T86Value = isNaN(T86) ? "-" : Number(T86).toFixed(2);
  const F87 = K75 * S68; // ofsajdi domacin
  const F87Value = isNaN(F87) ? "-" : Math.round(F87);
  const D87 = (Number(F87) * 55) / 100; // ofsajdi domacin 2p
  const D87Value = isNaN(D87) ? "-" : Number(D87).toFixed(2);
  const B87 = (Number(F87) * 45) / 100; // ofsajdi domacin pp
  const B87Value = isNaN(B87) ? "-" : Number(B87).toFixed(2);
  const O87 = K75 * T68; // ofsajdi gost
  const O87Value = isNaN(O87) ? "-" : Math.round(O87);
  const R87 = (Number(O87) * 55) / 100; // ofsajdi drugo poluvreme gost
  const R87Value = isNaN(R87) ? "-" : Number(R87).toFixed(2);
  const T87 = (Number(O87) * 45) / 100; // ofsajdi pp gost
  const T87Value = isNaN(T87) ? "-" : Number(T87).toFixed(2);
  const F88 = L75 * S68; //centarsutevi domacin
  const F88Value = isNaN(F88) ? "-" : Math.round(F88);
  const D88 = (Number(F88) * 55) / 100; // centarsutevi domacin 2p
  const D88Value = isNaN(D88) ? "-" : Number(D88).toFixed(2);
  const B88 = (Number(F88) * 45) / 100; // centarsutevi domacin pp
  const B88Value = isNaN(B88) ? "-" : Number(B88).toFixed(2);
  const O88 = L75 * T68; // centarsutevi gost
  const O88Value = isNaN(O88) ? "-" : Math.round(O88);
  const R88 = (Number(O88) * 55) / 100; // centarsutevi gost 2p
  const R88Value = isNaN(R88) ? "-" : Number(R88).toFixed(2);
  const T88 = (Number(O88) * 45) / 100; //centarsutevi gost pp
  const T88Value = isNaN(T88) ? "-" : Number(T88).toFixed(2);
  const F89 = M75 * S68; // odbrane golmana domacin
  const F89Value = isNaN(F89) ? "-" : Math.round(F89);
  const D89 = (Number(F89) * 55) / 100; // odbrane golmana domacin 2p
  const D89Value = isNaN(D89) ? "-" : Number(D89).toFixed(2);
  const B89 = (Number(F89) * 45) / 100; // odbrane golmana domacin pp
  const B89Value = isNaN(B89) ? "-" : Number(B89).toFixed(2);
  const O89 = M75 * T68; // odbrane golmana gost
  const O89Value = isNaN(O89) ? "-" : Math.round(O89);
  const R89 = (Number(O89) * 55) / 100; // odbrane golamana gost 2p
  const R89Value = isNaN(R89) ? "-" : Number(R89).toFixed(2);
  const T89 = (Number(O89) * 45) / 100; // odbrane golmana gost pp
  const T89Value = isNaN(T89) ? "-" : Number(T89).toFixed(2);
  const F90 = N75 * S68; // faulovi domacin
  const F90Value = isNaN(F90) ? "-" : Math.round(F90);
  const D90 = (Number(F90) * 55) / 100; // faulovi domacin 2p
  const D90Value = isNaN(D90) ? "-" : Number(D90).toFixed(2);
  const B90 = (Number(F90) * 45) / 100; // faulovi domacin pp
  const B90Value = isNaN(B90) ? "-" : Number(B90).toFixed(2);
  const O90 = N75 * T68; // faulovi gost
  const O90Value = isNaN(O90) ? "-" : Math.round(O90);
  const R90 = (Number(O90) * 55) / 100; // faulovi gost 2p
  const R90Value = isNaN(R90) ? "-" : Number(R90).toFixed(2);
  const T90 = (Number(O90) * 45) / 100; // faulovi gost pp
  const T90Value = isNaN(T90) ? "-" : Number(T90).toFixed(2);
  const F91 = O75 * S68; //zuti domacin kartoni
  const F91Value = isNaN(F91) ? "-" : Math.round(F91);
  const D91 = (Number(F91) * 55) / 100; //zuti  domacin kartoni 2p
  const D91Value = isNaN(D91) ? "-" : D91.toFixed(2);
  const B91 = (Number(F91) * 45) / 100; //zuti domacin kartoni pp
  const B91Value = isNaN(B91) ? "-" : Number(B91).toFixed(2);
  const O91 = N75 * T68; // zuti gost kartoni gost
  const O91Value = isNaN(O91) ? "-" : Math.round(O91);
  const R91 = (Number(O91) * 55) / 100; // zuti kartoni gost2p
  const R91Value = isNaN(R91) ? "-" : Number(R91).toFixed(2);
  const T91 = (Number(O91) * 45) / 100; // zuti kartoni gost pp
  const T91Value = isNaN(T91) ? "-" : Number(T91).toFixed(2);
  const F92 = P75 * S68; // dodavanja domacin

  const D92 = (Number(F92) * 55) / 100; // dodavanja domacin 2p
  const B92 = (Number(F92) * 45) / 100; // dodavanja domacin pp
  const O92 = P75 * T68; // dodavanja gost
  const R92 = (Number(O92) * 55) / 100; //dodavanja gost 2p
  const T92 = (Number(O92) * 45) / 100; //dodavanja gost pp
  const F93 = Q75 * S68; //precizna dodavanja domacin
  const D93 = (Number(F93) * 55) / 100; //precizna dodavanja domacin 2p
  const B93 = (Number(F93) * 45) / 100; // precizna dodavanja domacin
  const O93 = Q75 * T68; // gost precizna dodavanja
  const R93 = (Number(O93) * 55) / 100; // precizna dodavanja gost 2p
  const T93 = (Number(O93) * 45) / 100; // precizna dodavanja gost pp
  const F94 = R75 * S68; // klizeci startovi domacin
  const D94 = (Number(F94) * 55) / 100; // klizeci startovi domacin 2p
  const B94 = (Number(F94) * 45) / 100; // klizeci startovi domacin pp
  const O94 = R75 * T68; // klizeci startovi gost
  const R94 = (Number(O94) * 55) / 100; //klizeci startovi gost 2p
  const T94 = (Number(O94) * 45) / 100; // klizeci startovi gost pp
  const F95 = S75 * S68; // napadi domacin
  const D95 = (Number(F95) * 55) / 100; // napadi domacin 2p
  const B95 = (Number(F95) * 45) / 100; // napadi domacin pp
  const O95 = S75 * T68; // napadi gost
  const R95 = (Number(O95) * 55) / 100; // napadi gost drugi poluvreme
  const T95 = (Number(O95) * 45) / 100; // gost napadi pp
  const F96 = T75 * S68; //opasni napadi domacin
  const D96 = (Number(F96) * 55) / 100; // opasni napadi drugo poluvreme domacin
  const B96 = (Number(F96) * 45) / 100; // opasni napadi pp domacin
  const O96 = T75 * T68; // opasni napadi gost
  const R96 = (Number(O96) * 55) / 100; // opasni napadi drugo poluvreme gost
  const T96 = (Number(O96) * 45) / 100; //opasni napadi prvo poluvreme gost

  const B35 = isNaN(B81) ? "-" : Math.round(B81);
  const D35 = isNaN(D81) ? "-" : Math.round(D81);
  const F35 = isNaN(F81) ? "-" : Math.round(F81);
  const O35 = isNaN(O81) ? "-" : Math.round(O81);
  const R35 = isNaN(R81) ? "-" : Math.round(R81);
  const T35 = isNaN(T81) ? "-" : Math.round(T81);

  const B36 = isNaN(B82) ? "-" : Math.round(B82);
  const D36 = isNaN(D82) ? "-" : Math.round(D82);
  const F36 = isNaN(F82) ? "-" : Math.round(F82);
  const O36 = isNaN(O82) ? "-" : Math.round(O82);
  const R36 = isNaN(R82) ? "-" : Math.round(R82);
  const T36 = isNaN(T82) ? "-" : Math.round(T82);

  const B37 = isNaN(B83) ? "-" : Math.round(B83);
  const D37 = isNaN(D83) ? "-" : Math.round(D83);
  const F37 = isNaN(F83) ? "-" : Math.round(F83);
  const O37 = isNaN(O83) ? "-" : Math.round(O83);
  const R37 = isNaN(R83) ? "-" : Math.round(R83);
  const T37 = isNaN(T83) ? "-" : Math.round(T83);

  const B38 = isNaN(B84) ? "-" : Math.round(B84);
  const D38 = isNaN(D84) ? "-" : Math.round(D84);
  const F38 = isNaN(F84) ? "-" : Math.round(F84);
  const O38 = isNaN(O84) ? "-" : Math.round(O84);
  const R38 = isNaN(R84) ? "-" : Math.round(R84);
  const T38 = isNaN(T84) ? "-" : Math.round(T84);

  const B39 = isNaN(B85) ? "-" : Math.round(B85);
  const D39 = isNaN(D85) ? "-" : Math.round(D85);
  const F39 = isNaN(F85) ? "-" : Math.round(F85);
  const O39 = isNaN(O85) ? "-" : Math.round(O85);
  const R39 = isNaN(R85) ? "-" : Math.round(R85);
  const T39 = isNaN(T85) ? "-" : Math.round(T85);

  const B40 = isNaN(B86) ? "-" : Math.round(B86);
  const D40 = isNaN(D86) ? "-" : Math.round(D86);
  const F40 = isNaN(F86) ? "-" : Math.round(F86);
  const O40 = isNaN(O86) ? "-" : Math.round(O86);
  const R40 = isNaN(R86) ? "-" : Math.round(R86);
  const T40 = isNaN(T86) ? "-" : Math.round(T86);

  const B41 = isNaN(B87) ? "-" : Math.round(B87);
  const D41 = isNaN(D87) ? "-" : Math.round(D87);
  const F41 = isNaN(F87) ? "-" : Math.round(F87);
  const O41 = isNaN(O87) ? "-" : Math.round(O87);
  const R41 = isNaN(R87) ? "-" : Math.round(R87);
  const T41 = isNaN(T87) ? "-" : Math.round(T87);

  const B42 = isNaN(B88) ? "-" : Math.round(B88);
  const D42 = isNaN(D88) ? "-" : Math.round(D88);
  const F42 = isNaN(F88) ? "-" : Math.round(F88);
  const O42 = isNaN(O88) ? "-" : Math.round(O88);
  const R42 = isNaN(R88) ? "-" : Math.round(R88);
  const T42 = isNaN(T88) ? "-" : Math.round(T88);

  const B43 = isNaN(B89) ? "-" : Math.round(B89);
  const D43 = isNaN(D89) ? "-" : Math.round(D89);
  const F43 = isNaN(F89) ? "-" : Math.round(F89);
  const O43 = isNaN(O89) ? "-" : Math.round(O89);
  const R43 = isNaN(R89) ? "-" : Math.round(R89);
  const T43 = isNaN(T89) ? "-" : Math.round(T89);

  const B44 = isNaN(B90) ? "-" : Math.round(B90);
  const D44 = isNaN(D90) ? "-" : Math.round(D90);
  const F44 = isNaN(F90) ? "-" : Math.round(F90);
  const O44 = isNaN(O90) ? "-" : Math.round(O90);
  const R44 = isNaN(R90) ? "-" : Math.round(R90);
  const T44 = isNaN(T90) ? "-" : Math.round(T90);

  const B45 = isNaN(B91) ? "-" : Math.round(B91);
  const D45 = isNaN(D91) ? "-" : Math.round(D91);
  const F45 = isNaN(F91) ? "-" : Math.round(F91);
  const O45 = isNaN(O91) ? "-" : Math.round(O91);
  const R45 = isNaN(R91) ? "-" : Math.round(R91);
  const T45 = isNaN(T91) ? "-" : Math.round(T91);

  const B46 = isNaN(B92) ? "-" : Math.round(B92);
  const D46 = isNaN(D92) ? "-" : Math.round(D92);
  const F46 = isNaN(F92) ? "-" : Math.round(F92);
  const O46 = isNaN(O92) ? "-" : Math.round(O92);
  const R46 = isNaN(R92) ? "-" : Math.round(R92);
  const T46 = isNaN(T92) ? "-" : Math.round(T92);

  const B47 = isNaN(B93) ? "-" : Math.round(B93);
  const D47 = isNaN(D93) ? "-" : Math.round(D93);
  const F47 = isNaN(F93) ? "-" : Math.round(F93);
  const O47 = isNaN(O93) ? "-" : Math.round(O93);
  const R47 = isNaN(R93) ? "-" : Math.round(R93);
  const T47 = isNaN(T93) ? "-" : Math.round(T93);

  const B48 = isNaN(B94) ? "-" : Math.round(B94);
  const D48 = isNaN(D94) ? "-" : Math.round(D94);
  const F48 = isNaN(F94) ? "-" : Math.round(F94);
  const O48 = isNaN(O94) ? "-" : Math.round(O94);
  const R48 = isNaN(R94) ? "-" : Math.round(R94);
  const T48 = isNaN(T94) ? "-" : Math.round(T94);

  const B49 = isNaN(B95) ? "-" : Math.round(B95);
  const D49 = isNaN(D95) ? "-" : Math.round(D95);
  const F49 = isNaN(F95) ? "-" : Math.round(F95);
  const O49 = isNaN(O95) ? "-" : Math.round(O95);
  const R49 = isNaN(R95) ? "-" : Math.round(R95);
  const T49 = isNaN(T95) ? "-" : Math.round(T95);

  const B50 = isNaN(B96) ? "-" : Math.round(B96);
  const D50 = isNaN(D96) ? "-" : Math.round(D96);
  const F50 = isNaN(F96) ? "-" : Math.round(F96);
  const O50 = isNaN(O96) ? "-" : Math.round(O96);
  const R50 = isNaN(R96) ? "-" : Math.round(R96);
  const T50 = isNaN(T96) ? "-" : Math.round(T96);

  const I35 = isNaN(B35 + T35) ? "-" : Math.round(B35 + T35);
  const N35 = isNaN(F35 + O35) ? "-" : Math.round(F35 + O35);
  const I36 = isNaN(B36 + T36) ? "-" : Math.round(B36 + T36);
  const N36 = isNaN(F36 + O36) ? "-" : Math.round(F36 + O36);
  const I37 = isNaN(B37 + T37) ? "-" : Math.round(B37 + T37);
  const N37 = isNaN(F37 + O37) ? "-" : Math.round(F37 + O37);
  const I38 = isNaN(B38 + T38) ? "-" : Math.round(B38 + T38);
  const N38 = isNaN(F38 + O38) ? "-" : Math.round(F38 + O38);
  const I39 = isNaN(B39 + T39) ? "-" : Math.round(B39 + T39);
  const N39 = isNaN(F39 + O39) ? "-" : Math.round(F39 + O39);
  const I40 = isNaN(B40 + T40) ? "-" : Math.round(B40 + T40);
  const N40 = isNaN(F40 + O40) ? "-" : Math.round(F40 + O40);
  const I41 = isNaN(B41 + T41) ? "-" : Math.round(B41 + T41);
  const N41 = isNaN(F41 + O41) ? "-" : Math.round(F41 + O41);
  const I42 = isNaN(B42 + T42) ? "-" : Math.round(B42 + T42);
  const N42 = isNaN(F42 + O42) ? "-" : Math.round(F42 + O42);
  const I43 = isNaN(B43 + T43) ? "-" : Math.round(B43 + T43);
  const N43 = isNaN(F43 + O43) ? "-" : Math.round(F43 + O43);
  const I44 = isNaN(B44 + T44) ? "-" : Math.round(B44 + T44);
  const N44 = isNaN(F44 + O44) ? "-" : Math.round(F44 + O44);
  const I45 = isNaN(B45 + T45) ? "-" : Math.round(B45 + T45);
  const N45 = isNaN(F45 + O45) ? "-" : Math.round(F45 + O45);
  const I46 = isNaN(B46 + T46) ? "-" : Math.round(B46 + T46);
  const N46 = isNaN(F46 + O46) ? "-" : Math.round(F46 + O46);
  const I47 = isNaN(B47 + T47) ? "-" : Math.round(B47 + T47);
  const N47 = isNaN(F47 + O47) ? "-" : Math.round(F47 + O47);
  const I48 = isNaN(B48 + T48) ? "-" : Math.round(B48 + T48);
  const N48 = isNaN(F48 + O48) ? "-" : Math.round(F48 + O48);
  const I49 = isNaN(B49 + T49) ? "-" : Math.round(B49 + T49);
  const N49 = isNaN(F49 + O49) ? "-" : Math.round(F49 + O49);
  const I50 = isNaN(B50 + T50) ? "-" : Math.round(B50 + T50);
  const N50 = isNaN(F50 + O50) ? "-" : Math.round(F50 + O50);

  return (
    <div className={classes.container}>
      <Grupa1
        imgText="PROJEKCIJA UTAKMICE"
        imgBackgroundColorDark="#042C83"
        imgBackgroundColorLight="#0D2868"
        imgFontColor="#FFFFFF"
        label1="VREME POČETKA"
        w
        label2="ZEMLJA"
        label3="DOMAĆIN"
        label4="TAKMIČENJE - LIGA"
        label5="GOST"
      />
      <Grupa28
        imgBackgroundColorDark="#042C83"
        imgBackgroundColorLight="#0D2868"
        imgFontColor="#FFFFFF"
        label1={isNaN(S66) ? "-" : Number(S66).toFixed(2)}
        label2={isNaN(T66) ? "-" : Number(T66).toFixed(2)}
        label3={isNaN(U66) ? "-" : Number(U66).toFixed(2)}
        label4={isNaN(V66) ? "-" : Number(V66).toFixed(2)}
        label5={isNaN(SODG) ? "-" : Number(SODG).toFixed(2)}
        label6={isNaN(SNDG) ? "-" : Number(SNDG).toFixed(2)}
        label7={isNaN(SNDSOG) ? "-" : Number(SNDSOG).toFixed(2)}
        label8={isNaN(SODSNG) ? "-" : Number(SODSNG).toFixed(2)}
        calculatorType={`Projekcija utakmice`}
      />

      <div style={{ width: "110.6%", marginRight: "-154px" }}>
        <div className={styles.row}>
          {tips.map((tip, index) => (
            <div className={styles.tip} key={index}>
              <p style={{ color: "white" }}>{tip}</p>
            </div>
          ))}
        </div>
        <div
          className={styles.row}
          style={{
            height: "50px",
            marginTop: "5px",
            marginBottom: "5px",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          {odds.map((odd, index) => (
            <input
              onChange={(e) => handleChangeOdds(e, index)}
              className={styles.input}
              key={index}
              value={odd}
            ></input>
          ))}
        </div>
        <div className={styles.row}>
          <div
            style={{ backgroundColor: "#E1F8FC", borderRadius: "10px" }}
            className={styles.column1}
          >
            <p style={{ color: "black" }}>D-1.POL</p>
          </div>
          <div
            style={{ backgroundColor: "#00B0F0", borderRadius: "10px" }}
            className={styles.column1}
          >
            <p style={{ color: "white" }}>D-2.POL</p>
          </div>
          <div
            style={{ backgroundColor: "#DE63DF", borderRadius: "10px" }}
            className={styles.column1}
          >
            <p style={{ color: "black" }}>D.90 MINUTA</p>
          </div>
          <div
            style={{
              backgroundColor: "#DE63DF",
              width: "153%",
              borderRadius: "10px",
            }}
            className={styles.column1}
          >
            <p style={{ color: "black" }}>UKUPNA OBA</p>
          </div>
          <div
            style={{ backgroundColor: "#DE63DF", borderRadius: "10px" }}
            className={styles.column1}
          >
            <p style={{ color: "black" }}>G.90 MINUTA</p>
          </div>
          <div
            style={{ backgroundColor: "#00B0F0", borderRadius: "10px" }}
            className={styles.column1}
          >
            <p style={{ color: "white" }}>G-2.POL</p>
          </div>
          <div
            style={{ backgroundColor: "#E1F8FC", borderRadius: "10px" }}
            className={styles.column1}
          >
            <p style={{ color: "black" }}>G-1.POL</p>
          </div>
        </div>
        <div className={styles.row}>
          <div
            style={{ backgroundColor: "#E1F8FC" }}
            className={styles.column1}
          >
            <p style={{ color: "black" }}>{B32Value}</p>
          </div>
          <div
            style={{ backgroundColor: "#00B0F0" }}
            className={styles.column1}
          >
            <p style={{ color: "white" }}>{D32Value}</p>
          </div>
          <div
            style={{ backgroundColor: "#DE63DF" }}
            className={styles.column1}
          >
            <p style={{ color: "black" }}>{F32Value}</p>
          </div>
          <div
            style={{
              backgroundColor: "#F96DB9",
              width: "153%",
              margin: "0",
              padding: "0",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "start",
            }}
            className={styles.column1}
          >
            <div
              style={{
                backgroundColor: "darkblue",
                height: "24px",
                width: "100%",
              }}
            >
              <p style={{ marginTop: "0", color: "white" }}>
                OCEKIVANI BROJ GOLOVA
              </p>
            </div>
            <div
              onChange={(e) => setOcekivanBrGolova(e.target.value)}
              style={{
                backgroundColor: "#F96DB9",
                height: "22px",
                width: "100%p",
                textAlign: "center",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <p style={{ color: "black" }}>{I33}</p>
            </div>
          </div>
          <div
            style={{ backgroundColor: "#DE63DF" }}
            className={styles.column1}
          >
            <p style={{ color: "black" }}>{B67Value}</p>
          </div>
          <div
            style={{ backgroundColor: "#00B0F0" }}
            className={styles.column1}
          >
            <p style={{ color: "white" }}>{B68Value}</p>
          </div>
          <div
            style={{ backgroundColor: "#E1F8FC" }}
            className={styles.column1}
          >
            <p style={{ color: "black" }}>{B69Value}</p>
          </div>
        </div>
        <div className={styles.row}>
          <div style={{ backgroundColor: "blue" }} className={styles.column1}>
            <p style={{ color: "white" }}>{F80Value}</p>
          </div>
          <div
            style={{
              backgroundColor: "darkblue",
              width: "50%",
              margin: "0",
              padding: "0",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "start",
            }}
            className={styles.column1}
          >
            <div
              style={{
                backgroundColor: "blue",
                height: "48px",
                width: "15%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <p style={{ color: "white" }}>SVE PP.</p>
            </div>
            <div
              style={{
                backgroundColor: "darkblue",
                height: "48px",
                width: "70%",
                textAlign: "center",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <p style={{ color: "white" }}>POSED LOPTE</p>
            </div>
            <div
              style={{
                backgroundColor: "blue",
                height: "48px",
                width: "15%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <p style={{ color: "white" }}>SVE KRAJ</p>
            </div>
          </div>
          <div style={{ backgroundColor: "blue" }} className={styles.column1}>
            <p style={{ color: "white" }}>{O80Value}</p>
          </div>
        </div>
        <div className={styles.row}>
          <div
            style={{ backgroundColor: "#E1F8FC" }}
            className={styles.column1}
          >
            <p style={{ color: "black" }}>{B35}</p>
          </div>
          <div
            style={{ backgroundColor: "#00B0F0" }}
            className={styles.column1}
          >
            <p style={{ color: "white" }}>{D35}</p>
          </div>
          <div
            style={{ backgroundColor: "#DE63DF" }}
            className={styles.column1}
          >
            <p style={{ color: "black" }}>{F35}</p>
          </div>
          <div
            style={{
              backgroundColor: "darkblue",
              width: "153%",
              margin: "0",
              padding: "0",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "start",
            }}
            className={styles.column1}
          >
            <div
              style={{
                backgroundColor: "darkblue",
                height: "48px",
                width: "15%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <p style={{ color: "white" }}>{I35}</p>
            </div>
            <div
              style={{
                backgroundColor: "darkblue",
                height: "48px",
                width: "70%",
                textAlign: "center",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <p style={{ color: "white" }}>UKUPNO ŠUTEVA KA GOLU</p>
            </div>
            <div
              style={{
                backgroundColor: "darkblue",
                height: "48px",
                width: "15%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <p style={{ color: "white" }}>{N35}</p>
            </div>
          </div>
          <div
            style={{ backgroundColor: "#DE63DF" }}
            className={styles.column1}
          >
            <p style={{ color: "black" }}>{O35}</p>
          </div>
          <div
            style={{ backgroundColor: "#00B0F0" }}
            className={styles.column1}
          >
            <p style={{ color: "white" }}>{R35}</p>
          </div>
          <div
            style={{ backgroundColor: "#E1F8FC" }}
            className={styles.column1}
          >
            <p style={{ color: "black" }}>{T35}</p>
          </div>
        </div>
        <div className={styles.row}>
          <div
            style={{ backgroundColor: "#E1F8FC" }}
            className={styles.column1}
          >
            <p style={{ color: "black" }}>{B36}</p>
          </div>
          <div
            style={{ backgroundColor: "#00B0F0" }}
            className={styles.column1}
          >
            <p style={{ color: "white" }}>{D36}</p>
          </div>
          <div
            style={{ backgroundColor: "#DE63DF" }}
            className={styles.column1}
          >
            <p style={{ color: "black" }}>{F36}</p>
          </div>
          <div
            style={{
              backgroundColor: "darkblue",
              width: "153%",
              margin: "0",
              padding: "0",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "start",
            }}
            className={styles.column1}
          >
            <div
              style={{
                backgroundColor: "darkblue",
                height: "48px",
                width: "15%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <p style={{ color: "white" }}>{I36}</p>
            </div>
            <div
              style={{
                backgroundColor: "darkblue",
                height: "48px",
                width: "70%",
                textAlign: "center",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <p style={{ color: "white" }}>UKUPNO ŠUTEVA U OKVIR GOLA</p>
            </div>
            <div
              style={{
                backgroundColor: "darkblue",
                height: "48px",
                width: "15%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <p style={{ color: "white" }}>{N36}</p>
            </div>
          </div>
          <div
            style={{ backgroundColor: "#DE63DF" }}
            className={styles.column1}
          >
            <p style={{ color: "black" }}>{O36}</p>
          </div>
          <div
            style={{ backgroundColor: "#00B0F0" }}
            className={styles.column1}
          >
            <p style={{ color: "white" }}>{R36}</p>
          </div>
          <div
            style={{ backgroundColor: "#E1F8FC" }}
            className={styles.column1}
          >
            <p style={{ color: "black" }}>{T36}</p>
          </div>
        </div>

        <div className={styles.row}>
          <div
            style={{ backgroundColor: "#E1F8FC" }}
            className={styles.column1}
          >
            <p style={{ color: "black" }}>{B37}</p>
          </div>
          <div
            style={{ backgroundColor: "#00B0F0" }}
            className={styles.column1}
          >
            <p style={{ color: "white" }}>{D37}</p>
          </div>
          <div
            style={{ backgroundColor: "#DE63DF" }}
            className={styles.column1}
          >
            <p style={{ color: "black" }}>{F37}</p>
          </div>
          <div
            style={{
              backgroundColor: "darkblue",
              width: "153%",
              margin: "0",
              padding: "0",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "start",
            }}
            className={styles.column1}
          >
            <div
              style={{
                backgroundColor: "darkblue",
                height: "48px",
                width: "15%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <p style={{ color: "white" }}>{I37}</p>
            </div>
            <div
              style={{
                backgroundColor: "darkblue",
                height: "48px",
                width: "70%",
                textAlign: "center",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <p style={{ color: "white" }}>ŠUTEVI VAN OKVIRA GOLA</p>
            </div>
            <div
              style={{
                backgroundColor: "darkblue",
                height: "48px",
                width: "15%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <p style={{ color: "white" }}>{N37}</p>
            </div>
          </div>
          <div
            style={{ backgroundColor: "#DE63DF" }}
            className={styles.column1}
          >
            <p style={{ color: "black" }}>{O37}</p>
          </div>
          <div
            style={{ backgroundColor: "#00B0F0" }}
            className={styles.column1}
          >
            <p style={{ color: "white" }}>{R37}</p>
          </div>
          <div
            style={{ backgroundColor: "#E1F8FC" }}
            className={styles.column1}
          >
            <p style={{ color: "black" }}>{T37}</p>
          </div>
        </div>
        <div className={styles.row}>
          <div
            style={{ backgroundColor: "#E1F8FC" }}
            className={styles.column1}
          >
            <p style={{ color: "black" }}>{B38}</p>
          </div>
          <div
            style={{ backgroundColor: "#00B0F0" }}
            className={styles.column1}
          >
            <p style={{ color: "white" }}>{D38}</p>
          </div>
          <div
            style={{ backgroundColor: "#DE63DF" }}
            className={styles.column1}
          >
            <p style={{ color: "black" }}>{F38}</p>
          </div>
          <div
            style={{
              backgroundColor: "darkblue",
              width: "153%",
              margin: "0",
              padding: "0",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "start",
            }}
            className={styles.column1}
          >
            <div
              style={{
                backgroundColor: "darkblue",
                height: "48px",
                width: "15%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <p style={{ color: "white" }}>{I38}</p>
            </div>
            <div
              style={{
                backgroundColor: "darkblue",
                height: "48px",
                width: "70%",
                textAlign: "center",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <p style={{ color: "white" }}>BLOKIRANI ŠUTEVI</p>
            </div>
            <div
              style={{
                backgroundColor: "darkblue",
                height: "48px",
                width: "15%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <p style={{ color: "white" }}>{N38}</p>
            </div>
          </div>
          <div
            style={{ backgroundColor: "#DE63DF" }}
            className={styles.column1}
          >
            <p style={{ color: "black" }}>{O38}</p>
          </div>
          <div
            style={{ backgroundColor: "#00B0F0" }}
            className={styles.column1}
          >
            <p style={{ color: "white" }}>{R38}</p>
          </div>
          <div
            style={{ backgroundColor: "#E1F8FC" }}
            className={styles.column1}
          >
            <p style={{ color: "black" }}>{T38}</p>
          </div>
        </div>
        <div className={styles.row}>
          <div
            style={{ backgroundColor: "#E1F8FC" }}
            className={styles.column1}
          >
            <p style={{ color: "black" }}>{B39}</p>
          </div>
          <div
            style={{ backgroundColor: "#00B0F0" }}
            className={styles.column1}
          >
            <p style={{ color: "white" }}>{D39}</p>
          </div>
          <div
            style={{ backgroundColor: "#DE63DF" }}
            className={styles.column1}
          >
            <p style={{ color: "black" }}>{F39}</p>
          </div>
          <div
            style={{
              backgroundColor: "darkblue",
              width: "153%",
              margin: "0",
              padding: "0",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "start",
            }}
            className={styles.column1}
          >
            <div
              style={{
                backgroundColor: "darkblue",
                height: "48px",
                width: "15%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <p style={{ color: "white" }}>{I39}</p>
            </div>
            <div
              style={{
                backgroundColor: "darkblue",
                height: "48px",
                width: "70%",
                textAlign: "center",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <p style={{ color: "white" }}>SLOBODNI UDARCI</p>
            </div>
            <div
              style={{
                backgroundColor: "darkblue",
                height: "48px",
                width: "15%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <p style={{ color: "white" }}>{N39}</p>
            </div>
          </div>
          <div
            style={{ backgroundColor: "#DE63DF" }}
            className={styles.column1}
          >
            <p style={{ color: "black" }}>{O39}</p>
          </div>
          <div
            style={{ backgroundColor: "#00B0F0" }}
            className={styles.column1}
          >
            <p style={{ color: "white" }}>{R39}</p>
          </div>
          <div
            style={{ backgroundColor: "#E1F8FC" }}
            className={styles.column1}
          >
            <p style={{ color: "black" }}>{T39}</p>
          </div>
        </div>
        <div className={styles.row}>
          <div
            style={{ backgroundColor: "#E1F8FC" }}
            className={styles.column1}
          >
            <p style={{ color: "black" }}>{B40}</p>
          </div>
          <div
            style={{ backgroundColor: "#00B0F0" }}
            className={styles.column1}
          >
            <p style={{ color: "white" }}>{D40}</p>
          </div>
          <div
            style={{ backgroundColor: "#DE63DF" }}
            className={styles.column1}
          >
            <p style={{ color: "black" }}>{F40}</p>
          </div>
          <div
            style={{
              backgroundColor: "darkblue",
              width: "153%",
              margin: "0",
              padding: "0",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "start",
            }}
            className={styles.column1}
          >
            <div
              style={{
                backgroundColor: "darkblue",
                height: "48px",
                width: "15%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <p style={{ color: "white" }}>{I40}</p>
            </div>
            <div
              style={{
                backgroundColor: "darkblue",
                height: "48px",
                width: "70%",
                textAlign: "center",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <p style={{ color: "white" }}>KORNERI</p>
            </div>
            <div
              style={{
                backgroundColor: "darkblue",
                height: "48px",
                width: "15%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <p style={{ color: "white" }}>{N40}</p>
            </div>
          </div>
          <div
            style={{ backgroundColor: "#DE63DF" }}
            className={styles.column1}
          >
            <p style={{ color: "black" }}>{O40}</p>
          </div>
          <div
            style={{ backgroundColor: "#00B0F0" }}
            className={styles.column1}
          >
            <p style={{ color: "white" }}>{R40}</p>
          </div>
          <div
            style={{ backgroundColor: "#E1F8FC" }}
            className={styles.column1}
          >
            <p style={{ color: "black" }}>{T40}</p>
          </div>
        </div>
        <div className={styles.row}>
          <div
            style={{ backgroundColor: "#E1F8FC" }}
            className={styles.column1}
          >
            <p style={{ color: "black" }}>{B41}</p>
          </div>
          <div
            style={{ backgroundColor: "#00B0F0" }}
            className={styles.column1}
          >
            <p style={{ color: "white" }}>{D41}</p>
          </div>
          <div
            style={{ backgroundColor: "#DE63DF" }}
            className={styles.column1}
          >
            <p style={{ color: "black" }}>{F41}</p>
          </div>
          <div
            style={{
              backgroundColor: "darkblue",
              width: "153%",
              margin: "0",
              padding: "0",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "start",
            }}
            className={styles.column1}
          >
            <div
              style={{
                backgroundColor: "darkblue",
                height: "48px",
                width: "15%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <p style={{ color: "white" }}>{I41}</p>
            </div>
            <div
              style={{
                backgroundColor: "darkblue",
                height: "48px",
                width: "70%",
                textAlign: "center",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <p style={{ color: "white" }}>OFSAJDI</p>
            </div>
            <div
              style={{
                backgroundColor: "darkblue",
                height: "48px",
                width: "15%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <p style={{ color: "white" }}>{N41}</p>
            </div>
          </div>
          <div
            style={{ backgroundColor: "#DE63DF" }}
            className={styles.column1}
          >
            <p style={{ color: "black" }}>{O41}</p>
          </div>
          <div
            style={{ backgroundColor: "#00B0F0" }}
            className={styles.column1}
          >
            <p style={{ color: "white" }}>{R41}</p>
          </div>
          <div
            style={{ backgroundColor: "#E1F8FC" }}
            className={styles.column1}
          >
            <p style={{ color: "black" }}>{T41}</p>
          </div>
        </div>
        <div className={styles.row}>
          <div
            style={{ backgroundColor: "#E1F8FC" }}
            className={styles.column1}
          >
            <p style={{ color: "black" }}>{B42}</p>
          </div>
          <div
            style={{ backgroundColor: "#00B0F0" }}
            className={styles.column1}
          >
            <p style={{ color: "white" }}>{D42}</p>
          </div>
          <div
            style={{ backgroundColor: "#DE63DF" }}
            className={styles.column1}
          >
            <p style={{ color: "black" }}>{F42}</p>
          </div>
          <div
            style={{
              backgroundColor: "darkblue",
              width: "153%",
              margin: "0",
              padding: "0",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "start",
            }}
            className={styles.column1}
          >
            <div
              style={{
                backgroundColor: "darkblue",
                height: "48px",
                width: "15%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <p style={{ color: "white" }}>{I42}</p>
            </div>
            <div
              style={{
                backgroundColor: "darkblue",
                height: "48px",
                width: "70%",
                textAlign: "center",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <p style={{ color: "white" }}>CENTARŠUTEVI</p>
            </div>
            <div
              style={{
                backgroundColor: "darkblue",
                height: "48px",
                width: "15%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <p style={{ color: "white" }}>{N42}</p>
            </div>
          </div>
          <div
            style={{ backgroundColor: "#DE63DF" }}
            className={styles.column1}
          >
            <p style={{ color: "black" }}>{O42}</p>
          </div>
          <div
            style={{ backgroundColor: "#00B0F0" }}
            className={styles.column1}
          >
            <p style={{ color: "white" }}>{R42}</p>
          </div>
          <div
            style={{ backgroundColor: "#E1F8FC" }}
            className={styles.column1}
          >
            <p style={{ color: "black" }}>{T42}</p>
          </div>
        </div>
        <div className={styles.row}>
          <div
            style={{ backgroundColor: "#E1F8FC" }}
            className={styles.column1}
          >
            <p style={{ color: "black" }}>{B43}</p>
          </div>
          <div
            style={{ backgroundColor: "#00B0F0" }}
            className={styles.column1}
          >
            <p style={{ color: "white" }}>{D43}</p>
          </div>
          <div
            style={{ backgroundColor: "#DE63DF" }}
            className={styles.column1}
          >
            <p style={{ color: "black" }}>{F43}</p>
          </div>
          <div
            style={{
              backgroundColor: "darkblue",
              width: "153%",
              margin: "0",
              padding: "0",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "start",
            }}
            className={styles.column1}
          >
            <div
              style={{
                backgroundColor: "darkblue",
                height: "48px",
                width: "15%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <p style={{ color: "white" }}>{I43}</p>
            </div>
            <div
              style={{
                backgroundColor: "darkblue",
                height: "48px",
                width: "70%",
                textAlign: "center",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <p style={{ color: "white" }}>ODBRANE GOLMANA</p>
            </div>
            <div
              style={{
                backgroundColor: "darkblue",
                height: "48px",
                width: "15%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <p style={{ color: "white" }}>{N43}</p>
            </div>
          </div>
          <div
            style={{ backgroundColor: "#DE63DF" }}
            className={styles.column1}
          >
            <p style={{ color: "black" }}>{O43}</p>
          </div>
          <div
            style={{ backgroundColor: "#00B0F0" }}
            className={styles.column1}
          >
            <p style={{ color: "white" }}>{R43}</p>
          </div>
          <div
            style={{ backgroundColor: "#E1F8FC" }}
            className={styles.column1}
          >
            <p style={{ color: "black" }}>{T43}</p>
          </div>
        </div>
        <div className={styles.row}>
          <div
            style={{ backgroundColor: "#E1F8FC" }}
            className={styles.column1}
          >
            <p style={{ color: "black" }}>{B44}</p>
          </div>
          <div
            style={{ backgroundColor: "#00B0F0" }}
            className={styles.column1}
          >
            <p style={{ color: "white" }}>{D44}</p>
          </div>
          <div
            style={{ backgroundColor: "#DE63DF" }}
            className={styles.column1}
          >
            <p style={{ color: "black" }}>{F44}</p>
          </div>
          <div
            style={{
              backgroundColor: "darkblue",
              width: "153%",
              margin: "0",
              padding: "0",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "start",
            }}
            className={styles.column1}
          >
            <div
              style={{
                backgroundColor: "darkblue",
                height: "48px",
                width: "15%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <p style={{ color: "white" }}>{I44}</p>
            </div>
            <div
              style={{
                backgroundColor: "darkblue",
                height: "48px",
                width: "70%",
                textAlign: "center",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <p style={{ color: "white" }}>FAULOVI</p>
            </div>
            <div
              style={{
                backgroundColor: "darkblue",
                height: "48px",
                width: "15%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <p style={{ color: "white" }}>{N44}</p>
            </div>
          </div>
          <div
            style={{ backgroundColor: "#DE63DF" }}
            className={styles.column1}
          >
            <p style={{ color: "black" }}>{O44}</p>
          </div>
          <div
            style={{ backgroundColor: "#00B0F0" }}
            className={styles.column1}
          >
            <p style={{ color: "white" }}>{R44}</p>
          </div>
          <div
            style={{ backgroundColor: "#E1F8FC" }}
            className={styles.column1}
          >
            <p style={{ color: "black" }}>{T44}</p>
          </div>
        </div>
        <div className={styles.row}>
          <div
            style={{ backgroundColor: "#E1F8FC" }}
            className={styles.column1}
          >
            <p style={{ color: "black" }}>{B45}</p>
          </div>
          <div
            style={{ backgroundColor: "#00B0F0" }}
            className={styles.column1}
          >
            <p style={{ color: "white" }}>{D45}</p>
          </div>
          <div
            style={{ backgroundColor: "#DE63DF" }}
            className={styles.column1}
          >
            <p style={{ color: "black" }}>{F45}</p>
          </div>
          <div
            style={{
              backgroundColor: "darkblue",
              width: "153%",
              margin: "0",
              padding: "0",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "start",
            }}
            className={styles.column1}
          >
            <div
              style={{
                backgroundColor: "darkblue",
                height: "48px",
                width: "15%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <p style={{ color: "white" }}>{I45}</p>
            </div>
            <div
              style={{
                backgroundColor: "darkblue",
                height: "48px",
                width: "70%",
                textAlign: "center",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <p style={{ color: "white" }}>ZUTI KARTONI</p>
            </div>
            <div
              style={{
                backgroundColor: "darkblue",
                height: "48px",
                width: "15%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <p style={{ color: "white" }}>{N45}</p>
            </div>
          </div>
          <div
            style={{ backgroundColor: "#DE63DF" }}
            className={styles.column1}
          >
            <p style={{ color: "black" }}>{O45}</p>
          </div>
          <div
            style={{ backgroundColor: "#00B0F0" }}
            className={styles.column1}
          >
            <p style={{ color: "white" }}>{R45}</p>
          </div>
          <div
            style={{ backgroundColor: "#E1F8FC" }}
            className={styles.column1}
          >
            <p style={{ color: "black" }}>{T45}</p>
          </div>
        </div>
        <div className={styles.row}>
          <div
            style={{ backgroundColor: "#E1F8FC" }}
            className={styles.column1}
          >
            <p style={{ color: "black" }}>{B46}</p>
          </div>
          <div
            style={{ backgroundColor: "#00B0F0" }}
            className={styles.column1}
          >
            <p style={{ color: "white" }}>{D46}</p>
          </div>
          <div
            style={{ backgroundColor: "#DE63DF" }}
            className={styles.column1}
          >
            <p style={{ color: "black" }}>{F46}</p>
          </div>
          <div
            style={{
              backgroundColor: "darkblue",
              width: "153%",
              margin: "0",
              padding: "0",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "start",
            }}
            className={styles.column1}
          >
            <div
              style={{
                backgroundColor: "darkblue",
                height: "48px",
                width: "15%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <p style={{ color: "white" }}>{I46}</p>
            </div>
            <div
              style={{
                backgroundColor: "darkblue",
                height: "48px",
                width: "70%",
                textAlign: "center",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <p style={{ color: "white" }}>UKUPNO DODAVANJA</p>
            </div>
            <div
              style={{
                backgroundColor: "darkblue",
                height: "48px",
                width: "15%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <p style={{ color: "white" }}>{N46}</p>
            </div>
          </div>
          <div
            style={{ backgroundColor: "#DE63DF" }}
            className={styles.column1}
          >
            <p style={{ color: "black" }}>{O46}</p>
          </div>
          <div
            style={{ backgroundColor: "#00B0F0" }}
            className={styles.column1}
          >
            <p style={{ color: "white" }}>{R46}</p>
          </div>
          <div
            style={{ backgroundColor: "#E1F8FC" }}
            className={styles.column1}
          >
            <p style={{ color: "black" }}>{T46}</p>
          </div>
        </div>
        <div className={styles.row}>
          <div
            style={{ backgroundColor: "#E1F8FC" }}
            className={styles.column1}
          >
            <p style={{ color: "black" }}>{B47}</p>
          </div>
          <div
            style={{ backgroundColor: "#00B0F0" }}
            className={styles.column1}
          >
            <p style={{ color: "white" }}>{D47}</p>
          </div>
          <div
            style={{ backgroundColor: "#DE63DF" }}
            className={styles.column1}
          >
            <p style={{ color: "black" }}>{F47}</p>
          </div>
          <div
            style={{
              backgroundColor: "darkblue",
              width: "153%",
              margin: "0",
              padding: "0",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "start",
            }}
            className={styles.column1}
          >
            <div
              style={{
                backgroundColor: "darkblue",
                height: "48px",
                width: "15%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <p style={{ color: "white" }}>{I47}</p>
            </div>
            <div
              style={{
                backgroundColor: "darkblue",
                height: "48px",
                width: "70%",
                textAlign: "center",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <p style={{ color: "white" }}>PRECIZNA DODAVANJA</p>
            </div>
            <div
              style={{
                backgroundColor: "darkblue",
                height: "48px",
                width: "15%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <p style={{ color: "white" }}>{N47}</p>
            </div>
          </div>
          <div
            style={{ backgroundColor: "#DE63DF" }}
            className={styles.column1}
          >
            <p style={{ color: "black" }}>{O47}</p>
          </div>
          <div
            style={{ backgroundColor: "#00B0F0" }}
            className={styles.column1}
          >
            <p style={{ color: "white" }}>{R47}</p>
          </div>
          <div
            style={{ backgroundColor: "#E1F8FC" }}
            className={styles.column1}
          >
            <p style={{ color: "black" }}>{T47}</p>
          </div>
        </div>
        <div className={styles.row}>
          <div
            style={{ backgroundColor: "#E1F8FC" }}
            className={styles.column1}
          >
            <p style={{ color: "black" }}>{B48}</p>
          </div>
          <div
            style={{ backgroundColor: "#00B0F0" }}
            className={styles.column1}
          >
            <p style={{ color: "white" }}>{D48}</p>
          </div>
          <div
            style={{ backgroundColor: "#DE63DF" }}
            className={styles.column1}
          >
            <p style={{ color: "black" }}>{F48}</p>
          </div>
          <div
            style={{
              backgroundColor: "darkblue",
              width: "153%",
              margin: "0",
              padding: "0",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "start",
            }}
            className={styles.column1}
          >
            <div
              style={{
                backgroundColor: "darkblue",
                height: "48px",
                width: "15%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <p style={{ color: "white" }}>{I48}</p>
            </div>
            <div
              style={{
                backgroundColor: "darkblue",
                height: "48px",
                width: "70%",
                textAlign: "center",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <p style={{ color: "white" }}>KLIZECI STARTOVI</p>
            </div>
            <div
              style={{
                backgroundColor: "darkblue",
                height: "48px",
                width: "15%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <p style={{ color: "white" }}>{N48}</p>
            </div>
          </div>
          <div
            style={{ backgroundColor: "#DE63DF" }}
            className={styles.column1}
          >
            <p style={{ color: "black" }}>{O48}</p>
          </div>
          <div
            style={{ backgroundColor: "#00B0F0" }}
            className={styles.column1}
          >
            <p style={{ color: "white" }}>{R48}</p>
          </div>
          <div
            style={{ backgroundColor: "#E1F8FC" }}
            className={styles.column1}
          >
            <p style={{ color: "black" }}>{T48}</p>
          </div>
        </div>
        <div className={styles.row}>
          <div
            style={{ backgroundColor: "#E1F8FC" }}
            className={styles.column1}
          >
            <p style={{ color: "black" }}>{B49}</p>
          </div>
          <div
            style={{ backgroundColor: "#00B0F0" }}
            className={styles.column1}
          >
            <p style={{ color: "white" }}>{D49}</p>
          </div>
          <div
            style={{ backgroundColor: "#DE63DF" }}
            className={styles.column1}
          >
            <p style={{ color: "black" }}>{F49}</p>
          </div>
          <div
            style={{
              backgroundColor: "darkblue",
              width: "153%",
              margin: "0",
              padding: "0",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "start",
            }}
            className={styles.column1}
          >
            <div
              style={{
                backgroundColor: "darkblue",
                height: "48px",
                width: "15%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <p style={{ color: "white" }}>{I49}</p>
            </div>
            <div
              style={{
                backgroundColor: "darkblue",
                height: "48px",
                width: "70%",
                textAlign: "center",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <p style={{ color: "white" }}>NAPADI</p>
            </div>
            <div
              style={{
                backgroundColor: "darkblue",
                height: "48px",
                width: "15%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <p style={{ color: "white" }}>{N49}</p>
            </div>
          </div>
          <div
            style={{ backgroundColor: "#DE63DF" }}
            className={styles.column1}
          >
            <p style={{ color: "black" }}>{O49}</p>
          </div>
          <div
            style={{ backgroundColor: "#00B0F0" }}
            className={styles.column1}
          >
            <p style={{ color: "white" }}>{R49}</p>
          </div>
          <div
            style={{ backgroundColor: "#E1F8FC" }}
            className={styles.column1}
          >
            <p style={{ color: "black" }}>{T49}</p>
          </div>
        </div>
        <div className={styles.row}>
          <div
            style={{ backgroundColor: "#E1F8FC" }}
            className={styles.column1}
          >
            <p style={{ color: "black" }}>{B50}</p>
          </div>
          <div
            style={{ backgroundColor: "#00B0F0" }}
            className={styles.column1}
          >
            <p style={{ color: "white" }}>{D50}</p>
          </div>
          <div
            style={{ backgroundColor: "#DE63DF" }}
            className={styles.column1}
          >
            <p style={{ color: "black" }}>{F50}</p>
          </div>
          <div
            style={{
              backgroundColor: "darkblue",
              width: "153%",
              margin: "0",
              padding: "0",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "start",
            }}
            className={styles.column1}
          >
            <div
              style={{
                backgroundColor: "darkblue",
                height: "48px",
                width: "15%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <p style={{ color: "white" }}>{I50}</p>
            </div>
            <div
              style={{
                backgroundColor: "darkblue",
                height: "48px",
                width: "70%",
                textAlign: "center",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <p style={{ color: "white" }}>OPASNI NAPADI</p>
            </div>
            <div
              style={{
                backgroundColor: "darkblue",
                height: "48px",
                width: "15%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <p style={{ color: "white" }}>{N50}</p>
            </div>
          </div>
          <div
            style={{ backgroundColor: "#DE63DF" }}
            className={styles.column1}
          >
            <p style={{ color: "black" }}>{O50}</p>
          </div>
          <div
            style={{ backgroundColor: "#00B0F0" }}
            className={styles.column1}
          >
            <p style={{ color: "white" }}>{R50}</p>
          </div>
          <div
            style={{ backgroundColor: "#E1F8FC" }}
            className={styles.column1}
          >
            <p style={{ color: "black" }}>{T50}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProjekcijaUtakmice;
