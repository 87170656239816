import { notification } from "antd"
import axios from "axios"
const SERVER_URL = process.env.REACT_APP_SERVER_URL


const downloadApk = async () => {
    try {
      const response = await axios.get(`${SERVER_URL}/get-apk`, {
        responseType: 'blob',
      })

      const blob = new Blob([response.data], {
        type: 'application/vnd.android.package-archive',
      })

      const downloadUrl = window.URL.createObjectURL(blob)

      const link = document.createElement('a')
      link.href = downloadUrl
      const apkVersion = response.headers['apk-version']
      link.setAttribute('download', apkVersion)

      document.body.appendChild(link)
      link.click()
      link.remove()
      window.URL.revokeObjectURL(downloadUrl)
    } catch (error) {
      console.error('Error while downloading APK:', error)
      notification.error({
        message: 'Došlo je do greške prilikom preuzimanja APK fajla',
      })
    }
  }


  export default downloadApk