import React from 'react'
import classes from './BetkoFamilyDesc.module.css'

const BetkoFamilyDesc = () => {
  return (
    <div className={classes.familyPageContainer}>
      <div className={classes.textContainer}>
        <h2>BETTING (R)EVOLUCIJA !</h2>
        <h3>INOVANTNO ! VIRALNO ! DRUGAČIJE !</h3>
        <p className={classes.join}>
          PRIDRUŽI SE NAŠIM PROJEKTIMA ! KREIRAJ "BUDUĆNOST BETTINGA" ! POSTANI
          DEO NJE !
        </p>
        <p className={classes.green}>BETKO TI NUDI, DEO BETKO FAMILIJE BUDI !</p>
        <p>
          SVE ŠTO JE POTREBNO JESTE DA NAS KONTAKTIRATE ! IMAMO PROJEKTE SPREMNE
          ZA VAS ! INOVANTNE, VIRALNE, DRUGAČIJE, JEDINSTVENE I DO SADA NIGDE
          VIĐENE NA TRŽIŠTU SPORTSKOG KLAĐENJA. IMAMO BETTING (R)EVOLUCIJU !
        </p>
        <p>
          UKOLIKO SE BAVITE BETTING-OM U BILO KOJOJ OBLASTI DELOVANJA PRI ISTOM,
          SA NAŠIM IDEJNIM REŠENJIMA, I SA NAŠIM PROJEKTIMA UZ VAŠU PODRŠKU, ONI
          ĆE POSTATI I VAŠI !
        </p>
        <p>
          UKOLIKO STE "IGRAČ", I IMATE AMIBICIJU DA POSTANETE VIŠE OD TOGA SA
          BETKOM SE MOŽE, SVE ŠTO SE POŽELI !
        </p>
        <p>
          UKOLIKO STE SPORTSKA KLADIONICA, I ŽELITE DA POSTANETE LIDER SVIH
          KLADIONICA NA BALKANU, A VREMENOM I NA CELOM SVETU, ŠTA ČEKATE ? IMATE
          LI ZAISTA ŠTA DA IZGUBITE ? MI ZNAMO PUT DO VELIKIH STVARI ! VAŠE JE
          SAMO DA NAS KONTAKTIRATE !
        </p>

        <p className={classes.emailBetko}>betko.podrska@gmail.com</p>
      </div>
    </div>
  )
}

export default BetkoFamilyDesc
