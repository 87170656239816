import { useNavigate } from "react-router-dom";
import classes from "./betKalkulatori.module.css";

const BetKalkulatori = () => {
  const navigate = useNavigate();

  return (
    <div className={classes.container}>
      <ul>
        <li className={classes.liTitle}>KALKULATORI PROCENE</li>
        <li
          onClick={() => navigate("/bet_kalkulatori/fudbal_-_sve_igre")}
          className={classes.liItem}
        >
          FUDBAL - SVE IGRE
        </li>
        {/* <li
          onClick={() =>
            navigate("/bet_kalkulatori/procena_tačnog_broja_golova")
          }
          className={classes.liItem}
        >
          PROCENA TAČNOG BROJA GOLOVA
        </li> */}
        <li
          onClick={() => navigate("/bet_kalkulatori/procena_tačnog_rezultata")}
          className={classes.liItem}
        >
          PROCENA TAČNOG REZULTATA
        </li>
        <li
          onClick={() => navigate("/bet_kalkulatori/live_interaktiv")}
          className={classes.liItem}
        >
          LIVE INTERAKTIV
        </li>
        <li
          onClick={() => navigate("/bet_kalkulatori/košarka")}
          className={classes.liItem}
        >
          KOŠARKA
        </li>
        <li
          onClick={() => navigate("/bet_kalkulatori/košarka_poeni_igrača")}
          className={classes.liItem}
        >
          KOŠARKA POENI IGRAČA
        </li>
        <li
          onClick={() => navigate("/bet_kalkulatori/hokej")}
          className={classes.liItem}
        >
          HOKEJ
        </li>
        <li
          onClick={() => navigate("/bet_kalkulatori/američki_fudbal")}
          className={classes.liItem}
        >
          AMERIČKI FUDBAL
        </li>
        <li
          onClick={() => navigate("/bet_kalkulatori/bejzbol")}
          className={classes.liItem}
        >
          BEJZBOL
        </li>
        <li
          onClick={() => navigate("/bet_kalkulatori/stoni_tenis")}
          className={classes.liItem}
        >
          STONI TENIS
        </li>
        <li
          onClick={() => navigate("/bet_kalkulatori/tenis")}
          className={classes.liItem}
        >
          TENIS
        </li>
        <li
          onClick={() => navigate("/bet_kalkulatori/vaterpolo")}
          className={classes.liItem}
        >
          VATERPOLO
        </li>
        <li
          onClick={() => navigate("/bet_kalkulatori/rukomet")}
          className={classes.liItem}
        >
          RUKOMET
        </li>
        <li
          onClick={() => navigate("/bet_kalkulatori/badminton")}
          className={classes.liItem}
        >
          BADMINTON
        </li>
        <li
          onClick={() => navigate("/bet_kalkulatori/odbojka")}
          className={classes.liItem}
        >
          ODBOJKA
        </li>
        <li
          onClick={() => navigate("/bet_kalkulatori/odbojka_na_pesku")}
          className={classes.liItem}
        >
          ODBOJKA NA PESKU
        </li>
        <li
          onClick={() => navigate("/bet_kalkulatori/futsal")}
          className={classes.liItem}
        >
          FUTSAL
        </li>
        <li
          onClick={() => navigate("/bet_kalkulatori/boks")}
          className={classes.liItem}
        >
          BOKS
        </li>
        <li
          onClick={() => navigate("/bet_kalkulatori/mma")}
          className={classes.liItem}
        >
          MMA
        </li>
        <li
          onClick={() => navigate("/bet_kalkulatori/bilijar")}
          className={classes.liItem}
        >
          BILIJAR
        </li>
        <li
          onClick={() => navigate("/bet_kalkulatori/esports")}
          className={classes.liItem}
        >
          ESPORTS
        </li>
        <li
          onClick={() => navigate("/bet_kalkulatori/pikado")}
          className={classes.liItem}
        >
          PIKADO
        </li>

        <li className={classes.liTitle}>KALKULATORI STATISTIKE</li>
        <li
          onClick={() => navigate("/bet_kalkulatori/projekcija_utakmice")}
          className={classes.liItem}
        >
          PROJEKCIJA UTAKMICE
        </li>
        {/* <li
          onClick={() => navigate("/bet_kalkulatori/tradicija")}
          className={classes.liItem}
        >
          TRADICIJA
        </li> */}
        {/* <li
          onClick={() => navigate("/bet_kalkulatori/poređenje_nizova")}
          className={classes.liItem}
        >
          POREĐENJE NIZOVA
        </li> */}
        <li
          onClick={() => navigate("/bet_kalkulatori/statistika_sudija")}
          className={classes.liItem}
        >
          STATISTIKA SUDIJA
        </li>
        <li
          onClick={() => navigate("/bet_kalkulatori/poređenje_timova_-_fudbal")}
          className={classes.liItem}
        >
          POREĐENJE TIMOVA - FUDBAL
        </li>
        <li
          onClick={() =>
            navigate("/bet_kalkulatori/poređenje_golmana_-_fudbal")
          }
          className={classes.liItem}
        >
          POREĐENJE GOLMANA - FUDBAL
        </li>
        <li
          onClick={() => navigate("/bet_kalkulatori/poređenje_igrača_-_fudbal")}
          className={classes.liItem}
        >
          POREĐENJE IGRAČA - FUDBAL
        </li>
        <li
          onClick={() =>
            navigate("/bet_kalkulatori/poređenje_igrača_-_košarka")
          }
          className={classes.liItem}
        >
          POREĐENJE IGRAČA - KOŠARKA
        </li>
        <li className={classes.liTitle}>KALKULATORI KVOTA</li>
        <li
          onClick={() => navigate("/bet_kalkulatori/nivelacija_kvota")}
          className={classes.liItem}
        >
          NIVELACIJA KVOTA
        </li>
        <li
          onClick={() => navigate("/bet_kalkulatori/procena_vrednosti_tiketa")}
          className={classes.liItem}
        >
          PROCENA VREDNOSTI TIKETA
        </li>
        <li
          onClick={() => navigate("/bet_kalkulatori/odbrana_tiketa")}
          className={classes.liItem}
        >
          ODBRANA TIKETA
        </li>
        {/* <li
          onClick={() => navigate("/bet_kalkulatori/poređenje_kvota_-_marža")}
          className={classes.liItem}
        >
          POREĐENJE KVOTA - MARŽA
        </li> */}

        <li className={classes.liTitle}>KALKULATORI STRATEGIJE</li>
        {/* <li
          onClick={() => navigate("/bet_kalkulatori/fibonacci")}
          className={classes.liItem}
        >
          FIBONACCI
        </li> */}
        <li
          onClick={() => navigate("/bet_kalkulatori/d_alembert")}
          className={classes.liItem}
        >
          D ALEMBERT
        </li>
        <li
          onClick={() => navigate("/bet_kalkulatori/paroli")}
          className={classes.liItem}
        >
          PAROLI
        </li>
        {/* <li
          onClick={() => navigate("/bet_kalkulatori/devet_magičnih_linija")}
          className={classes.liItem}
        >
          DEVET MAGIČNIH LINIJA
        </li> */}
        <li className={classes.liTitle}>KALKULATORI PROGRESIJE</li>
        <li
          onClick={() => navigate("/bet_kalkulatori/pozitivna_linija")}
          className={classes.liItem}
        >
          POZITIVNA LINIJA
        </li>
        <li
          onClick={() => navigate("/bet_kalkulatori/pozitivna_progresija")}
          className={classes.liItem}
        >
          POZITIVNA PROGRESIJA
        </li>
        <li
          onClick={() => navigate("/bet_kalkulatori/arbitražni_kalkulator")}
          className={classes.liItem}
        >
          ARBITRAŽNI BETKO KALKULATOR
        </li>
        <li
          onClick={() => navigate("/bet_kalkulatori/negativna_progresija")}
          className={classes.liItem}
        >
          NEGATIVNA PROGRESIJA
        </li>
        <li className={classes.liTitle}>KALKULATORI SISTEMA</li>
        <li
          onClick={() =>
            navigate("/bet_kalkulatori/isplative_kvote_i_broj_kombinacija")
          }
          className={classes.liItem}
        >
          ISPLATIVE KVOTE I BROJ KOMBINACIJA
        </li>
        <li
          onClick={() =>
            navigate("/bet_kalkulatori/tablica_grupisanih_betko_sistema")
          }
          className={classes.liItem}
        >
          TABLICA GRUPISANIH BETKO SISTEMA
        </li>
        <li
          onClick={() =>
            navigate(
              "/bet_kalkulatori/tablica_uslovnih_kvota_i_broj_kombinacija"
            )
          }
          className={classes.liItem}
        >
          TABLICA USLOVNIH KVOTA I BROJ KOMBINACIJA
        </li>
        <li
          onClick={() => navigate("/bet_kalkulatori/puni_sistemi")}
          className={classes.liItem}
        >
          PUNI SISTEMI
        </li>
        <li
          onClick={() =>
            navigate("/bet_kalkulatori/puni_grupisani_betko_sistemi")
          }
          className={classes.liItem}
        >
          PUNI GRUPISANI BETKO SISTEMI
        </li>
        <li
          onClick={() => navigate("/bet_kalkulatori/sistemi_u_sistemu")}
          className={classes.liItem}
        >
          SISTEMI U SISTEMU
        </li>
        <li
          onClick={() => navigate("/bet_kalkulatori/skraćeni_sistemi")}
          className={classes.liItem}
        >
          SKRAĆENI SISTEMI
        </li>
        <li
          onClick={() =>
            navigate("/bet_kalkulatori/skraćeni_grupisani_sistemi")
          }
          className={classes.liItem}
        >
          SKRAĆENI GRUPISANI BETKO SISTEMI
        </li>
        <li
          onClick={() => navigate("/bet_kalkulatori/jednotrećinski_sistemi")}
          className={classes.liItem}
        >
          JEDNOTREĆINSKI BETKO SISTEMI
        </li>
        <li
          onClick={() =>
            navigate("/bet_kalkulatori/procentualni_betko_sistemi")
          }
          className={classes.liItem}
        >
          PROCENTUALNI BETKO SISTEMI
        </li>
        {/* <li
          onClick={() => navigate("/bet_kalkulatori/višeuslovni_sistemi")}
          className={classes.liItem}
        >
          VIŠEUSLOVNI SISTEMI
        </li> */}
        <li
          onClick={() => navigate("/bet_kalkulatori/combo_bombo")}
          className={classes.liItem}
        >
          COMBO BOMBO
        </li>
        <li
          onClick={() => navigate("/bet_kalkulatori/zatvaranje")}
          className={classes.liItem}
        >
          KOMBINOVANI SISTEMI
        </li>
      </ul>
    </div>
  );
};

export default BetKalkulatori;
