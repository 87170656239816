import { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import classes from "./adminChatImages.module.css";
import Axios from "axios";
import { notification, Modal, Input, Select } from "antd";

const { Option } = Select;

const AdminChatImages = () => {
  const SERVER_URL = process.env.REACT_APP_SERVER_URL;
  const [images, setImages] = useState([]);
  const [isVisibleModal, setIsModalVisible] = useState(false);
  const [imageType, setImageType] = useState();
  const [filteredImages, setFilteredImages] = useState([]);
  ////////////////
  const [infoBlockImage, setInfoBlockImage] = useState();
  const [betPortalImage, setBetPortalImage] = useState();
  const [statisticImage, setStatisticImage] = useState();
  const [betCourseImage, setBetCourseImage] = useState();
  const [betCourseNewImage, setBetCourseNewImage] = useState();
  const [yourSuggestionsImage, setYourSuggestionsImage] = useState();
  const [tutorialsImage, setTutorialsImage] = useState();
  const [userPackageImage, setUserPackageImage] = useState();
  ////////////////
  const [infoBlockLink, setInfoBlockLink] = useState();
  const [betPortalLink, setBetPortalLink] = useState();
  const [statisticLink, setStatisticLink] = useState();
  const [betCourseLink, setBetCourseLink] = useState();
  const [betCourseNewLink, setBetCourseNewLink] = useState();
  const [yourSuggestionsLink, setYourSuggestionsLink] = useState();
  const [tutorialsLink, setTutorialsLink] = useState();
  const [userPackageLink, setUserPackageLink] = useState();

  const fetchImages = async () => {
    try {
      const imagesArr = await Axios.get(`${SERVER_URL}/images/get-all`, {
        withCredentials: false,
      });

      setImages(imagesArr.data);
      setFilteredImages(imagesArr.data);
    } catch (error) {
      console.log("err", error);
    }
  };

  const fetchData = async () => {
    try {
      const allchatimages = await Axios.get(`${SERVER_URL}/chat-images`, {
        withCredentials: false,
      });

      if (allchatimages && allchatimages.data) {
        if (
          allchatimages.data.infoBlockImage &&
          allchatimages.data.infoBlockImage.image
        ) {
          setInfoBlockImage(allchatimages.data.infoBlockImage.image);
          setInfoBlockLink(allchatimages.data.infoBlockImage.link);
        }
        if (
          allchatimages.data.betPortalImage &&
          allchatimages.data.betPortalImage.image
        ) {
          setBetPortalImage(allchatimages.data.betPortalImage.image);
          setBetPortalLink(allchatimages.data.betPortalImage.link);
        }
        if (
          allchatimages.data.statisticImage &&
          allchatimages.data.statisticImage.image
        ) {
          setStatisticImage(allchatimages.data.statisticImage.image);
          setStatisticLink(allchatimages.data.statisticImage.link);
        }
        if (
          allchatimages.data.betCourseImage &&
          allchatimages.data.betCourseImage.image
        ) {
          setBetCourseImage(allchatimages.data.betCourseImage.image);
          setBetCourseLink(allchatimages.data.betCourseImage.link);
        }
        if (
          allchatimages.data.betCourseNewImage &&
          allchatimages.data.betCourseNewImage.image
        ) {
          setBetCourseNewImage(allchatimages.data.betCourseNewImage.image);
          setBetCourseNewLink(allchatimages.data.betCourseNewImage.link);
        }
        if (
          allchatimages.data.yourSuggestionsImage &&
          allchatimages.data.yourSuggestionsImage.image
        ) {
          setYourSuggestionsImage(
            allchatimages.data.yourSuggestionsImage.image
          );
          setYourSuggestionsLink(allchatimages.data.yourSuggestionsImage.link);
        }
        if (
          allchatimages.data.tutorialsImage &&
          allchatimages.data.tutorialsImage.image
        ) {
          setTutorialsImage(allchatimages.data.tutorialsImage.image);
          setTutorialsLink(allchatimages.data.tutorialsImage.link);
        }
        if (
          allchatimages.data.userspackagesImage &&
          allchatimages.data.userspackagesImage.image
        ) {
          setUserPackageImage(allchatimages.data.userspackagesImage.image);
          setUserPackageLink(allchatimages.data.userspackagesImage.link);
        }
      }
    } catch (error) {
      console.log("err", error);
    }
  };

  useEffect(() => {
    fetchImages();
    fetchData();
  }, []);

  const handleShowModal = (e) => {
    setIsModalVisible(true);
    setImageType(e);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setImageType();
  };

  const handleSelectImage = async (image) => {
    if (imageType === "infoblock") {
      setInfoBlockImage(image);
    } else if (imageType === "betportal") {
      setBetPortalImage(image);
    } else if (imageType === "statistic") {
      setStatisticImage(image);
    } else if (imageType === "betcourse") {
      setBetCourseImage(image);
    } else if (imageType === "betcoursenew") {
      setBetCourseNewImage(image);
    } else if (imageType === "yoursuggestions") {
      setYourSuggestionsImage(image);
    } else if (imageType === "tutorials") {
      setTutorialsImage(image);
    } else if (imageType === "userspackages") {
      setUserPackageImage(image);
    }
    setIsModalVisible(false);
  };

  const handleSubmit = async (type) => {
    if (type === "infoblock") {
      await Axios.post(
        `${SERVER_URL}/chat-image`,
        { image: infoBlockImage, imageType: type, link: infoBlockLink },
        {
          withCredentials: false,
        }
      );
    } else if (type === "betportal") {
      await Axios.post(
        `${SERVER_URL}/chat-image`,
        { image: betPortalImage, imageType: type, link: betPortalLink },
        {
          withCredentials: false,
        }
      );
    } else if (type === "statistic") {
      await Axios.post(
        `${SERVER_URL}/chat-image`,
        { image: statisticImage, imageType: type, link: statisticLink },
        {
          withCredentials: false,
        }
      );
    } else if (type === "betcourse") {
      await Axios.post(
        `${SERVER_URL}/chat-image`,
        { image: betCourseImage, imageType: type, link: betCourseLink },
        {
          withCredentials: false,
        }
      );
    } else if (type === "betcoursenew") {
      await Axios.post(
        `${SERVER_URL}/chat-image`,
        { image: betCourseNewImage, imageType: type, link: betCourseNewLink },
        {
          withCredentials: false,
        }
      );
    } else if (type === "yoursuggestions") {
      await Axios.post(
        `${SERVER_URL}/chat-image`,
        {
          image: yourSuggestionsImage,
          imageType: type,
          link: yourSuggestionsLink,
        },
        {
          withCredentials: false,
        }
      );
    } else if (type === "tutorials") {
      await Axios.post(
        `${SERVER_URL}/chat-image`,
        { image: tutorialsImage, imageType: type, link: tutorialsLink },
        {
          withCredentials: false,
        }
      );
    } else if (type === "userspackages") {
      await Axios.post(
        `${SERVER_URL}/chat-image`,
        { image: userPackageImage, imageType: type, link: userPackageLink },
        {
          withCredentials: false,
        }
      );
    }
  };

  const filterImages = (e) => {
    if (e) {
      let filtered = images.filter((item) => item.imageType === e);

      setFilteredImages(filtered);
    } else {
      setFilteredImages(images);
    }
  };

  return (
    <div className={classes.container}>
      <div style={{ display: "flex", alignItems: "center" }}>
        <h1>Izbor chat image (Pitaj slike) za menije: </h1>
      </div>

      <div className={classes.menu}>
        <div className={classes.row}>
          <div className={classes.left}>
            <p>Info Blok:</p>
            {infoBlockImage ? (
              <img
                style={{ width: "100px" }}
                src={`${SERVER_URL}/${infoBlockImage?.url}`}
              ></img>
            ) : (
              <div style={{ width: "100px" }}></div>
            )}
          </div>
          <Input
            value={infoBlockLink}
            placeholder="link na koji vodi slika"
            onChange={(e) => {
              setInfoBlockLink(e.target.value);
            }}
            className={classes.input}
          ></Input>
          <button
            className={classes.addImageBtn}
            onClick={() => {
              handleShowModal("infoblock");
            }}
          >
            Izaberite novu sliku
          </button>

          <button
            className={classes.saveBtn}
            onClick={() => {
              handleSubmit("infoblock");
            }}
          >
            Sačuvaj
          </button>
        </div>
        <div className={classes.row}>
          <div className={classes.left}>
            <p>Bet portal:</p>
            {betPortalImage ? (
              <img
                style={{ width: "100px" }}
                src={`${SERVER_URL}/${betPortalImage?.url}`}
              ></img>
            ) : (
              <div style={{ width: "100px" }}></div>
            )}
          </div>
          <Input
            value={betPortalLink}
            placeholder="link na koji vodi slika"
            onChange={(e) => {
              setBetPortalLink(e.target.value);
            }}
            className={classes.input}
          ></Input>
          <button
            className={classes.addImageBtn}
            onClick={() => {
              handleShowModal("betportal");
            }}
          >
            Izaberite novu sliku
          </button>

          <button
            className={classes.saveBtn}
            onClick={() => {
              handleSubmit("betportal");
            }}
          >
            Sačuvaj
          </button>
        </div>
        <div className={classes.row}>
          <div className={classes.left}>
            <p>Statistika:</p>
            {statisticImage ? (
              <img
                style={{ width: "100px" }}
                src={`${SERVER_URL}/${statisticImage?.url}`}
              ></img>
            ) : (
              <div style={{ width: "100px" }}></div>
            )}
          </div>
          <Input
            value={statisticLink}
            placeholder="link na koji vodi slika"
            onChange={(e) => {
              setStatisticLink(e.target.value);
            }}
            className={classes.input}
          ></Input>
          <button
            className={classes.addImageBtn}
            onClick={() => {
              handleShowModal("statistic");
            }}
          >
            Izaberite novu sliku
          </button>

          <button
            className={classes.saveBtn}
            onClick={() => {
              handleSubmit("statistic");
            }}
          >
            Sačuvaj
          </button>
        </div>
        {/* <div className={classes.row}>
          <div className={classes.left}>
            <p>Bet kurs:</p>
            {betCourseImage ? (
              <img
                style={{ width: "100px" }}
                src={`${SERVER_URL}/${betCourseImage?.url}`}
              ></img>
            ) : (
              <div style={{ width: "100px" }}></div>
            )}
          </div>
          <Input
            value={betCourseLink}
            placeholder="link na koji vodi slika"
            onChange={(e) => {
              setBetCourseLink(e.target.value);
            }}
            className={classes.input}
          ></Input>
          <button
            className={classes.addImageBtn}
            onClick={() => {
              handleShowModal("betcourse");
            }}
          >
            Izaberite novu sliku
          </button>

          <button
            className={classes.saveBtn}
            onClick={() => {
              handleSubmit("betcourse");
            }}
          >
            Sačuvaj
          </button>
        </div> */}
        <div className={classes.row}>
          <div className={classes.left}>
            <p>Premium:</p>
            {betCourseNewImage ? (
              <img
                style={{ width: "100px" }}
                src={`${SERVER_URL}/${betCourseNewImage?.url}`}
              ></img>
            ) : (
              <div style={{ width: "100px" }}></div>
            )}
          </div>
          <Input
            value={betCourseNewLink}
            placeholder="link na koji vodi slika"
            onChange={(e) => {
              setBetCourseNewLink(e.target.value);
            }}
            className={classes.input}
          ></Input>
          <button
            className={classes.addImageBtn}
            onClick={() => {
              handleShowModal("betcoursenew");
            }}
          >
            Izaberite novu sliku
          </button>

          <button
            className={classes.saveBtn}
            onClick={() => {
              handleSubmit("betcoursenew");
            }}
          >
            Sačuvaj
          </button>
        </div>
        <div className={classes.row}>
          <div className={classes.left}>
            <p>Vaši predlozi:</p>
            {yourSuggestionsImage ? (
              <img
                style={{ width: "100px" }}
                src={`${SERVER_URL}/${yourSuggestionsImage?.url}`}
              ></img>
            ) : (
              <div style={{ width: "100px" }}></div>
            )}
          </div>
          <Input
            value={yourSuggestionsLink}
            placeholder="link na koji vodi slika"
            onChange={(e) => {
              setYourSuggestionsLink(e.target.value);
            }}
            className={classes.input}
          ></Input>
          <button
            className={classes.addImageBtn}
            onClick={() => {
              handleShowModal("yoursuggestions");
            }}
          >
            Izaberite novu sliku
          </button>

          <button
            className={classes.saveBtn}
            onClick={() => {
              handleSubmit("yoursuggestions");
            }}
          >
            Sačuvaj
          </button>
        </div>
        <div className={classes.row}>
          <div className={classes.left}>
            <p>Bet tutorijal:</p>
            {tutorialsImage ? (
              <img
                style={{ width: "100px" }}
                src={`${SERVER_URL}/${tutorialsImage?.url}`}
              ></img>
            ) : (
              <div style={{ width: "100px" }}></div>
            )}
          </div>
          <Input
            value={tutorialsLink}
            placeholder="link na koji vodi slika"
            onChange={(e) => {
              setTutorialsLink(e.target.value);
            }}
            className={classes.input}
          ></Input>
          <button
            className={classes.addImageBtn}
            onClick={() => {
              handleShowModal("tutorials");
            }}
          >
            Izaberite novu sliku
          </button>

          <button
            className={classes.saveBtn}
            onClick={() => {
              handleSubmit("tutorials");
            }}
          >
            Sačuvaj
          </button>
        </div>

        <div className={classes.row}>
          <div className={classes.left}>
            <p>Korisnički paketi:</p>
            {userPackageImage ? (
              <img
                style={{ width: "100px" }}
                src={`${SERVER_URL}/${userPackageImage?.url}`}
              ></img>
            ) : (
              <div style={{ width: "100px" }}></div>
            )}
          </div>
          <Input
            value={userPackageLink}
            placeholder="link na koji vodi slika"
            onChange={(e) => {
              setUserPackageLink(e.target.value);
            }}
            className={classes.input}
          ></Input>
          <button
            className={classes.addImageBtn}
            onClick={() => {
              handleShowModal("userspackages");
            }}
          >
            Izaberite novu sliku
          </button>

          <button
            className={classes.saveBtn}
            onClick={() => {
              handleSubmit("userspackages");
            }}
          >
            Sačuvaj
          </button>
        </div>
      </div>

      <Modal
        width={"80%"}
        footer={[]}
        title="Kliknite sliku da biste je odabrali"
        visible={isVisibleModal}
        onCancel={handleCancel}
      >
        <div>
          <Select
            style={{ minWidth: "200px" }}
            onChange={(e) => {
              filterImages(e);
            }}
            defaultValue={""}
          >
            <Option value="">Sve</Option>
            <Option value="home">Početna strana</Option>
            <Option value="gallery">Gallery</Option>
          </Select>
        </div>
        <div className={classes.imagesList}>
          {filteredImages &&
            filteredImages.length > 0 &&
            filteredImages.map((item, index) => {
              return (
                <div className={classes.imageItem}>
                  <img
                    onClick={() => {
                      handleSelectImage(item);
                    }}
                    className="img"
                    src={`${SERVER_URL}/${item.url}`}
                    alt={item.url}
                  />
                </div>
              );
            })}
        </div>
      </Modal>
    </div>
  );
};

export default AdminChatImages;
