import classes from "./adminSviKorisnici.module.css";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import { useQuery } from "@tanstack/react-query";
import AdminSingleUser from "./AdminSingleUser";
import AdminUserSearchModule from "./AdminUserSearchModule";
import Spinner from "../components/Spinner";

const SviKorisnici = () => {
  const axiosPrivate = useAxiosPrivate();
  const getAllUsers = async () => {
    const { data } = await axiosPrivate.get("/user/getAllUsers");
    data.allUsers.map((user) => {
      user.paidToDate = new Date(user.paidToDate).getTime();
      return user;
    });
    return data;
  };
  const allUsersQuery = useQuery({
    queryKey: ["users"],
    queryFn: () => getAllUsers(),
    staleTime: 0,
    refetchInterval: 1000 * 60 * 60 * 12,
  });
  if (allUsersQuery.isError) return <h2>Error...</h2>;

  return (
    <div className={classes.container}>
      {allUsersQuery.isLoading ? (
        <Spinner />
      ) : (
        <>
          <h1>Svi Korisnici</h1>
          <div className={classes.top}>
            <AdminUserSearchModule />
          </div>
          <div className={classes.postContainer}>
            {allUsersQuery.data.allUsers !== undefined &&
              allUsersQuery.data.allUsers.map((user) => {
                return <AdminSingleUser user={user} key={user._id} />;
              })}
          </div>
        </>
      )}
    </div>
  );
};

export default SviKorisnici;
