import classes from './shopAdminHome.module.css'

const ShopAdminHome = () => {
  return (
    <div className={classes.container}>
      <h2>HOME</h2>
      <h3> BETKO SHOP ADMIN DASHBOARD</h3>
    </div>
  )
}

export default ShopAdminHome
