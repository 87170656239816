import React, { useContext, useEffect, useState } from 'react'
import { Table, Button, Input, message, Modal } from 'antd'
import classes from './AdminTipsterAwardsArchive.module.css'
import AuthContext from '../context/AuthProvider'
import formatPrice from '../utils/FormatPriceCurrency'
import useAxiosPrivate from '../hooks/useAxiosPrivate'
import { BsEye } from 'react-icons/bs'
import { useNavigate } from 'react-router-dom'
const SERVER_URL = process.env.REACT_APP_SERVER_URL

const AdminTipsterAwardsArchive = () => {
  const [currentPage, setCurrentPage] = useState(1)
  const { auth } = useContext(AuthContext)
  const [allAwards, setAllAwards] = useState(null)
  const [refetchTrigger, setRefetchTrigger] = useState(false)
  const [selectedAward, setSelectedAward] = useState(null)
  const [previewAwardModal, setPreviewAwardModal] = useState()
  const isAdmin = auth.roles.includes(5151)
  const axiosPrivate = useAxiosPrivate()
  const navigate = useNavigate()

  useEffect(() => {
    const getAllAwards = async () => {
      try {
        const response = await axiosPrivate.get(
          `${SERVER_URL}/get-all-user-awards/${currentPage}/10`
        )

        setAllAwards(response?.data)
      } catch (error) {
        setAllAwards([])
        message.error(
          error?.response?.data?.message || 'Greška pri preuzimanju nagrada!'
        )
      }
    }

    getAllAwards()
  }, [refetchTrigger, currentPage])

  useEffect(() => {
    if (!isAdmin) {
      navigate('/betko-shop-admin/home')
    }
  }, [])

  const columns = [
    {
      title: 'Dobitnik',
      key: 'username',
      render: (record) => (
        <span>{record?.user?.username || record?.user?.userName}</span>
      ),
    },
    {
      title: 'Email',
      key: 'email',
      render: (record) => <span>{record?.user?.email}</span>,
    },
    {
      title: 'Period',
      dataIndex: 'period',
      key: 'period',
    },
    {
      title: 'Mesto',
      key: 'place',
      render: (place) => (
        <span>
          {place === 'fistPlace'
            ? 'Prvo '
            : place === 'secondPlace'
            ? 'Drugo'
            : 'Treće'}
        </span>
      ),
    },
    {
      title: 'Tabela',
      key: 'table',
      render: (place) => (
        <span>
          {place === 'year'
            ? 'Godišnja'
            : place === 'month'
            ? 'Mesešna'
            : 'Rang'}
        </span>
      ),
    },

    {
      title: 'Opis',
      key: 'opis',
      render: (_, record) => (
        <span>
          <Button
            icon={<BsEye />}
            onClick={() => {
              setPreviewAwardModal(true)
              setSelectedAward(record)
            }}
            title='Opis nagrade'
          />
        </span>
      ),
    },
  ]

  return (
    <div className={classes.shopAdminAwards}>
      <h2>ARHIVA TIPSTER NAGRADA</h2>

      <div className={classes.awardTableContainer}>
        <Table
          columns={columns}
          dataSource={allAwards?.paginatedData}
          className={classes.awardTable}
          pagination={{
            current: currentPage,
            pageSize: 10,
            total: allAwards?.totalCount,
            onChange: (page) => setCurrentPage(page),
            showSizeChanger: false,
          }}
        />
      </div>

      <Modal
        open={previewAwardModal}
        title='OPIS NAGRADE'
        onCancel={() => {
          setPreviewAwardModal(false)
          setSelectedAward(null)
        }}
        closable
        footer={null}
        className={classes.awardPreviewModal}
      >
        <p>{selectedAward?.description}</p>
      </Modal>
    </div>
  )
}

export default AdminTipsterAwardsArchive
