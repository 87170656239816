import Grupa1 from "../components/Grupa1";
import Grupa28 from "../components/Grupa28";
import Grupa3007 from "../components/Grupa3007";
import classes from "./kosarkaPoeniIgraca.module.css";
import { useState, useEffect } from "react";
const KosarkaPoeniIgraca = () => {
  //////zaglavlje////////
  const [snd, setSND] = useState("-");
  const [sod, setSOD] = useState("-");
  const [sog, setSOG] = useState("-");
  const [sng, setSNG] = useState("-");
  const [sodg, setSODG] = useState("-");
  const [sndg, setSNDG] = useState("-");
  const [sndSog, setSNDSOG] = useState("-");
  const [sodSng, setSODSNG] = useState("-");
  /////input polja////
  const [kvotaNaKonacanIshod1, setKvotaNaKonacanIshod1] = useState(0);
  const [kvotaNaKonacanIshodX, setKvotaNaKonacanIshodX] = useState(0);
  const [kvotaNaKonacanIshod2, setKvotaNaKonacanIshod2] = useState(0);
  const [domacinDao, setDomacinDao] = useState(0);
  const [domacinPrimio, setDomacinPrimio] = useState(0);
  const [brojUtakmicaDomacin, setBrojUtakmicaDomacin] = useState(0);
  const [brojUtakmicaGost, setBrojUtakmicaGost] = useState(0);
  const [gostDao, setGostDao] = useState(0);
  const [gostPrimio, setGostPrimio] = useState(0);
  //////////////////
  const [pobedaDomacina, setPobedaDomacina] = useState(0);
  const [pobedaGosta, setPobedaGosta] = useState(0);
  const [nereseno, setNereseno] = useState(0);
  const [golMin, setGolMin] = useState(0);
  const [golMax, setGolMax] = useState(0);
  const [golBalans, setGolBalans] = useState(0);
  const [tacanRezultatDomacin, setTacanRezultatDomacin] = useState(0);
  const [tacanRezultatGost, setTacanRezultatGost] = useState(0);
  const [pobedaSaRazlikom, setPobedaSaRazlikom] = useState(0);
  const [betkoPredlog1, setBetkoPredlog1] = useState(0);
  const [betkoPredlog2, setBetkoPredlog2] = useState(0);

  const [pobedaSaRazlikomZagrada, setPobedaSaRazlikomZagrada] = useState(0);
  const [
    ocekivaniMaksimalanTacanRezultat,
    setOcekivaniMaksimalanTacanRezultat,
  ] = useState(0);
  const [ocekivaniMinimalanTacanRezultat, setOcekivaniMinimalanTacanRezultat] =
    useState(0);

  const [ocekivaniSrednjiTacanRezultat, setOcekivaniSrednjiTacanRezultat] =
    useState(0);
  const [
    ocekivaniMaksimalanTacanRezultat2,
    setOcekivaniMaksimalanTacanRezultat2,
  ] = useState(0);
  const [
    ocekivaniMinimalanTacanRezultat2,
    setOcekivaniMinimalanTacanRezultat2,
  ] = useState(0);

  const [ocekivaniSrednjiTacanRezultat2, setOcekivaniSrednjiTacanRezultat2] =
    useState(0);
  const initial = Array.from({ length: 16 }, () => ({
    igracDomaciTim: "",
    brUtakmica: 0,
    ukupnoPoeni: 0,
    prosekPoena: 0,
    procenat: 0,
    min: 0,
    sredina: 0,
    max: 0,
    max2: 0,
    min2: 0,
    sredina2: 0,
    procenat2: 0,
    prosekPoena2: 0,
    ukupnoPoeni2: 0,
    brUtakmica2: 0,
    igracGostujuciTim: "",
  }));
  const [data, setData] = useState(initial);

  const [rerender, setReRender] = useState(false);
  const onChangeInput = (e, indexRow, index) => {
    let datapriv = data;
    if (index === 0) {
      datapriv[indexRow].igracDomaciTim = e;
    } else if (index === 1) {
      datapriv[indexRow].brUtakmica = e;
    } else if (index === 2) {
      datapriv[indexRow].prosekPoena = e;
    } else if (index === 5) {
      datapriv[indexRow].igracGostujuciTim = e;
    } else if (index === 4) {
      datapriv[indexRow].brUtakmica2 = e;
    } else if (index === 3) {
      datapriv[indexRow].prosekPoena2 = e;
    }
    setData(datapriv);
    setReRender(!rerender);
    handleCalculate();
  };

  useEffect(() => {
    handleCalculate();
  }, [data, rerender]);
  const handleCalculate = () => {
    for (let i = 0; i < data.length; i++) {
      if (
        (data[i].brUtakmica !== 0 && data[i].prosekPoena !== 0) ||
        (data[i].brUtakmica2 !== 0 && data[i].prosekPoena2 !== 0)
      ) {
        let datapriv = data;
        datapriv[i].ukupnoPoeni = (
          datapriv[i].brUtakmica * datapriv[i].prosekPoena
        ).toFixed(2);

        datapriv[i].ukupnoPoeni2 = (
          datapriv[i].brUtakmica2 * datapriv[i].prosekPoena2
        ).toFixed(2);

        datapriv[i].procenat = ((datapriv[i].prosekPoena / snd) * 100).toFixed(
          1
        );

        datapriv[i].procenat2 = (
          (datapriv[i].prosekPoena2 / sng) *
          100
        ).toFixed(1);

        datapriv[i].min = (
          (datapriv[i].procenat * ocekivaniMinimalanTacanRezultat) /
          100
        ).toFixed(2);

        datapriv[i].min2 = (
          (datapriv[i].procenat2 * ocekivaniMinimalanTacanRezultat2) /
          100
        ).toFixed(2);

        datapriv[i].sredina = (
          (datapriv[i].procenat * ocekivaniSrednjiTacanRezultat) /
          100
        ).toFixed(2);

        datapriv[i].max = (
          (datapriv[i].procenat * ocekivaniMaksimalanTacanRezultat) /
          100
        ).toFixed(2);

        datapriv[i].sredina2 = (
          (datapriv[i].procenat2 * ocekivaniSrednjiTacanRezultat2) /
          100
        ).toFixed(2);

        datapriv[i].max2 = (
          (datapriv[i].procenat2 * ocekivaniMaksimalanTacanRezultat2) /
          100
        ).toFixed(2);
        setData(datapriv);
      }
    }
  };
  return (
    <div className={classes.container}>
      <Grupa1
        imgText="KOŠARKA POENI IGRAČA"
        imgBackgroundColorDark="#000000"
        imgBackgroundColorLight="#000000"
        imgFontColor="#F9511D"
        label1="VREME POČETKA"
        label2="ZEMLJA"
        label3="DOMAĆIN"
        label4="TAKMIČENJE - LIGA"
        label5="GOST"
      />
      <Grupa28
        imgBackgroundColorDark="#000000"
        imgBackgroundColorLight="#000000"
        imgFontColor="#F9511D"
        label1={snd}
        label2={sod}
        label3={sog}
        label4={sng}
        label5={sodg}
        label6={sndg}
        label7={sndSog}
        label8={sodSng}
        calculatorType={`Kosarka poeni igraca`}
      />
      <Grupa3007
        setSND={setSND}
        setSOD={setSOD}
        setSNDG={setSNDG}
        setSOG={setSOG}
        setSNG={setSNG}
        setSODG={setSODG}
        setSNDSOG={setSNDSOG}
        setSODSNG={setSODSNG}
        kvotaNaKonacanIshod1={kvotaNaKonacanIshod1}
        setKvotaNaKonacanIshod1={setKvotaNaKonacanIshod1}
        kvotaNaKonacanIshod2={kvotaNaKonacanIshod2}
        setKvotaNaKonacanIshod2={setKvotaNaKonacanIshod2}
        kvotaNaKonacanIshodX={kvotaNaKonacanIshodX}
        setKvotaNaKonacanIshodX={setKvotaNaKonacanIshodX}
        domacinDao={domacinDao}
        setDomacinDao={setDomacinDao}
        domacinPrimio={domacinPrimio}
        setDomacinPrimio={setDomacinPrimio}
        brojUtakmicaDomacin={brojUtakmicaDomacin}
        setBrojUtakmicaDomacin={setBrojUtakmicaDomacin}
        brojUtakmicaGost={brojUtakmicaGost}
        setBrojUtakmicaGost={setBrojUtakmicaGost}
        gostDao={gostDao}
        setGostDao={setGostDao}
        gostPrimio={gostPrimio}
        setGostPrimio={setGostPrimio}
        pobedaDomacina={pobedaDomacina}
        setPobedaDomacina={setPobedaDomacina}
        pobedaGosta={pobedaGosta}
        setPobedaGosta={setPobedaGosta}
        nereseno={nereseno}
        setNereseno={setNereseno}
        golMin={golMin}
        setGolMin={setGolMin}
        golMax={golMax}
        setGolMax={setGolMax}
        golBalans={golBalans}
        setGolBalans={setGolBalans}
        tacanRezultatDomacin={tacanRezultatDomacin}
        setTacanRezultatDomacin={setTacanRezultatDomacin}
        tacanRezultatGost={tacanRezultatGost}
        setTacanRezultatGost={setTacanRezultatGost}
        pobedaSaRazlikom={pobedaSaRazlikom}
        setPobedaSaRazlikom={setPobedaSaRazlikom}
        betkoPredlog1={betkoPredlog1}
        setBetkoPredlog1={setBetkoPredlog1}
        betkoPredlog2={betkoPredlog2}
        setBetkoPredlog2={setBetkoPredlog2}
        pobedaSaRazlikomZagrada={pobedaSaRazlikomZagrada}
        setPobedaSaRazlikomZagrada={setPobedaSaRazlikomZagrada}
        setOcekivaniMaksimalanTacanRezultat={
          setOcekivaniMaksimalanTacanRezultat
        }
        setOcekivaniMaksimalanTacanRezultat2={
          setOcekivaniMaksimalanTacanRezultat2
        }
        setOcekivaniMinimalanTacanRezultat={setOcekivaniMinimalanTacanRezultat}
        setOcekivaniMinimalanTacanRezultat2={
          setOcekivaniMinimalanTacanRezultat2
        }
        setOcekivaniSrednjiTacanRezultat={setOcekivaniSrednjiTacanRezultat}
        setOcekivaniSrednjiTacanRezultat2={setOcekivaniSrednjiTacanRezultat2}
      />

      <div className={classes.calculator}>
        <div className={classes.zaglavlje}>
          <div className={classes.podzaglavlje}>
            <p style={{ color: "#F9511D" }}> OČEKIVANI MINIMALNI REZULTAT</p>
            <p style={{ color: "#F9511D" }}>OČEKIVANI SREDNJI REZULTAT</p>
            <p style={{ color: "#F9511D" }}>
              OČEKIVANI MAKSIMALNI TAČAN REZULTAT
            </p>
          </div>
          <div className={classes.podzaglavlje2}>
            <p style={{ margin: "10px", fontSize: "80px", color: "red" }}>
              {ocekivaniMinimalanTacanRezultat}
            </p>
            <p style={{ margin: "10px", fontSize: "80px", color: "red" }}>
              {ocekivaniMinimalanTacanRezultat2}
            </p>
            <p style={{ margin: "10px", fontSize: "80px", color: "yellow" }}>
              {ocekivaniSrednjiTacanRezultat}
            </p>
            <p style={{ margin: "10px", fontSize: "80px", color: "yellow" }}>
              {ocekivaniSrednjiTacanRezultat2}
            </p>
            <p style={{ margin: "10px", fontSize: "80px", color: "green" }}>
              {ocekivaniMaksimalanTacanRezultat}
            </p>
            <p style={{ margin: "10px", fontSize: "80px", color: "green" }}>
              {ocekivaniMaksimalanTacanRezultat2}
            </p>
          </div>
        </div>

        <div className={classes.parent}>
          <table
            style={{ textAlign: "center", fontWeight: "bold" }}
            className={classes.calculateTable}
          >
            <thead>
              <tr>
                <th style={{ width: "15%", color: "#F9511D" }}>
                  IGRAČ (DOMAĆI TIM)
                </th>
                <th style={{ width: "5%", color: "#F9511D" }}>BR. UT.</th>
                <th style={{ width: "5%", color: "#F9511D" }}>PR.KOŠ TIM</th>
                <th style={{ width: "5%", color: "#F9511D" }}>PR. POEN</th>
                <th style={{ width: "5%", color: "#F9511D" }}>%</th>
                <th style={{ width: "5%", color: "#F9511D" }}>MIN+</th>
                <th style={{ width: "5%", color: "#F9511D" }}>SREDINA</th>
                <th style={{ width: "5%", color: "#F9511D" }}>MAX-</th>
                <th
                  style={{
                    width: "5%",
                    backgroundColor: "red",
                    color: "black",
                  }}
                >
                  MAX-
                </th>
                <th
                  style={{
                    width: "5%",
                    backgroundColor: "red",
                    color: "black",
                  }}
                >
                  SREDINA
                </th>
                <th
                  style={{
                    width: "5%",
                    backgroundColor: "red",
                    color: "black",
                  }}
                >
                  MIN +
                </th>
                <th
                  style={{
                    width: "5%",
                    backgroundColor: "red",
                    color: "black",
                  }}
                >
                  %
                </th>
                <th
                  style={{
                    width: "5%",
                    backgroundColor: "red",
                    color: "black",
                  }}
                >
                  PR. POEN
                </th>
                <th
                  style={{
                    width: "5%",
                    backgroundColor: "red",
                    color: "black",
                  }}
                >
                  PR.KOŠ TIM
                </th>
                <th
                  style={{
                    width: "5%",
                    backgroundColor: "red",
                    color: "black",
                  }}
                >
                  BR. UT.
                </th>
                <th
                  style={{
                    width: "15%",
                    backgroundColor: "red",
                    color: "black",
                  }}
                >
                  IGRAČ (GOSTUJUĆI TIM)
                </th>
              </tr>
            </thead>
          </table>

          <div
            className="table"
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              fontWeight: "bolder",
            }}
          >
            <div style={{ display: "flex" }}>
              <div
                className="igracDomaciTim"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "15%",
                }}
              >
                {initial.map((item, index) => {
                  return (
                    <div
                      style={{
                        height: "40px",
                        textAlign: "center",
                        display: "flex",
                        justifyContent: "center",
                        fontWeight: "bold",
                        border: "1px solid black",
                        borderLeft: "4px solid black",
                        background: "white",
                      }}
                    >
                      <input
                        style={{
                          marginLeft: "3px",
                          height: "95%",
                          fontWeight: "bolder",
                          width: "100%",
                          textAlign: "center",
                          background: "white",
                          border: "none",
                        }}
                        onChange={(e) =>
                          onChangeInput(e.target.value, index, 0)
                        }
                        key={index}
                        value={data[index].igracDomaciTim}
                      />
                    </div>
                  );
                })}
              </div>

              <div
                className="brUtakmica"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "5%",
                }}
              >
                {initial.map((item, index) => {
                  return (
                    <div
                      style={{
                        height: "40px",
                        textAlign: "center",
                        display: "flex",
                        justifyContent: "center",
                        fontWeight: "bold",
                        border: "1px solid black",
                        background: "white",
                      }}
                    >
                      <input
                        style={{
                          height: "95%",
                          width: "95%",
                          textAlign: "center",
                          background: "white",
                          border: "none",
                          fontWeight: "bolder",
                        }}
                        onChange={(e) =>
                          onChangeInput(e.target.value, index, 1)
                        }
                        key={index}
                        value={data[index].brUtakmica}
                      />
                    </div>
                  );
                })}
              </div>

              <div
                className="ukupnoPoeni"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "5%",
                }}
              >
                {initial.map((item, index) => {
                  return (
                    <div
                      style={{
                        height: "40px",
                        textAlign: "center",
                        display: "flex",
                        justifyContent: "center",
                        border: "1px solid black",
                        background: "lightgray",
                        alignItems: "center",
                        color: "black",
                      }}
                    >
                      <input
                        style={{
                          height: "95%",
                          width: "95%",
                          textAlign: "center",
                          border: "none",
                          fontWeight: "bolder",
                        }}
                        disabled
                        key={index}
                        value={snd}
                      />
                    </div>
                  );
                })}
              </div>

              <div
                className="prosekPoena"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "5%",
                }}
              >
                {initial.map((item, index) => {
                  return (
                    <div
                      style={{
                        height: "40px",
                        textAlign: "center",
                        display: "flex",
                        justifyContent: "center",
                        fontWeight: "bold",
                        border: "1px solid black",
                        background: "white",
                      }}
                    >
                      <input
                        style={{
                          height: "95%",
                          width: "95%",
                          textAlign: "center",
                          background: "white",
                          border: "none",
                          fontWeight: "bolder",
                        }}
                        onChange={(e) =>
                          onChangeInput(e.target.value, index, 2)
                        }
                        key={index}
                        value={data[index].prosekPoena}
                      />
                    </div>
                  );
                })}
              </div>

              <div
                className="procenat"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "5%",
                }}
              >
                {initial.map((item, index) => {
                  return (
                    <div
                      style={{
                        height: "40px",
                        textAlign: "center",
                        display: "flex",
                        justifyContent: "center",
                        border: "1px solid black",
                        background: "grey",
                        alignItems: "center",
                        color: "#F9511D",
                      }}
                    >
                      {isNaN(data[index].procenat) ? 0 : data[index].procenat}
                    </div>
                  );
                })}
              </div>

              <div
                className="min"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "5%",
                }}
              >
                {initial.map((item, index) => {
                  return (
                    <div
                      style={{
                        height: "40px",
                        textAlign: "center",
                        display: "flex",
                        justifyContent: "center",
                        border: "1px solid black",
                        alignItems: "center",
                        color: "#F9511D",
                        background: "#161616",
                      }}
                    >
                      {isNaN(data[index].min) ? 0 : data[index].min}
                    </div>
                  );
                })}
              </div>

              <div
                className="sredina"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "5%",
                }}
              >
                {initial.map((item, index) => {
                  return (
                    <div
                      style={{
                        height: "40px",
                        textAlign: "center",
                        display: "flex",
                        justifyContent: "center",
                        border: "1px solid black",
                        alignItems: "center",
                        color: "#F9511D",
                        background: "#161616",
                      }}
                    >
                      {isNaN(data[index].sredina) ? 0 : data[index].sredina}
                    </div>
                  );
                })}
              </div>

              <div
                className="max"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "4.9%",
                }}
              >
                {initial.map((item, index) => {
                  return (
                    <div
                      style={{
                        height: "40px",
                        textAlign: "center",
                        display: "flex",
                        justifyContent: "center",
                        border: "1px solid black",
                        alignItems: "center",
                        color: "#F9511D",
                        background: "#161616",
                      }}
                    >
                      {isNaN(data[index].max) ? 0 : data[index].max}
                    </div>
                  );
                })}
              </div>

              <div
                className="max2"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "5.1%",
                }}
              >
                {initial.map((item, index) => {
                  return (
                    <div
                      style={{
                        height: "40px",
                        textAlign: "center",
                        display: "flex",
                        justifyContent: "center",
                        border: "1px solid black",
                        background: "red",
                        alignItems: "center",
                        color: "black",
                      }}
                    >
                      {isNaN(data[index].max2) ? 0 : data[index].max2}
                    </div>
                  );
                })}
              </div>

              <div
                className="sredina2"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "5%",
                }}
              >
                {initial.map((item, index) => {
                  return (
                    <div
                      style={{
                        height: "40px",
                        textAlign: "center",
                        display: "flex",
                        justifyContent: "center",
                        border: "1px solid black",
                        background: "red",
                        alignItems: "center",
                        color: "black",
                      }}
                    >
                      {isNaN(data[index].sredina2) ? 0 : data[index].sredina2}
                    </div>
                  );
                })}
              </div>

              <div
                className="min2"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "5%",
                }}
              >
                {initial.map((item, index) => {
                  return (
                    <div
                      style={{
                        height: "40px",
                        textAlign: "center",
                        display: "flex",
                        justifyContent: "center",
                        border: "1px solid black",
                        background: "red",
                        alignItems: "center",
                        color: "black",
                      }}
                    >
                      {isNaN(data[index].min2) ? 0 : data[index].min2}
                    </div>
                  );
                })}
              </div>

              <div
                className="procenat2"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "5%",
                }}
              >
                {initial.map((item, index) => {
                  return (
                    <div
                      style={{
                        height: "40px",
                        textAlign: "center",
                        display: "flex",
                        justifyContent: "center",
                        border: "1px solid black",
                        background: "#FC7676",
                        alignItems: "center",
                        color: "black",
                      }}
                    >
                      {isNaN(data[index].procenat2) ? 0 : data[index].procenat2}
                    </div>
                  );
                })}
              </div>

              <div
                className="prosekPoena2"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "5%",
                }}
              >
                {initial.map((item, index) => {
                  return (
                    <div
                      style={{
                        height: "40px",
                        textAlign: "center",
                        display: "flex",
                        justifyContent: "center",
                        fontWeight: "bold",
                        border: "1px solid black",
                        background: "white",
                      }}
                    >
                      <input
                        style={{
                          width: "95%",
                          height: "95%",
                          textAlign: "center",
                          background: "white",
                          border: "none",
                          fontWeight: "bolder",
                        }}
                        onChange={(e) =>
                          onChangeInput(e.target.value, index, 3)
                        }
                        key={index}
                        value={data[index].prosekPoena2}
                      />
                    </div>
                  );
                })}
              </div>

              <div
                className="ukupnoPoeni2"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "5%",
                }}
              >
                {initial.map((item, index) => {
                  return (
                    <div
                      style={{
                        height: "40px",
                        textAlign: "center",
                        display: "flex",
                        justifyContent: "center",
                        border: "1px solid black",
                        background: "#FDA172",
                        alignItems: "center",
                        color: "black",
                      }}
                    >
                      <input
                        style={{
                          height: "95%",
                          width: "95%",
                          textAlign: "center",
                          background: "lightgray",
                          border: "none",
                          fontWeight: "bolder",
                        }}
                        disabled
                        key={index}
                        value={sng}
                      />
                    </div>
                  );
                })}
              </div>

              <div
                className="brojUtakmica2"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "5%",
                }}
              >
                {initial.map((item, index) => {
                  return (
                    <div
                      style={{
                        height: "40px",
                        textAlign: "center",
                        display: "flex",
                        justifyContent: "center",
                        fontWeight: "bold",
                        border: "1px solid black",
                        background: "white",
                      }}
                    >
                      <input
                        style={{
                          width: "95%",
                          height: "95%",
                          textAlign: "center",
                          background: "white",
                          border: "none",
                          fontWeight: "bolder",
                        }}
                        onChange={(e) =>
                          onChangeInput(e.target.value, index, 4)
                        }
                        key={index}
                        value={data[index].brUtakmica2}
                      />
                    </div>
                  );
                })}
              </div>

              <div
                className="igracGostujuciTim"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "15%",
                }}
              >
                {initial.map((item, index) => {
                  return (
                    <div
                      style={{
                        height: "40px",
                        textAlign: "center",
                        display: "flex",
                        justifyContent: "center",
                        fontWeight: "bold",
                        border: "1px solid black",
                        background: "white",
                      }}
                    >
                      <input
                        style={{
                          width: "95%",
                          height: "95%",
                          textAlign: "center",
                          background: "white",
                          border: "none",
                          fontWeight: "bolder",
                        }}
                        onChange={(e) =>
                          onChangeInput(e.target.value, index, 5)
                        }
                        key={index}
                        value={data[index].igracGostujuciTim}
                      />
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default KosarkaPoeniIgraca;
