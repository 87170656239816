import React, { useEffect, useRef } from 'react'
const frontendURL = process.env.REACT_APP_FRONTEND_URL

const WsPayFrom = ({ formValues, TotalAmount }) => {
  const formRef = useRef()

  useEffect(() => {
    formValues && formRef.current.submit()
  }, [formValues])

  return (
    <form
      name='pay'
      ref={formRef}
      // Production:
      // action='https://form.wspay.biz/authorization.aspx'
      // Test:
      action='https://formtest.wspay.biz/authorization.aspx'
      method='POST'
    >
      <input type='submit' value='Pay' style={{ display: 'none' }} />
      <input type='hidden' name='ShopID' value='JJBETKOME' />
      <input
        type='hidden'
        name='ShoppingCartID'
        value={formValues?.ShoppingCartID}
      />
      <input type='hidden' name='Version' value='2.0' />
      <input type='hidden' name='TotalAmount' value={TotalAmount} />
      <input type='hidden' name='Signature' value={formValues?.Signature} />
      <input type='hidden' name='Lang' value='SR' />
      <input
        type='hidden'
        name='CustomerFirstName'
        value={formValues?.CustomerFirstName}
      />
      <input
        type='hidden'
        name='CustomerLastName'
        value={formValues?.CustomerLastName}
      />
      <input
        type='hidden'
        name='CustomerEmail'
        value={formValues?.CustomerEmail}
      />
      <input
        type='hidden'
        name='CustomerAddress'
        value={formValues?.CustomerAddress}
      />
      <input
        type='hidden'
        name='CustomerCity'
        value={formValues?.CustomerCity}
      />
      <input type='hidden' name='CustomerZIP' value={formValues?.CustomerZIP} />
      <input
        type='hidden'
        name='CustomerCountry'
        value={formValues?.CustomerCountry}
      />
      <input
        type='hidden'
        name='CustomerPhone'
        value={formValues?.CustomerPhone}
      />
      <input
        type='hidden'
        name='ReturnURL'
        value={`${frontendURL}/uspesno-placanje`}
      />
      <input
        type='hidden'
        name='CancelURL'
        value={`${frontendURL}/neuspesno-placanje`}
      />
      {/* 0000 – no payment plan (installments are not allowed), only payment at once */}
      <input type='hidden' name='PaymentPlan' value='0000' />
      <input
        type='hidden'
        name='ReturnErrorURL'
        value={`${frontendURL}/neuspesno-placanje`}
      />
    </form>
  )
}

export default WsPayFrom
