import Grupa3 from "../components/Grupa3";
import Grupa20 from "../components/Grupa20";
import classes from "./isplativeKvoteIBrojKombinacija.module.css";
import Grupa300 from "../components/Grupa300";
import React, { useState, useEffect } from "react";

const IsplativeKvoteIBrojKombinacija = () => {
  const [upisUslovnog, setUpisUslovnog] = useState(0);
  const [upisUkupnog, setUpisUkupnog] = useState(0);
  const [minIsplativo, setMinIsplativno] = useState(0);
  const [minProcenat, setMinProcenat] = useState(0);
  const [ukupanBroj, setUkupanBroj] = useState(0);

  const handleChangeUpisUslovnog = (e) => {
    setUpisUslovnog(e.target.value);
  };

  const handleChangeUpisUkupnog = (e) => {
    setUpisUkupnog(e.target.value);
  };

  const handleCalculate = () => {
    if (upisUslovnog && upisUkupnog) {
      let h76 = parseFloat(upisUkupnog);
      let b76 = parseFloat(upisUslovnog);

      let b62 = b76; // Since b62 and b76 are same
      let p62 = h76; // Similarly p62 and h76

      setMinProcenat(((b62 / p62) * 100).toFixed(2));

      function combin(n, k) {
        if (k === 0 || k === n) return 1;
        if (k > n) return 0;

        let result = 1;
        for (let i = 1; i <= k; i++) {
          result = (result * (n - i + 1)) / i;
        }

        return result;
      }

      let ukupno = combin(p62, b62);

      let res = JSON.parse(JSON.stringify(ukupno));

      setUkupanBroj(parseFloat(res).toFixed(0));

      setMinIsplativno((parseFloat(res).toFixed(0) ** (1 / b62)).toFixed(2));
    }
  };

  useEffect(() => {
    handleCalculate();
  }, [upisUkupnog, upisUslovnog]);

  return (
    <div className={classes.container}>
      <Grupa3
        imgText="ISPLATIVE KVOTE I BROJ KOMBINACIJA"
        imgBackgroundColorDark="#072C80"
        imgBackgroundColorLight="#0D2762"
        imgFontColor="#FF0000"
      />
      <Grupa20
        imgBackgroundColorDark="#072C80"
        imgBackgroundColorLight="#0D2762"
        imgFontColor="#FF0000"
        calculatorType={`Isplative kvote i broj kombinacija`}
      />
      <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
        <div className={classes.onerowisplative}>
          <label className={classes.paragraphisplative}>
            UPIS USLOVNOG BROJA TRAŽENIH PODATAKA
          </label>
          <label className={classes.paragraphisplative}>
            UPIS UKUPNOG BROJA PAROVA U SISTEMU
          </label>
        </div>
        <div className={classes.onerowisplative}>
          <input
            style={{ fontWeight: "bolder" }}
            className={classes.input}
            onChange={(e) => {
              handleChangeUpisUslovnog(e);
            }}
          ></input>
          <input
            style={{ fontWeight: "bolder" }}
            className={classes.input}
            onChange={(e) => {
              handleChangeUpisUkupnog(e);
            }}
          ></input>
        </div>
      </div>

      <div className={classes.calculator}>
        <p className={classes.minIsplativo}>{minIsplativo}</p>
        <p className={classes.minProcenat}>
          {minProcenat ? `${minProcenat} %` : 0}
        </p>
        <p className={classes.ukupanBroj}>
          {isNaN(ukupanBroj) ? "-" : parseFloat(ukupanBroj)}
        </p>
      </div>
    </div>
  );
};

export default IsplativeKvoteIBrojKombinacija;
