import classes from "./grupa308.module.css";
import { useEffect } from "react";

const Grupa308 = ({
  upisKvoteNaKonacanIshod1,
  upisKvoteNaKonacanIshod2,
  upisDomPlus,
  upisDomMinus,
  upisGostPlus,
  upisGostMinus,
  brUtakmicaDomacin,
  brUtakmicaGost,
  setSND,
  setSOD,
  setSOG,
  setSNG,
  setSODG,
  setSNDG,
  setSNDSOG,
  setSODSNG,
  setUpisKvoteNaKonacanIshod1,
  setUpisKvoteNaKonacanIshod2,
  setUpisDomPlus,
  setUpisDomMinus,
  setUpisGostPlus,
  setUpisGostMinus,
  setBrUtakmicaDomacin,
  setBrUtakmicaGost,
  setProcenatPobede1,
  setProcenatPobede2,
  setOpcijaTacnogRezultata1,
  setOpcijaTacnogRezultata2,
  setBodMin,
  setBodMax,
  setBodBalans,
  setBetkoPredlog,
}) => {
  useEffect(() => {
    handleCalculate();
  }, [
    upisKvoteNaKonacanIshod1,
    upisKvoteNaKonacanIshod2,
    upisDomMinus,
    upisGostMinus,
    upisDomPlus,
    upisGostPlus,
    brUtakmicaDomacin,
    brUtakmicaGost,
  ]);

  const handleChangePobedaDomacina = (e) => {
    setUpisKvoteNaKonacanIshod1(e);
  };

  const handleChangePobedaGosta = (e) => {
    setUpisKvoteNaKonacanIshod2(e);
  };

  const handleChangeDomacinDao = (e) => {
    setUpisDomPlus(e);
  };

  const handleChangeGostDao = (e) => {
    setUpisGostPlus(e);
  };

  const handleChangeDomacinPrimio = (e) => {
    setUpisDomMinus(e);
  };

  const handleChangeGostPrimio = (e) => {
    setUpisGostMinus(e);
  };

  const handleChangeDomacinBrUtakmica = (e) => {
    setBrUtakmicaDomacin(e);
  };

  const handleChangeGostBrUtakmica = (e) => {
    setBrUtakmicaGost(e);
  };

  const handleCalculate = (e) => {
    if (
      (upisKvoteNaKonacanIshod1,
      upisKvoteNaKonacanIshod2,
      upisDomMinus,
      upisGostMinus,
      upisDomPlus,
      upisGostPlus,
      brUtakmicaDomacin,
      brUtakmicaGost)
    ) {
      /////////// ZAGLAVLJE //////////////////

      let snd = upisDomPlus / brUtakmicaDomacin;

      setSND(snd.toFixed(2));

      let sod = upisDomMinus / brUtakmicaDomacin;

      setSOD(sod.toFixed(2));

      let sog = upisGostPlus / brUtakmicaGost;

      setSOG(sog.toFixed(2));

      let sng = upisGostMinus / brUtakmicaGost;

      setSNG(sng.toFixed(2));

      let sodg = (sod + sog) / 2;

      setSODG(sodg.toFixed(2));

      let sndg = (snd + sng) / 2;

      setSNDG(sndg.toFixed(2));

      let sndsog = (snd + sog) / 2;

      setSNDSOG(sndsog.toFixed(2));

      let sodsng = (sod + sng) / 2;

      setSODSNG(sodsng.toFixed(2));
      /////////////////////////////

      const procenat1 = 1 / upisKvoteNaKonacanIshod1;
      const procenat2 = 1 / upisKvoteNaKonacanIshod2;
      let ukupanProcenat = procenat1 + procenat2;

      ukupanProcenat = ukupanProcenat * 100;

      const iznad100Procenata = ukupanProcenat - 100;

      const polaOdIznad = iznad100Procenata / 2;

      const procenatPobede1 = (procenat1 * 100 - polaOdIznad).toFixed(2);

      const procenatPobede2 = (procenat2 * 100 - polaOdIznad).toFixed(2);

      setProcenatPobede1(procenatPobede1);
      setProcenatPobede2(procenatPobede2);

      let f57 = snd + sod;

      let g57 = sog + sng;

      let f62 = (f57 + g57) / 2;

      let g62;

      if (f62 >= 10) {
        g62 = 1.23;
      } else if (f62 >= 9) {
        g62 = 1.34;
      } else if (f62 >= 8) {
        g62 = 1.11;
      } else if (f62 >= 7) {
        g62 = 1.21;
      } else {
        g62 = 1.14;
      }

      let h62 = g62 + 1.11;
      g62 = g62 / 100;

      let f61 = f62 + f62 * g62;

      let e61 = f61 - f61 * 0.15;

      let bodmin = e61;

      setBodMin(bodmin.toFixed(2));

      let bodbalans = f61;

      setBodBalans(bodbalans.toFixed(2));

      let g59 = f61 + (f61 - e61);

      h62 = h62 / 100;

      let g61 = g59 + g59 * h62;

      let bodmax = g61;

      setBodMax(bodmax.toFixed(2));
      let ah61 = procenatPobede1;
      let ai61 = procenatPobede2;

      let ag61 = f61;

      let aj61 = (ag61 * ah61) / 100;

      let ak61 = (ag61 * ai61) / 100;

      let al61 = Math.round(aj61);

      let an61 = Math.round(ak61);

      let ay61;

      if (al61 === an61 && ah61 >= ai61) {
        ay61 = al61 + 1;
      } else {
        ay61 = al61;
      }

      let opcijatacnogrez1 = Math.round(ay61);

      setOpcijaTacnogRezultata1(opcijatacnogrez1);

      let ba61;

      // console.log(al61, an61, ah61);

      if (al61 === an61 && al61 > ah61) {
        ba61 = an61 + 1;
      } else {
        ba61 = an61;
      }

      let opcijatacnogrez2 = Math.round(ba61);

      setOpcijaTacnogRezultata2(opcijatacnogrez2);

      let f59 = (e61 + f61) / 2;

      let betkopredlog = f59.toFixed(2);

      setBetkoPredlog(betkopredlog);
    }
  };

  return (
    <div className={classes.container}>
      <input
        onChange={(e) => {
          handleChangePobedaDomacina(e.target.value);
        }}
        className={classes.blackCenteredInput}
        placeholder="pobeda domaćina"
        type="number"
        min="0"
      />
      <input
        onChange={(e) => {
          handleChangePobedaGosta(e.target.value);
        }}
        className={classes.blackCenteredInput}
        placeholder="pobeda gosta"
        type="number"
        min="0"
      />
      <input
        onChange={(e) => {
          handleChangeDomacinDao(e.target.value);
        }}
        className={classes.blackCenteredInput}
        placeholder="domaćin (dao)"
        type="number"
        min="0"
      />
      <input
        onChange={(e) => {
          handleChangeDomacinPrimio(e.target.value);
        }}
        className={classes.blackCenteredInput}
        placeholder="domaćin (primio)"
        type="number"
        min="0"
      />
      <input
        onChange={(e) => {
          handleChangeDomacinBrUtakmica(e.target.value);
        }}
        className={classes.blackCenteredInput}
        placeholder="domaćin broj ut."
        type="number"
        min="0"
      />
      <input
        onChange={(e) => {
          handleChangeGostDao(e.target.value);
        }}
        className={classes.blackCenteredInput}
        placeholder="gost (dao)"
        type="number"
        min="0"
      />
      <input
        onChange={(e) => {
          handleChangeGostPrimio(e.target.value);
        }}
        className={classes.blackCenteredInput}
        placeholder="gost (primio)"
        type="number"
        min="0"
      />
      <input
        onChange={(e) => {
          handleChangeGostBrUtakmica(e.target.value);
        }}
        className={classes.blackCenteredInput}
        placeholder="gost broj ut."
        type="number"
        min="0"
      />
    </div>
  );
};

export default Grupa308;
