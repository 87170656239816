import { useState, useRef, useEffect } from 'react'
import classes from './adminFooterImages.module.css'
import Axios from 'axios'
import { notification, Modal, Input, InputNumber, Select } from 'antd'
import svggore from './assets/arrowup.svg'
import svgdole from './assets/arrowdown.svg'

const { Option } = Select

const AdminChatImages = () => {
  const SERVER_URL = process.env.REACT_APP_SERVER_URL
  const [images, setImages] = useState([])
  const [filteredImages, setFilteredImages] = useState([])
  const [items, setItems] = useState([])
  const [data, setData] = useState([])
  const [reRender, setReRender] = useState(false)
  const [selectedItemIndex, setSelectedItemIndex] = useState(0)
  const [isVisibleModal, setIsModalVisible] = useState(false)
  const [isVisibleOldModal, setIsOldModalVisible] = useState(false)
  const [isSaveButtonDisabled, setIsSaveButtonDisabled] = useState(false)
  const [imageType, setImageType] = useState('') //web ili mobile slika

  const fetchImages = async () => {
    try {
      const imagesArr = await Axios.get(`${SERVER_URL}/images/get-all`, {
        withCredentials: false,
      })

      setImages(imagesArr.data)
      setFilteredImages(imagesArr.data)
    } catch (error) {
      console.log('err', error)
    }
  }

  const fetchData = async () => {
    try {
      const imagesArr = await Axios.get(`${SERVER_URL}/footer-images`, {
        withCredentials: false,
      })

      setData(imagesArr.data)
    } catch (error) {
      console.log('error with fetch footer image items', error)
    }
  }

  useEffect(() => {
    fetchImages()
    fetchData()
  }, [])

  const handleCancel = () => {
    setIsModalVisible(false)
    setIsOldModalVisible(false)
  }

  const handleSelectImage = (item) => {
    if (imageType === 'web') {
      let itemsArr = items

      itemsArr[selectedItemIndex].image = item

      setItems(itemsArr)
    } else if (imageType === 'mobile') {
      let itemsArr = items

      itemsArr[selectedItemIndex].mobileImage = item

      setItems(itemsArr)
    }

    setIsModalVisible(false)
  }

  const handleSelectImageOldItem = (item) => {
    if (imageType === 'web') {
      let itemsArr = data.items
      let dataObj = data
      itemsArr[selectedItemIndex].image = item
      dataObj.items = itemsArr
      setData(dataObj)
    } else if (imageType === 'mobile') {
      let itemsArr = data.items
      let dataObj = data
      itemsArr[selectedItemIndex].mobileImage = item
      dataObj.items = itemsArr
      setData(dataObj)
    }

    setIsOldModalVisible(false)
  }

  const handleShowModal = (index) => {
    setIsModalVisible(true)
    setSelectedItemIndex(index)
  }

  const handleShowModalOldItems = (index) => {
    setIsOldModalVisible(true)
    setSelectedItemIndex(index)
  }

  const handleNewItem = () => {
    setItems([...items, { image: '', url: '', mobileImage: '', duration: 0 }])
  }

  const handleDeleteItem = async (itemId) => {
    try {
      await Axios.delete(`${SERVER_URL}/footer-imageitem/${itemId}`, {
        withCredentials: false,
      })

      notification.success({
        message: 'Uspešno obrisana stavka.',
        duration: 2,
      })

      setTimeout(() => {
        window.location.reload()
      }, 200)
    } catch (error) {
      console.log(error)
      notification.error({
        message: 'Error with delete of footer images item',
      })
    }
  }

  const handleSubmit = async () => {
    try {
      setIsSaveButtonDisabled(true)

      await Axios.post(
        `${SERVER_URL}/footer-images`,
        { items, data },
        {
          withCredentials: false,
        }
      )

      notification.success({
        message: 'Uspešno objavljene reklamne slike za footer.',
        duration: 2,
      })

      setTimeout(() => {
        window.location.reload()
      }, 200)
    } catch (error) {
      console.log(error)
      notification.error({
        message: 'Error with post of footer images',
      })
    }
  }

  const handleChangeItemDuration = (e, index) => {
    let itemsArr = items

    itemsArr[index].duration = e

    setItems(itemsArr)
  }

  const handleChangeItemCountry = (e, index) => {
    let itemsArr = items

    itemsArr[index].country = e

    setItems(itemsArr)
  }

  const handleRemoveFromAdding = (index) => {
    let array = items
    array.splice(index, 1)

    setItems(array)
    setReRender(!reRender)
  }

  const handleChangeItemUrl = (e, index) => {
    let itemsArr = items

    itemsArr[index].url = e.target.value

    setItems(itemsArr)
  }

  const handleChangeItemUrlMobile = (e, index) => {
    let itemsArr = items

    itemsArr[index].urlMobile = e.target.value

    setItems(itemsArr)
  }

  const handleMoveItem = async (itemId, direction) => {
    try {
      await Axios.put(
        `${SERVER_URL}/footerimages-moveitem?direction=${direction}&&itemId=${itemId}`
      )

      notification.success({ message: 'Uspešno pomerena stavka' })

      setTimeout(() => {
        window.location.reload()
      }, 150)
    } catch (error) {
      console.log(error)
    }
  }

  const handleChangeOldItemDuration = (e, index) => {
    let itemsArr = data.items
    let dataObj = data
    itemsArr[index].duration = e

    dataObj.items = itemsArr
    setData(dataObj)
  }

  const handleChangeOldItemCountry = (e, index) => {
    let itemsArr = data.items
    let dataObj = data
    itemsArr[index].country = e

    dataObj.items = itemsArr
    setData(dataObj)
  }

  const handleChangeOldItemUrl = (e, index) => {
    let itemsArr = data.items
    let dataObj = data
    itemsArr[index].url = e.target.value

    dataObj.items = itemsArr
    setData(dataObj)
  }

  const handleChangeOldItemUrlMobile = (e, index) => {
    let itemsArr = data.items
    let dataObj = data
    itemsArr[index].urlMobile = e.target.value

    dataObj.items = itemsArr
    setData(dataObj)
  }

  const filterImages = (e) => {
    if (e) {
      let filtered = images.filter((item) => item.imageType === e)

      setFilteredImages(filtered)
    } else {
      setFilteredImages(images)
    }
  }

  return (
    <div className={classes.container}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <h1>
          Izbor footer slika za reklame | web (1285x40) | mobile (372x50):{' '}
        </h1>
        <button
          onClick={() => {
            handleNewItem()
          }}
          className={classes.addImageBtn}
        >
          Dodaj
        </button>
      </div>

      <div className={classes.menu}>
        {data &&
          data.items &&
          data.items.length > 0 &&
          data.items.map((item, index) => {
            return (
              <div className={classes.row}>
                <div className={classes.arrows}>
                  {index !== 0 && (
                    <img
                      className={classes.svgimg}
                      src={svggore}
                      onClick={() => {
                        handleMoveItem(item._id, 'moveleft')
                      }}
                    />
                  )}
                  {index !== data.items.length - 1 && (
                    <img
                      className={classes.svgimg}
                      src={svgdole}
                      onClick={() => {
                        handleMoveItem(item._id, 'moveright')
                      }}
                    />
                  )}
                </div>
                <img
                  className={classes.img}
                  src={`${SERVER_URL}/${item?.image?.url}`}
                ></img>
                <img
                  className={classes.img}
                  src={`${SERVER_URL}/${item?.mobileImage?.url}`}
                ></img>
                <Input
                  placeholder='link za web'
                  className={classes.input}
                  defaultValue={item.url}
                  onChange={(e) => {
                    handleChangeOldItemUrl(e, index)
                  }}
                ></Input>
                <Input
                  placeholder='link za mobile'
                  className={classes.input}
                  defaultValue={item.urlMobile}
                  onChange={(e) => {
                    handleChangeOldItemUrlMobile(e, index)
                  }}
                ></Input>
                <Select
                  defaultValue={item.country}
                  className={classes.country}
                  placeholder={'Država'}
                  onChange={(e) => {
                    handleChangeOldItemCountry(e, index)
                  }}
                >
                  <Option value='RS'>Srbija</Option>
                  <Option value='ME'>Crna Gora</Option>
                  <Option value='MK'>Makedonija</Option>
                  <Option value='HR'>Hrvatska</Option>
                  <Option value='BA'>Bosna i hercegovina</Option>
                  <Option value='SI'>Slovenija</Option>
                  <Option value='world'>Ostalo</Option>
                </Select>
                <InputNumber
                  placeholder='trajanje u sekundama'
                  className={classes.inputnumber_fromant}
                  min={0}
                  defaultValue={item.duration}
                  onChange={(e) => {
                    handleChangeOldItemDuration(e, index)
                  }}
                ></InputNumber>
                <button
                  className={classes.addImageBtn}
                  onClick={() => {
                    handleShowModalOldItems(index)
                    setImageType('web')
                  }}
                >
                  Web slika
                </button>

                <button
                  className={classes.addImageBtn}
                  onClick={() => {
                    handleShowModalOldItems(index)
                    setImageType('mobile')
                  }}
                >
                  Mobile slika
                </button>
                <button
                  className={classes.delBtn}
                  onClick={() => {
                    handleDeleteItem(item._id)
                  }}
                >
                  Obriši
                </button>
              </div>
            )
          })}
        {items &&
          items.length > 0 &&
          items.map((item, index) => {
            return (
              <div className={classes.row}>
                <img
                  className={classes.img}
                  src={`${SERVER_URL}/${item?.image?.url}`}
                ></img>
                <img
                  className={classes.img}
                  src={`${SERVER_URL}/${item?.mobileImage?.url}`}
                ></img>
                <Input
                  placeholder='link za web'
                  className={classes.input}
                  onChange={(e) => {
                    handleChangeItemUrl(e, index)
                  }}
                ></Input>
                <Input
                  placeholder='link za mobile'
                  className={classes.input}
                  defaultValue={item.urlMobile}
                  onChange={(e) => {
                    handleChangeItemUrlMobile(e, index)
                  }}
                ></Input>
                <Select
                  defaultValue={item.country}
                  className={classes.country}
                  placeholder={'Država'}
                  onChange={(e) => {
                    handleChangeItemCountry(e, index)
                  }}
                >
                  <Option value='RS'>Srbija</Option>
                  <Option value='ME'>Crna Gora</Option>
                  <Option value='MK'>Makedonija</Option>
                  <Option value='HR'>Hrvatska</Option>
                  <Option value='BA'>Bosna i hercegovina</Option>
                  <Option value='SI'>Slovenija</Option>
                  <Option value='world'>Ostalo</Option>
                </Select>
                <InputNumber
                  placeholder='trajanje u sekundama'
                  className={classes.inputnumber_fromant}
                  min={0}
                  onChange={(e) => {
                    handleChangeItemDuration(e, index)
                  }}
                ></InputNumber>

                <button
                  className={classes.addImageBtn}
                  onClick={() => {
                    handleShowModal(index)
                    setImageType('web')
                  }}
                >
                  Web slika
                </button>

                <button
                  className={classes.addImageBtn}
                  onClick={() => {
                    handleShowModal(index)
                    setImageType('mobile')
                  }}
                >
                  Mobile slika
                </button>
                <button
                  className={classes.delBtn}
                  onClick={() => {
                    handleRemoveFromAdding(index)
                  }}
                >
                  Obriši
                </button>
              </div>
            )
          })}
      </div>
      <div className={classes.saveDiv}>
        <button
          disabled={isSaveButtonDisabled}
          onClick={handleSubmit}
          className={classes.saveBtn}
        >
          Sačuvaj
        </button>
      </div>

      <Modal
        width={'80%'}
        footer={[]}
        title='Kliknite sliku da biste je odabrali'
        visible={isVisibleModal}
        onCancel={handleCancel}
      >
        <div>
          <Select
            style={{ minWidth: '200px' }}
            onChange={(e) => {
              filterImages(e)
            }}
            defaultValue={''}
          >
            <Option value=''>Sve</Option>
            <Option value='home'>Početna strana</Option>
            <Option value='gallery'>Gallery</Option>
          </Select>
        </div>
        <div className={classes.imagesList}>
          {filteredImages &&
            filteredImages.length > 0 &&
            filteredImages.map((item, index) => {
              return (
                <div className={classes.imageItem}>
                  <img
                    onClick={() => {
                      handleSelectImage(item)
                    }}
                    className='img'
                    src={`${SERVER_URL}/${item.url}`}
                    alt={item.url}
                  />
                </div>
              )
            })}
        </div>
      </Modal>
      <Modal
        width={'80%'}
        footer={[]}
        title='Kliknite sliku da biste je odabrali'
        visible={isVisibleOldModal}
        onCancel={handleCancel}
      >
        <div>
          <Select
            style={{ minWidth: '200px' }}
            onChange={(e) => {
              filterImages(e)
            }}
            defaultValue={''}
          >
            <Option value=''>Sve</Option>
            <Option value='home'>Početna strana</Option>
            <Option value='gallery'>Gallery</Option>
          </Select>
        </div>
        <div className={classes.imagesList}>
          {filteredImages &&
            filteredImages.length > 0 &&
            filteredImages.map((item, index) => {
              return (
                <div className={classes.imageItem}>
                  <img
                    onClick={() => {
                      handleSelectImageOldItem(item)
                    }}
                    className='img'
                    src={`${SERVER_URL}/${item.url}`}
                    alt={item.url}
                  />
                </div>
              )
            })}
        </div>
      </Modal>
    </div>
  )
}

export default AdminChatImages
