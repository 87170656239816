import Grupa3 from "../components/Grupa3";
import Grupa20 from "../components/Grupa20";
import classes from "./tablicaUslovnihKvotaIBrojKombinacija.module.css";

const TablicaUslovnihKvotaIBrojKombinacija = () => {
  const colLeft1 = [1];
  const colLeft2 = [2, 1];
  const colLeft3 = [3, 3, 1];
  const colLeft4 = [4, 6, 4, 1];
  const colLeft5 = [5, 10, 10, 5, 1];
  const colLeft6 = [6, 15, 20, 15, 6, 1];
  const colLeft7 = [7, 21, 35, 35, 21, 7, 1];
  const colLeft8 = [8, 28, 56, 70, 56, 28, 8, 1];
  const colLeft9 = [9, 36, 84, 126, 126, 84, 36, 9, 1];
  const colLeft10 = [10, 45, 120, 210, 252, 210, 120, 45, 10, 1];
  const colLeft11 = [11, 55, 165, 330, 462, 462, 330, 165, 55, 11, 1];
  const colLeft12 = [12, 66, 220, 495, 792, 924, 792, 495, 220, 66, 12, 1];
  const colLeft13 = [
    13, 78, 286, 715, 1287, 1716, 1716, 1287, 715, 286, 78, 13, 1,
  ];
  const colLeft14 = [
    14, 91, 364, 1001, 2002, 3003, 3432, 3003, 2002, 1001, 364, 91, 14, 1,
  ];
  const colLeft15 = [
    15, 105, 455, 1365, 3003, 5005, 6435, 6435, 5005, 3003, 1365, 455, 105, 15,
    1,
  ];
  const colLeft16 = [
    16, 120, 560, 1820, 4368, 8008, 11440, 12870, 11440, 8008, 4368, 1820, 560,
    120, 16, 1,
  ];

  const colRight1 = [1.0];
  const colRight2 = [2.0, 1.0];
  const colRight3 = [3.0, 1.73, 1.0];
  const colRight4 = [4.0, 2.45, 1.59, 1.0];
  const colRight5 = [5.0, 3.16, 2.15, 1.5, 1.0];
  const colRight6 = [6.0, 3.87, 2.71, 1.97, 1.43, 1.0];
  const colRight7 = [7.0, 4.58, 3.27, 2.43, 1.84, 1.38, 1.0];
  const colRight8 = [8.0, 5.29, 3.83, 2.89, 2.24, 1.74, 1.35, 1.0];
  const colRight9 = [9.0, 6.0, 4.38, 3.35, 2.63, 2.09, 1.67, 1.32, 1.0];
  const colRight10 = [10, 6.71, 4.93, 3.31, 2.91, 2.51, 2.15, 1.82, 1.53, 1.0];
  const colRight11 = [
    11, 7.42, 5.48, 4.26, 3.41, 2.78, 2.29, 1.89, 1.56, 1.27, 1.0,
  ];
  const colRight12 = [
    12, 8.12, 6.04, 4.72, 3.8, 3.12, 2.59, 2.17, 1.82, 1.52, 1.25, 1.0,
  ];
  const colRight13 = [
    13.0, 8.83, 6.59, 5.17, 4.19, 3.46, 2.9, 2.45, 2.08, 1.76, 1.49, 1.24, 1.0,
  ];
  const colRight14 = [
    14.0, 9.54, 7.14, 5.62, 4.57, 3.8, 3.2, 2.72, 2.33, 2.0, 1.71, 1.46, 1.23,
    1.0,
  ];
  const colRight15 = [
    15.0, 10.25, 7.69, 6.08, 4.96, 4.14, 3.5, 2.99, 2.58, 2.23, 1.93, 1.67,
    1.43, 1.21, 1.0,
  ];
  const colRight16 = [
    16.0, 10.95, 8.24, 6.53, 5.35, 4.47, 3.8, 3.26, 2.82, 2.46, 2.14, 1.87,
    1.63, 1.41, 1.2, 1.0,
  ];

  return (
    <div className={classes.container}>
      <Grupa3
        imgText="TABLICA USLOVNIH KVOTA I BROJ KOMBINACIJA"
        imgBackgroundColorDark="#532178"
        imgBackgroundColorLight="#7630AC"
        imgFontColor="#FFFFFF"
      />
      <Grupa20
        imgBackgroundColorDark="#532178"
        imgBackgroundColorLight="#7630AC"
        imgFontColor="#FFFFFF"
        calculatorType={`Tablica uslovnih kvota i broj kombinacija`}
      />

      <div
        className={classes.calculator}
        style={{ display: "flex", flexDirection: "row" }}
      >
        <div
          className={classes.kolona}
          style={{ display: "flex", flexDirection: "column" }}
        >
          <div className={classes.naslovnaCelijaCosak}></div>
          <div
            className={classes.celijaLeveKolone}
            style={{ background: "red" }}
          >
            <p>1</p>
          </div>
          <div
            className={classes.celijaLeveKolone}
            style={{ background: "darkblue", color: "white", color: "white" }}
          >
            <p>2</p>
          </div>
          <div
            className={classes.celijaLeveKolone}
            style={{ background: "red" }}
          >
            <p>3</p>
          </div>
          <div
            className={classes.celijaLeveKolone}
            style={{ background: "darkblue", color: "white" }}
          >
            <p>4</p>
          </div>
          <div
            className={classes.celijaLeveKolone}
            style={{ background: "red" }}
          >
            <p>5</p>
          </div>
          <div
            className={classes.celijaLeveKolone}
            style={{ background: "darkblue", color: "white" }}
          >
            <p>6</p>
          </div>
          <div
            className={classes.celijaLeveKolone}
            style={{ background: "red" }}
          >
            <p>7</p>
          </div>
          <div
            className={classes.celijaLeveKolone}
            style={{ background: "darkblue", color: "white" }}
          >
            <p>8</p>
          </div>
          <div
            className={classes.celijaLeveKolone}
            style={{ background: "red" }}
          >
            <p>9</p>
          </div>
          <div
            className={classes.celijaLeveKolone}
            style={{ background: "darkblue", color: "white" }}
          >
            <p>10</p>
          </div>
          <div
            className={classes.celijaLeveKolone}
            style={{ background: "red" }}
          >
            <p>11</p>
          </div>
          <div
            className={classes.celijaLeveKolone}
            style={{ background: "darkblue", color: "white" }}
          >
            <p>12</p>
          </div>
          <div
            className={classes.celijaLeveKolone}
            style={{ background: "red" }}
          >
            <p>13</p>
          </div>
          <div
            className={classes.celijaLeveKolone}
            style={{ background: "darkblue", color: "white" }}
          >
            <p>14</p>
          </div>
          <div
            className={classes.celijaLeveKolone}
            style={{ background: "red" }}
          >
            <p>15</p>
          </div>
          <div
            className={classes.celijaLeveKolone}
            style={{ background: "darkblue", color: "white" }}
          >
            <p>16</p>
          </div>
        </div>

        <div
          className={classes.kolona}
          style={{ display: "flex", flexDirection: "column" }}
        >
          <div className={classes.naslovnaCelija} style={{ background: "red" }}>
            <p>1</p>
          </div>

          <div
            className={classes.poslednjaCelija}
            style={{
              borderLeft: "3px solid white",
              borderBottom: "3px solid white",
              borderTop: "3px solid white",
            }}
          >
            <div className={classes.levaPodCelijaPoslednjeCelije}>
              <p
                style={{
                  textAlign: "center",
                  fontWeight: "bolder",
                  marginTop: "10px",
                }}
              >
                {colLeft1[0]}
              </p>
            </div>
            <div className={classes.desnaPodCelijaPoslednjeCelije}>
              <p>{colRight1[0].toFixed(2)}</p>
            </div>
          </div>

          <div
            className={classes.celijaX}
            style={{ borderTop: "3px solid white" }}
          ></div>
          <div
            className={classes.celijaX}
            style={{ borderTop: "3px solid white" }}
          ></div>
          <div
            className={classes.celijaX}
            style={{ borderTop: "3px solid white" }}
          ></div>
          <div
            className={classes.celijaX}
            style={{ borderTop: "3px solid white" }}
          ></div>
          <div
            className={classes.celijaX}
            style={{ borderTop: "3px solid white" }}
          ></div>
          <div
            className={classes.celijaX}
            style={{ borderTop: "3px solid white" }}
          ></div>
          <div
            className={classes.celijaX}
            style={{ borderTop: "3px solid white" }}
          ></div>
          <div
            className={classes.celijaX}
            style={{ borderTop: "3px solid white" }}
          ></div>
          <div
            className={classes.celijaX}
            style={{ borderTop: "3px solid white" }}
          ></div>
          <div
            className={classes.celijaX}
            style={{ borderTop: "3px solid white" }}
          ></div>
          <div
            className={classes.celijaX}
            style={{ borderTop: "3px solid white" }}
          ></div>
          <div
            className={classes.celijaX}
            style={{ borderTop: "3px solid white" }}
          ></div>
          <div
            className={classes.celijaX}
            style={{ borderTop: "3px solid white" }}
          ></div>
          <div
            className={classes.celijaX}
            style={{ borderTop: "3px solid white" }}
          ></div>
          <div
            className={classes.celijaX}
            style={{ borderTop: "3px solid white" }}
          ></div>
        </div>

        <div
          className={classes.kolona}
          style={{ display: "flex", flexDirection: "column" }}
        >
          <div
            className={classes.naslovnaCelija}
            style={{ background: "darkblue", color: "white" }}
          >
            <p>2</p>
          </div>

          <div
            className={classes.celija}
            style={{ borderTop: "3px solid white" }}
          >
            <div className={classes.levaPodCelija}>
              <p
                style={{
                  textAlign: "center",
                  fontWeight: "bolder",
                  marginTop: "10px",
                }}
              >
                {colLeft2[0]}
              </p>
            </div>
            <div className={classes.desnaPodCelija}>
              <p>{colRight2[0].toFixed(2)}</p>
            </div>
          </div>

          <div
            className={classes.poslednjaCelija}
            style={{
              borderLeft: "3px solid white",
              borderBottom: "3px solid white",
            }}
          >
            <div className={classes.levaPodCelijaPoslednjeCelije}>
              <p
                style={{
                  textAlign: "center",
                  fontWeight: "bolder",
                  marginTop: "10px",
                }}
              >
                {colLeft2[1]}
              </p>
            </div>
            <div className={classes.desnaPodCelijaPoslednjeCelije}>
              <p>{colRight2[1].toFixed(2)}</p>
            </div>
          </div>

          <div
            className={classes.celijaX}
            style={{ borderTop: "3px solid white" }}
          ></div>
          <div
            className={classes.celijaX}
            style={{ borderTop: "3px solid white" }}
          ></div>
          <div
            className={classes.celijaX}
            style={{ borderTop: "3px solid white" }}
          ></div>
          <div
            className={classes.celijaX}
            style={{ borderTop: "3px solid white" }}
          ></div>
          <div
            className={classes.celijaX}
            style={{ borderTop: "3px solid white" }}
          ></div>
          <div
            className={classes.celijaX}
            style={{ borderTop: "3px solid white" }}
          ></div>
          <div
            className={classes.celijaX}
            style={{ borderTop: "3px solid white" }}
          ></div>
          <div
            className={classes.celijaX}
            style={{ borderTop: "3px solid white" }}
          ></div>
          <div
            className={classes.celijaX}
            style={{ borderTop: "3px solid white" }}
          ></div>
          <div
            className={classes.celijaX}
            style={{ borderTop: "3px solid white" }}
          ></div>
          <div
            className={classes.celijaX}
            style={{ borderTop: "3px solid white" }}
          ></div>
          <div
            className={classes.celijaX}
            style={{ borderTop: "3px solid white" }}
          ></div>
          <div
            className={classes.celijaX}
            style={{ borderTop: "3px solid white" }}
          ></div>
          <div
            className={classes.celijaX}
            style={{ borderTop: "3px solid white" }}
          ></div>
        </div>

        <div
          className={classes.kolona}
          style={{ display: "flex", flexDirection: "column" }}
        >
          <div className={classes.naslovnaCelija} style={{ background: "red" }}>
            <p>3</p>
          </div>

          <div
            className={classes.celija}
            style={{ borderTop: "3px solid white" }}
          >
            <div className={classes.levaPodCelija}>
              <p
                style={{
                  textAlign: "center",
                  fontWeight: "bolder",
                  marginTop: "10px",
                }}
              >
                {colLeft3[0]}
              </p>
            </div>
            <div className={classes.desnaPodCelija}>
              <p>{colRight3[0].toFixed(2)}</p>
            </div>
          </div>

          <div className={classes.celija}>
            <div className={classes.levaPodCelija}>
              <p
                style={{
                  textAlign: "center",
                  fontWeight: "bolder",
                  marginTop: "10px",
                }}
              >
                {colLeft3[1]}
              </p>
            </div>
            <div className={classes.desnaPodCelija}>
              <p>{colRight3[1].toFixed(2)}</p>
            </div>
          </div>

          <div
            className={classes.poslednjaCelija}
            style={{
              borderLeft: "3px solid white",
              borderBottom: "3px solid white",
            }}
          >
            <div className={classes.levaPodCelijaPoslednjeCelije}>
              <p
                style={{
                  textAlign: "center",
                  fontWeight: "bolder",
                  marginTop: "10px",
                }}
              >
                {colLeft3[2]}
              </p>
            </div>
            <div className={classes.desnaPodCelijaPoslednjeCelije}>
              <p>{colRight3[2].toFixed(2)}</p>
            </div>
          </div>

          <div
            className={classes.celijaX}
            style={{ borderTop: "3px solid white" }}
          ></div>
          <div
            className={classes.celijaX}
            style={{ borderTop: "3px solid white" }}
          ></div>
          <div
            className={classes.celijaX}
            style={{ borderTop: "3px solid white" }}
          ></div>
          <div
            className={classes.celijaX}
            style={{ borderTop: "3px solid white" }}
          ></div>
          <div
            className={classes.celijaX}
            style={{ borderTop: "3px solid white" }}
          ></div>
          <div
            className={classes.celijaX}
            style={{ borderTop: "3px solid white" }}
          ></div>
          <div
            className={classes.celijaX}
            style={{ borderTop: "3px solid white" }}
          ></div>
          <div
            className={classes.celijaX}
            style={{ borderTop: "3px solid white" }}
          ></div>
          <div
            className={classes.celijaX}
            style={{ borderTop: "3px solid white" }}
          ></div>
          <div
            className={classes.celijaX}
            style={{ borderTop: "3px solid white" }}
          ></div>
          <div
            className={classes.celijaX}
            style={{ borderTop: "3px solid white" }}
          ></div>
          <div
            className={classes.celijaX}
            style={{ borderTop: "3px solid white" }}
          ></div>
          <div
            className={classes.celijaX}
            style={{ borderTop: "3px solid white" }}
          ></div>
        </div>

        <div
          className={classes.kolona}
          style={{ display: "flex", flexDirection: "column" }}
        >
          <div
            className={classes.naslovnaCelija}
            style={{ background: "darkblue", color: "white" }}
          >
            <p>4</p>
          </div>

          <div
            className={classes.celija}
            style={{ borderTop: "3px solid white" }}
            s
          >
            <div className={classes.levaPodCelija}>
              <p
                style={{
                  textAlign: "center",
                  fontWeight: "bolder",
                  marginTop: "10px",
                }}
              >
                {colLeft4[0]}
              </p>
            </div>
            <div className={classes.desnaPodCelija}>
              <p>{colRight4[0].toFixed(2)}</p>
            </div>
          </div>

          <div className={classes.celija}>
            <div className={classes.levaPodCelija}>
              <p
                style={{
                  textAlign: "center",
                  fontWeight: "bolder",
                  marginTop: "10px",
                }}
              >
                {colLeft4[1]}
              </p>
            </div>
            <div className={classes.desnaPodCelija}>
              <p>{colRight4[1].toFixed(2)}</p>
            </div>
          </div>

          <div className={classes.celija}>
            <div className={classes.levaPodCelija}>
              <p
                style={{
                  textAlign: "center",
                  fontWeight: "bolder",
                  marginTop: "10px",
                }}
              >
                {colLeft4[2]}
              </p>
            </div>
            <div className={classes.desnaPodCelija}>
              <p>{colRight4[2].toFixed(2)}</p>
            </div>
          </div>

          <div
            className={classes.poslednjaCelija}
            style={{
              borderLeft: "3px solid white",
              borderBottom: "3px solid white",
            }}
          >
            <div className={classes.levaPodCelijaPoslednjeCelije}>
              <p
                style={{
                  textAlign: "center",
                  fontWeight: "bolder",
                  marginTop: "10px",
                }}
              >
                {colLeft4[3]}
              </p>
            </div>
            <div className={classes.desnaPodCelijaPoslednjeCelije}>
              <p>{colRight4[3].toFixed(2)}</p>
            </div>
          </div>

          <div
            className={classes.celijaX}
            style={{ borderTop: "3px solid white" }}
          ></div>
          <div
            className={classes.celijaX}
            style={{ borderTop: "3px solid white" }}
          ></div>
          <div
            className={classes.celijaX}
            style={{ borderTop: "3px solid white" }}
          ></div>
          <div
            className={classes.celijaX}
            style={{ borderTop: "3px solid white" }}
          ></div>
          <div
            className={classes.celijaX}
            style={{ borderTop: "3px solid white" }}
          ></div>
          <div
            className={classes.celijaX}
            style={{ borderTop: "3px solid white" }}
          ></div>
          <div
            className={classes.celijaX}
            style={{ borderTop: "3px solid white" }}
          ></div>
          <div
            className={classes.celijaX}
            style={{ borderTop: "3px solid white" }}
          ></div>
          <div
            className={classes.celijaX}
            style={{ borderTop: "3px solid white" }}
          ></div>
          <div
            className={classes.celijaX}
            style={{ borderTop: "3px solid white" }}
          ></div>
          <div
            className={classes.celijaX}
            style={{ borderTop: "3px solid white" }}
          ></div>
          <div
            className={classes.celijaX}
            style={{ borderTop: "3px solid white" }}
          ></div>
        </div>

        <div
          className={classes.kolona}
          style={{ display: "flex", flexDirection: "column" }}
        >
          <div className={classes.naslovnaCelija} style={{ background: "red" }}>
            <p>5</p>
          </div>

          <div
            className={classes.celija}
            style={{ borderTop: "3px solid white" }}
          >
            <div className={classes.levaPodCelija}>
              <p
                style={{
                  textAlign: "center",
                  fontWeight: "bolder",
                  marginTop: "10px",
                }}
              >
                {colLeft5[0]}
              </p>
            </div>
            <div className={classes.desnaPodCelija}>
              <p>{colRight5[0].toFixed(2)}</p>
            </div>
          </div>

          <div className={classes.celija}>
            <div className={classes.levaPodCelija}>
              <p
                style={{
                  textAlign: "center",
                  fontWeight: "bolder",
                  marginTop: "10px",
                }}
              >
                {colLeft5[1]}
              </p>
            </div>
            <div className={classes.desnaPodCelija}>
              <p>{colRight5[1].toFixed(2)}</p>
            </div>
          </div>

          <div className={classes.celija}>
            <div className={classes.levaPodCelija}>
              <p
                style={{
                  textAlign: "center",
                  fontWeight: "bolder",
                  marginTop: "10px",
                }}
              >
                {colLeft5[2]}
              </p>
            </div>
            <div className={classes.desnaPodCelija}>
              <p>{colRight5[2].toFixed(2)}</p>
            </div>
          </div>

          <div className={classes.celija}>
            <div className={classes.levaPodCelija}>
              <p
                style={{
                  textAlign: "center",
                  fontWeight: "bolder",
                  marginTop: "10px",
                }}
              >
                {colLeft5[3]}
              </p>
            </div>
            <div className={classes.desnaPodCelija}>
              <p>{colRight5[3].toFixed(2)}</p>
            </div>
          </div>

          <div
            className={classes.poslednjaCelija}
            style={{
              borderLeft: "3px solid white",
              borderBottom: "3px solid white",
            }}
          >
            <div className={classes.levaPodCelijaPoslednjeCelije}>
              <p
                style={{
                  textAlign: "center",
                  fontWeight: "bolder",
                  marginTop: "10px",
                }}
              >
                {colLeft5[4]}
              </p>
            </div>
            <div className={classes.desnaPodCelijaPoslednjeCelije}>
              <p>{colRight5[4].toFixed(2)}</p>
            </div>
          </div>

          <div
            className={classes.celijaX}
            style={{ borderTop: "3px solid white" }}
          ></div>
          <div
            className={classes.celijaX}
            style={{ borderTop: "3px solid white" }}
          ></div>
          <div
            className={classes.celijaX}
            style={{ borderTop: "3px solid white" }}
          ></div>
          <div
            className={classes.celijaX}
            style={{ borderTop: "3px solid white" }}
          ></div>
          <div
            className={classes.celijaX}
            style={{ borderTop: "3px solid white" }}
          ></div>
          <div
            className={classes.celijaX}
            style={{ borderTop: "3px solid white" }}
          ></div>
          <div
            className={classes.celijaX}
            style={{ borderTop: "3px solid white" }}
          ></div>
          <div
            className={classes.celijaX}
            style={{ borderTop: "3px solid white" }}
          ></div>
          <div
            className={classes.celijaX}
            style={{ borderTop: "3px solid white" }}
          ></div>
          <div
            className={classes.celijaX}
            style={{ borderTop: "3px solid white" }}
          ></div>
          <div
            className={classes.celijaX}
            style={{ borderTop: "3px solid white" }}
          ></div>
        </div>

        <div
          className={classes.kolona}
          style={{ display: "flex", flexDirection: "column" }}
        >
          <div
            className={classes.naslovnaCelija}
            style={{ background: "darkblue", color: "white" }}
          >
            <p>6</p>
          </div>

          <div
            className={classes.celija}
            style={{ borderTop: "3px solid white" }}
          >
            <div className={classes.levaPodCelija}>
              <p
                style={{
                  textAlign: "center",
                  fontWeight: "bolder",
                  marginTop: "10px",
                }}
              >
                {colLeft6[0]}
              </p>
            </div>
            <div className={classes.desnaPodCelija}>
              <p>{colRight6[0].toFixed(2)}</p>
            </div>
          </div>

          <div className={classes.celija}>
            <div className={classes.levaPodCelija}>
              <p
                style={{
                  textAlign: "center",
                  fontWeight: "bolder",
                  marginTop: "10px",
                }}
              >
                {colLeft6[1]}
              </p>
            </div>
            <div className={classes.desnaPodCelija}>
              <p>{colRight6[1].toFixed(2)}</p>
            </div>
          </div>

          <div className={classes.celija}>
            <div className={classes.levaPodCelija}>
              <p
                style={{
                  textAlign: "center",
                  fontWeight: "bolder",
                  marginTop: "10px",
                }}
              >
                {colLeft6[2]}
              </p>
            </div>
            <div className={classes.desnaPodCelija}>
              <p>{colRight6[2].toFixed(2)}</p>
            </div>
          </div>

          <div className={classes.celija}>
            <div className={classes.levaPodCelija}>
              <p
                style={{
                  textAlign: "center",
                  fontWeight: "bolder",
                  marginTop: "10px",
                }}
              >
                {colLeft6[3]}
              </p>
            </div>
            <div className={classes.desnaPodCelija}>
              <p>{colRight6[3].toFixed(2)}</p>
            </div>
          </div>

          <div className={classes.celija}>
            <div className={classes.levaPodCelija}>
              <p
                style={{
                  textAlign: "center",
                  fontWeight: "bolder",
                  marginTop: "10px",
                }}
              >
                {colLeft6[4]}
              </p>
            </div>
            <div className={classes.desnaPodCelija}>
              <p>{colRight6[4].toFixed(2)}</p>
            </div>
          </div>

          <div
            className={classes.poslednjaCelija}
            style={{
              borderLeft: "3px solid white",
              borderBottom: "3px solid white",
            }}
          >
            <div className={classes.levaPodCelijaPoslednjeCelije}>
              <p
                style={{
                  textAlign: "center",
                  fontWeight: "bolder",
                  marginTop: "10px",
                }}
              >
                {colLeft6[5]}
              </p>
            </div>
            <div className={classes.desnaPodCelijaPoslednjeCelije}>
              <p>{colRight6[5].toFixed(2)}</p>
            </div>
          </div>

          <div
            className={classes.celijaX}
            style={{ borderTop: "3px solid white" }}
          ></div>
          <div
            className={classes.celijaX}
            style={{ borderTop: "3px solid white" }}
          ></div>
          <div
            className={classes.celijaX}
            style={{ borderTop: "3px solid white" }}
          ></div>
          <div
            className={classes.celijaX}
            style={{ borderTop: "3px solid white" }}
          ></div>
          <div
            className={classes.celijaX}
            style={{ borderTop: "3px solid white" }}
          ></div>
          <div
            className={classes.celijaX}
            style={{ borderTop: "3px solid white" }}
          ></div>
          <div
            className={classes.celijaX}
            style={{ borderTop: "3px solid white" }}
          ></div>
          <div
            className={classes.celijaX}
            style={{ borderTop: "3px solid white" }}
          ></div>
          <div
            className={classes.celijaX}
            style={{ borderTop: "3px solid white" }}
          ></div>
          <div
            className={classes.celijaX}
            style={{ borderTop: "3px solid white" }}
          ></div>
        </div>

        <div
          className={classes.kolona}
          style={{ display: "flex", flexDirection: "column" }}
        >
          <div className={classes.naslovnaCelija} style={{ background: "red" }}>
            <p>7</p>
          </div>

          <div
            className={classes.celija}
            style={{ borderTop: "3px solid white" }}
          >
            <div className={classes.levaPodCelija}>
              <p
                style={{
                  textAlign: "center",
                  fontWeight: "bolder",
                  marginTop: "10px",
                }}
              >
                {colLeft7[0]}
              </p>
            </div>
            <div className={classes.desnaPodCelija}>
              <p>{colRight7[0].toFixed(2)}</p>
            </div>
          </div>

          <div className={classes.celija}>
            <div className={classes.levaPodCelija}>
              <p
                style={{
                  textAlign: "center",
                  fontWeight: "bolder",
                  marginTop: "10px",
                }}
              >
                {colLeft7[1]}
              </p>
            </div>
            <div className={classes.desnaPodCelija}>
              <p>{colRight7[1].toFixed(2)}</p>
            </div>
          </div>

          <div className={classes.celija}>
            <div className={classes.levaPodCelija}>
              <p
                style={{
                  textAlign: "center",
                  fontWeight: "bolder",
                  marginTop: "10px",
                }}
              >
                {colLeft7[2]}
              </p>
            </div>
            <div className={classes.desnaPodCelija}>
              <p>{colRight7[2].toFixed(2)}</p>
            </div>
          </div>

          <div className={classes.celija}>
            <div className={classes.levaPodCelija}>
              <p
                style={{
                  textAlign: "center",
                  fontWeight: "bolder",
                  marginTop: "10px",
                }}
              >
                {colLeft7[3]}
              </p>
            </div>
            <div className={classes.desnaPodCelija}>
              <p>{colRight7[3].toFixed(2)}</p>
            </div>
          </div>

          <div className={classes.celija}>
            <div className={classes.levaPodCelija}>
              <p
                style={{
                  textAlign: "center",
                  fontWeight: "bolder",
                  marginTop: "10px",
                }}
              >
                {colLeft7[4]}
              </p>
            </div>
            <div className={classes.desnaPodCelija}>
              <p>{colRight7[4].toFixed(2)}</p>
            </div>
          </div>

          <div className={classes.celija}>
            <div className={classes.levaPodCelija}>
              <p
                style={{
                  textAlign: "center",
                  fontWeight: "bolder",
                  marginTop: "10px",
                }}
              >
                {colLeft7[5]}
              </p>
            </div>
            <div className={classes.desnaPodCelija}>
              <p>{colRight7[5].toFixed(2)}</p>
            </div>
          </div>

          <div
            className={classes.poslednjaCelija}
            style={{
              borderLeft: "3px solid white",
              borderBottom: "3px solid white",
            }}
          >
            <div className={classes.levaPodCelijaPoslednjeCelije}>
              <p
                style={{
                  textAlign: "center",
                  fontWeight: "bolder",
                  marginTop: "10px",
                }}
              >
                {colLeft7[6]}
              </p>
            </div>
            <div className={classes.desnaPodCelijaPoslednjeCelije}>
              <p>{colRight7[6].toFixed(2)}</p>
            </div>
          </div>

          <div
            className={classes.celijaX}
            style={{ borderTop: "3px solid white" }}
          ></div>
          <div
            className={classes.celijaX}
            style={{ borderTop: "3px solid white" }}
          ></div>
          <div
            className={classes.celijaX}
            style={{ borderTop: "3px solid white" }}
          ></div>
          <div
            className={classes.celijaX}
            style={{ borderTop: "3px solid white" }}
          ></div>
          <div
            className={classes.celijaX}
            style={{ borderTop: "3px solid white" }}
          ></div>
          <div
            className={classes.celijaX}
            style={{ borderTop: "3px solid white" }}
          ></div>
          <div
            className={classes.celijaX}
            style={{ borderTop: "3px solid white" }}
          ></div>
          <div
            className={classes.celijaX}
            style={{ borderTop: "3px solid white" }}
          ></div>
          <div
            className={classes.celijaX}
            style={{ borderTop: "3px solid white" }}
          ></div>
        </div>

        <div
          className={classes.kolona}
          style={{ display: "flex", flexDirection: "column" }}
        >
          <div
            className={classes.naslovnaCelija}
            style={{ background: "darkblue", color: "white" }}
          >
            <p>8</p>
          </div>

          <div
            className={classes.celija}
            style={{ borderTop: "3px solid white" }}
          >
            <div className={classes.levaPodCelija}>
              <p
                style={{
                  textAlign: "center",
                  fontWeight: "bolder",
                  marginTop: "10px",
                }}
              >
                {colLeft8[0]}
              </p>
            </div>
            <div className={classes.desnaPodCelija}>
              <p>{colRight8[0].toFixed(2)}</p>
            </div>
          </div>

          <div className={classes.celija}>
            <div className={classes.levaPodCelija}>
              <p
                style={{
                  textAlign: "center",
                  fontWeight: "bolder",
                  marginTop: "10px",
                }}
              >
                {colLeft8[1]}
              </p>
            </div>
            <div className={classes.desnaPodCelija}>
              <p>{colRight8[1].toFixed(2)}</p>
            </div>
          </div>

          <div className={classes.celija}>
            <div className={classes.levaPodCelija}>
              <p
                style={{
                  textAlign: "center",
                  fontWeight: "bolder",
                  marginTop: "10px",
                }}
              >
                {colLeft8[2]}
              </p>
            </div>
            <div className={classes.desnaPodCelija}>
              <p>{colRight8[2].toFixed(2)}</p>
            </div>
          </div>

          <div className={classes.celija}>
            <div className={classes.levaPodCelija}>
              <p
                style={{
                  textAlign: "center",
                  fontWeight: "bolder",
                  marginTop: "10px",
                }}
              >
                {colLeft8[3]}
              </p>
            </div>
            <div className={classes.desnaPodCelija}>
              <p>{colRight8[3].toFixed(2)}</p>
            </div>
          </div>

          <div className={classes.celija}>
            <div className={classes.levaPodCelija}>
              <p
                style={{
                  textAlign: "center",
                  fontWeight: "bolder",
                  marginTop: "10px",
                }}
              >
                {colLeft8[4]}
              </p>
            </div>
            <div className={classes.desnaPodCelija}>
              <p>{colRight8[4].toFixed(2)}</p>
            </div>
          </div>

          <div className={classes.celija}>
            <div className={classes.levaPodCelija}>
              <p
                style={{
                  textAlign: "center",
                  fontWeight: "bolder",
                  marginTop: "10px",
                }}
              >
                {colLeft8[5]}
              </p>
            </div>
            <div className={classes.desnaPodCelija}>
              <p>{colRight8[5].toFixed(2)}</p>
            </div>
          </div>

          <div className={classes.celija}>
            <div className={classes.levaPodCelija}>
              <p
                style={{
                  textAlign: "center",
                  fontWeight: "bolder",
                  marginTop: "10px",
                }}
              >
                {colLeft8[6]}
              </p>
            </div>
            <div className={classes.desnaPodCelija}>
              <p>{colRight8[6].toFixed(2)}</p>
            </div>
          </div>

          <div
            className={classes.poslednjaCelija}
            style={{
              borderLeft: "3px solid white",
              borderBottom: "3px solid white",
            }}
          >
            <div className={classes.levaPodCelijaPoslednjeCelije}>
              <p
                style={{
                  textAlign: "center",
                  fontWeight: "bolder",
                  marginTop: "10px",
                }}
              >
                {colLeft8[7]}
              </p>
            </div>
            <div className={classes.desnaPodCelijaPoslednjeCelije}>
              <p>{colRight8[7].toFixed(2)}</p>
            </div>
          </div>

          <div
            className={classes.celijaX}
            style={{ borderTop: "3px solid white" }}
          ></div>
          <div
            className={classes.celijaX}
            style={{ borderTop: "3px solid white" }}
          ></div>
          <div
            className={classes.celijaX}
            style={{ borderTop: "3px solid white" }}
          ></div>
          <div
            className={classes.celijaX}
            style={{ borderTop: "3px solid white" }}
          ></div>
          <div
            className={classes.celijaX}
            style={{ borderTop: "3px solid white" }}
          ></div>
          <div
            className={classes.celijaX}
            style={{ borderTop: "3px solid white" }}
          ></div>
          <div
            className={classes.celijaX}
            style={{ borderTop: "3px solid white" }}
          ></div>
          <div
            className={classes.celijaX}
            style={{ borderTop: "3px solid white" }}
          ></div>
        </div>

        <div
          className={classes.kolona}
          style={{ display: "flex", flexDirection: "column" }}
        >
          <div className={classes.naslovnaCelija} style={{ background: "red" }}>
            <p>9</p>
          </div>

          <div
            className={classes.celija}
            style={{ borderTop: "3px solid white" }}
          >
            <div className={classes.levaPodCelija}>
              <p
                style={{
                  textAlign: "center",
                  fontWeight: "bolder",
                  marginTop: "10px",
                }}
              >
                {colLeft9[0]}
              </p>
            </div>
            <div className={classes.desnaPodCelija}>
              <p>{colRight9[0].toFixed(2)}</p>
            </div>
          </div>

          <div className={classes.celija}>
            <div className={classes.levaPodCelija}>
              <p
                style={{
                  textAlign: "center",
                  fontWeight: "bolder",
                  marginTop: "10px",
                }}
              >
                {colLeft9[1]}
              </p>
            </div>
            <div className={classes.desnaPodCelija}>
              <p>{colRight9[1].toFixed(2)}</p>
            </div>
          </div>

          <div className={classes.celija}>
            <div className={classes.levaPodCelija}>
              <p
                style={{
                  textAlign: "center",
                  fontWeight: "bolder",
                  marginTop: "10px",
                }}
              >
                {colLeft9[2]}
              </p>
            </div>
            <div className={classes.desnaPodCelija}>
              <p>{colRight9[2].toFixed(2)}</p>
            </div>
          </div>

          <div className={classes.celija}>
            <div className={classes.levaPodCelija}>
              <p
                style={{
                  textAlign: "center",
                  fontWeight: "bolder",
                  marginTop: "10px",
                }}
              >
                {colLeft9[3]}
              </p>
            </div>
            <div className={classes.desnaPodCelija}>
              <p>{colRight9[3].toFixed(2)}</p>
            </div>
          </div>

          <div className={classes.celija}>
            <div className={classes.levaPodCelija}>
              <p
                style={{
                  textAlign: "center",
                  fontWeight: "bolder",
                  marginTop: "10px",
                }}
              >
                {colLeft9[4]}
              </p>
            </div>
            <div className={classes.desnaPodCelija}>
              <p>{colRight9[4].toFixed(2)}</p>
            </div>
          </div>

          <div className={classes.celija}>
            <div className={classes.levaPodCelija}>
              <p
                style={{
                  textAlign: "center",
                  fontWeight: "bolder",
                  marginTop: "10px",
                }}
              >
                {colLeft9[5]}
              </p>
            </div>
            <div className={classes.desnaPodCelija}>
              <p>{colRight9[5].toFixed(2)}</p>
            </div>
          </div>

          <div className={classes.celija}>
            <div className={classes.levaPodCelija}>
              <p
                style={{
                  textAlign: "center",
                  fontWeight: "bolder",
                  marginTop: "10px",
                }}
              >
                {colLeft9[6]}
              </p>
            </div>
            <div className={classes.desnaPodCelija}>
              <p>{colRight9[6].toFixed(2)}</p>
            </div>
          </div>

          <div className={classes.celija}>
            <div className={classes.levaPodCelija}>
              <p
                style={{
                  textAlign: "center",
                  fontWeight: "bolder",
                  marginTop: "10px",
                }}
              >
                {colLeft9[7]}
              </p>
            </div>
            <div className={classes.desnaPodCelija}>
              <p>{colRight9[7].toFixed(2)}</p>
            </div>
          </div>

          <div
            className={classes.poslednjaCelija}
            style={{
              borderLeft: "3px solid white",
              borderBottom: "3px solid white",
            }}
          >
            <div className={classes.levaPodCelijaPoslednjeCelije}>
              <p
                style={{
                  textAlign: "center",
                  fontWeight: "bolder",
                  marginTop: "10px",
                }}
              >
                {colLeft9[8]}
              </p>
            </div>
            <div className={classes.desnaPodCelijaPoslednjeCelije}>
              <p>{colRight9[8].toFixed(2)}</p>
            </div>
          </div>

          <div
            className={classes.celijaX}
            style={{ borderTop: "3px solid white" }}
          ></div>
          <div
            className={classes.celijaX}
            style={{ borderTop: "3px solid white" }}
          ></div>
          <div
            className={classes.celijaX}
            style={{ borderTop: "3px solid white" }}
          ></div>
          <div
            className={classes.celijaX}
            style={{ borderTop: "3px solid white" }}
          ></div>
          <div
            className={classes.celijaX}
            style={{ borderTop: "3px solid white" }}
          ></div>
          <div
            className={classes.celijaX}
            style={{ borderTop: "3px solid white" }}
          ></div>
          <div
            className={classes.celijaX}
            style={{ borderTop: "3px solid white" }}
          ></div>
        </div>

        <div
          className={classes.kolona}
          style={{ display: "flex", flexDirection: "column" }}
        >
          <div
            className={classes.naslovnaCelija}
            style={{ background: "darkblue", color: "white" }}
          >
            <p>10</p>
          </div>

          <div
            className={classes.celija}
            style={{ borderTop: "3px solid white" }}
            s
          >
            <div className={classes.levaPodCelija}>
              <p
                style={{
                  textAlign: "center",
                  fontWeight: "bolder",
                  marginTop: "10px",
                }}
              >
                {colLeft10[0]}
              </p>
            </div>
            <div className={classes.desnaPodCelija}>
              <p>{colRight10[0].toFixed(2)}</p>
            </div>
          </div>

          <div className={classes.celija}>
            <div className={classes.levaPodCelija}>
              <p
                style={{
                  textAlign: "center",
                  fontWeight: "bolder",
                  marginTop: "10px",
                }}
              >
                {colLeft10[1]}
              </p>
            </div>
            <div className={classes.desnaPodCelija}>
              <p>{colRight10[1].toFixed(2)}</p>
            </div>
          </div>

          <div className={classes.celija}>
            <div className={classes.levaPodCelija}>
              <p
                style={{
                  textAlign: "center",
                  fontWeight: "bolder",
                  marginTop: "10px",
                }}
              >
                {colLeft10[2]}
              </p>
            </div>
            <div className={classes.desnaPodCelija}>
              <p>{colRight10[2].toFixed(2)}</p>
            </div>
          </div>

          <div className={classes.celija}>
            <div className={classes.levaPodCelija}>
              <p
                style={{
                  textAlign: "center",
                  fontWeight: "bolder",
                  marginTop: "10px",
                }}
              >
                {colLeft10[3]}
              </p>
            </div>
            <div className={classes.desnaPodCelija}>
              <p>{colRight10[3].toFixed(2)}</p>
            </div>
          </div>

          <div className={classes.celija}>
            <div className={classes.levaPodCelija}>
              <p
                style={{
                  textAlign: "center",
                  fontWeight: "bolder",
                  marginTop: "10px",
                }}
              >
                {colLeft10[4]}
              </p>
            </div>
            <div className={classes.desnaPodCelija}>
              <p>{colRight10[4].toFixed(2)}</p>
            </div>
          </div>

          <div className={classes.celija}>
            <div className={classes.levaPodCelija}>
              <p
                style={{
                  textAlign: "center",
                  fontWeight: "bolder",
                  marginTop: "10px",
                }}
              >
                {colLeft10[5]}
              </p>
            </div>
            <div className={classes.desnaPodCelija}>
              <p>{colRight10[5].toFixed(2)}</p>
            </div>
          </div>

          <div className={classes.celija}>
            <div className={classes.levaPodCelija}>
              <p
                style={{
                  textAlign: "center",
                  fontWeight: "bolder",
                  marginTop: "10px",
                }}
              >
                {colLeft10[6]}
              </p>
            </div>
            <div className={classes.desnaPodCelija}>
              <p>{colRight10[6].toFixed(2)}</p>
            </div>
          </div>

          <div className={classes.celija}>
            <div className={classes.levaPodCelija}>
              <p
                style={{
                  textAlign: "center",
                  fontWeight: "bolder",
                  marginTop: "10px",
                }}
              >
                {colLeft10[7]}
              </p>
            </div>
            <div className={classes.desnaPodCelija}>
              <p>{colRight10[7].toFixed(2)}</p>
            </div>
          </div>

          <div className={classes.celija}>
            <div className={classes.levaPodCelija}>
              <p
                style={{
                  textAlign: "center",
                  fontWeight: "bolder",
                  marginTop: "10px",
                }}
              >
                {colLeft10[8]}
              </p>
            </div>
            <div className={classes.desnaPodCelija}>
              <p>{colRight10[8].toFixed(2)}</p>
            </div>
          </div>

          <div
            className={classes.poslednjaCelija}
            style={{
              borderLeft: "3px solid white",
              borderBottom: "3px solid white",
            }}
          >
            <div className={classes.levaPodCelijaPoslednjeCelije}>
              <p
                style={{
                  textAlign: "center",
                  fontWeight: "bolder",
                  marginTop: "10px",
                }}
              >
                {colLeft10[9]}
              </p>
            </div>
            <div className={classes.desnaPodCelijaPoslednjeCelije}>
              <p>{colRight10[9].toFixed(2)}</p>
            </div>
          </div>

          <div
            className={classes.celijaX}
            style={{ borderTop: "3px solid white" }}
          ></div>
          <div
            className={classes.celijaX}
            style={{ borderTop: "3px solid white" }}
          ></div>
          <div
            className={classes.celijaX}
            style={{ borderTop: "3px solid white" }}
          ></div>
          <div
            className={classes.celijaX}
            style={{ borderTop: "3px solid white" }}
          ></div>
          <div
            className={classes.celijaX}
            style={{ borderTop: "3px solid white" }}
          ></div>
          <div
            className={classes.celijaX}
            style={{ borderTop: "3px solid white" }}
          ></div>
        </div>

        <div
          className={classes.kolona}
          style={{ display: "flex", flexDirection: "column" }}
        >
          <div className={classes.naslovnaCelija} style={{ background: "red" }}>
            <p>11</p>
          </div>

          <div
            className={classes.celija}
            style={{ borderTop: "3px solid white" }}
          >
            <div className={classes.levaPodCelija}>
              <p
                style={{
                  textAlign: "center",
                  fontWeight: "bolder",
                  marginTop: "10px",
                }}
              >
                {colLeft11[0]}
              </p>
            </div>
            <div className={classes.desnaPodCelija}>
              <p>{colRight11[0].toFixed(2)}</p>
            </div>
          </div>

          <div className={classes.celija}>
            <div className={classes.levaPodCelija}>
              <p
                style={{
                  textAlign: "center",
                  fontWeight: "bolder",
                  marginTop: "10px",
                }}
              >
                {colLeft11[1]}
              </p>
            </div>
            <div className={classes.desnaPodCelija}>
              <p>{colRight11[1].toFixed(2)}</p>
            </div>
          </div>

          <div className={classes.celija}>
            <div className={classes.levaPodCelija}>
              <p
                style={{
                  textAlign: "center",
                  fontWeight: "bolder",
                  marginTop: "10px",
                }}
              >
                {colLeft11[2]}
              </p>
            </div>
            <div className={classes.desnaPodCelija}>
              <p>{colRight11[2].toFixed(2)}</p>
            </div>
          </div>

          <div className={classes.celija}>
            <div className={classes.levaPodCelija}>
              <p
                style={{
                  textAlign: "center",
                  fontWeight: "bolder",
                  marginTop: "10px",
                }}
              >
                {colLeft11[3]}
              </p>
            </div>
            <div className={classes.desnaPodCelija}>
              <p>{colRight11[3].toFixed(2)}</p>
            </div>
          </div>

          <div className={classes.celija}>
            <div className={classes.levaPodCelija}>
              <p
                style={{
                  textAlign: "center",
                  fontWeight: "bolder",
                  marginTop: "10px",
                }}
              >
                {colLeft11[4]}
              </p>
            </div>
            <div className={classes.desnaPodCelija}>
              <p>{colRight11[4].toFixed(2)}</p>
            </div>
          </div>

          <div className={classes.celija}>
            <div className={classes.levaPodCelija}>
              <p
                style={{
                  textAlign: "center",
                  fontWeight: "bolder",
                  marginTop: "10px",
                }}
              >
                {colLeft11[5]}
              </p>
            </div>
            <div className={classes.desnaPodCelija}>
              <p>{colRight11[5].toFixed(2)}</p>
            </div>
          </div>

          <div className={classes.celija}>
            <div className={classes.levaPodCelija}>
              <p
                style={{
                  textAlign: "center",
                  fontWeight: "bolder",
                  marginTop: "10px",
                }}
              >
                {colLeft11[6]}
              </p>
            </div>
            <div className={classes.desnaPodCelija}>
              <p>{colRight11[6].toFixed(2)}</p>
            </div>
          </div>

          <div className={classes.celija}>
            <div className={classes.levaPodCelija}>
              <p
                style={{
                  textAlign: "center",
                  fontWeight: "bolder",
                  marginTop: "10px",
                }}
              >
                {colLeft11[7]}
              </p>
            </div>
            <div className={classes.desnaPodCelija}>
              <p>{colRight11[7].toFixed(2)}</p>
            </div>
          </div>

          <div className={classes.celija}>
            <div className={classes.levaPodCelija}>
              <p
                style={{
                  textAlign: "center",
                  fontWeight: "bolder",
                  marginTop: "10px",
                }}
              >
                {colLeft11[8]}
              </p>
            </div>
            <div className={classes.desnaPodCelija}>
              <p>{colRight11[8].toFixed(2)}</p>
            </div>
          </div>

          <div className={classes.celija}>
            <div className={classes.levaPodCelija}>
              <p
                style={{
                  textAlign: "center",
                  fontWeight: "bolder",
                  marginTop: "10px",
                }}
              >
                {colLeft11[9]}
              </p>
            </div>
            <div className={classes.desnaPodCelija}>
              <p>{colRight11[9].toFixed(2)}</p>
            </div>
          </div>

          <div
            className={classes.poslednjaCelija}
            style={{
              borderLeft: "3px solid white",
              borderBottom: "3px solid white",
            }}
          >
            <div className={classes.levaPodCelijaPoslednjeCelije}>
              <p
                style={{
                  textAlign: "center",
                  fontWeight: "bolder",
                  marginTop: "10px",
                }}
              >
                {colLeft11[10]}
              </p>
            </div>
            <div className={classes.desnaPodCelijaPoslednjeCelije}>
              <p>{colRight11[10].toFixed(2)}</p>
            </div>
          </div>

          <div
            className={classes.celijaX}
            style={{ borderTop: "3px solid white" }}
          ></div>
          <div
            className={classes.celijaX}
            style={{ borderTop: "3px solid white" }}
          ></div>
          <div
            className={classes.celijaX}
            style={{ borderTop: "3px solid white" }}
          ></div>
          <div
            className={classes.celijaX}
            style={{ borderTop: "3px solid white" }}
          ></div>
          <div
            className={classes.celijaX}
            style={{ borderTop: "3px solid white" }}
          ></div>
        </div>

        <div
          className={classes.kolona}
          style={{ display: "flex", flexDirection: "column" }}
        >
          <div
            className={classes.naslovnaCelija}
            style={{ background: "darkblue", color: "white" }}
          >
            <p>12</p>
          </div>

          <div
            className={classes.celija}
            style={{ borderTop: "3px solid white" }}
          >
            <div className={classes.levaPodCelija}>
              <p
                style={{
                  textAlign: "center",
                  fontWeight: "bolder",
                  marginTop: "10px",
                }}
              >
                {colLeft12[0]}
              </p>
            </div>
            <div className={classes.desnaPodCelija}>
              <p>{colRight12[0].toFixed(2)}</p>
            </div>
          </div>

          <div className={classes.celija}>
            <div className={classes.levaPodCelija}>
              <p
                style={{
                  textAlign: "center",
                  fontWeight: "bolder",
                  marginTop: "10px",
                }}
              >
                {colLeft12[1]}
              </p>
            </div>
            <div className={classes.desnaPodCelija}>
              <p>{colRight12[1].toFixed(2)}</p>
            </div>
          </div>

          <div className={classes.celija}>
            <div className={classes.levaPodCelija}>
              <p
                style={{
                  textAlign: "center",
                  fontWeight: "bolder",
                  marginTop: "10px",
                }}
              >
                {colLeft12[2]}
              </p>
            </div>
            <div className={classes.desnaPodCelija}>
              <p>{colRight12[2].toFixed(2)}</p>
            </div>
          </div>

          <div className={classes.celija}>
            <div className={classes.levaPodCelija}>
              <p
                style={{
                  textAlign: "center",
                  fontWeight: "bolder",
                  marginTop: "10px",
                }}
              >
                {colLeft12[3]}
              </p>
            </div>
            <div className={classes.desnaPodCelija}>
              <p>{colRight12[3].toFixed(2)}</p>
            </div>
          </div>

          <div className={classes.celija}>
            <div className={classes.levaPodCelija}>
              <p
                style={{
                  textAlign: "center",
                  fontWeight: "bolder",
                  marginTop: "10px",
                }}
              >
                {colLeft12[4]}
              </p>
            </div>
            <div className={classes.desnaPodCelija}>
              <p>{colRight12[4].toFixed(2)}</p>
            </div>
          </div>

          <div className={classes.celija}>
            <div className={classes.levaPodCelija}>
              <p
                style={{
                  textAlign: "center",
                  fontWeight: "bolder",
                  marginTop: "10px",
                }}
              >
                {colLeft12[5]}
              </p>
            </div>
            <div className={classes.desnaPodCelija}>
              <p>{colRight12[5].toFixed(2)}</p>
            </div>
          </div>

          <div className={classes.celija}>
            <div className={classes.levaPodCelija}>
              <p
                style={{
                  textAlign: "center",
                  fontWeight: "bolder",
                  marginTop: "10px",
                }}
              >
                {colLeft12[6]}
              </p>
            </div>
            <div className={classes.desnaPodCelija}>
              <p>{colRight12[6].toFixed(2)}</p>
            </div>
          </div>

          <div className={classes.celija}>
            <div className={classes.levaPodCelija}>
              <p
                style={{
                  textAlign: "center",
                  fontWeight: "bolder",
                  marginTop: "10px",
                }}
              >
                {colLeft12[7]}
              </p>
            </div>
            <div className={classes.desnaPodCelija}>
              <p>{colRight12[7].toFixed(2)}</p>
            </div>
          </div>

          <div className={classes.celija}>
            <div className={classes.levaPodCelija}>
              <p
                style={{
                  textAlign: "center",
                  fontWeight: "bolder",
                  marginTop: "10px",
                }}
              >
                {colLeft12[8]}
              </p>
            </div>
            <div className={classes.desnaPodCelija}>
              <p>{colRight12[8].toFixed(2)}</p>
            </div>
          </div>

          <div className={classes.celija}>
            <div className={classes.levaPodCelija}>
              <p
                style={{
                  textAlign: "center",
                  fontWeight: "bolder",
                  marginTop: "10px",
                }}
              >
                {colLeft12[9]}
              </p>
            </div>
            <div className={classes.desnaPodCelija}>
              <p>{colRight12[9].toFixed(2)}</p>
            </div>
          </div>

          <div className={classes.celija}>
            <div className={classes.levaPodCelija}>
              <p
                style={{
                  textAlign: "center",
                  fontWeight: "bolder",
                  marginTop: "10px",
                }}
              >
                {colLeft12[10]}
              </p>
            </div>
            <div className={classes.desnaPodCelija}>
              <p>{colRight12[10].toFixed(2)}</p>
            </div>
          </div>

          <div
            className={classes.poslednjaCelija}
            style={{
              borderLeft: "3px solid white",
              borderBottom: "3px solid white",
            }}
          >
            <div className={classes.levaPodCelijaPoslednjeCelije}>
              <p
                style={{
                  textAlign: "center",
                  fontWeight: "bolder",
                  marginTop: "10px",
                }}
              >
                {colLeft12[11]}
              </p>
            </div>
            <div className={classes.desnaPodCelijaPoslednjeCelije}>
              <p>{colRight12[11].toFixed(2)}</p>
            </div>
          </div>

          <div
            className={classes.celijaX}
            style={{ borderTop: "3px solid white" }}
          ></div>
          <div
            className={classes.celijaX}
            style={{ borderTop: "3px solid white" }}
          ></div>
          <div
            className={classes.celijaX}
            style={{ borderTop: "3px solid white" }}
          ></div>
          <div
            className={classes.celijaX}
            style={{ borderTop: "3px solid white" }}
          ></div>
        </div>

        <div
          className={classes.kolona}
          style={{ display: "flex", flexDirection: "column" }}
        >
          <div className={classes.naslovnaCelija} style={{ background: "red" }}>
            <p>13</p>
          </div>

          <div
            className={classes.celija}
            style={{ borderTop: "3px solid white" }}
          >
            <div className={classes.levaPodCelija}>
              <p
                style={{
                  textAlign: "center",
                  fontWeight: "bolder",
                  marginTop: "10px",
                }}
              >
                {colLeft13[0]}
              </p>
            </div>
            <div className={classes.desnaPodCelija}>
              <p>{colRight13[0].toFixed(2)}</p>
            </div>
          </div>

          <div className={classes.celija}>
            <div className={classes.levaPodCelija}>
              <p
                style={{
                  textAlign: "center",
                  fontWeight: "bolder",
                  marginTop: "10px",
                }}
              >
                {colLeft13[1]}
              </p>
            </div>
            <div className={classes.desnaPodCelija}>
              <p>{colRight13[1].toFixed(2)}</p>
            </div>
          </div>

          <div className={classes.celija}>
            <div className={classes.levaPodCelija}>
              <p
                style={{
                  textAlign: "center",
                  fontWeight: "bolder",
                  marginTop: "10px",
                }}
              >
                {colLeft13[2]}
              </p>
            </div>
            <div className={classes.desnaPodCelija}>
              <p>{colRight13[2].toFixed(2)}</p>
            </div>
          </div>

          <div className={classes.celija}>
            <div className={classes.levaPodCelija}>
              <p
                style={{
                  textAlign: "center",
                  fontWeight: "bolder",
                  marginTop: "10px",
                }}
              >
                {colLeft13[3]}
              </p>
            </div>
            <div className={classes.desnaPodCelija}>
              <p>{colRight13[3].toFixed(2)}</p>
            </div>
          </div>

          <div className={classes.celija}>
            <div className={classes.levaPodCelija}>
              <p
                style={{
                  textAlign: "center",
                  fontWeight: "bolder",
                  marginTop: "10px",
                }}
              >
                {colLeft13[4]}
              </p>
            </div>
            <div className={classes.desnaPodCelija}>
              <p>{colRight13[4].toFixed(2)}</p>
            </div>
          </div>

          <div className={classes.celija}>
            <div className={classes.levaPodCelija}>
              <p
                style={{
                  textAlign: "center",
                  fontWeight: "bolder",
                  marginTop: "10px",
                }}
              >
                {colLeft13[5]}
              </p>
            </div>
            <div className={classes.desnaPodCelija}>
              <p>{colRight13[5].toFixed(2)}</p>
            </div>
          </div>

          <div className={classes.celija}>
            <div className={classes.levaPodCelija}>
              <p
                style={{
                  textAlign: "center",
                  fontWeight: "bolder",
                  marginTop: "10px",
                }}
              >
                {colLeft13[6]}
              </p>
            </div>
            <div className={classes.desnaPodCelija}>
              <p>{colRight13[6].toFixed(2)}</p>
            </div>
          </div>

          <div className={classes.celija}>
            <div className={classes.levaPodCelija}>
              <p
                style={{
                  textAlign: "center",
                  fontWeight: "bolder",
                  marginTop: "10px",
                }}
              >
                {colLeft13[7]}
              </p>
            </div>
            <div className={classes.desnaPodCelija}>
              <p>{colRight13[7].toFixed(2)}</p>
            </div>
          </div>

          <div className={classes.celija}>
            <div className={classes.levaPodCelija}>
              <p
                style={{
                  textAlign: "center",
                  fontWeight: "bolder",
                  marginTop: "10px",
                }}
              >
                {colLeft13[8]}
              </p>
            </div>
            <div className={classes.desnaPodCelija}>
              <p>{colRight13[8].toFixed(2)}</p>
            </div>
          </div>

          <div className={classes.celija}>
            <div className={classes.levaPodCelija}>
              <p
                style={{
                  textAlign: "center",
                  fontWeight: "bolder",
                  marginTop: "10px",
                }}
              >
                {colLeft13[9]}
              </p>
            </div>
            <div className={classes.desnaPodCelija}>
              <p>{colRight13[9].toFixed(2)}</p>
            </div>
          </div>

          <div className={classes.celija}>
            <div className={classes.levaPodCelija}>
              <p
                style={{
                  textAlign: "center",
                  fontWeight: "bolder",
                  marginTop: "10px",
                }}
              >
                {colLeft13[10]}
              </p>
            </div>
            <div className={classes.desnaPodCelija}>
              <p>{colRight13[10].toFixed(2)}</p>
            </div>
          </div>

          <div className={classes.celija}>
            <div className={classes.levaPodCelija}>
              <p
                style={{
                  textAlign: "center",
                  fontWeight: "bolder",
                  marginTop: "10px",
                }}
              >
                {colLeft13[11]}
              </p>
            </div>
            <div className={classes.desnaPodCelija}>
              <p>{colRight13[11].toFixed(2)}</p>
            </div>
          </div>

          <div
            className={classes.poslednjaCelija}
            style={{
              borderLeft: "3px solid white",
              borderBottom: "3px solid white",
            }}
          >
            <div className={classes.levaPodCelijaPoslednjeCelije}>
              <p
                style={{
                  textAlign: "center",
                  fontWeight: "bolder",
                  marginTop: "10px",
                }}
              >
                {colLeft13[12]}
              </p>
            </div>
            <div className={classes.desnaPodCelijaPoslednjeCelije}>
              <p>{colRight13[12].toFixed(2)}</p>
            </div>
          </div>

          <div
            className={classes.celijaX}
            style={{ borderTop: "3px solid white" }}
          ></div>
          <div
            className={classes.celijaX}
            style={{ borderTop: "3px solid white" }}
          ></div>
          <div
            className={classes.celijaX}
            style={{ borderTop: "3px solid white" }}
          ></div>
        </div>

        <div
          className={classes.kolona}
          style={{ display: "flex", flexDirection: "column" }}
        >
          <div
            className={classes.naslovnaCelija}
            style={{ background: "darkblue", color: "white" }}
          >
            <p>14</p>
          </div>

          <div
            className={classes.celija}
            style={{ borderTop: "3px solid white" }}
            s
          >
            <div className={classes.levaPodCelija}>
              <p
                style={{
                  textAlign: "center",
                  fontWeight: "bolder",
                  marginTop: "10px",
                }}
              >
                {colLeft14[0]}
              </p>
            </div>
            <div className={classes.desnaPodCelija}>
              <p>{colRight14[0].toFixed(2)}</p>
            </div>
          </div>

          <div className={classes.celija}>
            <div className={classes.levaPodCelija}>
              <p
                style={{
                  textAlign: "center",
                  fontWeight: "bolder",
                  marginTop: "10px",
                }}
              >
                {colLeft14[1]}
              </p>
            </div>
            <div className={classes.desnaPodCelija}>
              <p>{colRight14[1].toFixed(2)}</p>
            </div>
          </div>

          <div className={classes.celija}>
            <div className={classes.levaPodCelija}>
              <p
                style={{
                  textAlign: "center",
                  fontWeight: "bolder",
                  marginTop: "10px",
                }}
              >
                {colLeft14[2]}
              </p>
            </div>
            <div className={classes.desnaPodCelija}>
              <p>{colRight14[2].toFixed(2)}</p>
            </div>
          </div>

          <div className={classes.celija}>
            <div className={classes.levaPodCelija}>
              <p
                style={{
                  textAlign: "center",
                  fontWeight: "bolder",
                  marginTop: "10px",
                }}
              >
                {colLeft14[3]}
              </p>
            </div>
            <div className={classes.desnaPodCelija}>
              <p>{colRight14[3].toFixed(2)}</p>
            </div>
          </div>

          <div className={classes.celija}>
            <div className={classes.levaPodCelija}>
              <p
                style={{
                  textAlign: "center",
                  fontWeight: "bolder",
                  marginTop: "10px",
                }}
              >
                {colLeft14[4]}
              </p>
            </div>
            <div className={classes.desnaPodCelija}>
              <p>{colRight14[4].toFixed(2)}</p>
            </div>
          </div>

          <div className={classes.celija}>
            <div className={classes.levaPodCelija}>
              <p
                style={{
                  textAlign: "center",
                  fontWeight: "bolder",
                  marginTop: "10px",
                }}
              >
                {colLeft14[5]}
              </p>
            </div>
            <div className={classes.desnaPodCelija}>
              <p>{colRight14[5].toFixed(2)}</p>
            </div>
          </div>

          <div className={classes.celija}>
            <div className={classes.levaPodCelija}>
              <p
                style={{
                  textAlign: "center",
                  fontWeight: "bolder",
                  marginTop: "10px",
                }}
              >
                {colLeft14[6]}
              </p>
            </div>
            <div className={classes.desnaPodCelija}>
              <p>{colRight14[6].toFixed(2)}</p>
            </div>
          </div>

          <div className={classes.celija}>
            <div className={classes.levaPodCelija}>
              <p
                style={{
                  textAlign: "center",
                  fontWeight: "bolder",
                  marginTop: "10px",
                }}
              >
                {colLeft14[7]}
              </p>
            </div>
            <div className={classes.desnaPodCelija}>
              <p>{colRight14[7].toFixed(2)}</p>
            </div>
          </div>

          <div className={classes.celija}>
            <div className={classes.levaPodCelija}>
              <p
                style={{
                  textAlign: "center",
                  fontWeight: "bolder",
                  marginTop: "10px",
                }}
              >
                {colLeft14[8]}
              </p>
            </div>
            <div className={classes.desnaPodCelija}>
              <p>{colRight14[8].toFixed(2)}</p>
            </div>
          </div>

          <div className={classes.celija}>
            <div className={classes.levaPodCelija}>
              <p
                style={{
                  textAlign: "center",
                  fontWeight: "bolder",
                  marginTop: "10px",
                }}
              >
                {colLeft14[9]}
              </p>
            </div>
            <div className={classes.desnaPodCelija}>
              <p>{colRight14[9].toFixed(2)}</p>
            </div>
          </div>

          <div className={classes.celija}>
            <div className={classes.levaPodCelija}>
              <p
                style={{
                  textAlign: "center",
                  fontWeight: "bolder",
                  marginTop: "10px",
                }}
              >
                {colLeft14[10]}
              </p>
            </div>
            <div className={classes.desnaPodCelija}>
              <p>{colRight14[10].toFixed(2)}</p>
            </div>
          </div>

          <div className={classes.celija}>
            <div className={classes.levaPodCelija}>
              <p
                style={{
                  textAlign: "center",
                  fontWeight: "bolder",
                  marginTop: "10px",
                }}
              >
                {colLeft14[11]}
              </p>
            </div>
            <div className={classes.desnaPodCelija}>
              <p>{colRight14[11].toFixed(2)}</p>
            </div>
          </div>

          <div className={classes.celija}>
            <div className={classes.levaPodCelija}>
              <p
                style={{
                  textAlign: "center",
                  fontWeight: "bolder",
                  marginTop: "10px",
                }}
              >
                {colLeft14[12]}
              </p>
            </div>
            <div className={classes.desnaPodCelija}>
              <p>{colRight14[12].toFixed(2)}</p>
            </div>
          </div>

          <div
            className={classes.poslednjaCelija}
            style={{
              borderLeft: "3px solid white",
              borderBottom: "3px solid white",
            }}
          >
            <div className={classes.levaPodCelijaPoslednjeCelije}>
              <p
                style={{
                  textAlign: "center",
                  fontWeight: "bolder",
                  marginTop: "10px",
                }}
              >
                {colLeft14[13]}
              </p>
            </div>
            <div className={classes.desnaPodCelijaPoslednjeCelije}>
              <p>{colRight14[13].toFixed(2)}</p>
            </div>
          </div>

          <div
            className={classes.celijaX}
            style={{ borderTop: "3px solid white" }}
          ></div>
          <div
            className={classes.celijaX}
            style={{ borderTop: "3px solid white" }}
          ></div>
        </div>

        <div
          className={classes.kolona}
          style={{ display: "flex", flexDirection: "column" }}
        >
          <div className={classes.naslovnaCelija} style={{ background: "red" }}>
            <p>15</p>
          </div>

          <div
            className={classes.celija}
            style={{ borderTop: "3px solid white" }}
          >
            <div className={classes.levaPodCelija}>
              <p
                style={{
                  textAlign: "center",
                  fontWeight: "bolder",
                  marginTop: "10px",
                }}
              >
                {colLeft15[0]}
              </p>
            </div>
            <div className={classes.desnaPodCelija}>
              <p>{colRight15[0].toFixed(2)}</p>
            </div>
          </div>

          <div className={classes.celija}>
            <div className={classes.levaPodCelija}>
              <p
                style={{
                  textAlign: "center",
                  fontWeight: "bolder",
                  marginTop: "10px",
                }}
              >
                {colLeft15[1]}
              </p>
            </div>
            <div className={classes.desnaPodCelija}>
              <p>{colRight15[1].toFixed(2)}</p>
            </div>
          </div>

          <div className={classes.celija}>
            <div className={classes.levaPodCelija}>
              <p
                style={{
                  textAlign: "center",
                  fontWeight: "bolder",
                  marginTop: "10px",
                }}
              >
                {colLeft15[2]}
              </p>
            </div>
            <div className={classes.desnaPodCelija}>
              <p>{colRight15[2].toFixed(2)}</p>
            </div>
          </div>

          <div className={classes.celija}>
            <div className={classes.levaPodCelija}>
              <p
                style={{
                  textAlign: "center",
                  fontWeight: "bolder",
                  marginTop: "10px",
                }}
              >
                {colLeft15[3]}
              </p>
            </div>
            <div className={classes.desnaPodCelija}>
              <p>{colRight15[3].toFixed(2)}</p>
            </div>
          </div>

          <div className={classes.celija}>
            <div className={classes.levaPodCelija}>
              <p
                style={{
                  textAlign: "center",
                  fontWeight: "bolder",
                  marginTop: "10px",
                }}
              >
                {colLeft15[4]}
              </p>
            </div>
            <div className={classes.desnaPodCelija}>
              <p>{colRight15[4].toFixed(2)}</p>
            </div>
          </div>

          <div className={classes.celija}>
            <div className={classes.levaPodCelija}>
              <p
                style={{
                  textAlign: "center",
                  fontWeight: "bolder",
                  marginTop: "10px",
                }}
              >
                {colLeft15[5]}
              </p>
            </div>
            <div className={classes.desnaPodCelija}>
              <p>{colRight15[5].toFixed(2)}</p>
            </div>
          </div>

          <div className={classes.celija}>
            <div className={classes.levaPodCelija}>
              <p
                style={{
                  textAlign: "center",
                  fontWeight: "bolder",
                  marginTop: "10px",
                }}
              >
                {colLeft15[6]}
              </p>
            </div>
            <div className={classes.desnaPodCelija}>
              <p>{colRight15[6].toFixed(2)}</p>
            </div>
          </div>

          <div className={classes.celija}>
            <div className={classes.levaPodCelija}>
              <p
                style={{
                  textAlign: "center",
                  fontWeight: "bolder",
                  marginTop: "10px",
                }}
              >
                {colLeft15[7]}
              </p>
            </div>
            <div className={classes.desnaPodCelija}>
              <p>{colRight15[7].toFixed(2)}</p>
            </div>
          </div>

          <div className={classes.celija}>
            <div className={classes.levaPodCelija}>
              <p
                style={{
                  textAlign: "center",
                  fontWeight: "bolder",
                  marginTop: "10px",
                }}
              >
                {colLeft15[8]}
              </p>
            </div>
            <div className={classes.desnaPodCelija}>
              <p>{colRight15[8].toFixed(2)}</p>
            </div>
          </div>

          <div className={classes.celija}>
            <div className={classes.levaPodCelija}>
              <p
                style={{
                  textAlign: "center",
                  fontWeight: "bolder",
                  marginTop: "10px",
                }}
              >
                {colLeft15[9]}
              </p>
            </div>
            <div className={classes.desnaPodCelija}>
              <p>{colRight15[9].toFixed(2)}</p>
            </div>
          </div>

          <div className={classes.celija}>
            <div className={classes.levaPodCelija}>
              <p
                style={{
                  textAlign: "center",
                  fontWeight: "bolder",
                  marginTop: "10px",
                }}
              >
                {colLeft15[10]}
              </p>
            </div>
            <div className={classes.desnaPodCelija}>
              <p>{colRight15[10].toFixed(2)}</p>
            </div>
          </div>

          <div className={classes.celija}>
            <div className={classes.levaPodCelija}>
              <p
                style={{
                  textAlign: "center",
                  fontWeight: "bolder",
                  marginTop: "10px",
                }}
              >
                {colLeft15[11]}
              </p>
            </div>
            <div className={classes.desnaPodCelija}>
              <p>{colRight15[11].toFixed(2)}</p>
            </div>
          </div>

          <div className={classes.celija}>
            <div className={classes.levaPodCelija}>
              <p
                style={{
                  textAlign: "center",
                  fontWeight: "bolder",
                  marginTop: "10px",
                }}
              >
                {colLeft15[12]}
              </p>
            </div>
            <div className={classes.desnaPodCelija}>
              <p>{colRight15[12].toFixed(2)}</p>
            </div>
          </div>

          <div className={classes.celija}>
            <div className={classes.levaPodCelija}>
              <p
                style={{
                  textAlign: "center",
                  fontWeight: "bolder",
                  marginTop: "10px",
                }}
              >
                {colLeft15[13]}
              </p>
            </div>
            <div className={classes.desnaPodCelija}>
              <p>{colRight15[13].toFixed(2)}</p>
            </div>
          </div>

          <div
            className={classes.poslednjaCelija}
            style={{
              borderLeft: "3px solid white",
              borderBottom: "3px solid white",
            }}
          >
            <div className={classes.levaPodCelijaPoslednjeCelije}>
              <p
                style={{
                  textAlign: "center",
                  fontWeight: "bolder",
                  marginTop: "10px",
                }}
              >
                {colLeft15[14]}
              </p>
            </div>
            <div className={classes.desnaPodCelijaPoslednjeCelije}>
              <p>{colRight15[14].toFixed(2)}</p>
            </div>
          </div>

          <div
            className={classes.celijaX}
            style={{ borderTop: "3px solid white" }}
          ></div>
        </div>

        <div
          className={classes.kolona}
          style={{ display: "flex", flexDirection: "column" }}
        >
          <div
            className={classes.naslovnaCelija}
            style={{ background: "darkblue", color: "white" }}
          >
            <p>16</p>
          </div>

          <div
            className={classes.celija}
            style={{ borderTop: "3px solid white" }}
          >
            <div className={classes.levaPodCelija}>
              <p
                style={{
                  textAlign: "center",
                  fontWeight: "bolder",
                  marginTop: "10px",
                }}
              >
                {colLeft16[0]}
              </p>
            </div>
            <div className={classes.desnaPodCelija}>
              <p>{colRight16[0].toFixed(2)}</p>
            </div>
          </div>

          <div className={classes.celija}>
            <div className={classes.levaPodCelija}>
              <p
                style={{
                  textAlign: "center",
                  fontWeight: "bolder",
                  marginTop: "10px",
                }}
              >
                {colLeft16[1]}
              </p>
            </div>
            <div className={classes.desnaPodCelija}>
              <p>{colRight16[1].toFixed(2)}</p>
            </div>
          </div>

          <div className={classes.celija}>
            <div className={classes.levaPodCelija}>
              <p
                style={{
                  textAlign: "center",
                  fontWeight: "bolder",
                  marginTop: "10px",
                }}
              >
                {colLeft16[2]}
              </p>
            </div>
            <div className={classes.desnaPodCelija}>
              <p>{colRight16[2].toFixed(2)}</p>
            </div>
          </div>

          <div className={classes.celija}>
            <div className={classes.levaPodCelija}>
              <p
                style={{
                  textAlign: "center",
                  fontWeight: "bolder",
                  marginTop: "10px",
                }}
              >
                {colLeft16[3]}
              </p>
            </div>
            <div className={classes.desnaPodCelija}>
              <p>{colRight16[3].toFixed(2)}</p>
            </div>
          </div>

          <div className={classes.celija}>
            <div className={classes.levaPodCelija}>
              <p
                style={{
                  textAlign: "center",
                  fontWeight: "bolder",
                  marginTop: "10px",
                }}
              >
                {colLeft16[4]}
              </p>
            </div>
            <div className={classes.desnaPodCelija}>
              <p>{colRight16[4].toFixed(2)}</p>
            </div>
          </div>

          <div className={classes.celija}>
            <div className={classes.levaPodCelija}>
              <p
                style={{
                  textAlign: "center",
                  fontWeight: "bolder",
                  marginTop: "10px",
                }}
              >
                {colLeft16[5]}
              </p>
            </div>
            <div className={classes.desnaPodCelija}>
              <p>{colRight16[5].toFixed(2)}</p>
            </div>
          </div>

          <div className={classes.celija}>
            <div className={classes.levaPodCelija}>
              <p
                style={{
                  textAlign: "center",
                  fontWeight: "bolder",
                  marginTop: "10px",
                }}
              >
                {colLeft16[6]}
              </p>
            </div>
            <div className={classes.desnaPodCelija}>
              <p>{colRight16[6].toFixed(2)}</p>
            </div>
          </div>

          <div className={classes.celija}>
            <div className={classes.levaPodCelija}>
              <p
                style={{
                  textAlign: "center",
                  fontWeight: "bolder",
                  marginTop: "10px",
                }}
              >
                {colLeft16[7]}
              </p>
            </div>
            <div className={classes.desnaPodCelija}>
              <p>{colRight16[7].toFixed(2)}</p>
            </div>
          </div>

          <div className={classes.celija}>
            <div className={classes.levaPodCelija}>
              <p
                style={{
                  textAlign: "center",
                  fontWeight: "bolder",
                  marginTop: "10px",
                }}
              >
                {colLeft16[8]}
              </p>
            </div>
            <div className={classes.desnaPodCelija}>
              <p>{colRight16[8].toFixed(2)}</p>
            </div>
          </div>

          <div className={classes.celija}>
            <div className={classes.levaPodCelija}>
              <p
                style={{
                  textAlign: "center",
                  fontWeight: "bolder",
                  marginTop: "10px",
                }}
              >
                {colLeft16[9]}
              </p>
            </div>
            <div className={classes.desnaPodCelija}>
              <p>{colRight16[9].toFixed(2)}</p>
            </div>
          </div>

          <div className={classes.celija}>
            <div className={classes.levaPodCelija}>
              <p
                style={{
                  textAlign: "center",
                  fontWeight: "bolder",
                  marginTop: "10px",
                }}
              >
                {colLeft16[10]}
              </p>
            </div>
            <div className={classes.desnaPodCelija}>
              <p>{colRight16[10].toFixed(2)}</p>
            </div>
          </div>

          <div className={classes.celija}>
            <div className={classes.levaPodCelija}>
              <p
                style={{
                  textAlign: "center",
                  fontWeight: "bolder",
                  marginTop: "10px",
                }}
              >
                {colLeft16[11]}
              </p>
            </div>
            <div className={classes.desnaPodCelija}>
              <p>{colRight16[11].toFixed(2)}</p>
            </div>
          </div>

          <div className={classes.celija}>
            <div className={classes.levaPodCelija}>
              <p
                style={{
                  textAlign: "center",
                  fontWeight: "bolder",
                  marginTop: "10px",
                }}
              >
                {colLeft16[12]}
              </p>
            </div>
            <div className={classes.desnaPodCelija}>
              <p>{colRight16[12].toFixed(2)}</p>
            </div>
          </div>

          <div className={classes.celija}>
            <div className={classes.levaPodCelija}>
              <p
                style={{
                  textAlign: "center",
                  fontWeight: "bolder",
                  marginTop: "10px",
                }}
              >
                {colLeft16[13]}
              </p>
            </div>
            <div className={classes.desnaPodCelija}>
              <p>{colRight16[13].toFixed(2)}</p>
            </div>
          </div>

          <div className={classes.celija}>
            <div className={classes.levaPodCelija}>
              <p
                style={{
                  textAlign: "center",
                  fontWeight: "bolder",
                  marginTop: "10px",
                }}
              >
                {colLeft16[14]}
              </p>
            </div>
            <div className={classes.desnaPodCelija}>
              <p>{colRight16[14].toFixed(2)}</p>
            </div>
          </div>

          <div
            className={classes.poslednjaCelija}
            style={{ borderLeft: "3px solid white" }}
          >
            <div className={classes.levaPodCelijaPoslednjeCelije}>
              <p
                style={{
                  textAlign: "center",
                  fontWeight: "bolder",
                  marginTop: "10px",
                }}
              >
                {colLeft16[15]}
              </p>
            </div>
            <div className={classes.desnaPodCelijaPoslednjeCelije}>
              <p>{colRight16[15].toFixed(2)}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TablicaUslovnihKvotaIBrojKombinacija;
