import Grupa1 from "../components/Grupa1";
import Grupa28 from "../components/Grupa28";
import Grupa3002 from "../components/Grupa3002";
import classes from "./odbojka.module.css";
import { useState } from "react";

const Odbojka = () => {
  const [pobedaigraca1, setPobedaIgraca1] = useState(0);
  const [pobedaigraca2, setPobedaIgraca2] = useState(0);
  const [brSetovaNaMecu, setBrSetovaNaMecu] = useState(0);
  const [igrac1OsvSetova, setIgrac1OsvSetova] = useState(0);
  const [igrac2OsvSetova, setIgrac2OsvSetova] = useState(0);
  const [igrac1IzgSetova, setIgrac1IzgSetova] = useState(0);
  const [igrac2IzgSetova, setIgrac2IzgSetova] = useState(0);
  const [brMecevaIgrac1, setBrMecevaIgrac1] = useState(0);
  const [brMecevaIgrac2, setBrMecevaIgrac2] = useState(0);
  const [procenatPobedeIgraca1, setProcenatPobedeIgraca1] = useState(0);
  const [procenatPobedeIgraca2, setProcenatPobedeIgraca2] = useState(0);
  const [maxSetova, setMaxSetova] = useState(0);
  const [snd, setSND] = useState("-");
  const [sod, setSOD] = useState("-");
  const [sog, setSOG] = useState("-");
  const [sng, setSNG] = useState("-");
  const [sodg, setSODG] = useState("-");
  const [sndg, setSNDG] = useState("-");
  const [sndSog, setSNDSOG] = useState("-");
  const [sodSng, setSODSNG] = useState("-");
  const [balans, setBalans] = useState(0);
  const [ocekivanibrojsetovaigrac1, setOcekivaniBrojSetovaIgrac1] = useState(0);
  const [ocekivanibrojsetovaigrac2, setOcekivaniBrojSetovaIgrac2] = useState(0);
  const [betkoPredlog, setBetkoPredlog] = useState("");
  const [isCalculated, setIsCalculated] = useState(false);

  return (
    <div className={classes.container}>
      <Grupa1
        imgText="ODBOJKA"
        imgBackgroundColorDark="#0E3C8D"
        imgBackgroundColorLight="#0B61E6"
        imgFontColor="#38F81E"
        label1="VREME POČETKA"
        label2="ZEMLJA"
        label3="DOMAĆIN"
        label4="TAKMIČENJE - LIGA"
        label5="GOST"
      />
      <Grupa28
        imgBackgroundColorDark="#0E3C8D"
        imgBackgroundColorLight="#0B61E6"
        imgFontColor="#38F81E"
        label1={snd}
        label2={sod}
        label3={sog}
        label4={sng}
        label5={sodg}
        label6={sndg}
        label7={sndSog}
        label8={sodSng}
        calculatorType={`Odbojka`}
      />
      <Grupa3002
        pobedaigraca1={pobedaigraca1}
        pobedaigraca2={pobedaigraca2}
        setPobedaIgraca1={setPobedaIgraca1}
        setPobedaIgraca2={setPobedaIgraca2}
        brSetovaNaMecu={brSetovaNaMecu}
        setBrSetovaNaMecu={setBrSetovaNaMecu}
        igrac1OsvSetova={igrac1OsvSetova}
        setIgrac1OsvSetova={setIgrac1OsvSetova}
        igrac1IzgSetova={igrac1IzgSetova}
        setIgrac1IzgSetova={setIgrac1IzgSetova}
        igrac2IzgSetova={igrac2IzgSetova}
        setIgrac2IzgSetova={setIgrac2IzgSetova}
        igrac2OsvSetova={igrac2OsvSetova}
        setIgrac2OsvSetova={setIgrac2OsvSetova}
        brMecevaIgrac1={brMecevaIgrac1}
        brMecevaIgrac2={brMecevaIgrac2}
        setBrMecevaIgrac1={setBrMecevaIgrac1}
        setBrMecevaIgrac2={setBrMecevaIgrac2}
        setProcenatPobedeIgraca1={setProcenatPobedeIgraca1}
        setProcenatPobedeIgraca2={setProcenatPobedeIgraca2}
        setBalans={setBalans}
        setMaxSetova={setMaxSetova}
        setSND={setSND}
        setSOD={setSOD}
        setSNDG={setSNDG}
        setSOG={setSOG}
        setSNG={setSNG}
        setSODG={setSODG}
        setSNDSOG={setSNDSOG}
        setSODSNG={setSODSNG}
        setBetkoPredlog={setBetkoPredlog}
        setOcekivaniBrojSetovaIgrac1={setOcekivaniBrojSetovaIgrac1}
        setOcekivaniBrojSetovaIgrac2={setOcekivaniBrojSetovaIgrac2}
        setIsCalculated={setIsCalculated}
      />
      <div className={classes.calculator}>
        <p className={classes.prvi}>
          {!isCalculated ? null : procenatPobedeIgraca1}{" "}
          {!isCalculated ? null : "%"}
        </p>
        <p className={classes.drugi}>
          {!isCalculated ? null : procenatPobedeIgraca2}{" "}
          {!isCalculated ? null : "%"}
        </p>
        <p className={classes.maxSetova}>{!isCalculated ? null : maxSetova}</p>
        <p className={classes.treci}>
          {!isCalculated ? null : ocekivanibrojsetovaigrac1}{" "}
        </p>
        <p className={classes.cetvrti}>
          {!isCalculated ? null : ocekivanibrojsetovaigrac2}{" "}
        </p>
        <p className={classes.peti}>{!isCalculated ? null : balans}</p>
        <p className={classes.minSetova}>
          {!isCalculated ? null : brSetovaNaMecu}{" "}
        </p>
        <p className={classes.betkopredlog}>
          {!isCalculated ? null : betkoPredlog}{" "}
        </p>
      </div>
    </div>
  );
};

export default Odbojka;
