import { useNavigate } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import { format } from "date-fns";
import { useState } from "react";
import classes from "./adminSinglePost.module.css";
import { Modal, Button } from "antd";

const AdminSinglePost = ({ post, type }) => {
  const navigate = useNavigate();
  const [isDelModalVisible, setIsDelModalVisible] = useState(false);
  const axiosPrivate = useAxiosPrivate();
  const formatedDate = format(new Date(post.createdAt), "dd-MM-yyyy HH:mm:ss");

  let path;
  let func;

  if (type === "betKursNovo") {
    path = "";
    func = "deleteBetKursNovoPost";
  }
  if (type === "betKurs") {
    path = "";
    func = "deleteBetCoursePost";
  }
  if (type === "infoBlok") {
    path = "";
    func = "deleteInfoBlokPost";
  }
  if (type === "betPortal") {
    path = "";
    func = "deleteBetPortalPost";
  }
  if (type === "statistika") {
    path = "";
    func = "deleteStatistikaPost";
  }
  if (type === "nasiPredlozi") {
    path = "";
    func = "deleteNasiPredloziPost";
  }
  if (type === "betKalkulatoriTutorijal") {
    path = "";
    func = "deleteBetKalkulatoriTutorijalPost";
  }
  if (type === "betKursPoglavlje") {
    path = "";
    func = "betcoursechapter";
  }
  if (type === "korisnickiPaketi") {
    path = "";
    func = "users-package";
  }

  const deletePostMutation = useMutation({
    mutationFn: () =>
      axiosPrivate.delete(`${path}/${func}`, {
        data: { postId: post._id },
      }),
  });

  if (deletePostMutation.isLoading) return <h1>Loading...</h1>;
  if (deletePostMutation.isError) return <h1>Error...</h1>;

  const handleIzmeni = () => {
    if (type === "betKalkulatoriTutorijal") {
      navigate("/betko-admin/edituj-post-tutorijal", {
        state: { postId: post._id, type },
      });
    } else if (type === "betKurs") {
      navigate("/betko-admin/edituj-post-betkurs", {
        state: { postId: post._id, type },
      });
    } else if (type === "korisnickiPaketi") {
      navigate("/betko-admin/edituj-post-korisnickipaketi", {
        state: { postId: post._id, type },
      });
    } else {
      navigate("/betko-admin/edituj-post", {
        state: { postId: post._id, type },
      });
    }
  };
  const handleObrisi = () => {
    deletePostMutation.mutate(post._id);

    window.location.reload();
  };

  return (
    <div className={classes.singlePost}>
      <div className={classes.left}>
        <p>
          <span className={classes.title}>Modifikovan:</span>
          {formatedDate}
        </p>
        |
        <p>
          <span className={classes.title}>Naslov:</span>
          {post.title.length >= 38
            ? post.title.substring(0, 38) + "..."
            : post.title}
        </p>
      </div>
      <div className={classes.right}>
        {type !== "betKursPoglavlje" && (
          <div>
            {" "}
            <button className={classes.btn} onClick={handleIzmeni}>
              Izmeni
            </button>
            |
          </div>
        )}
        <button
          className={classes.btn}
          onClick={() => {
            setIsDelModalVisible(true);
          }}
        >
          Obriši
        </button>
      </div>

      <Modal
        footer={[]}
        open={isDelModalVisible}
        title="Da li ste sigurni da želite da obrišete post?"
        onCancel={() => {
          setIsDelModalVisible(false);
        }}
      >
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-evenly",
            marginTop: "20px",
          }}
        >
          <Button
            onClick={(e) => {
              handleObrisi();
              setIsDelModalVisible(false);
            }}
          >
            Obriši
          </Button>
          <Button
            onClick={(e) => {
              setIsDelModalVisible(false);
            }}
          >
            Odustani
          </Button>
        </div>
      </Modal>
    </div>
  );
};

export default AdminSinglePost;
