import { useNavigate } from "react-router-dom";
import classes from "./betKurs.module.css";
import { useState, useEffect } from "react";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import Axios from "axios";
const SERVER_URL = process.env.REACT_APP_SERVER_URL;

const BetKurs = () => {
  const navigate = useNavigate();
  const axiosPrivate = useAxiosPrivate();
  const [items, setItems] = useState([]);

  const fetchItems = async () => {
    const data = await Axios.get(`${SERVER_URL}/betcourses-menu`);

    setItems(data.data);
  };

  useEffect(() => {
    fetchItems();
  }, []);

  return (
    <div className={classes.container}>
      {items &&
        items.length > 0 &&
        items.map((item, index) => {
          return (
            <ul key={index}>
              <li className={classes.liTitle}>
                <p style={{ margin: "0", padding: "0" }}>
                  {item.title.toUpperCase()}
                </p>
              </li>
              {item.items &&
                item.items.length > 0 &&
                item.items.map((courseitem, courseindex) => {
                  return (
                    <li
                      key={courseindex}
                      onClick={() => {
                        navigate(`/bet_kurs/${courseitem._id.toString()}`);
                      }}
                      className={classes.liItem}
                    >
                      {courseitem.title.toUpperCase()}
                    </li>
                  );
                })}
            </ul>
          );
        })}
    </div>
  );
};

export default BetKurs;
