import classes from "./adminHeader.module.css";

import { useNavigate } from "react-router-dom";

const AdminHeader = () => {
  const navigate = useNavigate();

  return (
    <div className={classes.container}>
      <h1
        onClick={() => {
          navigate("/");
        }}
      >
        Admin Panel - BETKOBETKO.com
      </h1>
    </div>
  );
};

export default AdminHeader;
