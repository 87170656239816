import React, { useEffect, useState } from 'react'
import {
  Form,
  Input,
  Button,
  InputNumber,
  DatePicker,
  message,
  TimePicker,
} from 'antd'
import classes from './adminMisterTipster.module.css'
import axios from 'axios'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import moment from 'moment'
dayjs.extend(utc)
dayjs.extend(timezone)
const SERVER_URL = process.env.REACT_APP_SERVER_URL

const initialMatches = [
  {
    club1: '',
    club2: '',
    odds1: '',
    oddsX: '',
    odds2: '',
    date: null,
    league: '',
  },
  {
    club1: '',
    club2: '',
    odds1: '',
    oddsX: '',
    odds2: '',
    date: null,
    league: '',
  },
  {
    club1: '',
    club2: '',
    odds1: '',
    oddsX: '',
    odds2: '',
    date: null,
    league: '',
  },
  {
    club1: '',
    club2: '',
    odds1: '',
    oddsX: '',
    odds2: '',
    date: null,
    league: '',
  },
  {
    club1: '',
    club2: '',
    odds1: '',
    oddsX: '',
    odds2: '',
    date: null,
    league: '',
  },
]
// INITIAL VALUES FOR EASIER TESTING
// const initialMatches = [
//   {
//     club1: '00',
//     club2: '00',
//     odds1: '1',
//     oddsX: '1',
//     odds2: '1',
//     date: null,
//     league: '1',
//     isEntered1: null,
//   },
//   {
//     club1: '01',
//     club2: '01',
//     odds1: '1',
//     oddsX: '2',
//     odds2: '3',
//     date: null,
//     league: '01',
//   },
//   {
//     club1: '02',
//     club2: '02',
//     odds1: '2',
//     oddsX: '2',
//     odds2: '2',
//     date: null,
//     league: '02',
//   },
//   {
//     club1: '23',
//     club2: '23',
//     odds1: '23',
//     oddsX: '2',
//     odds2: '2',
//     date: null,
//     league: '23',
//   },
//   {
//     club1: '13',
//     club2: '13',
//     odds1: '13',
//     oddsX: '13',
//     odds2: '13',
//     date: null,
//     league: '13',
//   },
// ]

const AdminMisterTipster = () => {
  const [form] = Form.useForm()
  const [selectedDate, setSelectedDate] = useState(new Date())
  const [refetchTrigger, setRefetchTrigger] = useState(false)
  const [matchResultValues, setMatchResultValues] = useState({})
  const [allOddsAdded, setAllOddsAdded] = useState(false)

  const handleInputChange = (index, value) => {
    setMatchResultValues({
      ...matchResultValues,
      [index]: value,
    })
  }

  const onFinish = async (values) => {
    const formValues = values.matches.map((pair) => {
      const time = dayjs(pair.date)
      const hours = time.hour()
      const minutes = time.minute()

      const pairDateAndTime = dayjs(selectedDate)
        .hour(hours)
        .minute(minutes)
        .second(0)
        .millisecond(0)

      const localTimeString =
        pairDateAndTime.format('YYYY-MM-DDTHH:mm:ss') + '.000Z'
      return {
        ...pair,
        date: localTimeString,
      }
    })

    try {
      await axios.post(`${SERVER_URL}/create-pair`, {
        data: formValues,
      })
      setRefetchTrigger((curr) => !curr)
      message.success('Uspešno ste dodali parove!')
    } catch (err) {
      message.error(
        err?.response?.data?.message || 'Greška pri kreiranju parova!'
      )
    }
  }

  const handleDateChange = (date, dateString) => {
    setSelectedDate(dateString)
  }

  useEffect(() => {
    const getPairsForDate = async () => {
      const dateObject = new Date(selectedDate)
      const dateToFetch = moment(dateObject).utc().startOf('day')

      try {
        const response = await axios.get(`${SERVER_URL}/get-pair-by-date`, {
          params: { date: dateToFetch.toISOString() },
        })

        const odds = response.data.pairsForThatDate
          .map((pair) =>
            pair.isEntered1 || pair.isEntered2 || pair.isEnteredX
              ? 'updated'
              : undefined
          )
          .filter((value) => value)

        setAllOddsAdded(odds?.length === 5)

        const formValues =
          response?.data?.pairsForThatDate?.length > 0
            ? response?.data?.pairsForThatDate.map((pair) => {
                return {
                  ...pair,
                  date: dayjs(pair.date).utc(),
                }
              })
            : initialMatches

        form.setFieldsValue({
          matches: formValues,
        })
      } catch (error) {
        message.error(error?.response?.data?.message)
        form.resetFields()
      } finally {
        allOddsAdded && message.info('Sve kvote za danas su unete! ')
      }
    }

    getPairsForDate()
  }, [selectedDate, refetchTrigger])

  const handleCreateTodayMatches = async () => {
    try {
      await axios.post(`${SERVER_URL}/create-tickets`)
      message.success('Tiketi za danas su uspešno poslati!')
    } catch (err) {
      message.error(
        err?.response?.data?.message || 'Greška pri slanu današnjih tiketa!'
      )
    }
  }
  const formValues = form?.getFieldValue('matches')

  const handleUpdateMatchResult = async (index) => {
    const resultEnum = ['1', 'X', 'x', '2']
    const _id = formValues && formValues[index]?._id
    const inputValue = matchResultValues[index]
    if (!resultEnum.includes(inputValue)) {
      message.error('Ishod meča može biti samo 1, X ili 2 !')
      return
    }
    const payload = {
      isEntered1: inputValue === '1',
      isEntered2: inputValue === '2',
      isEnteredX: inputValue === 'X' || inputValue === 'x',
    }

    try {
      await axios.put(`${SERVER_URL}/update-pair/${_id}`, payload)
      message.success(`Uspešno ste uneli konačan ishod meča: ${inputValue}`)
      setRefetchTrigger((curr) => !curr)
    } catch (err) {
      message.error('Greška pri ažuriranju ishod meča!')
    }
  }

  const determineMatchResult = (isEntered1, isEntered2, isEnteredX) => {
    if (isEnteredX) return 'X'
    if (isEntered1) return 1
    if (isEntered2) return 2
    return null
  }

  return (
    <div className={classes.misterTipsterAdmin}>
      <div className={classes.misterTipsterHeader}>
        <h2>Mister Tipster Parovi</h2>
        <div>
          <span>Izaberite datum: </span>
          <DatePicker
            onChange={handleDateChange}
            placeholder='Danas'
            className={classes.inputWhiteBg}
          />
        </div>
      </div>
      <Form
        form={form}
        name='misterTipsterForm'
        layout='vertical'
        onFinish={onFinish}
        initialValues={{ matches: initialMatches }}
      >
        <Form.List name='matches'>
          {(fields) => (
            <div className={classes.tipsterFormList}>
              {fields.map(({ key, name, fieldKey, ...restField }, index) => {
                const formValue = form.getFieldValue('matches')[index] || {}
                const { isEntered1, isEntered2, isEnteredX } = formValue
                const matchResult = determineMatchResult(
                  isEntered1,
                  isEntered2,
                  isEnteredX
                )

                return (
                  <div key={key} className={classes.matchWrapper}>
                    <div className={classes.matchHeaderWrapper}>
                      <h3>Meč {key + 1}</h3>
                      <div>
                        <input
                          placeholder='Ishod meča'
                          type='text'
                          disabled={allOddsAdded}
                          value={matchResultValues[index] || matchResult || ''}
                          onChange={(e) =>
                            handleInputChange(index, e.target.value)
                          }
                        />
                        {!allOddsAdded && (
                          <button
                            type='button'
                            onClick={() => handleUpdateMatchResult(index)}
                          >
                            OK
                          </button>
                        )}
                      </div>
                    </div>
                    <div className={classes.formRow}>
                      <Form.Item
                        {...restField}
                        name={[name, 'club1']}
                        fieldKey={[fieldKey, 'club1']}
                        label={<label> Klub 1 </label>}
                        rules={[
                          {
                            required: true,
                            message: 'Molimo unesite klub 1!',
                          },
                        ]}
                      >
                        <Input className={classes.inputWhiteBg} />
                      </Form.Item>

                      <Form.Item
                        {...restField}
                        name={[name, 'club2']}
                        fieldKey={[fieldKey, 'club2']}
                        label={<label> Klub 2 </label>}
                        rules={[
                          {
                            required: true,
                            message: 'Molimo unesite klub 2!',
                          },
                        ]}
                      >
                        <Input className={classes.inputWhiteBg} />
                      </Form.Item>
                    </div>
                    <div className={classes.formRow}>
                      <Form.Item
                        {...restField}
                        name={[name, 'league']}
                        fieldKey={[fieldKey, 'league']}
                        label={<label> Liga </label>}
                        rules={[
                          { required: true, message: 'Molimo unesite ligu!' },
                        ]}
                      >
                        <Input className={classes.inputWhiteBg} />
                      </Form.Item>
                      <Form.Item
                        {...restField}
                        name={[name, 'date']}
                        fieldKey={[fieldKey, 'date']}
                        label={<label>Datum i vreme</label>}
                        rules={[
                          {
                            required: true,
                            message: 'Molimo unesite datum i vreme!',
                          },
                        ]}
                      >
                        <TimePicker
                          className={classes.inputWhiteBg}
                          placeholder='Početak meča'
                          showTime
                          format='HH:mm'
                        />
                      </Form.Item>
                    </div>
                    <div className={classes.formRow}>
                      <Form.Item
                        {...restField}
                        name={[name, 'odds1']}
                        fieldKey={[fieldKey, 'odds1']}
                        label={<label> Kvota za 1 </label>}
                        rules={[
                          {
                            required: true,
                            message: 'Molimo unesite klub kvotu za bet 1!',
                          },
                        ]}
                      >
                        <InputNumber
                          min={1}
                          step={0.1}
                          precision={2}
                          className={classes.inputWhiteBg}
                        />
                      </Form.Item>
                      <Form.Item
                        {...restField}
                        name={[name, 'oddsX']}
                        fieldKey={[fieldKey, 'oddsX']}
                        label={<label> Kvota za X </label>}
                        rules={[
                          {
                            required: true,
                            message: 'Molimo unesite klub kvotu za bet X!',
                          },
                        ]}
                      >
                        <InputNumber
                          min={1}
                          step={0.1}
                          precision={2}
                          className={classes.inputWhiteBg}
                        />
                      </Form.Item>
                      <Form.Item
                        {...restField}
                        name={[name, 'odds2']}
                        fieldKey={[fieldKey, 'odds2']}
                        label={<label> Kvota za 2 </label>}
                        rules={[
                          {
                            required: true,
                            message: 'Molimo unesite klub kvotu za bet 2!',
                          },
                        ]}
                      >
                        <InputNumber
                          min={1}
                          precision={2}
                          step={0.1}
                          className={classes.inputWhiteBg}
                        />
                      </Form.Item>
                    </div>
                  </div>
                )
              })}
            </div>
          )}
        </Form.List>
        <Form.Item>
          <div className={classes.submitBtnsWrapper}>
            <div className={classes.submitBtnWrapper}>
              <Button
                type='primary'
                size='large'
                htmlType='submit'
                className={classes.submitBtn}
              >
                Sačuvaj
              </Button>
            </div>
            <div className={classes.submitBtnWrapper}>
              <Button
                type='primary'
                size='large'
                className={classes.submitBtn}
                onClick={handleCreateTodayMatches}
              >
                Pošalji DANAŠNJE mečeve
              </Button>
            </div>
          </div>
        </Form.Item>
      </Form>
    </div>
  )
}

export default AdminMisterTipster
