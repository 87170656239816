import classes from "./grupa300.module.css";
import { useEffect } from "react";

const Grupa3004 = ({
  snd,
  setSND,
  sod,
  setSOD,
  sog,
  setSOG,
  sng,
  setSNG,
  sodg,
  setSODG,
  sndg,
  setSNDG,
  sndSog,
  setSNDSOG,
  sodSng,
  setSODSNG,
  pobedaDomacina,
  setPobedaDomacina,
  nereseno,
  setNeresno,
  pobedaGosta,
  setPobedaGosta,
  domacinDao,
  setDomacinDao,
  domacinPrimio,
  setDomacinPrimio,
  brojUtakmicaDomacin,
  setBrojUtakmicaDomacin,
  brojUtakmicaGost,
  setBrojUtakmicaGost,
  gostDao,
  setGostDao,
  gostPrimio,
  setGostPrimio,
  konacanIshod1,
  setKonacanIshod1,
  konacanIshod2,
  setKonacanIshod2,
  poeniMin,
  setPoeniMin,
  poeniMax,
  setPoeniMax,
  poeniBalans,
  setPoeniBalans,
  pobedaSaRazlikomMin,
  setPobedaSaRazlikomMin,
  pobedaSaRazlikomMax,
  setPobedaSaRazlikomMax,
  pobedaSaRazlikomBalans,
  setPobedaSaRazlikomBalans,
  tacanRezultatDom1,
  setTacanRezultatDom1,
  tacanRezultatDom2,
  setTacanRezultatDom2,
  tacanRezultatDom3,
  setTacanRezultatDom3,
  tacanRezultatGost1,
  setTacanRezultatGost1,
  tacanRezultatGost2,
  setTacanRezultatGost2,
  tacanRezultatGost3,
  setTacanRezultatGost3,
  betkopredloog,
  setBetkoPredlog,
}) => {
  const handleCalculate = () => {
    if (
      pobedaDomacina &&
      nereseno &&
      pobedaGosta &&
      domacinDao &&
      domacinPrimio &&
      brojUtakmicaDomacin &&
      gostDao &&
      gostPrimio &&
      brojUtakmicaGost
    ) {
      /////////// ZAGLAVLJE //////////////////

      let snd = domacinDao / brojUtakmicaDomacin;

      setSND(snd.toFixed(2));

      let sod = domacinPrimio / brojUtakmicaDomacin;

      setSOD(sod.toFixed(2));

      let sog = gostDao / brojUtakmicaGost;

      setSOG(sog.toFixed(2));

      let sng = gostPrimio / brojUtakmicaGost;

      setSNG(sng.toFixed(2));

      let sodg = (sod + sog) / 2;

      setSODG(sodg.toFixed(2));

      let sndg = (snd + sng) / 2;

      setSNDG(sndg.toFixed(2));

      let sndsog = (snd + sog) / 2;

      setSNDSOG(sndsog.toFixed(2));

      let sodsng = (sod + sng) / 2;

      setSODSNG(sodsng.toFixed(2));
      /////////////////////////////

      let h63 = pobedaDomacina;

      let k63 = (1 / h63) * 100;

      let i63 = nereseno;

      let l63 = (1 / i63) * 100;

      let j63 = pobedaGosta;

      let m63 = (1 / j63) * 100;

      let n63 = k63 + l63 + m63 - 100;

      let o63 = k63 - n63 / 3;

      let r63 = (o63 * n63) / 100;

      let u63 = k63 - r63;

      let p63 = l63 - n63 / 3;

      let s63 = (p63 * n63) / 100;

      let v63 = l63 - s63;

      let l61 = v63 / 2;

      let pobeda1 = u63 + l61;

      setKonacanIshod1(pobeda1.toFixed(2));

      let q63 = m63 - n63 / 3;

      let t63 = (q63 * n63) / 100;

      let w63 = m63 - t63;

      let pobeda2 = l61 + w63;

      setKonacanIshod2(pobeda2.toFixed(2));

      let n67 = domacinDao;

      let o67 = domacinPrimio;

      let o69 = brojUtakmicaDomacin;

      let o71 = (n67 + o67) / o69;

      let p67 = gostPrimio;

      let q67 = gostDao;

      let q69 = brojUtakmicaGost;

      let q71 = (p67 + q67) / q69;

      let o73 = (o71 + q71) / 2;

      let l60 = l63 - s63;

      let p58 = o73 - o73 * 0.0102;

      let p60 = p58;

      let bd60 = p60 / 2;

      let ag60 = pobeda1;

      let ah60 = nereseno;

      let aq60 = p60;

      let ar60 = ag60 + ah60 / 2;

      let at60 = (aq60 * ar60) / 100;

      let ai60 = pobeda2;
      let as60 = ai60 + ah60 / 2;
      let au60 = (aq60 * as60) / 100;

      let av60 = Math.round(at60);

      let ax60 = Math.round(au60);

      let poenimin = o73 - (l60 / 100) * p60;

      setPoeniMin(poenimin.toFixed(2));

      let n60 = o73 - (l60 / 100) * p60;

      let poenimax = p60 - n60 + p60;

      setPoeniMax(poenimax.toFixed(2));

      let poenibalans = p60;

      setPoeniBalans(poenibalans.toFixed(2));

      let k60 = u63;

      let m60 = w63;

      let max;
      let min;

      if (av60 > ax60) {
        max = av60;
        min = ax60;
      } else {
        max = ax60;
        min = av60;
      }

      let be60 = (max - min) / 5;

      let bf60;

      if (k60 >= m60) {
        bf60 = bd60 + be60 / 2;
      } else {
        bf60 = bd60 - be60 / 2;
      }

      let bg60;

      if (bd60 > bf60) {
        bg60 = bd60 + be60 / 2;
      } else {
        bg60 = bd60 - be60 / 2;
      }

      let pobedasarazlikombalans = Math.round(bf60 - bg60);

      setPobedaSaRazlikomBalans(pobedasarazlikombalans);

      let pobedasarazlikommin = Math.round(
        pobedasarazlikombalans - pobedasarazlikombalans * 0.25
      );

      setPobedaSaRazlikomMin(pobedasarazlikommin);

      let pobedasarazlikommax = Math.round(
        pobedasarazlikombalans + pobedasarazlikombalans * 0.25
      );

      setPobedaSaRazlikomMax(pobedasarazlikommax);

      let o60 = (n60 + p60) / 2;

      let betkovpredlog = o60.toFixed(2);

      setBetkoPredlog(betkovpredlog);

      let bk60 = n60 / 2;

      let bl60 = be60;

      let bm60;

      if (k60 >= m60) {
        bm60 = bk60 + bl60 / 2;
      } else {
        bm60 = bk60 - bl60 / 2;
      }

      let tacanrez1dom = Math.round(bm60);

      setTacanRezultatDom1(tacanrez1dom);

      let bn60;

      if (bk60 > bm60) {
        bn60 = bk60 + bl60 / 2;
      } else {
        bn60 = bk60 - bl60 / 2;
      }

      let tacanrez1gost = Math.round(bn60);

      setTacanRezultatGost1(tacanrez1gost);

      let tacanrez2dom = Math.round(bf60);

      setTacanRezultatDom2(tacanrez2dom);

      let tacanrez2gost = Math.round(bg60);

      setTacanRezultatGost2(tacanrez2gost);

      let q60 = p60 - n60 + p60;

      let br60 = q60 / 2;

      let bu60;

      let bt60;

      let bs60 = (max - min) / 5;

      if (k60 >= m60) {
        bt60 = br60 + bs60 / 2;
      } else {
        bt60 = br60 - bs60 / 2;
      }

      if (br60 > bt60) {
        bu60 = br60 + bs60 / 2;
      } else {
        bu60 = br60 - bs60 / 2;
      }

      let tacanrez3gost = Math.round(bu60);
      let tacanrez3dom = Math.round(bt60);

      setTacanRezultatDom3(tacanrez3dom);
      setTacanRezultatGost3(tacanrez3gost);
    }
  };

  useEffect(() => {
    handleCalculate();
  }, [
    pobedaDomacina,
    nereseno,
    pobedaGosta,
    domacinDao,
    domacinPrimio,
    brojUtakmicaDomacin,
    gostDao,
    gostPrimio,
    brojUtakmicaGost,
  ]);

  return (
    <div className={classes.container}>
      <input
        onChange={(e) => {
          setPobedaDomacina(parseFloat(e.target.value));
        }}
        className={classes.blackCenteredInput}
        placeholder="pobeda domaćina"
        type="number"
        min="0"
      />
      <input
        onChange={(e) => {
          setNeresno(parseFloat(e.target.value));
        }}
        className={classes.blackCenteredInput}
        placeholder="nerešeno"
        type="number"
        min="0"
      />
      <input
        onChange={(e) => {
          setPobedaGosta(parseFloat(e.target.value));
        }}
        className={classes.blackCenteredInput}
        placeholder="pobeda gosta"
        type="number"
        min="0"
      />
      <input
        onChange={(e) => {
          setDomacinDao(parseFloat(e.target.value));
        }}
        className={classes.blackCenteredInput}
        placeholder="domaćin + (dao)"
        type="number"
        min="0"
      />
      <input
        onChange={(e) => {
          setDomacinPrimio(parseFloat(e.target.value));
        }}
        className={classes.blackCenteredInput}
        placeholder="domaćin - (primio)"
        type="number"
        min="0"
      />
      <input
        onChange={(e) => {
          setBrojUtakmicaDomacin(parseFloat(e.target.value));
        }}
        className={classes.blackCenteredInput}
        placeholder="domaćin broj utak."
        type="number"
        min="0"
      />
      <input
        onChange={(e) => {
          setGostDao(parseFloat(e.target.value));
        }}
        className={classes.blackCenteredInput}
        placeholder="gost + (dao)"
        type="number"
        min="0"
      />
      <input
        onChange={(e) => {
          setGostPrimio(parseFloat(e.target.value));
        }}
        className={classes.blackCenteredInput}
        placeholder="gost - (primio)"
        type="number"
        min="0"
      />
      <input
        onChange={(e) => {
          setBrojUtakmicaGost(parseFloat(e.target.value));
        }}
        className={classes.blackCenteredInput}
        placeholder="gost broj utak."
        type="number"
        min="0"
      />
    </div>
  );
};

export default Grupa3004;
