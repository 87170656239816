import React from 'react'
import classes from './CategoriesSidebar.module.css'

const CategoriesSidebar = ({
  allCategories,
  setSelectedCategory,
  selectedCategory,
  setSelectedProduct,
  setSearchParams,
}) => {
  const handleChangeCategory = (category) => {
    setSelectedProduct(null)
    setSelectedCategory(category)
    setSearchParams((prevParams) => {
      const newParams = new URLSearchParams(prevParams)
      newParams.delete('productId')
      return newParams
    })
  }

  return (
    <div className={classes.categoriesSidebar}>
      <ul>
        <li
          onClick={() => handleChangeCategory(null)}
          className={!selectedCategory ? classes.selectedCategory : ''}
        >
          <button>Svi proizvodi</button>
        </li>
        {allCategories?.map((category) => (
          <li
            key={category?._id}
            className={
              selectedCategory === category?._id ? classes.selectedCategory : ''
            }
          >
            <button
              className={category?.isEmpty ? classes.emptyCategory : ''}
              onClick={() => handleChangeCategory(category?._id)}
            >
              {category?.name}
            </button>
          </li>
        ))}
      </ul>
    </div>
  )
}

export default CategoriesSidebar
