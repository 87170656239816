import classes from "./grupa216.module.css";
import Axios from "axios";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
const SERVER_URL = process.env.REACT_APP_SERVER_URL;

const Grupa216 = ({
  imgBackgroundColorDark,
  imgBackgroundColorLight,
  imgFontColor,
  label1,
  label2,
  label3,
  label4,
  label5,
  label6,
  label7,
  label8,
  label9,
  label10,
  label11,
  label12,
  label13,
  label14,
  label15,
  label16,
  calculatorType,
}) => {
  const navigate = useNavigate();
  let [tutorialLink, setTutorialLink] = useState("");

  useEffect(() => {
    fetchLink();
  }, []);

  const fetchLink = async () => {
    try {
      const tutorial = await Axios.get(
        `${SERVER_URL}/tutorial-calcname?name=${calculatorType}`
      );

      if (tutorial && tutorial.data && tutorial.data._id) {
        setTutorialLink(tutorial.data._id);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleOpiskalkulatora = () => {
    window.open(`../../bet_kalkulatori_tutorijal/${tutorialLink}`, "_blank");
  };
  const handlePitaj = () => {
    window.open("https://m.me/betkobet1x2", "_blank");
  };
  return (
    <div className={classes.container}>
      <div
        style={{
          background: `linear-gradient(${imgBackgroundColorDark}, ${imgBackgroundColorLight})`,
          color: imgFontColor,
          display: "flex",
        }}
      >
        <div className={classes.left}>
          <div className={classes.segment}>
            <a href="#" className={classes.tooltip}>
              <div style={{ color: imgFontColor }}>UG 0-1</div>
              <span>0 ILI 1 GOL NA UTAKMICI</span>
            </a>
            <div>{label1}</div>
          </div>
          <div className={classes.segment}>
            <a href="#" className={classes.tooltip}>
              <div style={{ color: imgFontColor }}>UG 1-2</div>
              <span>1 ILI 2 GOLA NA UTAKMICI</span>
            </a>
            <div>{label2}</div>
          </div>
          <div className={classes.segment}>
            <a href="#" className={classes.tooltip}>
              <div style={{ color: imgFontColor }}>UG 2-3</div>
              <span>2 ILI 3 GOLA NA UTAKMICI</span>
            </a>
            <div>{label3}</div>
          </div>
          <div className={classes.segment}>
            <a href="#" className={classes.tooltip}>
              <div style={{ color: imgFontColor }}>UG 3-4</div>
              <span>3 ILI 4 GOLA NA UTAKMICI</span>
            </a>
            <div>{label4}</div>
          </div>
          <div className={classes.segment}>
            <a href="#" className={classes.tooltip}>
              <div style={{ color: imgFontColor }}>UG 4-5</div>
              <span>4 ILI 5 GOLOVA NA UTAKMICI</span>
            </a>
            <div>{label5}</div>
          </div>
          <div className={classes.segment}>
            <a href="#" className={classes.tooltip}>
              <div style={{ color: imgFontColor }}>UG 5+</div>
              <span>5 ILI VIŠE GOLOVA NA UTAKMICI</span>
            </a>
            <div>{label6}</div>
          </div>
          <div className={classes.segment}>
            <a href="#" className={classes.tooltip}>
              <div style={{ color: imgFontColor }}>UG 0-2</div>
              <span>NAJVIŠE 2 GOLA NA UTAKMICI</span>
            </a>
            <div>{label7}</div>
          </div>
          <div className={classes.segment}>
            <a href="#" className={classes.tooltip}>
              <div style={{ color: imgFontColor }}>UG 1-3</div>
              <span>OD 1 DO 3 GOLOVA NA UTAKMICI</span>
            </a>
            <div>{label8}</div>
          </div>
          <div className={classes.segment}>
            <a href="#" className={classes.tooltip}>
              <div style={{ color: imgFontColor }}>UG 2-4</div>
              <span>OD 2 DO 4 GOLOVA NA UTAKMICI</span>
            </a>
            <div>{label9}</div>
          </div>
          <div className={classes.segment}>
            <a href="#" className={classes.tooltip}>
              <div style={{ color: imgFontColor }}>UG 3-5</div>
              <span>OD 3 DO 5 GOLOVA NA UTAKMICI</span>
            </a>
            <div>{label10}</div>
          </div>
          <div className={classes.segment}>
            <a href="#" className={classes.tooltip}>
              <div style={{ color: imgFontColor }}>UG 4+</div>
              <span>4 ILI VIŠE GOLOVA NA UTAKMICI</span>
            </a>
            <div>{label11}</div>
          </div>
          <div className={classes.segment}>
            <a href="#" className={classes.tooltip}>
              <div style={{ color: imgFontColor }}>UG 0-3</div>
              <span>OD 0 DO 3 GOLOVA NA UTAKMICI</span>
            </a>
            <div>{label12}</div>
          </div>
          <div className={classes.segment}>
            <a href="#" className={classes.tooltip}>
              <div style={{ color: imgFontColor }}>UG 1-4</div>
              <span>OD 1 DO 4 GOLOVA NA UTAKMICI</span>
            </a>
            <div>{label13}</div>
          </div>
          <div className={classes.segment}>
            <a href="#" className={classes.tooltip}>
              <div style={{ color: imgFontColor }}>UG 2-5</div>
              <span>OD 2 DO 5 GOLOVA NA UTAKMICI</span>
            </a>
            <div>{label14}</div>
          </div>
          <div className={classes.segment}>
            <a href="#" className={classes.tooltip}>
              <div style={{ color: imgFontColor }}>UG 1-5</div>
              <span>OD 1 DO 5 GOLOVA NA UTAKMICI</span>
            </a>
            <div>{label15}</div>
          </div>
          <div className={classes.segment}>
            <a href="#" className={classes.tooltip}>
              <div style={{ color: imgFontColor }}>UG 2+</div>
              <span>2 ILI VIŠE GOLOVA NA UTAKMICI</span>
            </a>
            <div>{label16}</div>
          </div>
        </div>
        <div className={classes.right}>
          <button
            onClick={() => handleOpiskalkulatora()}
            className={classes.btn1}
          >
            OPIS KALKULATORA
          </button>
          <button onClick={() => handlePitaj()} className={classes.btn2}>
            PITAJ
          </button>
        </div>
      </div>
    </div>
  );
};

export default Grupa216;
