import { ReactCalculator } from "simple-react-calculator";
import { useNavigate } from "react-router-dom";
import marketingImg from "./slikaMarketing.png";
import classes from "./grupa3.module.css";

const Grupa3 = ({
  imgText,
  imgBackgroundColorDark,
  imgBackgroundColorLight,
  imgFontColor,
}) => {
  const navigate = useNavigate();

  const handleOsveziKalkulator = () => {
    window.location.reload();
  };

  return (
    <div className={classes.outherContainer}>
      <div className={classes.container}>
        <div className={classes.leftContainer}>
          <div
            style={{
              height: 54,
              background: `linear-gradient(${imgBackgroundColorDark}, ${imgBackgroundColorLight})`,
              color: imgFontColor,
              border: "3px solid black",
              borderRadius: 8,
              textAlign: "center",
              fontSize: 32,
              fontWeight: "bolder",
            }}
          >
            <p
              style={{
                margin: 12,
              }}
            >
              {imgText}
            </p>
          </div>
          <div className={classes.marketing}>
            <img src={marketingImg} alt="reklama" />
          </div>
        </div>
        <div className={classes.calculator}>
          <ReactCalculator />
        </div>
      </div>
      <div className={classes.footer}>
        <a href="#" className={classes.tooltip}>
          GDE DA PRONAĐEM PODATKE ZA UNOS ?
          <span>
            POSETI SAJT BILO KOJE KLADIONICE ILI BILO KOJI DRUGI SAJT KOJI
            OBRAĐUJE KVOTE I REZULTATE UTAKMICA. ZA DETALJNIJA OBJAŠNJENJA
            KLIKNI NA ''OPIS KALKULATORA''.
          </span>
        </a>
        <a href="#" className={classes.tooltip}>
          INSTRUKCIJE ZA UNOS PODATAKA
          <span>
            ZA UNOS PODATAKA KORISTI BELA POLJA. UKOLIKO POLJA ODVAJA PRIKAZANA
            TAČKA UNESI BROJ I U LEVO I U DESNO POLJE OD TAČKE (NPR. 2 = LEVO .
            DESNO = 00). UKOLIKO NEMA PRIKAZANE TAČKE UNESI DECIMALNI BROJ UZ
            UPIS TAČKE (NPR. 2.00). POLJA OBELEŽENA CRVENOM ZVEZDICOM * SU
            OBAVEZNA POLJA ZA UNOS U CELINI UNOSA BROJA ILI DECIMALNOG BROJA.
          </span>
        </a>
        <a onClick={() => handleOsveziKalkulator()}>
          KLIKNI I OSVEŽI KALKULATOR
        </a>
        <a
          onClick={() =>
            navigate("/info_blok/655577ae8caca2f92700d0fd")
          }
        >
          UBRZAJ RAD SA KALKULATORIMA !
        </a>
      </div>
    </div>
  );
};

export default Grupa3;
