import { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import classes from "./adminPostSearchModule.module.css";

const AdminPostSearchModule = ({ type }) => {
  const [title, setTitle] = useState("");

  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();

  const getSinglePost = async (title) => {
    if (type === "betKursNovo") {
      const { data } = await axiosPrivate.get(
        `/getSingleAllBetKursNovoPost/${title}`
      );
      const post = data;
      navigate("/betko-admin/edituj-post", {
        state: { postId: post._id, type },
      });
      return data;
    }
    if (type === "infoBlok") {
      const { data } = await axiosPrivate.get(
        `/getSingleAllInfoBlokPost/${title}`
      );
      const post = data;
      navigate("/betko-admin/edituj-post", {
        state: { postId: post._id, type },
      });
      return data;
    }
    if (type === "betPortal") {
      const { data } = await axiosPrivate.get(
        `/getSingleAllBetPortalPost/${title}`
      );
      const post = data;
      navigate("/betko-admin/edituj-post", {
        state: { postId: post._id, type },
      });
      return data;
    }
    if (type === "statistika") {
      const { data } = await axiosPrivate.get(
        `/statistika/getSingleAllStatistikaPost/${title}`
      );
      const post = data;
      navigate("/betko-admin/edituj-post", {
        state: { postId: post._id, type },
      });
      return data;
    }
    if (type === "nasiPredlozi") {
      const { data } = await axiosPrivate.get(
        `/getSingleAllNasiPredloziPost/${title}`
      );
      const post = data;
      navigate("/betko-admin/edituj-post", {
        state: { postId: post._id, type },
      });
      return data;
    }
    if (type === "betKalkulatoriTutorijal") {
      const { data } = await axiosPrivate.get(
        `/getSingleAllBetKalkulatoriTutorijalPost/${title}`
      );
      const post = data;
      navigate("/betko-admin/edituj-post-tutorijal", {
        state: { postId: post._id, type },
      });
      return data;
    }
  };

  const { status, data, error, refetch } = useQuery(
    ["post", title],
    () => getSinglePost(title),
    {
      enabled: false,
    }
  );

  //   if (singleUserQuery.isLoading) return <h2>Loading...</h2>;
  //   if (singleUserQuery.isError) return <h2>Error...</h2>;

  const handleSearch = () => {
    // console.log("title", title);
    setTitle("");
    refetch();
  };

  return (
    <div className={classes.container}>
      <input
        className={classes.input}
        type="text"
        placeholder="Upiši naslov"
        onChange={(e) => setTitle(e.target.value)}
        value={title}
      />
      <button onClick={handleSearch} className={classes.btn}>
        Pretraži
      </button>
    </div>
  );
};

export default AdminPostSearchModule;
