import { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import AdminPostSearchModule from "./AdminPostSearchModule";
import AdminBetkoInfoZidSinglePost from "./AdminBetkoInfoZidSinglePost";
import Spinner from "../components/Spinner";
import classes from "./adminBetPortal.module.css";

const AdminBetPortal = () => {
  const [isArray, setIsArray] = useState(false);
  const navigate = useNavigate();
  const axiosPrivate = useAxiosPrivate();

  const getAllBetPortalPosts = async () => {
    const { data } = await axiosPrivate.get("/betko-info-wall");
    const isArray = await Array.isArray(data);
    if (isArray) {
      setIsArray(true);
    }
    return data;
  };
  const allBetPortalPostsQuery = useQuery({
    // queryKey: ["BetPortalPosts"],
    queryFn: getAllBetPortalPosts,
    staleTime: 0,
    refetchInterval: 1000 * 60 * 60 * 12,
  });

  if (allBetPortalPostsQuery.isError) return <h2>Error...</h2>;

  console.log('aallll', allBetPortalPostsQuery);

  const handleNewPost = () => {
    navigate("/betko-admin/kreiraj-zid-karticu", {
      state: "betWall",
    });
  };

  return (
    <div className={classes.container}>
      {allBetPortalPostsQuery.isLoading ? (
        <Spinner />
      ) : (
        <>
          <h1>BETKO INFO ZID</h1>
          <div className={classes.top}>
            {isArray && allBetPortalPostsQuery.data !== undefined && (
              <AdminPostSearchModule type="betPortal" />
            )}
            <button onClick={() => handleNewPost()} className={classes.btn}>
              Dodaj Novi
            </button>
          </div>
          <div className={classes.postContainer}>
            <div>
              {isArray && allBetPortalPostsQuery.data !== undefined
                ? allBetPortalPostsQuery.data.map((post) => {
                  console.log('return post', post);
                    return (
                      <AdminBetkoInfoZidSinglePost
                        post={post}
                        key={post._id}
                      />
                    );
                  })
                : allBetPortalPostsQuery.data.msg}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default AdminBetPortal;
