import React from "react";

const Title = ({ title, background, color }) => {
  return (
    <div
      style={{
        backgroundColor: background,
        border: "3px solid black",
        width: "99%",
        height: "100px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginTop: "20px",
      }}
    >
      <h2 style={{ color: color, fontSize: "40px" }}>{title}</h2>
    </div>
  );
};

export default Title;
