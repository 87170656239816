import React, { useContext, useEffect, useState } from 'react'
import classes from './GlobalnaTabela.module.css'
import MisterTipsterDefaultTable from '../components/MisterTipsterDefaultTable'
import axios from 'axios'
import AuthContext from '../context/AuthProvider'
const SERVER_URL = process.env.REACT_APP_SERVER_URL

const GlobalnaTabela = () => {
  const [globalTableData, setGlobalTableDate] = useState([[], []])
  const [isLoading, setIsLoading] = useState(false)
  const [searchTerm, setSearchTerm] = useState(null)
  const searchParams = new URLSearchParams(window.location.search)
  const { auth } = useContext(AuthContext)
  const page = searchParams.get('page')

  useEffect(() => {
    const fetchTableData = async () => {
      setIsLoading(true)
      try {
        const response = await axios.get(
          `${SERVER_URL}/user/get-general-table/${
            auth?._id || 'noId'
          }/${page}/${searchTerm || null}`
        )
        setGlobalTableDate(response?.data)
      } catch (error) {
        setGlobalTableDate([], [])
        console.error('Error fetching table data:', error)
      }
      setIsLoading(false)
    }

    fetchTableData()
  }, [page, searchTerm])

  return (
    <div className={classes.yearTableContainer}>
      <MisterTipsterDefaultTable
        data={globalTableData}
        period='global'
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        isLoading={isLoading}
      />
    </div>
  )
}

export default GlobalnaTabela
